import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { StackDetailsPage } from '../components/Training/Admin/Course/StackDetail';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const stackDetailResp = await tokenWithRedirect(
    async () => await apiService.learning.getStackDetail(id),
    action.request.url
  );

  const { data: stackDetail } = stackDetailResp;

  return {
    stackDetail,
  };
}

export function Component() {
  const { stackDetail } = useLoaderData<typeof clientLoader>();

  return <StackDetailsPage stack={stackDetail} />;
}
