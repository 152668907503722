import { Link } from '@remix-run/react';
import { useMemo } from 'react';

import {
  type ModelsPromptConfig,
  type ModelsTrainingProfile,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { usePromptTemplateEditorModal } from '../PromptTemplate/PromptTemplateEditor';
import { buildTemplateConfigGroups } from './utils';

function PromptTemplateLink(props: {
  config?: ModelsPromptConfig | null;
  inlineEdit?: boolean;
}): JSX.Element {
  const { config } = props;
  const openEditorModal = usePromptTemplateEditorModal();
  if (!config || config.templateId === '') return <span>N/A</span>;

  const onEdit = async () => {
    const resp = await apiService.promptTemplate.getTemplateById(
      config.templateId
    );
    openEditorModal(resp.data.promptTemplate);
  };

  if (props.inlineEdit) {
    return (
      <button type='button' onClick={onEdit} className='underline'>
        {config.templateName}
      </button>
    );
  }
  return (
    <Link
      to={`/admin/prompt-templates/${config.templateId}`}
      className='underline'
    >
      {props.config?.templateName ?? 'N/A'}
    </Link>
  );
}

function PromptTemplateRow(props: {
  label: string;
  config?: ModelsPromptConfig | null;
  inlineEdit?: boolean;
}) {
  const { label, config } = props;
  const unset = !config || config.templateId === '';
  return (
    <tr className={`${unset ? 'text-red-002' : 'text-white'}`}>
      <td>{label}</td>
      <td>
        <PromptTemplateLink config={config} inlineEdit={props.inlineEdit} />
      </td>
    </tr>
  );
}

export function TrainingProfileQuickLook(props: {
  profile: ModelsTrainingProfile;
  inlineEdit?: boolean;
}) {
  const { profile, inlineEdit } = props;
  const groups = useMemo(() => buildTemplateConfigGroups(profile), [profile]);
  return (
    <table className='border-separate' style={{ borderSpacing: '8px 0' }}>
      <tbody>
        {groups.global.map((g, i) => (
          <PromptTemplateRow
            key={`${i}-${g.name}`}
            label={g.label}
            config={g.config}
            inlineEdit={inlineEdit}
          />
        ))}
        {groups.blocks.map((block, i) => (
          <PromptTemplateRow
            key={`${i}-${block.type}`}
            label={`Block - ${block.type}`}
            config={block}
            inlineEdit={inlineEdit}
          />
        ))}
      </tbody>
    </table>
  );
}
