import {
  type ClientLoaderFunctionArgs,
  Link,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from '@remix-run/react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useForm } from 'react-hook-form';
import { useTitle } from 'react-use';

import { type HubspotProduct } from '@lp-lib/api-service-client/public';

import { XIcon } from '../components/icons/XIcon';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);

  const resp = await apiService.hubSpot.searchProducts({
    limit: Number(url.searchParams.get('limit') || 50),
    query: url.searchParams.get('query') || '',
  });

  return resp.data;
}

function HubSpotProduct(props: {
  product: HubspotProduct;
  onEdit: () => void;
}) {
  const { product } = props;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const createDate = product.createdate
    ? utcToZonedTime(product.createdate, timezone)
    : null;

  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  const [internalProductId, stripePriceId] = (product.hs_sku ?? '').split(',');
  const isCustomProduct = !!internalProductId && !!stripePriceId;

  return (
    <tr className='w-full h-10 text-sms hover:bg-lp-gray-002 odd:bg-lp-gray-001'>
      <td>{product.name}</td>
      <td>{currency.format(Number(product.price))}</td>
      <td>
        {createDate ? format(createDate, 'MMM d, yyyy h:mm aa O') : 'N/A'}
      </td>
      <td>
        {isCustomProduct ? (
          <Link to={`./${product.hs_object_id}`} className='text-sms underline'>
            Edit
          </Link>
        ) : (
          <div className='cursor-not-allowed text-secondary'>
            Non Custom Product
          </div>
        )}
      </td>
    </tr>
  );
}

function HubSpotProducts() {
  const { products } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const query = params.get('query');
  const { register, handleSubmit, reset } = useForm<{ query: string }>({
    defaultValues: { query: query || '' },
  });

  const onSubmit = handleSubmit(async (data) => {
    params.set('query', data.query);
    navigate({ search: params.toString() });
  });

  const onClear = () => {
    reset();
    params.delete('query');
    navigate({ search: params.toString() });
  };

  return (
    <div className='w-full relative text-white'>
      <header className='flex justify-between items-center mb-10'>
        <h1 className='font-bold text-3xl'>HubSpot Products</h1>
      </header>
      <div className='flex items-center justify-between'>
        <form onSubmit={onSubmit}>
          <div className='flex items-center gap-2'>
            <div className='relative'>
              <input
                className='field mb-0 w-80 h-10'
                {...register('query')}
                placeholder='Search Product ID / Name / Description'
              />
              {query && (
                <button
                  type='button'
                  className='absolute right-2 top-1/2 transform -translate-y-1/2'
                  onClick={onClear}
                >
                  <XIcon />
                </button>
              )}
            </div>
            <button type='submit' className='btn-primary w-30 h-10 text-sms'>
              Search
            </button>
          </div>
        </form>
        <Link to={'./create'} className='text-primary underline text-sms'>
          New Product
        </Link>
      </div>
      <table className='w-full'>
        <thead className='text-left h-12 text-base text-bold'>
          <tr>
            <th>Product Name</th>
            <th>Unit Price</th>
            <th>Create Date</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <HubSpotProduct
              key={product.hs_object_id}
              product={product}
              onEdit={() => {
                navigate(`./${product.hs_object_id}/customize`);
              }}
            />
          ))}
        </tbody>
      </table>
      <div className='text-center text-sms text-secondary mt-10'>
        We don't support pagination, refine the search query to find your deal.
      </div>
    </div>
  );
}

export function Component() {
  useTitle(makeTitle('HubSpot Products'));

  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <HubSpotProducts />
    </AdminView>
  );
}
