import { Fragment, useLayoutEffect, useMemo, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { match } from 'ts-pattern';
import useFitText from 'use-fit-text';
import { useSnapshot } from 'valtio';

import { EnumsJeopardyClueType } from '@lp-lib/api-service-client/public';
import {
  type JeopardyBlock,
  type JeopardyCategory,
  type JeopardyClue,
} from '@lp-lib/game';

import { useDevicePreviewFrame } from '../../../Training/Editor/Shared/DevicePreview';
import { FFriendlyEditableText } from '../../design/Editable';
import { type JeopardyBlockControlAPI } from './JeopardyBlockPlayground';
import {
  type JeopardyGameCategory,
  type JeopardyGameClue,
  type JeopardyStage,
} from './types';

function ScaleText(props: { children: React.ReactNode }) {
  const { fontSize, ref } = useFitText({ logLevel: 'none' });
  return (
    <div
      ref={ref}
      className='w-full h-full flex items-center justify-center text-center p-2'
      style={{ fontSize }}
    >
      {props.children}
    </div>
  );
}

function JeopardyBoardCellCategory(props: { category: JeopardyGameCategory }) {
  const { category } = props;

  return (
    <div
      id={`jeopardy-board-cell-category-${category.id}`}
      className={`
        bg-[#0B1887] flex items-center justify-center
        text-center font-bold uppercase p-1
        ${category.status === 'init' ? 'text-transparent' : 'text-white'}
      `}
    >
      <ScaleText>{category.name}</ScaleText>
    </div>
  );
}

function AnimateRightBlinking() {
  const ringRef = useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    ringRef.current?.animate(
      [
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
      ],
      {
        duration: 166,
        fill: 'forwards',
        iterations: 3,
        easing: 'steps(2, jump-none)',
      }
    );
  });

  return (
    <div
      ref={ringRef}
      className={`
      ${'absolute inset-0 ring-2 ring-offset-black ring-offset-1 ring-tertiary opacity-0 pointer-events-none'}
    `}
    />
  );
}

function JeopardyBoardCellClue(props: {
  clue: JeopardyGameClue;
  onSelect: () => void;
}) {
  const { clue, onSelect } = props;

  return (
    <div
      id={`jeopardy-board-cell-clue-${clue.id}`}
      className={`
          w-full h-full
          transition-colors
          flex items-center justify-center font-bold text-xl text-center
          ${
            clue.status === 'present' || clue.status === 'selectable'
              ? 'text-tertiary'
              : 'text-transparent'
          }
          ${
            clue.status === 'selectable'
              ? 'bg-[#0029FF] hover:bg-[#2649FF] hover:ring-1 hover:ring-white cursor-pointer'
              : 'bg-[#0B1887]'
          }
        `}
      onClick={clue.status === 'selectable' ? onSelect : undefined}
    >
      ${clue.points}
    </div>
  );
}

export function useJeopardyBoardCellClueRef(clueId: string | null) {
  const iframeRef = useDevicePreviewFrame();
  return useMemo(() => {
    if (!clueId) return null;

    const id = `jeopardy-board-cell-clue-${clueId}`;

    return iframeRef
      ? iframeRef?.contentDocument?.getElementById(id)
      : document.getElementById(id);
  }, [iframeRef, clueId]);
}

export function JeopardyBoard(props: {
  block: JeopardyBlock;
  ctrl: JeopardyBlockControlAPI;
  stage: JeopardyStage;
}) {
  const { ctrl, stage } = props;
  const { board } = useSnapshot(ctrl.state);

  const ref = useRef<HTMLDivElement>(null);

  // animate the board in
  useLayoutEffect(() => {
    if (stage.state !== 'init-board' || stage.animate !== 'slide-in') return;
    if (!ref.current) return;

    ref.current.animate(
      [
        {
          transform: 'translateY(110%)',
        },
        {
          transform: 'translateY(0)',
        },
      ],
      {
        duration: 300,
        easing: 'ease-in-out',
        fill: 'forwards',
      }
    );
  }, [stage]);

  return (
    <div
      className='w-full h-full grid gap-1 grid-flow-col grid-cols-3 grid-rows-4'
      ref={ref}
    >
      {board.categories.map((category) => {
        return (
          <Fragment key={category.id}>
            <JeopardyBoardCellCategory
              key={category.id}
              category={category as JeopardyGameCategory}
            />
            {category.clues.map((clue) => {
              return (
                <div
                  className='relative w-full h-full text-tertiary'
                  key={clue.id}
                >
                  <JeopardyBoardCellClue
                    clue={clue as JeopardyGameClue}
                    onSelect={() => ctrl.selectClue(clue as JeopardyGameClue)}
                  />

                  {stage.state === 'play-clue' &&
                  stage.clue.id === clue.id &&
                  stage.animateClueCellBlinking ? (
                    <AnimateRightBlinking />
                  ) : null}
                </div>
              );
            })}
          </Fragment>
        );
      })}
    </div>
  );
}

function EditableCell(props: {
  onClick: () => void;
  children: React.ReactNode;
}) {
  const { onClick, children } = props;
  const { fontSize, ref } = useFitText({ logLevel: 'none' });
  return (
    <div
      ref={ref}
      className={`
        bg-[#0B1887] relative
        flex items-center justify-center text-center text-white p-1 cursor-pointer group
      `}
      onClick={onClick}
      style={{ fontSize }}
    >
      <div className='absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center'>
        <div className='absolute inset-0 bg-black bg-opacity-50' />
        <div className='relative bg-black text-white text-sms font-bold rounded-full px-5 py-1'>
          Edit
        </div>
      </div>
      {children}
    </div>
  );
}

export function JeopardyEditorBoard(props: {
  block: JeopardyBlock;
  onCategoryChange: (category: JeopardyCategory) => void;
  onEditClue: (clue: JeopardyClue) => void;
  view: 'clues' | 'answers' | 'points';
}) {
  const { block, onCategoryChange, onEditClue, view } = props;

  return (
    <div className='w-full h-full grid gap-1 grid-flow-col grid-cols-3 grid-rows-4'>
      {block.fields.board.categories?.slice(0, 3).map((category) => {
        return (
          <Fragment key={category.id}>
            <div
              className='
                bg-[#0029FF] flex items-center justify-center gap-1
                text-center text-white uppercase p-1
              '
            >
              <ScaleText>
                <FFriendlyEditableText
                  value={category.name}
                  onBlur={(val) => {
                    onCategoryChange({ ...category, name: val });
                  }}
                  className={`
                    w-full outline-none cursor-text
                    contenteditable-placeholder whitespace-pre-wrap break-words
                  `}
                  placeholder='Category Here'
                />
              </ScaleText>
            </div>
            {category.clues.slice(0, 3).map((clue) => {
              return (
                <EditableCell key={clue.id} onClick={() => onEditClue(clue)}>
                  {match(view)
                    .with('clues', () => <span>{clue.question}</span>)
                    .with('answers', () => {
                      switch (clue.type) {
                        case EnumsJeopardyClueType.JeopardyClueTypeQuestion:
                          return <span>{clue.answer}</span>;
                        default:
                          return (
                            <div className='flex flex-col items-start'>
                              {clue.answerChoices?.map((choice, index) => (
                                <div
                                  key={index}
                                  className='flex items-start gap-1'
                                >
                                  <div>{choice.correct ? '✓' : '×'}</div>
                                  <div className='text-left'>{choice.text}</div>
                                </div>
                              ))}
                            </div>
                          );
                      }
                    })
                    .with('points', () => (
                      <span className={`text-tertiary font-bold text-xl`}>
                        ${clue.value}
                      </span>
                    ))
                    .otherwise(() => null)}
                </EditableCell>
              );
            })}
          </Fragment>
        );
      })}
    </div>
  );
}
