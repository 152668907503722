import { useCallback } from 'react';

import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import {
  EnrollmentModal,
  type EnrollmentTarget,
} from './Modals/CourseAssignmentModal';

export function useTriggerEnrollmentModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: {
      enrollmentTarget: EnrollmentTarget;
      onAssigned: (params: {
        mode: 'email' | 'group';
        items: string[];
        createGroup?: boolean;
        newGroupName?: string;
      }) => Promise<void>;
    }) => {
      return triggerModal({
        kind: 'custom',
        containerClassName: 'bg-lp-black-002',
        element: (p) => (
          <EnrollmentModal
            enrollmentTarget={props.enrollmentTarget}
            onClose={p.internalOnCancel}
            onAssigned={async (emailsAndGroups) => {
              await props.onAssigned(emailsAndGroups);
              p.internalOnConfirm();
            }}
          />
        ),
      });
    },
    [triggerModal]
  );
}
