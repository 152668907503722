import { useNavigate } from '@remix-run/react';
import { $path } from 'remix-routes';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { ActionsMenu } from '../Components/ActionMenu';

interface AssignedMemberRowProps {
  uid: string;
  name: string;
  group: string;
  assignedOn: string;
  addedBy: string;
  completed: boolean;
  onUnassign: () => void;
}

export function AssignedMemberRow({
  uid,
  name,
  group,
  assignedOn,
  addedBy,
  completed,
  onUnassign,
}: AssignedMemberRowProps) {
  const navigate = useNavigate();
  const handleClick = useLiveCallback(() => {
    navigate($path('/learning/admin/learners/:id/profile', { id: uid }));
  });
  const actions = [
    {
      label: 'Unassign Member',
      icon: <DeleteIcon className='w-6 h-6 fill-current pr-2' />,
      onClick: () => onUnassign?.(),
      variant: 'danger' as const,
    },
  ];

  return (
    <div
      className='
        relative w-full
        rounded-lg px-4 py-3
        bg-[#17171A] hover:bg-[#1f1f22] transition-colors
        flex flex-col md:flex-row md:items-center
        border-lp-gray-003 border
        cursor-pointer
      '
      onClick={handleClick}
    >
      <div className='md:w-1/5 text-white font-medium text-sm truncate mb-1 md:mb-0 pr-1'>
        {name}
      </div>

      <div className='md:w-1/5 text-white/90 text-sm mb-1 md:mb-0'>{group}</div>

      <div className='md:w-1/5 text-white/90 text-sm mb-1 md:mb-0'>
        {assignedOn}
      </div>

      <div className='md:w-1/5 text-white/90 text-sm mb-1 md:mb-0'>
        {addedBy}
      </div>

      <div
        className='md:w-1/5 text-sm font-semibold mb-2 md:mb-0'
        style={{ color: completed ? 'limegreen' : 'white/90' }}
      >
        {completed ? 'Yes' : 'No'}
      </div>

      {/* Action menu col */}
      <div className='relative flex items-center justify-end w-12 self-end md:self-auto'>
        <ActionsMenu actions={actions} className='w-50 text-left' />
      </div>
    </div>
  );
}
