import { format } from 'date-fns';
import debounce from 'lodash/debounce';
import { useMemo } from 'react';
import type { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { match } from 'ts-pattern';

import { type DtoStackSearchResult } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../../services/api-service';
import { buildReactSelectStyles } from '../../../../utils/react-select';

export type StackOption = {
  kind: 'existing';
  stack: DtoStackSearchResult;
  value: string;
};

interface StackMultiSelectProps {
  options: StackOption[];
  onChange: (val: StackOption[]) => void;
  placeholder?: string;
  disabled?: boolean;
  excludeStackIds?: string[];
}

async function loadAllCourses(
  excludeStackIds: string[] = []
): Promise<StackOption[]> {
  try {
    const resp = await apiService.learning.searchUserCreatedStacks('');
    const stacks = resp.data.stacks;
    return stacks
      .filter((c) => !excludeStackIds.includes(c.id))
      .map((c) => {
        return {
          kind: 'existing' as const,
          stack: c,
          value: c.id,
        };
      });
  } catch {
    return [];
  }
}

async function searchStacksByName(
  query: string,
  excludeStackIds: string[] = []
): Promise<StackOption[]> {
  try {
    const resp = await apiService.learning.searchUserCreatedStacks(query);
    const stacks = resp.data.stacks;
    return stacks
      .filter((c) => !excludeStackIds.includes(c.id))
      .map((g) => ({
        kind: 'existing' as const,
        stack: g,
        value: g.id,
      }));
  } catch {
    return [];
  }
}

function loadCourses(
  inputValue: string,
  excludeStackIds: string[] = []
): Promise<StackOption[]> {
  if (!inputValue) {
    return loadAllCourses(excludeStackIds);
  } else {
    return searchStacksByName(inputValue, excludeStackIds);
  }
}

const debouncedLoad = debounce(
  (
    inputValue: string,
    excludeStackIds: string[],
    callback: (val: StackOption[]) => void
  ) => {
    loadCourses(inputValue, excludeStackIds)
      .then((results) => callback(results))
      .catch(() => callback([]));
  },
  200
);

export function StackMultiSelect(props: StackMultiSelectProps) {
  const {
    options,
    onChange,
    placeholder,
    disabled,
    excludeStackIds = [],
  } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<StackOption, true>({
        override: {
          control: { minHeight: 48 },
          multiValue: () => ({ display: 'none' }),
          menu: {
            background: 'black',
            paddingLeft: '8px',
            paddingRight: '8px',
          },
          option: (provided, state) => ({
            ...provided,
            background:
              state.isFocused && !state.isDisabled ? '#303036' : 'black',
            borderRadius: '12px',
            padding: '6px',
          }),
        },
      }),
    []
  );

  function loadOptions(
    inputValue: string,
    callback: (options: StackOption[]) => void
  ): void {
    debouncedLoad(inputValue, excludeStackIds, callback);
  }

  function getOptionValue(option: StackOption) {
    return option.stack.id;
  }

  const formatOptionLabel = (option: StackOption) => {
    return match(option)
      .with({ kind: 'existing' }, (o) => {
        const createdDate = format(new Date(o.stack.createdAt), 'MM/dd/yyyy');
        return (
          <div className='flex items-center justify-between w-full'>
            <div className='flex items-center gap-3'>
              <div className='w-19 h-10 rounded-lg bg-gray-700 overflow-hidden flex items-center justify-center'>
                <span className='text-2xl'>📚</span>
              </div>
              <span className='text-white font-bold'>{o.stack.name}</span>
            </div>
            <div className='flex items-center gap-8 pr-2'>
              <span className='text-white text-left'>{createdDate}</span>
            </div>
          </div>
        );
      })
      .exhaustive();
  };

  function handleChange(newValue: MultiValue<StackOption>) {
    onChange(newValue.slice()); // convert from readonly array
  }

  return (
    <AsyncSelect<StackOption, true>
      isMulti
      cacheOptions
      defaultOptions
      hideSelectedOptions
      loadOptions={loadOptions}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      placeholder={placeholder}
      value={options}
      onChange={handleChange}
      styles={styles}
      isDisabled={disabled}
      classNamePrefix='select-box-v2'
      noOptionsMessage={() => 'No matching stacks'}
    />
  );
}
