import { useNavigate } from '@remix-run/react';
import { $path } from 'remix-routes';

import { type DtoGroupEnrollmentDetail } from '@lp-lib/api-service-client/public';

import { DeleteIcon } from '../../../icons/DeleteIcon';
import { EditIcon } from '../../../icons/EditIcon';
import { ActionsMenu } from '../Components/ActionMenu';

interface GroupRowProps {
  group: DtoGroupEnrollmentDetail;
  isUnassigning?: boolean;
  onEdit?: (groupId: string) => void;
  onDelete: (groupId: string) => void;
  editLabel?: string;
  deleteLabel?: string;
}

export function AssignedGroupRow({
  group,
  onEdit,
  onDelete,
  editLabel = 'Edit Group',
  deleteLabel = 'Delete Group',
}: GroupRowProps) {
  const navigate = useNavigate();

  const actions = [
    {
      label: editLabel,
      icon: <EditIcon className='w-6 h-6 fill-current text-white pr-2' />,
      onClick: () =>
        onEdit
          ? onEdit(group.groupId)
          : navigate(
              $path('/learning/admin/learners/groups/:id', {
                id: group.groupId,
              })
            ),
    },
    {
      label: deleteLabel,
      icon: <DeleteIcon className='w-6 h-6 fill-current pr-2' />,
      onClick: () => onDelete(group.groupId),
      variant: 'danger' as const,
    },
  ];

  return (
    <div
      className='
      relative w-full
      rounded-lg px-4 py-3
      bg-[#17171A] hover:bg-[#1f1f22] transition-colors
      flex flex-col md:flex-row md:items-center
      border-lp-gray-003 border hover:cursor-pointer
    '
      onClick={() => onEdit?.(group.groupId)}
    >
      <div className='md:w-1/5 text-white font-medium text-sm mb-1 md:mb-0'>
        {group.groupName}
      </div>
      <div className='md:w-1/5 text-white/90 text-sm mb-1 md:mb-0'>
        {group.assignedBy}
      </div>
      <div className='md:w-1/5 text-white/90 text-sm mb-1 md:mb-0'>
        {new Date(group.assignedOn).toLocaleDateString()}
      </div>
      <div className='md:w-1/5 text-white/90 text-sm mb-2 md:mb-0'>
        {group.membersCount} members
      </div>
      {/* Action menu col */}
      <div className='md:w-1/5 relative flex items-center justify-end w-12 self-end md:self-auto'>
        <ActionsMenu actions={actions} className='w-50 text-left' />
      </div>
    </div>
  );
}
