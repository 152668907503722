import axios from 'axios';

import config from '../../config';
import { AIChatAPI } from './ai.chat.api';
import { AIDocumentAPI } from './ai.document.api';
import { AIGeneralAPI } from './ai.general.api';
import { AIGradeAPI } from './ai.grade.api';
import { AnalyticsAPI } from './analytics.api';
import { AuthAPI } from './auth.api';
import { BlockAPI } from './block.api';
import { BrandAPI } from './brand.api';
import { CelebrationAPI } from './celebration.api';
import { ChannelAPI } from './channel.api';
import { CloudHostingAPI } from './cloudHosting.api';
import { CollectiveAPI } from './collective.api';
import { DmgAPI } from './dmg.api';
import { DrawingAPI } from './drawing.api';
import { EventAPI } from './event.api';
import { GameAPI } from './game.api';
import { GamePackAPI } from './gamePack.api';
import { GameScoreAPI } from './gameScore.api';
import { HubSpotAPI } from './hubspot.api';
import { JoyCaptureAPI } from './joyCapture.api';
import { LearningAPI } from './learning.api';
import { MediaAPI } from './media.api';
import { MessageAPI } from './message.api';
import { MessageCampaignAPI } from './messageCampaign.api';
import { MessageLogicAPI } from './messageLogic.api';
import { MessageTemplateAPI } from './messageTemplate.api';
import { MiscAPI } from './misc.api';
import { NotificationAPI } from './notification.api';
import { OnboardAPI } from './onboard.api';
import { OnboardingAPI } from './onboarding.api';
import { OpenAIAPI } from './openai.api';
import { OrganizationAPI } from './organization.api';
import { PagesAPI } from './page.api';
import { PairingAPI } from './pairing.api';
import { ProductAPI } from './product.api';
import { ProgramAPI } from './program.api';
import { ProgressionAPI } from './progression.api';
import { PromotionAPI } from './promotion.api';
import { PromptTemplateAPI } from './promptTemplate.api';
import { OneTimePurchaseAPI } from './purchase.api';
import { SessionAPI } from './session.api';
import { SettingsAPI } from './settings.api';
import { SlackAPI } from './slack.api';
import { StripeAPI } from './stripe.api';
import { TagAPI } from './tag.api';
import { TrainingAPI } from './training.api';
import { TrainingProfileAPI } from './trainingProfile.api';
import { TranslationAPI } from './translation.api';
import { TextToSpeechAPI } from './tts.api';
import { UserAPI } from './user.api';
import { UserSettingsAPI } from './userSettings.api';
import { APIServiceUtils } from './utils';
import { VenueAPI } from './venue.api';
import { ZoomAPI } from './zoom.api';

export class APIService {
  constructor(
    baseURL = config.api.baseUrl,
    private client = axios.create({
      baseURL: baseURL,
    }),
    readonly interceptors = client.interceptors
  ) {}
  utils = APIServiceUtils;
  auth = new AuthAPI(this.client);
  block = new BlockAPI(this.client);
  game = new GameAPI(this.client);
  gamePack = new GamePackAPI(this.client);
  gameScore = new GameScoreAPI(this.client);
  misc = new MiscAPI(this.client);
  tag = new TagAPI(this.client);
  user = new UserAPI(this.client);
  venue = new VenueAPI(this.client);
  organization = new OrganizationAPI(this.client);
  session = new SessionAPI(this.client);
  pairing = new PairingAPI(this.client);
  messageTemplate = new MessageTemplateAPI(this.client);
  cloudHosting = new CloudHostingAPI(this.client);
  slack = new SlackAPI(this.client);
  messageLogic = new MessageLogicAPI(this.client);
  channel = new ChannelAPI(this.client);
  dmg = new DmgAPI(this.client);
  messageCampaign = new MessageCampaignAPI(this.client);
  message = new MessageAPI(this.client);
  media = new MediaAPI(this.client);
  analytics = new AnalyticsAPI(this.client);
  event = new EventAPI(this.client);
  onboard = new OnboardAPI(this.client);
  onboarding = new OnboardingAPI(this.client);
  promotion = new PromotionAPI(this.client);
  notification = new NotificationAPI(this.client);
  joyCapture = new JoyCaptureAPI(this.client);
  userSettings = new UserSettingsAPI(this.client);
  settings = new SettingsAPI(this.client);
  celebration = new CelebrationAPI(this.client);
  tts = new TextToSpeechAPI(this.client);
  drawing = new DrawingAPI(this.client);
  promptTemplate = new PromptTemplateAPI(this.client);
  openai = new OpenAIAPI(this.client);
  aiChat = new AIChatAPI(this.client);
  collective = new CollectiveAPI(this.client);
  brand = new BrandAPI(this.client);
  aiGrade = new AIGradeAPI(this.client);
  program = new ProgramAPI(this.client);
  product = new ProductAPI(this.client);
  stripe = new StripeAPI(this.client);
  otp = new OneTimePurchaseAPI(this.client);
  page = new PagesAPI(this.client);
  zoom = new ZoomAPI(this.client);
  translation = new TranslationAPI(this.client);
  aiGeneral = new AIGeneralAPI(this.client);
  progression = new ProgressionAPI(this.client);
  aiDoc = new AIDocumentAPI(this.client);
  trainingProfile = new TrainingProfileAPI(this.client);
  training = new TrainingAPI(this.client);
  learning = new LearningAPI(this.client);
  hubSpot = new HubSpotAPI(this.client);

  setSecureToken(token: string | null): void {
    if (!token) return;
    this.client.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}
