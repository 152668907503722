import { proxy, useSnapshot } from 'valtio';

import { type EnumsJeopardyTurnResult } from '@lp-lib/api-service-client/public';
import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { Emitter, type EmitterOptions } from '../../../utils/emitter';
import { markSnapshottable } from '../../../utils/valtio';

export type JeopardyTool = {
  kind: 'strike' | 'extra-time';
  used: boolean;
};

type State = {
  category: string;
  points: number;
  tools: JeopardyTool[];
};

type JeopardyEvents = {
  end: (result: EnumsJeopardyTurnResult) => void;
};

export class JeopardyControl {
  private _events = new Emitter<JeopardyEvents>();
  private _state = markSnapshottable(
    proxy<State>({
      category: '',
      points: 0,
      tools: [
        { kind: 'strike', used: false },
        { kind: 'strike', used: false },
        { kind: 'extra-time', used: false },
      ],
    })
  );

  get state() {
    return this._state;
  }

  setClue(props: { category: string; points: number }) {
    this._events.clear();

    this._state.category = props.category;
    this._state.points = props.points;
  }

  once<Name extends keyof JeopardyEvents>(
    name: Name,
    cb: JeopardyEvents[Name],
    opts?: EmitterOptions
  ) {
    return this._events.once(name, cb, opts);
  }

  end(result: EnumsJeopardyTurnResult) {
    this._events.emit('end', result);
  }

  useTool(index: number) {
    this._state.tools[index].used = true;
  }
}

function JeopardyToolCell(props: {
  icon: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  used?: boolean;
}) {
  return (
    <button
      type='button'
      className={`
        ${
          props.used
            ? 'opacity-0 w-0'
            : 'opacity-100 disabled:opacity-40 w-15 mx-2.5'
        }
        flex flex-col items-center justify-center gap-2
        transition-all duration-500
      `}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <img className='w-full h-6 object-contain' src={props.icon} alt='' />
      <div className='text-white text-3xs'>{props.label}</div>
    </button>
  );
}

export function JeopardyHeader(props: { jeopardyControl: JeopardyControl }) {
  const { category, points } = useSnapshot(props.jeopardyControl.state);

  return (
    <div className='pt-7 w-full flex items-center justify-center gap-5'>
      {category && (
        <div className='text-white text-sm lg:text-base font-bold'>
          {category}
        </div>
      )}
      {points ? (
        <div className='text-tertiary text-base lg:text-lg font-bold'>
          ${points}
        </div>
      ) : null}
    </div>
  );
}

export function JeopardyTools(props: {
  jeopardyControl: JeopardyControl;
  onStrike?: () => void;
  onExtraTime?: () => void;
  visible: boolean;
}) {
  const tools = useSnapshot(props.jeopardyControl.state).tools;

  return (
    <div
      className={`
        flex items-center transition-opacity duration-500
        ${props.visible ? 'opacity-100' : 'opacity-0'}
      `}
    >
      {tools.map((tool, index) => {
        switch (tool.kind) {
          case 'strike':
            return (
              <JeopardyToolCell
                key={index}
                icon={getStaticAssetPath(`images/jeopardy/strike.png`)}
                label={'Strike'}
                disabled={!props.onStrike}
                used={tool.used}
                onClick={() => {
                  props.onStrike?.();
                  props.jeopardyControl.useTool(index);
                }}
              />
            );
          case 'extra-time':
            return (
              <JeopardyToolCell
                key={index}
                icon={getStaticAssetPath(`images/jeopardy/extra-time.png`)}
                label={'Extra Time'}
                disabled={!props.onExtraTime}
                used={tool.used}
                onClick={() => {
                  props.onExtraTime?.();
                  props.jeopardyControl.useTool(index);
                }}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
}
