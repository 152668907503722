import { type GamePack } from '../../types/game';
import { LongCourseCard } from './LongCourseCard';

interface LongCourseCardListProps {
  packs: GamePack[];
  isStackMode: boolean;
  selectedCourses: string[];
  selectionOrder: (id: string) => number;
  onSelect: (id: string) => void;
  onAssign: (pack: GamePack) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onDetails: (id: string) => void;
  learnersMap?: Record<string, number>;
}

export function LongCourseCardList({
  packs,
  isStackMode,
  selectedCourses,
  selectionOrder,
  onSelect,
  onAssign,
  onEdit,
  onDelete,
  onDetails,
  learnersMap = {},
}: LongCourseCardListProps) {
  return (
    <div className='flex flex-col w-full'>
      {/* Sticky header row */}
      <div
        className='
        sticky top-0 z-10
        px-4 py-2
        bg-modal
        text-gray-400 text-sm
        flex items-center
      '
      >
        {isStackMode && <div className='w-8' />}
        <div className='flex-1'>Name</div>
        <div className='flex items-center gap-16 pr-10'>
          <div className='w-20 text-start'>Learners</div>
          <div className='w-20 text-start'>Progress</div>
          <div className='w-32 text-start'>Created On</div>
        </div>
        <div className='w-32' />
      </div>
      <div className='flex flex-col gap-2.5 pr-4'>
        {packs.map((pack) => {
          const learners = learnersMap[pack.id] ?? 0;
          return (
            <LongCourseCard
              key={pack.id}
              pack={pack}
              onAssign={onAssign}
              onDetails={onDetails}
              onEdit={onEdit}
              onDelete={onDelete}
              isStackMode={isStackMode}
              isSelected={selectedCourses.includes(pack.id)}
              onSelect={onSelect}
              selectionOrder={selectionOrder(pack.id)}
              learners={learners}
            />
          );
        })}
      </div>
    </div>
  );
}
