import { useRef } from 'react';

import {
  type DtoGamePack,
  EnumsGamePackUGCCreationStatus,
  EnumsGamePackVersion,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { type User } from '../../types';
import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { StoredValueChan } from '../../utils/Chan';
import { usePostLogin } from '../Access';
import { useUserGetter } from '../UserContext';

export function useCreateEmptyGamePack(preloadPack: DtoGamePack | null) {
  const st = useRef<'none' | 'inProgress' | 'done' | 'failed'>('none');
  const chan = useInstance(() => new StoredValueChan<DtoGamePack>());
  const createGamePack = useLiveCallback(async () => {
    if (preloadPack) return preloadPack;
    if (st.current === 'inProgress' || st.current === 'done') {
      return chan.take();
    }
    st.current = 'inProgress';
    try {
      const resp = await apiService.gamePack.create({
        version: EnumsGamePackVersion.GamePackVersionV1,
        childrenIds: [],
        name: 'Untitled Training',
      });
      st.current = 'done';
      chan.put(resp.data.gamePack);
      return chan.take();
    } catch (error) {
      st.current = 'failed';
    }
  });
  return createGamePack;
}

export function useCreateGuestUser() {
  const getUser = useUserGetter();
  const postLogin = usePostLogin();
  const st = useRef<'none' | 'inProgress' | 'done' | 'failed'>('none');
  const chan = useInstance(() => new StoredValueChan<User>());
  const createUser = useLiveCallback(async () => {
    const user = getUser();
    if (user.id) {
      st.current = 'done';
      return user;
    }
    if (st.current === 'inProgress' || st.current === 'done') {
      return chan.take();
    }
    st.current = 'inProgress';
    try {
      const resp = await apiService.auth.guestLogin({
        username: 'guest',
      });
      st.current = 'done';
      postLogin(resp.data);
      chan.put(resp.data.user);
      return chan.take();
    } catch (error) {
      st.current = 'failed';
    }
  });
  return createUser;
}

export function pullCourseCreationStatus(
  id: string,
  callback?: (pack: DtoGamePack) => void,
  signal?: AbortSignal,
  intervalMs = 5000
) {
  return new Promise<void>((resolve, reject) => {
    const ctrl = new BrowserIntervalCtrl();
    signal?.addEventListener('abort', () => {
      ctrl.clear();
    });
    ctrl.set(async () => {
      const resp = await apiService.gamePack.getGamePackById(id);
      const pack = resp.data.gamePack;
      callback?.(pack);
      const creationStatus = pack.ugcSettings?.creationStatus;
      if (
        creationStatus ===
        EnumsGamePackUGCCreationStatus.GamePackUGCCreationStatusFailed
      ) {
        ctrl.clear();
        reject(new Error('Fail to create the course'));
      } else if (
        creationStatus ===
        EnumsGamePackUGCCreationStatus.GamePackUGCCreationStatusCompleted
      ) {
        ctrl.clear();
        resolve();
      }
    }, intervalMs);
  });
}

export function isCreationDone(
  creationStatus?: EnumsGamePackUGCCreationStatus | null
) {
  return (
    !creationStatus ||
    creationStatus ===
      EnumsGamePackUGCCreationStatus.GamePackUGCCreationStatusCompleted ||
    creationStatus ===
      EnumsGamePackUGCCreationStatus.GamePackUGCCreationStatusFailed
  );
}
