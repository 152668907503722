import '@typeform/embed/build/css/popup.css';

import { createPopup } from '@typeform/embed';

import { type DtoFormResponse } from '@lp-lib/api-service-client/public';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';

export async function openTrainingTypeform(): Promise<DtoFormResponse | null> {
  const typeformEnabled = getFeatureQueryParam('course-creation-typeform');
  if (!typeformEnabled) return null;

  let formResponse: DtoFormResponse | null = null;
  await new Promise((resolve) => {
    createPopup('KFI3EAHr', {
      width: 960,
      height: 600,
      open: 'load',
      onClose: () => resolve(true),
      onSubmit: ({ formId, responseId }) => {
        formResponse = { formId, responseId };
      },
      autoClose: true,
    });
  });

  return formResponse;
}
