import { EnumsBlockDownVoteReason } from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import { OndIcon } from '../../../icons/OndIcon';
import { OndRecordingDangerouslyOutdatedIcon } from './Editor';

export function BlockRecordingIcon(props: {
  block: Block | null;
  recordingVersionMismatch?: boolean;
  className?: string;
  iconClassName?: string;
}): JSX.Element | null {
  return props.block?.recording ? (
    <div
      className={`${
        props.className ?? 'absolute left-1 top-1'
      } flex items-center`}
    >
      <OndIcon className={props.iconClassName ?? 'w-[1.20rem] h-[1.20rem]'} />
      <OndRecordingDangerouslyOutdatedIcon
        outdatedRecording={props.block.outdatedRecording}
        versionMismatch={props.recordingVersionMismatch}
      />
    </div>
  ) : null;
}

export class BlockVoteUtils {
  static GetOptions() {
    return [
      {
        emoji: '💻',
        label: 'Tech issues',
        value: EnumsBlockDownVoteReason.BlockDownVoteReasonTechIssues,
      },
      {
        emoji: '😡',
        label: 'Too hard',
        value: EnumsBlockDownVoteReason.BlockDownVoteReasonTooHard,
      },
      {
        emoji: '🤨',
        label: 'Confusing rules',
        value: EnumsBlockDownVoteReason.BlockDownVoteReasonConfusingRules,
      },
      {
        emoji: '🤔',
        label: 'Something else',
        value: EnumsBlockDownVoteReason.BlockDownVoteReasonSomethingElse,
      },
    ];
  }
}
