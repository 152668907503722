import React from 'react';

import { type Organization, OrganizerRoleUtils } from '../../types';
import { useUser } from '../UserContext';

export function OrgRequired(props: {
  children?: ((org: Organization) => JSX.Element) | React.ReactNode;
  denied?: React.ReactNode;
}) {
  const user = useUser();

  if (!user.organizer?.organization)
    return (
      props.denied ?? (
        <div className='w-full h-full flex items-center justify-center text-white'>
          This is only available to organization members.
        </div>
      )
    );

  if (props.children instanceof Function) {
    return props.children(user.organizer.organization);
  }
  return <>{props.children}</>;
}

export function OrgAdminRequired(props: {
  children?: ((org: Organization) => JSX.Element) | React.ReactNode;
  denied?: React.ReactNode;
}) {
  const user = useUser();

  if (
    !user.organizer?.organization ||
    !OrganizerRoleUtils.isOwnerOrAdmin(user.organizer)
  )
    return (
      props.denied ?? (
        <div className='w-full h-full flex items-center justify-center text-white'>
          This is only available to admins
        </div>
      )
    );

  if (props.children instanceof Function) {
    return props.children(user.organizer.organization);
  }
  return <>{props.children}</>;
}
