import { type RenderSlackBlocksParams } from '@lp-lib/api-service-client/public';
import {
  EmailTemplateAnalyticsDigest,
  EmailTemplateEnrollment,
  EmailTemplateExportSession,
  EmailTemplateGameUpdates,
  EmailTemplateGroupEnrollment,
  EmailTemplateGroupPhoto,
  EmailTemplateIntakeFormEdit,
  EmailTemplateIntakeFormReceived,
  EmailTemplateIntakeFormSummary,
  EmailTemplateInvitation,
  EmailTemplateLoginVerify,
  EmailTemplateOnDEvent,
  EmailTemplateStackEnrollment,
  EmailTemplateStripeInvoice,
  EmailTemplateStripeInvoicePaid,
  EmailTemplateWelcomeBySelfRegister,
} from '@lp-lib/email-templates';

export const HARDCODED_EMAIL_TEMPLATES = [
  EmailTemplateAnalyticsDigest,
  EmailTemplateExportSession,
  EmailTemplateGameUpdates,
  EmailTemplateGroupPhoto,
  EmailTemplateIntakeFormEdit,
  EmailTemplateIntakeFormReceived,
  EmailTemplateIntakeFormSummary,
  EmailTemplateInvitation,
  EmailTemplateLoginVerify,
  EmailTemplateOnDEvent,
  EmailTemplateStripeInvoice,
  EmailTemplateStripeInvoicePaid,
  EmailTemplateWelcomeBySelfRegister,
  EmailTemplateEnrollment,
  EmailTemplateStackEnrollment,
  EmailTemplateGroupEnrollment,
];

export interface SlackTemplate {
  key: string;
  name: string;
  description: string;
  slackTemplateName: RenderSlackBlocksParams['templateName'];
}

export const HARDCODED_SLACK_TEMPLATES: SlackTemplate[] = [
  {
    key: 'analytics-digest',
    name: 'Analytics Digest',
    description: `- Frequency: 1st of every month
- Target: Org Admins
- Condition: If the organization has at least 1 valid game session in the past month`,
    slackTemplateName: 'promotion-analytics-digest',
  },
  {
    key: 'game-updates',
    name: 'Game Updates',
    description: `- Frequency: Every Week
  - Target: all members who have played at least 1 game
  `,
    slackTemplateName: 'game-pack-promotion',
  },
  {
    key: 'game-recaps-slack',
    name: 'Post Game Recaps',
    description: `- Frequency: After each game session (1 day cooldown)
  - Target: all participants
  - Condition: if the game session has at least 2 players`,
    slackTemplateName: 'promotion-game-recap',
  },
  {
    key: 'work-anniversaries-slack',
    name: 'Work Anniversaries Celebrations',
    description: 'Work anniversaries template in celebrations program',
    slackTemplateName: 'celebration-anniversary',
  },
  {
    key: 'birthdays-slack',
    name: 'Birthdays Celebrations',
    description: 'Birthday template in celebrations program',
    slackTemplateName: 'celebration-birthday',
  },
];
