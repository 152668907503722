import { useNavigate } from '@remix-run/react';
import pluralize from 'pluralize';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';

import { type Block } from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { useAsyncCall } from '../../hooks/useAsyncCall';
import { useMakeHostViewAutoloadHandler } from '../../hooks/useLoadGame';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useMinigameContext } from '../../pages/Minigame/Context';
import { apiService } from '../../services/api-service';
import { type Game, GameActionType, type GamePack } from '../../types/game';
import { NotificationType } from '../../types/notification';
import { uuidv4 } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { Modal } from '../common/Modal';
import { BlockIcon } from '../icons/Block';
import { DeleteIcon } from '../icons/DeleteIcon';
import { DownloadIcon } from '../icons/DownloadIcon';
import { EditIcon } from '../icons/EditIcon';
import { HostIcon } from '../icons/HostIcon';
import { Loading } from '../Loading';
import { useNotificationDataSource } from '../Notification/Context';
import { usePortalRoutePrefix } from '../PortalContext';
import { ShowMore } from '../ShowMore';
import { useUser } from '../UserContext';
import { BlockRecordingIcon } from './Blocks';
import { BlockKnifeUtils } from './Blocks/Shared';
import { GameActionSheet } from './GameActionSheet';
import {
  ErrorMessage,
  useGameLikeActionSheetSettings,
  useGameLikeEventEmitter,
  useGameLikeWorkspace,
  useShowGameLikeEditButton,
} from './GameCenter';
import { useGameEditorStore } from './GameEditorStore';
import { GameUtils } from './GameUtils';
import {
  type AddGameHandler,
  GameCover,
  GamePackCover,
  MyGameDropdown,
  Tags,
} from './Utilities';
import { makeGameEditUrl } from './utils';

const BlockList = ({
  game,
  isSelecting,
  updateSelectedBlockIds,
}: {
  game: Game;
  isSelecting: boolean;
  updateSelectedBlockIds: (blockIds: string[]) => void;
}): JSX.Element => {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [checkedState, setCheckedState] = useState<(string | null)[]>([]);
  const blockIds = useMemo(() => blocks.map((b) => b.id), [blocks]);
  const expectedOndPlaybackVersion =
    GameUtils.DeriveOndPlaybackVersionFromBlocks(blocks);

  const { state, error, call, reset } = useAsyncCall(
    useCallback(async () => {
      const resp = await apiService.block.getBlocksByGameId(game.id);
      const blks = resp.data.blocks || [];
      setBlocks(blks);
      setCheckedState(new Array(blks.length).fill(null));
    }, [game.id])
  );

  const resetAll = useCallback(() => {
    setBlocks([]);
    reset();
  }, [reset]);

  useEffect(() => {
    call();
    return () => {
      resetAll();
    };
  }, [call, resetAll, game.blocksCount]);

  useEffect(() => {
    if (!isSelecting) return;
    setCheckedState(blockIds);
    updateSelectedBlockIds(blockIds);
    return () => {
      setCheckedState(new Array(blockIds.length).fill(null));
      updateSelectedBlockIds([]);
    };
  }, [isSelecting, blockIds, updateSelectedBlockIds]);

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) => {
      if (index === position) {
        return item ? null : blocks[index].id;
      }
      return item;
    });
    setCheckedState(updatedCheckedState);
    updateSelectedBlockIds(
      updatedCheckedState.filter((s) => s !== null) as string[]
    );
  };

  const handleRetry = () => {
    call();
  };

  return (
    <div>
      {blocks.map((b, i) => {
        const summary = BlockKnifeUtils.Summary(b);
        return (
          <div key={b.id} className='flex my-2 w-full'>
            {isSelecting && (
              <div className='mr-8 flex items-center justify-center'>
                <input
                  type='checkbox'
                  className='checkbox-dark'
                  checked={!!checkedState[i]}
                  onChange={() => handleOnChange(i)}
                />
              </div>
            )}
            <div className='text-xs'>{i + 1}</div>
            <div className='w-38 h-21 mx-3 relative flex-shrink-0'>
              <img
                src={
                  MediaUtils.PickMediaUrl(summary.coverMedia, {
                    priority: [MediaFormatVersion.SM],
                    videoThumbnail: 'first',
                  }) || placeholder
                }
                alt='cover'
                className='object-cover w-full h-full rounded border border-secondary'
              />
              <BlockIcon
                blockType={b.type}
                className='absolute right-1.5 top-1.5 w-2.5 h-2.5'
              />
              <BlockRecordingIcon
                block={b}
                recordingVersionMismatch={
                  !!b.recording &&
                  b.recording.version !== expectedOndPlaybackVersion
                }
              />
            </div>
            <div className='flex items-center'>
              <p className='text-sms line-clamp-3'>{summary.title}</p>
            </div>
          </div>
        );
      })}
      {state.transformed.isRunning && <Loading />}
      {error && (
        <ErrorMessage text='Something went wrong' handleRetry={handleRetry} />
      )}
    </div>
  );
};

function BlockManagement(props: { game: Game }) {
  const { game } = props;
  const ctx = useMinigameContext();
  const emitter = useGameLikeEventEmitter('game');
  const notificationDataSource = useNotificationDataSource();
  const [isSelectingBlocks, setIsSelectingBlocks] = useState<boolean>(false);
  const [selectedBlockIds, setSelectedBlockIds] = useState<string[]>([]);
  const [addBlockClicked, setAddBlockClicked] = useState<boolean>(false);

  useEffect(() => {
    if (selectedBlockIds.length === 0) {
      setAddBlockClicked(false);
    }
  }, [selectedBlockIds.length]);

  const handleCreateGame = async (game: Game, addFn?: AddGameHandler) => {
    if (addFn) {
      await addFn(game, false);
    }
    notificationDataSource.send({
      id: uuidv4(),
      toUserClientId: '',
      type: NotificationType.GameAction,
      createdAt: Date.now(),
      metadata: {
        actionType: GameActionType.CreateGame,
        game: game,
      },
    });
  };

  const handleAddBlock = async (target: Game, sendNotification = true) => {
    if (selectedBlockIds.length === 0) {
      return;
    }
    const resp = await apiService.block.duplicateGameBlocks(
      game.id,
      target.id,
      selectedBlockIds
    );
    target.blocksCount = resp.data.blocks.length;
    if (game.id === target.id) {
      game.blocksCount = target.blocksCount;
    }
    emitter.emit('updated', target);
    if (sendNotification) {
      notificationDataSource.send({
        id: uuidv4(),
        toUserClientId: '',
        type: NotificationType.GameAction,
        createdAt: Date.now(),
        metadata: {
          actionType: GameActionType.AddBlocks,
          game: target,
          numOfBlocks: selectedBlockIds.length,
        },
      });
    }
  };

  const handleDoneClick = () => {
    setIsSelectingBlocks(false);
    setAddBlockClicked(false);
  };

  const updateSelectedBlockIds = useCallback((blockIds: string[]) => {
    setSelectedBlockIds(blockIds);
  }, []);

  return (
    <>
      <div className='flex px-6 py-8 w-full bg-black sticky top-0 z-5'>
        <div className='flex flex-row w-2/3 items-center'>
          <div>
            <span className='text-xl'>Blocks</span>
            <span className='text-secondary ml-1'>({game.blocksCount})</span>
          </div>
          <div className='text-secondary text-sms ml-2'>
            {game.recordingsCount} {pluralize('Block', game.recordingsCount)}{' '}
            Recorded
          </div>
        </div>
        {!ctx.embed && (
          <div className='absolute right-6 flex w-1/3 items-start justify-end'>
            {isSelectingBlocks ? (
              <div className='z-5 flex items-start'>
                <button
                  type='button'
                  className='outline-none focus:outline-none text-secondary mr-6 hover:text-white hover:underline mt-2'
                  onClick={handleDoneClick}
                >
                  Done
                </button>
                {!addBlockClicked && (
                  <button
                    type='button'
                    className='outline-none focus:outline-none text-white w-75 h-10 flex items-center rounded-xl border border-secondary px-3 disabled:text-secondary text-sms'
                    disabled={selectedBlockIds.length === 0}
                    onClick={() => setAddBlockClicked(true)}
                  >
                    <DownloadIcon />
                    <p className='ml-2'>Select target Minigame</p>
                    <p className='text-sms ml-auto'>▼</p>
                  </button>
                )}
                {addBlockClicked && (
                  <MyGameDropdown
                    src={game}
                    handleAdd={handleAddBlock}
                    handleCreate={handleCreateGame}
                  />
                )}
              </div>
            ) : (
              <button
                type='button'
                className='outline-none focus:outline-none hover:underline font-medium text-primary'
                onClick={() => setIsSelectingBlocks(true)}
              >
                Add Blocks to My Minigames
              </button>
            )}
          </div>
        )}
      </div>
      <div className='flex flex-col w-2/3 h-72 px-6'>
        <BlockList
          game={game}
          isSelecting={isSelectingBlocks}
          updateSelectedBlockIds={updateSelectedBlockIds}
        />
        <div className='w-full py-2.5'></div>
      </div>
    </>
  );
}

function MiniGamePack(props: { pack: GamePack }): JSX.Element | null {
  const { pack } = props;
  const navigate = useNavigate();
  const prefix = usePortalRoutePrefix();
  const user = useUser();

  const canNavigate = pack.isPrime ? true : pack.uid === user.id;

  const onClick = () => {
    if (!canNavigate) return;
    navigate(`/${prefix}/gamepacks?packId=${pack.id}`);
  };

  return (
    <div
      className={`flex items-center gap-4 ${
        canNavigate ? 'cursor-pointer' : 'cursor-not-allowed'
      }`}
      onClick={onClick}
    >
      <div className='w-43 flex-shrink-0'>
        <GamePackCover pack={pack} />
      </div>
      <div>
        <div className='text-sm font-bold'>{pack.name}</div>
        <div className='flex items-center gap-1 text-white text-xs'>
          <div>
            {pack.childrenIds?.length}{' '}
            {pluralize('Minigame', pack.childrenIds?.length)}
          </div>
          <span>·</span>
          <div>{pack.isPrime ? 'In' : 'Not In'} Library</div>
          <span>·</span>
          <div>{pack.isFeatured ? 'Featured' : 'Not Featured'}</div>
        </div>
        {!canNavigate && (
          <div className='text-secondary text-xs'>
            <div>
              This is a personal game pack belongs to {pack.creator.username}
            </div>
            <div>Pack Id: {pack.id}</div>
          </div>
        )}
      </div>
    </div>
  );
}

function LinkedGamePacks(props: { game: Game }): JSX.Element | null {
  const { game } = props;
  const { data: packs, isValidating } = useSWR(
    `/games/${game.id}/game-packs`,
    async () => {
      const resp = await apiService.game.getLinkedGamePacks(game.id);
      return resp.data.gamePacks;
    }
  );

  return (
    <div className='flex px-6 py-8 w-full bg-black sticky top-0 z-5'>
      {packs === undefined && isValidating ? (
        <Loading />
      ) : (
        <>
          {(packs ?? []).length === 0 && (
            <div className='text-sm'>No Linked Game Packs</div>
          )}
          <div className='flex flex-col gap-4'>
            {(packs ?? []).map((p) => (
              <MiniGamePack key={p.id} pack={p} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export const GameDetailCard = ({ game }: { game: Game }): JSX.Element => {
  const ctx = useMinigameContext();
  const store = useGameEditorStore();
  const [, setActiveGame] = useGameLikeWorkspace('game', 'active');
  const [menuKey, setMenuKey] = useState<'block-details' | 'linked-packs'>(
    'block-details'
  );

  const picked =
    ctx.embed && ctx.embedCtx.picked ? ctx.embedCtx.picked(game.id) : false;
  const actionSheetSettings = useGameLikeActionSheetSettings(
    game,
    'detail-card',
    ctx.pageType
  );

  const showEditButton = useShowGameLikeEditButton(game);
  const portalRoutePrefix = usePortalRoutePrefix();
  const handleLoadGame = useMakeHostViewAutoloadHandler(game);

  const onEditingGame = async () => {
    if (ctx.embed) {
      window.open(makeGameEditUrl(portalRoutePrefix, game.id), '_blank');
    } else {
      await store.setEditingGame(game, { fetchBlocks: true });
    }
  };

  const handleWithGamePack = () => {
    if (!ctx.embed) return;
    const fn = picked ? ctx.embedCtx.handleRemove : ctx.embedCtx.handleAdd;
    if (fn) fn(game);
    setActiveGame(null);
  };

  return (
    <div className='relative flex flex-col items-start justify-start text-white w-full h-full overflow-y-auto scrollbar'>
      <button
        type='button'
        className='btn-secondary w-30 h-10 absolute top-2 right-2 z-5'
        onClick={() => setActiveGame(null)}
      >
        Close
      </button>
      <div className='px-6 py-8 w-full border-b border-secondary relative'>
        <div className='flex w-full'>
          <div className='flex flex-col w-1/3'>
            <div className='aspect-w-3 aspect-h-2'>
              <GameCover game={game} className='w-full' />
            </div>
            <div className='flex flex-col mt-6'>
              {!ctx.embed && game.tags && (
                <ShowMore lines={3}>
                  <Tags routePrefix={ctx.routePrefix} tags={game.tags} />
                </ShowMore>
              )}
              <div className='text-secondary text-sms mt-3 whitespace-nowrap'>
                Last modified:{' '}
                {new Date(game.updatedAt).toLocaleDateString([], {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </div>
            </div>
          </div>
          <div className='flex flex-col w-2/3 ml-6'>
            <div className='text-2xl my-2'>{game.name}</div>
            <div className='text-sms my-2'>
              <span className='text-secondary'>Created by: </span>
              <span className='text-white'>{game.creator.username}</span>
            </div>
            <div>
              <ShowMore lines={3} responsive={{ xl: 6 }}>
                <div className='text-sms mt-2'>{game.description}</div>
              </ShowMore>
            </div>
            <div className='flex flex-grow items-end justify-end'>
              <div className='flex flex-row items-center'>
                {ctx.embed ? (
                  <>
                    <button
                      type='button'
                      className='btn-secondary h-10 flex items-center justify-center px-5 text-sms mr-5'
                      onClick={onEditingGame}
                    >
                      <EditIcon className='w-3.5 h-3.5 fill-current mr-2' />
                      <p>Edit</p>
                    </button>
                    <button
                      type='button'
                      className={`${
                        picked ? 'btn-delete' : 'btn-primary'
                      } h-10 flex items-center justify-center px-3 text-sms`}
                      onClick={handleWithGamePack}
                    >
                      {picked ? (
                        <DeleteIcon />
                      ) : (
                        <span className='text-xl font-medium'>+</span>
                      )}
                      <p className='ml-2'>
                        {picked ? 'Remove from' : 'Add to'} Game Pack
                      </p>
                    </button>
                  </>
                ) : (
                  <>
                    <GameActionSheet
                      game={game}
                      buttonClassName='w-7.5 h-7.5 mr-5'
                      settings={actionSheetSettings}
                    />
                    {showEditButton && (
                      <button
                        type='button'
                        className='btn-secondary h-10 flex items-center justify-center px-5 text-sms mr-5'
                        onClick={onEditingGame}
                      >
                        <EditIcon className='w-3.5 h-3.5 fill-current mr-2' />
                        <p>Edit</p>
                      </button>
                    )}
                    <button
                      type='button'
                      className='btn-primary h-10 flex items-center justify-center px-5 text-sms'
                      onClick={handleLoadGame}
                    >
                      <HostIcon className='w-3.5 h-3.5 fill-current mr-2' />
                      <p>Host Live</p>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='absolute bottom-0 flex items-center gap-4'>
          <button
            type='button'
            className={`btn rounded-none text-sm font-medium border-b ${
              menuKey === 'block-details'
                ? ' border-white cursor-default'
                : 'border-transparent'
            } `}
            onClick={() => {
              setMenuKey('block-details');
            }}
          >
            Block Details
          </button>
          <button
            type='button'
            className={`btn rounded-none text-sm font-medium border-b ${
              menuKey === 'linked-packs'
                ? ' border-white cursor-default'
                : 'border-transparent'
            }`}
            onClick={() => {
              setMenuKey('linked-packs');
            }}
          >
            Linked Game Packs
          </button>
        </div>
      </div>
      {menuKey === 'block-details' && <BlockManagement game={game} />}
      {menuKey === 'linked-packs' && <LinkedGamePacks game={game} />}
    </div>
  );
};

export const GameDetailCardModal = (): JSX.Element => {
  const [activeGame, setActiveGame] = useGameLikeWorkspace('game', 'active');
  const ref = useRef<HTMLDivElement>(null);
  const [creatingGame] = useGameLikeWorkspace('game', 'create');

  useOutsideClick(ref, () => {
    if (creatingGame) return;
    setActiveGame(null);
  });

  if (!activeGame) {
    return <></>;
  }
  return (
    <Modal borderStyle='gray' className='w-5/6 h-5/6 max-w-[1024px]'>
      <div ref={ref} className='w-full h-full'>
        <GameDetailCard game={activeGame} />
      </div>
    </Modal>
  );
};
