import { type DtoAssignedUser } from '@lp-lib/api-service-client/public';

import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { GreenCheckIcon } from '../../../icons/GreenCheckIcon';
import { XBoldIcon } from '../../../icons/XIcon';

interface AssignmentResultsModalProps {
  assignedUsers: DtoAssignedUser[];
  onClose: () => void;
}

export function AssignmentResultsModal({
  assignedUsers,
  onClose,
}: AssignmentResultsModalProps) {
  const successCount = assignedUsers.filter((user) => user.assigned).length;

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[500px]'
      innerClassName='bg-main-layer'
    >
      <div className='px-8 pt-8 pb-8 text-white flex flex-col gap-6'>
        <div className='text-2xl font-bold text-center'>
          Course Assignment Results
        </div>

        <div className='flex flex-col gap-4'>
          <div className='text-center text-lg'>
            {successCount === assignedUsers.length ? (
              <span className='text-cyan-500'>
                Successfully assigned to all users
              </span>
            ) : successCount === 0 ? (
              <span className='text-red-500'>
                Failed to assign to any users
              </span>
            ) : (
              <span>
                Assigned to {successCount} out of {assignedUsers.length} users
              </span>
            )}
          </div>

          <div className='bg-[#232325] rounded-lg max-h-60 overflow-y-auto'>
            {assignedUsers.map((user) => (
              <div
                key={user.userId}
                className='flex items-center justify-between p-3 border-b border-gray-700 last:border-0'
              >
                <span className='text-gray-300'>{user.email}</span>
                {user.assigned ? (
                  <GreenCheckIcon className='w-5 h-5 text-cyan-500' />
                ) : (
                  <XBoldIcon className='w-5 h-5 text-red-500' />
                )}
              </div>
            ))}
          </div>
        </div>

        <div className='flex justify-center'>
          <button
            onClick={onClose}
            className='px-6 py-2 bg-cyan-500 text-white rounded-lg hover:bg-cyan-600 transition-colors'
          >
            Close
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
