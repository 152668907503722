import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTitle } from 'react-use';
import { match } from 'ts-pattern';

import { EnumsOAuthProvider } from '@lp-lib/api-service-client/public';

import { useAuthAnalytics } from '../../analytics/auth';
import config from '../../config';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useQueryParam } from '../../hooks/useQueryParam';
import {
  REDIRECT_CUSTOM_TARGET_MAP,
  type RedirectRule,
  useExecUserRedirect,
} from '../../hooks/useRedirection';
import { apiService } from '../../services/api-service';
import { HttpStatusCode } from '../../types';
import { getStaticAssetPath } from '../../utils/assets';
import {
  assertExhaustive,
  err2code,
  err2s,
  makeTitle,
} from '../../utils/common';
import { isTokenValid } from '../../utils/token';
import { LegalDisclaimer } from '../common/Utilities';
import { GlobalLoading } from '../GlobalLoading';
import { GoogleIcon } from '../icons/GoogleIcon';
import { GreenCheckIcon } from '../icons/GreenCheckIcon';
import { SlackIcon2 } from '../icons/SlackIcon';
import { Loading } from '../Loading';
import { PortalLink } from '../PortalContext';
import { useIsUserLoaded, useUser } from '../UserContext';
import { AuthUtils, usePostLogin, useRedirectTo } from './hooks';
import { LoginHeader, LoginModalLayoutV2 } from './LoginModalLayout';
import { LoginModalPassword } from './LoginModalPassword';
import { type OAuthState, OAuthUtils } from './OAuth';
import {
  ALL_LOGIN_TYPES,
  EMAIL_PATTERN,
  type LoginType,
  type SetLoginType,
} from './types';

function useLoginType(): LoginType | null {
  const loginType = useQueryParam('login-type');
  if (!loginType) return null;
  const found = ALL_LOGIN_TYPES.find((t) => t === loginType);
  return found ?? null;
}

export const LoginModal = (): JSX.Element | null => {
  useTitle(makeTitle('Login'));

  const redirectTo = useRedirectTo();
  const postLogin = usePostLogin();
  const loginTypeFromSearchParams = useLoginType();
  const userNotFoundEmail = useQueryParam('user-not-found');
  const fromVenueLink = AuthUtils.FromPlayableLink(redirectTo);
  const initEmail = useQueryParam('email');
  const initAutoSubmit = useQueryParam('auto-submit');
  const user = useUser({ init: true });
  const isUserLoaded = useIsUserLoaded();
  const [next, setNext] = useState<'none' | 'redirect' | 'login'>('none');
  const execRedirect = useExecUserRedirect();

  const rules = useMemo<RedirectRule[]>(
    () => [
      {
        kind: 'admin',
        target: redirectTo || PortalLink.AdminHome,
      },
      {
        kind: 'organizer',
        target: redirectTo || PortalLink.OrganizerHome,
      },
      {
        kind: 'venueActivated',
        target: redirectTo || PortalLink.HostHome,
      },
      {
        kind: 'guest',
        target: REDIRECT_CUSTOM_TARGET_MAP.noop,
        clearToken: true,
      },
      { kind: 'final', target: redirectTo || config.app.homeUrlOrPathname },
    ],
    [redirectTo]
  );

  // the first time redirection, make sure user doesn't see the login form before this.
  useLayoutEffect(() => {
    if (!isUserLoaded) return;
    execRedirect(user, rules).then((r) => {
      setNext(r ? 'redirect' : 'login');
    });
  }, [execRedirect, isUserLoaded, rules, user]);

  // subsequent redirection after user logged in
  useEffect(() => {
    if (next !== 'login') return;
    execRedirect(user, rules);
  }, [execRedirect, next, rules, user]);

  const [loginType, setLoginType] = useState<LoginType | null>(
    loginTypeFromSearchParams
  );

  if (next !== 'login') return <GlobalLoading debug='login' />;

  switch (loginType) {
    case null:
    case 'google':
    case 'email':
      return (
        <LoginModalHome
          setLoginType={setLoginType}
          init={
            userNotFoundEmail
              ? {
                  email: userNotFoundEmail,
                  stage: fromVenueLink ? 'email-retry' : 'user-not-found',
                }
              : {
                  email: initEmail,
                  stage: 'default',
                  autoSubmit: initAutoSubmit === 'true',
                }
          }
        />
      );

    case 'password':
      return (
        <LoginModalPassword
          setLoginType={setLoginType}
          onLoginSuccess={postLogin}
        />
      );
    default:
      assertExhaustive(loginType);
      return null;
  }
};

const LoginButtonBase = ({
  text,
  onClick,
}: {
  icon: (props: { className?: string }) => JSX.Element;
  text: string;
  onClick: () => void;
}): JSX.Element => {
  return (
    <button
      type='button'
      onClick={onClick}
      className='w-1/2 h-12.5 flex items-center justify-center btn-secondary hover:btn-primary'
    >
      {/* {icon({ className: 'w-6 h-6 fill-current' })} */}
      <div>{text}</div>
    </button>
  );
};

type EmailFormData = {
  email: string;
};

type EmailLoginCallback = (result: {
  status: 'ok' | 'not-found';
  email: string;
}) => void;

function EmailLoginForm({
  email,
  onLogin,
  retrying,
  autoSubmit,
}: {
  email: string | null;
  onLogin: EmailLoginCallback;
  retrying?: boolean;
  autoSubmit?: boolean;
}) {
  const analytics = useAuthAnalytics();

  const { state, call } = useLiveAsyncCall(async (data: EmailFormData) => {
    const queries = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );
    return apiService.auth.verifyLogin({
      identify: data.email,
      webDomain: window.location.origin,
      queries,
      retry: retrying || false,
    });
  });

  const { register, handleSubmit, formState, setFocus } =
    useForm<EmailFormData>({
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
      defaultValues: {
        email: email ?? '',
      },
    });

  const onSubmit = handleSubmit(async (data: EmailFormData) => {
    analytics.trackEmailLoginFormSubmitted(data);

    const resp = await call(data);
    if (resp) onLogin({ status: 'ok', email: data.email });
    if (state.error && err2code(state.error) === HttpStatusCode.NOT_FOUND) {
      onLogin({ status: 'not-found', email: data.email });
    }
  });

  const doSubmit = useLiveCallback(() => onSubmit());

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  useLayoutEffect(() => {
    if (!autoSubmit) return;
    doSubmit();
  }, [autoSubmit, doSubmit]);

  return (
    <form
      onSubmit={onSubmit}
      className='w-full h-full flex flex-col justify-center items-center'
    >
      <label className='w-full flex flex-col items-start gap-1'>
        <p className={retrying ? 'font-bold' : ''}>
          {retrying
            ? 'Is this your work email address?'
            : 'Enter your work email address'}
        </p>
        <input
          className={`w-full h-12.5 ${
            formState.errors.email ? 'field-error' : 'field'
          } ${retrying ? 'text-tertiary font-medium text-xl' : ''} mb-0`}
          placeholder='Email Address'
          {...register('email', {
            required: true,
            pattern: EMAIL_PATTERN,
            maxLength: 60,
          })}
          maxLength={60}
        />
      </label>
      <div className='w-full text-xs font-medium text-red-002 text-left h-4'>
        {formState.errors.email
          ? 'Please enter a valid email address'
          : state.error &&
            err2code(state.error) !== HttpStatusCode.NOT_FOUND &&
            err2s(state.error)}
      </div>
      <button
        type='submit'
        className={`btn ${
          retrying ? 'btn-secondary  w-[80%]' : 'btn-primary  w-full'
        } h-10 flex items-center justify-center font-bold`}
        disabled={state.state.isRunning}
      >
        {state.state.isRunning && <Loading text='' containerClassName='mr-2' />}
        {retrying ? 'This is correct!' : 'Continue'}
      </button>
    </form>
  );
}

type Stage = 'default' | 'email-sent' | 'user-not-found' | 'email-retry';

const LoginModalHome = (props: {
  initStage?: Stage;

  init?: {
    email: string | null;
    stage: Stage;
    autoSubmit?: boolean;
  };
  setLoginType: SetLoginType;
}): JSX.Element => {
  const [email, setEmail] = useState<string | null>(props.init?.email ?? null);
  const [stage, setStage] = useState<Stage>(props.init?.stage ?? 'default');
  const redirectTo = useRedirectTo();
  const analytics = useAuthAnalytics();
  const [autoSubmit, setAutoSubmit] = useState(props.init?.autoSubmit ?? false);

  const handleEmailLogin = (result: Parameters<EmailLoginCallback>[0]) => {
    setAutoSubmit(false);

    setEmail(result.email);
    switch (result.status) {
      case 'ok':
        setStage('email-sent');
        break;
      case 'not-found':
        setStage(
          AuthUtils.FromPlayableLink(redirectTo)
            ? 'email-retry'
            : 'user-not-found'
        );
        break;
      default:
        assertExhaustive(result.status);
        break;
    }
  };

  const handleEmailRetryLogin = (result: Parameters<EmailLoginCallback>[0]) => {
    const wrongEmail = email;
    setEmail(result.email);
    switch (result.status) {
      case 'ok':
        setStage('email-sent');
        analytics.trackFailedLoginAttempt({
          wrongEmail: wrongEmail,
          fixedEmail: result.email,
          isEmailFixed: true,
          joinedAsGuest: false,
        });
        break;
      case 'not-found':
        setStage('user-not-found');
        break;
      default:
        assertExhaustive(result.status);
        break;
    }
  };

  const handleBack = () => {
    setStage('default');
  };

  return (
    <LoginModalLayoutV2>
      <div className='absolute text-white flex justify-center items-center'>
        <LoginHeader noSlogan={stage !== 'default'} />
        {match(stage)
          .with('default', () => (
            <DefaultStage
              email={email}
              setLoginType={props.setLoginType}
              handleEmailLogin={handleEmailLogin}
              autoSubmit={autoSubmit}
            />
          ))
          .with('email-sent', () => <EmailSentStage email={email} />)
          .with('user-not-found', () => (
            <NotFoundStage email={email} handleBack={handleBack} />
          ))
          .with('email-retry', () => (
            <EmailRetryStage
              email={email}
              handleBack={handleBack}
              handleEmailLogin={handleEmailRetryLogin}
            />
          ))
          .exhaustive()}
      </div>
    </LoginModalLayoutV2>
  );
};

function DefaultStage(props: {
  email: string | null;
  setLoginType: SetLoginType;
  handleEmailLogin: (result: Parameters<EmailLoginCallback>[0]) => void;
  autoSubmit?: boolean;
}) {
  const { email, setLoginType, handleEmailLogin, autoSubmit } = props;
  const analytics = useAuthAnalytics();
  const handleLoginWithOAuth = (provider: EnumsOAuthProvider) => {
    const state: OAuthState = {
      provider,
      scenario: 'login',
      search: window.location.search,
    };
    analytics.trackOAuthButtonClicked(state);
    window.location.href = OAuthUtils.GetURL(state);
  };

  return (
    <div
      className='w-max h-full flex flex-col items-center bg-black
    bg-opacity-80 border border-secondary rounded-2.25xl 
    pt-10 pb-2 px-5 lg:px-15 gap-10'
    >
      <div className='text-tertiary text-3.5xl'>Join the Fun!</div>

      <div className='flex flex-col items-center gap-4'>
        <div className='w-80 lg:w-100'>
          <EmailLoginForm
            email={email}
            onLogin={handleEmailLogin}
            autoSubmit={autoSubmit}
          />
        </div>

        <div className='w-full flex items-center justify-center gap-4'>
          <div className='h-px bg-lp-gray-005 w-full' />
          <div className='text-xs'>OR</div>
          <div className='h-px bg-lp-gray-005 w-full' />
        </div>

        <div className='w-full flex gap-2.5'>
          <LoginButtonBase
            icon={GoogleIcon}
            text='Login with Google'
            onClick={() =>
              handleLoginWithOAuth(EnumsOAuthProvider.OAuthProviderGoogle)
            }
          />
          <LoginButtonBase
            icon={SlackIcon2}
            text='Login with Slack'
            onClick={() =>
              handleLoginWithOAuth(EnumsOAuthProvider.OAuthProviderSlack)
            }
          />
        </div>
      </div>

      <div className='w-full flex flex-col gap-1'>
        <LegalDisclaimer text='By joining Luna Park' />
        <button
          type='button'
          onClick={() => setLoginType('password')}
          className='text-sms font-medium text-icon-gray'
        >
          Login with LP Account
        </button>
      </div>
    </div>
  );
}

function EmailSentStage(props: { email: string | null }) {
  const { email } = props;
  const redirectTo = useRedirectTo();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const timer = setInterval(async () => {
      if (isTokenValid()) {
        setLoggedIn(true);
        clearInterval(timer);
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleContinue = () => {
    window.location.replace(redirectTo ?? '/home');
  };

  return (
    <div
      className='w-full h-full flex flex-col items-center bg-black 
    bg-opacity-80 border border-secondary rounded-2.25xl 
    pt-10 pb-16 px-5 lg:px-15 gap-10'
    >
      {loggedIn ? (
        <div className='w-80 lg:w-100 flex flex-col items-center justify-center gap-4'>
          <GreenCheckIcon />
          <div className='font-bold'>Successfully logged in!</div>
          <div className='text-sms'>
            You may now access the full Luna Park experience.
          </div>

          <button
            type='button'
            className='mt-6 btn btn-primary w-full h-10 flex justify-center items-center'
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      ) : (
        <div className='w-80 lg:w-100 flex flex-col items-center justify-center gap-4'>
          <img
            src={getStaticAssetPath('images/email-sent.png')}
            alt='email-sent'
            className='w-40 h-40'
          />
          <div className='font-bold text-center'>Check Your Inbox</div>
          <div className='text-sms text-center mt-4'>
            A magic link has been sent to {email ?? 'your email'}. <br />
            Click the link to login.
          </div>
        </div>
      )}
    </div>
  );
}

function NotFoundStage(props: {
  email: string | null;
  handleBack: () => void;
}) {
  const { email, handleBack } = props;
  return (
    <div
      className='w-max h-full flex flex-col items-center bg-black
        bg-opacity-80 border border-secondary rounded-2.25xl 
        py-10 px-5 lg:px-15 gap-10'
    >
      <div className='w-80 lg:w-100 flex flex-col items-center justify-center gap-4'>
        <div className='text-3.5xl font-bold'>Bummer!</div>
        <div className='font-bold text-center'>
          {email ?? 'This email'} has not been added to this account.
        </div>
        <div className='text-sms text-center mt-4'>
          If you think you entered the wrong address, click the button below to
          try again.
        </div>
        <button
          type='button'
          className='w-full h-12.5 flex items-center justify-center btn-secondary'
          onClick={handleBack}
        >
          Back
        </button>
      </div>
    </div>
  );
}

function EmailRetryStage(props: {
  email: string | null;
  handleBack: () => void;
  handleEmailLogin: (result: Parameters<EmailLoginCallback>[0]) => void;
}) {
  const { email, handleBack, handleEmailLogin } = props;
  return (
    <div
      className='w-max h-full flex flex-col items-center bg-black
    bg-opacity-80 border border-secondary rounded-2.25xl 
    py-12 px-5 gap-10'
    >
      <div className='w-100 lg:w-120 flex flex-col items-center justify-center gap-5'>
        <div className='w-[90%] text-xl lg:text-2xl font-bold text-center'>
          Hmmm ... we don’t recognize that address. Let’s double check.
        </div>
        <div className='w-100 lg:w-120'>
          <EmailLoginForm email={email} onLogin={handleEmailLogin} retrying />
        </div>
        <button
          type='button'
          className='w-full flex items-center justify-center text-sms text-secondary'
          onClick={handleBack}
        >
          Back to login
        </button>
      </div>
    </div>
  );
}
