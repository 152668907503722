export function DocumentIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-4.5 h-4.5 fill-current'}
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.63298 0C2.33631 0 1.28516 1.04467 1.28516 2.33333V15.6667C1.28516 16.9553 2.33631 18 3.63298 18H14.3659C15.6626 18 16.7137 16.9553 16.7137 15.6667V4.80104C16.7137 4.27121 16.5323 3.75715 16.1992 3.34342L14.2128 0.875712C13.7673 0.322209 13.0927 0 12.3795 0H3.63298ZM3.29758 2.33333C3.29758 2.14924 3.44774 2 3.63298 2H12.3795C12.4814 2 12.5777 2.04603 12.6414 2.1251L14.6278 4.59281C14.6754 4.65191 14.7013 4.72535 14.7013 4.80104V15.6667C14.7013 15.8508 14.5511 16 14.3659 16H3.63298C3.44774 16 3.29758 15.8508 3.29758 15.6667V2.33333ZM5.296 7.23077C5.296 6.67848 5.71045 6.23077 6.22171 6.23077H11.776C12.2873 6.23077 12.7017 6.67848 12.7017 7.23077C12.7017 7.78305 12.2873 8.23077 11.776 8.23077H6.22171C5.71045 8.23077 5.296 7.78305 5.296 7.23077ZM5.296 11.2308C5.296 10.6785 5.71045 10.2308 6.22171 10.2308H11.776C12.2873 10.2308 12.7017 10.6785 12.7017 11.2308C12.7017 11.7831 12.2873 12.2308 11.776 12.2308H6.22171C5.71045 12.2308 5.296 11.7831 5.296 11.2308Z'
      />
    </svg>
  );
}
