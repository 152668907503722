import '../../../components/GameV2/design/styles.css';

import { useNavigate } from '@remix-run/react';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useLatest } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import {
  type DtoBlock,
  type DtoGame,
  type DtoGamePack,
  type DtoSendTrainingEditorMessageRequest,
  EnumsGamePackChangeLevel,
  type ModelsLogicSettings,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import { useLearningAnalytics } from '../../../analytics/learning';
import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { fromDTOBlocks, fromDTOGame } from '../../../utils/api-dto';
import { sleep, uuidv4 } from '../../../utils/common';
import { markSnapshottable } from '../../../utils/valtio';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { GameEditorStore } from '../../Game/GameEditorStore';
import { UGCFileManagerProvider } from '../../Game/UGC';
import { BlockAnimator } from '../../GameV2/apis/BlockAnimationControl';
import { ArrowLeftIcon } from '../../icons/Arrows';
import { PlayIcon } from '../../icons/PlayIcon';
import { RenderIcon } from '../../icons/RenderIcon';
import { SettingIcon } from '../../icons/SettingIcon';
import { useOpenScormExportModal } from '../ScormExport';
import { LogicEditor } from './LogicEditor';
import {
  AIChatBackground,
  AIChatHeader,
  TrainingEditorAIChatSidebar,
} from './TrainingEditorAIChat';
import { TrainingEditorDetails } from './TrainingEditorDetail';
import { TrainingEditorSettings } from './TrainingEditorSettings';
import { TrainingEditorSidebar } from './TrainingEditorSidebar';
import { TrainingPreview } from './TrainingPreview';
import {
  type TrainingEditorAIChatStatus,
  type TrainingEditorState,
} from './types';
import { TrainingEditorUtils } from './utils';

function BetaBadge(props: { className?: string }) {
  return (
    <div
      className={`w-12.5 h-3.5 uppercase bg-lp-red-002 text-white text-sms 
      font-bold flex items-center justify-center rounded-sm ${
        props.className ?? ''
      }`}
    >
      beta
    </div>
  );
}

function NameField(props: {
  value: string;
  onChange: (value: string) => void;
}) {
  const { value, onChange } = props;

  const [editing, setEditing] = useState(false);

  if (!editing)
    return (
      <button
        type='button'
        className='hover:text-white'
        onClick={() => setEditing(true)}
      >
        {value}
      </button>
    );

  return (
    <input
      className='field mb-0 w-50 h-8'
      defaultValue={value}
      onBlur={(e) => {
        onChange(e.target.value);
        setEditing(false);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      }}
      autoFocus
    />
  );
}

function BlockWatcher(props: { store: GameEditorStore; block: Block }) {
  const { store, block } = props;

  const isRendering = TrainingEditorUtils.IsBlockRendering(block);
  const refreshDialogue = useLiveCallback(async () => {
    const resp = await apiService.block.getBlock(block.id);
    const updatedBlock = resp.data.block;
    store.blockEditorStore.setBlockField({
      blockId: updatedBlock.id,
      blockField: {
        dialogue: updatedBlock.fields.dialogue,
      },
    });
  });

  useEffect(() => {
    if (!isRendering) return;

    let intervalId: ReturnType<typeof setInterval> | null = null;
    const timer = setTimeout(() => {
      intervalId = setInterval(refreshDialogue, 30000);
    }, 60000);

    return () => {
      clearTimeout(timer);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRendering, refreshDialogue]);

  return null;
}

function SlideGroupWatcher(props: {
  store: GameEditorStore;
  onRequiresRenderChange: (value: boolean) => void;
  onRenderingChange: (value: boolean) => void;
}) {
  const { store, onRequiresRenderChange, onRenderingChange } = props;
  const blocks = useSnapshot(store.blockEditorStore.state).blocks;

  const requiresRender = useMemo(
    () =>
      blocks.some((b) => TrainingEditorUtils.IsBlockRenderRequired(b as Block)),
    [blocks]
  );
  const isRendering = useMemo(
    () => blocks.some((b) => TrainingEditorUtils.IsBlockRendering(b as Block)),
    [blocks]
  );

  const onRequiresRenderChangeRef = useLatest(onRequiresRenderChange);
  useEffect(() => {
    onRequiresRenderChangeRef.current(requiresRender);
  }, [requiresRender, onRequiresRenderChangeRef]);
  const onRenderingChangeRef = useLatest(onRenderingChange);
  useEffect(() => {
    onRenderingChangeRef.current(isRendering);
  }, [isRendering, onRenderingChangeRef]);

  return (
    <>
      {blocks.map((block) => (
        <BlockWatcher key={block.id} store={store} block={block as Block} />
      ))}
    </>
  );
}

function Watcher(props: {
  stores: GameEditorStore[];
  onRequiresRenderChange: (value: boolean) => void;
  onRenderingChange: (value: boolean) => void;
}) {
  const { stores, onRequiresRenderChange, onRenderingChange } = props;
  const requiresRenderSet = useInstance(() => new Set<string>());
  const isRenderingSet = useInstance(() => new Set<string>());

  const handleRenderAvatars = (id: string, value: boolean) => {
    if (value) {
      requiresRenderSet.add(id);
    } else {
      requiresRenderSet.delete(id);
    }

    onRequiresRenderChange(requiresRenderSet.size > 0);
  };
  const handleRendering = (id: string, value: boolean) => {
    if (value) {
      isRenderingSet.add(id);
    } else {
      isRenderingSet.delete(id);
    }
    onRenderingChange(isRenderingSet.size > 0);
  };

  return (
    <>
      {stores.map((store) => (
        <SlideGroupWatcher
          key={store.state.game?.id}
          store={store}
          onRequiresRenderChange={(value) =>
            handleRenderAvatars(store.state.game?.id ?? '', value)
          }
          onRenderingChange={(value) =>
            handleRendering(store.state.game?.id ?? '', value)
          }
        />
      ))}
    </>
  );
}

function PlayButton(props: {
  onClick: () => void;
  showPreviewTooltip?: boolean;
}) {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: null,
      interactive: false,
      visible: !!props.showPreviewTooltip,
    });

  const onClick = () => {
    props.onClick();
  };
  return (
    <>
      <button
        ref={setTriggerRef}
        type='button'
        className='text-icon-gray hover:text-white'
        onClick={onClick}
      >
        <PlayIcon className='w-6 h-6 fill-current text-icon-gray hover:text-white' />
      </button>
      {visible &&
        createPortal(
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: `animate-pulse text-sms font-bold text-black relative flex items-center justify-center w-[212px] h-[37px]`,
            })}
          >
            <svg
              className='absolute inset-0 z-0'
              xmlns='http://www.w3.org/2000/svg'
              width='212'
              height='37'
              viewBox='0 0 212 37'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M111.163 7L106.5 0L101.837 7H8C3.58172 7 0 10.5817 0 15V29C0 33.4183 3.58172 37 7.99999 37H204C208.418 37 212 33.4183 212 29V15C212 10.5817 208.418 7 204 7H111.163Z'
                fill='#FBB707'
              />
            </svg>
            <p className='z-[1] mt-1.5'>Click to Preview Your Course</p>
          </div>,
          document.body
        )}
    </>
  );
}

function SidebarContainer(props: {
  aiChatStatus: TrainingEditorAIChatStatus;
  slidesNav: React.ReactNode;
  aiChatSidebar: React.ReactNode;
}) {
  const { aiChatStatus, slidesNav, aiChatSidebar } = props;

  const slidesNavRef = useRef<HTMLDivElement>(null);
  const aiChatSidebarRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (aiChatStatus !== 'opening') return;

    slidesNavRef.current?.animate(
      [{ transform: 'translateX(0)' }, { transform: 'translateX(-100%)' }],
      { duration: 500, fill: 'forwards' }
    );
    aiChatSidebarRef.current?.style.setProperty('opacity', '0');
    aiChatSidebarRef.current?.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: 200,
      fill: 'forwards',
      delay: 500,
    });
  }, [aiChatStatus]);

  useLayoutEffect(() => {
    if (aiChatStatus !== 'closing') return;

    slidesNavRef.current?.animate(
      [{ transform: 'translateX(-100%)' }, { transform: 'translateX(0)' }],
      { duration: 500, fill: 'forwards' }
    );
    aiChatSidebarRef.current?.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 500,
      fill: 'forwards',
    });
  }, [aiChatStatus]);

  return (
    <div className='flex-none w-72 h-full relative z-10'>
      {aiChatStatus !== 'open' && (
        <div ref={slidesNavRef} className={`absolute inset-0`}>
          {slidesNav}
        </div>
      )}

      {aiChatStatus !== 'closed' && (
        <div ref={aiChatSidebarRef} className={`absolute inset-0`}>
          {aiChatSidebar}
        </div>
      )}
    </div>
  );
}

export class TrainingEditorControlAPI {
  private _state = markSnapshottable(
    proxy<TrainingEditorState>({
      aiChatStatus: 'closed',
      aiChatMessageSending: false,
      blockDirtyKey: null,
      previewBlock: null,
    })
  );

  get state() {
    return this._state;
  }

  async openAIChat() {
    this.clearPreviewBlock();

    this.state.aiChatStatus = 'opening';
    await sleep(700);
    this.state.aiChatStatus = 'open';
  }

  async closeAIChat() {
    this.clearPreviewBlock();

    this.state.aiChatStatus = 'closing';
    await sleep(500);
    this.state.aiChatStatus = 'closed';
  }

  async sendAIChatMessage(
    blockId: string,
    dto: DtoSendTrainingEditorMessageRequest
  ) {
    this.clearPreviewBlock();

    try {
      this.state.aiChatMessageSending = true;
      const resp = await apiService.training.sendEditorChatMessage(
        blockId,
        dto
      );
      return resp.data;
    } finally {
      this.state.aiChatMessageSending = false;
    }
  }

  async clearAIChat(blockId: string) {
    await apiService.training.deleteEditorThread(blockId);
  }

  markCurrentBlockDirty() {
    this.state.blockDirtyKey = uuidv4();
  }

  previewBlock(block: Block) {
    this.state.previewBlock = block;
  }

  clearPreviewBlock() {
    this.state.previewBlock = null;
  }
}

export function TrainingEditor(props: {
  pack: DtoGamePack;
  games: DtoGame[];
  blocks: DtoBlock[];
  showPreviewTooltip?: boolean;
}) {
  const { games, blocks } = props;

  const navigate = useNavigate();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const analytics = useLearningAnalytics();

  const controlAPI = useInstance(() => new TrainingEditorControlAPI());
  const state = useSnapshot(controlAPI.state);

  const [pack, setPack] = useState(props.pack);

  const [stores, setStores] = useState<GameEditorStore[]>(() => {
    return games.map((game) => {
      const store = new GameEditorStore();
      store.setEditingGame(fromDTOGame(game), {
        blocks: fromDTOBlocks(blocks.filter((b) => b.gameId === game.id)) || [],
      });
      store.setSelectedBlockId(null);
      return store;
    });
  });
  const [selectedGameId, setSelectedGameId] = useState<string | null>(() =>
    games.length > 0 ? games[0].id : null
  );
  const selectedStore = stores.find((s) => s.state.game?.id === selectedGameId);
  const [requiresRender, setRequiresRender] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [animator] = useState(() => new BlockAnimator<string>({}, () => null));
  const showBlockLogic = useFeatureQueryParam('show-block-logic');
  const openScormExportModal = useOpenScormExportModal();
  const [showPreviewTooltip, setShowPreviewTooltip] = useState(
    props.showPreviewTooltip
  );

  const handleChangeName = async (value: string) => {
    const resp = await apiService.gamePack.update(pack.id, {
      name: value,
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
    });
    setPack(resp.data.gamePack);
  };

  const {
    call: handleRenderAvatars,
    state: {
      state: { isRunning: isRenderRequesting },
    },
  } = useLiveAsyncCall(async () => {
    const blockIds = stores.flatMap((s) =>
      s.blockEditorStore.state.blocks
        .filter((block) => TrainingEditorUtils.IsBlockRenderRequired(block))
        .map((block) => block.id)
    );

    const { result } = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='p-5 text-white'>
          <div className='text-2xl font-medium text-center'>
            Render Avatar Videos?
          </div>
          <div className='my-4 text-sms'>
            Rendering avatars will take a few minutes. You can continue to edit
            the other parts of your game while the avatars render.
            <br />
            <br />
            Slides with Avatars: {blockIds.length}
          </div>
        </div>
      ),
      confirmBtnLabel: 'Continue',
      confirmBtnVariant: 'primary',
      cancelBtnLabel: 'Cancel',
    });
    if (result === 'canceled') return;

    const resp = await apiService.block.renderBlocks({ blockIds });
    for (const block of resp.data.blocks) {
      const store = stores.find((s) => s.state.game?.id === block.gameId);
      if (!store) continue;
      store.blockEditorStore.setBlockField({
        blockId: block.id,
        blockField: {
          dialogue: block.fields.dialogue,
        },
      });
    }
  });

  const handleCourseSettingsEdit = useLiveCallback(async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => {
        return (
          <TrainingEditorSettings
            pack={pack}
            onCancel={p.internalOnCancel}
            onSave={async (change) => {
              p.internalOnConfirm();
              const resp = await apiService.gamePack.update(pack.id, change);
              setPack(resp.data.gamePack);
            }}
          />
        );
      },
    });
  });

  const handleLogicEdit = useLiveCallback(async () => {
    const blocks = stores.flatMap((s) => s.blockEditorStore.state.blocks);
    const miniGameNames = new Map<string, string>();
    stores.forEach(
      (s) =>
        s.state.game && miniGameNames.set(s.state.game.id, s.state.game.name)
    );

    await triggerModal({
      kind: 'custom',
      element: (p) => {
        return (
          <LogicEditor
            defaultValue={pack.logicSettings}
            blocks={blocks}
            blockId={selectedStore?.state.selectedBlockId}
            minigameNames={miniGameNames}
            onCancel={p.internalOnCancel}
            onSave={async (logicSettings: ModelsLogicSettings) => {
              p.internalOnConfirm();
              const resp = await apiService.gamePack.update(pack.id, {
                logicSettings,
                changeLevel:
                  EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
              });
              setPack(resp.data.gamePack);
            }}
          />
        );
      },
    });
  });

  if (isPreviewing) {
    return (
      <TrainingPreview
        pack={pack}
        stores={stores}
        selectedStore={selectedStore}
        defaultDevice='desktop'
        onClose={() => setIsPreviewing(false)}
        analytics={analytics}
      />
    );
  }

  return (
    <div className='w-full h-full bg-layer-001 px-2.5 py-1.5 flex flex-col gap-2'>
      <Watcher
        stores={stores}
        onRequiresRenderChange={setRequiresRender}
        onRenderingChange={setIsRendering}
      />

      <header className='w-full h-15 px-7.5 flex-none bg-main-layer rounded-xl grid grid-cols-3'>
        <div className='flex items-center gap-2 text-sms text-icon-gray'>
          <button
            type='button'
            className='hover:text-white'
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className='w-4 h-4 fill-current' />
          </button>

          <NameField value={pack.name} onChange={handleChangeName} />
          <BetaBadge />
        </div>
        <div className='flex justify-center items-center gap-5'>
          <button
            type='button'
            className='text-icon-gray hover:text-white'
            onClick={handleCourseSettingsEdit}
          >
            <SettingIcon className='w-6 h-6 fill-current' />
          </button>

          {showBlockLogic && (
            <button
              type='button'
              className='text-icon-gray hover:text-white'
              onClick={handleLogicEdit}
            >
              <svg
                className='w-6 h-6 fill-current'
                xmlns='http://www.w3.org/2000/svg'
                width='32'
                height='32'
                viewBox='0 0 256 256'
              >
                <path d='M232,64a32,32,0,1,0-40,31v17a8,8,0,0,1-8,8H96a23.84,23.84,0,0,0-8,1.38V95a32,32,0,1,0-16,0v66a32,32,0,1,0,16,0V144a8,8,0,0,1,8-8h88a24,24,0,0,0,24-24V95A32.06,32.06,0,0,0,232,64ZM64,64A16,16,0,1,1,80,80,16,16,0,0,1,64,64ZM96,192a16,16,0,1,1-16-16A16,16,0,0,1,96,192ZM200,80a16,16,0,1,1,16-16A16,16,0,0,1,200,80Z'></path>
              </svg>
            </button>
          )}
          <PlayButton
            onClick={() => {
              setShowPreviewTooltip(false);
              setIsPreviewing(true);
            }}
            showPreviewTooltip={showPreviewTooltip}
          />
        </div>
        <div className='flex justify-end items-center gap-6'>
          {(isRendering || isRenderRequesting) && (
            <div className='flex flex-col items-center gap-1'>
              <div className='text-3xs font-medium text-white'>
                Rendering ... check again in a few minutes.
              </div>
            </div>
          )}
          {isRendering || isRenderRequesting || requiresRender ? (
            <button
              type='button'
              className='btn-primary w-50 h-10 flex justify-center items-center gap-1'
              onClick={handleRenderAvatars}
              disabled={isRenderRequesting || isRendering}
            >
              <RenderIcon className='w-4 h-4 fill-current' />
              Render Avatars
            </button>
          ) : (
            <>
              <button
                type='button'
                className='btn text-secondary text-sms underline w-40 h-10'
                onClick={() => {
                  openScormExportModal(pack);
                }}
              >
                Download SCORM
              </button>
              <button
                type='button'
                className='btn-secondary w-30 h-10'
                onClick={() => {
                  navigate(-1);
                }}
              >
                Done
              </button>
            </>
          )}
        </div>
      </header>

      <main className={`relative w-full flex-1 overflow-hidden rounded-xl`}>
        <AIChatBackground aiChatStatus={state.aiChatStatus} />

        <div
          className={`relative z-5 w-full h-full rounded-xl flex gap-4 ${
            state.aiChatStatus === 'opening' || state.aiChatStatus === 'open'
              ? 'p-3'
              : 'p-0'
          } transition-padding duration-500`}
        >
          <SidebarContainer
            aiChatStatus={state.aiChatStatus}
            slidesNav={
              <TrainingEditorSidebar
                pack={pack}
                stores={stores}
                onStoresChange={setStores}
                selectedGameId={selectedGameId}
                onSelectedGameIdChange={setSelectedGameId}
                onPackChange={setPack}
                analytics={analytics}
              />
            }
            aiChatSidebar={
              selectedStore ? (
                <UGCFileManagerProvider>
                  <TrainingEditorAIChatSidebar
                    pack={pack}
                    store={selectedStore}
                    ctrl={controlAPI}
                  />
                </UGCFileManagerProvider>
              ) : null
            }
          />

          <div className='flex-1 h-full flex flex-col'>
            <AIChatHeader aiChatStatus={state.aiChatStatus} ctrl={controlAPI} />

            <div className='w-full flex-1 overflow-hidden'>
              {selectedStore && (
                <TrainingEditorDetails
                  pack={pack}
                  stores={stores}
                  store={selectedStore}
                  animator={animator}
                  analytics={analytics}
                  state={state as TrainingEditorState}
                  ctrl={controlAPI}
                />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
