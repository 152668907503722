import { useEffect, useRef } from 'react';
import { match } from 'ts-pattern';
import { proxy } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsFillInTheBlanksSegmentType,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import {
  type FillInTheBlanksBlock,
  QuestionBlockAnswerGrade,
  type QuestionBlockAnswerGradeModel,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { getFeatureQueryParamNumber } from '../../../../hooks/useFeatureQueryParam';
import { apiService } from '../../../../services/api-service';
import { assertExhaustive } from '../../../../utils/common';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
} from '../../../../utils/valtio';
import { LVOLocalPlayer } from '../../../VoiceOver/LocalLocalizedVoiceOvers';
import { CommonButton } from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import { CommonInput, type CommonInputVariant } from '../../design/Input';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';

function getEvaluationPrompt(
  block: FillInTheBlanksBlock,
  blanks: Blank[],
  result: GameResult
) {
  const question = block.fields.segments
    .map((s) =>
      s.type === EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank
        ? '___'
        : s.text
    )
    .join(' ');

  const answersJson = JSON.stringify(
    blanks.map((b) => ({
      expected: b.answer,
      submitted: b.submission,
      result: b.result,
    }))
  );

  return `
\`\`\`openai { "model": "gpt-4o-mini", "temperature": 1, "maxTokens": 1024 }
You are a scriptwriter for an online platform called Luna Park. Your only job is to write a script that informs a user if they answered correctly, partially correctly, or incorrectly in a fill in the blanks game.

I will give you the question, the blanks, and the final result. In every blank, I will give you the expected answer, the user's submitted answer, and how we have evaluated it.

Your script should be short and creative.

When the evaluation is incorrect, use only the provided question and answer to restate what the user missed. Do not use any other information or knowledge for this script.  
When the evaluation is correct, your script can be short and does not need to restate the answer.

Output only the script, with nothing before or after.

Question: ${question}
Blanks: ${answersJson}
Final Result: ${result}
\`\`\`
`.trim();
}

type GradeResult = {
  model?: QuestionBlockAnswerGradeModel;
  grade: QuestionBlockAnswerGrade;
  timeMs: number;
  error?: unknown;
  cached?: boolean;
};

interface Grader {
  grade(submission: string): Promise<GradeResult>;
}

class SimpleGrader implements Grader {
  constructor(private correctAnswers: string[]) {}

  async grade(submission: string): Promise<GradeResult> {
    const trimmedAnswer = submission.trim().toLowerCase();
    if (this.correctAnswers.some((correct) => correct === trimmedAnswer)) {
      return {
        model: 'Preset',
        grade: QuestionBlockAnswerGrade.CORRECT,
        timeMs: 0,
      };
    }
    return {
      model: 'Preset',
      grade: QuestionBlockAnswerGrade.NONE,
      timeMs: 0,
    };
  }
}

class AIGrader implements Grader {
  constructor(
    private model: QuestionBlockAnswerGradeModel,
    private temperature: number,
    private correctAnswers: string[]
  ) {}

  async grade(submission: string): Promise<GradeResult> {
    const trimmedAnswer = submission.trim().toLowerCase();
    const t1 = Date.now();
    const req = {
      model: this.model,
      temperature: this.temperature,
      correctAnswers: this.correctAnswers,
      userSubmission: trimmedAnswer,
    };
    try {
      const resp = await apiService.aiGrade.gradeSubmission(req);
      return {
        model: this.model,
        grade: resp.data.evaluation
          ? QuestionBlockAnswerGrade.CORRECT
          : QuestionBlockAnswerGrade.NONE,
        timeMs: Date.now() - t1,
        error: null,
      };
    } catch (error) {
      return {
        model: this.model,
        grade: QuestionBlockAnswerGrade.NONE,
        timeMs: Date.now() - t1,
        error,
      };
    }
  }
}

class GraderPipeline implements Grader {
  constructor(private graders: Grader[]) {}

  static FromBlank(blank: Blank) {
    const temperature = getFeatureQueryParamNumber(
      'question-block-ai-grade-temperature',
      true
    );
    const graders: Grader[] = [];
    graders.push(new SimpleGrader([blank.answer]));
    graders.push(new AIGrader('GPTv3', temperature, [blank.answer]));
    graders.push(new AIGrader('GPTv4', temperature, [blank.answer]));
    return new GraderPipeline(graders);
  }

  async grade(submission: string): Promise<GradeResult> {
    const trimmedAnswer = submission.trim().toLowerCase();
    const results: GradeResult[] = [];
    for (const grader of this.graders) {
      const r = await grader.grade(trimmedAnswer);
      results.push(r);
      if (r.grade !== QuestionBlockAnswerGrade.NONE) {
        break;
      }
    }
    return (
      results[results.length - 1] ?? {
        model: 'Preset',
        grade: QuestionBlockAnswerGrade.NONE,
      }
    );
  }
}

type Blank = {
  index: number;
  segmentId: string;
  answer: string;
  submission: string;
  result: 'correct' | 'incorrect' | null;
};

type GameResult = 'correct' | 'incorrect' | 'partiallyCorrect';

type GameState = {
  status: 'init' | 'present' | 'play' | 'grade' | 'inform' | 'complete';
  blanks: Blank[];
  blankIndex: number;

  showResult: boolean;
  questionVisible: boolean;
  result: GameResult | null;
};

export class FIBTypingControlAPI implements IBlockCtrl {
  private _state: ValtioSnapshottable<GameState>;

  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;

  constructor(
    private block: FillInTheBlanksBlock,
    private deps: BlockDependencies
  ) {
    this.logger = deps.getLogger('fib-typing');

    const blanks: Blank[] = block.fields.segments
      .filter(
        (s) =>
          s.type ===
          EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank
      )
      .map((s, index) => ({
        index,
        segmentId: s.id,
        answer: s.text,
        submission: '',
        result: null,
      }));

    const blankIndex = 0;

    this._state = markSnapshottable(
      proxy<GameState>({
        status: 'init',
        blanks,
        blankIndex,
        questionVisible: false,
        result: null,
        showResult: false,
      })
    );
  }

  get state() {
    return this._state;
  }

  async preload() {
    // nothing to preload
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    this._state.status = 'present';
    this._state.questionVisible = true;

    this._state.status = 'play';
  }

  updateSubmission(blankIndex: number, value: string) {
    this.state.blanks[blankIndex].submission = value;
  }

  nextBlank() {
    this.state.blankIndex =
      (this.state.blankIndex + 1) % this.state.blanks.length;
    console.log('nextBlank', this.state.blankIndex);
  }

  setBlankIndex(index: number) {
    this.state.blankIndex = index;
  }

  async submit() {
    this.state.status = 'grade';

    await Promise.all(
      this.state.blanks.map(async (blank) => {
        const grader = GraderPipeline.FromBlank(blank);
        const result = await grader.grade(blank.submission);
        blank.result =
          result.grade === QuestionBlockAnswerGrade.CORRECT
            ? 'correct'
            : 'incorrect';
      })
    );
    this.state.result = this.state.blanks.every((b) => b.result === 'correct')
      ? 'correct'
      : this.state.blanks.some((b) => b.result === 'correct')
      ? 'partiallyCorrect'
      : 'incorrect';

    this.state.status = 'inform';
    this.state.showResult = true;
    switch (this.state.result) {
      case 'correct':
        this.deps.sfxControl.play('rapidCorrect');
        const correctReq = await this.makeTTSRenderRequest(
          getEvaluationPrompt(this.block, this.state.blanks, 'correct')
        );
        await this.playTTS(correctReq);
        break;
      case 'partiallyCorrect':
        this.deps.sfxControl.play('rapidDuplicate');
        const partialReq = await this.makeTTSRenderRequest(
          getEvaluationPrompt(this.block, this.state.blanks, 'partiallyCorrect')
        );
        await this.playTTS(partialReq);
        break;
      case 'incorrect':
        this.deps.sfxControl.play('rapidWrong');
        const incorrectReq = await this.makeTTSRenderRequest(
          getEvaluationPrompt(this.block, this.state.blanks, 'incorrect')
        );
        await this.playTTS(incorrectReq);
        break;
    }

    this._state.status = 'complete';
  }

  async end() {
    await this.delegate?.blockDidEnd();
  }

  // private async preloadTTS(script: string) {
  //   const req = await this.makeTTSRenderRequest(script);
  //   if (!req) return null;
  //   await lvoLocalCacheWarm(req);
  //   return req;
  // }

  private async playTTS(req: Nullable<DtoTTSRenderRequest>) {
    if (!req) return;

    try {
      const player = new LVOLocalPlayer(req);
      const info = await player.playFromPool();
      await info?.trackEnded;
    } catch (e) {
      this.logger.error('failed to play TTS', e);
    }
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }
}

function BlankInput(props: {
  blank: Blank;
  active: boolean;
  submittable: boolean;
  disabled: boolean;
  showResult: boolean;
  isLastBlank: boolean;
  ctrl: FIBTypingControlAPI;
}) {
  const {
    blank,
    active,
    submittable,
    disabled,
    showResult,
    isLastBlank,
    ctrl,
  } = props;

  const ref = useRef<HTMLInputElement | null>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    console.log('enter');

    if (isLastBlank && submittable) {
      ctrl.submit();
      return;
    }

    ctrl.nextBlank();
  };

  let variant: CommonInputVariant = 'brand';
  if (showResult) {
    variant = blank.result === 'correct' ? 'correct' : 'incorrect';
  }

  useEffect(() => {
    if (active) {
      console.log('focus', !!ref.current);
      ref.current?.focus();
    }
  }, [active]);

  return (
    <CommonInput
      ref={ref}
      type='text'
      placeholder=''
      value={blank.submission}
      onChange={(e) => ctrl.updateSubmission(blank.index, e.target.value)}
      disabled={disabled}
      onKeyDown={(e) => handleKeyDown(e)}
      onFocus={() => ctrl.setBlankIndex(blank.index)}
      variant={variant}
      styles={{
        spacing: 'px-0 py-0',
      }}
      className={`${
        showResult
          ? match(blank.result)
              .with('correct', () => 'text-green-001')
              .with('incorrect', () => 'text-red-001')
              .otherwise(() => '')
          : ''
      } font-normal`}
      style={{
        width: `${Math.max(8, blank.submission.length)}ch`,
      }}
    />
  );
}

export function FIBPlaygroundTyping(props: {
  block: FillInTheBlanksBlock;
  ctrl: FIBTypingControlAPI;
}) {
  const { ctrl } = props;

  const { status, questionVisible, blanks, blankIndex, result, showResult } =
    useSnapshot(props.ctrl.state);

  const submittable =
    status === 'play' && blanks.every((b) => b.submission.length > 0);

  useEffect(() => {
    if (status === 'init') {
      ctrl.present();
    }
  }, [status, ctrl]);

  return (
    <div className='w-full h-full flex flex-col'>
      <main className='w-full flex-1 px-10 py-5 flex flex-col gap-5 sm:gap-10'>
        <div className='text-center text-sms italic text-icon-gray'>
          Fill in the blanks
        </div>

        <div
          className={`
            text-white text-center break-words
            text-base sm:text-xl lg:text-2xl leading-relaxed
            transition-opacity duration-500 ${
              questionVisible ? 'opacity-100' : 'opacity-0'
            }
        `}
        >
          {props.block.fields.segments.map((segment) => {
            switch (segment.type) {
              case EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeText:
                return <span key={segment.id}>{segment.text}</span>;
              case EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank:
                const blank = blanks.find(
                  (b) => b.segmentId === segment.id
                ) as Blank;

                return (
                  <BlankInput
                    key={blank.segmentId}
                    disabled={status !== 'play'}
                    active={status === 'play' && blank.index === blankIndex}
                    blank={blank}
                    submittable={submittable}
                    showResult={showResult}
                    isLastBlank={blank.index === blanks.length - 1}
                    ctrl={ctrl}
                  />
                );
              default:
                assertExhaustive(segment.type);
                return null;
            }
          })}
        </div>

        <div
          className={`transition-opacity duration-500 ${
            showResult && result !== 'correct' ? 'opacity-100' : 'opacity-0'
          } flex flex-col gap-5 sm:gap-10`}
        >
          <div className='text-center text-icon-gray text-sms italic'>
            Correct answer
          </div>
          <div className='text-center text-white text-base sm:text-xl lg:text-2xl leading-relaxed'>
            {props.block.fields.segments.map((s) => {
              switch (s.type) {
                case EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeText:
                  return <span key={s.id}>{s.text}</span>;
                case EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank:
                  const blank = blanks.find(
                    (b) => b.segmentId === s.id
                  ) as Blank;
                  return (
                    <span
                      key={s.id}
                      className={`${
                        blank.result === 'correct'
                          ? 'text-green-001'
                          : 'text-red-006'
                      }`}
                    >
                      {blank.answer}
                    </span>
                  );
                default:
                  return null;
              }
            })}
          </div>
        </div>
      </main>
      <footer
        className={`'w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5 transition-opacity duration-500 relative`}
      >
        <div className='w-full h-5 flex items-center justify-center text-xs text-black text-opacity-80 text-center'>
          {match(result)
            .with('correct', () => (
              <span className='text-green-001'>
                <strong>Correct!</strong> Nice job!
              </span>
            ))
            .with('incorrect', () => (
              <span className='text-red-006'>
                <strong>Incorrect!</strong> See correct answer above.
              </span>
            ))
            .with('partiallyCorrect', () => (
              <span className='text-red-006'>
                <strong>Partially Correct!</strong> See correct answer above.
              </span>
            ))
            .otherwise(() => null)}
        </div>
        {result ? (
          <CommonButton
            variant={
              result === 'correct'
                ? 'correct'
                : result === 'incorrect' || result === 'partiallyCorrect'
                ? 'incorrect'
                : 'brand'
            }
            onClick={() => ctrl.end()}
          >
            Continue
          </CommonButton>
        ) : (
          <CommonButton
            variant='brand'
            onClick={() => ctrl.submit()}
            disabled={!submittable}
          >
            Submit
          </CommonButton>
        )}
        {result === 'correct' && (
          <CorrectAnimationWithLayout onEnd={() => void 0} />
        )}
      </footer>
    </div>
  );
}
