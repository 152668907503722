import { EnumsExternalMediaProvider } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import { SearchIcon } from '../../icons/SearchIcon';
import { type MediaItem, type MediaProvider } from '../MediaProvider';

export const AzureProvider: MediaProvider = {
  name: 'Bing Images',
  kind: EnumsExternalMediaProvider.ExternalMediaProviderAzureBing,
  proxy: true,
  filter: {
    aspectRatio: true,
  },
  icon: <SearchIcon className='w-8 h-8 p-2 rounded-lg bg-white' />,
  async searchMedia(params): Promise<MediaItem[]> {
    const response = await apiService.media.searchExternalMedia({
      ...params,
      provider: 'azure-bing',
      size: 25,
    });

    return response.data.items;
  },
};
