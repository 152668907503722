import { useNavigate } from '@remix-run/react';
import { useCallback } from 'react';
import { $path } from 'remix-routes';
import useSWR from 'swr';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { gamePackToEnrollmentTarget } from '../../app/components/GamePack/utils';
import { HomePublicLibraryIndex } from '../../app/components/PublicLibrary/HomePublicLibraryIndex';
import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { GameCenterContextProvider } from '../components/Game/GameCenter';
import { CreateCourseButton } from '../components/Training/Admin/Components/CreateCourseButton';
import { DeleteCourseConfirmationModal } from '../components/Training/Admin/Modals/DeleteCourseConfirmationModal';
import { useEnrollmentActions } from '../components/Training/Admin/useCourseActions';
import { LongCourseCard } from '../components/Training/LongCourseCard';
import { TrainingGenAIStarter } from '../components/Training/TrainingStarter';
import { useFeatureQueryParam } from '../hooks/useFeatureQueryParam';
import { getLogger } from '../logger/logger';
import { GamePackContextProvider } from '../pages/GamePack/Context';
import { GamePackCRUDUtility } from '../pages/GamePack/CRUDUtility';
import { apiService } from '../services/api-service';
import { type GamePack } from '../types/game';
import { fromDTOGamePack } from '../utils/api-dto';
import { useCreatedCoursesAndStacks } from './learning.admin.my-courses.client';

function TemplateLibrary() {
  const { data } = useSWR('/learning/admin/template-library', async () => {
    const pageName = EnumsPageName.PageNameGeneralLearning;
    const page = (await apiService.page.get(pageName)).data.page;
    return { pageName, page };
  });

  if (!data) return null;

  const { page, pageName } = data;
  // Programs & featured carousel is not supported here
  return (
    <GameCenterContextProvider>
      <GamePackContextProvider
        embed={false}
        mode='public-home'
        pageType='public'
        routePrefix={$path('/learning/admin')}
        editRoutePrefix=''
      >
        <HomePublicLibraryIndex
          page={page}
          pageName={pageName}
          programs={[]}
          featuredItems={[]}
        />

        <GamePackCRUDUtility />
      </GamePackContextProvider>
    </GameCenterContextProvider>
  );
}

const logger = getLogger();

export function Component() {
  const { courses, isLoading, error, refreshCourses } =
    useCreatedCoursesAndStacks();

  const navigate = useNavigate();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const { handleAssign } = useEnrollmentActions();
  const showLearningLibrary = useFeatureQueryParam('learning-library');

  const handleAssignPack = useCallback(
    (pack: GamePack) => {
      const target = gamePackToEnrollmentTarget(pack);
      handleAssign(target);
    },
    [handleAssign]
  );

  const handleDelete = async (id: string) => {
    const response = await confirmCancel({
      kind: 'confirm-cancel',
      prompt: <DeleteCourseConfirmationModal />,
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
    });
    if (response.result !== 'confirmed') return;

    try {
      await apiService.learning.deleteCourse(id);
      refreshCourses();
    } catch (e) {
      logger.error('Error deleting course', e);
    }
  };

  return (
    <div className='w-full h-full py-10 text-white max-w-screen-2xl items-center mx-auto'>
      <div className='w-full px-20'>
        <TrainingGenAIStarter />
      </div>

      <div className='w-full px-20'>
        <div className='pt-15 pb-5 flex items-center justify-between'>
          <div className='flex flex-col gap-1'>
            <div className='text-2xl font-bold'>My Courses</div>
            <div className='text-sms text-icon-gray'>
              Courses you have customized or chosen from the template library
            </div>
          </div>
          <CreateCourseButton />
        </div>

        <div className='w-full'>
          {/* Loading State */}
          {isLoading && courses.length === 0 && (
            <div className='flex justify-center py-4'>
              <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-white'></div>
            </div>
          )}

          {/* Error State */}
          {error && (
            <div className='text-center py-4 text-red-500'>
              Failed to load courses.{' '}
              <button
                onClick={() => refreshCourses()}
                className='text-blue-500 hover:underline'
              >
                Retry
              </button>
            </div>
          )}

          {/* Course List */}
          {courses.length > 0 && (
            <div className='flex flex-col gap-2.5 pb-10'>
              {courses.slice(0, 5).map((course, index) => (
                <LongCourseCard
                  learners={course.learnerCount}
                  key={course.gamePack.id}
                  pack={fromDTOGamePack(course.gamePack)}
                  onAssign={() =>
                    handleAssignPack(fromDTOGamePack(course.gamePack))
                  }
                  showHeaders={index === 0}
                  onDetails={(id) =>
                    navigate($path('/learning/admin/my-courses/:id', { id }))
                  }
                  onEdit={(id) =>
                    navigate($path('/trainings/:id/edit', { id }))
                  }
                  onDelete={(id) => handleDelete(id)}
                />
              ))}

              {/* Loading More State */}
              {isLoading && (
                <div className='flex justify-center py-4'>
                  <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-white'></div>
                </div>
              )}

              <div
                className='text-sms text-gray-500 hover:underline cursor-pointer font-bold self-end'
                onClick={() => navigate('/learning/admin/my-courses')}
              >
                View All
              </div>
            </div>
          )}
        </div>
      </div>

      {showLearningLibrary && <TemplateLibrary />}
    </div>
  );
}
