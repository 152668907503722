import {
  DtoProgression,
  ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import { Block } from './block';

export type BlockOutputKey = `${string}.${string}`;

export function getBlockOutputKey(
  blockId: Block['id'],
  outputName: string
): BlockOutputKey {
  return `${blockId}.${outputName}`;
}

export function getBlockOutputAsString(output: ModelsBlockOutput): string {
  switch (output.type) {
    case 'string':
    case 'number':
    case 'enum':
      return String(output.value);
  }
  return '';
}

export function appendBlockReferenceOutputs(
  outputs: DtoProgression['blockOutputs'],
  blockMap: Map<Block['id'], Block>
): DtoProgression['blockOutputs'] {
  if (!outputs) return outputs;
  for (const [key, output] of Object.entries(outputs ?? {})) {
    const [blockId, outputName] = key.split('.', 2) as [Block['id'], string];
    const block = blockMap.get(blockId);
    if (block?.fields.referenceId) {
      outputs[getBlockOutputKey(block.fields.referenceId, outputName)] = output;
    }
  }
  return outputs;
}

export type BlockOutputDesc = {
  // unique name of this output.
  name: string;
  // option display name of this output.
  displayName?: string;
  schema: BlockOutputSchema;
  description?: string;
};

export type BlockOutputSchema =
  | {
      type: 'string';
    }
  | {
      type: 'number';
    }
  | {
      type: 'enum';
      values: string[];
    };

export type BlockOutputsDesc = {
  [key: BlockOutputDesc['name']]: BlockOutputDesc;
};
