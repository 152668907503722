import {
  type ClientLoaderFunctionArgs,
  Link,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useMemo } from 'react';

import {
  type ModelsPromptConfig,
  type ModelsTrainingProfile,
} from '@lp-lib/api-service-client/public';

import { ActionSheet } from '../components/ActionSheet';
import { type Action } from '../components/ActionSheet';
import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { CopyIcon } from '../components/icons/CopyIcon';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { LockIcon } from '../components/icons/LockIcon';
import { UnlockIcon } from '../components/icons/UnlockIcon';
import { buildTemplateConfigGroups } from '../components/TrainingProfile/utils';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { DateUtils } from '../utils/date';
import { tokenWithRedirect } from '../utils/router';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const resp = await tokenWithRedirect(
    () => apiService.trainingProfile.listProfiles(),
    action.request.url,
    { admin: true }
  );

  return {
    profiles: resp.data.profiles,
  };
};

type ActionSheetKeys = 'clone' | 'delete' | 'lock' | 'unlock';

function PromptTemplateLink(props: {
  config?: ModelsPromptConfig | null;
}): JSX.Element {
  const { config } = props;
  if (!config || config.templateId === '') return <span>N/A</span>;
  return (
    <Link
      to={`/admin/prompt-templates/${config.templateId}`}
      className='underline'
      onClick={(e) => e.stopPropagation()}
    >
      {props.config?.templateName ?? 'unnamed'}
    </Link>
  );
}

function PromptTemplateEntry(props: {
  label: string;
  config?: ModelsPromptConfig | null;
}) {
  const { label, config } = props;
  const unset = !config || config.templateId === '';
  return (
    <div className={`${unset ? 'text-red-002' : 'text-white'}`}>
      {label}: <PromptTemplateLink config={config} />
    </div>
  );
}

function ProfileItem(props: { profile: ModelsTrainingProfile }): JSX.Element {
  const { profile } = props;
  const navigate = useNavigate();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const {
    state: { state },
    call: onDelete,
  } = useLiveAsyncCall(async () => {
    const response = await confirmCancel({
      kind: 'confirm-cancel',
      prompt: (
        <div className='text-white text-center text-2xl font-medium'>
          <div className='flex flex-col gap-1'>
            <div>Are you sure you want to delete this profile?</div>
            <div className='text-red-002'>
              Anything used this profile will be broken!!!
            </div>
          </div>
        </div>
      ),
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;
    await apiService.trainingProfile.deleteProfile(profile.id);
    navigate('.', { replace: true });
  });

  const { call: onClone } = useLiveAsyncCall(async () => {
    await apiService.trainingProfile.duplicateProfile(profile.id);
    navigate('.', { replace: true });
  });

  const { call: onLock } = useLiveAsyncCall(async () => {
    await apiService.trainingProfile.lockProfile(profile.id);
    navigate('.', { replace: true });
  });

  const { call: onUnlock } = useLiveAsyncCall(async () => {
    await apiService.trainingProfile.unlockProfile(profile.id);
    navigate('.', { replace: true });
  });

  const actions: Action<ActionSheetKeys>[] = [
    {
      kind: 'button',
      key: 'clone',
      icon: <CopyIcon />,
      text: 'Clone Profile',
      onClick: onClone,
    },
  ];

  if (!profile.locked) {
    actions.push(
      {
        kind: 'button',
        key: 'lock',
        icon: <LockIcon />,
        text: 'Lock Profile',
        onClick: onLock,
      },
      {
        kind: 'button',
        key: 'delete',
        icon: <DeleteIcon />,
        text: 'Delete Profile',
        className: 'text-red-002',
        disabled: state.isRunning,
        onClick: onDelete,
      }
    );
  } else {
    actions.push({
      kind: 'button',
      key: 'unlock',
      icon: <UnlockIcon />,
      text: 'Unlock Profile',
      className: 'text-tertiary',
      onClick: onUnlock,
    });
  }

  const groups = useMemo(() => buildTemplateConfigGroups(profile), [profile]);

  return (
    <tr
      className='text-sms cursor-pointer hover:bg-lp-gray-002 odd:bg-lp-gray-001'
      onClick={() => navigate(`./${profile.id}`)}
    >
      <td className='py-1.5'>{profile.name}</td>
      <td className={`py-1.5 ${profile.locked ? 'text-tertiary' : ''}`}>
        {profile.locked ? 'true' : 'false'}
      </td>
      <td className='py-1.5'>
        {groups.global.map((g, i) => (
          <PromptTemplateEntry
            key={`${i}-${g.name}`}
            label={g.label}
            config={g.config}
          />
        ))}
      </td>
      <td className='py-1.5'>
        {groups.blocks.map((b, i) => (
          <div key={i}>
            <PromptTemplateEntry label={b.type} config={b} />
          </div>
        ))}
      </td>
      <td className='py-1.5'>{DateUtils.FormatDatetime(profile.updatedAt)}</td>
      <td className='py-1.5'>
        <ActionSheet<ActionSheetKeys> actions={actions} placement='left' />
      </td>
    </tr>
  );
}

function ProfileList(): JSX.Element {
  const { profiles } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();
  return (
    <div className='flex flex-col gap-6 text-white'>
      <div className='flex items-center justify-between'>
        <p className='text-2xl font-medium'>Training Prompt Profiles</p>
        <button
          type='button'
          className='btn-primary w-42 h-10 flex justify-center items-center'
          onClick={() => navigate('./create')}
        >
          Create Profile
        </button>
      </div>
      <table className='w-full'>
        <thead>
          <tr className='text-left'>
            <th className='pb-3'>Name</th>
            <th className='pb-3'>Locked</th>
            <th className='pb-3'>Objectives</th>
            <th className='pb-3'>Blocks</th>
            <th className='pb-3'>Updated At</th>
            <th className='pb-3'></th>
          </tr>
        </thead>
        <tbody>
          {profiles?.map((p) => (
            <ProfileItem key={p.id} profile={p} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function Component() {
  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />

      <ProfileList />
    </AdminView>
  );
}
