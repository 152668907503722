import { match } from 'ts-pattern';

import {
  EnumsPageName,
  type ModelsMediaAsset,
  type ModelsTagStyles,
} from '@lp-lib/api-service-client/public';

export enum TaggedObjectType {
  Block = 100,
  Game = 200,
  PrimeGame = 201,
  GamePack = 210,
  PrimeGamePack = 211,
  Brand = 220,
  ProgramRound = 221,
}

interface TagExtension {
  objectType: TaggedObjectType;
  objectsCount: number;
  featuredObjectIds: string[] | null;
}

export interface Tag {
  id: number;
  name: string;
  slug: string;
  shortAlias?: string | null;
  description?: string | null;
  icon?: ModelsMediaAsset | null;
  background?: ModelsMediaAsset | null;
  styles?: ModelsTagStyles | null;
  supportingMedia?: ModelsMediaAsset | null;
  extensions: TagExtension[];
}

interface TaggedObject {
  tags?: Tag[];
}

type VirtualTagKeys = 'notFound' | 'my' | 'untagged' | 'playAgain';

export const virtualTags: Record<VirtualTagKeys, Tag> = {
  notFound: {
    id: -404,
    name: 'Tag Not Found',
    slug: 'untagged',
    extensions: [],
  },
  my: {
    id: -100,
    name: 'My',
    slug: '',
    extensions: [],
  },
  untagged: {
    id: -101,
    name: 'Not Categorized',
    slug: 'untagged',
    extensions: [],
  },
  playAgain: {
    id: -102,
    name: 'Play Again',
    slug: 'play-again',
    extensions: [],
  },
};

export const virtualTagIds = Object.values(virtualTags).map((t) => t.id);

export const virtualTagList = Object.values(virtualTags);

export function getTagLink(tag: Tag): string {
  switch (tag.id) {
    case virtualTags.my.id:
      return '/my';
    case virtualTags.untagged.id:
      return '/untagged';
    case virtualTags.playAgain.id:
      return '/play-again';
    default:
      return `/tags/${tag.slug}`;
  }
}

export function isVirtualTag(tag: Tag): boolean {
  switch (tag.id) {
    case virtualTags.notFound.id:
    case virtualTags.my.id:
    case virtualTags.untagged.id:
    case virtualTags.playAgain.id:
      return true;
    default:
      return false;
  }
}

export function tagMatched(obj: TaggedObject, tagId: number): boolean {
  return (
    (obj.tags || []).findIndex((t) => t.id === tagId) >= 0 ||
    (!obj.tags && tagId === virtualTags.untagged.id)
  );
}

export type TagDiscoverContext =
  | 'discover-games'
  | 'discover-game-packs'
  | 'public-game-packs'
  | 'brands'
  | 'public-game-packs-ng';

function getObjectsCount(tag: Tag, objectType: TaggedObjectType): number {
  return (
    tag.extensions?.find((e) => e.objectType === objectType)?.objectsCount || 0
  );
}

export const TagUtils = {
  getGamesCount: (tag: Tag): number => {
    return getObjectsCount(tag, TaggedObjectType.Game);
  },
  getPrimeGamesCount: (tag: Tag): number => {
    return getObjectsCount(tag, TaggedObjectType.PrimeGame);
  },
  getGamePacksCount: (tag: Tag): number => {
    return getObjectsCount(tag, TaggedObjectType.GamePack);
  },
  getPrimeGamePacksCount: (tag: Tag): number => {
    return getObjectsCount(tag, TaggedObjectType.PrimeGamePack);
  },
  getBrandsCount: (tag: Tag): number => {
    return getObjectsCount(tag, TaggedObjectType.Brand);
  },

  getObjectType(context: EnumsPageName): TaggedObjectType {
    return match(context)
      .with(EnumsPageName.PageNameBrands, () => TaggedObjectType.Brand)
      .with(
        EnumsPageName.PageNameDiscoverGames,
        () => TaggedObjectType.PrimeGame
      )
      .otherwise(() => TaggedObjectType.PrimeGamePack);
  },

  getObjectsCount(tag: Tag, objectType: TaggedObjectType): number {
    return getObjectsCount(tag, objectType);
  },

  getObjectLabel(
    objectType: TaggedObjectType,
    withPrimePrefix?: boolean
  ): string {
    return match(objectType)
      .with(TaggedObjectType.Block, () => 'Block')
      .with(TaggedObjectType.Game, () => 'Game')
      .with(
        TaggedObjectType.PrimeGame,
        () => `${withPrimePrefix ? 'Prime ' : ''}Game`
      )
      .with(TaggedObjectType.GamePack, () => 'Game Pack')
      .with(
        TaggedObjectType.PrimeGamePack,
        () => `${withPrimePrefix ? 'Prime ' : ''}Game Pack`
      )
      .with(TaggedObjectType.Brand, () => 'Brand')
      .with(TaggedObjectType.ProgramRound, () => 'Program Round')
      .otherwise(() => 'Unknown');
  },
};
