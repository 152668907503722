import { useEffect, useState } from 'react';

import { useForceUpdate } from '../../../../hooks/useForceUpdate';
import { SpeakerIcon } from '../../../icons/SpeakerIcon';
import { type RoleplayBlockControlAPI } from './RoleplayBlock';

function Captions(props: { ctrl: RoleplayBlockControlAPI }) {
  const { ctrl } = props;

  const forceUpdate = useForceUpdate();
  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate();
    }, 300);
    return () => clearInterval(interval);
  }, [forceUpdate]);

  const items = ctrl.VoiceChatAPI.state.items;
  if (items.length === 0) return null;
  const lastItem = items[items.length - 1];
  const item = ctrl.VoiceChatAPI.getItem(lastItem.id);
  if (!item) return null;

  return (
    <div className='font-bold text-white text-base lg:text-xl text-center'>
      {item.formatted.transcript || item.formatted.text || ''}
    </div>
  );
}

export function hasAudioSignal(frequencies: Float32Array | null) {
  if (!frequencies) return false;
  const threshold = 0.1;
  const average =
    frequencies.reduce((sum, value) => sum + Math.abs(value), 0) /
    frequencies.length;
  return average > threshold;
}

export function RoleplayTalkingIndicatorPushToTalk(props: {
  isRecording: boolean;
  ctrl: RoleplayBlockControlAPI;
}) {
  const { isRecording, ctrl } = props;

  const [assistantSpeakingAt, setAssistantSpeakingAt] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (isRecording) return;

    const interval = setInterval(() => {
      const wavStreamPlayer = ctrl.VoiceChatAPI.getWavStreamPlayer();
      if (
        wavStreamPlayer &&
        hasAudioSignal(wavStreamPlayer.getFrequencies('voice').values)
      ) {
        setAssistantSpeakingAt(new Date().getTime());
      }
    }, 300);
    return () => {
      clearInterval(interval);
      setAssistantSpeakingAt(null);
    };
  }, [ctrl.VoiceChatAPI, isRecording]);

  if (isRecording) {
    return <div className='text-xl font-bold text-white'>Listening...</div>;
  }

  // If assistant has not spoken yet, don't show anything
  if (!assistantSpeakingAt) return null;

  // If assistant is stopped for more than 2 seconds, it's time for user to talk
  if (new Date().getTime() - assistantSpeakingAt > 2000) {
    return (
      <div className='text-xl font-bold text-green-001 animate-pulse'>
        Click below to talk
      </div>
    );
  }

  return <Captions ctrl={ctrl} />;
}

export function RoleplayTalkingIndicatorAutoDetection(props: {
  ctrl: RoleplayBlockControlAPI;
}) {
  const { ctrl } = props;

  const [status, setStatus] = useState<
    'none' | 'start-talking' | 'captions' | 'listening'
  >('none');

  useEffect(() => {
    let assistantSpeakAt = 0;
    let userSpeakAt = 0;

    const interval = setInterval(() => {
      const wavStreamPlayer = ctrl.VoiceChatAPI.getWavStreamPlayer();
      if (
        wavStreamPlayer &&
        hasAudioSignal(wavStreamPlayer.getFrequencies('voice').values)
      ) {
        assistantSpeakAt = new Date().getTime();
      }

      const wavRecorder = ctrl.VoiceChatAPI.getWavRecorder();
      if (
        wavRecorder &&
        hasAudioSignal(wavRecorder.getFrequencies('voice').values)
      ) {
        userSpeakAt = new Date().getTime();
      }

      // If both assistant and user are not speaking, don't show anything
      if (!assistantSpeakAt && !userSpeakAt) {
        setStatus('none');
        return;
      }

      // If the last speaker is assistant
      if (assistantSpeakAt > userSpeakAt) {
        // If assistant is stopped for more than 2 seconds, it's time for user to talk
        if (new Date().getTime() - assistantSpeakAt > 2000) {
          setStatus('start-talking');
        } else {
          setStatus('captions');
        }
        return;
      }

      // If the last speaker is user
      if (new Date().getTime() - userSpeakAt > 2000) {
        setStatus('start-talking');
      } else {
        setStatus('listening');
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, [ctrl]);

  switch (status) {
    case 'none':
      return null;
    case 'start-talking':
      return (
        <div className='flex justify-center items-center gap-2 text-xl font-bold text-green-001 animate-pulse'>
          <SpeakerIcon className='w-5 h-5 fill-current' />
          <div>Start talking...</div>
        </div>
      );
    case 'captions':
      return <Captions ctrl={ctrl} />;
    case 'listening':
      return <div className='text-xl font-bold text-white'>Listening...</div>;
  }
}
