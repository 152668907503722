import { type AxiosResponse } from 'axios';

import {
  type DtoActivateOTPUpsellRequest,
  type DtoBlock,
  type DtoBrand,
  type DtoCreateCustomizeGamePackRequest,
  type DtoCreateDownloadUGCFileTokenResponse,
  type DtoCreateGamePackCheckoutSessionRequest,
  type DtoCreateGamePackCheckoutSessionResponse,
  type DtoCreateGamePackInvoiceRequest,
  type DtoCreateGamePackInvoiceResponse,
  type DtoCreateGamePackOTPIntentRequest,
  type DtoCreateGamePackPaymentIntentRequest,
  type DtoCreateGamePackPaymentIntentResponse,
  type DtoCreateGamePackRequest,
  type DtoExportScormPackageRequest,
  type DtoGenerateTrainingCourseRequest,
  type DtoGenerateTrainingCourseResponse,
  type DtoGetUGCFilesResponse,
  type DtoMultiBrandResponse,
  type DtoOptimizeUGCPromptRequest,
  type DtoOptimizeUGCPromptResponse,
  type DtoOTPUpsellResponse,
  type DtoPropagateUGCSettingsResponse,
  type DtoPublishGamePackRequest,
  type DtoQueryGroupedGamePacksByTagsResponse,
  type DtoRecommendGamePacksRequest,
  type DtoRecommendGamePacksResponse,
  type DtoRecommendTrainingCourseCoverImagesRequest,
  type DtoRecommendTrainingCourseCoverImagesResponse,
  type DtoRecommendTrainingCoursesRequest,
  type DtoRecommendTrainingCoursesResponse,
  type DtoSingleGamePackResponse,
  type DtoUGCGenerateBlockRequest,
  type DtoUGCGenerateBlockResponse,
  type DtoUGCGenerateNameRequest,
  type DtoUGCGenerateNameResponse,
  type DtoUpdateGamePackRequest,
  type DtoUpdateGamePackTagsRequest,
  type EnumsGamePackVersion,
  type EnumsOneTimePurchaseUpsellPromoCode,
  type EnumsPageName,
  type GetGamePackParams,
} from '@lp-lib/api-service-client/public';

import { type Tag } from '../../types';
import {
  type GameLikeCollectionType,
  type GameLikeFilters,
  type GamePack,
} from '../../types/game';
import { APIServiceURL, publicFetchAPIService } from '../public';
import { BaseAPIService } from './base.api';
import { type GamesResponse } from './game.api';
import { type Paginable, Paginator } from './pagination';

export interface GamePacksResponse extends Paginable {
  gamePacks: GamePack[];
  gamePacksCount: number;
}

export interface GamePackResponse {
  gamePack: GamePack;
  blocks?: DtoBlock[];
  brands?: DtoBrand[];
}

export type ImportGamePackRequest = {
  gamePack: GamePack & {
    games: [];
  };
};

export type GamePackPlay = {
  gamePackId: string;
  loadedAt: number;
  replayable: boolean;
};

export interface RecommendGamePacksRequest {
  gamePackPlays: GamePackPlay[];
}

export type GamePackRecommendation = {
  gamePack: GamePack;
  tag: Tag;
};

export interface GamePackRecommendationListResponse {
  gamePackRecommendations: GamePackRecommendation[];
}

export class GamePackAPI extends BaseAPIService {
  create(req: DtoCreateGamePackRequest) {
    return this.client.post<DtoSingleGamePackResponse>('/game-packs', req);
  }

  update(id: string, req: DtoUpdateGamePackRequest) {
    return this.client.put<DtoSingleGamePackResponse>(`/game-packs/${id}`, req);
  }

  getMyGamePacks(
    version?: EnumsGamePackVersion
  ): Paginator<GamePacksResponse, GamePack> {
    return new Paginator<GamePacksResponse, GamePack>(
      this.client,
      '/game-packs',
      'gamePacks',
      {
        size: 36,
        config: {
          params: { type: 'my', key: version },
        },
      }
    );
  }

  getGamePacksByTagId(
    tagId: number,
    personalized = false,
    pageSize = 36,
    excludeTemplates = false
  ): Paginator<GamePacksResponse, GamePack> {
    return new Paginator<GamePacksResponse, GamePack>(
      this.client,
      '/game-packs',
      'gamePacks',
      {
        size: pageSize,
        config: {
          params: { type: 'tag', key: tagId, personalized, excludeTemplates },
        },
      }
    );
  }

  getUntaggedPrimeGamePacks(): Paginator<GamePacksResponse, GamePack> {
    return new Paginator<GamePacksResponse, GamePack>(
      this.client,
      '/game-packs',
      'gamePacks',
      {
        size: 36,
        config: {
          params: { type: 'untagged' },
        },
      }
    );
  }

  searchGamePacks(
    q: string,
    params?: {
      filterParams?: GameLikeFilters;
      primeOnly?: boolean;
      scope?: EnumsPageName;
      tagId?: number;
      ugcOnly?: string;
    }
  ): Paginator<GamePacksResponse, GamePack> {
    const encoded = params?.filterParams
      ? btoa(JSON.stringify(params?.filterParams))
      : null;
    return new Paginator<GamePacksResponse, GamePack>(
      this.client,
      '/game-packs/search',
      'gamePacks',
      {
        size: 36,
        config: {
          params: {
            q,
            filters: encoded,
            primeOnly: !!params?.primeOnly,
            scope: params?.scope,
            tagId: params?.tagId,
            ugcOnly: params?.ugcOnly,
          },
        },
      }
    );
  }

  delete(id: string): Promise<AxiosResponse> {
    return this.client.delete(`/game-packs/${id}`);
  }

  duplicate(
    id: string,
    fork?: boolean
  ): Promise<AxiosResponse<GamePackResponse>> {
    return this.client.post<GamePackResponse>(`/game-packs/${id}/duplicate`, {
      fork: !!fork,
    });
  }

  getGamePackById(
    id: string,
    params?: Omit<GetGamePackParams, 'id'>
  ): Promise<AxiosResponse<DtoSingleGamePackResponse>> {
    return this.client.get<DtoSingleGamePackResponse>(`/game-packs/${id}`, {
      params,
    });
  }

  publish(
    id: string,
    req: DtoPublishGamePackRequest
  ): Promise<AxiosResponse<GamePackResponse>> {
    return this.client.post<GamePackResponse>(`/game-packs/${id}/publish`, req);
  }

  getLinkedGames(id: string): Promise<AxiosResponse<GamesResponse>> {
    return this.client.get<GamesResponse>(`/game-packs/${id}/games`);
  }

  getLinkedBrands(id: string): Promise<AxiosResponse<DtoMultiBrandResponse>> {
    return this.client.get<DtoMultiBrandResponse>(`/game-packs/${id}/brands`);
  }

  getGamePacksByCollection(
    collection: GameLikeCollectionType,
    size?: number
  ): Promise<AxiosResponse<GamePacksResponse>> {
    return this.client.get('/game-packs', {
      params: { type: 'collection', collection, size },
    });
  }

  updateGamePacksByCollection(
    collection: GameLikeCollectionType,
    gamePackIds: string[]
  ): Promise<AxiosResponse> {
    return this.client.put(`/game-packs/collections/${collection}`, {
      gamePackIds,
    });
  }

  updateGamePackTags(
    id: string,
    req: DtoUpdateGamePackTagsRequest
  ): Promise<AxiosResponse<DtoSingleGamePackResponse>> {
    return this.client.put(`/game-packs/${id}/tags`, req);
  }

  getFeaturedGamePacksByTag(
    tagId: number
  ): Promise<AxiosResponse<GamePacksResponse>> {
    return this.client.get<GamePacksResponse>(
      `/game-packs/tags/${tagId}/featured`
    );
  }

  getGroupedGamePacksByTags(
    tagIds: number[],
    size = 36
  ): Promise<AxiosResponse<DtoQueryGroupedGamePacksByTagsResponse>> {
    return this.client.get<DtoQueryGroupedGamePacksByTagsResponse>(
      '/game-packs/tag-grouped',
      {
        params: { tagIds: tagIds.join(','), size },
      }
    );
  }

  updateFeaturedGamePacksByTag(
    tagId: number,
    gamePackIds: string[]
  ): Promise<AxiosResponse> {
    return this.client.put(`/game-packs/tags/${tagId}/featured`, {
      gamePackIds,
    });
  }

  importGamePack(
    req: ImportGamePackRequest
  ): Promise<AxiosResponse<GamePackResponse>> {
    return this.client.post<GamePackResponse>('/game-packs/import', req);
  }

  recommendGamePack(
    req: DtoRecommendGamePacksRequest
  ): Promise<AxiosResponse<DtoRecommendGamePacksResponse>> {
    return this.client.post('/game-packs/recommend', req);
  }

  getPlayedGamePacks(): Paginator<GamePacksResponse, GamePack> {
    return new Paginator<GamePacksResponse, GamePack>(
      this.client,
      '/game-packs',
      'gamePacks',
      {
        size: 36,
        config: {
          params: { type: 'played' },
        },
      }
    );
  }

  createGamePackCheckoutSession(
    packId: string,
    req: DtoCreateGamePackCheckoutSessionRequest
  ): Promise<AxiosResponse<DtoCreateGamePackCheckoutSessionResponse>> {
    return this.client.post(
      `/game-packs/${packId}/create-checkout-session`,
      req
    );
  }

  getOrgPurchasedGamePacks(
    orgId: string
  ): Promise<AxiosResponse<GamePacksResponse>> {
    return this.client.get('/game-packs', {
      params: { type: 'org_purchased', key: orgId },
    });
  }

  createOTPIntent(
    packId: string,
    req: DtoCreateGamePackOTPIntentRequest
  ): Promise<AxiosResponse> {
    return this.client.post(`/game-packs/${packId}/create-otp-intent`, req);
  }

  createPaymentIntent(
    packId: string,
    req: DtoCreateGamePackPaymentIntentRequest
  ): Promise<AxiosResponse<DtoCreateGamePackPaymentIntentResponse>> {
    return this.client.post(`/game-packs/${packId}/create-payment-intent`, req);
  }

  activateOTPUpsell(
    packId: string,
    req: DtoActivateOTPUpsellRequest
  ): Promise<AxiosResponse> {
    return this.client.post(`/game-packs/${packId}/activate-upsell`, req);
  }

  getOTPUpsell(
    packId: string,
    promoCode: EnumsOneTimePurchaseUpsellPromoCode
  ): Promise<AxiosResponse<DtoOTPUpsellResponse>> {
    return this.client.get(`/game-packs/${packId}/upsell`, {
      params: { promoCode },
    });
  }

  createInvoice(
    packId: string,
    req: DtoCreateGamePackInvoiceRequest
  ): Promise<AxiosResponse<DtoCreateGamePackInvoiceResponse>> {
    return this.client.post(`/game-packs/${packId}/create-invoice`, req);
  }

  customize(id: string, req: DtoCreateCustomizeGamePackRequest) {
    return this.client.post<DtoSingleGamePackResponse>(
      `/game-packs/${id}/customize`,
      req
    );
  }

  generateBlock(id: string, req: DtoUGCGenerateBlockRequest) {
    return this.client.post<DtoUGCGenerateBlockResponse>(
      `/game-packs/${id}/generate-block`,
      req
    );
  }

  propagateUGCSettings(id: string) {
    return this.client.post<DtoPropagateUGCSettingsResponse>(
      `/game-packs/${id}/propagate-ugc-settings`
    );
  }

  getUGCFiles(id: string) {
    return this.client.get<DtoGetUGCFilesResponse>(
      `/game-packs/${id}/ugc-files`
    );
  }

  deleteUGCFile(packId: string, fileId: string) {
    return this.client.delete<void>(
      `/game-packs/${packId}/ugc-files/${fileId}`
    );
  }

  createDownloadUGCFileToken(packId: string, fileId: string) {
    return this.client.post<DtoCreateDownloadUGCFileTokenResponse>(
      `/game-packs/${packId}/ugc-files/${fileId}/download-token`
    );
  }

  optimizeUGCPrompt(packId: string, req: DtoOptimizeUGCPromptRequest) {
    return this.client.post<DtoOptimizeUGCPromptResponse>(
      `/game-packs/${packId}/optimize-ugc-prompt`,
      req
    );
  }
  generateName(packId: string, req: DtoUGCGenerateNameRequest) {
    return this.client.post<DtoUGCGenerateNameResponse>(
      `/game-packs/${packId}/generate-name`,
      req
    );
  }
  async recommendTrainingCourses(
    packId: string,
    req: DtoRecommendTrainingCoursesRequest,
    opts?: { signal?: AbortSignal }
  ) {
    const url = new APIServiceURL(
      `/game-packs/${packId}/recommend-training-courses`
    );
    const resp =
      await publicFetchAPIService<DtoRecommendTrainingCoursesResponse>(url, {
        method: 'POST',
        bodyObj: req,
        token: this.getToken(),
        signal: opts?.signal,
      });
    return { data: resp.json, status: resp.res.status };
  }
  async generateTrainingCourse(
    packId: string,
    req: DtoGenerateTrainingCourseRequest,
    opts?: { signal?: AbortSignal }
  ) {
    const url = new APIServiceURL(
      `/game-packs/${packId}/generate-training-course`
    );
    const resp = await publicFetchAPIService<DtoGenerateTrainingCourseResponse>(
      url,
      {
        method: 'POST',
        bodyObj: req,
        token: this.getToken(),
        signal: opts?.signal,
      }
    );
    return { data: resp.json, status: resp.res.status };
  }
  exportScormPackage(
    packId: string,
    req: DtoExportScormPackageRequest
  ): Promise<AxiosResponse<Blob>> {
    return this.client.post(`/game-packs/${packId}/export-scorm-package`, req, {
      responseType: 'blob',
    });
  }
  recommendCoverImages(
    req: DtoRecommendTrainingCourseCoverImagesRequest
  ): Promise<AxiosResponse<DtoRecommendTrainingCourseCoverImagesResponse>> {
    return this.client.post(`/game-packs/recommend-cover-images`, req);
  }
}
