import '@blocknote/mantine/style.css';

import type { PartialBlock } from '@blocknote/core';
import { BlockNoteView } from '@blocknote/mantine';
import { useCreateBlockNote } from '@blocknote/react';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import {
  EnumsDialogueGenerationType,
  EnumsMediaScene,
  EnumsSlideBackgroundOption,
  EnumsSlideLayout,
  type ModelsDialogue,
  type ModelsMediaAsset,
} from '@lp-lib/api-service-client/public';
import {
  type Media,
  type SlideBlock,
  type SlideBlockAnimationKey,
} from '@lp-lib/game';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { getLogger } from '../../../logger/logger';
import { apiService } from '../../../services/api-service';
import { fromMediaDTO, toMediaDTO } from '../../../utils/api-dto';
import { assertExhaustive, err2s } from '../../../utils/common';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../../utils/media';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import {
  BlockAnimation,
  type BlockAnimator,
  BlockAnimatorProvider,
  useBlockAnimator,
} from '../../GameV2/apis/BlockAnimationControl';
import { CommonButton } from '../../GameV2/design/Button';
import { Captions } from '../../GameV2/design/Captions';
import { EditableText } from '../../GameV2/design/Editable';
import { EditableTextWithQuotes } from '../../GameV2/design/EditableQuote';
import { ScrollableContent } from '../../GameV2/design/ScrollableContent';
import { TrainingLogo } from '../../GameV2/design/TrainingLogo';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { ImageIcon } from '../../icons/ImageIcon';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { useTriggerMediaSearchModal } from '../../MediaSearch/useTriggerMediaSearchModal';
import { SwitcherControlled } from '../../Switcher';
import { useOrgBrandColor } from '../../VenueOrgLogoAverageColor/useOrgBrandColor';
import { DialogueEditor } from '../../VoiceOver/DialogueEditor';
import {
  usePersonalities,
  usePersonality,
} from '../../VoiceOver/usePersonalities';
import { VoiceOverUtils } from '../../VoiceOver/utils';
import { useBlockFieldController, useTrainingSlideEditor } from './hooks';
import { BlockAnimationsEditor } from './Shared/BlockAnimationsEditor';
import { BlockMusicSelect } from './Shared/BlockMusicSelect';
import { SlideBlockBackgroundSelect } from './SlideBlockBackgroundSelect';
import { SlideBlockLayoutSelect } from './SlideBlockLayoutSelect';
import { type TrainingSlideEditorProps } from './types';

const logger = getLogger().scoped('training-editor');

const Animate = BlockAnimation<SlideBlockAnimationKey>;

function EditableFieldText(
  props: TrainingSlideEditorProps<SlideBlock> & {
    field: 'heading' | 'subtitle' | 'buttonText';
    placeholder?: string;
  }
) {
  const { value, updateLocal, updateRemote } = useBlockFieldController(
    props.block,
    props.field
  );

  const placeholder = useMemo(() => {
    if (props.placeholder) return props.placeholder;
    switch (props.field) {
      case 'heading':
        return 'Your Title Goes Here';
      case 'subtitle':
        return 'Your Subtitle Goes Here';
      case 'buttonText':
        return undefined;
    }
  }, [props.field, props.placeholder]);

  return (
    <EditableText
      value={value ?? ''}
      onBlur={(value) => {
        updateLocal(value);
        updateRemote(value);
      }}
      placeholder={placeholder}
    />
  );
}

function EditableFieldTextWithQuotes(
  props: TrainingSlideEditorProps<SlideBlock> & {
    field: 'heading' | 'subtitle' | 'buttonText';
  }
) {
  const { value, updateLocal, updateRemote } = useBlockFieldController(
    props.block,
    props.field
  );

  return (
    <EditableTextWithQuotes
      value={value ?? ''}
      onBlur={(value) => {
        updateLocal(value);
        updateRemote(value);
      }}
    />
  );
}

function htmlToBulletPoints(html: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const liElements = doc.querySelectorAll('li');

  return Array.from(liElements).map((li) => li.textContent?.trim() ?? '');
}

function bulletPointsToHtml(value: string[], blockId: string) {
  return value && value.length > 0
    ? value
        .map(
          (p, i) =>
            `<li data-animation-key="${blockId}-bullet-${i + 1}">${p}</li>`
        )
        .join('')
    : `<li data-animation-key="${blockId}-bullet-1"></li>`;
}

function EditableBulletPoints(props: TrainingSlideEditorProps<SlideBlock>) {
  const { value, updateLocal, updateRemote } = useBlockFieldController(
    props.block,
    'bulletPoints'
  );
  const animator = useBlockAnimator<SlideBlockAnimationKey>();
  const [points, setPoints] = useState(value);
  const [html, setHtml] = useState(() =>
    bulletPointsToHtml(value, props.block.id)
  );
  const handleBlur = useLiveCallback(() => {
    const points = htmlToBulletPoints(html);
    const nextHtml = bulletPointsToHtml(points, props.block.id);
    setPoints(points);
    setHtml(nextHtml);

    updateLocal(points);
    updateRemote(points);
  });
  const registerBullets = useLiveCallback((points: string[]) => {
    // register the bullet points.
    for (let i = 0; i < points.length; i++) {
      const k = `bullet-${i + 1}`;
      const id = `${props.block.id}-${k}`;
      const el = document.querySelector(`li[data-animation-key="${id}"]`);
      if (!el) continue;
      animator.register(k as SlideBlockAnimationKey, el as HTMLElement, false);
    }
  });

  useEffect(() => {
    registerBullets(points);
  }, [registerBullets, points]);

  return (
    <ContentEditable
      tagName='ol'
      className={`
        outline-none list-decimal
        text-base sm:text-xl lg:text-2xl
        space-y-2 sm:space-y-4 pl-5 sm:pl-8 lg:pl-10
      `}
      html={html}
      onChange={(e) => {
        setHtml(e.currentTarget.innerHTML);
      }}
      onBlur={handleBlur}
    />
  );
}

function MediaField(
  props: TrainingSlideEditorProps<SlideBlock> & {
    rounded: boolean;
    emptyStateClassName?: string;
  }
) {
  const { value, updateLocal, updateRemote } = useBlockFieldController(
    props.block,
    'media'
  );

  const triggerMediaSearchModal = useTriggerMediaSearchModal();

  const handleUploadSuccess = (media: Media) => {
    const asset: ModelsMediaAsset = {
      media: toMediaDTO(media),
      data: {
        id: media.id,
      },
    };
    updateLocal(asset);
    updateRemote(asset);
  };

  const openMediaSearchModal = () => {
    triggerMediaSearchModal({
      onUploadSuccess: handleUploadSuccess,
      video: true,
    });
  };

  const displayUrl = MediaUtils.PickMediaUrl(fromMediaDTO(value?.media), {
    priority: ImagePickPriorityHighToLow,
    videoThumbnail: 'first',
  });

  return (
    <>
      {displayUrl ? (
        // Media preview
        <div
          className={`relative group cursor-pointer`}
          onClick={openMediaSearchModal}
        >
          <img
            src={displayUrl}
            alt=''
            className={`w-full h-full object-contain ${
              props.rounded ? 'rounded-xl' : ''
            }`}
          />
          <div className='absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity'>
            <button
              type='button'
              onClick={(e) => {
                e.stopPropagation();
                updateLocal(null);
                updateRemote(null);
              }}
              className='absolute top-2 right-2 text-white hover:text-red-500 hover:border-red-500 border-2 p-2 border-white rounded-full fill-current'
            >
              <DeleteIcon className='w-5 h-5' />
            </button>
            <div className='w-full h-full flex items-center justify-center'>
              <p className='text-white text-center'>
                Click to replace the media
              </p>
            </div>
          </div>
        </div>
      ) : (
        // Empty state
        <button
          type='button'
          className={`${
            props.emptyStateClassName ?? 'w-full'
          } bg-main-layer border border-secondary rounded-xl flex flex-col items-center justify-center gap-4 hover:bg-light-gray transition-colors`}
          style={{ aspectRatio: '16/9' }}
          onClick={openMediaSearchModal}
        >
          <ImageIcon className='w-12 h-12 text-white' />
          <div className='text-center'>
            <div className='text-white'>Add Media</div>
          </div>
        </button>
      )}
    </>
  );
}

function BackgroundMediaField(props: TrainingSlideEditorProps<SlideBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const value = block.fields.backgroundMedia;

  const triggerMediaSearchModal = useTriggerMediaSearchModal();

  const handleUploadSuccess = (media: Media) => {
    const asset: ModelsMediaAsset = {
      media: toMediaDTO(media),
      data: {
        id: media.id,
      },
    };
    onChange('backgroundMedia', asset);
    onBlur('backgroundMedia', asset);
  };

  const openMediaSearchModal = () => {
    triggerMediaSearchModal({
      onUploadSuccess: handleUploadSuccess,
      video: true,
    });
  };

  const deleteMedia = () => {
    onChange('backgroundMedia', null);
    onBlur('backgroundMedia', null);
  };

  const displayUrl = MediaUtils.PickMediaUrl(fromMediaDTO(value?.media), {
    priority: ImagePickPriorityHighToLow,
    videoThumbnail: 'first',
  });

  if (
    block.fields.backgroundOption !==
    EnumsSlideBackgroundOption.SlideBackgroundOptionMedia
  ) {
    return null;
  }

  return (
    <div className='flex gap-1 items-center'>
      <button
        type='button'
        className='flex gap-1 items-center text-icon-gray text-sms'
        onClick={openMediaSearchModal}
      >
        {displayUrl ? (
          <img
            src={displayUrl}
            alt=''
            className='w-6.5 h-5 border border-icon-gray rounded-sm'
          />
        ) : (
          <span className='px-1.5 py-0.75 border border-icon-gray rounded-sm'>
            <ImageIcon className='w-3 h-3' />
          </span>
        )}
        Background Media
      </button>
      {displayUrl ? (
        <button type='button' onClick={deleteMedia}>
          <DeleteIcon className='w-2.5 h-2.5 text-red-006 fill-current' />
        </button>
      ) : null}
    </div>
  );
}

function BigTitle(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full flex flex-col gap-5 p-10 sm:p-13 lg:p-15'>
      <Logo />
      <Animate
        name='heading'
        noInit
        className='text-3.5xl sm:text-4.25xl lg:text-5xl font-bold leading-normal'
      >
        <EditableFieldText {...props} field='heading' />
      </Animate>
      <Animate name='subtitle' noInit>
        <EditableFieldText {...props} field='subtitle' />
      </Animate>
    </div>
  );
}

function BigTitleMedia(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full h-full flex flex-col md:justify-center p-10 sm:p-13 lg:p-15'>
      <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between md:gap-8'>
        <div className='flex flex-col gap-5 md:w-1/2'>
          <Logo />
          <Animate
            name='heading'
            noInit
            className='text-3.5xl sm:text-4.25xl lg:text-5xl font-bold leading-normal'
          >
            <EditableFieldText {...props} field='heading' />
          </Animate>
          <Animate name='subtitle' noInit>
            <EditableFieldText {...props} field='subtitle' />
          </Animate>
        </div>
        <Animate name='media' noInit className='sm:w-1/2 mt-4 sm:mt-0 sm:pl-2'>
          <MediaField {...props} rounded />
        </Animate>
      </div>
    </div>
  );
}

function BigMedia(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <Animate
      name='media'
      noInit
      className='w-full h-full flex justify-center items-center'
    >
      <MediaField {...props} rounded={false} emptyStateClassName='h-40' />
    </Animate>
  );
}

function Quote(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full h-full flex flex-col lg:justify-center p-10 sm:p-13 lg:p-15'>
      <div className='flex flex-col gap-5 md:max-w-2xl mt-18 lg:mt-0'>
        <Logo />
        <Animate
          name='heading'
          noInit
          className='text-2.5xl sm:text-3.5xl lg:text-4.5xl font-bold leading-normal justify-start'
        >
          <EditableFieldTextWithQuotes {...props} field='heading' />
        </Animate>
        <Animate name='subtitle' noInit>
          <EditableFieldText {...props} field='subtitle' />
        </Animate>
      </div>
    </div>
  );
}

function QuoteMedia(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full h-full flex flex-col lg:justify-center p-10 sm:p-13 lg:p-15'>
      <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between md:gap-8 mt-18 lg:mt-0'>
        <div className='flex flex-col gap-5 md:w-1/2'>
          <Logo />
          <Animate
            name='heading'
            noInit
            className='text-2.5xl sm:text-3.5xl md:text-4.5xl font-bold leading-normal justify-start'
          >
            <EditableFieldTextWithQuotes {...props} field='heading' />
          </Animate>
          <Animate name='subtitle' noInit>
            <EditableFieldText {...props} field='subtitle' />
          </Animate>
        </div>
        <Animate name='media' noInit className='sm:w-1/2 mt-4 sm:mt-0 sm:pl-2'>
          <MediaField {...props} rounded />
        </Animate>
      </div>
    </div>
  );
}

function TitleAndText(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full flex flex-col gap-5 p-10 sm:p-13 lg:p-15'>
      <Logo />
      <Animate
        name='heading'
        noInit
        className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
      >
        <EditableFieldText {...props} field='heading' />
      </Animate>
      <Animate name='subtitle' noInit>
        <EditableFieldText {...props} field='subtitle' />
      </Animate>
    </div>
  );
}

function BulletPoints(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full flex flex-col gap-5 p-10 sm:p-13 lg:p-15'>
      <Logo />
      <Animate
        name='heading'
        noInit
        className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
      >
        <EditableFieldText {...props} field='heading' />
      </Animate>
      <div className='min-h-0 flex-1 text-base sm:text-xl lg:text-2xl'>
        <EditableBulletPoints {...props} />
      </div>
    </div>
  );
}

function BulletPointsMedia(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full h-full flex flex-col md:justify-center p-10 sm:p-13 lg:p-15'>
      <div className='flex flex-col sm:flex-row sm:items-start sm:justify-between md:gap-8'>
        <div className='flex flex-col gap-5 md:w-1/2'>
          <Logo />
          <Animate
            name='heading'
            noInit
            className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
          >
            <EditableFieldText {...props} field='heading' />
          </Animate>
          <div className='min-h-0 flex-1 text-base sm:text-xl lg:text-2xl'>
            <EditableBulletPoints {...props} />
          </div>
        </div>
        <Animate name='media' noInit className='sm:w-1/2 mt-4 sm:mt-0 sm:pl-2'>
          <MediaField {...props} rounded />
        </Animate>
      </div>
    </div>
  );
}

function Classic(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full flex flex-col sm:flex-row gap-5 p-10 sm:p-13 lg:p-15'>
      <div className='w-full sm:w-1/2 flex flex-col gap-5'>
        <Logo />
        <Animate
          name='heading'
          noInit
          className='text-xl font-bold leading-normal'
        >
          <EditableFieldText {...props} field='heading' />
        </Animate>
        <Animate name='subtitle' noInit className='flex-1'>
          <EditableFieldText {...props} field='subtitle' />
        </Animate>
      </div>

      <Animate name='media' noInit className='w-full sm:w-1/2'>
        <MediaField {...props} rounded />
      </Animate>
    </div>
  );
}

function FreeForm(props: TrainingSlideEditorProps<SlideBlock>) {
  const { block } = props;
  const body = block.fields.freeFormBody;
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const uploadFile = useLiveCallback(async (file: File) => {
    const mediaResp = await apiService.media.upload(file, {
      scene: EnumsMediaScene.MediaSceneBlockMedia,
      contentType: file.type,
    });
    return mediaResp.data.media.url;
  });

  const editor = useCreateBlockNote({
    animations: false,
    initialContent: body?.json
      ? (body.json.content as PartialBlock[])
      : undefined,
    uploadFile,
  });

  const { call: initFromMarkdown } = useLiveAsyncCall(async () => {
    if (!body?.markdown || body.markdownUpdatedAt < body.jsonUpdatedAt) return;
    try {
      const blocks = await editor.tryParseMarkdownToBlocks(body.markdown);
      if (blocks.length === 0) return;
      editor.replaceBlocks(editor.document, blocks);
      const next = {
        ...body,
        json: { content: blocks },
        jsonUpdatedAt: Date.now(),
      };
      onChange('freeFormBody', next);
      onBlur('freeFormBody', next);
    } catch (error) {
      logger.error('Error update freeform from markdown', err2s(error));
    }
  });

  useEffect(() => {
    initFromMarkdown();
  }, [initFromMarkdown]);

  const toBody = useLiveCallback(async () => {
    const content = editor.document;
    const now = Date.now();
    return {
      json: { content },
      jsonUpdatedAt: now,
      markdown: await editor.blocksToMarkdownLossy(content),
      markdownUpdatedAt: now,
    };
  });

  useEffect(() => {
    return editor.onUploadEnd(() => {
      // note: need a timeout to ensure the content is updated
      setTimeout(async () => {
        const next = await toBody();
        onChange('freeFormBody', next);
        onBlur('freeFormBody', next);
      }, 0);
    });
  }, [editor, onChange, onBlur, toBody]);

  return (
    <BlockNoteView
      editor={editor}
      theme='dark'
      data-theming-slide-block
      onChange={async () => {
        onChange('freeFormBody', await toBody());
      }}
      onBlur={async () => {
        onBlur('freeFormBody', await toBody());
      }}
    />
  );
}

function Content(props: TrainingSlideEditorProps<SlideBlock>) {
  switch (props.block.fields.layout) {
    case EnumsSlideLayout.SlideLayoutClassic:
      return <Classic {...props} />;
    case EnumsSlideLayout.SlideLayoutTitleAndText:
      return <TitleAndText {...props} />;
    case EnumsSlideLayout.SlideLayoutBigTitle:
      return <BigTitle {...props} />;
    case EnumsSlideLayout.SlideLayoutBulletPoints:
      return <BulletPoints {...props} />;
    case EnumsSlideLayout.SlideLayoutFullHost:
      return null;
    case EnumsSlideLayout.SlideLayoutQuote:
      return <Quote {...props} />;
    case EnumsSlideLayout.SlideLayoutBigMedia:
      return <BigMedia {...props} />;
    case EnumsSlideLayout.SlideLayoutQuoteMedia:
      return <QuoteMedia {...props} />;
    case EnumsSlideLayout.SlideLayoutBigTitleMedia:
      return <BigTitleMedia {...props} />;
    case EnumsSlideLayout.SlideLayoutBulletPointsMedia:
      return <BulletPointsMedia {...props} />;
    case EnumsSlideLayout.SlideLayoutFreeForm:
      return <FreeForm {...props} />;
    default:
      assertExhaustive(props.block.fields.layout);
      return null;
  }
}

function FullHostAvatar(
  props: TrainingSlideEditorProps<SlideBlock> & {
    className?: string;
    placeholder?: boolean;
  }
) {
  const personalityId =
    props.block.fields.dialogue?.entries?.at(0)?.personalityId;
  const personality = usePersonality(personalityId, {
    fallbackToDefault: true,
  });
  const mediaUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(personality?.profileImage?.media),
    {
      priority: ImagePickPriorityHighToLow,
    }
  );
  if (!mediaUrl) return null;
  return (
    <div className={`relative w-full h-full ${props.className}`}>
      <img src={mediaUrl} alt='' className='w-full h-full object-cover' />
      {props.placeholder && (
        <div className='absolute inset-0 rounded-full flex justify-center items-center'>
          <p className={`text-icon-gray font-bold text-5xl`}>Placeholder</p>
        </div>
      )}
    </div>
  );
}

function Background(props: TrainingSlideEditorProps<SlideBlock>) {
  const { block } = props;

  const { color: brandColor } = useOrgBrandColor();
  const placeholder =
    VoiceOverUtils.IsDialogueRendering(block.fields.dialogue) ||
    VoiceOverUtils.IsDialogueRequiresRender(block.fields.dialogue);

  if (block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost)
    return (
      <div className='absolute inset-0'>
        <FullHostAvatar
          {...props}
          className='w-full h-full object-cover'
          placeholder={placeholder}
        />
      </div>
    );

  if (props.block.fields.layout === EnumsSlideLayout.SlideLayoutBigMedia) {
    const firstFrameUrl = MediaUtils.PickMediaUrl(
      fromMediaDTO(props.block.fields.media?.media),
      {
        videoThumbnail: 'first',
        priority: ImagePickPriorityHighToLow,
      }
    );

    if (!firstFrameUrl) return null;

    return (
      <div className='absolute inset-0 overflow-hidden'>
        <img
          src={firstFrameUrl ?? ''}
          alt=''
          className='w-full h-full object-cover blur opacity-50 select-none'
        />
      </div>
    );
  }

  if (
    block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionHost
  ) {
    return (
      <div className='absolute inset-0'>
        <FullHostAvatar
          {...props}
          className='w-full h-full object-cover'
          placeholder={placeholder}
        />
        <Animate
          name='overlay'
          noInit
          className={`absolute inset-0 bg-opacity-60 bg-black`}
        />
      </div>
    );
  }

  if (
    block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionBrand
  ) {
    return (
      <div
        className='absolute inset-0 opacity-20'
        style={{
          backgroundColor: brandColor,
        }}
      />
    );
  }

  if (
    block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionMedia
  ) {
    const firstFrameUrl = MediaUtils.PickMediaUrl(
      fromMediaDTO(block.fields.backgroundMedia?.media),
      {
        videoThumbnail: 'first',
        priority: ImagePickPriorityHighToLow,
      }
    );
    return !firstFrameUrl ? null : (
      <div className='absolute inset-0'>
        <img
          src={firstFrameUrl ?? ''}
          alt=''
          className='w-full h-full object-cover select-none'
        />
      </div>
    );
  }

  return null;
}

function Left(props: TrainingSlideEditorProps<SlideBlock>) {
  const isFullHostLayout =
    props.block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost;
  const showContent = !isFullHostLayout;

  return (
    <>
      <Background {...props} />

      <div className='relative w-full h-full flex flex-col text-white'>
        {isFullHostLayout && <Captions>Caption will display here</Captions>}
        {showContent && (
          <div className='flex-1 min-h-0'>
            <ScrollableContent>
              <Content {...props} />
            </ScrollableContent>
          </div>
        )}
        {!props.block.fields.hideButton && (
          <footer
            className={`${
              isFullHostLayout ? 'mt-auto' : ''
            } mt-auto w-full flex items-center justify-center gap-2 p-3 pb-5`}
          >
            {Boolean(props.block.fields.dialogue?.entries?.length) && (
              <CommonButton
                variant='gray'
                styles={{
                  size: 'h-full',
                  disabled: 'disabled:opacity-100 disabled:cursor-auto',
                }}
                style={{
                  aspectRatio: '1/1',
                }}
                disabled
              >
                <RefreshIcon className='w-4 h-4 fill-current' />
              </CommonButton>
            )}
            <CommonButton
              variant='brand'
              className='flex-none'
              styles={{
                disabled: 'disabled:opacity-100 disabled:cursor-auto',
              }}
              disabled
            >
              <EditableFieldText {...props} field='buttonText' />
            </CommonButton>
          </footer>
        )}
      </div>
    </>
  );
}

function Logo() {
  const animator = useBlockAnimator<SlideBlockAnimationKey>();
  return <TrainingLogo ref={animator.ref('logo', false)} />;
}

function HideButtonField(props: TrainingSlideEditorProps<SlideBlock>) {
  const {
    value: hideButton,
    updateLocal,
    updateRemote,
  } = useBlockFieldController(props.block, 'hideButton');

  return (
    <div>
      <p className='text-base text-white font-bold mb-1'>Button</p>
      <div className='flex items-center justify-between'>
        <div>
          <p className='text-sms'>Show "Continue" button?</p>
          {/* <p className='text-icon-gray text-xs'>
            {hideButton
              ? 'Auto-advance to next slide'
              : 'Advance with button click'}
          </p> */}
        </div>
        <SwitcherControlled
          name={`${props.block.id}-hideButton`}
          checked={!hideButton}
          onChange={(checked: boolean): void => {
            updateLocal(!checked);
            updateRemote(!checked);
          }}
        />
      </div>
    </div>
  );
}

function getAnimatableKeysForBlock(
  block: SlideBlock
): SlideBlockAnimationKey[] {
  const result: SlideBlockAnimationKey[] = [];
  if (
    block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionHost
  ) {
    result.push('overlay');
  }
  switch (block.fields.layout) {
    case EnumsSlideLayout.SlideLayoutQuote:
    case EnumsSlideLayout.SlideLayoutBigTitle:
    case EnumsSlideLayout.SlideLayoutTitleAndText:
      result.push('logo', 'heading', 'subtitle');
      return result;
    case EnumsSlideLayout.SlideLayoutQuoteMedia:
    case EnumsSlideLayout.SlideLayoutBigTitleMedia:
    case EnumsSlideLayout.SlideLayoutClassic:
      result.push('logo', 'heading', 'subtitle', 'media');
      return result;
    case EnumsSlideLayout.SlideLayoutBulletPoints: {
      result.push('logo', 'heading');
      for (let i = 0; i < block.fields.bulletPoints.length; i++) {
        result.push(`bullet-${i + 1}`);
      }
      return result;
    }
    case EnumsSlideLayout.SlideLayoutBulletPointsMedia: {
      result.push('logo', 'heading', 'media');
      for (let i = 0; i < block.fields.bulletPoints.length; i++) {
        result.push(`bullet-${i + 1}`);
      }
      return result;
    }
    case EnumsSlideLayout.SlideLayoutBigMedia:
      result.push('media');
      return result;
    case EnumsSlideLayout.SlideLayoutFullHost:
      return result;
    case EnumsSlideLayout.SlideLayoutFreeForm:
      return result;
    default:
      assertExhaustive(block.fields.layout);
      return [];
  }
}

function findTriggers(dialogue: Nullable<ModelsDialogue>) {
  if (!dialogue) return [];

  const triggerSet = new Set<string>();
  for (let i = 0; i < dialogue.entries.length; i++) {
    const entry = dialogue.entries[i];
    const matches = entry.script.matchAll(/<mark name="([^"]+)"\s*\/>/g);
    for (const match of matches) {
      triggerSet.add(match[1]);
    }
  }
  return Array.from(triggerSet);
}

function Right(props: TrainingSlideEditorProps<SlideBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const { data: personalities } = usePersonalities();

  const isRendering = VoiceOverUtils.IsDialogueRendering(block.fields.dialogue);

  const confirmFullHostChange = async (fullHost: boolean) => {
    if (isRendering) {
      await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='p-5 text-white'>
            <div className='text-2xl font-medium text-center'>Attention</div>
            <div className='mt-4 text-sms'>
              You are not able to change the layout while the script avatars are
              rendering.
            </div>
          </div>
        ),
        confirmBtnLabel: 'Confirm',
        confirmBtnVariant: 'primary',
        confirmOnly: true,
      });
      return false;
    }

    // from full host to anything else
    if (!fullHost) {
      // if there are rendered avatars, we need to confirm.
      if (VoiceOverUtils.ContainsRenderedAvatars(block.fields.dialogue)) {
        const { result } = await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='p-5 text-white'>
              <div className='text-2xl font-medium text-center'>Warning</div>
              <div className='mt-4 text-sms'>
                This layout change will delete existing rendered avatars from
                this slide. This action cannot be undone.
              </div>
            </div>
          ),
          confirmBtnLabel: 'Confirm',
          confirmBtnVariant: 'delete',
          cancelBtnLabel: 'Cancel',
        });
        if (result === 'canceled') return false;
      }

      // convert all offline generated avatars to client generated.
      if (block.fields.dialogue) {
        const dialogue = VoiceOverUtils.ConvertDialogueToClient(
          block.fields.dialogue
        );
        onChange('dialogue', dialogue);
        onBlur('dialogue', dialogue);
      }
      return true;
    }

    // from anything else to full host
    if (fullHost) {
      //  if there are client generated avatars, we need to confirm.
      const containsClientGenerated = block.fields.dialogue?.entries.some(
        (e) =>
          e.generationType ===
          EnumsDialogueGenerationType.DialogueGenerationTypeClient
      );
      if (containsClientGenerated) {
        const { result } = await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='p-5 text-white'>
              <div className='text-2xl font-medium text-center'>Attention</div>
              <div className='mt-4 text-sms'>
                Changing to Full Host layout will require avatar generation.
                You'll need to render the avatars separately after the layout
                change.
              </div>
            </div>
          ),
          confirmBtnLabel: 'Continue',
          confirmBtnVariant: 'primary',
          cancelBtnLabel: 'Cancel',
        });
        if (result === 'canceled') return false;
      }

      if (block.fields.dialogue) {
        const dialogue = VoiceOverUtils.ConvertDialogueToOffline(
          block.fields.dialogue,
          personalities ?? []
        );
        onChange('dialogue', dialogue);
        onBlur('dialogue', dialogue);
      }

      return true;
    }
  };

  const fullHost =
    block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost ||
    block.fields.backgroundOption ===
      EnumsSlideBackgroundOption.SlideBackgroundOptionHost;
  const isBackgroundSelectHidden =
    block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost;

  const handleLayoutChange = async (value: EnumsSlideLayout) => {
    const fullHostAfter =
      value === EnumsSlideLayout.SlideLayoutFullHost ||
      block.fields.backgroundOption ===
        EnumsSlideBackgroundOption.SlideBackgroundOptionHost;

    if (fullHost !== fullHostAfter) {
      const success = await confirmFullHostChange(fullHostAfter);
      if (!success) return;
    }

    onChange('layout', value);
    onBlur('layout', value);
  };

  return (
    <div className='w-full h-full flex flex-col gap-5 text-white overflow-y-auto scrollbar'>
      <div className='flex-1 max-h-[50%] overflow-visible'>
        <DialogueEditor
          key={block.id}
          offlineRendering={fullHost}
          value={block.fields.dialogue}
          onChange={(value) => {
            onChange('dialogue', value);
            onBlur('dialogue', value);

            // if we removed any triggers, we should remove them from the animations too.
            const triggers = findTriggers(value);
            const animations = block.fields.animations ?? {};
            const next = cloneDeep(animations);
            for (const [k, anim] of Object.entries(animations)) {
              if (
                anim?.enter?.triggerId &&
                !triggers.includes(anim.enter.triggerId)
              ) {
                delete next[k as SlideBlockAnimationKey];
              }
            }
            onChange('animations', next);
            onBlur('animations', next);
          }}
        />
      </div>

      <label>
        <p className='text-base text-white font-bold mb-1'>Slide Layout</p>
        <SlideBlockLayoutSelect
          value={block.fields.layout}
          onChange={handleLayoutChange}
        />
      </label>

      {!isBackgroundSelectHidden && (
        <label>
          <p className='text-base text-white font-bold mb-1'>Background</p>
          <SlideBlockBackgroundSelect
            value={
              block.fields.backgroundOption ??
              EnumsSlideBackgroundOption.SlideBackgroundOptionNone
            }
            onChange={async (value) => {
              const fullHostAfter =
                block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost ||
                value === EnumsSlideBackgroundOption.SlideBackgroundOptionHost;
              if (fullHost !== fullHostAfter) {
                const success = await confirmFullHostChange(fullHostAfter);
                if (!success) return;
              }

              onChange('backgroundOption', value);
              onBlur('backgroundOption', value);
            }}
          />
        </label>
      )}

      <label>
        <p className='text-base text-white font-bold mb-1'>Background Music</p>
        <BlockMusicSelect
          value={block.fields.bgMusic}
          onChange={(value) => {
            onChange('bgMusic', value);
            // Do not persist the decorated media object.
            const out = cloneDeep(value);
            delete out?.asset.media;
            onBlur('bgMusic', out);
          }}
        />
      </label>

      <HideButtonField {...props} />
      <div className='flex-1 min-h-0 max-h-[25%]'>
        <BlockAnimationsEditor
          {...props}
          getAnimatableKeysForBlock={getAnimatableKeysForBlock}
          triggers={findTriggers(block.fields.dialogue)}
          preview
        />
      </div>
    </div>
  );
}

export function SlidesBlockEditorUnder(
  props: TrainingSlideEditorProps<SlideBlock>
) {
  return (
    <div className='flex flex-col gap-5 text-white'>
      <BackgroundMediaField {...props} />
    </div>
  );
}

export function SlideBlockEditor(
  props: TrainingSlideEditorProps<SlideBlock> & {
    animator: BlockAnimator<SlideBlockAnimationKey>;
  }
) {
  useEffect(() => {
    props.animator.setAnimations(props.block.fields.animations ?? {}, false);
  }, [props.animator, props.block.fields.animations]);

  return (
    <BlockAnimatorProvider value={props.animator}>
      <Left {...props} />
    </BlockAnimatorProvider>
  );
}

export function SlideBlockSidebarEditor(
  props: TrainingSlideEditorProps<SlideBlock> & {
    animator: BlockAnimator<SlideBlockAnimationKey>;
  }
) {
  return <Right {...props} />;
}
