import { useMemo, useState } from 'react';
import Select from 'react-select';

import {
  type DtoGamePack,
  ScormVersion,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { SiteBaseURL } from '../../services/public';
import { type GamePack } from '../../types/game';
import { downloadFile } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../Loading';

function ScormExportPanel(props: {
  pack: GamePack | DtoGamePack;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { pack } = props;
  const [scormVersion, setScormVersion] = useState<ScormVersion>(
    ScormVersion.V12
  );

  const {
    call: exportScormPackage,
    state: { state: exportState },
  } = useLiveAsyncCall(async () => {
    const url = new SiteBaseURL();
    url.pathname = `/game-packs/${pack.id}/share`;
    url.searchParams.set('guest-users', 'enabled');
    const resp = await apiService.gamePack.exportScormPackage(pack.id, {
      version: scormVersion,
      url: url.toString(),
    });
    const data = resp.data;
    downloadFile(
      URL.createObjectURL(data),
      `${pack.name} - SCORM ${scormVersion}.zip`
    );
  });

  const styles = useMemo(() => buildReactSelectStyles(), []);

  const options = useInstance(() => [
    { value: ScormVersion.V12, label: 'SCORM 1.2' },
    { value: ScormVersion.V20042ndEdition, label: 'SCORM 2004 2nd Edition' },
    { value: ScormVersion.V20043rdEdition, label: 'SCORM 2004 3rd Edition' },
    { value: ScormVersion.V20044thEdition, label: 'SCORM 2004 4th Edition' },
  ]);

  return (
    <div className='w-full flex flex-col items-center justify-center gap-6 py-7.5 px-10 bg-dark-gray'>
      <header className='text-center text-xl font-medium text-white'>
        Download as SCORM
      </header>
      <main className='flex flex-col items-center justify-center gap-10'>
        <p className='text-white text-base text-center'>
          Want to run your course on an LMS or SCORM cloud? Download our xAPI
          enabled SCORM package and add directly to your LMS.
        </p>
        <p className='text-icon-gray text-sms text-center'>
          The SCORM package will include image, font, video, audio everything
          you need for the course.
        </p>

        <Select<Option<ScormVersion>, false>
          classNamePrefix='select-box-v2'
          className='w-full'
          styles={styles}
          value={options.find((o) => o.value === scormVersion) ?? null}
          options={options}
          onChange={(option) => {
            if (!option) return;
            setScormVersion(option.value);
          }}
          isSearchable={false}
        />
      </main>
      <footer className='flex items-center justify-center gap-2.5'>
        <button
          type='button'
          className='btn-secondary w-34 h-10 font-medium'
          onClick={props.onCancel}
        >
          Cancel
        </button>
        <button
          type='button'
          className='btn-primary w-48 h-10 font-medium flex items-center justify-center gap-1'
          disabled={exportState.isRunning}
          onClick={exportScormPackage}
        >
          {exportState.isRunning && <Loading text='' />}
          <p>Download SCORM</p>
        </button>
      </footer>
    </div>
  );
}

export function useOpenScormExportModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback((pack: GamePack | DtoGamePack) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-160'
          borderStyle='gray'
          onClose={p.internalOnCancel}
        >
          <ScormExportPanel
            pack={pack}
            onConfirm={p.internalOnConfirm}
            onCancel={p.internalOnCancel}
          />
        </ModalWrapper>
      ),
    });
  });
}
