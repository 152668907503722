import {
  ClientAspectRatio,
  EnumsExternalMediaProvider,
  type SearchExternalMediaParams,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import { uuidv4 } from '../../../utils/common';
import { b64toURL } from '../../../utils/media';
import { AIIcon } from '../../icons/AIIcon';
import { type MediaItem, type MediaProvider } from '../MediaProvider';

function transformAspectRatio(
  aspectRatio: SearchExternalMediaParams['aspectRatio']
) {
  switch (aspectRatio) {
    case 'square':
      return ClientAspectRatio.ASPECTRATIO_SQUARE;
    case 'tall':
      return ClientAspectRatio.ASPECTRATIO_TALL;
    default:
      return ClientAspectRatio.ASPECTRATIO_WIDE;
  }
}

export const ImageGenProvider: MediaProvider = {
  name: 'Image AI',
  kind: EnumsExternalMediaProvider.ExternalMediaProviderImageGen,
  proxy: false,
  filter: {
    aspectRatio: true,
  },
  icon: <AIIcon className='w-8 h-8 p-2 rounded-lg bg-white' />,
  ui: {
    noResultsText: 'We could not generate any images for this text.',
    zeroStateText: 'Generate images from text by our AI',
    inputPlaceholder: 'Describe the image you want to generate',
    inputIcon: AIIcon,
  },
  async searchMedia(params): Promise<MediaItem[]> {
    const response = await apiService.aiGeneral.generateImages({
      aspectRatio: transformAspectRatio(params.aspectRatio),
      num: 4,
      prompt: params.q,
    });

    return response.data.images.map((image) => {
      const url = b64toURL(image.b64);
      return {
        id: uuidv4(),
        url: url,
        thumbnailUrl: url,
        provider: EnumsExternalMediaProvider.ExternalMediaProviderImageGen,
      };
    });
  },
};
