import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type Media,
  type MediaFormat,
  MediaFormatVersion,
  MediaTranscodeStatus,
  MediaType,
} from '@lp-lib/media';

import config from '../config';
import { type MessageMedia } from '../types';
import { assertExhaustive, uuidv4, xDomainifyUrl } from './common';
import { UnplayableMediaFactory } from './unplayable';

export const MediaPickPriorityHD = Object.freeze([
  MediaFormatVersion.HD,
  MediaFormatVersion.Raw,
] as const);

export const MediaPickPriorityFHD = Object.freeze([
  MediaFormatVersion.FHD,
  MediaFormatVersion.HD,
  MediaFormatVersion.Raw,
] as const);

export const ImagePickPriorityDefault = Object.freeze([
  MediaFormatVersion.MD,
  MediaFormatVersion.HD,
  MediaFormatVersion.Raw,
  MediaFormatVersion.SM,
] as const);

export const ImagePickPriorityLowToHigh = Object.freeze([
  MediaFormatVersion.SM,
  MediaFormatVersion.MD,
  MediaFormatVersion.HD,
  MediaFormatVersion.Raw,
] as const);

export const ImagePickPriorityHighToLow = Object.freeze([
  MediaFormatVersion.HD,
  MediaFormatVersion.MD,
  MediaFormatVersion.SM,
  MediaFormatVersion.Raw,
] as const);

/**
 * Defines the length to show image media.
 */
export const IMAGE_DURATION_MS = 3000;

const VideoPickPriorityDefault = MediaPickPriorityHD;

const AudioPickPriorityDefault = Object.freeze([
  MediaFormatVersion.HQ,
  MediaFormatVersion.SQ,
  MediaFormatVersion.Raw,
] as const);

export interface PickMediaUrlOptions {
  priority?: readonly MediaFormatVersion[];
  videoThumbnail?: 'first' | 'last';
}

export class MediaUtils {
  static PickMediaFormat(
    media: Media | null | undefined,
    options?: Pick<PickMediaUrlOptions, 'priority'>
  ): MediaFormat | null {
    const { priority } = options || {};
    let found: MediaFormat | null = null;
    if (media) {
      if (media.transcodeStatus === MediaTranscodeStatus.Ready) {
        const selectPriority =
          priority ||
          (media.type === MediaType.Image
            ? ImagePickPriorityDefault
            : media.type === MediaType.Video
            ? VideoPickPriorityDefault
            : AudioPickPriorityDefault);
        const mapping: { [K in MediaFormatVersion]?: MediaFormat } = {};
        media.formats.forEach((f) => (mapping[f.version] = f));

        for (const v of selectPriority) {
          const format = mapping[v];
          if (format) {
            found = format;
            break;
          }
        }
      }
      if (!found)
        found = media.formats.find((f) => f.url === media.url) ?? null;
    }
    return found;
  }

  static GetMediaTypeLabel(media: Media | null | undefined) {
    const mediaType = media?.type;
    switch (mediaType) {
      case MediaType.Image:
        return 'Image';
      case MediaType.Video:
        return 'Video';
      case MediaType.Audio:
        return 'Audio';
      case undefined:
        return 'Unknown';
      default:
        assertExhaustive(mediaType);
        return '';
    }
  }

  static PickMediaUrl(
    media: Media | null | undefined,
    options?: PickMediaUrlOptions
  ): string | null {
    const { videoThumbnail } = options ?? {};
    let mediaUrl: string | null = null;

    if (media && videoThumbnail && media.type === MediaType.Video) {
      mediaUrl =
        videoThumbnail === 'first'
          ? media.firstThumbnailUrl ?? null
          : media.lastThumbnailUrl ?? null;
    } else if (media) {
      const picked = MediaUtils.PickMediaFormat(media, options);
      mediaUrl = picked?.url ?? media.url ?? null;
    }

    return mediaUrl;
  }

  static GetAVDurationMs(media: Media | MediaFormat | null): number {
    if (!media) return 0;
    if ('version' in media) {
      return media.length;
    }
    const durations = media.formats
      .map((f) => f.length)
      .filter((l) => Boolean(l) && l > 0);
    const duration = durations[0];
    if (!duration) return 0;
    return duration;
  }

  static GetAVPlayingDurationSeconds(
    media: Media | null,
    hasAnimation = false
  ): number {
    const durationMs = MediaUtils.GetAVDurationMs(media);
    const withAnim = hasAnimation ? durationMs + 3000 : durationMs;
    return Math.round(withAnim / 1000);
  }

  static IsMediaObject(obj: unknown): obj is Media {
    return (
      !!obj &&
      typeof obj === 'object' &&
      'id' in obj &&
      'url' in obj &&
      'transcodeStatus' in obj
    );
  }

  /**
   * Returns the duration of the given Media object. When the Media object is an image uses a preset duration.
   * Note: unlike GetVideoPlayingDurationSeconds this function does not account for "animation" durations.
   */
  static GetMediaDurationMs(media: Media | null | undefined): number {
    if (!media) return 0;

    switch (media.type) {
      case MediaType.Image:
        return IMAGE_DURATION_MS;
      case MediaType.Video:
        return MediaUtils.GetAVDurationMs(media);
      case MediaType.Audio:
        return MediaUtils.GetAVDurationMs(media);
      default:
        assertExhaustive(media.type);
        return 0;
    }
  }

  static GetMediaViewerURL(mediaId: string): string {
    return `${window.origin}/media/${mediaId}`;
  }

  static CovertToMessageMedia(
    media: Media,
    options?: {
      title?: string;
    }
  ): MessageMedia | null {
    switch (media.type) {
      case MediaType.Image:
        return {
          mediaUrl: this.PickMediaUrl(media) || '',
          title: '',
          thumbnailUrl: '',
          type: 'image',
        };
      case MediaType.Video:
        const mediaUrl = config.slack.mediaViewerEnabled
          ? this.GetMediaViewerURL(media.id)
          : this.PickMediaUrl(media);

        return {
          type: 'video',
          title: options?.title ?? 'video.mp4',
          mediaUrl: mediaUrl || '',
          thumbnailUrl: media.firstThumbnailUrl || '',
        };
      case MediaType.Audio:
        return null;
      default:
        assertExhaustive(media.type);
        return null;
    }
  }

  static IntoFakeMedia(
    item: MediaFormat | null | undefined,
    type: MediaType,
    optionalOverrides?: Partial<Media>
  ): Media | null {
    if (!item) return null;
    return {
      id: uuidv4(),
      type,
      url: item.url,
      hash: optionalOverrides?.hash ?? uuidv4().replace('-', ''),
      uid: '00000000-0000-0000-0000-000000000001',
      transcodeStatus: MediaTranscodeStatus.Ready,
      scene: null,
      formats: [
        {
          ...item,
          version: MediaFormatVersion.Raw,
        },
        {
          ...item,
        },
      ],
      createdAt: '',
      updatedAt: '',
      ...optionalOverrides,
    };
  }

  static IntoFakeAudioMediaFromBytes(
    src: string,
    bytes: Blob,
    durationMs: number
  ) {
    return this.IntoFakeMedia(
      {
        version: MediaFormatVersion.HQ,
        url: src,
        width: 0,
        height: 0,
        size: bytes.size,
        length: durationMs,
      },
      MediaType.Audio,
      {
        scene: 'tts',
      }
    );
  }

  static async IntoUnplayable(m: Media, xDomainify = true) {
    const format = MediaUtils.PickMediaFormat(m);
    let src = format?.url;
    if (!src) throw new Error('No audio found!');
    src = xDomainify && !src.startsWith('blob:') ? xDomainifyUrl(src) : src;

    const unplayable = UnplayableMediaFactory.From(src, m.type);
    await unplayable.intoPlayable();
    return unplayable;
  }
}

export function loadImageAsPromise(
  url: string,
  cors?: boolean
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    if (cors) img.crossOrigin = 'anonymous';
    img.onload = () => resolve(img);
    img.onerror = (err) => reject({ err, url });
    img.src = url;
  });
}

export function loadCanvasBlobAsPromise(
  cvs: HTMLCanvasElement,
  type?: string,
  quality?: unknown
): Promise<Blob | null> {
  return new Promise((resolve) => {
    cvs.toBlob(resolve, type, quality);
  });
}

export function getImageDimensions(
  dataUrl: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, _reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = dataUrl;
  });
}

export function releaseMediaStream(stream?: MediaStream | null): void {
  if (!stream) return;
  stream.getTracks().forEach((track) => track.stop());
}

export function removeAllTracks(stream?: MediaStream | null): void {
  if (!stream) return;
  const tracks = stream.getTracks();
  tracks.forEach((track) => stream.removeTrack(track));
}

export function getDeviceIdFromTrack(
  track: MediaStreamTrack | null | undefined
): string | null {
  if (!track) return null;
  return track.getSettings().deviceId || null;
}

export type Profile = {
  width: number;
  height: number;
  frameRate: number;
};

export type ProfileIndex = '720p';

export function profileFor(p: ProfileIndex): Profile {
  switch (p) {
    case '720p':
      return {
        width: 1280,
        height: 720,
        frameRate: 15,
      };
    default:
      assertExhaustive(p);
      return {
        width: 1280,
        height: 720,
        frameRate: 15,
      };
  }
}

export function buildSrcSet(urls: string[], preload?: boolean): string {
  return urls
    .map((url) => {
      if (preload) {
        const img = new Image();
        img.src = url;
      }
      const m = url.match(/(.*?)@(\d+x)/);
      return `${url}${m && m[2] !== '1x' ? ` ${m[2]}` : ''}`;
    })
    .join(', ');
}

export function captureStreamFromVideo(
  el: HTMLMediaElement
): MediaStream | undefined {
  if (el.captureStream) {
    return el.captureStream();
  } else if (el.mozCaptureStream) {
    return el.mozCaptureStream();
  }
}

export function b64toURL(b64: string, mimeType = 'image/png') {
  return `data:${mimeType};base64,${b64}`;
}

export function b64URLtoBlob(b64URL: string) {
  const buffer = Buffer.from(b64URL.split(',')[1], 'base64');
  return new Blob([buffer]);
}

export function b64toMasqueradeMedia(
  b64: string,
  scene = EnumsMediaScene.MediaSceneGamePackCover
): Media {
  return {
    id: uuidv4(),
    type: MediaType.Image,
    url: b64toURL(b64),
    hash: '',
    uid: '',
    transcodeStatus: MediaTranscodeStatus.Ready,
    scene,
    formats: [],
    createdAt: '',
    updatedAt: '',
  };
}
