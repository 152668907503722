import {
  type ClientLoaderFunctionArgs,
  Link,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { type ReactNode, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useTitle } from 'react-use';

import {
  type DtoHubSpotDeal,
  type DtoHubSpotDealAssociation,
  type DtoProduct,
  type HubspotLineItem,
} from '@lp-lib/api-service-client/public';

import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { NewWindowIcon } from '../components/icons/NewWindowIcon';
import { Loading } from '../components/Loading';
import {
  AllPricePicker,
  CustomPriceEditor,
  usePriceOptions,
} from '../components/Product/CustomPrice';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { err2s, makeTitle } from '../utils/common';
import { buildReactSelectStyles } from '../utils/react-select';

const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });
  const resp = await apiService.hubSpot.getDeal(id);
  const resp2 = await apiService.product.getPublicProducts();

  return { ...resp.data, products: resp2.data.marketed };
}

function DealBasicInfo(props: { deal: DtoHubSpotDeal }) {
  const { deal } = props;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const closeDate = deal.closeDate
    ? utcToZonedTime(deal.closeDate, timezone)
    : null;
  const owner = deal.owner
    ? `${deal.owner.firstName} ${deal.owner.lastName}`
    : deal.ownerId;

  const stage = deal.pipeline?.stages.find(
    (s) => s.id === deal.pipelineStageId
  );

  return (
    <div className='w-full border border-secondary rounded-lg p-2'>
      <header className='font-bold mb-2 text-base'>Basic Info</header>
      <div className='flex items-center gap-1'>
        <label className='font-bold'>Amount:</label>
        <p>{currency.format(Number(deal.amount))}</p>
      </div>
      <div className='flex items-center gap-1'>
        <label className='font-bold'>Close Date:</label>
        <p>{closeDate ? format(closeDate, 'MMM d, yyyy h:mm aa O') : 'N/A'}</p>
      </div>
      <div className='flex items-center gap-1'>
        <label className='font-bold'>Stage:</label>
        <p>
          {stage?.label ?? 'N/A'} ({deal.pipeline?.label ?? 'N/A'})
        </p>
      </div>
      <div className='flex items-center gap-1'>
        <label className='font-bold'>Owner:</label>
        <p>{owner}</p>
      </div>
    </div>
  );
}

function DealContacts(props: {
  contacts: DtoHubSpotDealAssociation['contacts'];
}) {
  const { contacts } = props;
  return (
    <div className='w-full border border-secondary rounded-lg p-2'>
      <header className='font-bold mb-2 text-base'>
        Contacts ({contacts.length})
      </header>
      <div className='flex flex-col gap-1'>
        {contacts.map((contact) => (
          <div key={contact.id}>
            {contact.firstname} {contact.lastname} ({contact.email})
          </div>
        ))}
      </div>
    </div>
  );
}

function DealLineItems(props: {
  lineItems: DtoHubSpotDealAssociation['lineItems'];
}) {
  const { lineItems } = props;
  return (
    <div className='w-full border border-secondary rounded-lg p-2'>
      <header className='font-bold mb-2 text-base'>
        Line Items ({lineItems.length})
      </header>
      <div className='flex flex-col gap-1'>
        {lineItems.map((lineItem) => (
          <div key={lineItem.hs_object_id} className='flex flex-col gap-0.5'>
            <div className='flex items-center gap-1'>
              <p>{lineItem.name}</p>
              <p className='text-xs text-icon-gray'>x{lineItem.quantity}</p>
              <p className='ml-auto'>
                {currency.format(Number(lineItem.amount))}
              </p>
            </div>
            <div className='text-icon-gray text-sms'>
              URL: {lineItem.hs_url || '(Not Set)'}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function CustomizeDeal(props: {
  deal: DtoHubSpotDeal;
  association: DtoHubSpotDealAssociation;
  lineItem: HubspotLineItem;
  products: DtoProduct[];
  setSidePanel: (panel: ReactNode) => void;
}) {
  const { deal, lineItem, products } = props;
  const form = useForm<{
    productId?: string | null;
    stripePriceId?: string | null;
  }>({
    defaultValues: {
      productId: lineItem.hs_sku?.split(',')[0] || null,
      stripePriceId: lineItem.hs_sku?.split(',')[1] || null,
    },
  });
  const styles = useMemo(
    () =>
      buildReactSelectStyles({
        override: {
          control: {
            height: '40px',
          },
          groupHeading: {
            fontSize: '0.75rem',
            fontWeight: 600,
            color: '#8B9294',
            textTransform: 'uppercase',
            letterSpacing: '0.05rem',
          },
        },
      }),
    []
  );
  const productId = form.watch('productId');
  const product = products.find((p) => p.id === productId);
  const { priceOptions, isLoading, mutate } = usePriceOptions(product);

  const openAddCustomPriceEditor = () => {
    if (!product) return;
    props.setSidePanel(
      <CustomPriceEditor
        product={product}
        defaultAmount={Number(lineItem.amount)}
        onCancel={() => props.setSidePanel(null)}
        onSave={async (price) => {
          await mutate();
          form.setValue('stripePriceId', price.id);
          props.setSidePanel(null);
        }}
      />
    );
  };

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = form;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const nagivate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    if (!data.productId || !data.stripePriceId) return;
    try {
      const resp = await apiService.hubSpot.updateDealCustomPrice(deal.id, {
        productId: data.productId,
        stripePriceId: data.stripePriceId,
        lineItemId: lineItem.hs_object_id,
      });
      await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='p-3 text-white text-center'>
            <p className='text-2xl font-medium'>Price attached</p>
            <p className='mt-4 text-sm'>
              This is the{' '}
              <Link
                to={resp.data.paymentLinkUrl}
                target='_blank'
                className='underline text-primary'
              >
                payment link
              </Link>
              {'.'}
              <br />
              You can also check the latest{' '}
              <Link
                to={deal.link}
                target='_blank'
                className='underline text-primary'
              >
                deal
              </Link>{' '}
              on HubSpot.
            </p>
          </div>
        ),
        confirmBtnLabel: 'Okay',
        confirmOnly: true,
      });
      nagivate(0);
    } catch (error) {
      form.setError('root.serverError', {
        message: err2s(error) ?? 'Something went wrong, please try again.',
      });
      throw error;
    }
  });

  return (
    <form className='flex flex-col gap-4'>
      <header className='text-2xl font-medium'>Customize Deal Price</header>
      <main className='w-1/3 flex flex-col gap-4'>
        <Controller
          name='productId'
          control={form.control}
          rules={{ required: 'Please select the product' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className='w-full flex flex-col gap-1'>
              <label className='font-bold'>Product</label>
              <Select<DtoProduct>
                options={products}
                onChange={(o) => onChange(o?.id)}
                value={products.find((p) => p.id === value) ?? null}
                classNamePrefix='select-box-v2'
                className='w-full h-full'
                styles={styles}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
              {error && (
                <div className='text-sms text-red-002'>{error.message}</div>
              )}
            </div>
          )}
        />
        <Controller
          name='stripePriceId'
          control={form.control}
          rules={{ required: 'Please select the price' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <div className='w-full flex flex-col gap-1'>
                <div className='flex items-center justify-between'>
                  <label className='font-bold'>Price</label>
                  {productId && !isLoading && (
                    <button
                      type='button'
                      className='text-sms text-left text-primary underline'
                      onClick={openAddCustomPriceEditor}
                    >
                      Add new custom price
                    </button>
                  )}
                </div>
                {isLoading ? (
                  <Loading text='' />
                ) : (
                  <AllPricePicker
                    priceOptions={priceOptions}
                    disabled={!productId}
                    value={
                      priceOptions
                        .flatMap((o) => o.options)
                        .find((o) => o.id === value) ?? null
                    }
                    onChange={(o) => onChange(o?.id)}
                  />
                )}
                {error && (
                  <div className='text-sms text-red-002'>{error.message}</div>
                )}
              </div>
            );
          }}
        />
      </main>
      <footer className='flex items-center gap-4 mt-4'>
        <button
          type='button'
          className='btn-secondary w-30 h-10'
          disabled={!isDirty}
          onClick={() => reset()}
        >
          Reset
        </button>
        <button
          type='button'
          className='btn-primary w-60 h-10 flex items-center justify-center gap-1'
          onClick={onSubmit}
          disabled={!isDirty || isSubmitting}
        >
          {isSubmitting && <Loading text='' />}
          <p>Attach Price to Deal</p>
        </button>
      </footer>
    </form>
  );
}

export function Component() {
  const { deal, association, products } = useLoaderData<typeof clientLoader>();
  useTitle(makeTitle(deal.name));

  const [sidePanel, setSidePanel] = useState<ReactNode>(null);
  const customLineItem = association.lineItems.find((li) => !li.hs_product_id);
  const eligibleForCustomization =
    association.lineItems.length === 1 && customLineItem;

  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <div className='w-full relative text-white flex flex-col gap-10'>
        <header className='flex justify-between items-center'>
          <h1 className='font-bold text-3xl flex items-center gap-1'>
            <p>{deal.name}</p>
            <Link to={deal.link} target='_blank'>
              <NewWindowIcon className='w-6 h-6 fill-current' />
            </Link>
          </h1>
        </header>
        <div className='grid grid-cols-3 gap-4 text-sm'>
          <DealBasicInfo deal={deal} />
          <DealContacts contacts={association.contacts} />
          <DealLineItems lineItems={association.lineItems} />
        </div>
        {!eligibleForCustomization ? (
          <div className='text-red-002'>
            This deal is not eligible for customization, we only support deals
            with a single custom line item that is not associate with any
            HubSpot product. Please review your deal configuration.
          </div>
        ) : (
          <CustomizeDeal
            deal={deal}
            association={association}
            products={products}
            lineItem={customLineItem}
            setSidePanel={setSidePanel}
          />
        )}
        {sidePanel && (
          <div className='fixed inset-0 z-50'>
            <div className='absolute inset-0 bg-lp-black-001' />
            <div className='absolute top-0 bottom-0 right-0 w-1/2 bg-layer-001'>
              {sidePanel}
            </div>
          </div>
        )}
      </div>
    </AdminView>
  );
}
