import { useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import { type Block, type BlockType } from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { RoleUtils } from '../../types';
import { type Game, GameLikeLabelMap, type GamePack } from '../../types/game';
import { err2s } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { type Action, ActionSheet } from '../ActionSheet';
import { DragDropList } from '../common/DragDrop';
import { useBlockRecordingUploadDescForBlock } from '../GameRecorder/hooks/uploads';
import { BlockIcon } from '../icons/Block';
import { CheckedIcon } from '../icons/CheckedIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { DuplicateIcon } from '../icons/DuplicateIcon';
import { EditIcon } from '../icons/EditIcon';
import { EyeIcon } from '../icons/EyeIcon';
import { LPLogo } from '../icons/LPLogo';
import { PlusIcon } from '../icons/PlusIcon';
import { TimerIcon } from '../icons/TimerIcon';
import { Loading } from '../Loading';
import { BlockEditorStoreProvider, BlockTypeChooser } from '../RoutedBlock';
import {
  useShowStreamingToolsToggle,
  useStreamingToolsSubpanelState,
} from '../StreamingTools/StreamingToolsContext';
import { useUser } from '../UserContext';
import { BlockRecordingIcon } from './Blocks';
import { BlockEditor, useOndRecordingDetails } from './Blocks/Common/Editor';
import { BlockKnifeUtils } from './Blocks/Shared';
import { useTriggerEditGameModal } from './GameBaseEditor';
import { PrimeWarningMessage, useGameLikeEventEmitter } from './GameCenter';
import {
  useEditingGame,
  useGameEditorStore,
  useRefreshBlocksForGameEditor,
  useSelectedBlockId,
} from './GameEditorStore';
import { GameUtils } from './GameUtils';
import {
  useGameSessionBlock,
  useGameSessionStatus,
  usePlayedBlockIds,
} from './hooks';
import { useRecordingDeleteConfirmConfigForGame } from './OndRecordingUtils';
import { GameCover, GamePackCover } from './Utilities';

export function Header(props: {
  game: Game | GamePack;
  isSavingChanges: boolean;
  onClose: () => void;
}) {
  const { game, isSavingChanges, onClose } = props;

  const isAdmin = RoleUtils.isAdmin(useUser());
  const triggerEditGameModal = useTriggerEditGameModal();
  const isGameEditable = game.type === 'game';

  const handleClickGame = () => {
    if (!isGameEditable) return;

    triggerEditGameModal();
  };

  return (
    <div
      className={`w-full h-20 ${
        isAdmin ? 'bg-admin-red' : 'bg-black'
      } px-5 border-secondary border-b flex items-center justify-between`}
    >
      <div className={`flex items-center gap-5`}>
        <LPLogo type={isAdmin ? 'admin' : 'default'} />
        <div
          className={`text-xl text-white flex flex-row justify-start items-center ${
            isGameEditable ? 'group cursor-pointer' : ''
          }`}
          onClick={handleClickGame}
        >
          <div className='w-19 mr-4'>
            {game.type === 'gamePack' ? (
              <GamePackCover pack={game} />
            ) : (
              <GameCover game={game} className='w-18 h-12' />
            )}
          </div>
          <div className='flex flex-col'>
            <p
              className={`${
                game.isPrime ? 'text-red-002' : 'text-white'
              } text-2xs font-bold`}
            >
              {isGameEditable
                ? `${game.isPrime ? 'PRIME ' : ''}${GameLikeLabelMap[
                    game.type
                  ]?.toUpperCase()} EDITOR`
                : `PREVIEW ${GameLikeLabelMap[game.type]?.toUpperCase()}`}
            </p>
            <div className='flex flex-row items-center'>
              <p className='group-hover:underline'>{game.name}</p>
              {isGameEditable && (
                <button
                  className='btn ml-5 w-6 h-6 flex flex-row justify-center items-center 
          bg-secondary border border-solid border-secondary rounded-md hover:bg-secondary-hover'
                  type='button'
                >
                  <EditIcon className='w-2 h-2 fill-current' />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`flex items-center gap-5`}>
        <p className='text-secondary text-sms'>
          {isSavingChanges ? 'Saving changes...' : 'Changes saved'}
        </p>
        <button
          onClick={onClose}
          className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
          disabled={isSavingChanges}
          type='button'
        >
          {isSavingChanges ? <Loading /> : 'Done'}
        </button>
      </div>
    </div>
  );
}

function AddBlockButton(props: {
  disabled: boolean;
  onAddBlock: (type: BlockType) => void;
}) {
  const [showChooser, setShowChooser] = useState(false);

  return (
    <div className='relative'>
      <button
        type='button'
        onClick={() => setShowChooser(true)}
        disabled={props.disabled}
        className='btn-primary w-6 h-6 rounded flex justify-center items-center'
      >
        <PlusIcon />
      </button>

      {showChooser && (
        <div className='z-5 absolute left-10 -top-2'>
          <BlockTypeChooser
            onAddBlock={(type) => {
              props.onAddBlock(type);
              setShowChooser(false);
            }}
            onCancel={() => setShowChooser(false)}
          />
        </div>
      )}
    </div>
  );
}

function RecordingLogButton(props: { block: Block }) {
  const blockUploadDesc = useBlockRecordingUploadDescForBlock(
    props.block,
    'w-4 h-4'
  );
  const [, setSubpanelState] = useStreamingToolsSubpanelState();
  const [, setShowStreamingTools] = useShowStreamingToolsToggle();

  if (!blockUploadDesc) return null;
  return (
    <button
      type='button'
      className='btn'
      onClick={() => {
        setShowStreamingTools(true);
        setSubpanelState('recording-logs', {
          blockId: props.block.id,
        });
      }}
    >
      {blockUploadDesc.icon}
    </button>
  );
}

function BlockActionSheet(props: {
  onDuplicate?: () => void;
  onDelete?: () => void;
  onDetach?: () => void;
  onOpen?: () => void;
}) {
  const actions: Action<string>[] = [];

  if (props.onDuplicate) {
    actions.push({
      kind: 'button',
      key: 'duplicate',
      icon: <DuplicateIcon />,
      text: 'Duplicate',
      onClick: props.onDuplicate,
    });
  }

  if (props.onDelete) {
    actions.push({
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-005',
      onClick: props.onDelete,
    });
  }

  if (props.onDetach) {
    actions.push({
      kind: 'button',
      key: 'detach',
      icon: <DeleteIcon />,
      text: 'Detach',
      onClick: props.onDetach,
    });
  }

  if (props.onOpen) {
    actions.push({
      kind: 'button',
      key: 'open',
      icon: <EyeIcon />,
      text: 'Open',
      onClick: props.onOpen,
    });
  }

  if (actions.length === 0) return null;
  return (
    <ActionSheet
      placement='bottom-end'
      arrow={true}
      btnSizingClassName='w-7.5 h-7.5'
      dropdownClassName='min-w-32'
      actions={actions}
    />
  );
}

export function BlockCard(props: {
  index: number;
  block: Block;
  isSelected: boolean;
  isSelectable: boolean;
  isPlaying: boolean;
  isPlayed: boolean;
  isDisabled: boolean;
  recordingVersionMismatch: boolean;
  title?: string;
  scrollOptions?: ScrollIntoViewOptions;
  actionSheet?: JSX.Element;
  onSelect: () => void;
  dataTestId?: string;
  withRecordingLogButton?: boolean;
}): JSX.Element {
  const {
    index,
    block,
    isSelected,
    isSelectable,
    isPlaying,
    isPlayed,
    isDisabled,
    recordingVersionMismatch,
    title,
    scrollOptions = {
      behavior: 'smooth',
      block: 'nearest',
    },
    actionSheet,
    onSelect,
    withRecordingLogButton,
    dataTestId,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const prevIsSelected = usePrevious(isSelected);

  const blockSummary = BlockKnifeUtils.Summary(block);
  const mediaUrl =
    MediaUtils.PickMediaUrl(blockSummary.coverMedia, {
      priority: [MediaFormatVersion.SM],
      videoThumbnail: 'first',
    }) ?? null;

  useEffect(() => {
    if (!prevIsSelected && isSelected) {
      ref.current?.scrollIntoView(scrollOptions);
    }
  }, [isSelected, prevIsSelected, scrollOptions]);

  return (
    <div
      ref={ref}
      data-testid={dataTestId}
      title={title}
      onClick={isSelectable && !isDisabled ? onSelect : undefined}
      className={`relative w-full h-24.5 flex-shrink-0 
        border ${isSelected ? 'border-primary' : 'border-transparent'}
        ${
          isSelected
            ? 'bg-primary bg-opacity-40 hover:bg-opacity-60'
            : isSelectable && !isDisabled
            ? 'bg-lp-gray-009 hover:bg-primary hover:bg-opacity-40'
            : 'bg-lp-gray-009'
        }
        ${isDisabled ? 'opacity-20' : ''}
        ${isSelectable && !isSelected && !isDisabled ? 'cursor-pointer' : ''}
        flex justify-center items-center
      `}
    >
      <div className='absolute w-42 h-full'>
        {mediaUrl ? (
          <img
            className='w-full h-full object-cover opacity-40'
            src={mediaUrl}
            alt=''
          />
        ) : (
          <div className='w-full h-full bg-secondary' />
        )}
      </div>

      <div className='absolute w-full h-full py-1.5 px-7'>
        <p className='w-full h-full text-white font-bold text-sm line-clamp-4'>
          {blockSummary.title}
        </p>
      </div>

      <div className='absolute left-2 top-1.5 flex flex-col items-center gap-1'>
        <p className='text-white text-opacity-80 font-bold text-xs'>
          {index + 1}
        </p>
        {isPlaying && (
          <div className='text-tertiary'>
            <TimerIcon />
          </div>
        )}
        {!isPlaying && isPlayed && (
          <div className='text-green-001'>
            <CheckedIcon />
          </div>
        )}
      </div>

      <div className='absolute right-1 top-1 flex flex-col items-center gap-1'>
        <BlockIcon blockType={block.type} className='w-3.5 h-3.5' />
        <BlockRecordingIcon
          block={block}
          recordingVersionMismatch={recordingVersionMismatch}
          className='flex-col gap-1'
        />
        {withRecordingLogButton && <RecordingLogButton block={block} />}
      </div>

      <div className='absolute right-1.5 bottom-1.5'>{actionSheet}</div>
    </div>
  );
}

export const GameBlockEditor = (): JSX.Element | null => {
  const game = useEditingGame();
  const blocks = game?.blocks;
  const gameSessionStatus = useGameSessionStatus();
  const gameSessionBlock = useGameSessionBlock();
  const selectedBlockId = useSelectedBlockId();
  const selectedBlock = blocks
    ? blocks.find((b) => b.id === selectedBlockId) || null
    : null;

  const playedBlockIds = usePlayedBlockIds();
  const emitter = useGameLikeEventEmitter('game');
  const store = useGameEditorStore();
  const expectedOndPlaybackVersion =
    GameUtils.DeriveOndPlaybackVersionFromBlocks(blocks);
  const [blockUpdatingError, setBlockUpdatingError] = useState<Error | null>(
    null
  );

  const [isSavingChanges, setSavingChanges] = useState(false);
  const [isUpdatingBlock, setIsUpdatingBlock] = useState(false);

  const handleClose = useLiveCallback(async () => {
    await store.setEditingGame(null);
    if (game) {
      emitter.emit('updated', {
        ...game,
        blocksCount: blocks?.length || 0,
      });
    }
  });

  const asyncDetails = useOndRecordingDetails(
    selectedBlock,
    expectedOndPlaybackVersion,
    useRefreshBlocksForGameEditor(game?.id),
    false,
    useRecordingDeleteConfirmConfigForGame(game)
  );

  if (!game) return null;

  const handleSelectBlock = (blockId: string) => {
    store.setSelectedBlockId(blockId);
  };

  const handleAddBlock = async (type: BlockType) => {
    const currentIndex = blocks?.findIndex((b) => b.id === selectedBlockId);
    const position = currentIndex !== undefined ? currentIndex + 1 : 0;
    setIsUpdatingBlock(true);
    try {
      await store.createBlock(game.id, position, type);
    } catch (error: UnassertedUnknown) {
      setBlockUpdatingError(error);
    } finally {
      setIsUpdatingBlock(false);
    }
  };

  const handleDeleteBlock = async (blockId: string) => {
    setIsUpdatingBlock(true);
    try {
      await store.deleteBlock(blockId);
    } catch (error: UnassertedUnknown) {
      setBlockUpdatingError(error);
    } finally {
      setIsUpdatingBlock(false);
    }
  };

  const handleDetachBlock = async (blockId: string) => {
    setIsUpdatingBlock(true);
    try {
      await store.detachBlock(blockId);
    } catch (error: UnassertedUnknown) {
      setBlockUpdatingError(error);
    } finally {
      setIsUpdatingBlock(false);
    }
  };

  const handleDuplicateBlock = async (blockId: string) => {
    setIsUpdatingBlock(true);
    try {
      await store.duplicateBlock(blockId);
    } catch (error: UnassertedUnknown) {
      setBlockUpdatingError(error);
    } finally {
      setIsUpdatingBlock(false);
    }
  };

  const handleMoveBlock = async (dragIndex: number, targetIndex: number) => {
    if (!blocks || blocks.length < 2 || dragIndex >= blocks.length) return;
    setIsUpdatingBlock(true);
    try {
      await store.moveBlocks(dragIndex, targetIndex);
    } catch (error: UnassertedUnknown) {
      setBlockUpdatingError(error);
    } finally {
      setIsUpdatingBlock(false);
    }
  };

  return (
    <div className='w-screen h-screen bg-black fixed flex flex-col z-50 top-0'>
      <Header
        game={game}
        isSavingChanges={isSavingChanges || isUpdatingBlock}
        onClose={handleClose}
      />

      <PrimeWarningMessage gameLike={game} />

      <div className='relative w-full flex-1 overflow-auto flex flex-row'>
        <section className='absolute w-72 h-full bg-modal flex flex-col'>
          <header className='w-full px-4 py-3 flex flex-col gap-1'>
            <div className='w-full flex flex-row items-center justify-between'>
              <p className='font-bold text-white'>Blocks</p>

              <AddBlockButton
                disabled={isSavingChanges || isUpdatingBlock}
                onAddBlock={handleAddBlock}
              />
            </div>

            {blockUpdatingError && (
              <p className='text-red-005'>{err2s(blockUpdatingError)}</p>
            )}
          </header>

          <main className='w-full flex-1 overflow-y-auto scrollbar flex flex-col gap-1'>
            {game.blocks && (
              <DragDropList
                type={`game-${game.id}-blocks`}
                items={game.blocks}
                onMove={handleMoveBlock}
                render={({ item: block, index, drag, ref, style }) => {
                  const isPlaying =
                    !!gameSessionStatus && gameSessionBlock?.id === block.id;
                  const isPlayed = playedBlockIds.includes(block.id);
                  return (
                    <div className='w-full' ref={ref} style={style}>
                      <div className='w-full' ref={drag}>
                        <BlockCard
                          block={block}
                          index={index}
                          isSelected={selectedBlockId === block.id}
                          isSelectable={true}
                          isPlaying={isPlaying}
                          isPlayed={isPlayed}
                          isDisabled={isPlaying || isPlayed}
                          recordingVersionMismatch={
                            !!block.recording &&
                            block.recording.version !==
                              expectedOndPlaybackVersion
                          }
                          actionSheet={
                            <BlockActionSheet
                              onDuplicate={() => handleDuplicateBlock(block.id)}
                              onDelete={() => handleDeleteBlock(block.id)}
                              onDetach={
                                game.isPrime
                                  ? () => handleDetachBlock(block.id)
                                  : undefined
                              }
                              onOpen={() =>
                                window.open(
                                  `/admin/blocks/${block.id}`,
                                  '_blank'
                                )
                              }
                            />
                          }
                          onSelect={() => handleSelectBlock(block.id)}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            )}
          </main>
        </section>

        <div
          // reset scroll position when switching blocks
          key={selectedBlock?.id}
          className='w-full ml-72 scrollbar overflow-y-auto overflow-x-hidden'
        >
          <div className='p-5 flex items-center justify-end bg-black sticky top-0 left-0 z-3'>
            {asyncDetails.detailsLink}
          </div>
          <div className='mx-4 mb-4'>
            <BlockEditorStoreProvider store={store.blockEditorStore}>
              <BlockEditor
                block={selectedBlock}
                setSavingChanges={(val: boolean) => {
                  setSavingChanges(val);
                  if (val) asyncDetails.setWarn(true);
                }}
              />
            </BlockEditorStoreProvider>
          </div>
        </div>
      </div>

      {asyncDetails.detailsModal}
      {asyncDetails.warningModal}
    </div>
  );
};
