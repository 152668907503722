import { type AxiosResponse } from 'axios';

import {
  type DtoChangeHubSpotProductRequest,
  type DtoGetHubSpotDealResponse,
  type DtoGetHubSpotProductResponse,
  type DtoSearchDealRequest,
  type DtoSearchDealResponse,
  type DtoSearchHubSpotProductRequest,
  type DtoSearchHubSpotProductResponse,
  type DtoUpdateHubSpotDealWithCustomPriceRequest,
  type DtoUpdateHubSpotDealWithCustomPriceResponse,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class HubSpotAPI extends BaseAPIService {
  searchDeals(
    req: DtoSearchDealRequest
  ): Promise<AxiosResponse<DtoSearchDealResponse>> {
    return this.client.post('/hubspot/deals/search', req);
  }

  getDeal(dealId: string): Promise<AxiosResponse<DtoGetHubSpotDealResponse>> {
    return this.client.get(`/hubspot/deals/${dealId}`);
  }

  updateDealCustomPrice(
    dealId: string,
    req: DtoUpdateHubSpotDealWithCustomPriceRequest
  ): Promise<AxiosResponse<DtoUpdateHubSpotDealWithCustomPriceResponse>> {
    return this.client.put(`/hubspot/deals/${dealId}/custom-price`, req);
  }

  searchProducts(
    req: DtoSearchHubSpotProductRequest
  ): Promise<AxiosResponse<DtoSearchHubSpotProductResponse>> {
    return this.client.post('/hubspot/products/search', req);
  }

  createProduct(
    req: DtoChangeHubSpotProductRequest
  ): Promise<AxiosResponse<DtoGetHubSpotProductResponse>> {
    return this.client.post('/hubspot/products', req);
  }

  getProduct(id: string): Promise<AxiosResponse<DtoGetHubSpotProductResponse>> {
    return this.client.get(`/hubspot/products/${id}`);
  }

  updateProduct(
    id: string,
    req: DtoChangeHubSpotProductRequest
  ): Promise<AxiosResponse<DtoGetHubSpotProductResponse>> {
    return this.client.put(`/hubspot/products/${id}`, req);
  }
}
