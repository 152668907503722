import { ConfirmCancelModalText } from '../../../ConfirmCancelModalContext';

export function DeleteCourseConfirmationModal(): JSX.Element {
  return (
    <div className='text-white flex-col items-center justify-center py-2 px-4'>
      <ConfirmCancelModalText className='text-2xl font-medium'>
        Delete this course?
      </ConfirmCancelModalText>
      <div className='my-2 text-sms text-center'>
        This action cannot be undone.
      </div>
    </div>
  );
}
