import { type AxiosResponse } from 'axios';

import {
  type DtoGenerateMediaSearchKeywordRequest,
  type DtoGenerateMediaSearchKeywordResponse,
  type DtoGenerateTopicBlocksRequest,
  type DtoGenerateTopicBlocksResponse,
  type DtoGenerateTopicOutlineRequest,
  type DtoGenerateTopicOutlineResponse,
  type DtoGenerateTrainingBlockRequest,
  type DtoGenerateTrainingBlockResponse,
  type DtoGenerateTrainingOutlineRequest,
  type DtoGenerateTrainingOutlineResponse,
  type DtoGetTrainingMusicLibraryResponse,
  type DtoGetTrainingPersonalitiesResponse,
  type DtoMakeTrainingCourseRequest,
  type DtoMakeTrainingCourseResponse,
  type DtoSelectBestMatchMediaRequest,
  type DtoSelectBestMatchMediaResponse,
  type DtoSendTrainingEditorMessageRequest,
  type DtoSendTrainingEditorMessageResponse,
  type DtoTrainingEditorMessagesResponse,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class TrainingAPI extends BaseAPIService {
  getMusicLibrary(): Promise<
    AxiosResponse<DtoGetTrainingMusicLibraryResponse>
  > {
    return this.client.get('/training/music-library');
  }

  getPersonalities(): Promise<
    AxiosResponse<DtoGetTrainingPersonalitiesResponse>
  > {
    return this.client.get('/training/personalities');
  }

  generateTrainingOutline(
    req: DtoGenerateTrainingOutlineRequest
  ): Promise<AxiosResponse<DtoGenerateTrainingOutlineResponse>> {
    return this.client.post('/training/generate-training-outline', req);
  }

  generateTopicOutline(
    req: DtoGenerateTopicOutlineRequest
  ): Promise<AxiosResponse<DtoGenerateTopicOutlineResponse>> {
    return this.client.post('/training/generate-topic-outline', req);
  }

  generateTopicBlocks(
    req: DtoGenerateTopicBlocksRequest
  ): Promise<AxiosResponse<DtoGenerateTopicBlocksResponse>> {
    return this.client.post('/training/generate-topic-blocks', req);
  }

  generateTrainingBlock(
    req: DtoGenerateTrainingBlockRequest
  ): Promise<AxiosResponse<DtoGenerateTrainingBlockResponse>> {
    return this.client.post('/training/generate-training-block', req);
  }

  makeTrainingCourse(
    req: DtoMakeTrainingCourseRequest
  ): Promise<AxiosResponse<DtoMakeTrainingCourseResponse>> {
    return this.client.post('/training/make-training-course', req);
  }

  generateMediaSearchKeywords(
    req: DtoGenerateMediaSearchKeywordRequest
  ): Promise<AxiosResponse<DtoGenerateMediaSearchKeywordResponse>> {
    return this.client.post('/training/generate-media-search-keywords', req);
  }

  selectBestMatchMedia(
    req: DtoSelectBestMatchMediaRequest
  ): Promise<AxiosResponse<DtoSelectBestMatchMediaResponse>> {
    return this.client.post('/training/select-best-match-media', req);
  }

  sendEditorChatMessage(
    blockId: string,
    req: DtoSendTrainingEditorMessageRequest
  ): Promise<AxiosResponse<DtoSendTrainingEditorMessageResponse>> {
    return this.client.post(`/training/blocks/${blockId}/editor/message`, req);
  }

  getEditorMessages(
    blockId: string
  ): Promise<AxiosResponse<DtoTrainingEditorMessagesResponse>> {
    return this.client.get(`/training/blocks/${blockId}/editor/messages`);
  }

  deleteEditorThread(blockId: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`/training/blocks/${blockId}/editor/thread`);
  }
}
