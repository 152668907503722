import { FormProvider, useForm } from 'react-hook-form';

import {
  type DtoMessageLogicV2,
  EnumsMessageTarget,
  EnumsMessageTriggerType,
} from '@lp-lib/api-service-client/public';

import { MessageConditionEditor } from '../MessageCondition';
import { MessageTargetEditor } from '../MessageTarget';
import { MessageTriggerEditor } from '../MessageTrigger';
import { type MessageCampaignV2 } from '../Shared';
import { MessageTemplateFields } from '../Template';
import { MessageLogicEditorLayout } from './MessageLogicEditorLayout';
import { type MessageLogicFormData } from './Shared';
import { MessageLogicUtils } from './utils';

export function SeatCapErrorLogicEditor(props: {
  campaign: MessageCampaignV2;
  logic?: DtoMessageLogicV2;
  onCancel: () => void;
  onSubmit: (logic: DtoMessageLogicV2) => void;
}) {
  const { campaign, logic, onCancel, onSubmit } = props;

  const form = useForm<MessageLogicFormData>({
    defaultValues: MessageLogicUtils.MakeDefaultValues({ logic }),
  });

  return (
    <FormProvider {...form}>
      <MessageLogicEditorLayout
        campaign={campaign}
        logic={logic}
        onCancel={onCancel}
        onSubmit={onSubmit}
        trigger={
          <MessageTriggerEditor
            config={{
              types: [
                EnumsMessageTriggerType.MessageTriggerTypeWeekdaysOffset,
                EnumsMessageTriggerType.MessageTriggerTypeTimeOffset,
              ],
            }}
          />
        }
        target={
          <MessageTargetEditor
            supportedTargets={[EnumsMessageTarget.MessageTargetOrgAdmins]}
          />
        }
        condition={
          <MessageConditionEditor
            vars={{
              isStillOverLimit: true,
              errorType: 'scheduler',
            }}
            explanations={{
              isStillOverLimit:
                'Whether the seat cap is still over the limit. true or false.',
              errorType: 'The type of error that occurred. scheduler or venue.',
            }}
          />
        }
        template={
          <MessageTemplateFields
            vars={{
              recipientName: 'John',
              userEmail: 'john@example.com',
              errorEmail: 'john@example.com',
              seatCap: '10',
            }}
            varsExplanation={{
              recipientName: 'The name of the recipient.',
              userEmail: 'The email of the user who encountered the error.',
              errorEmail:
                'The new email address that exceeded the capacity when scheduling or signing up at the venue.',
              seatCap: 'The seat cap of the organization.',
            }}
          />
        }
      />
    </FormProvider>
  );
}
