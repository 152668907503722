import { useRef, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { type ModelsStack } from '@lp-lib/api-service-client/public';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { DeleteIcon } from '../icons/DeleteIcon';
import { GameIcon } from '../icons/GameIcon';
import { OptionsIcon } from '../icons/OptionsIcon';

interface StackCardProps {
  stack: ModelsStack;
  onAssign: (stack: ModelsStack) => void;
  onDetails: (id: string) => void;
  onDelete: (id: string) => void;
  showHeaders?: boolean;
  learners: number;
}

export function StackCard({
  stack,
  onAssign,
  onDetails,
  onDelete,
  showHeaders = false,
  learners,
}: StackCardProps) {
  return (
    <div className='flex flex-col gap-2'>
      {showHeaders && (
        <div className='flex items-center px-4 text-gray-400 text-sm mr-8'>
          <div className='flex-1'>Name</div>
          <div className='flex items-center gap-16'>
            <div className='w-20 text-start'>Learners</div>
            <div className='w-20 text-start'>Progress</div>
            <div className='w-32 text-start'>Created On</div>
          </div>
          <div className='w-32' />
        </div>
      )}

      <div className='relative w-full'>
        <div className='absolute -bottom-3 left-3 w-full h-full bg-[#17171A] rounded-lg border border-lp-gray-003' />
        <div className='absolute -bottom-1.5 left-1.5 w-full h-full bg-[#17171A] rounded-lg border border-lp-gray-003' />
        {/* Main card */}
        <div className='relative w-full bg-[#17171A] rounded-lg p-4 flex items-center justify-between gap-4 border-lp-gray-003 border'>
          <div className='flex items-center gap-4 flex-1'>
            <div className='w-21 h-12 rounded-lg bg-gray-700 overflow-hidden flex items-center justify-center'>
              <span className='text-2xl'>📚</span>
            </div>
            <div className='flex flex-col'>
              <span className='text-sms text-[#E96B24]'>COURSE STACK</span>
              <span className='text-white font-semibold'>{stack.name}</span>
            </div>
          </div>

          {/* Center section with stats */}
          <div className='flex items-center gap-16'>
            <div className='w-20 text-center'>
              <span className='text-white text-sms font-semibold'>
                {learners}
              </span>
            </div>
            <div className='w-20 text-center'>
              <span className='text-white text-sms font-semibold'>0%</span>
            </div>
            <div className='w-32 text-center'>
              <span className='text-white text-sms'>
                {new Date(stack.createdAt).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })}
              </span>
            </div>
          </div>

          {/* Right section with actions */}
          <div className='flex items-center gap-4 w-32 justify-end ml-8'>
            <button
              onClick={() => onAssign(stack)}
              className='px-6 py-2 bg-[#232325] hover:bg-[#2A2A2D] text-white rounded-lg transition-colors text-sms'
            >
              Assign
            </button>
            <StackActionsMenu
              onDetails={() => onDetails(stack.id)}
              onDelete={() => onDelete(stack.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

interface StackActionsMenuProps {
  onDetails: () => void;
  onDelete: () => void;
}

export function StackActionsMenu({
  onDetails,
  onDelete,
}: StackActionsMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'bottom-end',
      interactive: true,
      offset: [0, 8],
      visible: isOpen,
      onVisibleChange: (next) => {
        setIsOpen(next);
      },
      trigger: 'click',
    });

  useOutsideClick(tooltipRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const handleAction = (action: () => void) => {
    action();
    setIsOpen(false);
  };

  return (
    <div className='relative'>
      <button
        ref={setTriggerRef}
        type='button'
        className='p-2 hover:bg-[#232325] rounded-lg transition-colors'
      >
        <OptionsIcon className='w-5 h-5 fill-current text-white' />
      </button>

      {visible && (
        <div
          ref={(node) => {
            setTooltipRef(node);
          }}
          {...getTooltipProps({
            className:
              'z-50 w-50 bg-black text-white rounded-xl p-1 border border-secondary',
          })}
        >
          <button
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              handleAction(onDetails);
            }}
            className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms'
          >
            <GameIcon className='w-6 h-6 fill-current text-white pr-2' />
            Course Stack Details
          </button>
          <button
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              handleAction(onDelete);
            }}
            className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms text-red-500'
          >
            <DeleteIcon className='w-6 h-6 fill-current pr-2' />
            Delete Stack
          </button>
        </div>
      )}
    </div>
  );
}
