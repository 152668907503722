import { add, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import { usePostGameAnalytics } from '../../../analytics/postGame';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { getStaticAssetPath } from '../../../utils/assets';
import { type VenueTrimmedEvent } from '../../Venue';

const recurringGraph = getStaticAssetPath('images/recurring-graph.png');

export function RecurringEventPromotion(props: {
  onDismiss: () => void;
  isOrganizer: boolean;
  venueEvent: VenueTrimmedEvent;
  venueUrl: string;
}) {
  const analytics = usePostGameAnalytics();

  const handleSkip = useLiveCallback(() => {
    analytics.trackRecurringEventPromotionDismissed({
      isOrganizer: props.isOrganizer,
      choice: 'skip',
    });
    props.onDismiss();
  });

  const handleSchedule = useLiveCallback(() => {
    analytics.trackRecurringEventPromotionDismissed({
      isOrganizer: props.isOrganizer,
      choice: 'add-to-google-calendar',
    });
    props.onDismiss();
  });

  const createEventUrl = useMemo(() => {
    const startAt = utcToZonedTime(
      props.venueEvent.startAt,
      props.venueEvent.timezone
    );
    const endAt = utcToZonedTime(
      props.venueEvent.endAt,
      props.venueEvent.timezone
    );
    return makeGoogleCalendarEventUrl({
      title: '🎡 Luna Park Game',
      details: `<h2>Let's get together in Luna Park!</h2><p>Meet in my venue: ${props.venueUrl}</p></p><p>First time playing? Watch this video before you join: https://youtu.be/i-kk2hoy9R0</p>`,
      location: props.venueUrl,
      dates: {
        start: add(startAt, { weeks: 1 }),
        end: add(endAt, { weeks: 1 }),
      },
      rrule: 'FREQ=WEEKLY',
    });
  }, [
    props.venueEvent.endAt,
    props.venueEvent.startAt,
    props.venueEvent.timezone,
    props.venueUrl,
  ]);

  useEffectOnce(() => {
    analytics.trackRecurringEventPromotionViewed({
      isOrganizer: props.isOrganizer,
    });
  });

  const formattedDate = format(
    utcToZonedTime(props.venueEvent.startAt, props.venueEvent.timezone),
    "EEEE 'at' h:mma"
  );
  const when = `Every ${formattedDate}`;

  return (
    <div className='w-full max-w-200 bg-black rounded-xl flex flex-col items-center border border-secondary text-white'>
      <div className='text-tertiary text-2xl font-bold pt-10 text-center'>
        <div>Same time next week?</div>
      </div>
      <div className='flex items-center gap-10 p-10'>
        <img
          src={recurringGraph}
          alt='recurring graph'
          className='w-60 h-auto'
        />
        <div className='flex flex-col gap-3'>
          <p className='text-xl font-bold'>
            Put your team building on auto-pilot by making this a recurring
            event
          </p>
          <p>
            <strong>When: {when}</strong>
            <br />
            <span className='text-icon-gray text-sms'>
              (You can edit this information in the event after clicking the
              button below!)
            </span>
          </p>
        </div>
      </div>
      <a
        href={createEventUrl}
        target='_blank'
        rel='noreferrer'
        className='flex-none btn-primary font-bold w-90 h-10 flex items-center justify-center'
        onClick={handleSchedule}
      >
        Add to Google Calendar
      </a>
      <button
        type='button'
        className='pt-7 pb-7.5 flex-none text-icon-gray text-sms'
        onClick={handleSkip}
      >
        No thanks
      </button>
    </div>
  );
}

type CalendarEvent = Partial<{
  title: string;
  dates: {
    start: Date;
    end: Date;
  };
  details: string;
  location: string;
  rrule: string;
  attendees: string[];
}>;

const root = 'https://calendar.google.com/calendar/u/0/r/eventedit';
function makeGoogleCalendarEventUrl(props: CalendarEvent): string {
  const params = new URLSearchParams();
  if (props.title) params.append('text', props.title);
  if (props.details) params.append('details', props.details);
  if (props.location) params.append('location', props.location);
  if (props.rrule) params.append('recur', `RRULE:${props.rrule}`);
  if (props.attendees) params.append('add', props.attendees.join(','));
  if (props.dates) {
    params.append(
      'dates',
      [
        format(props.dates.start, "yyyyMMdd'T'HHmmss"),
        format(props.dates.end, "yyyyMMdd'T'HHmmss"),
      ].join('/')
    );
  }
  return `${root}?${params.toString()}`;
}
