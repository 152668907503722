import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization';
import { useAnalytics } from './AnalyticsContext';

class LearningAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}

  trackEditorNewSlideClicked(props: EventProperties) {
    this.analytics.track(
      'Spark Editor New Slide Clicked',
      this.buildEventProps(props)
    );
  }

  trackEditorConfigChanged(
    blockId: string,
    key: string,
    value: unknown,
    props?: EventProperties
  ) {
    this.analytics.track(
      'Spark Editor Config Changed',
      this.buildEventProps({
        ...props,
        blockId,
        key,
        value,
      })
    );
  }

  trackEditorCoursePreviewed(props: EventProperties) {
    this.analytics.track(
      'Spark Editor Course Previewed',
      this.buildEventProps(props)
    );
  }

  trackCourseAIPrompt(props?: EventProperties) {
    this.analytics.track('Spark Course AI Prompt', props);
  }

  trackCourseDetailActionClicked(props: EventProperties) {
    this.analytics.track(
      'Spark Course Detail Action Clicked',
      this.buildEventProps(props)
    );
  }

  trackCourseSelected(props: EventProperties) {
    this.analytics.track('Spark Course Selected', this.buildEventProps(props));
  }

  trackCourseStarted(props: EventProperties) {
    this.analytics.track('Spark Course Started', this.buildEventProps(props));
  }

  trackLandingBookMeetingClicked(props: EventProperties) {
    this.analytics.track(
      'Learning Landing Book Meeting Clicked',
      this.buildEventProps(props)
    );
  }

  trackTryForFreeClicked(props: EventProperties) {
    this.analytics.track(
      'Learning Landing Try For Free Clicked',
      this.buildEventProps(props)
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useLearningAnalytics(): LearningAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();

  return useMemo(
    () => new LearningAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
