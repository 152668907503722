export function FreeFormBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.14307 0C2.0385 0 1.14307 0.895431 1.14307 2V14C1.14307 15.1046 2.0385 16 3.14307 16H12.8574C13.9619 16 14.8574 15.1046 14.8574 14V2C14.8574 0.895431 13.9619 0 12.8574 0H3.14307ZM4.42878 3.42857C3.8765 3.42857 3.42878 3.87629 3.42878 4.42857V4.71429C3.42878 5.26657 3.8765 5.71429 4.42878 5.71429H11.5716C12.1239 5.71429 12.5716 5.26657 12.5716 4.71429V4.42857C12.5716 3.87629 12.1239 3.42857 11.5716 3.42857H4.42878ZM3.42878 7.85714C3.42878 7.30486 3.8765 6.85714 4.42878 6.85714H11.5716C12.1239 6.85714 12.5716 7.30486 12.5716 7.85714V8.14286C12.5716 8.69514 12.1239 9.14286 11.5716 9.14286H4.42878C3.8765 9.14286 3.42878 8.69514 3.42878 8.14286V7.85714ZM4.42878 10.2857C3.8765 10.2857 3.42878 10.7334 3.42878 11.2857V11.5714C3.42878 12.1237 3.8765 12.5714 4.42878 12.5714H9.28592C9.83821 12.5714 10.2859 12.1237 10.2859 11.5714V11.2857C10.2859 10.7334 9.83821 10.2857 9.28592 10.2857H4.42878Z'
        fill='white'
      />
    </svg>
  );
}
