import { Link } from '@remix-run/react';

import { BookLiveGameWidget } from '../../../pages/Live';
import {
  OnboardingTaskDetailLayout,
  type OnboardingTaskDetailSharedProps,
  OnboardingTaskSkipButton,
} from './Shared';

export function OnboardingTaskBookLiveGame(
  props: OnboardingTaskDetailSharedProps
): JSX.Element {
  const { onNext } = props;

  const handleSubmit = async () => {
    onNext();
  };

  return (
    <OnboardingTaskDetailLayout
      title='Book Live Show'
      description='Take your next team building event to another level. Book a live hosted experience your team will love!'
    >
      <div className='mt-4.5 text-base font-bold text-green-001'>
        Luna Park Live Events are included in your subscription at no extra
        charge!
      </div>
      <div className='mt-5 w-240 h-160 relative border border-[#939393] rounded-lg'>
        <BookLiveGameWidget onSubmit={handleSubmit} />
      </div>

      <OnboardingTaskSkipButton {...props} className='mt-9' />
    </OnboardingTaskDetailLayout>
  );
}

export function OnboardingTaskScheduleOnDGame(
  props: OnboardingTaskDetailSharedProps
): JSX.Element {
  return (
    <OnboardingTaskDetailLayout
      title='Schedule On-Demand Game'
      description='Schedule a time to play a Luna Park On Demand game with your colleagues.'
    >
      <Link
        to={`/events/create`}
        className='mt-15 w-100 h-15 btn-primary flex justify-center items-center'
      >
        Schedule
      </Link>

      <OnboardingTaskSkipButton {...props} className='mt-4' />
    </OnboardingTaskDetailLayout>
  );
}
