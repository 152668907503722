import { useCallback } from 'react';

import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { AddStacksToGroupModal } from './Modals/AddStacksToGroupModal';

interface TriggerModalOptions {
  onConfirm: (stackIds: string[]) => void;
  existingStackIds: string[];
}

export function useTriggerAddStacksToGroupModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    ({ onConfirm, existingStackIds }: TriggerModalOptions) => {
      return triggerModal({
        kind: 'custom',
        containerClassName: 'bg-lp-black-002',
        element: (modalProps) => (
          <AddStacksToGroupModal
            onClose={modalProps.internalOnCancel}
            onConfirm={(stackIds) => {
              onConfirm(stackIds);
              modalProps.internalOnConfirm();
            }}
            existingStackIds={existingStackIds}
          />
        ),
      });
    },
    [triggerModal]
  );
}
