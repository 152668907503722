import { useEffect, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';

import {
  type DtoGamePack,
  type DtoUpdateGamePackRequest,
  EnumsGamePackChangeLevel,
  EnumsMediaScene,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { apiService } from '../../../services/api-service';
import { fromMediaDTO } from '../../../utils/api-dto';
import { BrowserTimeoutCtrl } from '../../../utils/BrowserTimeoutCtrl';
import { b64toMasqueradeMedia, b64URLtoBlob } from '../../../utils/media';
import { AIIcon } from '../../icons/AIIcon';
import { ChatSendIcon } from '../../icons/Chat/ChatSendIcon';
import { Loading } from '../../Loading';
import { MediaUploader } from '../../MediaUploader/MediaUploader';

type FormData = {
  name: string;
  description: string;
  cover: Media | null;
  background: Media | null;
};

function CoverField(props: {
  value: Media | null;
  onChange: (media: Media | null) => void;
  setIsUploading: (isUploading: boolean) => void;
}) {
  const { value, onChange, setIsUploading } = props;
  const [animate, setAnimate] = useState(!value);
  const { getValues } = useFormContext<FormData>();

  useEffect(() => {
    if (!animate) return;
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(() => setAnimate(false), 10000);
    return () => ctrl.clear();
  }, [animate]);

  const [instruction, setInstruction] = useState('');
  const [openInstructionEditor, setOpenShowInstructionEditor] = useState(false);

  const {
    call: generateImage,
    state: { state },
  } = useLiveAsyncCall(async () => {
    setAnimate(false);
    const resp = await apiService.gamePack.recommendCoverImages({
      name: getValues('name'),
      description: getValues('description'),
      instruction: instruction ?? undefined,
    });
    const images = resp.data.images;
    if (images.length === 0) {
      throw new Error('failed to generate the image, please try again.');
    }
    const image = images[0];
    onChange(b64toMasqueradeMedia(image.b64));
  });

  const ref = useRef(null);

  useOutsideClick(ref, () => {
    if (state.isRunning) return;
    setOpenShowInstructionEditor(false);
  });

  return (
    <label ref={ref} className='flex-1 flex flex-col gap-1.5'>
      <div className='text-sm font-bold flex items-center gap-1.5 relative'>
        <p>Course Cover</p>
        <button
          type='button'
          className={`btn ${animate ? 'animate-pulse' : ''}`}
          onClick={() => setOpenShowInstructionEditor(!openInstructionEditor)}
          disabled={state.isRunning}
        >
          <AIIcon className='w-4 h-4 fill-current' />
        </button>
        {openInstructionEditor && (
          <div
            className='absolute top-6.5 left-0 w-full z-5 bg-black'
            style={{ aspectRatio: '16/9' }}
          >
            <textarea
              className='w-full h-full field mb-0 resize-none py-2'
              placeholder="Tell us more about the image you'd like to have"
              value={instruction}
              onChange={(e) => setInstruction(e.target.value)}
              disabled={state.isRunning}
            />
            <button
              type='button'
              className='btn-primary w-7 h-7 text-sms rounded-lg absolute 
              right-2 bottom-2 flex items-center justify-center'
              onClick={async () => {
                await generateImage();
                setOpenShowInstructionEditor(false);
              }}
              disabled={state.isRunning}
            >
              {state.isRunning ? (
                <Loading imgClassName='w-3.5 h-3.5' text='' />
              ) : (
                <ChatSendIcon />
              )}
            </button>
          </div>
        )}
      </div>
      <MediaUploader
        video={false}
        scene={EnumsMediaScene.MediaSceneGamePackCover}
        media={value}
        onUploadStart={() => setIsUploading(true)}
        onUploadSuccess={(media) => {
          onChange(media);
          setIsUploading(false);
        }}
        onUploadFailed={() => setIsUploading(false)}
        onMediaDelete={() => {
          onChange(null);
        }}
        width='w-full'
      />
    </label>
  );
}

export function TrainingEditorSettings(props: {
  pack: DtoGamePack;
  onCancel: () => void;
  onSave: (pack: DtoUpdateGamePackRequest) => void;
}) {
  const { onCancel, onSave } = props;
  const [isUploading, setIsUploading] = useState(false);

  const form = useForm<FormData>({
    defaultValues: {
      name: props.pack.name ?? '',
      description: props.pack.description ?? '',
      cover: fromMediaDTO(props.pack.cover),
      background: fromMediaDTO(
        props.pack.marketingSettings?.lobbyBackground?.media ?? null
      ),
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    if (data.cover?.url.startsWith('data:')) {
      setIsUploading(true);
      try {
        const resp = await apiService.media.upload(
          b64URLtoBlob(data.cover.url),
          {
            contentType: 'image/png',
            scene: EnumsMediaScene.MediaSceneGamePackCover,
          }
        );
        data.cover = resp.data.media;
      } catch (error) {
        setIsUploading(false);
      }
    }
    const pack: DtoUpdateGamePackRequest = {
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      name: data.name,
      description: data.description,
      coverMediaId: data.cover?.id ?? '',
      marketingSettings: {
        lobbyBackground: {
          data: {
            id: data.background?.id ?? '',
          },
        },
      },
    };

    onSave(pack);
  });

  return (
    <FormProvider {...form}>
      <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-165 min-h-45'>
        <div className='w-full h-full flex flex-col gap-5 text-white'>
          <div className='flex-none w-full py-2'>
            <div className='font-bold text-base'>Course Settings</div>
          </div>

          <label className='w-full flex flex-col gap-1.5 flex-1 min-h-0'>
            <span className='text-sm font-bold'>Course Name</span>
            <Controller<FormData, 'name'>
              name='name'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <input
                  className={`w-full h-12.5 m-0 ${
                    fieldState.error ? 'field-error' : 'field'
                  }`}
                  placeholder='Course Name'
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </label>

          <label className='w-full flex flex-col gap-1.5 flex-1 min-h-0'>
            <span className='text-sm font-bold'>Course Description</span>
            <Controller<FormData, 'description'>
              name='description'
              render={({ field }) => (
                <textarea
                  className={`w-full h-20 field m-0 resize-none py-2`}
                  placeholder='Course Description'
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </label>

          <div className='flex gap-7.5'>
            <Controller<FormData, 'cover'>
              name='cover'
              render={({ field }) => (
                <CoverField
                  value={field.value}
                  onChange={field.onChange}
                  setIsUploading={setIsUploading}
                />
              )}
            />

            <label className='flex-1 flex flex-col gap-1.5'>
              <span className='text-sm font-bold'>Course Background</span>
              <Controller<FormData, 'background'>
                name='background'
                render={({ field }) => (
                  <MediaUploader
                    video={false}
                    scene={EnumsMediaScene.MediaSceneGamePackMarketingMaterials}
                    media={field.value}
                    onUploadStart={() => setIsUploading(true)}
                    onUploadSuccess={(media) => {
                      field.onChange(media);
                      setIsUploading(false);
                    }}
                    onUploadFailed={() => setIsUploading(false)}
                    onMediaDelete={() => {
                      field.onChange(null);
                    }}
                    width='w-full'
                  />
                )}
              />
            </label>
          </div>

          <div className='mt-auto w-full flex items-center justify-end gap-4'>
            <button
              type='button'
              className='btn-secondary w-40 py-2'
              onClick={onCancel}
              disabled={isUploading}
            >
              Cancel
            </button>
            <button
              type='button'
              className='btn-primary w-40 py-2 flex items-center justify-center gap-1'
              onClick={onSubmit}
              disabled={isUploading}
            >
              <p>{isUploading ? 'Uploading' : 'Save'}</p>
              {isUploading && <Loading text='' imgClassName='w-5 h-5' />}
            </button>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}
