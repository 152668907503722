import { v4 } from 'uuid';

import { uncheckedIndexAccess_UNSAFE } from './uncheckedIndexAccess_UNSAFE';
export function booleanify(val?: string | number | boolean | null): boolean {
  if (typeof val === 'string') {
    const v = val.toLowerCase();
    if (v === 'no' || v === 'false' || v === '0') {
      return false;
    }
  }
  return Boolean(val);
}

export const assertExhaustive = (_n: never): void => {
  throw new Error('Unreachable');
};

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function randomString(length: number): string {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function randomPick<T>(arr: readonly T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function weightedPandomPick<T extends { weight: number }>(arr: T[]): T {
  const weights: number[] = [];

  for (let i = 0; i < arr.length; i++) {
    weights[i] = arr[i].weight + (weights[i - 1] || 0);
  }

  const random = Math.random() * weights[weights.length - 1];

  let i = 0;
  for (; i < weights.length; i++) if (weights[i] > random) break;
  return arr[i];
}

export function randomBetween(min = 0, max = 1): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function runAtLeast<T extends AsyncFunction>(
  fn: T,
  time: number
): Promise<Awaited<ReturnType<T>>> {
  const startAt = Date.now();
  const ret = await fn();
  const endedAt = Date.now();
  if (endedAt - startAt < time) {
    await sleep(time);
  }
  return ret;
}

export function makeTitle(title: string): string {
  if (title) {
    return `${title} | Luna Park`;
  }
  return `Luna Park`;
}

export function lineClamped(el: HTMLElement): boolean {
  return el.scrollHeight > el.clientHeight;
}

export function bytesToSize(
  bytes: number,
  decimals = 2,
  space = false
): string {
  const s = space ? ' ' : '';
  if (bytes === 0) return `0${s}Bytes`;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + s + sizes[i];
}

export async function benchmark<T>(fn: () => Promise<T>): Promise<[T, number]> {
  const startAt = Date.now();
  const ret = await fn();
  const endedAt = Date.now();
  return [ret, endedAt - startAt];
}

export function stripLeft(s: string, lookup: string): string {
  if (s.startsWith(lookup)) {
    return s.replace(lookup, '');
  }
  return s;
}

export function err2s(error?: Error | string | null | unknown): string | null {
  if (!error) return null;
  if (typeof error === 'string') return error;
  if (error instanceof Error) {
    if (uncheckedIndexAccess_UNSAFE(error).response?.data?.msg) {
      return uncheckedIndexAccess_UNSAFE(error).response.data.msg;
    }
    if ('msg' in error && typeof error['msg'] === 'string') {
      return error['msg'];
    }
    return error.message;
  }
  return `${error}`;
}

export function err2code(error?: Error | null): number | null {
  if (!error) return null;
  if (uncheckedIndexAccess_UNSAFE(error).response?.data?.code) {
    return uncheckedIndexAccess_UNSAFE(error).response.data.code;
  }
  return null;
}

export function lastItem<T>(item: ArrayLike<T>): T | undefined {
  return item[item.length - 1];
}

export function nullOrUndefined(value: unknown): value is null | undefined {
  return value === undefined || value === null;
}

export function uuidv4(): string {
  return v4();
}

export function tryBlurActiveElement(): void {
  if (document.activeElement && document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
}

// Hack for CORS error when attempting to access opaque response, such as converting an image to blob, or copying to
// canvas, or downloading a file. This is a known Chrome behavior.
//
// See: https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
export function xDomainifyUrl(
  url: string,
  from:
    | 'gameplay'
    | 'venue-background'
    | 'drawing'
    | 'memories-download'
    | 'emoji-board'
    | 'analytics-slides'
    | 'virtual-background'
    | 'external-media-upload' = 'gameplay'
): string {
  if (url.includes('?')) {
    return `${url}&x-req-from=${from}`;
  }
  return `${url}?x-req-from=${from}`;
}

/**
 * Return a promise that resolves when a single named event has been emitted.
 */
export function once<
  El extends {
    addEventListener: (
      eventName: string,
      cb: (ev: unknown) => void,
      options: AddEventListenerOptions
    ) => void;
  },
  Name extends Parameters<El['addEventListener']>[0]
>(el: El, eventName: Name, rejects = false): Promise<El> {
  return new Promise((resolve, reject) => {
    el.addEventListener(
      eventName,
      (evOrErr) => {
        if (rejects) return reject(evOrErr);
        return resolve(el);
      },
      { once: true }
    );
  });
}

export class TimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TimeoutError';
  }
}

export function raceWithTimeout<T>(
  p: Promise<T>,
  ms: number,
  aborter?: AbortController
): Promise<T> {
  // See https://github.com/sindresorhus/ky/pull/122 and associated issue.
  // Chrome/Firefox don't always properly detect unhandled promise rejection
  // since it's actually heuristic-based to ensure there is a callstack.
  // https://bugs.chromium.org/p/chromium/issues/detail?id=465666. Checking
  // "Pause on Unhandled Exceptions" then causes unnecessary pauses, even when
  // promises are definitely handled. Using Promise.race() can exacerbate the
  // issue. So this code attempts to tell Chrome that yes, the timeout is
  // handled, without rejecting.
  return new Promise<T>((resolve, reject) => {
    p.then(resolve).catch(reject);
    sleep(ms).then(() => {
      reject(new TimeoutError('Timeout after ' + ms + ' ms'));
      if (aborter) aborter.abort();
    });
  });
}

export async function waitForCondition<R>(
  fn: () => Promise<R>,
  timeoutMs: number,
  pollMs = 100,
  msg = `Timeout after ${timeoutMs} ms`
): Promise<NonNullable<R>> {
  let elapsedMs = 0;
  while (elapsedMs <= timeoutMs) {
    elapsedMs += pollMs;
    const ret = await fn();
    if (ret) return ret;
    await sleep(pollMs);
  }
  throw new TimeoutError(msg);
}

export function required<T>(v: Nullable<T>, name?: string): T {
  if (v === null || v === undefined)
    throw new Error(`Required parameter is missing${name ? ': ' + name : ''}`);
  return v;
}

export function ordinal(n: number): string {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function copy<T>(incoming: T): T {
  return JSON.parse(JSON.stringify(incoming));
}

export function assertDefinedFatal<T>(
  value: T | null | undefined,
  context = ''
): asserts value is T {
  if (value == null || value === undefined) {
    throw new Error(
      `Fatal error: value ${value} ${
        context ? `(${context})` : ''
      } must not be null/undefined.`
    );
  }
}

export function downloadObjectAsJSON(
  exportObj: unknown,
  exportName: string
): void {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(exportObj, null, '  ')
  )}`;
  const link = document.createElement('a');
  link.setAttribute('href', dataStr);
  link.setAttribute('download', exportName + '.json');
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function downloadFile(url: string, name: string): void {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

export function roundToNearestOf(val: number, nearestOf: number): number {
  if (val === 0 || nearestOf === 0) return val;
  return Math.ceil(val / nearestOf) * nearestOf;
}

export function isSuperset<T>(set: Set<T>, subset: Set<T>): boolean {
  for (const elem of subset) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}

export function setDifference<T>(setA: Set<T>, setB: Set<T>) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

export function toSentenceCase(str: string): string {
  return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
}

export function snakeCase2titleCase(str: string): string {
  return str
    .split('_')
    .map((s) => toSentenceCase(s))
    .join(' ');
}

export function isValidEmail(val: string): boolean {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);
}
