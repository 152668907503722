import {
  EnumsLiveBookingAccess,
  EnumsOndGamePlayAccess,
  EnumsOndGameUGCAccess,
  EnumsProductFeatureType,
  EnumsProgramAccess,
  type EnumsProgramType,
  EnumsTrainingAccess,
  type ModelsOrgPurchase,
  type ModelsProductFeature,
} from '@lp-lib/api-service-client/public';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { RoleUtils, type User } from '../../types';
import { type GamePack } from '../../types/game';
import { GamePackUtils } from '../Game/GamePack/utils';
import { isGuest, isOrgMember } from '../UserContext';

export function considerVenueCapAsDemo(venueCap: number | null | undefined) {
  return venueCap !== null && venueCap !== undefined && venueCap <= 5;
}

export interface IFeatureChecker {
  hasUnlimitedLiveBooking(): boolean;
  getLiveBookingDiscount(): number | undefined | null;
  hasAnyProgramAccess(): boolean;
  hasUnlimitedProgramAccess(): boolean;
  canAccessProgram(programId: string): boolean;
  canAccessProgramOfType(type: EnumsProgramType): boolean;
  hasVenueCap(): boolean;
  getVenueCap(gamePack?: { id: string }): number | undefined;
  hasUnlimitedOndGamePlayAccess(): boolean;
  hasAnyOndGamePlayAccess(): boolean;
  canAccessGamePack(gamePack: GamePack): boolean;
  canPurchaseGamePack(gamePack: GamePack): boolean;
  canAccessGamePackForOnd(gamePack: GamePack): boolean;
  canPurchaseGamePackForOnd(gamePack: GamePack): boolean;
  canEditGamePack(gamePack: GamePack): boolean;
  hasUnlimitedOndGameUGCAccess(): boolean;
  hasUnlimitedTrainingAccess(): boolean;
}

class AdminFeatureChecker implements IFeatureChecker {
  hasUnlimitedLiveBooking(): boolean {
    return true;
  }

  getLiveBookingDiscount(): number | undefined | null {
    return null;
  }

  hasAnyProgramAccess(): boolean {
    return true;
  }

  hasUnlimitedProgramAccess(): boolean {
    return true;
  }

  canAccessProgram(_programId: string): boolean {
    return true;
  }

  canAccessProgramOfType(_type: EnumsProgramType): boolean {
    return true;
  }

  hasVenueCap(): boolean {
    return false;
  }

  getVenueCap(_gamePack?: { id: string }): number | undefined {
    return undefined;
  }

  hasUnlimitedOndGamePlayAccess(): boolean {
    return true;
  }

  hasAnyOndGamePlayAccess(): boolean {
    return true;
  }

  canAccessGamePack(): boolean {
    return true;
  }

  canPurchaseGamePack(): boolean {
    return false;
  }

  canAccessGamePackForOnd(): boolean {
    return true;
  }

  canPurchaseGamePackForOnd(): boolean {
    return false;
  }

  canEditGamePack(): boolean {
    return true;
  }

  hasUnlimitedOndGameUGCAccess(): boolean {
    return false;
  }

  hasUnlimitedTrainingAccess(): boolean {
    return true;
  }
}

class HostFeatureChecker implements IFeatureChecker {
  hasUnlimitedLiveBooking(): boolean {
    return false;
  }

  getLiveBookingDiscount(): number | undefined | null {
    return null;
  }

  hasAnyProgramAccess(): boolean {
    return false;
  }

  hasUnlimitedProgramAccess(): boolean {
    return false;
  }

  canAccessProgramOfType(_type: EnumsProgramType): boolean {
    return false;
  }

  canAccessProgram(_programId: string): boolean {
    return false;
  }

  hasVenueCap(): boolean {
    return false;
  }

  getVenueCap(_gamePack?: { id: string }): number | undefined {
    return undefined;
  }

  hasUnlimitedOndGamePlayAccess(): boolean {
    return true;
  }

  hasAnyOndGamePlayAccess(): boolean {
    return true;
  }

  canAccessGamePack(): boolean {
    return true;
  }

  canPurchaseGamePack(): boolean {
    return false;
  }

  canAccessGamePackForOnd(): boolean {
    return true;
  }

  canPurchaseGamePackForOnd(): boolean {
    return false;
  }

  canEditGamePack(): boolean {
    return false;
  }

  hasUnlimitedOndGameUGCAccess(): boolean {
    return false;
  }
  hasUnlimitedTrainingAccess(): boolean {
    return false;
  }
}

class AnonFeatureChecker implements IFeatureChecker {
  hasUnlimitedLiveBooking(): boolean {
    return false;
  }
  getLiveBookingDiscount(): number | null | undefined {
    return;
  }
  hasAnyProgramAccess(): boolean {
    return false;
  }
  hasUnlimitedProgramAccess(): boolean {
    return false;
  }
  canAccessProgram(_programId: string): boolean {
    return false;
  }
  canAccessProgramOfType(_type: EnumsProgramType): boolean {
    return false;
  }
  hasVenueCap(): boolean {
    return false;
  }
  getVenueCap(_gamePack?: { id: string }): number | undefined {
    return undefined;
  }
  hasUnlimitedOndGamePlayAccess(): boolean {
    return false;
  }
  hasAnyOndGamePlayAccess(): boolean {
    return false;
  }
  canAccessGamePack(_gamePack: GamePack): boolean {
    return false;
  }
  canPurchaseGamePack(gamePack: GamePack): boolean {
    return GamePackUtils.ActivePrices(gamePack).length > 0;
  }
  canAccessGamePackForOnd(_gamePack: GamePack): boolean {
    return false;
  }
  canPurchaseGamePackForOnd(gamePack: GamePack): boolean {
    if (this.canAccessGamePackForOnd(gamePack)) return false;
    return GamePackUtils.ActivePrices(gamePack).length > 0;
  }
  canEditGamePack(): boolean {
    return false;
  }
  hasUnlimitedOndGameUGCAccess(): boolean {
    return false;
  }
  hasUnlimitedTrainingAccess(): boolean {
    return false;
  }
}

export class FeatureChecker implements IFeatureChecker {
  static GetFeatureChecker(
    user: User,
    features?: ModelsProductFeature[],
    purhcase?: ModelsOrgPurchase | null
  ): IFeatureChecker {
    if (RoleUtils.isAdmin(user)) return new AdminFeatureChecker();
    // note(falcon): this is primarily for compatibility with how the system used to work. we should improve the logic
    // on how we determine a "host".
    if (!isGuest(user) && !isOrgMember(user)) return new HostFeatureChecker();
    return new FeatureChecker(
      user.id,
      features ?? user.organizer?.organization?.subscription?.features ?? [],
      purhcase ?? user.organizer?.organization?.purchase,
      user.organizer?.organization?.maxSize
    );
  }

  static GetAnonFeatureChecker() {
    return new AnonFeatureChecker();
  }

  constructor(
    readonly uid: string,
    readonly features: ModelsProductFeature[],
    readonly purchase?: ModelsOrgPurchase | null,
    readonly seatLimit?: number | null | undefined
  ) {}

  hasUnlimitedLiveBooking(): boolean {
    return this.features.some(
      (f) =>
        f.type === EnumsProductFeatureType.ProductFeatureTypeLiveBooking &&
        f.featureSettings.liveBooking?.access ===
          EnumsLiveBookingAccess.LiveBookingAccessUnlimited
    );
  }

  getLiveBookingDiscount(): number | undefined | null {
    const f = this.features.find(
      (f) =>
        f.type === EnumsProductFeatureType.ProductFeatureTypeLiveBooking &&
        f.featureSettings.liveBooking?.access ===
          EnumsLiveBookingAccess.LiveBookingAccessDiscount
    );
    return f?.featureSettings.liveBooking?.discount;
  }

  hasAnyProgramAccess(): boolean {
    return this.features.some(
      (f) => f.type === EnumsProductFeatureType.ProductFeatureTypeProgramAccess
    );
  }

  hasVenueCap(): boolean {
    const hasVenueCapFeature = this.features.some(
      (f) => f.type === EnumsProductFeatureType.ProductFeatureTypeVenueSeatCap
    );
    return (
      hasVenueCapFeature ||
      (this.seatLimit !== null && this.seatLimit !== undefined)
    );
  }

  getVenueCap(gamePack?: { id: string }): number | undefined {
    const f = this.features.find(
      (f) => f.type === EnumsProductFeatureType.ProductFeatureTypeVenueSeatCap
    );
    const seatCap = f?.featureSettings.venueSeatCap?.seatCap ?? this.seatLimit;
    if (!seatCap) return undefined;
    if (!gamePack?.id) return seatCap;

    // no seat cap for a purchase.
    if (this.purchase?.gamePackIds.includes(gamePack.id)) return undefined;
    return seatCap;
  }

  hasUnlimitedProgramAccess(): boolean {
    return this.features.some(
      (f) =>
        f.type === EnumsProductFeatureType.ProductFeatureTypeProgramAccess &&
        f.featureSettings.programAccess?.access ===
          EnumsProgramAccess.ProgramAccessUnlimited
    );
  }

  canAccessProgram(programId: string): boolean {
    if (this.hasUnlimitedProgramAccess()) return true;
    return this.features.some((f) => {
      if (f.type !== EnumsProductFeatureType.ProductFeatureTypeProgramAccess)
        return false;
      return (
        f.featureSettings.programAccess?.programIds?.includes(programId) ??
        false
      );
    });
  }

  canAccessProgramOfType(type: EnumsProgramType): boolean {
    if (this.hasUnlimitedProgramAccess()) return true;
    return this.features.some((f) => {
      if (f.type !== EnumsProductFeatureType.ProductFeatureTypeProgramAccess)
        return false;
      return (
        f.featureSettings.programAccess?.programTypes?.includes(type) ?? false
      );
    });
  }

  hasUnlimitedOndGamePlayAccess(): boolean {
    return this.features.some(
      (f) =>
        f.type === EnumsProductFeatureType.ProductFeatureTypeOndGamePlay &&
        f.featureSettings.ondGamePlay?.access ===
          EnumsOndGamePlayAccess.OndGamePlayAccessUnlimited
    );
  }

  hasAnyOndGamePlayAccess(): boolean {
    return this.features.some(
      (f) => f.type === EnumsProductFeatureType.ProductFeatureTypeOndGamePlay
    );
  }

  canAccessGamePack(gamePack: GamePack): boolean {
    if (gamePack.cohostSettings?.enabled) {
      return this.canAccessGamePackForLive(gamePack);
    }
    return this.canAccessGamePackForOnd(gamePack);
  }

  canPurchaseGamePack(gamePack: GamePack): boolean {
    if (gamePack.cohostSettings?.enabled) {
      return this.canPurchaseGamePackForLive(gamePack);
    }
    return this.canPurchaseGamePackForOnd(gamePack);
  }

  canAccessGamePackForLive(gamePack: GamePack): boolean {
    if (this.hasUnlimitedLiveBooking()) return true;
    if (this.purchase?.gamePackIds.includes(gamePack.id)) return true;
    return false;
  }

  canPurchaseGamePackForLive(gamePack: GamePack): boolean {
    if (this.canAccessGamePackForLive(gamePack)) return false;
    return GamePackUtils.ActivePrices(gamePack).length > 0;
  }

  canAccessGamePackForOnd(gamePack: GamePack): boolean {
    // backwards compatibility
    // if (gamePack.isFree) return true;
    if (this.hasUnlimitedOndGamePlayAccess()) return true;
    if (this.purchase?.gamePackIds.includes(gamePack.id)) return true;
    return this.features.some((f) => {
      if (
        f.type !== EnumsProductFeatureType.ProductFeatureTypeOndGamePlay ||
        !f.featureSettings.ondGamePlay
      )
        return false;

      const { gamePackIds, gamePackTagIds } = f.featureSettings.ondGamePlay;
      if (gamePackIds?.includes(gamePack.id)) return true;
      if (gamePackTagIds && gamePack.tags) {
        const tags = gamePack.tags.map((t) => t.id);
        if (gamePackTagIds.some((id) => tags.includes(id))) return true;
      }
      return false;
    });
  }

  canPurchaseGamePackForOnd(gamePack: GamePack): boolean {
    const otpCheckout = getFeatureQueryParam('otp-checkout');
    if (!otpCheckout) return false;

    const hasPricingTable = GamePackUtils.ActivePrices(gamePack).length > 0;
    // cannot purchase that which has no price!
    if (!hasPricingTable) return false;

    // cannot purchase something you already purchased.
    if (this.purchase?.gamePackIds.includes(gamePack.id)) return false;

    // venue capped users must purchase to play, as well as users without access.
    return this.hasVenueCap() || !this.canAccessGamePackForOnd(gamePack);
  }

  canEditGamePack(gamePack: GamePack): boolean {
    if (!this.canAccessGamePackForOnd(gamePack)) return false;
    if (gamePack.isPrime) return false;
    return gamePack.uid === this.uid;
  }

  hasUnlimitedOndGameUGCAccess(): boolean {
    return this.features.some(
      (f) =>
        f.type === EnumsProductFeatureType.ProductFeatureTypeOndGameUgc &&
        f.featureSettings.ondGameUGC?.access ===
          EnumsOndGameUGCAccess.OndGameUGCAccessUnlimited
    );
  }

  hasUnlimitedTrainingAccess(): boolean {
    return this.features.some(
      (f) =>
        f.type === EnumsProductFeatureType.ProductFeatureTypeTraining &&
        f.featureSettings.training?.access ===
          EnumsTrainingAccess.TrainingAccessUnlimited
    );
  }
}
