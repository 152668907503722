import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import ReactMarkdown, { type Options } from 'react-markdown';
import useSWR from 'swr';
import { useSnapshot } from 'valtio';

import {
  type DtoGamePack,
  type DtoTrainingEditorMessage,
} from '@lp-lib/api-service-client/public';
import { type Block, BlockType } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../hooks/useInstance';
import { apiService } from '../../../services/api-service';
import { uuidv4 } from '../../../utils/common';
import { type GameEditorStore } from '../../Game/GameEditorStore';
import { useUGCFileManager } from '../../Game/UGC/CustomGameFileManager';
import { CustomGamePackPromptEditor } from '../../Game/UGC/CustomGamePackPromptEditor';
import { AIIcon } from '../../icons/AIIcon';
import { SparkBlockIcon } from '../../icons/Block';
import { type TrainingEditorControlAPI } from './TrainingEditor';
import { type TrainingEditorAIChatStatus } from './types';
import { TrainingEditorUtils } from './utils';

export const markdownComponents: Options['components'] = {
  h1: ({ children }) => <h1 className='text-lg font-bold'>{children}</h1>,
  h2: ({ children }) => <h2 className='text-base font-bold'>{children}</h2>,
  h3: ({ children }) => <h3 className='text-sm font-bold'>{children}</h3>,
  h4: ({ children }) => <h4 className='text-xs font-bold'>{children}</h4>,
  h5: ({ children }) => <h5 className='text-2xs font-bold'>{children}</h5>,
  h6: ({ children }) => <h6 className='text-3xs font-bold'>{children}</h6>,
  ul: ({ children }) => <ul className='list-disc list-inside'>{children}</ul>,
  ol: ({ children }) => (
    <ol className='list-decimal list-inside'>{children}</ol>
  ),
  pre: ({ children }) => (
    <pre className='whitespace-pre overflow-x-auto my-1 bg-light-gray p-2 rounded-md'>
      {children}
    </pre>
  ),
  blockquote: ({ children }) => (
    <blockquote className='border-l-2 border-gray-400 pl-2.5 my-2.5 text-icon-gray'>
      {children}
    </blockquote>
  ),
};

function MessageContainer(props: {
  block: Block;
  message: DtoTrainingEditorMessage;
  ctrl: TrainingEditorControlAPI;
}) {
  const { block, message, ctrl } = props;

  if (message.role === 'ai') {
    return (
      <div className='flex gap-2'>
        <AIIcon className='w-5 h-5 fill-current flex-none' />
        <div className='flex flex-col items-start gap-1'>
          {message.blockFields && (
            <button
              type='button'
              className='text-sm text-secondary hover:text-icon-gray'
              onClick={() => {
                ctrl.previewBlock({
                  ...block,
                  fields: message.blockFields,
                });
                ctrl.markCurrentBlockDirty();
              }}
            >
              Edited
            </button>
          )}
          <div className='text-xs'>
            <ReactMarkdown components={markdownComponents}>
              {message.text}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`max-w-[90%] bg-secondary rounded-xl p-3 text-sms whitespace-pre-wrap self-end`}
    >
      {message.text}
    </div>
  );
}

function MessageList(props: {
  block: Block;
  messages: DtoTrainingEditorMessage[];
  isRunning: boolean;
  ctrl: TrainingEditorControlAPI;
}) {
  const { block, messages, isRunning, ctrl } = props;

  const ref = useRef<HTMLDivElement>(null);

  const messagesCount = messages.length;

  useLayoutEffect(() => {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [messagesCount]);

  return (
    <div className='w-full flex-1 overflow-auto scrollbar text-white flex flex-col gap-2'>
      <div className='w-full flex-1'></div>

      <div className='flex flex-col gap-2'>
        {messages.map((msg) => {
          return (
            <MessageContainer
              key={msg.id}
              block={block}
              message={msg}
              ctrl={ctrl}
            />
          );
        })}

        {isRunning && (
          <MessageContainer
            block={block}
            message={{
              id: 'faked-thinking-id',
              role: 'ai',
              text: 'Thinking...',
            }}
            ctrl={ctrl}
          />
        )}

        {!isRunning && messages.length === 0 && (
          <MessageContainer
            block={block}
            message={{
              id: 'faked-intro-id',
              role: 'ai',
              text: 'I will help you edit this block, what do you want me to do here?',
            }}
            ctrl={ctrl}
          />
        )}
      </div>
      <div ref={ref}></div>
    </div>
  );
}

export function TrainingEditorAIChatSidebar(props: {
  pack: DtoGamePack;
  store: GameEditorStore;
  ctrl: TrainingEditorControlAPI;
}) {
  const { pack, store, ctrl } = props;

  const { selectedBlockId } = useSnapshot(store.state);
  const { blocks } = useSnapshot(store.blockEditorStore.state);
  const selectedBlock: Block | null = useMemo(() => {
    if (!selectedBlockId) return null;
    return blocks.find((b) => b.id === selectedBlockId) as Block | null;
  }, [blocks, selectedBlockId]);
  const fileman = useUGCFileManager();
  const { data: messages = [], mutate: mutateMessages } = useSWR(
    selectedBlockId
      ? `/training/blocks/${selectedBlockId}/editor/messages`
      : null,
    async () => {
      if (!selectedBlockId) return [];
      const resp = await apiService.training.getEditorMessages(selectedBlockId);
      return resp.data.messages;
    }
  );

  const {
    call: handleSubmit,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async (message: string) => {
    if (!selectedBlockId) return false;

    mutateMessages(
      (prev) => [
        ...(prev ?? []),
        {
          id: uuidv4(),
          role: 'human',
          text: message,
        },
      ],
      {
        revalidate: false,
      }
    );
    fileman.deleteAllFilesLocally();

    const resp = await ctrl.sendAIChatMessage(selectedBlockId, {
      packId: pack.id,
      text: message,
      fileIds: fileman.state.files.map((f) => f.file.id),
    });
    mutateMessages(resp.messages, {
      revalidate: false,
    });
    if (resp.block) {
      store.blockEditorStore.replaceBlock(fromAPIBlockTypes(resp.block));
      ctrl.markCurrentBlockDirty();
    }

    return true;
  });

  useEffect(() => {
    fileman.init(pack.id, []);
  }, [pack?.id, fileman]);

  if (!selectedBlock) return null;
  return (
    <div className='w-full h-full flex flex-col gap-4'>
      <div className='w-full flex-none text-white text-sms flex justify-between items-center gap-2'>
        <div className='flex items-center gap-2'>
          <SparkBlockIcon
            blockType={selectedBlock.type}
            fields={selectedBlock.fields}
            className='w-6 h-6 fill-current'
          />
          <p className='ml-1'>
            {TrainingEditorUtils.BlockTitle(selectedBlock as Block)}
          </p>
        </div>
        <button
          type='button'
          className='btn text-primary'
          onClick={() => {
            ctrl.clearAIChat(selectedBlock.id);
            mutateMessages([], {
              revalidate: false,
            });
          }}
        >
          Clear
        </button>
      </div>

      <MessageList
        block={selectedBlock as Block}
        messages={messages}
        isRunning={isRunning}
        ctrl={ctrl}
      />

      <div className='w-full flex-none'>
        <CustomGamePackPromptEditor
          enabled
          onSubmit={handleSubmit}
          onAbort={() => void 0}
          isSubmitting={isRunning}
          active
          autoFocus
          error={error}
          disableDeactivate
          placeholder='Type message...'
          wrapperClassName='mb-4'
          width='w-full'
        />
      </div>
    </div>
  );
}

const SUPPORTED_BLOCK_TYPES = [
  BlockType.QUESTION,
  BlockType.MULTIPLE_CHOICE,
  BlockType.JEOPARDY,
  BlockType.SLIDE,
  BlockType.MEMORY_MATCH,
];

export function AIChatButton(props: {
  aiChatStatus: TrainingEditorAIChatStatus;
  selectedBlock: Block;
  ctrl: TrainingEditorControlAPI;
}) {
  const { aiChatStatus, selectedBlock, ctrl } = props;

  const isMac = useInstance(() =>
    navigator.userAgent.toLowerCase().includes('mac')
  );
  const aiChatEnabled = useFeatureQueryParam('ai-slide-editor-chat');
  const enabled =
    aiChatEnabled && SUPPORTED_BLOCK_TYPES.includes(selectedBlock.type);
  // render it when opening & closing, so it can be anchored by AIChatBackground
  const render = enabled && aiChatStatus !== 'open';
  const visible = render && aiChatStatus === 'closed';

  useHotkeys(
    'mod+k',
    (event) => {
      event.preventDefault();
      ctrl.openAIChat();
    },
    { enabled: visible }
  );

  if (!render) return null;
  return (
    <button
      id='ai-chat-button'
      type='button'
      onClick={() => ctrl.openAIChat()}
      className={`
        bg-gradient-to-bl from-pairing-start to-pairing-end p-px rounded-xl
        ${visible ? 'opacity-100' : 'opacity-0'}
      `}
    >
      <div className='bg-dark-gray hover:bg-secondary-hover rounded-xl p-3 flex items-center'>
        <AIIcon className='w-3.5 h-3.5 fill-[#FF0935]' />
        <p className='ml-1 text-2xs text-white font-medium'>
          Edit this Slide with AI Chat
        </p>
        <p className='ml-3.5 text-sms text-icon-gray'>{isMac ? '⌘K' : '^K'}</p>
      </div>
    </button>
  );
}

export function AIChatHeader(props: {
  aiChatStatus: TrainingEditorAIChatStatus;
  ctrl: TrainingEditorControlAPI;
}) {
  const { aiChatStatus, ctrl } = props;

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (aiChatStatus !== 'opening') return;

    ref.current?.animate(
      [
        { opacity: 0, height: '0px' },
        { opacity: 0, height: '60px', offset: 500 / 700 },
        { opacity: 1, height: '60px' },
      ],
      { duration: 700, fill: 'forwards' }
    );
  }, [aiChatStatus]);

  useLayoutEffect(() => {
    if (aiChatStatus !== 'closing') return;

    ref.current?.animate([{ height: '60px' }, { height: '0px' }], {
      duration: 500,
      fill: 'forwards',
    });
  }, [aiChatStatus]);

  if (aiChatStatus === 'closed') return null;
  return (
    <div
      ref={ref}
      className={`
          flex-none w-full h-15 overflow-hidden
      `}
    >
      <div
        className='w-full h-15 relative 
          flex justify-center items-center'
      >
        <p className='text-base text-icon-gray'>
          AI chat will only update this slide. Your chat and draft history will
          be saved here.
        </p>

        <div className='absolute right-2'>
          <button
            type='button'
            className='btn text-white w-20 h-10'
            onClick={() => ctrl.closeAIChat()}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

export function AIChatBackground(props: {
  aiChatStatus: TrainingEditorAIChatStatus;
}) {
  const { aiChatStatus } = props;

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (aiChatStatus !== 'opening' && aiChatStatus !== 'closing') return;

    const buttonRect = document
      .getElementById('ai-chat-button')
      ?.getBoundingClientRect();

    if (!ref.current || !buttonRect) return;

    const displayRect = ref.current?.getBoundingClientRect();

    const tx = buttonRect.left - displayRect.left;
    let ty = buttonRect.top - displayRect.top;
    // 60px is the height of AIChatHeader, it will be hidden when aiChatStatus is 'closing'
    if (aiChatStatus === 'closing') {
      ty = ty - 60;
    }
    const scaleX = buttonRect.width / displayRect.width;
    const scaleY = buttonRect.height / displayRect.height;

    const keyframes = [
      {
        transformOrigin: 'top left',
        transform: `translate(${tx}px, ${ty}px) scale(${scaleX}, ${scaleY})`,
      },
      {
        transformOrigin: 'top left',
        transform: `translate(0px, 0px) scale(1, 1)`,
      },
    ];
    if (aiChatStatus === 'closing') {
      keyframes.reverse();
    }
    ref.current.animate(keyframes, {
      duration: 500,
      fill: 'forwards',
    });
  });

  if (aiChatStatus === 'closed') return null;
  return (
    <div
      ref={ref}
      className='absolute inset-0 bg-gradient-to-bl from-pairing-start to-pairing-end p-px rounded-xl'
    >
      <div className='w-full h-full bg-dark-gray rounded-xl'></div>
    </div>
  );
}
