import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { type ConnectDragSource } from 'react-dnd';
import Select from 'react-select';

import {
  type MultipleChoiceBlock,
  type MultipleChoiceOption,
} from '@lp-lib/game';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { DragDropList } from '../../common/DragDrop';
import { PointsInput } from '../../Game/Blocks/Common/Editor/PointsUtilities';
import { CommonButton, GamePlayButton } from '../../GameV2/design/Button';
import { FFriendlyEditableText } from '../../GameV2/design/Editable';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { MenuIcon } from '../../icons/MenuIcon';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { useTrainingSlideEditor } from './hooks';
import { BlockIntroSelect } from './Shared/BlockIntroSelect';
import { BlockMusicSelect } from './Shared/BlockMusicSelect';
import { type TrainingSlideEditorProps } from './types';

export function MultipleChoiceBlockEditor(
  props: TrainingSlideEditorProps<MultipleChoiceBlock>
) {
  return <Left {...props} />;
}

export function MultipleChoiceBlockSidebarEditor(
  props: TrainingSlideEditorProps<MultipleChoiceBlock>
) {
  return <Right {...props} />;
}

function Left(props: TrainingSlideEditorProps<MultipleChoiceBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const { block } = props;

  return (
    <MultipleChoiceEditor
      question={block.fields.question || ''}
      answerChoices={block.fields.answerChoices ?? []}
      onQuestionChange={(question) => {
        onChange('question', question);
        onBlur('question', question);
      }}
      onAnswerChoicesChange={(answerChoices) => {
        onChange('answerChoices', answerChoices);
        onBlur('answerChoices', answerChoices);
      }}
      maxChoicesCount={4}
    />
  );
}

export function MultipleChoiceEditor(props: {
  question: string;
  answerChoices: MultipleChoiceOption[];
  maxChoicesCount?: number;
  onQuestionChange: (value: string) => void;
  onAnswerChoicesChange: (value: MultipleChoiceOption[]) => void;
}) {
  const {
    question,
    answerChoices,
    maxChoicesCount = 4,
    onQuestionChange,
    onAnswerChoicesChange,
  } = props;

  const handleAddChoice = useLiveCallback(() => {
    if (answerChoices.length === 0) {
      const next = [{ text: '', correct: true }];
      onAnswerChoicesChange(next);
      return;
    }
    const next = [...answerChoices, { text: '' }];
    onAnswerChoicesChange(next);
  });

  const handleRemoveChoice = useLiveCallback((idx: number) => {
    const next = [...answerChoices];
    const [removed] = next.splice(idx, 1);
    if (removed?.correct && next.length > 0) {
      next[0] = { ...next[0], correct: true };
    }
    onAnswerChoicesChange(next);
  });

  const handleChoiceChange = useLiveCallback((idx: number, value: string) => {
    const next = [...answerChoices];
    next[idx] = { ...next[idx], text: value };
    onAnswerChoicesChange(next);
  });

  const handleMoveChoice = useLiveCallback((from: number, to: number) => {
    const next = [...answerChoices];
    const item = next[from];
    next.splice(from, 1);
    next.splice(to, 0, item);
    onAnswerChoicesChange(next);
  });

  return (
    <div className='relative w-full h-full min-h-0 flex flex-col'>
      <main className='w-full flex-1 min-h-0 px-5 flex flex-col items-center justify-center gap-2 lp-sm:gap-10'>
        <div className='text-white text-base text-center px-5'>
          <FFriendlyEditableText
            value={question}
            onBlur={(val) => {
              onQuestionChange(val);
            }}
            className={`
              w-full outline-none cursor-text
              contenteditable-placeholder whitespace-pre-wrap break-words
              text-base sm:text-xl lg:text-2xl
            `}
            placeholder='Your Question Here'
          />
        </div>

        <div className='w-full max-w-150 flex flex-col items-center gap-2'>
          <DragDropList
            type={`mc-answers`}
            items={answerChoices.map((o, idx) => ({
              ...o,
              id: `${idx}-${o.text}`,
            }))}
            onMove={handleMoveChoice}
            render={({ item, index, drag, ref, style }) => {
              return (
                <div className='w-full' ref={ref} style={style}>
                  <ChoiceEditor
                    onClick={() => handleRemoveChoice(index)}
                    choice={item}
                    onBlur={(val) => {
                      handleChoiceChange(index, val);
                    }}
                    index={index}
                    drag={drag}
                  />
                </div>
              );
            }}
          />

          {answerChoices.length < maxChoicesCount && (
            <div className='w-full px-5'>
              <button
                type='button'
                className='w-full h-10 btn bg-transparent border border-dashed border-secondary italic text-secondary text-sm'
                onClick={handleAddChoice}
              >
                Add Choice (max {maxChoicesCount})
              </button>
            </div>
          )}
        </div>
      </main>
      <footer className='w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5'>
        <CommonButton variant='brand' disabled>
          Continue
        </CommonButton>
      </footer>
    </div>
  );
}

function ChoiceEditor(props: {
  onClick: () => void;
  choice: MultipleChoiceOption;
  onBlur: (val: string) => void;
  index: number;
  drag: ConnectDragSource;
}) {
  return (
    <div className='relative w-full group flex items-center'>
      <div
        className='invisible group-hover:visible w-5 flex-none flex justify-center text-white cursor-move'
        ref={props.drag}
      >
        <MenuIcon className='w-3.5 h-3.5 fill-current' />
      </div>
      <div className='flex-1 min-w-0'>
        <GamePlayButton
          variant='gray'
          disabled
          styles={{
            text: 'text-sm font-bold text-white',
            disabled: 'disabled:opacity-100 disabled:cursor-auto',
            spacing: 'px-4 py-1 xl:py-2',
            size: 'w-full min-h-8 xl:min-h-11.5',
          }}
        >
          <FFriendlyEditableText
            value={props.choice.text || ''}
            onBlur={props.onBlur}
            className='w-full outline-none cursor-text contenteditable-placeholder whitespace-pre-wrap break-words'
            placeholder={`Answer ${props.index + 1}`}
            style={{
              '--placeholder-color': 'white',
            }}
          />
        </GamePlayButton>
      </div>
      <div
        className='invisible group-hover:visible w-5 flex-none flex justify-center cursor-pointer text-red-001'
        onClick={props.onClick}
      >
        <DeleteIcon className='w-3.5 h-3.5 fill-current' />
      </div>
    </div>
  );
}

function Right(props: TrainingSlideEditorProps<MultipleChoiceBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col gap-5 text-white'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={block.fields.personalityId}
          isClearable
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Block Intro</p>
        <BlockIntroSelect
          value={block.fields.intro}
          onChange={(value) => {
            onChange('intro', value);
            onBlur('intro', value);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Points Earned</p>
        <PointsInput
          defaultValue={block.fields.points}
          max={1000}
          min={0}
          placeholder={'Max 1000 points'}
          onChange={(value) => {
            onChange('points', value);
            onBlur('points', value);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Correct Answer</p>
        <CorrectAnswerSelector {...props} />
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>Background Music</p>
        <BlockMusicSelect
          value={block.fields.bgMusic}
          onChange={(value) => {
            onChange('bgMusic', value);
            // Do not persist the decorated media object.
            const out = cloneDeep(value);
            delete out?.asset.media;
            onBlur('bgMusic', out);
          }}
        />
      </label>
    </div>
  );
}

type CorrectAnswerOption = MultipleChoiceOption & { index: number };

function CorrectAnswerSelector(
  props: TrainingSlideEditorProps<MultipleChoiceBlock>
) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const styles = useMemo(
    () => buildReactSelectStyles<CorrectAnswerOption>(),
    []
  );

  const options = useMemo(() => {
    return block.fields.answerChoices.map((o, index) => ({
      ...o,
      index,
    }));
  }, [block.fields.answerChoices]);

  const selected = useMemo(() => {
    return options.find((o) => o.correct);
  }, [options]);

  return (
    <Select<CorrectAnswerOption, false>
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full'
      value={selected ?? null}
      options={options}
      placeholder='Choose correct answer'
      isDisabled={options.length === 0}
      getOptionLabel={(o) => o.text || `Answer ${o.index + 1}`}
      onChange={(v) => {
        if (!v) return;
        const next = [...(block.fields.answerChoices ?? [])];
        next[v.index] = { ...next[v.index], correct: true };
        if (selected) {
          next[selected.index] = { ...next[selected.index], correct: false };
        }

        onChange('answerChoices', next);
        onBlur('answerChoices', next);
      }}
      isSearchable={false}
    />
  );
}

export function CorrectAnswerSelect(props: {
  choices: MultipleChoiceOption[];
  onChange: (choices: MultipleChoiceOption[]) => void;
}) {
  const styles = useMemo(
    () => buildReactSelectStyles<CorrectAnswerOption>(),
    []
  );

  const options = useMemo(() => {
    return props.choices.map((o, index) => ({
      ...o,
      index,
    }));
  }, [props.choices]);

  const selected = useMemo(() => {
    return options.find((o) => o.correct);
  }, [options]);

  return (
    <Select<CorrectAnswerOption, false>
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full'
      value={selected ?? null}
      options={options}
      placeholder='Choose correct answer'
      isDisabled={options.length === 0}
      getOptionLabel={(o) => o.text || `Answer ${o.index + 1}`}
      onChange={(v) => {
        if (!v) return;
        const next = [...props.choices];
        next[v.index] = { ...next[v.index], correct: true };
        if (selected) {
          next[selected.index] = { ...next[selected.index], correct: false };
        }
        props.onChange(next);
      }}
      isSearchable={false}
    />
  );
}
