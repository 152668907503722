import { EnumsFillInTheBlanksAnswerStyle } from '@lp-lib/api-service-client/public';
import { assertExhaustive, type FillInTheBlanksBlock } from '@lp-lib/game';

import { type BlockDependencies, type IBlockCtrl } from '../../types';
import {
  FIBChoiceControlAPI,
  FIBPlaygroundChoice,
} from './FIBPlaygroundChoice';
import {
  FIBPlaygroundTyping,
  FIBTypingControlAPI,
} from './FIBPlaygroundTyping';

export function newFIBControlAPI(
  block: FillInTheBlanksBlock,
  blockDeps: BlockDependencies
) {
  switch (block.fields.answerStyle) {
    case EnumsFillInTheBlanksAnswerStyle.FillInTheBlanksAnswerStyleChoice:
      return new FIBChoiceControlAPI(block, blockDeps);
    case EnumsFillInTheBlanksAnswerStyle.FillInTheBlanksAnswerStyleTyping:
      return new FIBTypingControlAPI(block, blockDeps);
    default:
      assertExhaustive(block.fields.answerStyle);
      return null;
  }
}

export function FIBPlayground(props: {
  block: FillInTheBlanksBlock;
  ctrl: IBlockCtrl;
}) {
  switch (props.block.fields.answerStyle) {
    case EnumsFillInTheBlanksAnswerStyle.FillInTheBlanksAnswerStyleChoice:
      return (
        <FIBPlaygroundChoice
          block={props.block}
          ctrl={props.ctrl as FIBChoiceControlAPI}
        />
      );
    case EnumsFillInTheBlanksAnswerStyle.FillInTheBlanksAnswerStyleTyping:
      return (
        <FIBPlaygroundTyping
          block={props.block}
          ctrl={props.ctrl as FIBTypingControlAPI}
        />
      );
    default:
      assertExhaustive(props.block.fields.answerStyle);
      return null;
  }
}
