import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { CourseDetailsPage } from '../components/Training/Admin/Course/CourseDetail';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const [pack, membersResponse, groups, stacks] = await Promise.all([
    tokenWithRedirect(
      async () =>
        await apiService.gamePack.getGamePackById(id, {
          blocks: true,
          games: true,
        }),
      action.request.url
    ),
    tokenWithRedirect(
      async () => await apiService.learning.getCourseAssignedMembers(id),
      action.request.url
    ),
    tokenWithRedirect(
      async () => await apiService.learning.getCourseAssignedGroups(id),
      action.request.url
    ),
    tokenWithRedirect(
      async () => await apiService.learning.getCourseStacks(id),
      action.request.url
    ),
  ]);

  return {
    pack: pack.data,
    assignedMembers: membersResponse.data.members.map((member) => ({
      ...member,
      assignedOn: new Date(member.assignedOn).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }),
    })),
    groups: groups.data.groups,
    stacks: stacks.data.stacks,
    games: pack.data.games,
  };
}

export function Component() {
  const { pack, assignedMembers, groups, stacks, games } =
    useLoaderData<typeof clientLoader>();

  return (
    <CourseDetailsPage
      pack={pack.gamePack}
      assignedMembers={assignedMembers}
      groups={groups}
      stacks={stacks}
      games={games}
    />
  );
}
