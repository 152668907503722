import { format } from 'date-fns';
import debounce from 'lodash/debounce';
import { useMemo } from 'react';
import type { SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { match } from 'ts-pattern';

import { type DtoCourseSearchResult } from '@lp-lib/api-service-client/public';

import coverPlaceholder from '../../../../assets/img/placeholder/game-cover.png';
import { apiService } from '../../../../services/api-service';
import { buildReactSelectStyles } from '../../../../utils/react-select';

export type CourseOption = {
  kind: 'existing';
  course: DtoCourseSearchResult;
  value: string;
};

interface CourseSingleSelectProps {
  excludeCourseIds?: string[];
  onSelect: (val: CourseOption) => void;
  placeholder?: string;
  disabled?: boolean;
}

async function loadAllCourses(
  excludeCourseIds: string[] = []
): Promise<CourseOption[]> {
  try {
    const resp = await apiService.learning.getUserCourses();
    const courses = resp.data;
    return courses
      .filter((c) => !excludeCourseIds.includes(c.gamePack.id))
      .map((c) => {
        const searchResult: DtoCourseSearchResult = {
          id: c.gamePack.id,
          name: c.gamePack.name,
          cover: c.gamePack.cover,
          createdAt: c.gamePack.createdAt,
        };
        return {
          kind: 'existing' as const,
          course: searchResult,
          value: c.gamePack.id,
        };
      });
  } catch {
    return [];
  }
}

async function searchCoursesByName(
  query: string,
  excludeCourseIds: string[] = []
): Promise<CourseOption[]> {
  try {
    const resp = await apiService.learning.searchUserCreatedCourses(query);
    const courses = resp.data.courses;
    return courses
      .filter((c) => !excludeCourseIds.includes(c.id))
      .map((g) => ({
        kind: 'existing' as const,
        course: g,
        value: g.id,
      }));
  } catch {
    return [];
  }
}

function loadCourses(
  inputValue: string,
  excludeCourseIds: string[] = []
): Promise<CourseOption[]> {
  if (!inputValue) {
    return loadAllCourses(excludeCourseIds);
  } else {
    return searchCoursesByName(inputValue, excludeCourseIds);
  }
}

const debouncedLoad = debounce(
  (
    inputValue: string,
    excludeCourseIds: string[],
    callback: (val: CourseOption[]) => void
  ) => {
    loadCourses(inputValue, excludeCourseIds)
      .then((results) => callback(results))
      .catch(() => callback([]));
  },
  200
);

export function CourseMultiSelect(props: CourseSingleSelectProps) {
  const { onSelect, placeholder, disabled, excludeCourseIds = [] } = props;

  const styles = useMemo(
    () =>
      buildReactSelectStyles<CourseOption, false>({
        override: {
          control: { minHeight: 48 },
          menu: {
            background: 'black',
            paddingLeft: '8px',
            paddingRight: '8px',
          },
          option: (provided, state) => ({
            ...provided,
            background:
              state.isFocused && !state.isDisabled ? '#303036' : 'black',
            borderRadius: '12px',
            padding: '6px',
          }),
        },
      }),
    []
  );

  function loadOptions(
    inputValue: string,
    callback: (options: CourseOption[]) => void
  ): void {
    debouncedLoad(inputValue, excludeCourseIds, callback);
  }

  function getOptionValue(option: CourseOption) {
    return option.course.id;
  }

  const formatOptionLabel = (option: CourseOption) => {
    return match(option)
      .with({ kind: 'existing' }, (o) => {
        const createdDate = format(new Date(o.course.createdAt), 'MM/dd/yyyy');
        return (
          <div className='flex items-center justify-between w-full'>
            <div className='flex items-center gap-3'>
              <img
                src={o.course.cover?.url || coverPlaceholder}
                alt={o.course.name}
                className='w-18 h-10 rounded-lg object-cover'
              />
              <span className='text-white'>{o.course.name}</span>
            </div>
            <div className='flex items-center gap-8'>
              <span className='text-white text-left'>{createdDate}</span>
            </div>
          </div>
        );
      })
      .exhaustive();
  };

  function handleChange(newValue: SingleValue<CourseOption>) {
    if (newValue) {
      onSelect(newValue);
    }
  }
  const filterOptions = (candidate: {
    label: string;
    value: string;
    data: CourseOption;
  }) => {
    return !excludeCourseIds.includes(candidate.data.course.id);
  };

  return (
    <AsyncSelect<CourseOption, false>
      isMulti={false}
      cacheOptions
      defaultOptions
      hideSelectedOptions
      filterOption={filterOptions}
      loadOptions={loadOptions}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      placeholder={placeholder ?? 'Select a course...'}
      value={null}
      onChange={handleChange}
      styles={styles}
      isDisabled={disabled}
      classNamePrefix='select-box-v2'
      noOptionsMessage={() => 'No matching courses'}
      isClearable={false}
    />
  );
}
