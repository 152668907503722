import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { type ReactNode, useState } from 'react';
import { useTitle } from 'react-use';

import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { HubSpotProductEditor } from './admin.hubspot.products.create.client';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });
  const [product, marketedStripeProducts] = await Promise.all([
    (await apiService.hubSpot.getProduct(id)).data.product,
    (await apiService.product.getPublicProducts()).data.marketed,
  ]);
  return { product, marketedStripeProducts };
}

export function Component() {
  const { product, marketedStripeProducts } =
    useLoaderData<typeof clientLoader>();
  useTitle(makeTitle(`Edit ${product.name}`));

  const [sidePanel, setSidePanel] = useState<ReactNode>(null);

  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <div className='w-full relative text-white flex flex-col gap-10'>
        <header className='flex justify-between items-center'>
          <h1 className='font-bold text-3xl flex items-center gap-1'>
            <p>Edit {product.name}</p>
          </h1>
        </header>
        <HubSpotProductEditor
          product={product}
          marketedStripeProducts={marketedStripeProducts}
          setSidePanel={setSidePanel}
        />
        {sidePanel && (
          <div className='fixed inset-0 z-50'>
            <div className='absolute inset-0 bg-lp-black-001' />
            <div className='absolute top-0 bottom-0 right-0 w-1/2 bg-layer-001'>
              {sidePanel}
            </div>
          </div>
        )}
      </div>
    </AdminView>
  );
}
