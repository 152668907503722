import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { MediaFormatVersion } from '@lp-lib/media';

import { useDebouncedValue } from '../../hooks/useDebouncedValue';
import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { SessionMode } from '../../types';
import { MediaUtils } from '../../utils/media';
import { BlockKnifeUtils } from '../Game/Blocks/Shared';
import {
  useOndGameCurrentPlaybackItemId,
  useOndGameState,
} from '../Game/hooks';
import { type PlaybackDescItem } from '../Game/Playback/intoPlayback';
import { usePlaybackDesc } from '../Game/Playback/PlaybackInfoProvider';
import { ArrowRightIcon } from '../icons/Arrows';
import { BlockIcon } from '../icons/Block';
import { useLayoutAnchorRect } from '../LayoutAnchors/LayoutAnchors';
import { Loading } from '../Loading';
import { useOndGameUIControl, useOndGameUIControlState } from './index';

export function OndPlayTestPanel() {
  const visibility = getFeatureQueryParamArray('game-on-demand-play-test');
  if (visibility === 'disabled') return null;
  return (
    <OndPlayTestPanelInternal
      initialVisibility={visibility === 'float' ? 'float' : 'closed'}
    />
  );
}

type Visibility = 'closed' | 'open' | 'float';

function OndPlayTestPanelInternal(props: { initialVisibility: Visibility }) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [visibility, setVisibility] = useState<Visibility>(
    props.initialVisibility
  );
  const handleClickDrawerHandle = useLiveCallback(() => {
    setVisibility((v) => (v === 'closed' ? 'open' : 'closed'));
  });
  const handleToggleFloat = useLiveCallback(() => {
    setVisibility((v) => (v === 'float' ? 'open' : 'float'));
  });
  const [isSettling, setIsSettling] = useState(false);

  const { top: spacingTop, height: spacingHeight } = useLayoutAnchorRect(
    'lobby-top-spacing-anchor'
  ) ?? { top: 0, height: 0 };

  useLayoutEffect(() => {
    if (!ref.current) return;
    if (visibility !== 'float') {
      // ensure we're back to top...
      ref.current.style.top = '0';
      return;
    }
    ref.current.style.top = `${spacingTop + spacingHeight + 8}px`;
  }, [visibility, spacingTop, spacingHeight]);

  return (
    <div
      ref={ref}
      className={`
        fixed bg-layer-001
        ${
          visibility === 'float'
            ? 'top-2 bottom-2 left-14 z-10 rounded-lg border border-secondary opacity-50 hover:opacity-100'
            : 'top-0 bottom-0 left-0 z-50'
        }
        w-86 flex flex-col min-h-0
        transform transition-all ${
          visibility === 'closed' ? '-translate-x-full' : 'translate-x-0'
        }
      `}
    >
      <header className='flex-none px-4.5 pt-4.5 pb-3 border-b border-secondary'>
        <div className='flex items-center justify-between'>
          <div className='text-white text-lg font-bold'>Playback</div>
          <div className='flex items-center gap-2 text-white'>
            {isSettling && <Loading text='' imgClassName='w-3.5 h-3.5' />}
            <ToggleFloatButton
              visibility={visibility}
              onToggleFloat={handleToggleFloat}
            />
          </div>
        </div>
        <Subtitle />
      </header>
      <main
        className={`
          w-full flex-1 min-h-0
          overflow-y-scroll scrollbar
          text-white
        `}
      >
        <PlaybackPanel setIsSettling={setIsSettling} />
      </main>
      {visibility !== 'float' && (
        <button
          type='button'
          className={`
            w-9 h-28 absolute right-0 top-1/3 transform -translate-y-1/3 translate-x-full z-5 text-white
            appearance-none outline-none focus:outline-none
            rounded-tr-md rounded-br-md bg-layer-001
            flex items-center justify-center
          `}
          onClick={handleClickDrawerHandle}
        >
          <ArrowRightIcon
            className={`
              w-5 h-5 fill-current transform transition-transform
              ${visibility === 'open' ? 'rotate-180' : 'rotate-0'}
              delay-300 ease-in-out duration-300
            `}
          />
        </button>
      )}
    </div>
  );
}

function ToggleFloatButton(props: {
  visibility: Visibility;
  onToggleFloat: () => void;
}) {
  const { visibility, onToggleFloat } = props;
  const isFloating = visibility === 'float';
  return (
    <button
      type='button'
      onClick={onToggleFloat}
      title={isFloating ? 'Pin to side' : 'Detach to float'}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='w-4 h-4 fill-current hover:scale-120 transition-transform'
        viewBox='0 0 256 256'
      >
        {isFloating ? (
          <path d='M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,56H80V200H40ZM216,200H96V56H216V200Z'></path>
        ) : (
          <path d='M216,48H40A16,16,0,0,0,24,64V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM40,64H216v56H136a8,8,0,0,0-8,8v64H40ZM216,192H144V136h72v56Z'></path>
        )}
      </svg>
    </button>
  );
}

function useCanUsePlayTestPanel() {
  const ondGameState = useOndGameState();
  const ondGameUIState = useOndGameUIControlState();
  return ondGameState === 'running' && !ondGameUIState?.actionDisabled;
}

function Subtitle() {
  const isRunning = useCanUsePlayTestPanel();
  return isRunning ? (
    <div className='text-icon-gray text-xs'>
      Click to skip directly to a block
    </div>
  ) : (
    <div className='text-icon-gray text-xs'>
      Planned playback for this session
    </div>
  );
}

function PlaybackPanel(props: {
  setIsSettling: (isSettling: boolean) => void;
}) {
  const { setIsSettling } = props;
  const descRaw = usePlaybackDesc(SessionMode.OnDemand);
  const {
    value: desc,
    isInitializing,
    isSettling,
  } = useDebouncedValue(descRaw, {
    settleAfterMs: 500,
    keepPreviousValue: true,
  });

  useEffect(() => {
    setIsSettling(isSettling);
    return () => {
      setIsSettling(false);
    };
  }, [setIsSettling, isSettling]);

  const items = useMemo(() => {
    if (!desc?.items) return null;
    return desc.items.map((item, index) => {
      const renderUnitLabel =
        item.sessionUnitIndex !== desc.items[index - 1]?.sessionUnitIndex;
      return (
        <div className='w-full' key={`${item.sessionUnitIndex}-${item.id}`}>
          {renderUnitLabel && (
            <div
              className={`
                ${index === 0 ? '' : 'border-t border-secondary'}
                text-icon-gray text-xs capitalize py-2 font-bold
              `}
            >
              {desc.uiInfo.unitLabel} {item.sessionUnitIndex + 1}
            </div>
          )}
          <PlaybackItemCard item={item} />
        </div>
      );
    });
  }, [desc?.uiInfo.unitLabel, desc?.items]);

  return (
    <>
      {isInitializing ? (
        <div className='h-full flex items-center justify-center'>
          <Loading text='' />
        </div>
      ) : !desc ? (
        <div className='h-full flex items-center justify-center font-bold'>
          No playback description available
        </div>
      ) : (
        <div className='w-full min-h-0 flex flex-col gap-4'>
          <div className='px-4.5 space-y-4.5'>{items}</div>
          <footer className='w-full pb-4.5' />
        </div>
      )}
    </>
  );
}

function PlaybackItemCard(props: { item: PlaybackDescItem }) {
  const { item } = props;
  const isRunning = useCanUsePlayTestPanel();
  const ondGameUICtrl = useOndGameUIControl();
  const currentPlaybackItemId = useOndGameCurrentPlaybackItemId();
  const blockSummary = useMemo(() => {
    return BlockKnifeUtils.Summary(item.block);
  }, [item.block]);
  const mediaUrl = useMemo(() => {
    return (
      MediaUtils.PickMediaUrl(blockSummary.coverMedia, {
        priority: [MediaFormatVersion.SM],
        videoThumbnail: 'first',
      }) ?? null
    );
  }, [blockSummary.coverMedia]);
  const handleClick = useLiveCallback(async () => {
    if (!isRunning) return;
    ondGameUICtrl?.onClickPlayTestPlaybackItem(item.id);
  });
  const isCurrent = item.id === currentPlaybackItemId;

  const ref = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    if (!isCurrent || !ref.current) return;
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [isCurrent]);

  return (
    <div
      ref={ref}
      key={item.id}
      className={`
        w-full h-18 p-1.5 flex gap-2 border rounded-md transform transition-all
        ${
          isRunning
            ? 'hover:border-primary hover:border-solid hover:scale-105 hover:bg-black cursor-pointer'
            : 'opacity-70'
        }
        ${
          isCurrent
            ? 'border-primary ring-1 ring-primary'
            : item.injected
            ? 'border-tertiary border-dashed'
            : 'border-secondary'
        }
      `}
      onClick={handleClick}
    >
      <div
        className='flex-none h-full bg-secondary rounded-md overflow-hidden'
        style={{ aspectRatio: '16/9' }}
      >
        {mediaUrl && (
          <img className='w-full h-full object-cover' src={mediaUrl} alt='' />
        )}
      </div>
      <div className='min-w-0 flex-1 flex flex-col gap-1'>
        <div className='flex gap-1'>
          <div className='flex-1 line-clamp-2 text-xs text-white font-bold'>
            {blockSummary.title || blockSummary.prettyTypeName}
          </div>
          <BlockIcon
            blockType={item.block.type}
            className='flex-none w-3.5 h-3.5 fill-current'
          />
        </div>
        <div className='text-2xs text-icon-gray truncate'>
          {item.brand?.name ?? blockSummary.subtitle}
        </div>
      </div>
      <div className='absolute bottom-0 right-0 transform translate-y-full text-tertiary h-4 text-3xs italic'>
        {item.scenario}
      </div>
    </div>
  );
}
