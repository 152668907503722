import { useNavigate, useRevalidator, useSearchParams } from '@remix-run/react';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { $path } from 'remix-routes';

import {
  type DtoGetLearnersResponse,
  type DtoLearningGroupWithCounts,
  type ModelsLearnerAssignmentDetail,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { getLogger } from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import { SegmentedControl } from '../../../common/SegmentedControl';
import { type Option } from '../../../common/Utilities';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { EditIcon } from '../../../icons/EditIcon';
import { OptionsIcon } from '../../../icons/OptionsIcon';
import { TeamIcon } from '../../../icons/TeamIcon';
import { useTriggerAddMembersToGroupModal } from '../useTriggerAddMembersToGroupModal';
import { useTriggerCreateGroupModal } from '../useTriggerCreateGroupModal';

const logger = getLogger();

function LearnerRowItem({ row }: { row: ModelsLearnerAssignmentDetail }) {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const fullName = `${row.firstName} ${row.lastName}`.trim();

  const handleClick = useLiveCallback(() => {
    navigate($path('/learning/admin/learners/:id/profile', { id: row.userId }));
  });

  return (
    <div
      className='w-full rounded-lg px-4 py-3 bg-[#17171A] flex items-center hover:bg-[#1f1f22] transition-colors relative border-lp-gray-003 border cursor-pointer'
      onClick={handleClick}
    >
      <div className='flex-1 text-white font-medium text-sm truncate'>
        {fullName === '' ? row.userEmail : fullName}
      </div>
      <div className='flex-1 text-white/90 text-sm truncate'>
        {row.groupNames ? row.groupNames.join(', ') : 'N/A'}
      </div>

      {/* Courses column with hover popover */}
      <div
        className='w-20 text-white/90 text-sm flex items-center justify-center relative'
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {row.courses?.length ?? 0}
        {hovered && row.courses && (
          <div className='absolute top-full bg-black text-white rounded-xl p-1 border border-secondary z-50 w-48 shadow-lg'>
            <ul className='flex flex-col text-sm max-h-60 overflow-auto'>
              {row.courses.map((c, i) => (
                <li
                  key={i}
                  className='p-2 hover:bg-lp-black-003 rounded transition-colors whitespace-nowrap'
                >
                  {c.course_name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className='w-20 text-white/90 text-sm flex items-center justify-center'>
        0%
      </div>
      <div className='w-12'></div>
    </div>
  );
}

interface GroupRowItemProps {
  row: DtoLearningGroupWithCounts;
  onAddMembers: (groupId: string) => void;
  onDeleteGroup: (groupId: string) => void;
  onEditGroup: (groupId: string) => void;
}

export function GroupRowItem({
  row,
  onAddMembers,
  onDeleteGroup,
  onEditGroup,
}: GroupRowItemProps) {
  return (
    <div
      className='w-full rounded-lg px-4 py-3 bg-[#17171A] flex items-center hover:bg-[#1f1f22] transition-colors relative border border-lp-gray-003 hover:cursor-pointer'
      onClick={() => onEditGroup(row.id)}
    >
      <div className='flex-1 text-white font-medium text-sm truncate'>
        {row.name || 'Unnamed Group'}
      </div>

      <div className='w-24 text-white/90 text-sm text-center'>
        {row.membersCount}
      </div>

      <div className='w-32 text-white/90 text-sm text-center'>
        {new Date(row.createdAt).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })}
      </div>

      <div className='w-24 text-white/90 text-sm text-center'>
        {row.coursesCount}
      </div>

      <div className='w-24 text-white/90 text-sm text-center'>{'0%'}</div>

      {/* Actions Menu */}
      <div className='w-12 flex justify-end'>
        <GroupActionsMenu
          onAddMembers={() => onAddMembers(row.id)}
          onDelete={() => onDeleteGroup(row.id)}
          onEdit={() => onEditGroup(row.id)}
        />
      </div>
    </div>
  );
}

interface GroupActionsMenuProps {
  onAddMembers: () => void;
  onDelete: () => void;
  onEdit: () => void;
}

export const GroupActionsMenu = ({
  onAddMembers,
  onDelete,
  onEdit,
}: GroupActionsMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useOutsideClick(menuRef, () => {
    setShowDropdown(false);
  });

  return (
    <div className='relative' ref={menuRef}>
      <button
        type='button'
        className='p-2 hover:bg-[#232325] rounded-lg transition-colors'
        onClick={(e) => {
          e.stopPropagation();
          setShowDropdown(!showDropdown);
        }}
      >
        <OptionsIcon className='w-5 h-5 fill-current text-white' />
      </button>

      {showDropdown && (
        <div className='absolute right-0 top-12 bg-black text-white rounded-xl p-1 border border-secondary w-45 z-50'>
          <button
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              onEdit();
              setShowDropdown(false);
            }}
            className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms'
          >
            <EditIcon className='w-6 h-6 fill-current text-white pr-2' />
            Edit Group
          </button>
          <button
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              onAddMembers();
              setShowDropdown(false);
            }}
            className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms'
          >
            <TeamIcon className='w-6 h-6 fill-current text-white pr-2' />
            Add Members
          </button>
          <button
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              onDelete();
              setShowDropdown(false);
            }}
            className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start text-sms text-red-500'
          >
            <DeleteIcon className='w-6 h-6 fill-current pr-2' />
            Delete Group
          </button>
        </div>
      )}
    </div>
  );
};

type View = 'groups' | 'members';

export interface LearnersPageProps {
  learners?: Nullable<DtoGetLearnersResponse>;
  groups?: Nullable<DtoLearningGroupWithCounts[]>;
  view: View;
}

const viewOptions: Option<View>[] = [
  { label: 'Members', value: 'members' },
  { label: 'Groups', value: 'groups' },
];

export function LearnersPage({ learners, groups, view }: LearnersPageProps) {
  const [, setParams] = useSearchParams();

  const revalidator = useRevalidator();
  const navigate = useNavigate();

  const triggerAddMembers = useTriggerAddMembersToGroupModal();
  const triggerCreateGroup = useTriggerCreateGroupModal();

  const displayedRows = learners?.learnersDetails ?? [];
  const displayedGroups = groups ?? [];

  const handleAddMembers = async (emails: string[], groupId: string) => {
    try {
      await apiService.learning.addMembersToGroup(groupId, { emails });
      toast.success('Members added to group');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to add members', error);
      toast.error('Failed to add members');
    }
  };

  const handleCreateGroup = async ({
    name,
    emails,
  }: {
    name: string;
    emails: string[];
  }) => {
    try {
      await apiService.learning.createGroup({ name, emails });
      toast.success('Group created successfully');
      revalidator.revalidate();
    } catch (error) {
      logger.error('Failed to create group', error);
      toast.error('Failed to create group');
    }
  };

  const handleDeleteGroup = async (groupId: string) => {
    await apiService.learning.deleteGroup(groupId);
    revalidator.revalidate();
  };

  const handleEditGroup = async (groupId: string) => {
    navigate($path('/learning/admin/learners/groups/:id', { id: groupId }));
  };

  return (
    <div className='w-full h-full text-white px-8 py-10'>
      <div className='max-w-screen-2xl mx-auto'>
        <h1 className='text-3xl font-bold mb-2'>Learners</h1>
        <p className='text-sm text-gray-300 mb-6'>
          Learners are any members who have been assigned courses, in a group or
          as a solo learner
        </p>
        <div className='flex gap-2 mb-8 w-20'>
          <SegmentedControl
            options={viewOptions}
            value={viewOptions.find((o) => o.value === view)}
            onChange={(selectedOption) => {
              setParams({ tab: selectedOption.value });
            }}
            styles={{
              glider: 'bg-lp-gray-006 rounded-lg',
              track: 'rounded-xl p-1 border border-secondary',
              option: 'px-4 py-2 transition-colors text-sms',
              selectedOption: 'text-white',
              unselectedOption: 'text-gray-400 hover:text-white',
            }}
          />
        </div>

        {view === 'members' && (
          <>
            <h2 className='text-xl font-bold mb-4'>
              Members ({displayedRows.length})
            </h2>

            {/* Header row */}
            <div className='w-full px-4 py-2 flex items-center text-gray-400 text-xs uppercase font-medium'>
              <div className='flex-1'>Name</div>
              <div className='flex-1'>Groups</div>
              <div className='w-20 text-center'>Courses</div>
              <div className='w-20 text-center'>Progress</div>
              <div className='w-12' />
            </div>

            {/* Rows */}
            <div className='flex flex-col gap-2.5 mt-2'>
              {displayedRows.map((row) => (
                <LearnerRowItem key={row.userId} row={row} />
              ))}
            </div>
          </>
        )}

        {view === 'groups' && (
          <>
            <div className='flex items-center justify-between mb-4'>
              <h2 className='text-xl font-bold'>
                Groups ({displayedGroups.length})
              </h2>

              <button
                type='button'
                onClick={() => {
                  triggerCreateGroup({
                    onConfirm: handleCreateGroup,
                  });
                }}
                className='btn-secondary px-4 py-2'
              >
                Create Group
              </button>
            </div>

            {/* Header row */}
            <div className='w-full px-4 py-2 flex items-center text-gray-400 text-xs uppercase font-medium'>
              <div className='flex-1'>Name</div>
              <div className='w-24 text-center'>Members</div>
              <div className='w-32 text-center'>Created On</div>
              <div className='w-24 text-center'>Courses</div>
              <div className='w-24 text-center'>Progress</div>
              <div className='w-12' />
            </div>

            <div className='flex flex-col mt-4 text-gray-300 gap-2.5'>
              {displayedGroups.map((g) => (
                <GroupRowItem
                  key={g.id}
                  row={g}
                  onAddMembers={() => {
                    triggerAddMembers({
                      group: {
                        id: g.id,
                        name: g.name,
                      },
                      onConfirm: async (emails) => {
                        await handleAddMembers(emails, g.id);
                      },
                    });
                  }}
                  onDeleteGroup={handleDeleteGroup}
                  onEditGroup={handleEditGroup}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
