import { forwardRef, type InputHTMLAttributes } from 'react';

interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className = '', checked, onCheckedChange, ...props }, ref) => {
    return (
      <div className='relative inline-flex items-center'>
        <input
          type='checkbox'
          ref={ref}
          checked={checked}
          onChange={(e) => onCheckedChange?.(e.target.checked)}
          className='sr-only peer'
          {...props}
        />
        <div
          className={`
            w-5 h-5 border-2 border-gray-600 
            rounded bg-[#232325] 
            peer-focus:ring-2 peer-focus:ring-white/20 
            peer-checked:bg-cyan-500 peer-checked:border-cyan-500
            flex items-center justify-center
            transition-colors cursor-pointer
            ${className}
          `}
          onClick={() => onCheckedChange?.(!checked)}
        >
          {checked && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-3.5 w-3.5 text-white'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                clipRule='evenodd'
              />
            </svg>
          )}
        </div>
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';
