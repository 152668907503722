import sample from 'lodash/sample';
import React, { type ReactNode, useContext, useEffect, useMemo } from 'react';
import { proxy } from 'valtio';

import {
  EnumsJeopardyHostScriptKey,
  EnumsTTSRenderPolicy,
  type ModelsTTSLabeledRenderSettings,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import {
  type JeopardyBlock,
  type JeopardyBlockDetailScore,
} from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';
import { ConnectionStatus } from '@lp-lib/shared-schema';

import { getFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { useIsController } from '../../../../hooks/useMyInstance';
import { isReconnecting } from '../../../../store/utils';
import { type Participant } from '../../../../types';
import { sleep } from '../../../../utils/common';
import { TagQuery } from '../../../../utils/TagQuery';
import {
  markSnapshottable,
  type Snapshot,
  ValtioUtils,
} from '../../../../utils/valtio';
import { type FirebaseService, useFirebaseContext } from '../../../Firebase';
import { increment } from '../../../Firebase/utils';
import {
  useCohostClientIdGetter,
  useHostClientIdGetter,
  useLastJoinedParticipantGetter,
  useParticipantsAsArrayGetter,
} from '../../../Player';
import { StageMode, useStageControlAPI } from '../../../Stage';
import { useTeamGetter } from '../../../TeamAPI/TeamV1';
import { useVenueId } from '../../../Venue';
import {
  LVOBroadcastPlayer,
  lvoCacheWarm,
} from '../../../VoiceOver/LocalizedVoiceOvers';
import { lvoResolveTTSRequest } from '../../../VoiceOver/LocalLocalizedVoiceOvers';
import { type VariableRegistry } from '../../../VoiceOver/VariableRegistry';
import { useGameSessionBlockId } from '../../hooks';
import { useNewVariableRegistryWithCommonVariables } from '../../hooks/useNewVariableRegistry';
import { updateBlockDetailScore } from '../../store';
import {
  BuzzerControlAPI,
  BuzzerSharedAPI,
} from '../Common/GamePlay/Buzzer/BuzzerAPI';
import { PollControlAPI, PollSharedAPI } from '../Common/GamePlay/Poll/PollAPI';
import { type Board, type Clue, type Game, type Turn } from './types';
import { JeopardyUtils, log } from './utils';

export type SharedState = {
  game: Nullable<Game>;
  board: Nullable<Board>;
};

class JeopardyGameSharedAPI {
  private _state;

  constructor(
    venueId: string,
    blockId: string,
    svc: FirebaseService,
    private getLatestParticipantByUid: ReturnType<
      typeof useLastJoinedParticipantGetter
    >,
    private gameHandle = JeopardyUtils.GameHandle(svc, venueId, blockId),
    private boardHandle = JeopardyUtils.BoardHandle(svc, venueId, blockId),
    private buzzerAPI = new BuzzerSharedAPI(
      venueId,
      blockId,
      svc,
      log.scoped('buzzer')
    ),
    private pollAPI = new PollSharedAPI(
      venueId,
      blockId,
      svc,
      log.scoped('poll')
    )
  ) {
    this._state = markSnapshottable(proxy<SharedState>(this.initialState()));
  }

  get state() {
    return this._state;
  }

  get buzzer() {
    return this.buzzerAPI;
  }

  get poll() {
    return this.pollAPI;
  }

  on(): void {
    this.gameHandle.on((val) => ValtioUtils.set(this._state, 'game', val));
    this.boardHandle.on((val) => ValtioUtils.set(this._state, 'board', val));
    this.buzzerAPI.on();
    this.pollAPI.on();
  }

  off(): void {
    this.gameHandle.off();
    this.boardHandle.off();
    this.buzzerAPI.off();
    this.pollAPI.off();
  }

  getAvailableClues(snap: Snapshot<SharedState> = this._state): Clue[] {
    const playedClueIds = Object.values(snap.game?.turns ?? {})
      .filter((t): t is Turn => Boolean(t?.completed))
      .map((t) => t.clueId);
    return Object.values(snap.board?.items ?? {}).filter(
      (item) => item?.type === 'clue' && !playedClueIds.includes(item.id)
    ) as Clue[];
  }

  getContestantOnStage(
    snap: Snapshot<SharedState> = this._state
  ): Participant | null {
    const game = snap.game;
    if (!game || !game.state) return null;
    switch (game.state) {
      case 'INIT_CLUE_SELECTOR':
      case 'INSTRUCT_CLUE_SELECTOR':
      case 'WAIT_FOR_CLUE_SELECTION':
      case 'START_TURN':
        return game.clueSelectorUid
          ? this.getLatestParticipantByUid(game.clueSelectorUid)
          : null;
      case 'PREPARE_CONTESTANT_TO_ANSWER':
      case 'WAIT_FOR_ANSWER':
      case 'WAIT_FOR_JUDGEMENTS':
      case 'REVEAL_VERDICT':
      case 'REVEAL_ANSWER': {
        const currentTurn = this.getCurrentTurn(snap);
        if (
          !currentTurn ||
          !currentTurn.buzzQueue ||
          currentTurn.contestantIndex === null ||
          currentTurn.contestantIndex === undefined
        )
          return null;
        const contestantUid =
          currentTurn.buzzQueue[currentTurn.contestantIndex].uid;
        return this.getLatestParticipantByUid(contestantUid);
      }
      default:
        return null;
    }
  }

  // returns the role for the given uid for the current turn
  getTurnRole(
    snap: Snapshot<SharedState> = this._state,
    uid: string | null | undefined
  ) {
    if (!uid) return undefined;

    const game = snap.game;
    const currentTurn = this.getCurrentTurn(snap);
    if (!game || !game.state || !currentTurn) return undefined;

    const buzzerSubmissions = currentTurn.buzzQueue ?? [];
    const contestantUid =
      buzzerSubmissions[currentTurn.contestantIndex ?? -1]?.uid;

    if (contestantUid && contestantUid === uid) return 'contestant';

    const buzzersInQueue = buzzerSubmissions
      .slice((currentTurn.contestantIndex ?? 0) + 1)
      .map((b) => b.uid);

    return buzzersInQueue.includes(uid) ? 'buzzer' : 'judge';
  }

  getCurrentTurn(snap: Snapshot<SharedState> = this._state) {
    const game = snap.game;
    if (!game || !game.state) return null;
    const selectedClueId = game.selectedClueId;
    if (!selectedClueId) return null;
    return game.turns?.[selectedClueId] ?? null;
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
  }

  private initialState(): SharedState {
    return {
      game: null,
      board: null,
    };
  }
}

type GameControlState = {
  currentState: Game['state'];
  currentClueSelector: Game['clueSelectorUid'];
  currentClueId: Game['selectedClueId'];
  currentContestantIndex: Turn['contestantIndex'];
};

export function makeJeopardyGameControlAPIInitialState(): GameControlState {
  return {
    currentState: null,
    currentClueSelector: null,
    currentClueId: null,
    currentContestantIndex: null,
  };
}

class JeopardyGameControlAPI {
  private _state;
  private _board: Board | null = null;
  private _timers: Game['timers'] | null = null;
  private _gamePackVoiceId: string | null = null;
  private _ttsSettings: ModelsTTSLabeledRenderSettings | null = null;
  private _ttsScripts: ModelsTTSScript[] | null = null;
  private _encounteredHostScenarios = new Set<EnumsJeopardyHostScriptKey>();
  private _currentContestantUid: string | null = null;

  constructor(
    venueId: string,
    private blockId: string,
    svc: FirebaseService,
    private stageControlAPI: ReturnType<typeof useStageControlAPI>,
    private getLatestParticipantByUid: ReturnType<
      typeof useLastJoinedParticipantGetter
    >,
    private getParticipants: ReturnType<typeof useParticipantsAsArrayGetter>,
    private getTeam: ReturnType<typeof useTeamGetter>,
    private getHostClientId: ReturnType<typeof useHostClientIdGetter>,
    private getCohostClientId: ReturnType<typeof useCohostClientIdGetter>,
    private log: Logger,
    private variableRegistry: VariableRegistry,
    private rootHandle = JeopardyUtils.RootHandle(svc, venueId, blockId),
    private gameHandle = JeopardyUtils.GameHandle(svc, venueId, blockId),
    private buzzerAPI = new BuzzerControlAPI(
      venueId,
      blockId,
      svc,
      log.scoped('buzzer')
    ),
    private pollAPI = new PollControlAPI(
      venueId,
      blockId,
      svc,
      log.scoped('poll')
    )
  ) {
    this._state = markSnapshottable(
      proxy<GameControlState>(makeJeopardyGameControlAPIInitialState())
    );
    this.variableRegistry.set('contestantName', async () => {
      if (!this._currentContestantUid) return '';
      const participant = this.getLatestParticipantByUid(
        this._currentContestantUid
      );
      if (!participant) return '';
      return participant.firstName ?? participant.username ?? '';
    });
    this.variableRegistry.set('contestantTeamName', async () => {
      if (!this._currentContestantUid) return '';
      const participant = this.getLatestParticipantByUid(
        this._currentContestantUid
      );
      if (!participant) return '';
      return this.getTeam(participant.teamId)?.name ?? '';
    });
  }

  get state() {
    return this._state;
  }

  async playVOQuestion() {
    const itemId = this.state.currentClueId;
    if (!this._board || !itemId) return;

    const item = this._board.items[itemId];
    if (!item || item.type !== 'clue') return;

    try {
      const req = await lvoResolveTTSRequest(
        {
          script: item.clue,
          voiceId: this._gamePackVoiceId,
          ttsRenderSettings: this._ttsSettings,
          policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        },
        this.variableRegistry
      );

      if (!req) {
        // no question media...fallback to waiting.
        return sleep(5000);
      }

      const player = new LVOBroadcastPlayer(req);
      const info = await player.play();
      await info.tracksEnded;
    } catch (e) {
      this.log.error('failed to play question voice over', e);
    }
  }

  async playVOCategory(presentCategory: number | null | undefined) {
    if (!this._board || presentCategory === undefined) return;

    const item = Object.values(this._board.items).find(
      (it) => it?.type === 'category' && it.col === presentCategory
    );
    if (item?.type !== 'category') return;

    try {
      const req = await lvoResolveTTSRequest(
        {
          script: item.categoryScript,
          voiceId: this._gamePackVoiceId,
          ttsRenderSettings: this._ttsSettings,
          policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        },
        this.variableRegistry
      );

      if (!req) {
        // no category media...fallback to waiting.
        return sleep(3000);
      }

      const player = new LVOBroadcastPlayer(req);
      const info = await player.play();
      await info.tracksEnded;
    } catch (e) {
      this.log.error('failed to play category voice over', e);
    }
  }

  async initGame(
    block: JeopardyBlock,
    gamePackVoiceId: string | null | undefined
  ) {
    this.log.info('initialize game');
    const board = JeopardyUtils.AdaptBoardTypes(
      block.fields.board,
      block.fields.boardSize
    );
    const numClues = Object.values(board.items).filter(
      (i) => i?.type === 'clue'
    ).length;
    if (numClues === 0) {
      throw new Error('block must specify at least 1 clue');
    }

    const timers: Game['timers'] = {
      clueSelectionTimeSec: block.fields.clueSelectionTimeSec ?? 120,
      buzzerTimeSec: block.fields.buzzerTimeSec ?? 5,
      answerTimeSec: block.fields.answerTimeSec ?? 6,
      answerPrepareTimeSec: block.fields.answerPrepareTimeSec ?? 3,
      judgingTimeSec: block.fields.judgingTimeSec ?? 10,
    };

    await this.rootHandle.set({
      game: {
        buzzerId: this.blockId,
        clueSelectorUid: null as never,
        selectedClueId: null as never,
        answerSubmitted: null as never,
        state: 'INIT_BOARD',
        turns: null as never,
        timers,
        presentCategory: null as never,
        ttsSettings: (block.fields.ttsOptions?.[0] ?? null) as never,
      },
      board: board as never,
    });
    await this.stageControlAPI.updateStageMode(StageMode.BLOCK_CONTROLLED);
    this._state.currentState = 'INIT_BOARD';
    this._board = board;
    this._timers = timers;
    this._gamePackVoiceId = gamePackVoiceId ?? null;
    this._ttsSettings = block.fields.ttsOptions?.[0] ?? null;
    // TODO(falcon): should this be pushed to fb?
    this._ttsScripts = block.fields.ttsScripts ?? null;

    await this.prepareVoiceOvers(board);
  }

  private async prepareVoiceOvers(
    board: Board
    // mediaLookup: JeopardyMediaLookup | null | undefined
  ) {
    const categories = [];
    const clues = [];
    for (const item of Object.values(board.items)) {
      if (!item) continue;

      switch (item.type) {
        case 'category': {
          const req = {
            script: item.categoryScript,
            ttsRenderSettings: this._ttsSettings,
            voiceId: this._gamePackVoiceId,
            policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
          };

          categories.push([item.id, req] as const);
          break;
        }

        case 'clue': {
          const req = {
            script: item.clue,
            voiceId: this._gamePackVoiceId,
            ttsRenderSettings: this._ttsSettings,
            policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
          };

          clues.push([item.id, req] as const);
          break;
        }
      }
    }

    const prepares = [];

    // enqueue categories first
    for (const [, req] of categories) {
      const resolved = await lvoResolveTTSRequest(req, this.variableRegistry);
      prepares.push(lvoCacheWarm(resolved));
    }

    // prepare the clues, but do not enqueue them to force the system to wait.
    // They will likely finish by the time the categories have been read out.
    for (const [, req] of clues) {
      const resolved = await lvoResolveTTSRequest(req, this.variableRegistry);
      lvoCacheWarm(resolved);
    }

    // Force the game to wait until all preparation is complete.
    await Promise.all(prepares);
  }

  async boardReady() {
    this.log.info('board ready');

    const noCategoryIntroduction = getFeatureQueryParam(
      'jeopardy-no-category-introduction'
    );
    const nextState = noCategoryIntroduction
      ? 'INIT_CLUE_SELECTOR'
      : 'PRESENT_CATEGORIES';

    if (nextState === 'PRESENT_CATEGORIES') {
      await this.playHostVoiceOver(
        EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyCategoriesIntro,
        {
          orElseSleepMs: 2000,
        }
      );
    }

    await this.gameHandle.update({
      state: nextState,
    });
    this._state.currentState = nextState;
  }

  // NOTE: these are very dynamic so there is no cache warming: they load and
  // play as requested ASAP
  private async playHostVoiceOver(
    key: EnumsJeopardyHostScriptKey,
    opts?: Partial<{
      orElseSleepMs: number;
      gameOver: boolean;
    }>
  ) {
    if (!this._ttsScripts) return undefined;
    const query = new TagQuery(this._ttsScripts);

    const isFirstEncounter = !this._encounteredHostScenarios.has(key);
    const scenario: Record<string, boolean> = {
      [key]: true,
      [EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyFirstTime]:
        isFirstEncounter,
      [EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyGameOver]: Boolean(
        opts?.gameOver
      ),
    };

    let hostScripts = query.selectWith(scenario);
    if (isFirstEncounter) {
      this._encounteredHostScenarios.add(key);
      if (hostScripts.length === 0) {
        delete scenario[
          EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyFirstTime
        ];
        hostScripts = query.selectWith(scenario);
      }
    }

    const hostScript =
      hostScripts[Math.floor(Math.random() * hostScripts.length)];
    if (!hostScript) return undefined;

    try {
      const req = await lvoResolveTTSRequest(
        {
          script: hostScript.script,
          voiceId: hostScript.voiceId ?? this._gamePackVoiceId,
          ttsRenderSettings: hostScript.voiceId ? undefined : this._ttsSettings,
        },
        this.variableRegistry
      );

      if (req) {
        const player = new LVOBroadcastPlayer(req);
        const info = await player.play();
        await info.tracksEnded;
      } else if (opts?.orElseSleepMs) {
        await sleep(opts.orElseSleepMs);
      }
    } catch (e) {
      this.log.error('failed to play host voice over', e);
    }
  }

  async presentNextCategory() {
    this.log.info('present next category');

    const { committed, snapshot } = await this.rootHandle.ref.transaction(
      (snap) => {
        if (!snap) return snap;
        if (!snap.board || !snap.game) {
          throw new Error('not enough game state');
        }
        if (snap.game.state !== 'PRESENT_CATEGORIES') {
          throw new Error('not presenting categories');
        }
        const currentCategoryIndex = snap.game.presentCategory ?? -1;
        const nextCategoryIndex = currentCategoryIndex + 1;
        if (nextCategoryIndex >= snap.board.numCols) {
          snap.game.state = 'INIT_CLUE_SELECTOR';
          snap.game.presentCategory = null as never;
          return snap;
        } else {
          snap.game.presentCategory = nextCategoryIndex;
          return snap;
        }
      },
      undefined,
      false
    );

    if (!committed) {
      this.log.warn('failed to present next category');
      return false;
    }

    const nextState = snapshot?.val()?.game?.state;
    if (!nextState) {
      this.log.warn('no next state');
      return false;
    }

    if (nextState === 'INIT_CLUE_SELECTOR') {
      this._state.currentState = nextState;
      // nothing to do, done with categories.
      return false;
    }

    await this.playVOCategory(snapshot.val()?.game?.presentCategory);

    return nextState === 'PRESENT_CATEGORIES';
  }

  async setClueSelector(uid: string | null | undefined) {
    this.log.info('set clue selector', { clueSelectorUid: uid });
    if (!uid) return;

    const participant = this.getLatestParticipantByUid(uid);
    if (this.isParticipantUnavailable(participant)) {
      this.log.warn('participant is unavailable', { uid });
      const nextClueSelector = this.findNextAvailableClueSelector(participant);
      if (nextClueSelector) {
        this.log.info('found next clue selector', { uid: nextClueSelector.id });
        uid = nextClueSelector.id;
      }
    }

    await this.gameHandle.update({
      state: 'INSTRUCT_CLUE_SELECTOR',
      clueSelectorUid: uid,
      selectedClueId: null as never,
      answerSubmitted: null as never,
    });
    this._state.currentState = 'INSTRUCT_CLUE_SELECTOR';
    this._state.currentClueSelector = uid;
    this._state.currentClueId = null;
  }

  private isParticipantUnavailable(
    participant: Participant | null | undefined
  ) {
    return (
      !participant ||
      (participant.status === ConnectionStatus.Disconnected &&
        !isReconnecting(participant)) ||
      !!participant.away
    );
  }

  private findNextAvailableClueSelector(
    participant: Participant | null | undefined
  ) {
    const participants = this.getParticipants({
      filters: [
        'host:false',
        'cohost:false',
        'status:connected',
        'team:true',
        'staff:false',
        'away:false',
      ],
    });

    const teamMates: Participant[] = [];
    const candidates: Participant[] = [];
    for (const p of participants) {
      if (
        !p ||
        p.id === participant?.id ||
        p.clientId === participant?.clientId ||
        this.isParticipantUnavailable(p)
      )
        continue;
      if (p.teamId === participant?.teamId) {
        teamMates.push(p);
      } else {
        candidates.push(p);
      }
    }

    return sample(teamMates) ?? sample(candidates) ?? null;
  }

  async instructClueSelector() {
    const uid = this._state.currentClueSelector;
    if (!uid) return;

    this.log.info('present clue selector', { clueSelectorUid: uid });
    await this.putOnStage(uid);
    await this.playHostVoiceOver(
      EnumsJeopardyHostScriptKey.JeopardyHostScriptKeySelectClue
    );
    await this.gameHandle.update({
      state: 'WAIT_FOR_CLUE_SELECTION',
    });
    this._state.currentState = 'WAIT_FOR_CLUE_SELECTION';
  }

  async initTurn(clueId: string) {
    this.log.info('init turn', { clueId });

    // TODO(falcon): we should assert this clue hasn't been played yet...
    await this.gameHandle.ref.update({
      state: 'START_TURN',
      selectedClueId: clueId,
      answerSubmitted: null as never,
      [`turns/${clueId}`]: {
        clueId,
        selectedByUid: (this._state.currentClueSelector ?? null) as never,
        buzzQueue: null as never,
        contestantIndex: null as never,
        answerAttempts: null as never,
      },
    });
    this._state.currentState = 'START_TURN';
    this._state.currentClueId = clueId;

    await this.buzzerAPI.reset();
    await this.pollAPI.reset();
  }

  async pickRandomClue() {
    const root = await this.rootHandle.get();
    if (!root || !root.board || !root.game) {
      throw new Error('not enough game state');
    }

    // what's been played?
    const playedClueIds = Object.keys(root.game.turns ?? {});
    const availableClues = Object.values(root.board.items).filter(
      (item) => item?.type === 'clue' && !playedClueIds.includes(item.id)
    );

    const randomClue =
      availableClues[Math.floor(Math.random() * availableClues.length)];

    // we shouldn't hit this case in practice since the game will move to GAME_OVER when a turn ends.
    if (!randomClue) {
      throw new Error('no available clues');
    }
    await this.initTurn(randomClue.id);
  }

  async enableTurnBuzzer() {
    this.log.info('enable turn buzzer');
    if (!this._state.currentClueId) {
      throw new Error('no current clue');
    }

    // clear any dangling buzzers
    await this.buzzerAPI.reset();
    await this.gameHandle.ref.update({
      state: 'WAIT_FOR_BUZZERS',
    });
    this._state.currentState = 'WAIT_FOR_BUZZERS';
    await this.clearStage();
  }

  private async snapshotBuzzer() {
    this.log.info('snapshot buzzer');

    try {
      return await this.buzzerAPI.getSubmissions();
    } finally {
      await this.buzzerAPI.reset();
    }
  }

  async startTurn() {
    const clueId = this._state.currentClueId;
    this.log.info('start turn', { clueId });
    if (!clueId) {
      throw new Error('no current clue');
    }

    const buzzQueue = await this.snapshotBuzzer();
    if (buzzQueue.length === 0) {
      // no buzzers, so let's move along.
      await this.gameHandle.ref.update({
        state: 'REVEAL_ANSWER',
      });
      this._state.currentState = 'REVEAL_ANSWER';
      return;
    }

    const contestantUid = buzzQueue[0].uid;

    await this.gameHandle.ref.update({
      state: 'PREPARE_CONTESTANT_TO_ANSWER',
      answerSubmitted: null as never,
      [`turns/${clueId}/buzzQueue`]: buzzQueue,
      [`turns/${clueId}/contestantIndex`]: 0,
      [`turns/${clueId}/answerAttempts`]: {
        [contestantUid]: {
          contestantUid,
          judgements: null as never,
          verdict: null as never,
        },
      },
    });
    this._state.currentState = 'PREPARE_CONTESTANT_TO_ANSWER';
    this._state.currentContestantIndex = 0;
    await this.putOnStage(contestantUid);
  }

  async prepareContestantToAnswer() {
    this.log.info('prepare contestant to answer');
    // we just need to buy some time to allow the user to get on stage, and defer the timer.
    // there's nothing to do here but wait and then transition.
    await sleep((this._timers?.answerPrepareTimeSec ?? 3) * 1000);
    await this.gameHandle.ref.update({
      state: 'WAIT_FOR_ANSWER',
    });
    this._state.currentState = 'WAIT_FOR_ANSWER';
  }

  async enableJudgements() {
    this.log.info('enable judgements');
    const clueId = this._state.currentClueId;
    if (!clueId) {
      throw new Error('no current clue');
    }
    await this.pollAPI.init<{ label: string }>({
      correct: { id: 'correct', extra: { label: 'Correct' } },
      incorrect: { id: 'incorrect', extra: { label: 'Incorrect' } },
    });
    await this.gameHandle.ref.update({
      state: 'WAIT_FOR_JUDGEMENTS',
    });
    this._state.currentState = 'WAIT_FOR_JUDGEMENTS';
  }

  async evaluateAnswerAttempt() {
    const clueId = this._state.currentClueId;
    const contestantIndex = this._state.currentContestantIndex;
    this.log.info('evaluate answer attempt', { clueId, contestantIndex });
    if (!clueId) {
      throw new Error('no current clue');
    }
    if (contestantIndex === null || contestantIndex === undefined) {
      throw new Error('no current contestant');
    }
    const clue = this._board?.items[clueId];
    if (!clue || clue.type !== 'clue') {
      throw new Error('no clue');
    }

    const turnsSnap = await this.gameHandle.ref.child('turns').get();
    const turns = turnsSnap.val();
    if (!turns) {
      throw new Error('no turns');
    }

    const turn = turns[clueId];
    if (!turn) {
      throw new Error('no turn');
    }

    const playedClueIds = Object.keys(turns);
    const availableClues = Object.values(this._board?.items ?? {}).filter(
      (item) => item?.type === 'clue' && !playedClueIds.includes(item.id)
    );
    const remainingCluesCount = availableClues.length;

    const contestant = turn.buzzQueue?.[contestantIndex];
    const contestantUid = contestant?.uid;
    if (!contestantUid) {
      throw new Error('no current contestant');
    }

    const attempt = turn.answerAttempts?.[contestantUid];
    if (!attempt) {
      throw new Error('no current attempt');
    }

    const voteMap = await this.pollAPI.getVoteMap();

    let numCorrect = 0;
    let numIncorrect = 0;
    let numJudgements = 0;
    for (const vote of Object.values(voteMap ?? {})) {
      const response = vote?.optionId;
      if (!response) continue;
      if (response === 'correct') {
        numCorrect++;
      } else if (response === 'incorrect') {
        numIncorrect++;
      }
      numJudgements++;
    }

    const verdict =
      numJudgements === 0
        ? 'incorrect'
        : numCorrect >= numIncorrect
        ? 'correct'
        : 'incorrect';

    if (verdict === 'correct') {
      await this.gameHandle.ref.update({
        state: 'REVEAL_VERDICT',
      });
      await this.playHostVoiceOver(
        EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyCorrectResponse,
        {
          gameOver: remainingCluesCount === 0,
        }
      );
      await this.awardPoints(contestant.teamId, clue.value);
      await this.gameHandle.ref.update({
        clueSelectorUid: contestantUid, // this user gets selection control.
        state: 'REVEAL_ANSWER',
        [`turns/${clueId}/answerAttempts/${contestantUid}/judgements`]: voteMap,
        [`turns/${clueId}/answerAttempts/${contestantUid}/verdict`]: verdict,
      });
      this._state.currentState = 'REVEAL_ANSWER';
      this._state.currentClueSelector = contestantUid;
      return true;
    } else {
      // try to go to the next contestant.
      const nextContestantIndex = contestantIndex + 1;
      const nextContestant = turn.buzzQueue?.[nextContestantIndex]?.uid;
      if (!nextContestant) {
        await this.gameHandle.ref.update({
          state: 'REVEAL_VERDICT',
        });
        await this.playHostVoiceOver(
          EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyIncorrectNoBuzzQueue,
          {
            gameOver: remainingCluesCount === 0,
          }
        );
        // subtract points from the contestant's team.
        await this.awardPoints(contestant.teamId, -clue.value);
        // no more contestants.
        await this.gameHandle.ref.update({
          state: 'REVEAL_ANSWER',
          [`turns/${clueId}/answerAttempts/${contestantUid}/judgements`]:
            voteMap,
          [`turns/${clueId}/answerAttempts/${contestantUid}/verdict`]: verdict,
        });
        this._state.currentState = 'REVEAL_ANSWER';
        return false;
      } else {
        await this.gameHandle.ref.update({
          state: 'REVEAL_VERDICT',
        });
        await this.playHostVoiceOver(
          EnumsJeopardyHostScriptKey.JeopardyHostScriptKeyIncorrectNextBuzzer
        );
        // subtract points from the contestant's team.
        await this.awardPoints(contestant.teamId, -clue.value);
        // move to the next buzzer.
        await this.gameHandle.ref.update({
          state: 'PREPARE_CONTESTANT_TO_ANSWER',
          answerSubmitted: null as never,
          [`turns/${clueId}/contestantIndex`]: nextContestantIndex,
          [`turns/${clueId}/answerAttempts/${contestantUid}/judgements`]:
            voteMap,
          [`turns/${clueId}/answerAttempts/${contestantUid}/verdict`]: verdict,
          [`turns/${clueId}/answerAttempts/${nextContestant}`]: {
            contestantUid: nextContestant,
            judgements: null as never,
            verdict: null as never,
          },
        });
        this._state.currentState = 'PREPARE_CONTESTANT_TO_ANSWER';
        this._state.currentContestantIndex = nextContestantIndex;
        await this.putOnStage(nextContestant);
        return false;
      }
    }
  }

  async endTurn() {
    this.log.info('end turn');
    const clueId = this._state.currentClueId;
    if (!clueId) {
      throw new Error('no current clue');
    }

    const { committed, snapshot } = await this.rootHandle.ref.transaction(
      (snap) => {
        if (!snap) return snap;
        if (!snap.game || !snap.game?.turns || !snap.board) {
          throw new Error('no game info');
        }

        const selectedClueId = snap.game?.selectedClueId;
        if (!selectedClueId) {
          throw new Error('no selected clue');
        }
        const turn = snap.game.turns[selectedClueId];
        if (!turn) {
          throw new Error('no turn');
        }

        // what's been played?
        const playedClueIds = Object.keys(snap.game.turns ?? {});
        const availableClues = Object.values(snap.board.items).filter(
          (item) => item?.type === 'clue' && !playedClueIds.includes(item.id)
        );

        const isGameOver = availableClues.length === 0;
        turn.completed = true;
        if (isGameOver) {
          snap.game.state = 'GAME_OVER';
        }
        return snap;
      },
      undefined,
      false
    );

    if (!committed) {
      log.warn('failed to end turn');
      return;
    }

    const result = snapshot?.val();
    if (result?.game?.state === 'GAME_OVER') {
      this._state.currentState = 'GAME_OVER';
      await this.clearStage();
    } else {
      await this.setClueSelector(this._state.currentClueSelector);
    }
  }

  async resetGame(resetStage = true) {
    this.log.info('reset game');
    ValtioUtils.reset(this._state, makeJeopardyGameControlAPIInitialState());
    this._board = null;
    this._timers = null;
    this._gamePackVoiceId = null;
    this._ttsSettings = null;
    this._ttsScripts = null;
    this._encounteredHostScenarios.clear();
    this._currentContestantUid = null;
    await this.rootHandle.remove();
    await this.buzzerAPI.reset();
    await this.pollAPI.reset();
    if (!resetStage) return;
    // There might be the race condition that the next block such as spotlight
    // has brought someone on stage. Since the are both async APIs, the newly
    // added people will not be removed from the stage. The _resetGame_ will be
    // called when _GAME_END_, so we can skip resetting stage during unmount.
    // A better approach is that this should be queued.
    await this.stageControlAPI.updateStageMode(StageMode.BOS);
    await this.clearStage();
  }

  private awardPoints(teamId: string, points: number) {
    return updateBlockDetailScore<JeopardyBlockDetailScore>(teamId, {
      score: increment(points),
      submittedAt: Date.now(),
    });
  }

  private async putOnStage(uid: string) {
    this._currentContestantUid = uid;

    // we need the client id.
    const participant = this.getLatestParticipantByUid(uid);
    if (!participant) {
      this.log.error('no participant', { uid });
      return;
    }
    const clientId = participant.clientId;
    // keep them on stage if they already are.
    await this.stageControlAPI.leaveAll([
      clientId,
      this.getHostClientId(),
      this.getCohostClientId(),
    ]);
    await this.stageControlAPI.join(clientId, StageMode.BLOCK_CONTROLLED, {
      disableInvitedNotice: true,
    });
  }

  private async clearStage() {
    this._currentContestantUid = null;
    await this.stageControlAPI.leaveAll();
  }
}

export class JeopardyGamePlayAPI {
  constructor(
    venueId: string,
    blockId: string,
    svc: FirebaseService,
    private log: Logger,
    private gameHandle = JeopardyUtils.GameHandle(svc, venueId, blockId)
  ) {}

  async selectClue(clueId: string, uid: string) {
    this.log.info('select clue', { clueId, clueSelectorUid: uid });
    const { committed } = await this.gameHandle.ref.transaction((snap) => {
      if (!snap) return snap;
      if (snap.clueSelectorUid !== uid) {
        throw new Error('not the clue selector');
      }
      return {
        ...snap,
        selectedClueId: clueId,
      };
    });
    if (!committed) {
      this.log.warn('failed to select clue', { clueId, clueSelectorUid: uid });
    }
  }

  async finishedAnswering() {
    this.log.info('finished answering');
    await this.gameHandle.ref.update({
      answerSubmitted: true,
    });
  }
}

type Context = {
  gameSharedAPI: JeopardyGameSharedAPI;
  gameControlAPI?: JeopardyGameControlAPI;
  gamePlayAPI: JeopardyGamePlayAPI;
};

const context = React.createContext<Context | null>(null);

function useJeopardyContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('JeopardyContext is not in the tree!');
  return ctx;
}

export function useJeopardyGamePlayAPI(): Context['gamePlayAPI'] {
  return useJeopardyContext().gamePlayAPI;
}

export function useJeopardyGameControlAPI(): Context['gameControlAPI'] {
  return useJeopardyContext().gameControlAPI;
}

export function useJeopardyGameSharedAPI(): Context['gameSharedAPI'] {
  return useJeopardyContext().gameSharedAPI;
}

export function useSubscribeSharedState() {
  const api = useJeopardyGameSharedAPI();
  useEffect(() => {
    api.on();
    return () => api.off();
  }, [api]);
}

export function JeopardyBlockProvider(props: {
  children?: ReactNode;
}): JSX.Element | null {
  const venueId = useVenueId();
  const blockId = useGameSessionBlockId() ?? '';
  const { svc } = useFirebaseContext();
  const stageControlAPI = useStageControlAPI();
  const getLatestParticipant = useLastJoinedParticipantGetter();
  const getParticipants = useParticipantsAsArrayGetter();
  const getTeam = useTeamGetter();
  const isController = useIsController();
  const commonRegistry = useNewVariableRegistryWithCommonVariables();
  const getHostClientId = useHostClientIdGetter();
  const getCohostClientId = useCohostClientIdGetter();

  const ctx = useMemo(() => {
    return {
      gameSharedAPI: new JeopardyGameSharedAPI(
        venueId,
        blockId,
        svc,
        getLatestParticipant
      ),
      gameControlAPI: isController
        ? new JeopardyGameControlAPI(
            venueId,
            blockId,
            svc,
            stageControlAPI,
            getLatestParticipant,
            getParticipants,
            getTeam,
            getHostClientId,
            getCohostClientId,
            log,
            commonRegistry
          )
        : undefined,
      gamePlayAPI: new JeopardyGamePlayAPI(venueId, blockId, svc, log),
    };
  }, [
    venueId,
    blockId,
    svc,
    getLatestParticipant,
    isController,
    stageControlAPI,
    getParticipants,
    getTeam,
    getHostClientId,
    getCohostClientId,
    commonRegistry,
  ]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}
