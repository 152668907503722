import { useNavigate, useSearchParams } from '@remix-run/react';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { $path } from 'remix-routes';
import useSWR from 'swr';

import { type ModelsStack } from '@lp-lib/api-service-client/public';

import { gamePackToEnrollmentTarget } from '../../app/components/GamePack/utils';
import { SegmentedControl } from '../components/common/SegmentedControl';
import { type Option } from '../components/common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { CreateCourseButton } from '../components/Training/Admin/Components/CreateCourseButton';
import { PaginationNav } from '../components/Training/Admin/Components/PaginatedNav';
import { type EnrollmentTarget } from '../components/Training/Admin/Modals/CourseAssignmentModal';
import { useTriggerCreateStackModal } from '../components/Training/Admin/Modals/CreateStackModal';
import { DeleteCourseConfirmationModal } from '../components/Training/Admin/Modals/DeleteCourseConfirmationModal';
import { useEnrollmentActions } from '../components/Training/Admin/useCourseActions';
import { LongCourseCardList } from '../components/Training/LongCourseCardList';
import { StackCardList } from '../components/Training/StackCardList';
import { TrainingGenAIStarter } from '../components/Training/TrainingStarter';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { getLogger } from '../logger/logger';
import { apiService } from '../services/api-service';
import { type GamePack } from '../types/game';
import { fromDTOGamePack } from '../utils/api-dto';

const logger = getLogger().scoped('My Courses');

export function useCreatedCoursesAndStacks() {
  const [coursesPage, setCoursesPage] = useState(1);
  const [stacksPage, setStacksPage] = useState(1);
  const [coursesPageSize, setCoursesPageSize] = useState(10);
  const [stacksPageSize, setStacksPageSize] = useState(10);

  const {
    data: coursesData,
    error: coursesError,
    isLoading: coursesLoading,
    mutate: refreshCourses,
  } = useSWR([`/courses/created`, coursesPage, coursesPageSize], () =>
    apiService.learning.getCreatedCourses(coursesPage, coursesPageSize)
  );

  const {
    data: stacksData,
    error: stacksError,
    isLoading: stacksLoading,
    mutate: refreshStacks,
  } = useSWR([`/stacks/created`, stacksPage, stacksPageSize], () =>
    apiService.learning.getCreatedStacks(stacksPage, stacksPageSize)
  );

  const handleCoursesPageChange = useCallback((newPage: number) => {
    setCoursesPage(newPage);
  }, []);

  const handleStacksPageChange = useCallback((newPage: number) => {
    setStacksPage(newPage);
  }, []);

  const handleCoursesPageSizeChange = useCallback((newPageSize: number) => {
    setCoursesPageSize(newPageSize);
    setCoursesPage(1);
  }, []);

  const handleStacksPageSizeChange = useCallback((newPageSize: number) => {
    setStacksPageSize(newPageSize);
    setStacksPage(1);
  }, []);

  return {
    courses: coursesData?.data.courses || [],
    stacks: stacksData?.data.stacks || [],
    coursesPagination: coursesData?.data.pagination,
    stacksPagination: stacksData?.data.pagination,
    coursesPageSize,
    stacksPageSize,
    isLoading: coursesLoading || stacksLoading,
    error: coursesError || stacksError,
    handleCoursesPageChange,
    handleStacksPageChange,
    handleCoursesPageSizeChange,
    handleStacksPageSizeChange,
    refreshCourses,
    refreshStacks,
  };
}

type TabOption = 'courses' | 'stacks';

export function Component() {
  const navigate = useNavigate();
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const tabOptions: Option<TabOption>[] = [
    { label: 'Courses', value: 'courses' },
    { label: 'Stacks', value: 'stacks' },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<Option<TabOption>>(() => {
    const tab = searchParams.get('tab');
    return tabOptions.find((o) => o.value === tab) || tabOptions[0];
  });
  const switchTab = useLiveCallback((tab: Option<TabOption>) => {
    setSearchParams({ tab: tab.value });
    setActiveTab(tab);
  });

  const {
    courses,
    stacks,
    coursesPagination,
    stacksPagination,
    isLoading,
    error,
    handleCoursesPageChange,
    handleStacksPageChange,
    refreshCourses,
    refreshStacks,
    handleCoursesPageSizeChange,
    handleStacksPageSizeChange,
    coursesPageSize,
    stacksPageSize,
  } = useCreatedCoursesAndStacks();

  const { handleAssign } = useEnrollmentActions();
  const triggerCreateStackModal = useTriggerCreateStackModal();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();

  const handleAssignCourse = useCallback(
    (pack: GamePack) => {
      const target = gamePackToEnrollmentTarget(pack);
      handleAssign(target, () => {
        refreshCourses();
      });
    },
    [handleAssign, refreshCourses]
  );

  const handleAssignStack = useCallback(
    (stack: ModelsStack) => {
      const target: EnrollmentTarget = {
        type: 'stack',
        name: stack.name,
        id: stack.id,
      };
      handleAssign(target, () => {
        refreshStacks();
      });
    },
    [handleAssign, refreshStacks]
  );

  const handleSaveStack = async () => {
    try {
      const modalResp = await triggerCreateStackModal({
        onConfirm: async (name, courseIds) => {
          await apiService.learning.createStack({
            name,
            courseIds,
          });
          refreshStacks();
        },
      });

      if (modalResp.result === 'confirmed') {
        toast.dark('Successfully created course stack', { autoClose: 3000 });
      }
    } catch (error) {
      logger.error('Failed to create stack:', error);
      toast.dark('Failed to create course stack', { autoClose: 5000 });
    }
  };

  const handleDeleteCourse = async (id: string) => {
    const response = await confirmCancel({
      kind: 'confirm-cancel',
      prompt: <DeleteCourseConfirmationModal />,
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
    });
    if (response.result !== 'confirmed') {
      return;
    }
    try {
      await apiService.learning.deleteCourse(id);
      refreshCourses();
    } catch (e) {
      logger.error('Error deleting course', e);
    }
  };

  const handleDeleteStack = async (id: string) => {
    await apiService.learning.deleteStack(id);
    refreshStacks();
  };

  const handleEditCourse = async (id: string) => {
    navigate($path('/trainings/:id/edit', { id }));
  };

  const handleCourseDetails = async (id: string) => {
    navigate($path('/learning/admin/my-courses/:id', { id }));
  };

  const handleStackDetails = async (id: string) => {
    navigate($path('/learning/admin/my-courses/stacks/:id', { id }));
  };

  const toggleCourseSelection = useCallback((id: string) => {
    setSelectedCourses((prev) => {
      if (prev.includes(id)) {
        return prev.filter((courseId) => courseId !== id);
      } else {
        return [...prev, id];
      }
    });
  }, []);

  return (
    <div className='w-full h-full py-10 text-white max-w-screen-2xl mx-auto flex flex-col'>
      {/* Header controls */}
      <div className='flex flex-row items-start justify-between mb-10 pr-4'>
        <div className='flex flex-col gap-2'>
          <div className='text-2xl font-bold'>My Content</div>
          <div className='text-sms text-icon-gray'>
            Manage your courses and course stacks
          </div>
        </div>
        <div className='flex flex-row gap-4 h-10 self-center'>
          <CreateCourseButton />
          <button
            type='button'
            onClick={handleSaveStack}
            className='flex items-center gap-2 bg-[#232325] text-white font-bold py-3 px-6 rounded-xl hover:bg-lp-gray-002 transition'
          >
            <span>Create Course Stack</span>
          </button>
        </div>
      </div>

      {/* Tab Control */}
      <div className='w-full flex justify-start mb-6'>
        <div className='w-54'>
          <SegmentedControl
            options={tabOptions}
            value={activeTab}
            onChange={switchTab}
            styles={{
              glider: 'bg-lp-gray-006 rounded-lg',
              track: 'rounded-xl p-1 border border-secondary',
              option: 'px-4 py-2 transition-colors text-sms',
              selectedOption: 'text-white',
              unselectedOption: 'text-gray-400 hover:text-white',
            }}
          />
        </div>
      </div>

      <div className='flex-1 min-h-[600px] relative'>
        {/* Courses Tab */}
        {activeTab.value === 'courses' && (
          <div className='flex flex-col h-full min-h-0'>
            <div className='flex-1 overflow-y-auto'>
              {courses.length === 0 && !isLoading ? (
                <TrainingGenAIStarter />
              ) : (
                <LongCourseCardList
                  packs={courses.map((c) => fromDTOGamePack(c.gamePack))}
                  isStackMode={false}
                  selectedCourses={selectedCourses}
                  selectionOrder={(id) => selectedCourses.indexOf(id)}
                  onSelect={toggleCourseSelection}
                  onAssign={handleAssignCourse}
                  onEdit={(id) => handleEditCourse(id)}
                  onDelete={(id) => handleDeleteCourse(id)}
                  onDetails={(id) => handleCourseDetails(id)}
                  learnersMap={courses.reduce((acc, c) => {
                    acc[c.gamePack.id] = c.learnerCount;
                    return acc;
                  }, {} as Record<string, number>)}
                />
              )}
            </div>

            <div className='flex-none py-4 sticky bottom-0 z-10'>
              {courses.length > 0 && coursesPagination && (
                <PaginationNav
                  currentPage={coursesPagination.currentPage}
                  totalPages={coursesPagination.totalPages}
                  pageSize={coursesPageSize}
                  onPageChange={handleCoursesPageChange}
                  onPageSizeChange={handleCoursesPageSizeChange}
                  totalItems={coursesPagination.totalItems}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
        )}

        {/* Stacks Tab */}
        {activeTab.value === 'stacks' && (
          <div className='flex flex-col h-full'>
            <div className='flex-1 overflow-y-auto pr-4 pb-4'>
              <StackCardList
                stacks={stacks}
                onAssign={handleAssignStack}
                onDelete={handleDeleteStack}
                onDetails={handleStackDetails}
                learnersMap={stacks.reduce((acc, c) => {
                  acc[c.stack.id] = c.learnerCount;
                  return acc;
                }, {} as Record<string, number>)}
              />
            </div>

            <div className='flex-none py-4 sticky bottom-0 z-10'>
              {stacks.length > 0 && stacksPagination && (
                <PaginationNav
                  currentPage={stacksPagination.currentPage}
                  totalPages={stacksPagination.totalPages}
                  pageSize={stacksPageSize}
                  onPageChange={handleStacksPageChange}
                  onPageSizeChange={handleStacksPageSizeChange}
                  totalItems={stacksPagination.totalItems}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
        )}

        {/* Loading state */}
        {isLoading && (
          <div className='absolute inset-0 flex items-center justify-center'>
            <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-white'></div>
          </div>
        )}

        {/* Error state */}
        {error && (
          <div className='absolute inset-0 flex items-center justify-center bg-black/50'>
            <div className='text-center py-4 text-red-500'>
              Failed to load content.
              <button
                type='button'
                onClick={() =>
                  activeTab.value === 'courses'
                    ? refreshCourses()
                    : refreshStacks()
                }
                className='text-blue-500 hover:underline'
              >
                Retry
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
