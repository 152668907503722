import { type HiddenPictureBlock } from '@lp-lib/game';
import type { BlockOutputsDesc } from '@lp-lib/game/src/block-outputs';

const HOT_SPOT_STATES = ['found', 'not-found'] as const;

// note(falcon): this is a temporary solution to unblock logic for hp blocks, given our output logic currently does
// not support arrays.
export function getOutputSchema(block: HiddenPictureBlock): BlockOutputsDesc {
  const outputs: BlockOutputsDesc = {};

  const hotSpots = block.fields.pictures?.[0]?.hotSpotsV2;
  if (!hotSpots?.length) return outputs;

  for (const hotSpot of hotSpots) {
    outputs[hotSpot.id] = {
      name: hotSpot.id,
      displayName: hotSpot.name,
      description: '',
      schema: {
        type: 'enum',
        values: [...HOT_SPOT_STATES],
      },
    };
  }

  return outputs;
}
