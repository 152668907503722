import { Link } from '@remix-run/react';
import range from 'lodash/range';
import { useCallback, useState } from 'react';
import { type Control, Controller } from 'react-hook-form';
import Select, { type SingleValue } from 'react-select';

import {
  type EnumsGamePackChangeLevel,
  EnumsGamePackVersion,
  EnumsMediaScene,
  EnumsSharedAssetPurpose,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { type GamePack } from '../../../types/game';
import {
  fromDTOGamePack,
  fromMediaDataDTO,
  fromMediaDTO,
  toGamePackPromotionalAssetsDTO,
} from '../../../utils/api-dto';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { type Option } from '../../common/Utilities';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  ConfirmCancelModalWrapper,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { NewWindowIcon } from '../../icons/NewWindowIcon';
import { Loading } from '../../Loading';
import { useOpenMarkdownEditor } from '../../MarkdownEditor';
import { MediaEditor } from '../../MediaUploader/MediaEditor';
import { MediaUploader } from '../../MediaUploader/MediaUploader';
import { usePromptTemplate } from '../../PromptTemplate';
import { useOpenPromptTemplatePickerModal } from '../../PromptTemplate/PromptTemplatePicker';
import { SwitcherControlled } from '../../Switcher';
import { TagPicker } from '../../Tagging';
import { GamePackRandomizerEditor } from '../Blocks/Randomizer';
import { PlayerRangeUtils } from '../PlayerRangeUtils';
import {
  GamePackPromotionalAssetsForm,
  SendGameUpdatesForm,
} from './GamePackPromotionalAssets';
import {
  type GamePackEditorControlledProps,
  type GamePackEditorFieldProps,
  type GamePackEditorFormData,
  type GamePackFeaturedProps,
} from './types';
import { GamePackEditorUtils } from './utils';

export function PackNameField(props: GamePackEditorControlledProps) {
  return (
    <label htmlFor='name' className='font-bold block'>
      <div className='mb-2 flex flex-row justify-between'>
        <div>
          <span className='text-white'>Name</span>
          <span className='text-primary ml-2'>(Required)</span>
        </div>
      </div>
      <Controller<GamePackEditorFormData, 'name'>
        name='name'
        control={props.control}
        rules={{ required: true, maxLength: 50 }}
        render={({ field, fieldState }) => (
          <input
            {...field}
            className={
              fieldState.error ? 'field-error h-13 mb-0' : 'field h-13 mb-0'
            }
            placeholder='Must be 1 to 50 characters'
            maxLength={50}
          />
        )}
      />
    </label>
  );
}

export function CoverField(
  props: GamePackEditorFieldProps & {
    cover: Media | null;
    setIsUploading: (val: boolean) => void;
    setCover?: (media: Media | null) => void;
  }
) {
  const { setIsUploading } = props;
  const [cover, setCover] = useState<Media | null>(props.cover);
  return (
    <label htmlFor='cover' className='w-full'>
      <Controller<GamePackEditorFormData, 'coverMediaId'>
        name='coverMediaId'
        control={props.control}
        render={({ field }) => (
          <MediaUploader
            video={false}
            scene={EnumsMediaScene.MediaSceneGamePackCover}
            media={cover}
            onUploadStart={() => setIsUploading(true)}
            onUploadSuccess={(media) => {
              setCover(media);
              props.setCover?.(media);
              field.onChange(media.id);
              setIsUploading(false);
            }}
            onUploadFailed={() => setIsUploading(false)}
            onMediaDelete={() => {
              setCover(null);
              props.setCover?.(null);
              field.onChange(null);
            }}
            width='w-full'
          />
        )}
      />
    </label>
  );
}

export function DescriptionField(props: GamePackEditorFieldProps) {
  return (
    <label>
      <div className='mb-2 font-bold'>Description</div>
      <Controller<GamePackEditorFormData, 'description'>
        name='description'
        control={props.control}
        rules={{ maxLength: 1000 }}
        render={({ field, fieldState }) => (
          <div className='relative'>
            <textarea
              {...field}
              className={`${
                fieldState.error ? 'field-error mb-0' : 'field mb-0'
              } h-30 py-2 scrollbar`}
              maxLength={1000}
              placeholder='Max 1000 characters'
            />
          </div>
        )}
      />
    </label>
  );
}

export function RichDescriptionField(props: GamePackEditorFieldProps) {
  const openMarkdownEditor = useOpenMarkdownEditor();
  return (
    <label>
      <div className='mb-2 font-bold'>Rich Description (Markdown)</div>
      <Controller<GamePackEditorFormData, 'detailSettings.richDescription'>
        name='detailSettings.richDescription'
        control={props.control}
        rules={{ maxLength: 3000 }}
        render={({ field, fieldState }) => (
          <div className='relative'>
            <textarea
              {...field}
              value={field.value ?? ''}
              className={`${
                fieldState.error ? 'field-error mb-0' : 'field mb-0'
              } h-30 py-2 scrollbar`}
              maxLength={3000}
              placeholder='Max 3000 characters'
            />
            {
              <button
                type='button'
                className='absolute right-2 bottom-1 text-xl'
                onClick={() =>
                  openMarkdownEditor({
                    initValue: field.value ?? '',
                    onSave: (val) => field.onChange(val),
                  })
                }
              >
                🅼
              </button>
            }
          </div>
        )}
      />
    </label>
  );
}

export function CategoriesField(
  props: GamePackEditorFieldProps & { tags: GamePack['tags'] }
) {
  const styles = useInstance(() => ({
    override: { control: { minHeight: 52 } },
  }));

  return (
    <label>
      <div className='mb-2 font-bold'>Categories</div>
      <Controller<GamePackEditorFormData, 'tags'>
        name='tags'
        control={props.control}
        render={({ field }) => (
          <TagPicker
            onChange={(tags) => {
              field.onChange(tags.map((t) => t.name));
            }}
            tags={props?.tags}
            creatable
            multi={true}
            placeholder='Add existing or create new Categories'
            formatMeta={GamePackEditorUtils.FormatTagOptionMeta}
            styles={styles}
          />
        )}
      />
    </label>
  );
}

export function Featured(props: GamePackFeaturedProps) {
  return (
    <div className='flex w-2/3 justify-between items-center'>
      <p className='mr-4 text-xs font-light'>Feature On Demand Game</p>
      <Controller<GamePackEditorFormData, 'featured'>
        name='featured'
        control={props.control}
        render={({ field }) => (
          <SwitcherControlled
            name={field.name}
            checked={field.value || false}
            onChange={(checked) => {
              if (!checked) {
                field.onChange(checked);
                return;
              }

              props.onShowOverlay(
                <PromotionalAssetsOverlay
                  {...props}
                  onConfirm={() => {
                    field.onChange(true);
                  }}
                />
              );
            }}
          />
        )}
      />
    </div>
  );
}

function PromotionalAssetsOverlay(
  props: GamePackFeaturedProps & {
    onConfirm?: () => void;
  }
) {
  const { watch } = props;
  const title = watch('name');
  const description = watch('description');
  const cover = props.cover;

  return (
    <Controller<GamePackEditorFormData, 'promotionalAssets'>
      name='promotionalAssets'
      control={props.control}
      render={({ field }) => (
        <ConfirmCancelModalWrapper>
          <ModalWrapper containerClassName='w-160' borderStyle='white'>
            <div className='relative w-full'>
              <GamePackPromotionalAssetsForm
                defaultValues={
                  field.value || {
                    pushable: true,
                    title: title,
                    description: description || '',
                    cover: cover
                      ? {
                          mediaData: {
                            id: cover?.id,
                          },
                          media: cover,
                        }
                      : undefined,
                  }
                }
                onCancel={() => props.onShowOverlay(null)}
                onConfirm={async (assets) => {
                  field.onChange(assets);
                  props.onShowOverlay(null);
                  if (props.onConfirm) {
                    props.onConfirm();
                  }
                }}
              />
            </div>
          </ModalWrapper>
        </ConfirmCancelModalWrapper>
      )}
    />
  );
}

function PromotionSendOverlay(
  props: GamePackFeaturedProps & {
    onConfirm?: () => void;
    pack: GamePack;
  }
) {
  return (
    <ConfirmCancelModalWrapper>
      <ModalWrapper containerClassName='w-160' borderStyle='white'>
        <div className='relative w-full'>
          <SendGameUpdatesForm
            onClose={() => props.onShowOverlay(null)}
            onConfirm={async (data) => {
              if (!data.recipient) return;

              await apiService.promotion.sendGameUpdates({
                recipientId: data.recipient.uid,
                gamePackIds: props.pack.id,
              });

              props.onShowOverlay(null);
              if (props.onConfirm) {
                props.onConfirm();
              }
            }}
          />
        </div>
      </ModalWrapper>
    </ConfirmCancelModalWrapper>
  );
}

export function PromotionalAssets(
  props: GamePackFeaturedProps & {
    pack: GamePack;
  }
) {
  return (
    <div className='flex w-2/3 justify-between items-center'>
      <p className='px-4 text-xs font-light'>Promotional Assets</p>

      <div className='flex items-center gap-2'>
        <button
          type='button'
          className='btn text-primary text-sm underline cursor-pointer'
          onClick={() =>
            props.onShowOverlay(<PromotionSendOverlay {...props} />)
          }
        >
          Test
        </button>
        <button
          type='button'
          className='btn text-primary text-sm underline cursor-pointer'
          onClick={() =>
            props.onShowOverlay(<PromotionalAssetsOverlay {...props} />)
          }
        >
          Edit
        </button>
      </div>
    </div>
  );
}

type PlayerOption = Option<number | undefined>;

const minPlayerOptions: PlayerOption[] = range(1, 16).map((i) => ({
  label: `${i}`,
  value: i,
}));

const maxPlayerOptions: PlayerOption[] = [
  ...minPlayerOptions,
  { label: 'N/A', value: undefined },
];

function findPlayerOption(
  value: number | null | undefined,
  options: PlayerOption[]
): PlayerOption | null {
  if (options.length < 1) return null;
  const maybeOption = options.find((opt) => opt.value === value);
  if (maybeOption) {
    return maybeOption;
  } else if (value === null || value === undefined) {
    return options[0];
  } else {
    return { label: `${value}`, value };
  }
}

export function PlayerRangeEditor(props: {
  control: Control<GamePackEditorFormData>;
}): JSX.Element {
  const styles = useInstance(() => buildReactSelectStyles<PlayerOption>());
  return (
    <Controller<GamePackEditorFormData, 'playerRange'>
      name='playerRange'
      control={props.control}
      render={({ field }) => {
        const { value, onChange } = field;

        const handleMinChange = (change: SingleValue<PlayerOption>) => {
          if (!change || !change.value) return;
          const min = change.value;
          const max = value?.max && value.max < min ? min : value?.max;
          onChange({ min, max });
        };

        const handleMaxChange = (change: SingleValue<PlayerOption>) => {
          if (!change) return;
          if (change.value === undefined) {
            onChange({ min: value?.min, max: change.value });
            return;
          }
          const max = change.value;
          const min = !value?.min || value.min > max ? 2 : value.min;
          onChange({ min, max });
        };

        return (
          <div>
            <div className='mb-2 text-white font-bold'>
              Player Min and Max
              {value && (
                <span className='ml-2 text-secondary text-xs font-normal'>
                  {PlayerRangeUtils.Format(value)}
                </span>
              )}
            </div>
            <div className='flex gap-2 items-center text-sms'>
              <Select<PlayerOption>
                options={minPlayerOptions}
                value={findPlayerOption(value?.min, minPlayerOptions)}
                classNamePrefix='select-box-v2'
                className='w-24'
                styles={styles}
                onChange={handleMinChange}
              />
              to
              <Select<PlayerOption>
                options={maxPlayerOptions}
                value={findPlayerOption(value?.max, maxPlayerOptions)}
                classNamePrefix='select-box-v2'
                className='w-24'
                styles={styles}
                isOptionDisabled={(option) =>
                  option.value !== undefined &&
                  value?.min !== undefined &&
                  option.value < value.min
                }
                onChange={handleMaxChange}
              />
              players
            </div>
          </div>
        );
      }}
    />
  );
}

export function TeamRandomization(
  props: GamePackEditorControlledProps & {
    className?: string;
    icebreakerEnabled?: boolean;
  }
) {
  return (
    <Controller<GamePackEditorFormData, 'teamRandomizationSettings'>
      name='teamRandomizationSettings'
      control={props.control}
      render={({ field }) => (
        <GamePackRandomizerEditor
          className={props.className}
          value={field.value}
          onChange={(settings) => field.onChange(settings)}
          icebreakerEnabled={props.icebreakerEnabled}
        />
      )}
    />
  );
}

export function useTriggerCancelConfirmModal() {
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  return useCallback(async () => {
    return await confirmCancel({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-5'>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            You have unsaved changes. Are you sure you want to discard them?
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnVariant: 'delete',
      confirmBtnLabel: 'Discard changes',
      cancelBtnLabel: 'Keep editing',
      autoFocus: 'cancel',
    });
  }, [confirmCancel]);
}

export function useSubmit(packId: string | undefined) {
  return useLiveAsyncCall(
    async (
      data: GamePackEditorFormData,
      confirm?: {
        level: EnumsGamePackChangeLevel;
        note?: string;
      }
    ) => {
      const {
        promotionalAssets,
        blocks,
        faqGroups,
        anonymousFAQGroups,
        showcaseCards,
        marketingSettings,
        teamRandomizationSettings,
        aiHostSettings,
        cohostSettings,
        coverMediaId,
        extraSettings,
        detailSettings,
        playbackSettings,
        playerRange,
        tags,
        ugcSettings,
        ...rest
      } = data;
      const blockIds = blocks?.map((b) => b.id);
      const pAssets = toGamePackPromotionalAssetsDTO(promotionalAssets);
      if (packId) {
        if (!confirm) {
          throw new Error('Confirmation is required when updating a game pack');
        }
        const resp = await apiService.gamePack.update(packId, {
          ...rest,
          changeLevel: confirm.level,
          changeNote: confirm.note,
          childrenIds: blockIds,
          coverMediaId: coverMediaId ?? undefined,
          extraSettings: extraSettings ?? undefined,
          detailSettings: detailSettings ?? undefined,
          playbackSettings: playbackSettings ?? undefined,
          promotionalAssets: pAssets,
          playerRange: playerRange ?? undefined,
          tags: tags ?? undefined,
          marketingSettings: {
            ...marketingSettings,
            faqGroupIds: faqGroups?.map((faq) => faq.id),
            anonymousFAQGroupIds: anonymousFAQGroups?.map((faq) => faq.id),
            showcaseCardIds: showcaseCards?.map((card) => card.id),
          },
          teamRandomizationSettings: teamRandomizationSettings ?? undefined,
          aiHostSettings: aiHostSettings ?? undefined,
          cohostSettings: cohostSettings ?? undefined,
          ugcSettings: ugcSettings ?? undefined,
        });
        return fromDTOGamePack(resp.data.gamePack);
      } else {
        const resp = await apiService.gamePack.create({
          ...rest,
          version: EnumsGamePackVersion.GamePackVersionV2,
          childrenIds: blockIds,
          coverMediaId: coverMediaId ?? undefined,
          extraSettings: extraSettings ?? undefined,
          detailSettings: detailSettings ?? undefined,
          playbackSettings: playbackSettings ?? undefined,
          promotionalAssets: pAssets,
          playerRange: playerRange ?? undefined,
          tags: tags ?? undefined,
          marketingSettings: {
            ...marketingSettings,
            faqGroupIds: faqGroups?.map((faq) => faq.id),
            anonymousFAQGroupIds: anonymousFAQGroups?.map((faq) => faq.id),
            showcaseCardIds: showcaseCards?.map((card) => card.id),
          },
          teamRandomizationSettings: teamRandomizationSettings ?? undefined,
          aiHostSettings: aiHostSettings ?? undefined,
          ugcSettings: ugcSettings ?? undefined,
        });
        return fromDTOGamePack(resp.data.gamePack);
      }
    }
  );
}

export function LobbyBackgroundField(props: GamePackEditorFieldProps) {
  return (
    <label htmlFor='marketingSettings.lobbyBackground' className='w-full'>
      <Controller<GamePackEditorFormData, 'marketingSettings.lobbyBackground'>
        name='marketingSettings.lobbyBackground'
        control={props.control}
        render={({ field: { value: asset, onChange } }) => (
          <MediaEditor
            video
            scene={EnumsMediaScene.MediaSceneGamePackMarketingMaterials}
            title='Lobby Background'
            media={fromMediaDTO(asset?.media)}
            mediaData={fromMediaDataDTO(asset?.data)}
            volumeSelectable
            onChange={(data, media) => {
              onChange(
                media
                  ? {
                      media,
                      data,
                    }
                  : null
              );
            }}
            sharedAssetPurposes={[
              EnumsSharedAssetPurpose.SharedAssetPurposeGeneral,
            ]}
            onSharedAssetSelected={(item) => {
              onChange({
                data: {
                  id: item.mediaId,
                },
                media: item.media,
              });
            }}
          />
        )}
      />
    </label>
  );
}

export function ReplayableField(props: GamePackEditorFieldProps) {
  return (
    <label className='flex w-2/3 justify-between items-center'>
      <p className='mr-4 text-xs font-light'>Replayable</p>
      <Controller<GamePackEditorFormData, 'replayable'>
        name='replayable'
        control={props.control}
        render={({ field }) => (
          <SwitcherControlled
            name={field.name}
            checked={field.value || false}
            onChange={field.onChange}
            disabled={props.disabled}
          />
        )}
      />
    </label>
  );
}

export function UGCPromptTemplateEditor(props: {
  label: string;
  value?: string | null;
  onChange: (value: string | null | undefined) => void;
}) {
  const openPicker = useOpenPromptTemplatePickerModal();

  const { data, isLoading, error } = usePromptTemplate(props.value, false);

  const handleAdd = useLiveCallback(() => {
    openPicker({
      onSelected: (item) => {
        props.onChange(item.id);
      },
    });
  });

  const handleDelete = useLiveCallback(() => {
    props.onChange(null);
  });

  return (
    <div className='flex flex-col gap-4'>
      <div className='w-full'>
        <div className='mb-2 w-full flex items-center justify-between'>
          <div className='font-bold'>{props.label}</div>
          {props.value && (
            <button
              type='button'
              className='btn text-sms text-icon-gray hover:text-primary hover:underline transition-all'
              onClick={handleAdd}
            >
              Edit
            </button>
          )}
        </div>
        {!props.value ? (
          <button
            type='button'
            className='mt-2 btn text-sms text-secondary'
            onClick={handleAdd}
          >
            + Select from prompt library
          </button>
        ) : isLoading ? (
          <Loading text='' />
        ) : error || !data ? (
          <div className='text-red-002 text-sms text-center'>
            Error loading prompt template
          </div>
        ) : (
          <div className='w-full flex items-center gap-2'>
            <div className='w-full bg-layer-002 rounded border border-secondary px-4 py-2 flex items-center justify-between'>
              <div className='text-white font-bold'>{data.name}</div>
              <Link
                to={`/admin/prompt-templates/${props.value}`}
                target='_blank'
              >
                <div className='transform hover:scale-110 transition-transform'>
                  <NewWindowIcon />
                </div>
              </Link>
            </div>
            <button
              type='button'
              onClick={handleDelete}
              className='flex-none w-7.5 h-7.5 rounded-lg border border-secondary btn flex justify-center items-center text-red-002 bg-black'
            >
              <DeleteIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
