import { type ClientLoaderFunctionArgs, redirect } from '@remix-run/react';

export const Component = () => <></>;
export default Component ?? (() => <></>);
export const HydrateFallback = () => <></>;

export function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  return redirect(`/learning/starter${url.search}`);
}
