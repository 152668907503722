export function InstgramIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M12 3c-2.444 0-2.75.01-3.71.054-.959.044-1.613.196-2.186.419-.6.225-1.144.58-1.594 1.038-.457.45-.812.993-1.038 1.593-.221.573-.374 1.228-.418 2.186C3.011 9.25 3 9.555 3 12c0 2.445.01 2.75.054 3.71.044.959.196 1.613.419 2.186.225.6.58 1.144 1.038 1.594.45.458.993.812 1.593 1.038.573.222 1.227.374 2.186.418.96.044 1.266.054 3.71.054s2.75-.01 3.71-.054c.959-.044 1.613-.196 2.186-.419.6-.225 1.144-.58 1.594-1.038.458-.45.812-.993 1.038-1.593.222-.573.374-1.227.418-2.186.044-.96.054-1.266.054-3.71s-.01-2.75-.054-3.71c-.044-.959-.196-1.613-.419-2.186A4.413 4.413 0 0019.49 4.51a4.415 4.415 0 00-1.593-1.038c-.573-.221-1.228-.374-2.186-.418C14.75 3.011 14.445 3 12 3zm0 1.622c2.403 0 2.688.009 3.637.052.877.04 1.354.186 1.671.31.42.163.72.358 1.035.673.315.315.51.615.673 1.035.123.317.27.794.31 1.67.043.95.052 1.235.052 3.638 0 2.403-.009 2.688-.052 3.637-.04.877-.186 1.354-.31 1.671-.144.391-.374.745-.673 1.035-.29.299-.644.529-1.035.673-.317.123-.794.27-1.67.31-.95.043-1.234.052-3.638.052s-2.688-.009-3.637-.052c-.877-.04-1.354-.186-1.671-.31a2.787 2.787 0 01-1.035-.673 2.789 2.789 0 01-.673-1.035c-.123-.317-.27-.794-.31-1.67-.043-.95-.052-1.235-.052-3.638 0-2.403.009-2.688.052-3.637.04-.877.186-1.354.31-1.671.163-.42.358-.72.673-1.035.29-.299.644-.529 1.035-.673.317-.123.794-.27 1.67-.31.95-.043 1.235-.052 3.638-.052z'
        clipRule='evenodd'
      ></path>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M11.999 15.003a3.002 3.002 0 110-6.004 3.002 3.002 0 010 6.004zm0-7.629a4.626 4.626 0 100 9.252 4.626 4.626 0 000-9.252zm5.972-.084a1.094 1.094 0 11-2.188 0 1.094 1.094 0 012.188 0z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export function LinkedInIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#fff'
        d='M21 4.324v15.353A1.323 1.323 0 0119.677 21H4.324A1.323 1.323 0 013 19.677V4.324A1.324 1.324 0 014.324 3h15.353A1.323 1.323 0 0121 4.324zM8.294 9.882H5.647v8.47h2.647v-8.47zm.238-2.911a1.525 1.525 0 00-1.514-1.536h-.047a1.535 1.535 0 000 3.07 1.525 1.525 0 001.561-1.487v-.047zm9.82 6.236c0-2.546-1.62-3.536-3.229-3.536a3.017 3.017 0 00-2.678 1.366h-.074V9.882H9.882v8.47h2.647v-4.504a1.757 1.757 0 011.589-1.896h.1c.842 0 1.467.53 1.467 1.864v4.537h2.647l.02-5.146z'
      ></path>
    </svg>
  );
}
