import { type AxiosResponse } from 'axios';

import {
  type DtoAddCoursesToStackRequest,
  type DtoAddEnrollmentsToGroupRequest,
  type DtoAddMembersToLearningGroupRequest,
  type DtoAssignCourseRequest,
  type DtoAssignCourseResponse,
  type DtoAssignStackRequest,
  type DtoAssignStackResponse,
  type DtoCreateGroupRequest,
  type DtoCreateGroupResponse,
  type DtoCreateStackRequest,
  type DtoEnrollGroupsRequest,
  type DtoEnrollGroupsResponse,
  type DtoGetCourseAssignedGroupsResponse,
  type DtoGetCourseAssignedMembersResponse,
  type DtoGetCourseStacksResponse,
  type DtoGetCreatedCoursesResponse,
  type DtoGetCreatedStacksResponse,
  type DtoGetLearnersResponse,
  type DtoGroupDetailResponse,
  type DtoJoinGroupsRequest,
  type DtoLearnerProfileResponse,
  type DtoLearningEnrollment,
  type DtoLearningEnrollmentsResponse,
  type DtoLearningGroupMembership,
  type DtoLearningGroupMembershipsResponse,
  type DtoLearningGroupSearchResponse,
  type DtoLearningGroupWithCounts,
  type DtoReorderStackCoursesRequest,
  type DtoSearchUserCreatedCoursesResponse,
  type DtoSearchUserCreatedStacksResponse,
  type DtoSingleGamePackResponse,
  type DtoStackDetailResponse,
  type DtoUpdateGroupNameRequest,
  type DtoUpdateStackNameRequest,
  type ModelsStack,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';
import { Paginator, type PaginatorOptions } from './pagination';

export class LearningAPI extends BaseAPIService {
  assignCourse(
    req: DtoAssignCourseRequest
  ): Promise<AxiosResponse<DtoAssignCourseResponse>> {
    return this.client.post('/learning/courses/assign', req);
  }

  assignStack(
    req: DtoAssignStackRequest
  ): Promise<AxiosResponse<DtoAssignStackResponse>> {
    return this.client.post('/learning/stacks/assign', req);
  }

  getMyLearnerProfile(
    opts?: Partial<{
      enrollmentsSummary: boolean;
      membershipsSummary: boolean;
    }>
  ): Promise<AxiosResponse<DtoLearnerProfileResponse>> {
    return this.client.get(`/my/learner-profile`, {
      params: opts,
    });
  }

  getLearnerProfile(
    uid: string,
    opts?: Partial<{
      enrollmentsSummary: boolean;
      membershipsSummary: boolean;
    }>
  ): Promise<AxiosResponse<DtoLearnerProfileResponse>> {
    return this.client.get(`/learning/learners/${uid}/profile`, {
      params: opts,
    });
  }

  getLearnerMemberships(
    uid: string,
    opts?: PaginatorOptions
  ): Paginator<
    DtoLearningGroupMembershipsResponse,
    DtoLearningGroupMembership
  > {
    return new Paginator<
      DtoLearningGroupMembershipsResponse,
      DtoLearningGroupMembership
    >(
      this.client,
      `/learning/learners/${uid}/memberships`,
      'memberships',
      opts
    );
  }

  getLearnerEnrollments(
    uid: string,
    opts?: PaginatorOptions
  ): Paginator<DtoLearningEnrollmentsResponse, DtoLearningEnrollment> {
    return new Paginator<DtoLearningEnrollmentsResponse, DtoLearningEnrollment>(
      this.client,
      `/learning/learners/${uid}/enrollments`,
      'enrollments',
      opts
    );
  }

  getLearners(): Promise<AxiosResponse<DtoGetLearnersResponse>> {
    return this.client.get('/learning/learners');
  }

  getUserCourses(): Promise<AxiosResponse<DtoSingleGamePackResponse[]>> {
    return this.client.get('/learning/courses');
  }

  getCourseAssignedMembers(
    courseId: string
  ): Promise<AxiosResponse<DtoGetCourseAssignedMembersResponse>> {
    return this.client.get(`/learning/courses/${courseId}/members`);
  }

  unassignMemberFromCourse(
    courseId: string,
    userId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(
      `/learning/courses/${courseId}/members/${userId}`
    );
  }

  createStack(req: DtoCreateStackRequest): Promise<AxiosResponse<ModelsStack>> {
    return this.client.post('/learning/stacks', req);
  }

  deleteStack(stackId: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`/learning/stacks/${stackId}`);
  }

  getCreatedCourses(
    page = 1,
    pageSize = 10
  ): Promise<AxiosResponse<DtoGetCreatedCoursesResponse>> {
    return this.client.get('/learning/courses/created', {
      params: { page, pageSize },
    });
  }

  getUserCreatedGroups(): Promise<AxiosResponse<DtoLearningGroupWithCounts[]>> {
    return this.client.get('/learning/groups');
  }

  deleteGroup(groupId: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`/learning/groups/${groupId}`);
  }

  searchGroupsByName(
    q: string
  ): Promise<AxiosResponse<DtoLearningGroupSearchResponse>> {
    return this.client.get('/learning/groups/search', { params: { q } });
  }

  assignCourseToGroups(
    req: DtoEnrollGroupsRequest
  ): Promise<AxiosResponse<DtoEnrollGroupsResponse>> {
    return this.client.post('/learning/groups/assign/course', req);
  }

  assignStackToGroups(
    req: DtoEnrollGroupsRequest
  ): Promise<AxiosResponse<DtoEnrollGroupsResponse>> {
    return this.client.post('/learning/groups/assign/stack', req);
  }

  getCourseAssignedGroups(
    courseId: string
  ): Promise<AxiosResponse<DtoGetCourseAssignedGroupsResponse>> {
    return this.client.get(`/learning/courses/${courseId}/groups`);
  }

  getCreatedStacks(
    page = 1,
    pageSize = 10
  ): Promise<AxiosResponse<DtoGetCreatedStacksResponse>> {
    return this.client.get('/learning/stacks/created', {
      params: { page, pageSize },
    });
  }

  getStackDetail(
    stackId: string
  ): Promise<AxiosResponse<DtoStackDetailResponse>> {
    return this.client.get(`/learning/stacks/${stackId}`);
  }

  removeCourseFromStack(
    stackId: string,
    courseId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(
      `/learning/stacks/${stackId}/courses/${courseId}`
    );
  }

  reorderStackCourses(
    stackId: string,
    req: DtoReorderStackCoursesRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.put(`/learning/stacks/${stackId}/courses/order`, req);
  }

  unassignMemberFromStack(
    stackId: string,
    userId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(`/learning/stacks/${stackId}/members/${userId}`);
  }

  unassignGroupFromStack(
    stackId: string,
    groupId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(`/learning/stacks/${stackId}/groups/${groupId}`);
  }

  unassignGroupFromCourse(
    courseId: string,
    groupId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(
      `/learning/courses/${courseId}/groups/${groupId}`
    );
  }

  searchUserCreatedCourses(
    query: string
  ): Promise<AxiosResponse<DtoSearchUserCreatedCoursesResponse>> {
    return this.client.get(`/learning/courses/search`, {
      params: { q: query },
    });
  }

  addCoursesToStack(
    stackId: string,
    req: DtoAddCoursesToStackRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post(`/learning/stacks/${stackId}/courses`, req);
  }

  updateStackName(
    stackId: string,
    req: DtoUpdateStackNameRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.put(`/learning/stacks/${stackId}/name`, req);
  }

  getGroupDetail(
    groupId: string
  ): Promise<AxiosResponse<DtoGroupDetailResponse>> {
    return this.client.get(`/learning/groups/${groupId}`);
  }

  updateGroupName(
    groupId: string,
    req: DtoUpdateGroupNameRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.put(`/learning/groups/${groupId}/name`, req);
  }

  removeEnrollmentFromGroup(
    groupId: string,
    enrollmentType: 'course' | 'stack',
    objectId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(`/learning/groups/${groupId}/enrollments`, {
      params: { enrollmentType, objectId },
    });
  }

  removeMemberFromGroup(
    groupId: string,
    userId: string
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(`/learning/groups/${groupId}/members/${userId}`);
  }

  searchUserCreatedStacks(
    query: string
  ): Promise<AxiosResponse<DtoSearchUserCreatedStacksResponse>> {
    return this.client.get(`/learning/stacks/search`, {
      params: { q: query },
    });
  }

  addEnrollmentsToGroup(
    groupId: string,
    req: DtoAddEnrollmentsToGroupRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post(`/learning/groups/${groupId}/enrollments`, req);
  }

  addMembersToGroup(
    groupId: string,
    req: DtoAddMembersToLearningGroupRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post(`/learning/groups/${groupId}/members`, req);
  }

  joinGroups(req: DtoJoinGroupsRequest): Promise<AxiosResponse<void>> {
    return this.client.post(`/learning/groups/join`, req);
  }

  createGroup(
    req: DtoCreateGroupRequest
  ): Promise<AxiosResponse<DtoCreateGroupResponse>> {
    return this.client.post('/learning/groups', req);
  }

  getCourseStacks(
    courseId: string
  ): Promise<AxiosResponse<DtoGetCourseStacksResponse>> {
    return this.client.get(`/learning/courses/${courseId}/stacks`);
  }

  deleteCourse(courseId: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`/learning/courses/${courseId}`);
  }
}
