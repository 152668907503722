import { useMemo } from 'react';
import Select from 'react-select';

import { EnumsExternalMediaAspectRatio } from '@lp-lib/api-service-client/public';

import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';

const options: Option<EnumsExternalMediaAspectRatio>[] = [
  {
    label: 'Wide',
    value: EnumsExternalMediaAspectRatio.ExternalMediaAspectRatioWide,
  },
  {
    label: 'Tall',
    value: EnumsExternalMediaAspectRatio.ExternalMediaAspectRatioTall,
  },
  {
    label: 'Square',
    value: EnumsExternalMediaAspectRatio.ExternalMediaAspectRatioSquare,
  },
  {
    label: 'All',
    value: EnumsExternalMediaAspectRatio.ExternalMediaAspectRatioAll,
  },
];

export function AspectRatioPicker(props: {
  value: EnumsExternalMediaAspectRatio;
  onChange: (value: EnumsExternalMediaAspectRatio) => void;
}) {
  const styles = useMemo(() => buildReactSelectStyles(), []);
  return (
    <Select<Option<EnumsExternalMediaAspectRatio>, false>
      classNamePrefix='select-box-v2'
      styles={styles}
      value={options.find((o) => o.value === props.value)}
      options={options}
      onChange={(option) => {
        if (option) props.onChange(option.value);
      }}
      isSearchable={false}
      isClearable={false}
    />
  );
}
