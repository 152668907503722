import Select from 'react-select';

import { EnumsBlockIntro } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../../../hooks/useInstance';
import { buildReactSelectStyles } from '../../../../utils/react-select';

type BlockIntroOption = {
  value: EnumsBlockIntro;
  label: string;
};

const options = [
  {
    value: EnumsBlockIntro.BlockIntroNone,
    label: 'None',
  },
  {
    value: EnumsBlockIntro.BlockIntroVoiceWithStinger,
    label: 'Voice w/ Stinger',
  },
  {
    value: EnumsBlockIntro.BlockIntroStinger,
    label: 'Stinger Only',
  },
  {
    value: EnumsBlockIntro.BlockIntroVoice,
    label: 'Voice Only',
  },
];

export function BlockIntroSelect(props: {
  value: Nullable<EnumsBlockIntro>;
  onChange: (value: EnumsBlockIntro | null) => void;
}) {
  const styles = useInstance(() => buildReactSelectStyles<BlockIntroOption>());
  const selected = options.find((o) => o.value === props.value) ?? options[0];

  return (
    <Select<BlockIntroOption, false>
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full text-white'
      value={selected}
      options={options}
      onChange={(v) => props.onChange(v === null ? null : v.value)}
      isSearchable={false}
      menuPlacement='auto'
      menuPosition='fixed'
    />
  );
}
