import React, { useState } from 'react';

import { ArrowLeftIcon, ArrowRightIcon } from '../../../icons/Arrows';

const PAGE_SIZE_OPTIONS = [5, 10, 20, 50] as const;

interface PaginationNavProps {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  totalItems: number;
  isLoading: boolean;
  className?: string;
}

export const PaginationNav: React.FC<PaginationNavProps> = ({
  currentPage,
  totalPages,
  pageSize,
  onPageChange,
  onPageSizeChange,
  totalItems,
  isLoading,
  className = '',
}) => {
  const [inputPage, setInputPage] = useState(currentPage.toString());

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputPage(e.target.value);
  };

  const handlePageInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const newPage = parseInt(inputPage);
      if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
        onPageChange(newPage);
      } else {
        setInputPage(currentPage.toString());
      }
    }
  };

  const handlePageInputBlur = () => {
    const newPage = parseInt(inputPage);
    if (isNaN(newPage) || newPage < 1 || newPage > totalPages) {
      setInputPage(currentPage.toString());
    }
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = parseInt(e.target.value);
    onPageSizeChange(newSize);
  };

  // Update input when currentPage changes
  React.useEffect(() => {
    setInputPage(currentPage.toString());
  }, [currentPage]);

  return (
    <div className={`flex items-center justify-center gap-6 mt-6 ${className}`}>
      <div className='flex items-center gap-2 text-sm text-gray-400'>
        <span>Items per page:</span>
        <select
          value={pageSize}
          onChange={handlePageSizeChange}
          disabled={isLoading}
          className='bg-[#232325] text-white rounded-lg px-2 py-1 disabled:opacity-50'
        >
          {PAGE_SIZE_OPTIONS.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>

      <div className='flex items-center gap-4'>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage <= 1 || isLoading}
          className='p-2 rounded-lg bg-[#232325] text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-lp-gray-002 transition'
          aria-label='Previous page'
        >
          <ArrowLeftIcon className='w-5 h-5 fill-current text-white' />
        </button>

        <div className='flex items-center gap-2'>
          <span className='text-white'>Page</span>
          <input
            type='text'
            value={inputPage}
            onChange={handlePageInputChange}
            onKeyDown={handlePageInputKeyDown}
            onBlur={handlePageInputBlur}
            disabled={isLoading}
            className='w-12 bg-[#232325] text-white text-center rounded-lg px-2 py-1 disabled:opacity-50'
            aria-label='Current page'
          />
          <span className='text-white'>of {Math.max(1, totalPages)}</span>
        </div>

        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage >= totalPages || isLoading}
          className='p-2 rounded-lg bg-[#232325] text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-lp-gray-002 transition'
          aria-label='Next page'
        >
          <ArrowRightIcon className='w-5 h-5 fill-current text-white' />
        </button>
      </div>

      <div className='text-sm text-gray-400'>{totalItems} total items</div>
    </div>
  );
};
