import { useEffect } from 'react';
import { proxy } from 'valtio';

import {
  type DtoTTSRenderRequest,
  EnumsJeopardyTurnResult,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import type { MultipleChoiceBlock, MultipleChoiceOption } from '@lp-lib/game';
import { type BlockOutputsDesc } from '@lp-lib/game/src/block-outputs';
import { type Logger } from '@lp-lib/logger-base';

import { sleep } from '../../../../utils/common';
import { markSnapshottable, useSnapshot } from '../../../../utils/valtio';
import {
  lvoLocalCacheWarm,
  LVOLocalPlayer,
} from '../../../VoiceOver/LocalLocalizedVoiceOvers';
import { JeopardyHeader, JeopardyTools } from '../../apis/JeopardyControl';
import { getStingerPrompt } from '../../apis/StingerControl';
import { Timer, TimerBackground } from '../../apis/Timer';
import {
  CommonButton,
  type CommonButtonVariant,
  GamePlayButton,
} from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { getOutputSchema } from './outputs';

function getEvaluationPrompt(
  question: string,
  answer: string,
  evaluation: string
) {
  return `
\`\`\`openai { "model": "gpt-4o-mini", "temperature": 1, "maxTokens": 1024 }
You are a script writer for an online platform called Luna Park. Your only job is to write a script that informs a user if they answered correctly or incorrectly or skipped. I will give you the expected answer, the user's submitted answer, and how we have evaluated it.

Your script should be short and creative.

When the evaluation is incorrect, use only the provided question and answer to restate what the user missed. Do not use any other information or knowledge for this script.  

When the evaluation is correct, your script can be short and does not need to restate the answer.
Output only the script, with nothing before or after.

<example>
Question: Why is it challenging to host for an unseen audience?
Correct Answer: You can't adjust based on immediate feedback
Evaluation: incorrect
Output: Incorrect. One challenge for a host is they cannot adjust based on immediate feedback.
</example>

Output only the script, with nothing before or after. 

Question: ${question}
Correct Answer: ${answer}
Evaluation: ${evaluation}
\`\`\`
`.trim();
}

type State = {
  status: 'init' | 'present' | 'play' | 'grade' | 'inform' | 'complete';
  questionVisibility: 'visible' | 'hidden';
  choicesVisibility: 'visible' | 'hidden';
  choicesEnabled: boolean;
  selection?: MultipleChoiceOption | null;
  result?: 'correct' | 'incorrect' | 'skipped' | null;
  continueEnabled: boolean;
  strikedChoices: number[];
};

export class MultipleChoiceBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
      questionVisibility: 'hidden',
      choicesVisibility: 'hidden',
      choicesEnabled: false,
      continueEnabled: false,
      strikedChoices: [],
    })
  );

  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    question: Nullable<DtoTTSRenderRequest>;
    correct: Nullable<DtoTTSRenderRequest>;
    incorrect: Nullable<DtoTTSRenderRequest>;
    skipped: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    question: null,
    correct: null,
    incorrect: null,
    skipped: null,
  };

  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  private logger: Logger;
  private schema: BlockOutputsDesc;
  private readonly willGrade: boolean;
  private _timer: Nullable<Timer>;

  constructor(
    private block: MultipleChoiceBlock,
    private deps: BlockDependencies
  ) {
    this.logger = deps.getLogger('multiple-choice-block');
    this.schema = getOutputSchema(block);
    this.willGrade = block.fields.points !== 0;
    // only Jeopardy has a timer
    if (this.deps.jeopardyControl) {
      this._timer = new Timer(block.fields.questionTimeSec * 1000);
    }
  }

  get state() {
    return this._state;
  }

  get timer() {
    return this._timer;
  }

  get jeopardyControl() {
    return this.deps.jeopardyControl;
  }

  async preload() {
    if (this.deps.stingerControl.shouldPreloadTTS(this.block)) {
      this.resolvedTTS.stinger = await this.preloadTTS(
        getStingerPrompt(
          this.block.fields.question,
          'Tap the Fact',
          'A multiple choice question.'
        )
      );
    }

    this.resolvedTTS.question = await this.preloadTTS(
      this.block.fields.question
    );

    // preload TTS for grading, but do not wait it
    this.preloadGradingTTS();
  }

  private async preloadGradingTTS() {
    if (!this.willGrade) return;

    const correctChoice = this.block.fields.answerChoices.find(
      (c) => c.correct
    );

    this.resolvedTTS.correct = await this.preloadTTS(
      getEvaluationPrompt(
        this.block.fields.question,
        correctChoice?.text ?? '',
        'correct'
      )
    );
    this.resolvedTTS.incorrect = await this.preloadTTS(
      getEvaluationPrompt(
        this.block.fields.question,
        correctChoice?.text ?? '',
        'incorrect'
      )
    );
    if (this.deps.jeopardyControl) {
      this.resolvedTTS.skipped = await this.preloadTTS(
        getEvaluationPrompt(
          this.block.fields.question,
          correctChoice?.text ?? '',
          'skipped'
        )
      );
    }
  }

  async initialize(preloaded: Promise<void>) {
    await preloaded;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    try {
      const player = new LVOLocalPlayer(this.resolvedTTS.question);
      const info = await player.playFromPool();
      await info?.trackStarted;
      this._state.status = 'present';
      this._state.questionVisibility = 'visible';
      await info?.trackEnded;
    } catch (e) {
      this.logger.error(
        'failed to play question TTS, falling back to silence',
        e
      );
      this._state.status = 'present';
      this._state.questionVisibility = 'visible';
      await sleep(3000);
    }
    this._state.choicesVisibility = 'visible';
    this._state.choicesEnabled = true;
    this._state.status = 'play';

    this._timer?.on('timeout', () => this.timeout());
    this._timer?.start();
  }

  async selectChoice(choice: MultipleChoiceOption) {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    this._state.selection = choice;
    this._state.continueEnabled = true;
  }

  async strikeChoice() {
    this.deps.sfxControl.play('quicklyErase');

    const candidates = [];

    for (let i = 0; i < this.block.fields.answerChoices.length; i++) {
      if (this.block.fields.answerChoices[i].correct) continue;
      if (this._state.strikedChoices.includes(i)) continue;
      candidates.push(i);
    }

    if (candidates.length === 0) return;

    const index = candidates[Math.floor(Math.random() * candidates.length)];
    // TODO: play strike sound
    this._state.strikedChoices.push(index);
  }

  async pauseTime() {
    this.deps.sfxControl.play('timePaused');
    this._timer?.pause(10000);
  }

  async continue() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    if (this._state.status === 'complete') {
      await this.end();
    } else if (this._state.selection) {
      await this.submitChoice();
    }
  }

  async submitChoice() {
    this._timer?.stop();

    this._state.choicesEnabled = false;
    const choice = this._state.selection;
    this.delegate?.blockDidOutput(this.schema.choice, choice?.text ?? '');
    if (!this.willGrade) {
      this._state.status = 'complete';
      return this.end();
    }

    this._state.continueEnabled = false;
    this._state.status = 'grade';
    // need a little delay to make the transition smoother
    await sleep(250);

    this._state.result = choice?.correct ? 'correct' : 'incorrect';
    this.delegate?.blockDidOutput(this.schema.grade, this._state.result);

    this._state.status = 'inform';
    this.deps.sfxControl.play(choice?.correct ? 'rapidCorrect' : 'rapidWrong');

    if (choice?.correct) {
      await this.playTTS(this.resolvedTTS.correct);
    } else {
      await this.playTTS(this.resolvedTTS.incorrect);
    }

    this._state.status = 'complete';
    this._state.continueEnabled = true;
  }

  async skip() {
    this._timer?.stop();
    this._state.choicesEnabled = false;

    await sleep(250);

    this._state.result = 'skipped';
    this.delegate?.blockDidOutput(this.schema.grade, this._state.result);

    this._state.status = 'inform';
    // this.deps.sfxControl.play('')
    await this.playTTS(this.resolvedTTS.skipped);
    this._state.status = 'complete';
    this._state.continueEnabled = true;
  }

  async timeout() {
    this.deps.sfxControl.play('jeopardyTimesUp');
    await this.skip();
  }

  async end() {
    this._state.continueEnabled = false;
    await this.delegate?.blockDidEnd();
    this.jeopardyControl?.end(
      this._state.result === 'correct'
        ? EnumsJeopardyTurnResult.JeopardyTurnResultCorrect
        : this._state.result === 'incorrect'
        ? EnumsJeopardyTurnResult.JeopardyTurnResultIncorrect
        : EnumsJeopardyTurnResult.JeopardyTurnResultSkipped
    );
  }

  reset() {
    this._timer?.stop();
  }

  private async preloadTTS(script: string) {
    const req = await this.makeTTSRenderRequest(script);
    if (!req) return null;
    await lvoLocalCacheWarm(req);
    return req;
  }

  private async playTTS(req: Nullable<DtoTTSRenderRequest>) {
    if (!req) return;

    try {
      const player = new LVOLocalPlayer(req);
      const info = await player.playFromPool();
      await info?.trackEnded;
    } catch (e) {
      this.logger.error('failed to play TTS', e);
    }
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.block.fields.personalityId) return null;
    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }
}

export function MultipleChoiceBlockPlayground(props: {
  block: MultipleChoiceBlock;
  ctrl: MultipleChoiceBlockControlAPI;
}) {
  const { question, answerChoices } = props.block.fields;
  const {
    status,
    questionVisibility,
    choicesVisibility,
    choicesEnabled,
    selection,
    result,
    continueEnabled,
    strikedChoices,
  } = useSnapshot(props.ctrl.state);

  useEffect(() => {
    if (status !== 'init') return;
    props.ctrl.present();
  }, [status, props.ctrl]);

  useEffect(() => {
    return () => {
      props.ctrl.reset();
    };
  }, [props.ctrl]);

  return (
    <>
      {props.ctrl.timer && <TimerBackground timer={props.ctrl.timer} />}

      <div className='relative w-full h-full min-h-0 flex flex-col'>
        {props.ctrl.jeopardyControl && (
          <JeopardyHeader jeopardyControl={props.ctrl.jeopardyControl} />
        )}

        <main className='w-full flex-1 min-h-0 px-10 flex flex-col items-center justify-center gap-5 sm:gap-10'>
          <div
            className={`
            text-white text-center break-words
            text-xl lg:text-2xl
            transition-opacity duration-500 ${
              questionVisibility === 'visible' ? 'opacity-100' : 'opacity-0'
            }
         `}
          >
            {question}
          </div>

          <div
            className={`w-full max-w-150 flex flex-col items-center gap-2 transition-opacity duration-500 ${
              choicesVisibility === 'visible' ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {answerChoices.map((choice, idx) => {
              const isStriked = strikedChoices.includes(idx);

              const mySelection = selection?.text === choice.text;
              let variant: CommonButtonVariant = 'gray';
              if (result) {
                if (choice.correct) {
                  variant = 'correct';
                } else if (mySelection) {
                  variant = 'incorrect';
                }
              } else if (mySelection) {
                variant = 'brand';
              }
              const hasSelection = !!selection;

              return (
                <div
                  key={`choice-${idx}`}
                  className={`${
                    isStriked ? 'opacity-0 h-0' : 'opacity-100'
                  } w-full transition-all duration-500`}
                >
                  <GamePlayButton
                    variant={variant}
                    styles={{
                      text: 'text-sm font-bold text-white',
                      size: 'w-full min-h-11.5',
                      spacing: 'px-4 py-2',
                      bordered: result === 'incorrect' && choice.correct,
                    }}
                    className={hasSelection ? 'disabled:opacity-100' : ''}
                    onClick={() => props.ctrl.selectChoice(choice)}
                    disabled={!choicesEnabled}
                  >
                    {choice.text}
                  </GamePlayButton>
                </div>
              );
            })}

            {props.ctrl.jeopardyControl && (
              <button
                type='button'
                className={`btn text-sms font-bold text-white`}
                disabled={!choicesEnabled}
                onClick={() => props.ctrl.skip()}
              >{`Skip >>`}</button>
            )}
          </div>
        </main>

        <footer
          className={`
          relative w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5 transition-opacity duration-500
          ${choicesVisibility === 'visible' ? 'opacity-100' : 'opacity-0'}
        `}
        >
          {props.ctrl.jeopardyControl && (
            <JeopardyTools
              jeopardyControl={props.ctrl.jeopardyControl}
              onStrike={() => props.ctrl.strikeChoice()}
              onExtraTime={() => props.ctrl.pauseTime()}
              visible={status === 'play'}
            />
          )}

          <div
            className={`
            w-full h-5 flex items-center justify-center text-xs text-center transition-opacity
            ${
              result === 'correct'
                ? 'text-green-001 opacity-100'
                : result === 'incorrect'
                ? 'text-red-006 opacity-100'
                : result === 'skipped'
                ? 'text-yellow-001 opacity-100'
                : 'opacity-0'
            }
          `}
          >
            {result === 'correct' ? (
              <span>
                <strong>Correct!</strong> Nice job!
              </span>
            ) : result === 'incorrect' ? (
              <span>
                <strong>Incorrect!</strong> See correct answer above.
              </span>
            ) : result === 'skipped' ? (
              <span>
                <strong>Skipped!</strong> See correct answer above.
              </span>
            ) : null}
          </div>
          <CommonButton
            variant={
              result === 'correct'
                ? 'correct'
                : result === 'incorrect'
                ? 'incorrect'
                : 'brand'
            }
            onClick={() => props.ctrl.continue()}
            disabled={!continueEnabled}
          >
            Continue
          </CommonButton>
          {result === 'correct' && (
            <CorrectAnimationWithLayout onEnd={() => void 0} />
          )}
        </footer>
      </div>
    </>
  );
}
