import { type AxiosResponse } from 'axios';

import {
  type DtoGenAIGlobalSettingsResponse,
  type DtoImageGenRequest,
  type DtoImageGenResponse,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class AIGeneralAPI extends BaseAPIService {
  getGlobalSettings(): Promise<AxiosResponse<DtoGenAIGlobalSettingsResponse>> {
    return this.client.get(`/ai/global-settings`);
  }

  generateImages(
    req: DtoImageGenRequest
  ): Promise<AxiosResponse<DtoImageGenResponse>> {
    return this.client.post(`/ai/image/generate`, req);
  }
}
