import { PopupButton } from '@typeform/embed-react';
import { useState } from 'react';

import { useLiveAsyncCall } from '../../../../hooks/useAsyncCall';
import { getQueryParam } from '../../../../hooks/useQueryParam';
import { apiService } from '../../../../services/api-service';
import { sleep } from '../../../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { EditIcon } from '../../../icons/EditIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { Loading } from '../../../Loading';

function PromptModal(props: {
  title?: string;
  initPrompt: string | null;
  onSave: (prompt: string) => void;
  onCancel: () => void;
}) {
  const [prompt, setPrompt] = useState(props.initPrompt || '');

  return (
    <ModalWrapper
      containerClassName='w-240 h-150'
      innerClassName='rounded-xl'
      borderStyle='gray'
    >
      <div className='w-full h-full px-7.5 py-10 flex flex-col gap-10'>
        <div className='text-2xl text-center'>
          {props.title ? props.title : 'Edit your instructions to AI'}
        </div>

        <textarea
          className='field m-0 flex-1 px-4 py-2'
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder='Enter your instructions here'
        />

        <footer className='flex justify-center items-center gap-2'>
          <button
            type='button'
            className='btn-secondary w-30 h-10'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-30 h-10 mx-1 flex items-center justify-center gap-1'
            onClick={() => props.onSave(prompt)}
          >
            Save
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

function TypeformButton(props: {
  className?: string;
  children: React.ReactNode;
  onPromptGenerated: (prompt: string) => void;
}) {
  const {
    call: generatePrompt,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async (formId: string, responseId: string) => {
    await sleep(1000);

    for (let i = 0; i < 5; i++) {
      try {
        const resp = await apiService.block.generateRoleplayPrompt({
          formId,
          responseId,
          promptTemplateId: getQueryParam('prompt-template-id'),
        });
        props.onPromptGenerated(resp.data.prompt);
        break;
      } catch (err) {
        await sleep(1000);
      }
    }
  });

  return (
    <>
      <PopupButton
        id='oSpbLA9k'
        className={props.className}
        width={960}
        height={600}
        onSubmit={({ formId, responseId }) =>
          generatePrompt(formId, responseId)
        }
      >
        {props.children}
      </PopupButton>

      {isRunning && (
        <div className='fixed inset-0 bg-lp-black-004 z-50 flex flex-col items-center justify-center'>
          <Loading text='' />
          <div className='mt-2 text-center'>
            <p className='text-white text-lg font-bold'>
              Generating Instructions
            </p>
            <p className='mt-1'>This may take a while. Please standby.</p>
          </div>
        </div>
      )}
    </>
  );
}

export function RoleplayPromptEditor(props: {
  prompt: string;
  onChange: (prompt: string) => void;
}) {
  const { prompt, onChange } = props;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const openModal = (props: { initPrompt: string | null; title?: string }) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <PromptModal
          {...props}
          onSave={(newPrompt) => {
            onChange(newPrompt);
            p.internalOnConfirm();
          }}
          onCancel={p.internalOnCancel}
        />
      ),
    });
  };

  if (prompt.trim().length > 0) {
    return (
      <div className='w-full flex flex-col gap-1'>
        <p className='text-base font-bold'>Your Instructions to AI</p>
        <textarea
          className='w-full h-62 p-2.5 field m-0'
          value={prompt}
          disabled
        />
        <div className='flex justify-end items-center gap-2.5'>
          <button
            type='button'
            className='btn-secondary px-4 h-10 flex justify-center items-center gap-2.5'
            onClick={() => openModal({ initPrompt: prompt })}
          >
            <EditIcon className='w-5 h-5 fill-current' />
            <div className='text-sms font-medium'>Edit</div>
          </button>

          <TypeformButton
            className='btn-secondary px-4 h-10 flex justify-center items-center gap-2.5'
            onPromptGenerated={(prompt) => {
              openModal({
                initPrompt: prompt,
                title: 'Confirm your instructions to AI',
              });
            }}
          >
            <RefreshIcon className='w-5 h-5 fill-current' />
            <div className='text-sms font-medium'>Re-generate</div>
          </TypeformButton>
        </div>
      </div>
    );
  }

  return (
    <div className='w-full h-65 bg-layer-001 border border-secondary rounded-lg p-4 flex flex-col justify-between items-center'>
      <div className='flex flex-col gap-1'>
        <div className='text-base font-bold'>Roleplay Generator</div>
        <div className='text-sms text-icon-gray'>
          Build a roleplaying character prompt by answering a few quick
          questions about their persona, setting, and behavior.
        </div>
      </div>
      <TypeformButton
        className='btn-primary w-30 h-10'
        onPromptGenerated={(prompt) => {
          openModal({
            initPrompt: prompt,
            title: 'Confirm your instructions to AI',
          });
        }}
      >
        Start
      </TypeformButton>
    </div>
  );
}
