import { useCallback } from 'react';

import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { AddMembersToGroupModal } from './Modals/AddMembersToGroupModal';

interface TriggerModalOptions {
  group: {
    id: string;
    name: string;
  };
  onConfirm: (emails: string[]) => void;
}

export function useTriggerAddMembersToGroupModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    ({ group, onConfirm }: TriggerModalOptions) => {
      return triggerModal({
        kind: 'custom',
        containerClassName: 'bg-lp-black-002',
        element: (modalProps) => (
          <AddMembersToGroupModal
            group={group}
            onClose={modalProps.internalOnCancel}
            onConfirm={(emails) => {
              onConfirm(emails);
              modalProps.internalOnConfirm();
            }}
          />
        ),
      });
    },
    [triggerModal]
  );
}
