import { useLocation, useNavigate } from '@remix-run/react';
import { useMemo } from 'react';

import { EnumsGamePackVersion } from '@lp-lib/api-service-client/public';

import { useFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { GamePackList } from '../../../pages/GamePack';
import { apiService } from '../../../services/api-service';
import { GameLikeQueryType, type GamePack } from '../../../types/game';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { EditIcon } from '../../icons/EditIcon';
import { useMyOrganizationFeatureChecker } from '../../Organization';
import { useGameLikeWorkspace } from '../GameCenter';
import {
  CustomizableGamePackActionSheet,
  GamePackActionSheetButton,
} from '../GamePackActionSheet';
import { CreateCustomGameButton } from './Nav';
import { useUGCAnalytics } from './utils';

function GamePackCardActionSheet(props: { pack: GamePack }) {
  const [, setDeletingGamePack] = useGameLikeWorkspace('gamePack', 'delete');
  const navigate = useNavigate();
  const loccation = useLocation();

  return (
    <CustomizableGamePackActionSheet
      pack={props.pack}
      stopPropagation
      buttonClassName='invisible group-gamepack-card-hover:visible 
      w-7.5 h-7.5 absolute bottom-2 right-2'
      portal
    >
      {(onItemClick) => (
        <>
          <GamePackActionSheetButton
            onClick={() => {
              navigate(
                `/custom-games/${props.pack.id}/edit${loccation.search}`
              );
              onItemClick();
            }}
          >
            <EditIcon />
            <div className=''>Customize Game</div>
          </GamePackActionSheetButton>
          <GamePackActionSheetButton
            onClick={() => {
              setDeletingGamePack(props.pack);
              onItemClick();
            }}
            className={`text-red-002`}
          >
            <DeleteIcon />
            <div className=''>Delete Game</div>
          </GamePackActionSheetButton>
        </>
      )}
    </CustomizableGamePackActionSheet>
  );
}

export function CustomGamePackList() {
  const paginator = useMemo(
    () =>
      apiService.gamePack.getMyGamePacks(
        EnumsGamePackVersion.GamePackVersionV2
      ),
    []
  );
  const featureChecker = useMyOrganizationFeatureChecker();
  const analytics = useUGCAnalytics();
  const ugcEnabled = useFeatureQueryParam('game-pack-ugc');

  return (
    <div className='w-full flex-1 px-10 bg-game-library bg-w-full bg-no-repeat overflow-y-auto scrollbar'>
      <section className='w-full mt-10'>
        <header className='w-full flex items-start justify-between'>
          <div>
            <div className='text-white text-3.5xl font-bold'>
              My Custom Games
            </div>
            <div className='text-icon-gray'>
              Create, edit, and organize your personalized gaming experiences.
            </div>
          </div>
          {(featureChecker.hasUnlimitedOndGameUGCAccess() || ugcEnabled) && (
            <CreateCustomGameButton analytics={analytics} betaLabel />
          )}
        </header>
        <main>
          <GamePackList
            type={GameLikeQueryType.My}
            paginator={paginator}
            containerClassName='bg-transparent px-0 pt-0'
            showVersion={false}
            bottomAccessory={(p) => <GamePackCardActionSheet pack={p} />}
            badgeAccessory={() => <></>}
          />
        </main>
      </section>
    </div>
  );
}
