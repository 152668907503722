import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { LearnerProfilePage } from '../components/Training/Admin/Learner/LearnerProfile';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const [profile] = await tokenWithRedirect(
    async () =>
      Promise.all([(await apiService.learning.getLearnerProfile(id)).data]),
    action.request.url
  );

  return {
    profile,
  };
}

export function Component() {
  const data = useLoaderData<typeof clientLoader>();

  return <LearnerProfilePage {...data} />;
}
