import { useState } from 'react';
import { $path } from 'remix-routes';

import {
  type ModelsRoleplayEvaluation,
  type ModelsRoleplayEvaluationCriterion,
} from '@lp-lib/api-service-client/public';
import { type RoleplayBlock } from '@lp-lib/game';

import { uuidv4 } from '../../../../utils/common';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { DeleteIcon } from '../../../icons/DeleteIcon';

function EvaluationCriteriaModal(props: {
  criterion?: ModelsRoleplayEvaluationCriterion;
  onSave: (criterion: ModelsRoleplayEvaluationCriterion) => void;
  onCancel: () => void;
}) {
  const { criterion, onSave, onCancel } = props;
  const [name, setName] = useState(criterion?.name ?? '');
  const [prompt, setPrompt] = useState(criterion?.prompt ?? '');

  const handleSave = () => {
    onSave({
      id: criterion?.id ?? uuidv4(),
      name,
      prompt,
    });
  };

  return (
    <ModalWrapper
      containerClassName='w-160'
      innerClassName='rounded-xl'
      borderStyle='gray'
      onClose={onCancel}
    >
      <div className='w-full px-7.5 py-5 flex flex-col'>
        <header className='text-white flex flex-col gap-2 items-center'>
          <div className='text-2xl text-center'>
            {criterion ? 'Edit Evaluation Criteria' : 'Add Evaluation Criteria'}
          </div>
          <div className='w-3/4 text-center text-secondary text-sm'>
            Analyzes conversations by sending the transcript and custom prompt
            to the LLM for goal verification. The AI will return one of three
            results: success, failure, or unknown, along with a brief
            explanation of its decision.
          </div>
        </header>

        <div className='flex flex-col gap-1'>
          <label className='text-sm font-bold text-white'>Name</label>
          <input
            type='text'
            className='field'
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder='Deal Complete'
          />
        </div>

        <div className='flex flex-col gap-1'>
          <label className='text-sm font-bold text-white'>Prompt</label>
          <textarea
            className='field min-h-[120px] p-2'
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder='Evaluate if the conversation resulted in a successful deal'
          />
        </div>

        <footer className='mt-5 flex justify-center items-center gap-2'>
          <button
            type='button'
            className='btn-secondary w-30 h-10'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-30 h-10 mx-1 flex items-center justify-center gap-1'
            onClick={handleSave}
          >
            Save
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export function RoleplayEvaluationEditor(props: {
  block: RoleplayBlock;
  onChange: (evaluation: ModelsRoleplayEvaluation) => void;
}) {
  const { block, onChange } = props;
  const evaluation = block.fields.evaluation;
  const criteria = evaluation?.criteria ?? [];

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleChangeCriteria = (
    criteria: ModelsRoleplayEvaluationCriterion[]
  ) => {
    onChange({
      ...evaluation,
      criteria,
    });
  };

  const handleClickAdd = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <EvaluationCriteriaModal
          criterion={undefined}
          onSave={(criterion) => {
            const newCriteria = [...criteria, criterion];
            handleChangeCriteria(newCriteria);
            p.internalOnConfirm();
          }}
          onCancel={p.internalOnCancel}
        />
      ),
    });
  };

  const handleClickEdit = (criterion: ModelsRoleplayEvaluationCriterion) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <EvaluationCriteriaModal
          criterion={criterion}
          onSave={(updatedCriterion) => {
            const newCriteria = criteria.map((c) =>
              c.id === criterion.id ? updatedCriterion : c
            );
            handleChangeCriteria(newCriteria);
            p.internalOnConfirm();
          }}
          onCancel={p.internalOnCancel}
        />
      ),
    });
  };

  const handleDelete = async (id: string) => {
    const result = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <ConfirmCancelModalText className='text-2xl font-medium'>
          Are you sure you want to delete this evaluation criteria?
        </ConfirmCancelModalText>
      ),
      confirmBtnLabel: 'Delete',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'confirm',
    });
    if (result.result !== 'confirmed') return;

    const newCriteria = criteria.filter((c) => c.id !== id);
    handleChangeCriteria(newCriteria);
  };

  return (
    <div className='w-full flex flex-col gap-5 text-white'>
      <div>
        <div>
          <p className='text-base font-bold'>Evaluation Criteria</p>
          <p className='text-2xs text-secondary'>
            Define custom criteria to evaluate conversations against. You can
            find the evaluation results for each conversation in{' '}
            <a
              href={$path('/roleplay/histories', {
                'block-id': props.block.id,
              })}
              className='text-primary underline'
              target='_blank'
              rel='noreferrer'
            >
              the history tab
            </a>
            .
          </p>
        </div>
        {criteria.length > 0 && (
          <div className='mt-3 w-full flex flex-col gap-2'>
            {criteria.map((c) => (
              <div
                key={c.id}
                className='w-full bg-secondary p-2 rounded-lg flex justify-between items-center cursor-pointer gap-2 overflow-hidden'
                onClick={() => handleClickEdit(c)}
              >
                <div className='flex-1 flex flex-col overflow-hidden truncate'>
                  <span className='text-sms font-medium'>{c.name}</span>
                  <p className='text-xs text-secondary truncate'>{c.prompt}</p>
                </div>
                <button
                  type='button'
                  className='flex-none text-red-500'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(c.id);
                  }}
                >
                  <DeleteIcon className='w-4 h-4 fill-current' />
                </button>
              </div>
            ))}
          </div>
        )}
        <button
          type='button'
          className='mt-3 btn text-xs text-primary'
          onClick={handleClickAdd}
        >
          + Add Criteria
        </button>
      </div>
    </div>
  );
}
