import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useTitle } from 'react-use';

import { EnumsEventType } from '@lp-lib/api-service-client/public';

import { EventScheduleLive } from '../components/Event/Schedule/EventScheduleLive';
import { EventScheduleOnD } from '../components/Event/Schedule/EventScheduleOnD';
import { GamePackUtils } from '../components/Game/GamePack/utils';
import { PublicHomeLayoutV2 } from '../components/PublicHome/PublicHomeLayout';
import { PublicHomeProvider } from '../components/PublicHome/PublicHomeProvider';
import { useBootIntercom } from '../hooks/useBootIntercom';
import { useInstance } from '../hooks/useInstance';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { DateUtils } from '../utils/date';
import { tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { type } = action.params;
  const url = new URL(action.request.url);
  const packId = url.searchParams.get('game-pack-id');
  const startDate = url.searchParams.get('start-date');
  const timezone = url.searchParams.get('timezone');
  const backTo = url.searchParams.get('back-to');

  const pack = packId
    ? (await apiService.gamePack.getGamePackById(packId)).data.gamePack
    : null;

  const recommendedAttendees = await tokenWithRedirect(
    async () => (await apiService.event.getRecommendedAttendees()).data.results,
    action.request.url,
    { preferRedirect: 'login' }
  );

  const eventType =
    (pack && GamePackUtils.IsCohosted(pack)) ||
    (type && type === EnumsEventType.EventTypeLive)
      ? EnumsEventType.EventTypeLive
      : EnumsEventType.EventTypeOnd;

  return { eventType, pack, startDate, timezone, backTo, recommendedAttendees };
}

function Container() {
  useBootIntercom(useInstance(() => ['/events']));
  useTitle(makeTitle('Schedule an Event'));

  const { eventType, pack, startDate, timezone, backTo, recommendedAttendees } =
    useLoaderData<typeof clientLoader>();

  switch (eventType) {
    // NOTE(guoqiang): it's not used anymore.
    case EnumsEventType.EventTypeLive:
      return (
        <EventScheduleLive
          initData={{
            pack: pack,
            eventTime: DateUtils.ParseEventTime(startDate, timezone),
            attendees: [],
            hostShoutOut: '',
          }}
          backTo={backTo}
          recommendedAttendees={recommendedAttendees}
        />
      );
    case EnumsEventType.EventTypeOnd:
      return (
        <EventScheduleOnD
          initData={{
            pack,
            eventTime: DateUtils.ParseEventTime(startDate, timezone),
            attendees: [],
            durationMin: pack?.approximateDurationSeconds
              ? Math.ceil(pack.approximateDurationSeconds / 60)
              : 30,
            message: '',
          }}
          backTo={backTo}
          recommendedAttendees={recommendedAttendees}
        />
      );
    default:
      return null;
  }
}

export function Component() {
  return (
    <PublicHomeProvider>
      <PublicHomeLayoutV2 backgroundClassName='bg-black'>
        <Container />
      </PublicHomeLayoutV2>
    </PublicHomeProvider>
  );
}
