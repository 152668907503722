import { NavLink } from '@remix-run/react';
import { type ReactNode } from 'react';

import { useAppAnalytics } from '../../analytics/app/identifiable';
import { useMyOrganizationFeatureChecker } from '../Organization';
import { OrgSubscriptionUpgradeIcon } from '../Organization/OrgSubscription';
import { ScheduleTooltip } from './ScheduleTooltip';

export function HeaderLayout(
  props: {
    left: ReactNode;
    right: ReactNode;
    leftClassName?: string;
    rightClassName?: string;
  } & (
    | {
        fill: true;
        containerClassName?: string;
      }
    | {
        fill?: false;
      }
  )
): JSX.Element {
  if (!props.fill) {
    return (
      <>
        <div
          className={`absolute left-5 top-2 z-40 ${props.leftClassName ?? ''}`}
        >
          {props.left}
        </div>
        <div className={`absolute right-5 z-40 ${props.rightClassName ?? ''}`}>
          {props.right}
        </div>
      </>
    );
  }

  return (
    <div
      className={`w-full h-15 pl-5 pr-2 flex-shrink-0 bg-black border-secondary border-b flex items-center justify-between ${props.containerClassName}`}
    >
      <div
        className={`flex items-center gap-7.5 xl:gap-10 ${props.leftClassName}`}
      >
        {props.left}
      </div>

      <div className={`flex items-center gap-5 ${props.rightClassName}`}>
        {props.right}
      </div>
    </div>
  );
}

export function NavItem(props: {
  title: string;
  to: string;
  children?: React.ReactNode;
  onClick?: () => void;
  end?: boolean;
}) {
  const appAnalytics = useAppAnalytics();

  const handleClick = () => {
    appAnalytics.trackNavItemClicked(props.title, props.to);
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        `text-sm font-medium tracking-[1.25px] text-white border-b-2 ${
          isActive ? 'border-white' : 'border-transparent'
        }`
      }
      onClick={handleClick}
      end
    >
      {props.children || props.title}
    </NavLink>
  );
}

/**
 * Commonly used nav bar for host pages, like Minigames, Game Packs etc.
 */

export function HostNav(): JSX.Element {
  return (
    <div className='flex items-center gap-7.5 xl:gap-10'>
      <NavItem title='Minigames' to='/host/minigames'></NavItem>
      <NavItem title='Game Packs' to='/host/gamepacks'></NavItem>
    </div>
  );
}

/**
 * Commonly used nav bar for publicly accessible pages, like Home, My Stats etc.
 */
export function CommonNav(): JSX.Element {
  const programsAccess =
    useMyOrganizationFeatureChecker().hasAnyProgramAccess();
  return (
    <div className='h-full flex items-center gap-7.5 xl:gap-10'>
      <NavItem title='Home' to='/home'></NavItem>
      <ScheduleTooltip />
      <NavItem title='Programs' to='/programs'>
        <div className='flex items-center gap-1'>
          Programs
          {!programsAccess && <OrgSubscriptionUpgradeIcon />}
        </div>
      </NavItem>
    </div>
  );
}

export { NavItem as HeaderNavLink };
