import {
  type ClientLoaderFunctionArgs,
  Link,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from '@remix-run/react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useForm } from 'react-hook-form';
import { useTitle } from 'react-use';

import { type DtoHubSpotDeal } from '@lp-lib/api-service-client/public';

import { type Action, ActionSheet } from '../components/ActionSheet';
import { EditIcon } from '../components/icons/EditIcon';
import { XIcon } from '../components/icons/XIcon';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);

  const resp = await apiService.hubSpot.searchDeals({
    limit: Number(url.searchParams.get('limit') || 50),
    query: url.searchParams.get('query') || '',
  });

  return resp.data;
}

type ActionSheetKeys = 'setup-custom-product';

function HubSpotDeal(props: { deal: DtoHubSpotDeal; onEdit: () => void }) {
  const { deal } = props;
  const stage = deal.pipeline?.stages.find(
    (s) => s.id === deal.pipelineStageId
  );
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const closeDate = deal.closeDate
    ? utcToZonedTime(deal.closeDate, timezone)
    : null;
  const owner = deal.owner
    ? `${deal.owner.firstName} ${deal.owner.lastName}`
    : deal.ownerId;

  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  const actions: Action<ActionSheetKeys>[] = [
    {
      kind: 'button',
      key: 'setup-custom-product',
      icon: <EditIcon />,
      text: 'Setup Custom Product',
      onClick: () => {
        props.onEdit();
      },
    },
  ];

  return (
    <tr className='w-full h-10 text-sms hover:bg-lp-gray-002 odd:bg-lp-gray-001'>
      <td>
        <Link to={deal.link} target='_blank' className='underline'>
          {deal.name}
        </Link>
      </td>
      <td>
        {stage?.label ?? 'N/A'} ({deal.pipeline?.label ?? 'N/A'})
      </td>
      <td>{closeDate ? format(closeDate, 'MMM d, yyyy h:mm aa O') : 'N/A'}</td>
      <td>{owner}</td>
      <td>{currency.format(Number(deal.amount))}</td>
      <td>
        <ActionSheet<ActionSheetKeys> actions={actions} placement='left' />
      </td>
    </tr>
  );
}

function HubSpotDeals() {
  const { deals } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const query = params.get('query');
  const { register, handleSubmit, reset } = useForm<{ query: string }>({
    defaultValues: { query: query || '' },
  });

  const onSubmit = handleSubmit(async (data) => {
    params.set('query', data.query);
    navigate({ search: params.toString() });
  });

  const onClear = () => {
    reset();
    params.delete('query');
    navigate({ search: params.toString() });
  };

  return (
    <div className='w-full relative text-white'>
      <header className='flex justify-between items-center mb-10'>
        <h1 className='font-bold text-3xl'>HubSpot Deals</h1>
      </header>
      <form onSubmit={onSubmit}>
        <div className='flex items-center gap-2'>
          <div className='relative'>
            <input
              className='field mb-0 w-80 h-10'
              {...register('query')}
              placeholder='Search Deal ID / Name / Description'
            />
            {query && (
              <button
                type='button'
                className='absolute right-2 top-1/2 transform -translate-y-1/2'
                onClick={onClear}
              >
                <XIcon />
              </button>
            )}
          </div>
          <button type='submit' className='btn-primary w-30 h-10 text-sms'>
            Search
          </button>
        </div>
      </form>
      <table className='w-full'>
        <thead className='text-left h-12 text-base text-bold'>
          <tr>
            <th>Deal Name</th>
            <th>Deal Stage</th>
            <th>Close Date</th>
            <th>Owner</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {deals.map((deal) => (
            <HubSpotDeal
              key={deal.id}
              deal={deal}
              onEdit={() => {
                navigate(`./${deal.id}/customize`);
              }}
            />
          ))}
        </tbody>
      </table>
      <div className='text-center text-sms text-secondary mt-10'>
        We don't support pagination, refine the search query to find your deal.
      </div>
    </div>
  );
}

export function Component() {
  useTitle(makeTitle('HubSpot Deals'));

  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <HubSpotDeals />
    </AdminView>
  );
}
