import { getLogger } from '../../../logger/logger';

export class LMSNotifier {
  constructor(
    private win = window.parent,
    private logger = getLogger().scoped('lms-notifier')
  ) {}

  post(action: 'setCourseCompleted') {
    this.logger.info('posting message', { action });
    this.win.postMessage(
      JSON.stringify({
        action,
      }),
      '*'
    );
  }
}
