import { type Content, type Editor, mergeAttributes, Node } from '@tiptap/core';
import Placeholder from '@tiptap/extension-placeholder';
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';
import { useMemo } from 'react';
import Select from 'react-select';

import {
  EnumsFillInTheBlanksAnswerStyle,
  EnumsFillInTheBlanksSegmentType,
  type ModelsFillInTheBlanksSegment,
} from '@lp-lib/api-service-client/public';
import { type FillInTheBlanksBlock } from '@lp-lib/game';

import { uuidv4 } from '../../../../utils/common';
import { buildReactSelectStyles } from '../../../../utils/react-select';
import type { Option } from '../../../common/Utilities';
import { useTrainingSlideEditor } from '../../../Training/Editor/hooks';
import { type TrainingSlideEditorProps } from '../../../Training/Editor/types';
import { PersonalitySelect } from '../../../VoiceOver/PersonalitySelect';
import { FFriendlyEditableText } from '../../design/Editable';

function AnswerStyleSelect(props: {
  value?: EnumsFillInTheBlanksAnswerStyle | null;
  onChange: (value: EnumsFillInTheBlanksAnswerStyle) => void;
}) {
  const options = useMemo(() => {
    return [
      {
        label: 'Multiple Choice',
        value: EnumsFillInTheBlanksAnswerStyle.FillInTheBlanksAnswerStyleChoice,
      },
      {
        label: 'Type the Answer',
        value: EnumsFillInTheBlanksAnswerStyle.FillInTheBlanksAnswerStyleTyping,
      },
    ];
  }, []);

  const value = useMemo(() => {
    if (options.length === 0) return undefined;
    if (!props.value) return options[0];

    return options.find((o) => o.value === props.value);
  }, [props.value, options]);

  const styles = useMemo(
    () => buildReactSelectStyles<Option<EnumsFillInTheBlanksAnswerStyle>>(),
    []
  );

  return (
    <Select<Option<EnumsFillInTheBlanksAnswerStyle>, false>
      options={options}
      value={value}
      styles={styles}
      classNamePrefix='select-box-v2'
      isSearchable
      onChange={(v) => {
        if (!v) return;
        props.onChange(v.value);
      }}
    />
  );
}

const QuestionNode = Node.create({
  name: 'question',
  topNode: true,
  content: 'segment+',

  parseHTML() {
    return [{ tag: 'question' }];
  },
});

const SegmentNode = Node.create({
  name: 'segment',
  group: 'segment',
  content: 'text*',
  inline: true,
  selectable: true,
  draggable: false,

  addAttributes() {
    return {
      id: {
        default: uuidv4(),
      },
      type: {
        default: 'text', // 'text' | 'blank'
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'segment',
        getAttrs(node) {
          return {
            id: node.dataset.id,
            type: node.dataset.type,
          };
        },
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'segment',
      mergeAttributes(
        {
          'data-id': node.attrs.id,
          'data-type': node.attrs.type,
        },
        node.attrs.type === 'blank'
          ? {
              class: 'underline text-icon-gray',
            }
          : {},
        HTMLAttributes
      ),
      0,
    ];
  },
});

function segmentsToEditor(segments: ModelsFillInTheBlanksSegment[]): Content {
  if (segments.length === 0) {
    return null;
  }

  return {
    type: 'question',
    content: segments.map((segment) => ({
      type: 'segment',
      attrs: {
        id: segment.id,
        type: segment.type,
      },
      content: [{ type: 'text', text: segment.text }],
    })),
  };
}

function editorToSegments(editor: Editor): ModelsFillInTheBlanksSegment[] {
  const segments: ModelsFillInTheBlanksSegment[] = [];
  editor.state.doc.descendants((node) => {
    if (node.type.name === 'segment') {
      const text = node.textContent;
      if (!text) return;

      if (node.attrs.type === 'blank') {
        segments.push({
          id: uuidv4(),
          type: EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank,
          text,
        });
      } else {
        segments.push({
          id: uuidv4(),
          type: EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeText,
          text,
        });
      }
    }
  });

  return segments;
}

const Text = Node.create({
  name: 'text',
  group: 'inline',
  inline: true,
});

export function ChoiceButton(props: {
  backgroundColor: string;
  children: React.ReactNode;
}) {
  const { backgroundColor, children } = props;

  return (
    <div
      className='
        relative w-30 h-30 rounded-full
      '
      style={{
        backgroundColor,
        boxShadow: '20px -40px 40px 0px rgba(0, 0, 0, 0.50) inset',
      }}
    >
      <div
        className='absolute inset-0 rounded-full'
        style={{
          background:
            'linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,0.2) 100%)',
        }}
      ></div>
      <div
        className='absolute inset-0 bottom-[6px] rounded-full transition-all duration-300'
        style={{
          backgroundColor,
          boxShadow: '20px -40px 40px 0px rgba(0, 0, 0, 0.50) inset',
        }}
      ></div>
      <div
        className={`relative w-full h-full p-5 overflow-hidden flex items-center justify-center text-xs lg:text-base break-words font-bold text-center`}
      >
        {children}
      </div>
    </div>
  );
}

export function FillInTheBlanksBlockEditor(
  props: TrainingSlideEditorProps<FillInTheBlanksBlock>
) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const segments = props.block.fields.segments;

  const content = useMemo(() => segmentsToEditor(segments), [segments]);

  const blanks = segments.filter(
    (s) =>
      s.type === EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank
  );

  const editor = useEditor({
    extensions: [
      QuestionNode,
      SegmentNode,
      Text,
      Placeholder.configure({
        placeholder:
          'Type a sentence or phrase here. You’ll then select the word or words to hide.',
      }),
    ],
    editorProps: {
      attributes: {
        class:
          'w-full h-full appearance-none outline-none space-y-2 text-center leading-relaxed',
      },
    },
    content: content,
    onUpdate: ({ editor }) => {
      const segments = editorToSegments(editor);
      onChange('segments', segments);
    },
    onBlur: ({ editor }) => {
      const segments = editorToSegments(editor);
      onBlur('segments', segments);
    },
  });

  if (!editor) return null;

  const handleMarkAsBlank = () => {
    const { from, to } = editor.state.selection;
    const text = editor.state.doc.textBetween(from, to);

    editor
      .chain()
      .focus()
      .deleteSelection()
      .insertContent({
        type: 'segment',
        attrs: {
          id: uuidv4(),
          type: EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeBlank,
        },
        content: [
          {
            type: 'text',
            text,
          },
        ],
      })
      .run();
  };

  const handleUnmarkBlank = () => {
    const { from, to } = editor.state.selection;
    const text = editor.state.doc.textBetween(from, to);

    editor
      .chain()
      .focus()
      .deleteSelection()
      .insertContent({
        type: 'segment',
        attrs: {
          id: uuidv4(),
          type: EnumsFillInTheBlanksSegmentType.FillInTheBlanksSegmentTypeText,
        },
        content: [
          {
            type: 'text',
            text,
          },
        ],
      })
      .run();
  };

  return (
    <div className='relative w-full h-full min-h-0 flex flex-col'>
      <main className='w-full flex-1 min-h-0 px-10 py-5 flex flex-col gap-5 sm:gap-10'>
        <div className='text-center text-sms italic text-icon-gray'>
          Fill in the blanks
        </div>

        <div className='text-white flex flex-col gap-5'>
          <BubbleMenu
            editor={editor}
            shouldShow={({ editor }) => {
              return !editor.state.selection.empty;
            }}
          >
            {editor.isActive('segment', { type: 'blank' }) ? (
              <button
                type='button'
                className='btn-secondary px-3 py-1'
                onClick={handleUnmarkBlank}
              >
                Unmark Blank
              </button>
            ) : (
              <button
                type='button'
                className='btn-secondary px-3 py-1'
                onClick={handleMarkAsBlank}
                disabled={blanks.length >= 4}
              >
                Mark as Blank
              </button>
            )}
          </BubbleMenu>

          <EditorContent editor={editor} style={{}} />

          {segments.length > 0 && (
            <div className='text-sms italic text-icon-gray text-center'>
              Select the words (up to 4) the learner will fill in above
            </div>
          )}

          {block.fields.answerStyle ===
            EnumsFillInTheBlanksAnswerStyle.FillInTheBlanksAnswerStyleChoice &&
            segments.length > 0 && (
              <div className='flex flex-col gap-5'>
                <div className='flex flex-wrap gap-2 justify-center items-center'>
                  {blanks.map((blank) => (
                    <ChoiceButton key={blank.id} backgroundColor='#0029AE'>
                      <span className='text-[#81BCFF] cursor-not-allowed'>
                        {blank.text}
                      </span>
                    </ChoiceButton>
                  ))}

                  {Array.from({ length: Math.max(0, 4 - blanks.length) }).map(
                    (_, index) => (
                      <ChoiceButton
                        key={`wrong-${index}`}
                        backgroundColor='#383838'
                      >
                        <FFriendlyEditableText
                          value={block.fields.wrongAnswers[index] || ''}
                          placeholder='+ Option'
                          onBlur={(value) => {
                            const wrongAnswers = [...block.fields.wrongAnswers];
                            wrongAnswers[index] = value;
                            onChange('wrongAnswers', wrongAnswers);
                            onBlur('wrongAnswers', wrongAnswers);
                          }}
                          className='outline-none cursor-text whitespace-pre-wrap contenteditable-placeholder text-icon-gray'
                        />
                      </ChoiceButton>
                    )
                  )}
                </div>

                <div className='text-sms italic text-icon-gray text-center'>
                  The learner will choose from the words above
                </div>
              </div>
            )}
        </div>
      </main>
    </div>
  );
}

export function FillInTheBlanksBlockSidebarEditor(
  props: TrainingSlideEditorProps<FillInTheBlanksBlock>
) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full flex flex-col gap-5 text-white'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={props.block.fields.personalityId}
          isClearable
        />
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>Answer Style</p>
        <AnswerStyleSelect
          value={props.block.fields.answerStyle}
          onChange={(value) => {
            onChange('answerStyle', value);
            onBlur('answerStyle', value);
          }}
        />
      </label>
    </div>
  );
}
