export function HouseIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-5 h-5 fill-current'}
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M9.29289 1.29289L1.29289 9.29289C1.10536 9.48043 1 9.73478 1 10V18C1 18.5523 1.44772 19 2 19H7L7.11662 18.9933C7.61396 18.9355 8 18.5128 8 18V14H12V18C12 18.5523 12.4477 19 13 19H18C18.5523 19 19 18.5523 19 18V10C19 9.73478 18.8946 9.48043 18.7071 9.29289L10.7071 1.29289C10.3166 0.902369 9.68342 0.902369 9.29289 1.29289ZM10 3.415L17 10.415V17H14V13C14 12.4477 13.5523 12 13 12H7L6.88338 12.0067C6.38604 12.0645 6 12.4872 6 13V17H3V10.414L10 3.415Z' />
    </svg>
  );
}
