import { useMemo, useState } from 'react';
import Select from 'react-select';

import { EnumsRoleplayVoice } from '@lp-lib/api-service-client/public';

import { buildReactSelectStyles } from '../../../../utils/react-select';
import { type Option } from '../../../common/Utilities';
import { PauseIcon } from '../../../icons/PauseIcon';
import { PlayIcon } from '../../../icons/PlayIcon';

export function RoleplayVoiceSelect(props: {
  value: EnumsRoleplayVoice | null;
  onChange: (value: EnumsRoleplayVoice | null) => void;
}) {
  const [playingVoice, setPlayingVoice] = useState<EnumsRoleplayVoice | null>(
    null
  );

  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<EnumsRoleplayVoice | null>>({
        override: {
          control: {
            height: '40px',
          },
        },
      }),
    []
  );

  const options = useMemo(() => {
    return Object.values(EnumsRoleplayVoice).map((value) => ({
      label: value,
      value,
    }));
  }, []);

  return (
    <>
      {playingVoice && (
        <audio
          src={`https://cdn.openai.com/API/voice-previews/${playingVoice}.flac`}
          className='hidden'
          autoPlay
          onEnded={() => setPlayingVoice(null)}
        />
      )}

      <Select<Option<EnumsRoleplayVoice | null>>
        className='w-full'
        classNamePrefix='select-box-v2'
        styles={styles}
        placeholder='Select a voice'
        options={options}
        value={props.value ? { label: props.value, value: props.value } : null}
        onChange={(value) => props.onChange(value?.value || null)}
        formatOptionLabel={(opt, meta) => (
          <div className='flex justify-between items-center'>
            <p className='text-white'>{opt.label}</p>
            {meta.context === 'menu' &&
              (playingVoice === opt.value ? (
                <button
                  type='button'
                  className='btn btn-icon'
                  onClick={(e) => {
                    e.stopPropagation();
                    setPlayingVoice(null);
                  }}
                >
                  <PauseIcon className='w-4 h-4 fill-current' />
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-icon'
                  onClick={(e) => {
                    e.stopPropagation();
                    if (opt.value) {
                      setPlayingVoice(opt.value);
                    }
                  }}
                >
                  <PlayIcon className='w-4 h-4 fill-current' />
                </button>
              ))}
          </div>
        )}
      />
    </>
  );
}
