export function DrawToWinBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      fill='none'
      viewBox='0 0 15 14'
    >
      <path
        fill='#8C6FFF'
        d='M12.778 12.012c-.056-.112-.126-.168-.168-.21-.182-.125-.308-.154-.392-.182l-.28-.084-.56-.154-1.12-.294c-.364-.098-.742-.196-1.092-.295-.28-.084-.56-.168-.798-.28.056-.014.112-.042.168-.056.336-.097.7-.182 1.078-.252.742-.14 1.497-.252 2.24-.35a.34.34 0 00.294-.364.322.322 0 00-.364-.294c-.77.084-1.526.168-2.296.294-.392.07-.77.14-1.162.252-.21.07-.392.112-.644.266a.975.975 0 00-.21.21c-.07.098-.126.322-.056.462.112.28.252.336.35.406.42.252.77.35 1.162.476.378.125.742.224 1.106.322l1.092.322.056.014c-.295.056-.603.098-.91.14-.743.084-1.498.14-2.255.182-.938.042-1.89.056-2.828.042l-1.385 1.05c1.428.056 2.855.015 4.284-.084.77-.056 1.54-.14 2.31-.252.392-.056.77-.126 1.162-.21.196-.042.392-.098.602-.168a1.98 1.98 0 00.35-.168c.07-.056.154-.084.28-.308a.468.468 0 00.042-.21.528.528 0 00-.056-.223zM5.736 10.948L9.278 3.29l-.896-.42-3.458 7.476a.427.427 0 00-.014.294.414.414 0 00.196.21 1.085 1.085 0 01-.294.322l-.868.658-1.148-.532-.056-1.078c0-.14.028-.28.07-.42a.32.32 0 00.154.042.509.509 0 00.14-.028.325.325 0 00.21-.196l3.472-7.475-.895-.42-3.557 7.643c-.126.28-.182.588-.168.882l.168 2.968c0 .112.07.21.168.252a.402.402 0 00.126.028c.056 0 .126-.014.168-.056l2.366-1.792a1.86 1.86 0 00.574-.7zM9.698 2.394a.819.819 0 00.028-.63.803.803 0 00-.42-.462L7.374.42a.807.807 0 00-1.078.392l-.238.518L9.46 2.912l.238-.518z'
      ></path>
      <path
        fill='#8C6FFF'
        d='M5.9 1.719l3.216 1.5-3.75 8.044-3.141-1.292 3.674-8.252z'
      ></path>
    </svg>
  );
}
