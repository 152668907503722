import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';

import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { XIcon } from '../../../icons/XIcon';
import {
  StackMultiSelect,
  type StackOption,
} from '../Components/StackMultiSelect';

interface AddStacksToGroupModalProps {
  onClose: () => void;
  onConfirm: (stackIds: string[]) => void;
  existingStackIds: string[];
}

export function AddStacksToGroupModal({
  onClose,
  onConfirm,
  existingStackIds,
}: AddStacksToGroupModalProps) {
  const [selectedStacks, setSelectedStacks] = useState<StackOption[]>([]);
  const stacksListRef = useRef<HTMLDivElement>(null);

  // Auto-scroll to the latest stack when added
  useEffect(() => {
    if (selectedStacks.length > 0) {
      const container = stacksListRef.current;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }
  }, [selectedStacks.length]);

  const handleConfirm = () => {
    if (selectedStacks.length > 0) {
      onConfirm(selectedStacks.map((stack) => stack.value));
    }
  };

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[800px] h-auto'
      innerClassName='bg-main-layer'
    >
      <div className='px-8 pt-8 pb-8 text-white flex flex-col gap-6'>
        <div className='text-2xl font-bold'>Add Stacks to Group</div>

        {selectedStacks.length > 0 && (
          <div className='rounded-lg overflow-hidden'>
            <div
              ref={stacksListRef}
              className='max-h-64 overflow-y-auto flex flex-col gap-2.5'
            >
              {selectedStacks.map((stack) => (
                <div
                  key={stack.value}
                  className='flex items-center justify-between p-1.5 bg-lp-gray-009 rounded-lg'
                >
                  <div className='flex items-center gap-3'>
                    <div className='w-19 h-10 rounded-lg bg-gray-700 overflow-hidden flex items-center justify-center'>
                      <span className='text-2xl'>📚</span>
                    </div>
                    <span className='text-white font-bold text-sms'>
                      {stack.stack.name}
                    </span>
                  </div>
                  <div className='flex items-center gap-4'>
                    <span className='text-white text-left text-sms'>
                      {format(new Date(stack.stack.createdAt), 'MM/dd/yyyy')}
                    </span>
                    <button
                      type='button'
                      onClick={() =>
                        setSelectedStacks((prev) =>
                          prev.filter((s) => s.value !== stack.value)
                        )
                      }
                      className='flex items-center justify-center pr-3'
                    >
                      <XIcon className='w-4 h-4 fill-current text-white' />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div>
          <div className='text-sms font-medium text-icon-gray mb-2'>
            Select stacks to add:
          </div>
          <StackMultiSelect
            options={selectedStacks}
            onChange={setSelectedStacks}
            placeholder='Search and select stacks'
            disabled={false}
            excludeStackIds={existingStackIds}
          />
        </div>

        <div className='flex justify-center gap-4'>
          <button
            type='button'
            onClick={onClose}
            className='px-6 py-2 rounded-lg transition-colors btn btn-secondary'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={handleConfirm}
            disabled={selectedStacks.length === 0}
            className={`px-6 py-2 btn btn-primary ${
              selectedStacks.length > 0
                ? 'bg-cyan-500 text-white hover:bg-cyan-600'
                : 'bg-gray-600 text-gray-300 cursor-not-allowed'
            }`}
          >
            Add
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
