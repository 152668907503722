import { Link, useRevalidator } from '@remix-run/react';
import { useState } from 'react';
import { $path } from 'remix-routes';
import useSWRMutation from 'swr/mutation';

import {
  type DtoJoinGroupsRequest,
  type DtoLearnerProfileResponse,
  type DtoLearningEnrollment,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { getLogger } from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { InlineLoadingSpinner } from '../../../MediaSearch/MediaSearch';
import { getRandomAnimatedAvatar } from '../../../Participant/avatars';
import {
  LearningGroupMultiSelect,
  type LearningGroupOption,
} from '../Components/LearningGroupMultiSelect';
import { PageHeader } from '../Components/PageHeader';
import { EnrollmentRowItem } from './LearnerEnrollments';
import { MembershipRowItem } from './LearnerMemberships';

const logger = getLogger().scoped('learner-profile');

interface LearnerProfilePageProps {
  profile: DtoLearnerProfileResponse;
}

export function LearnerProfilePage({ profile }: LearnerProfilePageProps) {
  return (
    <div className='flex flex-col w-full text-white max-w-screen-2xl mx-auto relative'>
      <div className='w-full px-4 md:px-8 pt-4 md:pt-8 pb-1'>
        <PageHeader
          title='Members'
          path={'/learning/admin/learners?tab=members'}
        />
      </div>
      <div className='pt-4 pb-10 space-y-20'>
        <LearnerOverview profile={profile} />
        <EnrollmentsSummary
          profileUid={profile.uid}
          enrollmentsSummary={profile.enrollmentsSummary}
        />
        <MembershipsSummary
          profileUid={profile.uid}
          membershipsSummary={profile.membershipsSummary}
        />
      </div>
    </div>
  );
}

function LearnerOverview({ profile }: LearnerProfilePageProps) {
  return (
    <div className='w-full px-4 md:px-8 flex items-center justify-between gap-4'>
      <div className='flex-1 flex items-center gap-4'>
        <div className='rounded-full w-16 h-16 bg-secondary overflow-hidden'>
          <video
            src={getRandomAnimatedAvatar(profile.uid)}
            className='w-full h-full object-cover'
            muted
            autoPlay
            loop
            controls={false}
          />
        </div>
        <div className='space-y-1'>
          <div className='font-bold text-white text-2xl'>{profile.name}</div>
          <div className='text-icon-gray text-sms'>{profile.email}</div>
        </div>
      </div>

      {profile.enrollmentsSummary && (
        <div className='flex-none flex items-center gap-5'>
          <div className='w-16 flex flex-col items-center gap-1 text-white text-center'>
            <div className='font-bold text-2xl'>
              {profile.enrollmentsSummary.activeCount}
            </div>
            <div className='text-[11px]'>Active</div>
          </div>
          <div className='w-16 flex flex-col items-center gap-1 text-white text-center'>
            <div className='font-bold text-2xl'>
              {profile.enrollmentsSummary.completedCount}
            </div>
            <div className='text-[11px]'>Completed</div>
          </div>
          <div className='w-16 flex flex-col items-center gap-1 text-white text-center'>
            <div className='font-bold text-2xl'>
              {profile.enrollmentsSummary.totalCount}
            </div>
            <div className='text-[11px]'>All Courses</div>
          </div>
        </div>
      )}
    </div>
  );
}

function EnrollmentsSummary(props: {
  profileUid: string;
  enrollmentsSummary: DtoLearnerProfileResponse['enrollmentsSummary'];
}) {
  const revalidator = useRevalidator();
  const handleUnassign = useLiveCallback(
    async (enrollment: DtoLearningEnrollment) => {
      try {
        if (enrollment.type === 'stack') {
          await apiService.learning.unassignMemberFromStack(
            enrollment.objectId,
            enrollment.uid
          );
        } else {
          await apiService.learning.unassignMemberFromCourse(
            enrollment.objectId,
            enrollment.uid
          );
        }
        revalidator.revalidate();
      } catch (error) {
        logger.error('failed to unenroll member ', error, {
          targetObjectId: enrollment.objectId,
          targetObjectType: enrollment.type,
          targetUserId: enrollment.uid,
        });
      }
    }
  );

  if (!props.enrollmentsSummary) {
    return null;
  }

  return (
    <div className='w-full px-4 md:px-8'>
      <div className='pb-6 flex items-center justify-between'>
        <div className='font-bold text-2xl'>
          Course Assignments ({props.enrollmentsSummary.totalCount})
        </div>
      </div>

      <div className='w-full'>
        {!props.enrollmentsSummary.enrollments ||
        props.enrollmentsSummary.enrollments.length === 0 ? (
          <div className='w-full h-20 flex items-center justify-center text-center text-sm text-secondary'>
            No enrollments found.
          </div>
        ) : (
          <>
            <div className='flex flex-col mt-4 text-gray-300 gap-2.5'>
              {props.enrollmentsSummary.enrollments.map((e) => (
                <EnrollmentRowItem
                  key={e.id}
                  enrollment={e}
                  onUnassign={() => handleUnassign(e)}
                />
              ))}
            </div>

            {props.enrollmentsSummary.totalCount >
              props.enrollmentsSummary.enrollments.length && (
              <div className='w-full mt-3 flex justify-end'>
                <Link
                  to={$path('/learning/admin/learners/:id/enrollments', {
                    id: props.profileUid,
                  })}
                >
                  <button
                    type='button'
                    className='btn flex justify-center items-center text-primary gap-1 text-sm hover:bg-light-gray px-2 py-1 rounded transition-colors'
                  >
                    View All
                  </button>
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function MembershipsSummary(props: {
  profileUid: string;
  membershipsSummary: DtoLearnerProfileResponse['membershipsSummary'];
}) {
  const revalidator = useRevalidator();
  const handleRemoveMember = useLiveCallback(
    async (groupId: string, userId: string) => {
      try {
        await apiService.learning.removeMemberFromGroup(groupId, userId);
        revalidator.revalidate();
      } catch (error) {
        logger.error('failed to remove member', error, {
          targetGroupId: groupId,
          targetUserId: userId,
        });
      }
    }
  );

  if (!props.membershipsSummary) {
    return null;
  }

  return (
    <div className='w-full px-4 md:px-8'>
      <div className='pb-6 flex items-center justify-between'>
        <div className='font-bold text-2xl'>
          Group Memberships ({props.membershipsSummary.totalCount})
        </div>
        <AddToGroupButton profileUid={props.profileUid} />
      </div>

      <div className='w-full'>
        {!props.membershipsSummary.memberships ||
        props.membershipsSummary.memberships.length === 0 ? (
          <div className='w-full h-20 flex items-center justify-center text-center text-sm text-secondary'>
            No memberships found.
          </div>
        ) : (
          <>
            <div className='hidden md:flex w-full px-4 py-2 items-center text-gray-400 text-xs uppercase font-medium'>
              <div className='flex-1'>Name</div>
              <div className='flex-1'>Assigned On</div>
              <div className='flex-1'>Added By</div>
              <div className='w-12'></div>
            </div>

            <div className='flex flex-col mt-4 text-gray-300 gap-2.5'>
              {props.membershipsSummary.memberships.map((m) => (
                <MembershipRowItem
                  key={m.id}
                  membership={m}
                  onRemove={() => handleRemoveMember(m.groupId, m.uid)}
                />
              ))}
            </div>

            {props.membershipsSummary.totalCount >
              props.membershipsSummary.memberships.length && (
              <div className='w-full mt-3 flex justify-end'>
                <Link
                  to={$path('/learning/admin/learners/:id/memberships', {
                    id: props.profileUid,
                  })}
                >
                  <button
                    type='button'
                    className='btn flex justify-center items-center text-primary gap-1 text-sm hover:bg-light-gray px-2 py-1 rounded transition-colors'
                  >
                    View All
                  </button>
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function AddToGroupButton(props: { profileUid: string }) {
  const { profileUid } = props;
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const handleAddToGroupClick = useLiveCallback(() => {
    return triggerModal({
      kind: 'custom',
      containerClassName: 'bg-lp-black-002',
      element: (props) => (
        <AddToGroupModal
          profileUid={profileUid}
          onCancel={props.internalOnCancel}
          onConfirm={props.internalOnConfirm}
        />
      ),
    });
  });

  return (
    <button
      type='button'
      className='btn-secondary h-10 w-40'
      onClick={handleAddToGroupClick}
    >
      Add to Group
    </button>
  );
}

function AddToGroupModal(props: {
  profileUid: string;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const revalidator = useRevalidator();
  const [groupOptions, setGroupOptions] = useState<LearningGroupOption[]>([]);
  const { trigger, isMutating } = useSWRMutation(
    '/api/learning/groups/join',
    async (_, { arg }: { arg: { req: DtoJoinGroupsRequest } }) => {
      return apiService.learning.joinGroups(arg.req);
    }
  );
  const handleAssign = useLiveCallback(async () => {
    const req: DtoJoinGroupsRequest = {
      uids: [props.profileUid],
      groupIds: groupOptions.map((g) => g.value),
    };
    await trigger({ req });
    revalidator.revalidate();
    props.onConfirm();
  });

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[600px] h-auto'
      innerClassName='bg-main-layer flex flex-col'
    >
      <div className='px-8 pt-8 pb-8 text-white flex flex-col gap-6'>
        <div className='text-2xl font-bold text-center'>Add to Groups</div>

        <div>
          <LearningGroupMultiSelect
            options={groupOptions}
            onChange={setGroupOptions}
            placeholder='Enter or search group names...'
          />
        </div>

        <div className='flex justify-center gap-4 mt-4'>
          <button
            type='button'
            onClick={props.onCancel}
            className='btn btn-secondary px-6 py-2'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={handleAssign}
            className='btn btn-primary px-6 py-2'
            disabled={groupOptions.length === 0 || isMutating}
          >
            {isMutating ? (
              <InlineLoadingSpinner isUploading text='Assigning' />
            ) : (
              'Assign'
            )}
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
