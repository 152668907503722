import React, {
  type ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react';
import { proxy } from 'valtio';

import { type Logger } from '@lp-lib/logger-base';

import { useIsCoordinator } from '../../hooks/useMyInstance';
import { getQueryParam } from '../../hooks/useQueryParam';
import logger from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { type EventType } from '../../services/api-service/event.api';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import {
  type FirebaseService,
  FirebaseValueHandle,
  useFirebaseContext,
  useIsFirebaseConnected,
} from '../Firebase';

export type VenueTrimmedEvent = {
  id: string;
  type: EventType;
  orgId?: string | null;
  orgName?: string | null;
  typeformOrgName?: string | null;
  startAt: string;
  endAt: string;
  timezone: string;
  hostUid?: string | null;
  hostName?: string | null;
  vipOnStage: boolean;
};

type State = {
  event: Nullable<VenueTrimmedEvent>;
};

function makeEventHandle(
  svc: FirebaseService,
  venueId: string
): FirebaseValueHandle<Nullable<VenueTrimmedEvent>> {
  return new FirebaseValueHandle(
    svc.prefixedSafeRef(`venues/${venueId}/event`)
  );
}

class VenueEventAPI {
  private _state;
  constructor(
    venueId: string,
    svc: FirebaseService,
    private log: Logger,
    private handle = makeEventHandle(svc, venueId)
  ) {
    this._state = markSnapshottable(proxy<State>(this.initialState()));
  }

  async init(eventId: string, typeformOrgName?: string) {
    try {
      const resp = await apiService.event.getPublicEvent(eventId);
      const event = resp.data.event;
      this._state.event = {
        id: event.id,
        type: event.type,
        orgId: event.orgId ?? null,
        orgName: event.orgName ?? null,
        typeformOrgName: typeformOrgName ?? null,
        startAt: event.startAt,
        endAt: event.endAt,
        timezone: event.timezone,
        hostUid: event.hostUid ?? null,
        hostName: event.hostName ?? null,
        vipOnStage: event.vipOnStage,
      };
      this.log.info('init venue event', this._state.event);
      await this.handle.set(this._state.event as never);
    } catch (error) {
      this.log.error('Failed to init event', error);
    }
  }

  get state(): Readonly<ValtioSnapshottable<State>> {
    return this._state;
  }

  on(): void {
    this.handle.on((val) => {
      if (val === null) {
        this._state.event = null;
      } else {
        ValtioUtils.set(this._state, 'event', val);
      }
    });
  }

  off(): void {
    this.handle.off();
  }

  async clear(): Promise<void> {
    await this.handle.remove();
  }

  private initialState(): State {
    return {
      event: null,
    };
  }
}

type Context = {
  api: VenueEventAPI;
};

const context = React.createContext<Context | null>(null);

function useVenueEventContext(): Context {
  const ctx = useContext(context);
  if (!ctx) throw new Error('VenueEventContext is not in the tree!');
  return ctx;
}

function useVenueEventAPI(): Context['api'] {
  return useVenueEventContext().api;
}

export function useInitVenueEvent(eventId = getQueryParam('event-id')) {
  const api = useVenueEventAPI();
  const isCoordinator = useIsCoordinator();
  useLayoutEffect(() => {
    if (!eventId || !isCoordinator) return;
    api.init(eventId, getQueryParam('organization') ?? undefined);
  }, [api, eventId, isCoordinator]);
}

export function useClearVenueEvent() {
  const api = useVenueEventAPI();
  return useCallback(() => api.clear(), [api]);
}

export function useSubscribeVenueEvent() {
  const api = useVenueEventAPI();
  const firebaseConnected = useIsFirebaseConnected();
  useLayoutEffect(() => {
    if (!firebaseConnected) return;
    api.on();
    return () => api.off();
  }, [api, firebaseConnected]);
}

export function useVenueEvent(): Nullable<VenueTrimmedEvent> {
  const api = useVenueEventAPI();
  return useSnapshot(api.state).event;
}

export function VenueEventProvider(props: {
  venueId: string;
  children?: ReactNode;
}): JSX.Element | null {
  const { venueId } = props;
  const { svc } = useFirebaseContext();

  const ctx = useMemo(() => {
    return {
      api: new VenueEventAPI(venueId, svc, logger.scoped('venue-event')),
    };
  }, [svc, venueId]);

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}
