import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils/assets';

export function JeopardyBackground() {
  return (
    <img
      src={getStaticAssetPath('images/jeopardy/background.png')}
      className='w-full h-full object-cover'
      alt=''
    />
  );
}
