import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useTitle } from 'react-use';

import { EnumsGamePackVersion } from '@lp-lib/api-service-client/public';

import {
  CustomGamePackLayout,
  CustomGamePackStarter,
} from '../components/Game/UGC';
import { useUser } from '../components/UserContext';
import { getFeatureQueryParam } from '../hooks/useFeatureQueryParam';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);

  const id = url.searchParams.get('template-id');

  if (!id) throw new Response('Not Found', { status: 404 });

  const resp = await tokenWithRedirect(
    () =>
      apiService.gamePack.getGamePackById(id, {
        blocks: true,
        brands: true,
      }),
    action.request.url
  );

  const pack = resp.data.gamePack;

  if (
    !pack.isUGCTemplate ||
    pack.version !== EnumsGamePackVersion.GamePackVersionV2
  ) {
    throw new Response('Invalid Game Pack Template', { status: 400 });
  }

  return { template: pack };
}

export function Component() {
  const { template } = useLoaderData<typeof clientLoader>();
  const user = useUser();
  const name = user.organizer?.firstName || user.username;
  const gameName = template.name.replace(/custom/i, '').trim();
  const defaultName = name ? `${name}'s ${gameName}` : template.name;
  useTitle(makeTitle(`Create Custom ${defaultName}`));

  return (
    <CustomGamePackLayout useBackground>
      <CustomGamePackStarter
        template={template}
        defaultName={defaultName}
        enableAI={getFeatureQueryParam('game-pack-ugc-ai')}
      />
    </CustomGamePackLayout>
  );
}
