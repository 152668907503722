import isEqual from 'lodash/isEqual';
import pluralize from 'pluralize';
import React, {
  type CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLatest } from 'react-use';

import { type Block, BlockType } from '@lp-lib/game';

import { usePostGameAnalytics } from '../../analytics/postGame';
import { useOnDGameAnalytics } from '../../analytics/useOndGameAnalytics';
import {
  type Action,
  type ActionButton,
  type ActionNoop,
  ActionSheet,
} from '../../components/ActionSheet';
import { RenameTeam } from '../../components/AllTeams';
import { BrandUtils } from '../../components/Brand/utils';
import { CoordinatorController } from '../../components/Game/Blocks/Common/CoordinatorController/CoordinatorController';
import { AutoProgressingIndicator } from '../../components/Game/Blocks/Common/GamePlay/AutoProgressingIndicator';
import { BlockKnifeUtils } from '../../components/Game/Blocks/Shared';
import { useGameHostingCoordinator } from '../../components/Game/GameHostingProvider';
import { useOpenGamePackDetailModal } from '../../components/Game/GamePack';
import {
  useFetchGameSessionGamePack,
  useGameSessionBlock,
  useGameSessionBlockId,
  useGetOndGameCurrentPlaybackItem,
  useIsLiveGamePlay,
  useOndGameConfiguredJump,
  useOndGameCurrentPlaybackItem,
  useOndGameNextPlaybackItem,
  useOndGameState,
  useOndWaitMode,
  useOndWaitModeInfo,
} from '../../components/Game/hooks';
import { ondWaitEnd } from '../../components/Game/OndPhaseRunner';
import { configureJump } from '../../components/Game/OndPhaseRunner/OndPhaseRunner';
import { type OndPlaybackJump } from '../../components/Game/OndPhaseRunner/OndPhaseRunnerTypes';
import {
  type PlaybackDesc,
  type PlaybackDescItem,
} from '../../components/Game/Playback/intoPlayback';
import {
  getPlaybackGamesOverview,
  usePlaybackDesc,
} from '../../components/Game/Playback/PlaybackInfoProvider';
import { PreGameReadyUpWidget } from '../../components/Game/PreGame/PreGameReadyUpWidget';
import { useShowPreGame } from '../../components/Game/PreGame/Provider';
import { glAppend } from '../../components/GameLog/GameLogComponents';
import { Header as VenueHeader } from '../../components/Header';
import { HomeNavigator } from '../../components/Header/VenueHeader';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DoubleRightArrow,
} from '../../components/icons/Arrows';
import { BlockIconMap } from '../../components/icons/Block';
import { EditIcon } from '../../components/icons/EditIcon';
import { ErrorIcon } from '../../components/icons/ErrorIcon';
import { LeaveIcon } from '../../components/icons/LeaveIcon';
import { PauseIcon } from '../../components/icons/PauseIcon';
import { RefreshIcon } from '../../components/icons/RefreshIcon';
import { ReplaceIcon } from '../../components/icons/ReplaceIcon';
import { FilledSquareIcon } from '../../components/icons/SquareIcon';
import {
  LayoutAnchor,
  useComposedLayoutAnchor,
} from '../../components/LayoutAnchors/LayoutAnchors';
import { LobbyUpgradeCallout } from '../../components/Lobby/LobbyUpgradeCallout';
import {
  useOndGameUIControl,
  useOndGameUIControlState,
} from '../../components/OnDGameUIControl';
import {
  usePersistentPointsBadgeScale,
  usePersistentPointsScore,
} from '../../components/PersistentPoints/Provider';
import {
  useAmICohost,
  useMyTeamId,
  useNumOfParticipants,
} from '../../components/Player';
import {
  useStreamSessionElapsedTimeMs,
  useStreamSessionId,
} from '../../components/Session';
import {
  useIsTeamsOnTop,
  useLeaveTeamWithConfirm,
  useTeamColor,
  useTeamWithStaff,
} from '../../components/TeamAPI/TeamV1';
import { useTownhallShowTeam } from '../../components/Townhall';
import {
  useMyClientId,
  useMyClientType,
} from '../../components/Venue/VenuePlaygroundProvider';
import { useVenueId } from '../../components/Venue/VenueProvider';
import {
  getFeatureQueryParam,
  useFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useIsController, useIsCoordinator } from '../../hooks/useMyInstance';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { ClientTypeUtils, SessionMode, type TeamV0 } from '../../types';
import { MediaUtils } from '../../utils/media';
import { TimeUtils } from '../../utils/time';

function GameInfo(): JSX.Element | null {
  const pack = useFetchGameSessionGamePack();
  const cover = pack?.cover;
  const coverSrc = MediaUtils.PickMediaUrl(cover);

  const openGamePackDetailModal = useOpenGamePackDetailModal();

  const indicatorMeasurementRef = useComposedLayoutAnchor(
    'ond-gameplay-progress-indicator'
  );

  const showGameDetail = () => {
    if (!pack) return;
    openGamePackDetailModal(pack);
  };

  return (
    <div className='h-full bg-gray-400 flex-grow relative group'>
      {coverSrc ? (
        <div ref={indicatorMeasurementRef} className='w-full h-full'>
          <img
            src={coverSrc}
            alt='cover'
            className='object-cover w-full h-full'
          />
        </div>
      ) : (
        <div className='w-full h-full flex items-center justify-center bg-secondary text-[9px] font-light text-white'>
          {pack ? 'No Game Cover' : 'No Game Loaded'}
        </div>
      )}

      {pack && (
        <div
          className={`
            w-full h-full hidden group-hover:flex
            items-center justify-center absolute inset-0 z-5
            bg-black bg-opacity-60 text-3xs font-light text-white cursor-pointer
          `}
          onClick={showGameDetail}
        >
          Game Details
        </div>
      )}
    </div>
  );
}

function PointsBadge(): JSX.Element | null {
  const points = usePersistentPointsScore();
  const scale = usePersistentPointsBadgeScale();
  const twScaleProps = {
    '--tw-scale-x': scale === null ? 1 : scale,
    '--tw-scale-y': scale === null ? 1 : scale,
  };
  return (
    <div className={`w-full h-full relative`}>
      <LayoutAnchor
        id='points-badge-venue-header'
        className={`
          absolute w-full h-full pointer-events-off
        `}
      />
      <div
        className={`
        w-full h-full flex justify-start items-center
        font-cairo text-tertiary
      `}
      >
        <div
          className='
          font-extrabold text-lg leading-5
          transition-transform duration-300 transform mr-1
      '
          style={{ ...twScaleProps } as CSSProperties}
        >
          {Intl.NumberFormat('en-US', {
            notation: 'compact',
            maximumSignificantDigits: 3,
          }).format(points)}
        </div>
        <div className='text-3xs font-bold mt-0.5 tracking-wide'>Points</div>
      </div>
    </div>
  );
}

function TeamInfo(props: {
  gamePackDetailsHidden: boolean;
  team: TeamV0 | null;
  setRenaming: () => void;
}): JSX.Element {
  const { team } = props;
  const [menuOpened, setMenuOpened] = useState(false);
  const teamColor = useTeamColor(team?.id);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    setMenuOpened(false);
  });

  const width = props.gamePackDetailsHidden ? 'w-full' : 'w-30';

  return (
    <div
      className={`${width} h-full flex flex-col items-start justify-center px-2`}
    >
      <div
        className='w-full text-sms font-bold truncate text-white text-shadow'
        style={{ color: teamColor }}
      >
        {team?.name ?? 'No Team Joined'}
      </div>
      <div className='w-full flex items-center justify-between'>
        <PointsBadge />
        <div
          ref={ref}
          className='w-5 h-full text-white cursor-pointer flex items-center justify-end flex-shrink-0'
          onClick={() => setMenuOpened(!menuOpened)}
        >
          {menuOpened ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
        {ref.current && (
          <Menu trigger={ref.current} setRenaming={props.setRenaming} />
        )}
      </div>
    </div>
  );
}

type MenuKeys =
  | 'renameTeam'
  | 'leaveVenue'
  | 'pauseGame'
  | 'resetGame'
  | 'transferGame'
  | 'switchGame';

function useEditTeamNameAction(
  setRenaming: () => void
): ActionButton<MenuKeys> | ActionNoop {
  return useMemo(() => {
    return {
      kind: 'button',
      key: 'renameTeam',
      icon: <EditIcon />,
      text: 'Rename Team',
      onClick: setRenaming,
    };
  }, [setRenaming]);
}

function useLeaveVenueAction(): ActionButton<MenuKeys> {
  const leaveTeam = useLeaveTeamWithConfirm();

  return useMemo(() => {
    return {
      kind: 'button',
      key: 'leaveVenue',
      icon: <LeaveIcon className='fill-current text-red-002 w-3.5 h-3.5' />,
      text: 'Leave Venue',
      className: 'text-red-002',
      onClick: async () => {
        await leaveTeam('header-leave-button');
      },
    };
  }, [leaveTeam]);
}

function useOnDGameActions(): Action<MenuKeys>[] {
  const ondUICtrl = useOndGameUIControl();
  const ondState = useOndGameState();
  const { resetDisabled, pausingDisabled } = useOndGameUIControlState();
  const isRunning = ondState === 'running';

  const isCoordinator = useIsCoordinator();
  const isController = useIsController();
  return useMemo(() => {
    if (!isCoordinator) return [{ kind: 'noop' }];
    const options: Action<MenuKeys>[] = [
      { kind: 'delimiter' },
      {
        kind: 'button',
        key: 'pauseGame',
        icon: <PauseIcon className='w-3.5 h-3.5' />,
        text: 'Pause Game',
        disabled: pausingDisabled || !isRunning,
        onClick: () => ondUICtrl?.onClickPauseResume('pauseGame'),
      },
      {
        kind: 'button',
        key: 'resetGame',
        icon: <RefreshIcon />,
        text: 'Reset Game',
        disabled: resetDisabled,
        onClick: () => ondUICtrl?.onClickReset(),
      },
      {
        kind: 'button',
        key: 'switchGame',
        icon: <ReplaceIcon />,
        text: 'Switch Game',
        onClick: () => ondUICtrl?.onClickGameExplore(),
      },
    ];

    if (
      getFeatureQueryParam('game-on-demand-stuck-game-button') &&
      !isController
    ) {
      options.push(
        { kind: 'delimiter' },
        {
          kind: 'button',
          key: 'transferGame',
          icon: <ErrorIcon className='fill-current text-red-004 w-3.5 h-3.5' />,
          text: 'Stuck Game?',
          disabled: pausingDisabled || resetDisabled,
          onClick: () => ondUICtrl?.onClickTransferCloudHost(),
        }
      );
    }

    return options;
  }, [
    isController,
    isCoordinator,
    isRunning,
    ondUICtrl,
    pausingDisabled,
    resetDisabled,
  ]);
}

function Menu(props: {
  trigger: HTMLElement;
  setRenaming: () => void;
}): JSX.Element {
  const { trigger } = props;
  const actions: Action<MenuKeys>[] = [
    useEditTeamNameAction(props.setRenaming),
    useLeaveVenueAction(),
    ...useOnDGameActions(),
  ];
  return (
    <ActionSheet
      trigger={trigger}
      actions={actions}
      placement={'bottom-start'}
      offset={[0, 10]}
      dropdownClassName='bg-opacity-60 w-36'
    />
  );
}

/**
 * The control is hold by organizer if he is alive. Otherwise find the next
 * player by the join time.
 */
export function useIsSkipControllable(): boolean {
  const myClientId = useMyClientId();
  const coordinator = useGameHostingCoordinator();
  return coordinator?.clientId === myClientId;
}

function UpNextWidget() {
  const amICohost = useAmICohost();
  if (!amICohost) return null;
  return <ConnectedUpNextWidget />;
}

function ConnectedUpNextWidget() {
  // there's something about this hook where it changes even when some auxiliary state changes in the gameSessionStore.
  // it causes excessive rerendering for a component which should rerender pretty much only when the current or next
  // playback item changes. breaking this up is an attempt to avoid all of that.
  const nextPlaybackItem = useOndGameNextPlaybackItem();
  if (!nextPlaybackItem) return null;
  return (
    <UpNextWidgetInternal
      nextPlaybackItem={nextPlaybackItem as PlaybackDescItem}
    />
  );
}

const UpNextWidgetInternal = React.memo(
  (props: { nextPlaybackItem: PlaybackDescItem }) => {
    const { nextPlaybackItem } = props;
    const summaryText = BlockKnifeUtils.SummaryText(
      nextPlaybackItem.block as Block
    );
    const title =
      nextPlaybackItem.brand?.name || summaryText.title || 'Unknown';
    const scenarioOrBlockType = nextPlaybackItem.scenario
      ? BrandUtils.PredefinedBlockScenarioDisplayName(nextPlaybackItem.scenario)
      : summaryText.prettyTypeName;

    const Icon = BlockIconMap[nextPlaybackItem.block.type];
    return (
      <div className='w-full bg-secondary rounded-xl px-4 py-3 space-y-0.5'>
        <div className='w-full flex items-center justify-between'>
          <div className='text-secondary text-3xs uppercase tracking-wide font-bold'>
            Up Next
          </div>
        </div>
        <div className='text-white text-sm font-bold'>{title}</div>
        <div className='min-w-0 flex items-center gap-1'>
          <Icon />
          <div className='truncate text-icon-gray text-sms'>
            {scenarioOrBlockType}
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.nextPlaybackItem.id === nextProps.nextPlaybackItem.id
);

function useGameStartIndices(playbackDesc: Nullable<PlaybackDesc>) {
  return useMemo(() => {
    return getPlaybackGamesOverview(playbackDesc).map(
      (game) => game.startIndex
    );
  }, [playbackDesc]);
}

// note(falcon): we don't have a standard and consistent meaning of "game". in some cases it's roughly
// equivalent to a brand, but it's possible that within a single brand we have multiple, separate, and distinct "games".
// we're starting with a loose equivalence of a "game" to a "brand". i'm sure this won't be the last time we have to
// revisit this.
function useGamesRemaining() {
  const playbackDesc = usePlaybackDesc(SessionMode.OnDemand);
  const pbi = useOndGameCurrentPlaybackItem();
  const gameStartIndices = useGameStartIndices(playbackDesc);

  return useMemo(() => {
    if (!pbi || !playbackDesc) return undefined;
    const currentIndex = playbackDesc.items.findIndex(
      (item) => item.id === pbi.id
    );
    return gameStartIndices.reduce((acc, gameStartIndex) => {
      return acc + (gameStartIndex > currentIndex ? 1 : 0);
    }, 0);
  }, [gameStartIndices, pbi, playbackDesc]);
}

function GamesRemaining() {
  const gamesRemaining = useGamesRemaining();
  if (gamesRemaining === undefined) return null;

  return gamesRemaining === 0 ? (
    <span className='text-tertiary'>Final Game</span>
  ) : (
    <span className='text-white'>{`${gamesRemaining} ${pluralize(
      'Game',
      gamesRemaining
    )} Left`}</span>
  );
}

function SessionTimeWidget() {
  const amICohost = useAmICohost();
  const ondState = useOndGameState();
  if (!amICohost || !ondState || ondState === 'preparing') return null;
  return <SessionTimeWidgetInternal />;
}

function SessionTimeWidgetInternal() {
  const progressMs = useStreamSessionElapsedTimeMs();
  const gameSessionGamePack = useFetchGameSessionGamePack();
  const expectedDurationMs =
    (gameSessionGamePack?.approximateDurationSeconds ?? 0) * 1000;

  const percentage =
    expectedDurationMs > 0 ? progressMs / expectedDurationMs : 0;

  const showSkipToFinalGame = percentage >= 0.5;

  return (
    <div className='w-full bg-secondary rounded-xl px-4 py-3 space-y-0.5'>
      <div className='w-full flex items-center justify-between'>
        <div className='text-secondary text-3xs uppercase tracking-wide font-bold'>
          Event Time
        </div>
        <div className='text-icon-gray text-3xs uppercase tracking-wide'>
          <GamesRemaining />
        </div>
      </div>
      <div
        className={`${
          percentage >= 1 ? 'text-red-002' : 'text-icon-gray'
        } text-sm tabular-nums transition-colors`}
      >
        <span
          className={
            percentage >= 0.9
              ? 'text-red-002 font-bold'
              : percentage >= 0.6
              ? 'text-tertiary font-bold'
              : 'text-white'
          }
        >
          {TimeUtils.DurationAutoFormattedHHMMSS(progressMs)}
        </span>
        <span>/</span>
        <span>{TimeUtils.DurationAutoFormattedHHMMSS(expectedDurationMs)}</span>
      </div>
      {showSkipToFinalGame && <SkipToFinalGame />}
    </div>
  );
}

function SkipToFinalGame() {
  const gamesRemaining = useGamesRemaining() ?? 0;
  const playbackDesc = usePlaybackDesc(SessionMode.OnDemand);
  const gameStartIndices = useGameStartIndices(playbackDesc);
  const getCurrentPlaybackItem = useGetOndGameCurrentPlaybackItem();
  const configuredJump = useOndGameConfiguredJump();
  const [jump, setJump] = useState<OndPlaybackJump | null>(null);

  useEffect(() => {
    if (!jump) return;
    // reset the locally stored jump. the widget is persistent, so we need to reset it whenever the actual jump changes.
    if (!isEqual(jump, configuredJump)) setJump(null);
  }, [configuredJump, jump]);

  const handleQueueFinalGame = useLiveCallback(async () => {
    const currentPlaybackItem = getCurrentPlaybackItem();
    if (!playbackDesc || gameStartIndices.length === 0 || !currentPlaybackItem)
      return;

    let jumpAfterPlaybackItemId = currentPlaybackItem.id;
    let jumpAfterPlaybackItemIndex = -1;
    if (currentPlaybackItem.brand?.id) {
      let found = false;
      // jump after the "game" (brand) finishes
      for (let i = 0; i < playbackDesc.items.length; i++) {
        const pbi = playbackDesc.items[i];
        if (!found && pbi.id !== currentPlaybackItem.id) continue;
        if (!found && pbi.id === currentPlaybackItem.id) {
          jumpAfterPlaybackItemId = currentPlaybackItem.id;
          jumpAfterPlaybackItemIndex = i;
          found = true;
          continue;
        }

        if (pbi.brand?.id === currentPlaybackItem.brand.id) {
          jumpAfterPlaybackItemId = pbi.id;
          jumpAfterPlaybackItemIndex = i;
        } else {
          break;
        }
      }
    }

    const lastGameIndex = gameStartIndices[gameStartIndices.length - 1];
    const startOfLastGamePbi = playbackDesc.items[lastGameIndex];
    if (
      !startOfLastGamePbi ||
      jumpAfterPlaybackItemId === undefined ||
      jumpAfterPlaybackItemIndex > lastGameIndex // this is an edge case so we don't jump backward...
    )
      return;

    const jump = {
      jumpToPlaybackItemId: startOfLastGamePbi.id,
      jumpAfterPlaybackItemId: jumpAfterPlaybackItemId,
    };
    await configureJump(jump);
    setJump(jump);
  });

  const handleCancelJump = useLiveCallback(async () => {
    await configureJump(null);
    setJump(null);
  });

  if (!gamesRemaining) return null;

  return (
    <div className='flex items-baseline gap-1 font-bold text-white text-xs'>
      {jump ? (
        <>
          <span>Final game queued!</span>
          <button
            type='button'
            className='btn text-red-002 font-bold hover:underline'
            onClick={handleCancelJump}
          >
            Cancel
          </button>
        </>
      ) : (
        <>
          <span>Behind?</span>
          <button
            type='button'
            className='btn text-primary font-bold hover:underline'
            onClick={handleQueueFinalGame}
          >
            Queue final game
          </button>
        </>
      )}
    </div>
  );
}

function SkipWaitWidget(): JSX.Element | null {
  const isSkipControllable = useIsSkipControllable();
  const { readyForSkip, userSkippableMaxDurationSec, remainingSec } =
    useOndWaitModeInfo();
  const state = useOndGameState();
  const analytics = useOnDGameAnalytics();
  const playersCount = useNumOfParticipants({
    filters: ['host:false', 'cohost:false', 'status:connected', 'staff:false'],
  });
  const trackingProps = useLatest({
    venueId: useVenueId(),
    sessionId: useStreamSessionId() ?? null,
    gamePackId: useFetchGameSessionGamePack()?.id ?? null,
    blockId: useGameSessionBlockId(),
    playersCount,
  });

  const notReady = state !== 'running' || !readyForSkip;

  useEffect(() => {
    if (notReady || remainingSec > 0) return;
    analytics.trackEndBlockExited({
      ...trackingProps.current,
      totalSecs: userSkippableMaxDurationSec,
      remainingSecs: remainingSec,
      clicked: false,
    });
  }, [
    analytics,
    notReady,
    remainingSec,
    trackingProps,
    userSkippableMaxDurationSec,
  ]);

  if (notReady) return null;

  const handleSkip = async () => {
    await glAppend('ond-user-clicked-skip-countdown', {});
    await ondWaitEnd();
    analytics.trackEndBlockExited({
      ...trackingProps.current,
      totalSecs: userSkippableMaxDurationSec,
      remainingSecs: remainingSec,
      clicked: true,
    });
  };

  if (!isSkipControllable) {
    return null;
  }

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <div className='w-full h-10 relative'>
        <div
          className={`absolute bg-blue-001 inset-0 rounded-xl transform animate-plusing`}
          style={
            {
              '--tw-plusing-duration': '2s',
            } as CSSProperties
          }
        />
        <button
          type='button'
          className='w-full absolute inset-0 btn-primary flex items-center justify-center font-bold'
          onClick={handleSkip}
        >
          <div className='mr-2'>Skip Countdown</div>
          <DoubleRightArrow />
        </button>
      </div>

      <AutoProgressingIndicator className='mt-2.5' />
    </div>
  );
}

// this is a special widget that allows ending wait mode whenever the coordinator desires. it's primarily for testing
// in production with real gamepacks where the timers should not be modified, but we want to skip ahead quickly.
// this is for internal use.
function SkipTimerControlWidget(): JSX.Element | null {
  const enabled = useFeatureQueryParam('game-on-demand-skip-timer-control');
  const isSkipControllable = useIsSkipControllable();
  const state = useOndGameState();
  const waitMode = useOndWaitMode();
  const { readyForSkip } = useOndWaitModeInfo();
  const currentBlockType = useGameSessionBlock()?.type;

  if (
    !enabled ||
    state !== 'running' ||
    waitMode !== 'wait' ||
    !isSkipControllable ||
    readyForSkip || // will be handled by SkipWaitWidget. trying to reduce confusion for users.
    currentBlockType === BlockType.INSTRUCTION // will be handled by the block itself
  )
    return null;

  const handleSkip = async () => {
    await ondWaitEnd();
  };

  return (
    <div className=''>
      <button
        type='button'
        className='w-full h-10 btn-secondary flex items-center justify-center'
        onClick={handleSkip}
      >
        <div className='mr-2'>Skip Ahead</div>
        <DoubleRightArrow />
      </button>
      <div className='text-2xs text-icon-gray font-bold mt-2.5 text-center'>
        Only visible during testing
      </div>
    </div>
  );
}

function CoordinatorControllerWidget(): JSX.Element | null {
  const isSkipControllable = useIsSkipControllable();
  const state = useOndGameState();
  const block = useGameSessionBlock();

  if (!isSkipControllable || state !== 'running' || !block) return null;
  return <CoordinatorController selectedBlock={block} />;
}

function EndSessionWidget(): JSX.Element | null {
  const isSkipControllable = useIsSkipControllable();
  const ctrl = useOndGameUIControl();
  const state = useOndGameState();
  const analytics = usePostGameAnalytics();
  const showPreGame = useShowPreGame();

  const handleOnClick = () => {
    analytics.trackEndSessionClicked();
    ctrl?.onClickPostGameEndSession();
  };

  if (!isSkipControllable || showPreGame || state !== 'ended') return null;

  return (
    <button
      type='button'
      className='btn btn-secondary w-full h-10 flex items-center justify-center gap-1 text-sms'
      onClick={handleOnClick}
    >
      <FilledSquareIcon className='w-4 h-4 fill-current' />
      End Session
    </button>
  );
}

function GameSyntheticWidget(): JSX.Element {
  const team = useTeamWithStaff(useMyTeamId());
  const [renaming, setRenaming] = useState(false);
  const isLiveGamePlay = useIsLiveGamePlay();

  return (
    <div className='w-52'>
      <div className='w-full h-12.5 bg-black bg-opacity-80 rounded-1.5lg overflow-hidden mt-4 mb-2.5'>
        <div className='w-full h-full flex items-center justify-center'>
          {isLiveGamePlay ? null : <GameInfo />}
          <TeamInfo
            gamePackDetailsHidden={isLiveGamePlay}
            team={team}
            setRenaming={() => setRenaming(true)}
          />
        </div>
      </div>
      <div className='space-y-2.5'>
        {renaming && team && (
          <RenameTeam
            team={team}
            handleClose={() => setRenaming(false)}
            containerClassName='h-10 bg-black bg-opacity-40 rounded-xl px-2'
            showTeamIcon
          />
        )}
        <UpNextWidget />
        <SessionTimeWidget />
        <CoordinatorControllerWidget />
        <SkipWaitWidget />
        <EndSessionWidget />
        <PreGameReadyUpWidget />
        <SkipTimerControlWidget />
      </div>
    </div>
  );
}

export function Header(props: {
  homeURL: string;
  canGuestUserNavigateHome?: boolean;
}): JSX.Element {
  const teamsOnTop = useIsTeamsOnTop();
  const teamJoined = !!useMyTeamId();
  const forceUseRightWidget = useFeatureQueryParam(
    'townhall-force-use-header-right-widget'
  );
  const showTeam = useTownhallShowTeam();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const ondState = useOndGameState();

  let right;

  if (teamsOnTop) {
    if (showTeam || forceUseRightWidget) right = <GameSyntheticWidget />;
    // null means don't render anything
    else right = null;
    // undefined means use the default component
  } else if (isHost) right = undefined;
  else if (
    // Duplicated, but trying to make this obvious at how much of a special case
    // this is. Specifically: no townhall/teams on top, and OND. Which should
    // only be V1 playback.
    !teamsOnTop &&
    ondState &&
    // It will overlap with pregame
    ondState !== 'preparing'
  )
    right = <GameSyntheticWidget />;

  return (
    <VenueHeader
      left={
        <div>
          <HomeNavigator
            homeURL={props.homeURL}
            canGuestUserNavigate={props.canGuestUserNavigateHome}
          />
          <div className='mt-8'>
            <LobbyUpgradeCallout />
          </div>
        </div>
      }
      pinLeft={!teamJoined}
      right={right}
      enableRight
    />
  );
}
