import { type AxiosResponse } from 'axios';

import {
  type DtoAcceptOrgSubscriptionCancelOfferRequest,
  type DtoCheckInvitationResult,
  type DtoCreateCheckoutSessionRequest,
  type DtoCreateCheckoutSessionResponse,
  type DtoCreateStripeSubscriptionRequest,
  type DtoDeleteOrganizersRequest,
  type DtoExperience,
  type DtoExperienceListResponse,
  type DtoGenerateEngagementScoreReportRequest,
  type DtoGetOrgPresentationSummaryResponse,
  type DtoOnSeatCapErrorShownRequest,
  type DtoOrgSubscription,
  type DtoSinglePublicOrganizationResponse,
  type DtoSubmitOrgSubscriptionCancelReasonRequest,
  type DtoUpdateOrganizerRequest,
  type DtoUpdateOrgSettingsRequest,
  type DtoUpdateOrgSubscriptionPlanRequest,
  type DtoUpdateStripeSubscriptionRequest,
  type DtoUpgradeDowngradeOrgSubscriptionPlanRequest,
  type DtoUpsertSubscriptionIntentRequest,
  type EnumsOrgSubscriptionPlan,
  type EnumsOrgSubscriptionStatus,
  type GetPresentationSummaryParams,
} from '@lp-lib/api-service-client/public';

import {
  type Organization,
  type Organizer,
  type OrganizerRole,
  type OrgConnection,
} from '../../types/organization';
import { type TimeRangeKey } from './analytics.api';
import { BaseAPIService } from './base.api';
import { type MultiJoyCaptureResponse } from './joyCapture.api';
import { type Paginable, Paginator } from './pagination';

export type OrgSubscriptionPlan = `${EnumsOrgSubscriptionPlan}`;

export interface CreateOrganizationRequest {
  name: string;
  subscriptionPlan: OrgSubscriptionPlan;
  size?: number;
}

export interface UpdateOrganizationRequest {
  name?: string;
  maxSize?: number | null;
}

export type UpdateOrganizationLogoRequest = {
  logoId: string | null;
};

export interface OrganizationsResponse {
  organizations: Organization[];
}

export interface OrganizationResponse {
  organization: Organization;
}

export type UpdateOrganizerRequest = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

export interface OrganizerResponse {
  organizer: Organizer;
}

export interface OrganizersResponse extends Paginable {
  organizers: Organizer[];
}

export interface GetOrganizersOptions {
  q?: string | null;
  type?: string;
  size?: number;
  role?: OrganizerRole | null;
}

export interface CheckInvitationRequest {
  emails: string[];
}

export interface CheckInvitationResponse {
  failedResults: DtoCheckInvitationResult[];
}

export type InvitedUser = {
  email: string;
  fullName?: string;
  exUserId?: string | null;
};

export interface InviteOrganizersRequest {
  invitedUsers: InvitedUser[];
  webEndpoint: string;
  sendEmail?: boolean;
}

export interface InviteOrganizersResponse {
  organizers: Organizer[];
  newInviteeUids: string[];
}

export interface ReinviteOrganizerRequest {
  webEndpoint: string;
}

export interface UpdateOrganizersPairingStatusRequest {
  enabledUids: string[];
  disabledUids: string[];
}

type OrgConnectionResponse = {
  connection: OrgConnection;
};

interface CheckOrgConnectionAsSourceOfTruthResponse {
  exUsersCount: number;
  addUsersCount: number;
  deleteUsersCount: number;
  deleteUserEmailsPreview: string[];
}

export interface UpdateOrgConnectionAsSourceOfTruthRequest {
  asSourceOfTruth: boolean;
  sendInviteEmails?: boolean;
}

export class OrganizationAPI extends BaseAPIService {
  createOrganization(
    req: CreateOrganizationRequest
  ): Promise<AxiosResponse<OrganizationResponse>> {
    return this.client.post<OrganizationResponse>('/organizations', req);
  }

  getAllOrganizations(): Promise<AxiosResponse<OrganizationsResponse>> {
    return this.client.get<OrganizationsResponse>(`/organizations`, {
      params: { type: 'all' },
    });
  }

  getOrganization(orgId: string): Promise<AxiosResponse<OrganizationResponse>> {
    return this.client.get<OrganizationResponse>(`/organizations/${orgId}`);
  }

  getPublicOrganization(
    orgId: string
  ): Promise<AxiosResponse<DtoSinglePublicOrganizationResponse>> {
    return this.client.get<DtoSinglePublicOrganizationResponse>(
      `/organizations/${orgId}/public`
    );
  }

  updateOrganization(
    orgId: string,
    req: UpdateOrganizationRequest
  ): Promise<AxiosResponse<OrganizationResponse>> {
    return this.client.put<OrganizationResponse>(
      `/organizations/${orgId}`,
      req
    );
  }

  updateOrganizationUsesSlack(
    orgId: string,
    usesSlack: boolean
  ): Promise<AxiosResponse> {
    return this.client.put(`/organizations/${orgId}/uses-slack`, {
      usesSlack,
    });
  }

  updateOrganizationCanTrial(
    orgId: string,
    canTrial: boolean
  ): Promise<AxiosResponse> {
    return this.client.put(`/organizations/${orgId}/can-trial`, {
      canTrial,
    });
  }

  updateOrganizationLogo(
    orgId: string,
    req: UpdateOrganizationLogoRequest
  ): Promise<AxiosResponse<OrganizationResponse>> {
    return this.client.put<OrganizationResponse>(
      `/organizations/${orgId}/logo`,
      req
    );
  }

  importOrganizationLogo(
    orgId: string
  ): Promise<AxiosResponse<OrganizationResponse>> {
    return this.client.post<OrganizationResponse>(
      `/organizations/${orgId}/logo/import`
    );
  }

  deleteOrganization(orgId: string): Promise<AxiosResponse> {
    return this.client.delete(`/organizations/${orgId}`);
  }

  getOrganizers(
    orgId: string,
    options?: GetOrganizersOptions
  ): Paginator<OrganizersResponse, Organizer> {
    return new Paginator<OrganizersResponse, Organizer>(
      this.client,
      `/organizations/${orgId}/organizers`,
      'organizers',
      {
        size: options?.size,
        config: {
          params: options,
        },
      }
    );
  }

  getOrganizersByEmails(
    orgId: string,
    emails: string[]
  ): Promise<AxiosResponse<OrganizersResponse>> {
    return this.client.get<OrganizersResponse>(
      `/organizations/${orgId}/organizers/by-emails`,
      {
        params: { emails: emails.join(',') },
      }
    );
  }

  updateOrganizer(
    orgId: string,
    uid: string,
    req: DtoUpdateOrganizerRequest
  ): Promise<AxiosResponse<OrganizerResponse>> {
    return this.client.put(`/organizations/${orgId}/organizers/${uid}`, req);
  }

  deleteOrganizer(orgId: string, uid: string): Promise<AxiosResponse> {
    return this.client.delete(`/organizations/${orgId}/organizers/${uid}`);
  }

  deleteOrganizers(
    orgId: string,
    req: DtoDeleteOrganizersRequest
  ): Promise<AxiosResponse> {
    return this.client.post(
      `/organizations/${orgId}/organizers/batch-delete`,
      req
    );
  }

  updateOrganizerRole(
    orgId: string,
    uid: string,
    role: OrganizerRole
  ): Promise<AxiosResponse<void>> {
    return this.client.put(`/organizations/${orgId}/organizers/${uid}/role`, {
      role,
    });
  }

  getOrganizer(
    orgId: string,
    uid: string
  ): Promise<AxiosResponse<OrganizerResponse>> {
    return this.client.get<OrganizerResponse>(
      `/organizations/${orgId}/organizers/${uid}`
    );
  }

  checkOrganizersInvite(
    orgId: string,
    req: CheckInvitationRequest
  ): Promise<AxiosResponse<CheckInvitationResponse>> {
    return this.client.post(
      `/organizations/${orgId}/organizers/invite/check`,
      req
    );
  }

  inviteOrganizers(
    orgId: string,
    req: InviteOrganizersRequest
  ): Promise<AxiosResponse<InviteOrganizersResponse>> {
    return this.client.post(`/organizations/${orgId}/organizers/invite`, req);
  }

  reinviteOrganizer(
    orgId: string,
    uid: string,
    req: ReinviteOrganizerRequest
  ): Promise<AxiosResponse<OrganizerResponse>> {
    return this.client.post(
      `/organizations/${orgId}/organizers/${uid}/reinvite`,
      req
    );
  }

  toggleOrganizersPairingStatus(
    orgId: string,
    req: UpdateOrganizersPairingStatusRequest
  ): Promise<AxiosResponse> {
    return this.client.put(
      `/organizations/${orgId}/organizers/pairing-status`,
      req
    );
  }

  getOrgConnection(
    orgId: string
  ): Promise<AxiosResponse<OrgConnectionResponse>> {
    return this.client.get<OrgConnectionResponse>(
      `/organizations/${orgId}/connection`
    );
  }
  disconnect(orgId: string): Promise<AxiosResponse<void>> {
    return this.client.post<void>(`/organizations/${orgId}/disconnect`);
  }
  checkExConnectionAsSourceOfTruth(
    orgId: string
  ): Promise<AxiosResponse<CheckOrgConnectionAsSourceOfTruthResponse>> {
    return this.client.get(
      `/organizations/${orgId}/connection/as-source-of-truth/check`
    );
  }
  updateExConnectionAsSourceOfTruth(
    orgId: string,
    req: UpdateOrgConnectionAsSourceOfTruthRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.put(
      `/organizations/${orgId}/connection/as-source-of-truth`,
      req
    );
  }

  updateSubscriptionPlan(
    orgId: string,
    req: DtoUpdateOrgSubscriptionPlanRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.put(`/organizations/${orgId}/subscription/plan`, req);
  }

  upgradeDowngradeSubscriptionPlan(
    orgId: string,
    req: DtoUpgradeDowngradeOrgSubscriptionPlanRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post(
      `/organizations/${orgId}/subscription/upgrade-downgrade`,
      req
    );
  }

  cancelSubscriptionPlan(orgId: string): Promise<AxiosResponse<void>> {
    return this.client.post(`/organizations/${orgId}/subscription/cancel`);
  }

  submitCancelReason(
    orgId: string,
    req: DtoSubmitOrgSubscriptionCancelReasonRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post(
      `/organizations/${orgId}/subscription/submit-cancel-reason`,
      req
    );
  }

  acceptCancelOffer(
    orgId: string,
    req: DtoAcceptOrgSubscriptionCancelOfferRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post(
      `/organizations/${orgId}/subscription/accept-cancel-offer`,
      req
    );
  }

  updateSubscriptionStatus(orgId: string, status: EnumsOrgSubscriptionStatus) {
    return this.client.put(`/organizations/${orgId}/subscription/status`, {
      status,
    });
  }

  updateStripeSubscription(
    orgId: string,
    req: DtoUpdateStripeSubscriptionRequest
  ) {
    return this.client.put(
      `/organizations/${orgId}/subscription/stripe-subscription`,
      req
    );
  }

  getSubscriptionDetails(
    orgId: string
  ): Promise<AxiosResponse<DtoOrgSubscription>> {
    return this.client.get(`/organizations/${orgId}/subscription`);
  }

  createSubscriptionCheckoutSession(
    orgId: string,
    req: DtoCreateCheckoutSessionRequest
  ): Promise<AxiosResponse<DtoCreateCheckoutSessionResponse>> {
    return this.client.post(
      `/organizations/${orgId}/subscription/create-checkout-session`,
      req
    );
  }

  upsertSubscriptionIntent(
    orgId: string,
    req: DtoUpsertSubscriptionIntentRequest
  ): Promise<AxiosResponse<DtoCreateCheckoutSessionResponse>> {
    return this.client.post(
      `/organizations/${orgId}/subscription/upsert-intent`,
      req
    );
  }

  createSubscriptionPortalSession(orgId: string) {
    return this.client.post(
      `/organizations/${orgId}/subscription/create-portal-session`
    );
  }

  createStripeSubscription(
    orgId: string,
    req: DtoCreateStripeSubscriptionRequest
  ) {
    return this.client.post(
      `/organizations/${orgId}/subscription/create-stripe-subscription`,
      req
    );
  }

  getJoyfulMoments(
    orgId: string,
    limit: number
  ): Promise<AxiosResponse<MultiJoyCaptureResponse>> {
    return this.client.get(`/organizations/${orgId}/joyful-moments`, {
      params: { limit },
    });
  }

  updateOrgSettings(
    orgId: string,
    req: DtoUpdateOrgSettingsRequest
  ): Promise<AxiosResponse<OrganizationResponse>> {
    return this.client.put(`/organizations/${orgId}/settings`, req);
  }

  queryExperiences(
    orgId: string,
    timeRange: TimeRangeKey
  ): Paginator<DtoExperienceListResponse, DtoExperience> {
    return new Paginator<DtoExperienceListResponse, DtoExperience>(
      this.client,
      `/organizations/${orgId}/experiences`,
      'experiences',
      {
        size: 36,
        config: {
          params: {
            timeRange,
          },
        },
      }
    );
  }

  getOrganizerByUid(uid: string): Promise<AxiosResponse<OrganizerResponse>> {
    return this.client.get<OrganizerResponse>(`/organizers/${uid}`);
  }

  generateEngagementScoreReport(req: DtoGenerateEngagementScoreReportRequest) {
    return this.client.post(
      `/organizations/generate-engagement-score-report`,
      req
    );
  }

  getPresentationSummary(
    orgId: string,
    params: GetPresentationSummaryParams
  ): Promise<AxiosResponse<DtoGetOrgPresentationSummaryResponse>> {
    return this.client.get(`/organizations/${orgId}/presentation-summary`, {
      params,
    });
  }

  onSeatCapErrorShown(req: DtoOnSeatCapErrorShownRequest) {
    return this.client.post(`/organizations/seat-cap-error-shown`, req);
  }
}
