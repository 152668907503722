import { useEffect, useRef } from 'react';
import { match } from 'ts-pattern';
import { useSnapshot } from 'valtio';

import { EnumsJeopardyTurnResult } from '@lp-lib/api-service-client/public';
import { type JeopardyBlock } from '@lp-lib/game';

import { DoubleRightArrow } from '../../../icons/Arrows';
import { FilledCheckIcon } from '../../../icons/CheckIcon';
import { CloseIcon } from '../../../icons/CloseIcon';
import { QuestionMarkIcon } from '../../../icons/QuestionMarkIcon';
import { type JeopardyBlockControlAPI } from './JeopardyBlockPlayground';
import { useJeopardyBoardCellClueRef } from './JeopardyBoard';
import { type JeopardyTurn } from './types';

function JeopardyTurnResultCell(props: { turn: JeopardyTurn | null }) {
  const { turn } = props;

  const spotRef = useRef<HTMLDivElement>(null);
  const clueRef = useJeopardyBoardCellClueRef(turn?.clue?.id ?? null);

  useEffect(() => {
    if (!clueRef || !spotRef.current) return;

    const clueRefRect = clueRef.getBoundingClientRect();
    const targetRect = spotRef.current.getBoundingClientRect();

    const scaleX = clueRefRect.width / targetRect.width;
    const scaleY = clueRefRect.height / targetRect.height;
    const scale = Math.min(scaleX, scaleY);

    const tx =
      (clueRefRect.left + clueRefRect.right) / 2 -
      (targetRect.left + targetRect.right) / 2;
    const ty =
      (clueRefRect.top + clueRefRect.bottom) / 2 -
      (targetRect.top + targetRect.bottom) / 2;

    spotRef.current.animate(
      [
        {
          transform: `
            translate(${tx}px, ${ty}px) 
            scale(${scale}, ${scale})
          `,
          zIndex: 100,
        },
        {
          transform: 'translate(0, 0) scale(1, 1)',
          zIndex: 100,
        },
      ],
      {
        duration: 800,
        easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
      }
    );
  }, [clueRef, turn?.clue]);

  return (
    <div className='relative w-7.5 h-5.5'>
      <div className='absolute bottom-0 left-0 right-0 w-full h-3'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 30 12'
          className='w-full h-full'
        >
          <ellipse
            cx='15'
            cy='6'
            rx='15'
            ry='6'
            fill='white'
            fillOpacity='0.2'
          />
        </svg>
      </div>
      <div
        className='absolute top-0 left-0 right-0 flex justify-center'
        ref={spotRef}
      >
        {match(props.turn?.result)
          .with(EnumsJeopardyTurnResult.JeopardyTurnResultCorrect, () => (
            <FilledCheckIcon className='-mt-1 w-5.5 h-5.5 fill-current text-green-001' />
          ))
          .with(EnumsJeopardyTurnResult.JeopardyTurnResultIncorrect, () => (
            <CloseIcon className='w-3.5 h-3.5 fill-current text-red-001' />
          ))
          .with(EnumsJeopardyTurnResult.JeopardyTurnResultSkipped, () => (
            <DoubleRightArrow className='-mt-0.5 w-5 h-5 fill-current text-icon-gray' />
          ))
          .otherwise(() => (
            <QuestionMarkIcon className='w-4 h-4 fill-current text-[#ADADAD]' />
          ))}
      </div>
    </div>
  );
}

export function JeopardyTurnTracker(props: {
  block: JeopardyBlock;
  ctrl: JeopardyBlockControlAPI;
}) {
  const playedTurns = useSnapshot(props.ctrl.state).playedTurns;

  return (
    <div className='relative flex justify-center items-center gap-2.5'>
      <div className='absolute right-full mr-5 text-3xs text-white'>
        {playedTurns.length}/5
      </div>

      {playedTurns.map((turn, index) => (
        <JeopardyTurnResultCell key={index} turn={turn as JeopardyTurn} />
      ))}

      {Array.from({ length: 5 - playedTurns.length }).map((_, index) => (
        <JeopardyTurnResultCell key={index} turn={null} />
      ))}
    </div>
  );
}

export function JeopardyEditorTurnTracker() {
  return (
    <div className='relative flex justify-center items-center gap-2.5'>
      <div className='absolute right-full mr-5 text-3xs text-white'>0/5</div>

      {Array.from({ length: 5 }).map((_, index) => (
        <JeopardyTurnResultCell key={index} turn={null} />
      ))}
    </div>
  );
}
