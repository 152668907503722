import {
  type ClientLoaderFunctionArgs,
  type MetaFunction,
  useLoaderData,
} from '@remix-run/react';

import {
  type DtoFormResponse,
  type DtoGamePack,
  type DtoGamePackUGCFile,
} from '@lp-lib/api-service-client/public';

import { TrainingCreatorAssistant } from '../components/Training/TrainingCreatorAssistant';
import { apiService } from '../services/api-service';
import { LearingStarterLayout } from './learning.starter.client';

export async function clientLoader(action: ClientLoaderFunctionArgs): Promise<{
  pack: DtoGamePack | null;
  files: DtoGamePackUGCFile[];
  template: DtoGamePack | null;
  prompt: string | null;
  formResponse: DtoFormResponse | null;
  starterOrigin: string | null;
}> {
  const url = new URL(action.request.url);
  const id = url.searchParams.get('id');
  const templateId = url.searchParams.get('template-id');
  const starterOrigin = url.searchParams.get('starter-origin');
  const template = templateId
    ? (await apiService.gamePack.getGamePackById(templateId)).data.gamePack
    : null;
  const formId = url.searchParams.get('form-id');
  const responseId = url.searchParams.get('response-id');
  const formResponse =
    formId && responseId
      ? {
          formId,
          responseId,
        }
      : null;
  if (id) {
    const [pack, files] = await Promise.all([
      (await apiService.gamePack.getGamePackById(id)).data.gamePack,
      (await apiService.gamePack.getUGCFiles(id)).data.files,
    ]);
    return {
      pack,
      files,
      template,
      prompt: url.searchParams.get('prompt'),
      formResponse,
      starterOrigin,
    };
  }
  return {
    pack: null,
    files: [],
    template,
    prompt: url.searchParams.get('prompt'),
    formResponse,
    starterOrigin,
  };
}

export const meta: MetaFunction = () => {
  return [{ title: 'Training Creator Assistant | Luna Park' }];
};

export function Component() {
  const { pack, files, template, prompt, formResponse, starterOrigin } =
    useLoaderData<typeof clientLoader>();

  return (
    <LearingStarterLayout>
      <TrainingCreatorAssistant
        pack={pack}
        files={files}
        template={template}
        prompt={prompt}
        formResponse={formResponse}
        starterOrigin={starterOrigin}
      />
    </LearingStarterLayout>
  );
}
