import debounce from 'lodash/debounce';
import shuffle from 'lodash/shuffle';
import pluralize from 'pluralize';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import {
  type CSSProperties,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { MultiBackend, usePreview } from 'react-dnd-multi-backend';
import { useEffectOnce, usePreviousDistinct } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import {
  type CommonMedia,
  type DtoTTSRenderRequest,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';
import { MediaFormatVersion, type SwipeToWinBlock } from '@lp-lib/game';
import { type Logger } from '@lp-lib/logger-base';

import { useInstance } from '../../../../hooks/useInstance';
import { fromMediaDTO } from '../../../../utils/api-dto';
import { getStaticAssetPath } from '../../../../utils/assets';
import { sleep } from '../../../../utils/common';
import { MediaUtils } from '../../../../utils/media';
import { MonotonicallyIncrementingId } from '../../../../utils/MonotonicallyIncrementingId';
import { markSnapshottable, ValtioUtils } from '../../../../utils/valtio';
import { useSwipeable } from '../../../../vendor/react-swipeable';
import {
  type Classes,
  StagedTailwindTransition,
  type TailwindTransitionStage,
} from '../../../common/TailwindTransition';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../icons/Arrows';
import { useDevicePreviewFrame } from '../../../Training/Editor/Shared/DevicePreview';
import {
  lvoLocalCacheWarm,
  LVOLocalPlayer,
} from '../../../VoiceOver/LocalLocalizedVoiceOvers';
import { useOrgMasqueradeFallback } from '../../apis/OrgMasqueradeFallback';
import { getStingerPrompt } from '../../apis/StingerControl';
import { CommonButton } from '../../design/Button';
import { CorrectAnimationWithLayout } from '../../design/CorrectAnimation';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';
import { Match } from './Match';

type Item = {
  id: string;
  media: CommonMedia | null;
  text: string;
};

type State = {
  status: 'init' | 'present' | 'play' | 'inform' | 'complete';
  visibility: 'visible' | 'hidden';
  cta: 'submit' | 'complete';
  questions: Item[];
  answers: Item[];
  matching: {
    question: { id: string; viewId: string };
    answer: { id: string; viewId: string };
    result: 'correct' | 'incorrect';
  } | null;
};

export class SwipeToWinBlockControlAPI implements IBlockCtrl {
  readonly resultAnimationMs = 1500;
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
      visibility: 'hidden',
      cta: 'submit',
      questions: [],
      answers: [],
      matching: null,
    })
  );
  private resolvedTTS: {
    stinger: Nullable<DtoTTSRenderRequest>;
    question: Nullable<DtoTTSRenderRequest>;
    completion: Nullable<DtoTTSRenderRequest>;
  } = {
    stinger: null,
    question: null,
    completion: null,
  };
  private delegate: Nullable<PlaygroundPlaybackProtocol>;
  readonly logger: Logger;

  constructor(
    private block: SwipeToWinBlock,
    readonly deps: BlockDependencies,
    private shuffle: boolean = true
  ) {
    this.logger = deps.getLogger('swipe-to-win-block');
  }

  get state() {
    return this._state;
  }

  async preload() {
    if (!this.block.fields.personalityId) return;

    this.resolvedTTS.stinger = await this.stingerTTS();
    this.resolvedTTS.question = await this.questionTTS();
    this.resolvedTTS.completion = await this.completionTTS();

    await lvoLocalCacheWarm(this.resolvedTTS.stinger);
    await lvoLocalCacheWarm(this.resolvedTTS.question);
    lvoLocalCacheWarm(this.resolvedTTS.completion);
  }
  async initialize(preloaded: Promise<void>) {
    await preloaded;

    const idgen = new MonotonicallyIncrementingId('stw-card');
    const pairs = this.block.fields.cardPairs;
    const questions: Item[] = [];
    const answers: Item[] = [];
    for (const pair of pairs) {
      if ((!pair.firstMedia && !pair.firstText) || !pair.secondText) {
        continue;
      }
      const pairId = idgen.next();
      questions.push({
        id: pairId,
        media: pair.firstMedia?.media ?? null,
        text: pair.firstText,
      });
      answers.push({
        id: pairId,
        media: null,
        text: pair.secondText,
      });
    }
    if (this.shuffle) {
      this._state.questions = shuffle(questions);
      this._state.answers = shuffle(answers);
    } else {
      this._state.questions = questions;
      this._state.answers = answers;
    }
  }
  async present() {
    try {
      await this.deps.stingerControl.playBlockIntro(
        this.block,
        this.resolvedTTS.stinger
      );
    } catch (e) {
      this.logger.error('failed to play stinger TTS', e);
    }

    try {
      const player = new LVOLocalPlayer(this.resolvedTTS.question);
      const info = await player.playFromPool();
      await info?.trackStarted;
      this._state.status = 'present';
      this._state.visibility = 'visible';
      await info?.trackEnded;
    } catch (e) {
      this.logger.error(
        'failed to play question TTS, falling back to silence',
        e
      );
      this._state.status = 'present';
      this._state.visibility = 'visible';
      await sleep(3000);
    }

    this._state.status = 'play';
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async end() {
    this.deps.sfxControl.play('instructionHoverReadyButton');
    await this.delegate?.blockDidEnd();
  }

  private async stingerTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    if (!this.deps.stingerControl.shouldPreloadTTS(this.block)) return null;

    return this.makeTTSRenderRequest(
      getStingerPrompt(
        this.block.fields.instruction,
        'Match the pairs to win.',
        'Swipe to win.'
      )
    );
  }

  private async questionTTS() {
    return this.makeTTSRenderRequest(this.block.fields.instruction);
  }

  private async completionTTS(): Promise<Nullable<DtoTTSRenderRequest>> {
    return this.makeTTSRenderRequest(
      getCompletionPrompt(this.block.fields.instruction)
    );
  }

  private async makeTTSRenderRequest(
    script: string
  ): Promise<Nullable<DtoTTSRenderRequest>> {
    const personalityId = this.block.fields.personalityId;
    if (!personalityId) return null;

    const resolved = await this.deps.commonVariableRegistry.render(script);
    return {
      script: resolved.script,
      personalityId,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlShortLive,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
    };
  }

  async match(
    question: NonNullable<State['matching']>['question'],
    answer: NonNullable<State['matching']>['answer']
  ) {
    this.logger.info('match', {
      question: ValtioUtils.detachCopy(question),
      answer: ValtioUtils.detachCopy(answer),
    });
    this._state.matching = {
      question,
      answer,
      result: question.id === answer.id ? 'correct' : 'incorrect',
    };
    this.deps.sfxControl.play(
      this._state.matching.result === 'correct' ? 'rapidCorrect' : 'rapidWrong'
    );
    await sleep(this.resultAnimationMs);
    if (this._state.matching.result === 'incorrect') return;
    this.removeCard('question', question.id);
    this.removeCard('answer', answer.id);
    this.checkEnd();
  }

  clearLastMatchResult() {
    this._state.matching = null;
  }

  private removeCard(type: 'question' | 'answer', id: string) {
    const cards =
      type === 'question' ? this._state.questions : this._state.answers;
    const index = cards.findIndex((card) => card.id === id);
    if (index < 0) return;
    cards.splice(index, 1);
  }

  private async checkEnd() {
    if (this._state.questions.length > 0) return;
    this._state.cta = 'complete';
    await this.inform();
  }

  private async inform() {
    if (this.block.fields.pointsPerMatch === 0) {
      this._state.status = 'complete';
      return;
    }
    this._state.status = 'inform';
    try {
      const player = new LVOLocalPlayer(this.resolvedTTS.completion);
      const info = await player.playFromPool();
      await info?.trackEnded;
    } catch (e) {
      this.logger.error('failed to play result TTS', e);
    }
    this._state.status = 'complete';
  }
}

function Background() {
  const url = useInstance(() =>
    getStaticAssetPath('videos/swipe-to-win-background.mp4')
  );
  return (
    <div className='absolute inset-0'>
      <video
        src={url}
        autoPlay
        loop
        muted
        className='object-cover w-full h-full select-none'
        playsInline
      />
    </div>
  );
}

function ArrowButton(props: {
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <button
      type='button'
      className={`btn rounded-full ring-1 ring-secondary 
      text-white bg-black bg-opacity-80
      flex justify-center items-center w-12 h-12 ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
function useMakeCardViewItems(items: Item[], max: number) {
  return useMemo(() => {
    const vItems = items.map((item) => ({ ...item, viewId: item.id }));
    if (items.length > max) {
      return vItems;
    }
    for (const item of items) {
      vItems.push({ ...item, viewId: `dup-${item.id}` });
    }
    return vItems;
  }, [max, items]);
}

type AnswerViewItem = Item & {
  viewId: string;
  styles?: {
    layer: number;
    left: number;
    top: number;
    rotate: number;
  };
};

function AnswerCard(props: { card: Item }) {
  const { card } = props;

  const organization = useOrgMasqueradeFallback();
  const logoSrc = MediaUtils.PickMediaUrl(organization?.logo, {
    priority: [MediaFormatVersion.SM],
  });

  return (
    <div
      className='w-full h-full border-2 border-black rounded-2.5xl bg-white p-3 flex-none'
      style={{
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <div
        className='w-full h-full flex flex-col items-start justify-center rounded-1.5lg'
        style={{
          background: 'linear-gradient(156deg, #FEF0CD 18.43%, #FBB604 99.17%)',
        }}
      >
        <div className='w-full h-2/3 flex items-center justify-center text-xl font-bold text-black text-center'>
          {card.text}
        </div>
        <div className='w-full h-1/3 p-2 flex items-end'>
          <div className='w-full h-10 flex items-center gap-1'>
            {logoSrc && (
              <div className='flex-none w-10 h-10'>
                <img
                  src={logoSrc}
                  alt='logo'
                  className='w-full h-full object-contain rounded-lg'
                />
              </div>
            )}
            {organization && (
              <p className='text-black text-sms font-black italic'>
                {organization.name}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function AnswerCardCarousel(props: {
  block: SwipeToWinBlock;
  ctrl: SwipeToWinBlockControlAPI;
  context: Window | undefined;
  setDragOver: (value: boolean) => void;
  setCurrAnswer: (item: AnswerViewItem | null) => void;
}) {
  const { ctrl, setDragOver, setCurrAnswer } = props;
  const st = useSnapshot(ctrl.state);
  const answers = st.answers as Item[];
  const max = Math.min(7, answers.length);
  const mid = Math.floor(max / 2);
  const [currIdx, setCurrIdx] = useState(0);
  const rawItems = useMakeCardViewItems(answers, max);
  const matching = st.matching;
  const items = useMemo<AnswerViewItem[]>(() => {
    const temp: AnswerViewItem[] = [];
    for (let i = currIdx; i < currIdx + max; i++) {
      if (i < rawItems.length) {
        temp.push(rawItems[i]);
      } else {
        temp.push(rawItems[i - rawItems.length]);
      }
    }
    return temp.map((item, index) => {
      const gap = index - mid;
      const absgap = Math.abs(gap);
      const sign = gap > 0 ? 1 : -1;
      return {
        ...item,
        styles: {
          layer: -absgap,
          left: absgap === 0 ? 0 : (absgap * 20 - (absgap - 1) * 10) * sign,
          top: absgap * 20,
          rotate: gap * 5,
        },
      };
    });
  }, [currIdx, max, rawItems, mid]);

  useEffect(() => {
    setCurrAnswer(items[mid] ?? null);
  }, [items, mid, setCurrAnswer]);

  const updateCurrIdx = (by: number) => {
    ctrl.deps.sfxControl.play('swipeCardFlip');
    const left = 0;
    const right = rawItems.length - 1;
    setCurrIdx((prev) => {
      const next = prev + by;
      if (next < left) {
        return right;
      }
      if (next > right) return left;
      return next;
    });
  };

  const [collected, drag] = useDrag({
    type: 'stw-card',
    item: () => {
      return { answer: items[mid] };
    },
    collect: (monitor) => {
      if (!monitor.isDragging()) {
        setDragOver(false);
      }
      return {
        opacity: monitor.isDragging() ? 'opacity-0' : '',
      };
    },
  });

  const [, drop] = useDrop({
    accept: 'stw-card',
    drop(_, monitor) {
      const diff = monitor.getDifferenceFromInitialOffset();
      ctrl.logger.info('check horizontal swipe', {
        didDrop: monitor.didDrop(),
        dropResult: monitor.getDropResult(),
        diff,
      });
      if (!diff) return;
      if (Math.abs(diff.x) > 50) {
        updateCurrIdx(diff.x > 0 ? -1 : 1);
      }
    },
  });

  return (
    <div
      className='w-full absolute -bottom-15 left-1/2 transform-gpu -translate-x-1/2'
      ref={drop}
    >
      <div className='relative flex flex-col gap-3'>
        {answers.length > 0 ? (
          <p className='text-icon-gray text-sms text-center'>
            {answers.length} {pluralize('match', answers.length)} remaining
          </p>
        ) : (
          <p className='text-green-001 text-6xl font-black italic capitalize text-center'>
            Nice Job!
          </p>
        )}
        <div className='relative self-center w-fit-content h-fit-content'>
          <div className='w-60 h-84 relative'>
            {items.map((item, index) => (
              <div
                ref={index === mid ? drag : undefined}
                data-id={item.id}
                data-view-id={item.viewId}
                key={item.viewId}
                className={`w-full h-full absolute transform transform-gpu transition-all ${
                  index === mid
                    ? matching
                      ? 'opacity-0'
                      : collected.opacity
                    : ''
                }`}
                style={
                  item.styles
                    ? ({
                        left: `${item.styles.left}px`,
                        top: `${item.styles.top}px`,
                        '--tw-rotate': `${item.styles.rotate}deg`,
                        zIndex: item.styles.layer,
                      } as CSSProperties)
                    : undefined
                }
              >
                <AnswerCard key={item.id} card={item} />
                {index !== mid && (
                  <div className='bg-black bg-opacity-20 w-full h-full absolute inset-0'></div>
                )}
              </div>
            ))}
          </div>
          {answers.length > 0 && (
            <div className='hidden md:block w-full'>
              <ArrowButton
                className={`absolute -left-16 top-1/2 transform-gpu -translate-y-1/2 z-10`}
                onClick={() => updateCurrIdx(-1)}
              >
                <ArrowLeftIcon className='w-5 h-5 fill-current' />
              </ArrowButton>
              <ArrowButton
                className={`absolute -right-16 top-1/2 transform-gpu -translate-y-1/2 z-10`}
                onClick={() => updateCurrIdx(1)}
              >
                <ArrowRightIcon className='w-5 h-5 fill-current' />
              </ArrowButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// This is only used for mobile preview
// https://github.com/react-dnd/react-dnd/issues/2844
// https://github.com/LouisBrunner/dnd-multi-backend/tree/main/packages/react-dnd-preview
function AnswerCardDragPreview() {
  const preview = usePreview<{ answer: Item }>();
  if (!preview.display) return null;
  return (
    <div className='w-60 h-84 absolute z-20 opacity-80' style={preview.style}>
      <AnswerCard card={preview.item.answer} />
    </div>
  );
}

function AnswerCardMatchingPreview(props: { ctrl: SwipeToWinBlockControlAPI }) {
  const { ctrl } = props;
  const st = useSnapshot(ctrl.state);
  const answers = st.answers as Item[];
  const matching = st.matching;

  const stages = useMemo<TailwindTransitionStage<Classes>[]>(() => {
    if (!matching?.result) return [];
    if (matching.result === 'correct') {
      return [
        { classes: 'scale-1' },
        { classes: 'scale-110' },
        { classes: 'scale-1' },
      ];
    } else {
      return [
        { classes: '-translate-x-10' },
        { classes: 'translate-x-10' },
        { classes: '-translate-x-10' },
        { classes: 'translate-x-10' },
        { classes: 'translate-x-0' },
      ];
    }
  }, [matching?.result]);

  if (!matching) return null;

  const matchingAnswer = matching
    ? answers.find((a) => a.id === matching.answer.id)
    : null;

  if (!matchingAnswer) return null;

  return (
    <div
      className={`w-60 h-84 absolute top-[10%] left-1/2 transform-gpu 
    -translate-x-1/2 rotate-6 z-20`}
    >
      <StagedTailwindTransition stages={stages} debugKey='stw-matching-preview'>
        {(ref, initial) => (
          <div
            ref={ref}
            className={`w-full h-full ${initial} ${
              matching.result === 'correct' ? 'duration-300' : 'duration-[50ms]'
            } transition-all`}
          >
            <AnswerCard card={matchingAnswer} />
          </div>
        )}
      </StagedTailwindTransition>
    </div>
  );
}

function MatchingResult(props: { ctrl: SwipeToWinBlockControlAPI }) {
  const { ctrl } = props;
  const { matching } = useSnapshot(ctrl.state);

  useEffect(() => {
    if (!matching) return;
    const timeout = setTimeout(() => {
      ctrl.clearLastMatchResult();
    }, ctrl.resultAnimationMs);
    return () => {
      clearTimeout(timeout);
    };
  }, [ctrl, matching]);

  if (!matching) return null;

  return (
    <div
      className={`flex items-end justify-center absolute inset-0 z-10 ${
        matching.result === 'correct'
          ? 'bg-lp-green-003 bg-opacity-40'
          : matching.result === 'incorrect'
          ? 'bg-lp-red-002 bg-opacity-40'
          : ''
      }`}
    >
      <div className='w-full max-w-100 text-center mb-5'>
        <div
          className={`${
            matching.result === 'correct' ? 'text-green-001' : 'text-red-006'
          } text-6xl font-black italic capitalize`}
          style={{
            textShadow: '0px 4px 12px rgba(0, 0, 0, 0.50)',
          }}
        >
          {matching.result}
        </div>
        {matching.result === 'correct' && (
          <div className='w-full h-20 relative'>
            <CorrectAnimationWithLayout onEnd={() => void 0} />
          </div>
        )}
      </div>
    </div>
  );
}

function QuestionCard(props: { card: Item }) {
  const { card } = props;
  const mediaUrl = MediaUtils.PickMediaUrl(fromMediaDTO(card.media));
  return (
    <div
      className={`w-full h-full border border-secondary rounded-2.5xl 
        flex items-center justify-center overflow-hidden ${
          card.media ? 'bg-black' : 'bg-lp-blue-002'
        }`}
    >
      {mediaUrl ? (
        <img
          className='w-full h-full object-cover pointer-events-none'
          src={mediaUrl}
          alt='card media'
        />
      ) : (
        <p className='text-white text-xl font-bold'>{card.text}</p>
      )}
    </div>
  );
}

type QuestionViewItem = Item & {
  viewId: string;
  styles?: {
    layer: number;
    left: number;
    scale: number;
  };
};

function QuestionCardCarousel(props: {
  block: SwipeToWinBlock;
  ctrl: SwipeToWinBlockControlAPI;
  context: Window | undefined;
  setDragOver: (value: boolean) => void;
  setCurrQuestion: (item: QuestionViewItem | null) => void;
}) {
  const { ctrl, setCurrQuestion } = props;
  const st = useSnapshot(ctrl.state);
  const questions = st.questions as Item[];
  const max = Math.min(3, questions.length);
  const mid = Math.floor(max / 2);
  const [currIdx, setCurrIdx] = useState(0);
  const rawItems = useMakeCardViewItems(questions, max);
  const items = useMemo<QuestionViewItem[]>(() => {
    const temp: QuestionViewItem[] = [];
    for (let i = currIdx; i < currIdx + max; i++) {
      if (i < rawItems.length) {
        temp.push(rawItems[i]);
      } else {
        temp.push(rawItems[i - rawItems.length]);
      }
    }
    return temp.map((item, index) => {
      const gap = index - mid;
      const absgap = Math.abs(gap);
      const sign = gap > 0 ? 1 : -1;
      return {
        ...item,
        styles: {
          layer: max - absgap,
          left: absgap === 0 ? 0 : absgap * 160 * sign,
          scale: absgap === 0 ? 1 : 1 * Math.pow(0.8, absgap),
        },
      };
    });
  }, [currIdx, max, rawItems, mid]);

  useEffect(() => {
    setCurrQuestion(items[mid] ?? null);
  }, [items, mid, setCurrQuestion]);

  const updateCurrIdx = (by: number) => {
    const left = 0;
    const right = rawItems.length - 1;
    setCurrIdx((prev) => {
      const next = prev + by;
      if (next < left) {
        return right;
      }
      if (next > right) return left;
      return next;
    });
  };

  const { onMouseDown, ref: swipe } = useSwipeable({
    trackMouse: true,
    trackTouch: true,
    context: props.context,
    preventScrollOnSwipe: true,
    onSwiped: (eventData) => {
      if (eventData.dir === 'Right') {
        updateCurrIdx(-1);
      } else if (eventData.dir === 'Left') {
        updateCurrIdx(1);
      }
    },
  });

  // Checkout this issue for why adding the debounce
  // https://github.com/react-dnd/react-dnd/issues/3489#issuecomment-2449079505
  const setDragOver = debounce(props.setDragOver, 100);

  const [, drop] = useDrop({
    accept: 'stw-card',
    drop(item: { answer: AnswerViewItem }) {
      const question = items[mid];
      ctrl.logger.info('try match from drop', {
        question: ValtioUtils.detachCopy(question ?? null),
        answer: ValtioUtils.detachCopy(item.answer),
      });
      if (!question) return;
      ctrl.match(question, item.answer);
    },
    collect: (monitor) => {
      setDragOver(monitor.isOver());
    },
  });

  return (
    <div className='w-full flex itemc-center justify-center absolute'>
      <div
        // add padding for extra drop space
        className='relative self-center w-fit-content h-fit-content py-20'
        ref={drop}
      >
        <div
          className='w-80 h-45 md:w-115 md:h-65 relative'
          onMouseDown={onMouseDown}
          ref={(el) => {
            swipe(el);
          }}
        >
          {items.map((item, index) => (
            <div
              data-id={item.id}
              data-view-id={item.viewId}
              key={item.viewId}
              className={`w-full h-full absolute transform transform-gpu transition-all pointer-events-none`}
              style={
                item.styles
                  ? ({
                      left: `${item.styles.left}px`,
                      // for the middle card to be above the matching overlay
                      zIndex: index === mid ? 20 : item.styles.layer,
                      '--tw-scale-x': item.styles.scale,
                      '--tw-scale-y': item.styles.scale,
                    } as CSSProperties)
                  : undefined
              }
            >
              <QuestionCard key={item.id} card={item} />
              {index !== mid && (
                <div className='bg-black bg-opacity-60 w-full h-full absolute inset-0 rounded-2.5xl'></div>
              )}
            </div>
          ))}
        </div>
        {questions.length > 1 && (
          <div className='hidden md:block w-full'>
            <ArrowButton
              className={`absolute -left-32 top-1/2 transform-gpu -translate-y-1/2 z-10`}
              onClick={() => updateCurrIdx(-1)}
            >
              <ArrowLeftIcon className='w-5 h-5 fill-current' />
            </ArrowButton>
            <ArrowButton
              className={`absolute -right-32 top-1/2 transform-gpu -translate-y-1/2 z-10`}
              onClick={() => updateCurrIdx(1)}
            >
              <ArrowRightIcon className='w-5 h-5 fill-current' />
            </ArrowButton>
          </div>
        )}
        <AnswerCardMatchingPreview ctrl={ctrl} />
      </div>
    </div>
  );
}

function DragingOverlay(props: {
  ctrl: SwipeToWinBlockControlAPI;
  isDragOver: boolean;
}) {
  const { ctrl } = props;
  const curr = props.isDragOver;
  const prev = usePreviousDistinct(curr);

  useEffect(() => {
    if (curr && !prev) {
      ctrl.deps.sfxControl.play('swipeCardMatching');
    }
  }, [ctrl.deps.sfxControl, curr, prev]);

  return (
    <div
      className={`absolute inset-0 bg-black bg-opacity-20 pointer-events-none 
        transition-opacity flex justify-center ${
          props.isDragOver ? 'opacity-100 z-10' : 'opacity-0 z-0'
        }`}
    >
      <div className={`font-black italic mt-20`}>
        <Match />
      </div>
    </div>
  );
}

export function SwipeToWinBlockPlayground(props: {
  block: SwipeToWinBlock;
  ctrl: SwipeToWinBlockControlAPI;
}) {
  const { block, ctrl } = props;
  const { status, visibility, cta } = useSnapshot(ctrl.state);
  const iframe = useDevicePreviewFrame();

  const [currQuestion, setCurrQuestion] = useState<QuestionViewItem | null>(
    null
  );
  const [currAnswer, setCurrAnswer] = useState<AnswerViewItem | null>(null);

  const [isDragOver, setDragOver] = useState(false);

  useEffectOnce(() => {
    ctrl.present();
  });

  const handleSubmit = () => {
    ctrl.logger.info('try match from button', {
      question: ValtioUtils.detachCopy(currQuestion),
      answer: ValtioUtils.detachCopy(currAnswer),
    });
    if (!currQuestion || !currAnswer) return;
    ctrl.match(currQuestion, currAnswer);
  };

  return (
    <>
      {visibility === 'visible' && <Background />}
      <DndProvider
        backend={MultiBackend}
        context={iframe?.contentWindow}
        options={HTML5toTouch}
      >
        <div className='relative w-full h-full min-h-0 flex flex-col'>
          <main
            className={`
          w-full flex-1 min-h-0 px-1 pt-10 flex flex-col justify-start gap-5 md:gap-10
          transition-opacity duration-500 relative ${
            visibility === 'visible' ? 'opacity-100' : 'opacity-0'
          }
        `}
          >
            <div className='w-full h-15 relative flex items-center justify-center'>
              <div className='text-white text-center break-words text-base sm:text-xl lg:text-2xl'>
                {block.fields.instruction}
              </div>
            </div>
            <QuestionCardCarousel
              {...props}
              setDragOver={setDragOver}
              setCurrQuestion={setCurrQuestion}
              context={iframe?.contentWindow ?? undefined}
            />
            <AnswerCardCarousel
              {...props}
              setDragOver={setDragOver}
              setCurrAnswer={setCurrAnswer}
              context={iframe?.contentWindow ?? undefined}
            />
          </main>
        </div>
        <footer
          className={`'w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5 transition-opacity duration-500 relative ${
            visibility === 'visible' ? 'opacity-100' : 'opacity-0'
          } absolute bottom-0 transform -translate-y-full`}
        >
          {cta === 'submit' ? (
            <CommonButton
              variant='brand'
              onClick={handleSubmit}
              disabled={status !== 'play'}
            >
              Match (or swipe up)
            </CommonButton>
          ) : (
            <CommonButton
              variant='correct'
              onClick={() => ctrl.end()}
              className={
                status === 'inform' || status === 'complete'
                  ? 'opacity-100'
                  : 'opacity-0 pointer-events-off'
              }
              disabled={status === 'inform'}
            >
              Continue
            </CommonButton>
          )}
        </footer>
        <MatchingResult ctrl={ctrl} />
        <AnswerCardDragPreview />
        <DragingOverlay ctrl={ctrl} isDragOver={isDragOver} />
      </DndProvider>
    </>
  );
}

function getCompletionPrompt(context: string) {
  return `
\`\`\`openai { "model": "gpt-4o-mini", "temperature": 1, "maxTokens": 1024 }
You are a scriptwriter for an online platform called Luna Park. A user is playing a matching game where the must match card pairs. Assume they've found all the matches. Your job is to write a script that informs the user they are all done.

Your script should be short and creative. It should be positive and congratulatory, but not overly so. Assume the user has been playing games before this one, and that they will play more in the future, so the script should not be so final.

I will provide you context related to what the game was about. Never repeat the context in its entirety, but you may broadly reference the context in your script. Never include any information that is not directly related to the context.

<example>
Context: Connect the part names with their corresponding function.
Output: Hey, nice job! You've found everything.
</example>

<example>
Context: Match the database concepts on the left to their descriptions on the right
Output: Keep it up! Looks like you selected everything correctly!
</example>

Output only the script, with nothing before or after. 

Context: ${context}
\`\`\`
`.trim();
}
