import { useLoaderData } from '@remix-run/react';

import { useUserAnalytics } from '../analytics/user';
import { AdminAccess } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { ProvidersList } from '../components/ProvidersList';
import { TrainingAIMeidaTool } from '../components/Training/Playground';
import { UserContextProvider } from '../components/UserContext';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';

export async function clientLoader() {
  const resp = await apiService.aiGeneral.getGlobalSettings();
  return { ...resp.data };
}

export function Component() {
  const { imageGenProviders } = useLoaderData<typeof clientLoader>();
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <AdminAccess />,
  ];

  return (
    <ProvidersList providers={providers}>
      <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
        <AdminToolkitNav />

        <header className='text-white text-3.5xl font-bold'>
          Training Media Tool
        </header>
        <TrainingAIMeidaTool imageGenProviders={imageGenProviders} />
      </AdminView>
      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
