import debounce from 'lodash/debounce';
import uniqWith from 'lodash/uniqWith';
import { useEffect,useMemo, useRef, useState } from 'react';
import { components, type MultiValue, type ValueContainerProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { match } from 'ts-pattern';

import {
  apiService,
  type GetOrganizersOptions,
} from '../../services/api-service';
import { type Organizer, OrganizerUtils } from '../../types';
import { assertExhaustive, isValidEmail } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';

type OptionOrganizer = {
  kind: 'organizer';
  organizer: Organizer;
};

type OptionNew = {
  kind: undefined;
  __isNew__: true;
  value: string;
};

export type OrganizerSelectOption = OptionOrganizer | OptionNew;

async function search(
  orgId: string,
  options: GetOrganizersOptions,
  callback: (organizers: OrganizerSelectOption[]) => void
) {
  const paginator = apiService.organization.getOrganizers(orgId, options);
  const organizers = await paginator.next();
  if (!organizers) {
    callback([]);
    return;
  }
  callback(organizers.map((o) => ({ kind: 'organizer', organizer: o })));
}

const debounceSearch = debounce(search, 250);

type OptionPrefixFormatter = (option: OrganizerSelectOption) => string;

function Menu(props: {
  option: OrganizerSelectOption;
  prefixFormatter?: OptionPrefixFormatter;
}) {
  const { option, prefixFormatter } = props;
  const prefix = prefixFormatter?.(option);
  return match(option)
    .with({ kind: 'organizer' }, (option) => (
      <div className='ml-4 w-full flex flex-col justify-start'>
        <p className='text-sms font-normal text-white'>
          {prefix}
          {OrganizerUtils.GetDisplayName(option.organizer)}
        </p>
        <p className='text-3xs font-medium text-secondary'>
          {option.organizer.email}
        </p>
      </div>
    ))
    .with({ __isNew__: true }, (option) => (
      <div className='ml-4 w-full flex flex-col justify-start'>
        <p className='text-sms font-normal text-white'>
          Invite {prefix}
          {option.value}
        </p>
      </div>
    ))
    .exhaustive();
}

function AutoScrollValueContainer(
  props: ValueContainerProps<OrganizerSelectOption, true>
) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [props.children]);

  return (
    <components.ValueContainer
      {...props}
      innerProps={{
        ...props.innerProps,
        ref: containerRef,
      }}
    >
      {props.children}
    </components.ValueContainer>
  );
}

export interface OrganizerMultiSelectProps {
  orgId: string;
  options: OrganizerSelectOption[];
  onChange: (value: OrganizerSelectOption[]) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  isOptionDisabled?: (option: OrganizerSelectOption) => boolean;
  filterOrganizer?: (organizer: Organizer) => boolean;
  creatable?: boolean;
  maxAttendees?: number;
  scrollable?: {
    maxHeight: number;
  };
  orgSeatCapped?: boolean;
}

export function OrganizerMultiSelect(
  props: OrganizerMultiSelectProps
): JSX.Element {
  const {
    orgId,
    options,
    onChange,
    placeholder,
    className,
    disabled,
    isOptionDisabled,
    filterOrganizer,
    creatable,
    maxAttendees,
    scrollable,
    orgSeatCapped,
  } = props;

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = async (input: string) => {
    if (input.includes(',')) {
      const emails = uniqWith(
        input.split(',').map((e) => e.trim()),
        (a, b) => a.toLowerCase() === b.toLowerCase()
      ).filter(
        (email) =>
          !!email &&
          !options.some((o) =>
            match(o)
              .with(
                { kind: 'organizer' },
                (o) => o.organizer.email.toLowerCase() === email.toLowerCase()
              )
              .with(
                { kind: undefined },
                (o) => o.value.toLowerCase() === email.toLowerCase()
              )
              .otherwise(() => false)
          )
      );
      const validEmails = emails.filter((e) => isValidEmail(e));

      const resp = await apiService.organization.getOrganizersByEmails(
        orgId,
        validEmails
      );
      const existingOrganizers = resp.data.organizers;

      const newOptions = [...options];
      for (const email of emails) {
        const organizer = existingOrganizers.find(
          (o) => o.email.toLowerCase() === email.toLowerCase()
        );

        if (organizer) {
          if (!filterOrganizer || filterOrganizer(organizer)) {
            newOptions.push({ kind: 'organizer', organizer });
          }
        } else {
          newOptions.push({ kind: undefined, __isNew__: true, value: email });
        }
      }
      onChange(newOptions);
      setInputValue('');
      return;
    } else {
      setInputValue(input);
    }
  };

  const styles = useMemo(
    () =>
      buildReactSelectStyles<OrganizerSelectOption, true>({
        override: {
          control: {
            minHeight: 48,
          },
          multiValue: (data, index) => {
            if (orgSeatCapped && data.kind === undefined && data.__isNew__) {
              return {
                background: '#ee3529',
              };
            }

            return {
              background:
                maxAttendees !== undefined && index >= maxAttendees
                  ? '#FBB707'
                  : '#01ACC4',
            };
          },
          valueContainer: {
            ...(scrollable && {
              width: '100%',
              padding: '10px',
              overflow: 'auto',
              maxHeight: scrollable.maxHeight,
            }),
          },
        },
      }),
    [maxAttendees, scrollable, orgSeatCapped]
  );

  function getOptionValue(option: OrganizerSelectOption) {
    return match(option)
      .with({ kind: 'organizer' }, (opt) => opt.organizer.uid)
      .with({ __isNew__: true }, (opt) => opt.value)
      .exhaustive();
  }

  const filterOption = (option: OrganizerSelectOption) => {
    const kind = option.kind;
    switch (kind) {
      case 'organizer':
        return !filterOrganizer || filterOrganizer(option.organizer);
      case undefined:
        return true;
      default:
        assertExhaustive(kind);
        return true;
    }
  };

  const prefixFormatter = (option: OrganizerSelectOption) => {
    if (!creatable) return '';
    const kind = option.kind;
    switch (kind) {
      case 'organizer':
        return '';
      case undefined:
        return '✉️ ';
      default:
        assertExhaustive(kind);
        return '';
    }
  };

  function formatOptionValue(
    option: OrganizerSelectOption,
    prefixFormatter?: OptionPrefixFormatter
  ) {
    const formatted = match(option)
      .with({ kind: 'organizer' }, (opt) =>
        OrganizerUtils.GetDisplayName(opt.organizer)
      )
      .with({ __isNew__: true }, (opt) => opt.value)
      .exhaustive();
    return `${prefixFormatter?.(option)}${formatted}`;
  }

  const handleChange = (value: MultiValue<OrganizerSelectOption>) => {
    onChange(value.slice());
  };

  const Select = creatable ? AsyncCreatableSelect : AsyncSelect;

  return (
    <Select<OrganizerSelectOption, true>
      placeholder={placeholder}
      styles={styles}
      cacheOptions
      isMulti
      inputValue={inputValue}
      onInputChange={handleInputChange}
      loadOptions={(q, callback) => {
        debounceSearch(orgId, { size: 100, q }, callback);
      }}
      classNamePrefix='select-box-v2'
      className={`h-full m-0 ${className}`}
      value={options}
      defaultOptions
      closeMenuOnSelect={false}
      onChange={handleChange}
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No members matched';
      }}
      getOptionValue={getOptionValue}
      formatOptionLabel={(option, meta) =>
        match(meta.context)
          .with('value', () => formatOptionValue(option, prefixFormatter))
          .with('menu', () => (
            <Menu option={option} prefixFormatter={prefixFormatter} />
          ))
          .exhaustive()
      }
      isDisabled={disabled}
      isOptionDisabled={isOptionDisabled}
      filterOption={(option) => filterOption(option.data)}
      isValidNewOption={(v) => isValidEmail(v)}
      components={{
        ValueContainer: AutoScrollValueContainer,
      }}
    />
  );
}
