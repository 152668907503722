import { Link } from '@remix-run/react';
import { useState } from 'react';
import { $path } from 'remix-routes';

import {
  ArrowDownIcon,
  ArrowRightIcon,
} from '../../src/components/icons/Arrows';
import { ChatSingleBubbleIcon } from '../../src/components/icons/Chat/ChatBubbleIcon';
import { getStaticAssetPath } from '../../src/utils/assets';
import { makeMarketLink } from '../../src/utils/makeLinks';
import { HelpWidget } from './HelpWidget/HelpWidget';

type PrimaryNavItem = {
  name: string;
  children?: SecondaryNavItem[];
};

type SecondaryNavItem = {
  name: string;
  title?: string;
  description?: string;
  children?: TertiaryNavItem[];
  characterSrc?: string;
};

type TertiaryNavItem = {
  name: string;
  description: string;
  href?: string;
  highlight?: boolean;
};

function makeExploreLink(anchor?: string) {
  if (!anchor) return $path('/explore');
  return `${$path('/explore')}#${anchor}`;
}

const PRODUCT: PrimaryNavItem = {
  name: 'Product',
  children: [
    {
      name: 'Engagement Tools',
      title: 'Boost Engagement',
      description: 'Comprehensive Culture Toolkit for Modern Teams',
      characterSrc: getStaticAssetPath('images/anon-nav/characters-2.png'),
      children: [
        {
          name: '🛠️ Slack / Teams Programs',
          description: 'Boost Daily Conversation & Connection',
          href: makeExploreLink('slack-programs'),
        },
        {
          name: '🎯 Team Building Library',
          description: 'Empower Leaders to Connect Anytime',
          href: makeExploreLink(),
        },
        {
          name: '🌈 Diversity Equity & Inclusion',
          description: 'Celebrate Diversity, Foster Inclusion',
          href: makeExploreLink('diversity-equity-and-inclusion'),
        },
        {
          name: '🟦 Zoom App for Meetings',
          description: 'Break the Ice in Your Standups, 1:1s & All Hands',
        },
        {
          name: '🎨 Self-Serve Custom Games',
          description: 'Personalize Experiences Just for You',
          href: makeMarketLink(
            '/experiences/3Pjz9uRBgr5J8JrywEGtXw?tag=jeoparky-623'
          ),
        },
        {
          name: '🍾 Marquee Events',
          description: 'Live Hosted Social Games & Icebreakers',
        },
      ],
    },
    {
      name: 'Content Experience',
      title: 'Unlimited Access',
      description: 'Live Hosted Social Games & Icebreakers',
      characterSrc: getStaticAssetPath('images/anon-nav/characters-1.png'),
      children: [
        {
          name: '🔑 Escape Rooms',
          description: 'Solve Mysteries, Strengthen Team Bonds',
          href: makeExploreLink('virtual-escape-rooms'),
        },
        {
          name: '📚 Trivia',
          description: 'Test Your Team with Unmatched Trivia',
          href: makeExploreLink('virtual-team-trivia'),
        },
        {
          name: '🎨 Custom Games',
          description: 'Personalized Experiences Just for You',
          href: makeMarketLink(
            '/experiences/3Pjz9uRBgr5J8JrywEGtXw?tag=jeoparky-623'
          ),
          highlight: true,
        },
        // {
        //   name: '🌈 DEI',
        //   description: 'Celebrate Diversity, Foster Inclusion',
        //   href: makeExploreLink('diversity-equity-and-inclusion'),
        // },
        {
          name: '⛄ Seasonal',
          description: 'Discover Events for Every Occasion',
          href: makeExploreLink('seasonal-experiences'),
        },
        {
          name: '🧠 Jeopardy',
          description: 'Compete and Unite with Jeopardy!',
          href: makeExploreLink('custom-jeoparky'),
        },
        {
          name: '🧊 Ice Breakers',
          description: 'Foster New Relationships',
          href: makeExploreLink('icebreakers'),
        },
      ],
    },
  ],
};

const USE_CASE: PrimaryNavItem = {
  name: 'Use Case',
  children: [
    {
      name: 'Onboarding',
      title: 'New Hire Onboarding',
      description: 'Engage Hires Instantly & Effectively',
      characterSrc: getStaticAssetPath('images/anon-nav/characters-3.png'),
      children: [
        {
          name: '🧊 Ice Breakers',
          description: 'Foster New Relationships Effortlessly',
          href: makeExploreLink('icebreakers'),
        },
        {
          name: '👋 Intros Program',
          description: 'Automate Team Introductions to Build Camaraderie',
          href: makeMarketLink('/explore/programs/01HF6FE96KWJN09TNK73P4CEZB'),
        },
      ],
    },
    {
      name: 'Engage & Develop',
      title: 'Engage & Develop Your Team',
      description: 'Empower, Engage, and Retain Top Performers',
      characterSrc: getStaticAssetPath('images/anon-nav/characters-4.png'),
      children: [
        {
          name: '🎯 Team Building Games',
          description: '100s of Social Games for Every Moment',
          href: makeExploreLink(),
        },
        {
          name: '💬 Water Cooler Convos',
          description: 'Handcrafted Daily Conversation Starters',
          href: makeMarketLink('/explore/programs/01HF6FF78X0Q5QZ2QMNA1RCE4T'),
        },
        {
          name: '🌈 Diversity Equity & Inclusion',
          description: 'Celebrate Diversity, Foster Inclusion',
          href: makeExploreLink('diversity-equity-and-inclusion'),
        },
        {
          name: '🙌 Global Pairings',
          description: 'Connect, Collaborate, Compete...Repeat',
          href: makeMarketLink('/explore/programs/01HEW38JNAVS7WZFPYT0FNVBDP'),
        },
        {
          name: '🟦 Zoom App for Meetings',
          description:
            'Integrate Ice Breakers During 1:1s, Standup and All Hands Meetings',
        },
      ],
    },
    {
      name: 'Reward & Recognize',
      title: 'Reward & Recognize Your Team',
      description: 'Fun & Thoughtful Employee Recognition',
      characterSrc: getStaticAssetPath('images/anon-nav/characters-5.png'),
      children: [
        {
          name: '🎉 Company Celebrations',
          description: 'Honor Your Team With Ease',
          href: makeExploreLink('company-celebrations'),
        },
        {
          name: '🍦 Ice-Cream Shout-Outs',
          description: 'Gamify A Culture of Recognition',
          href: makeMarketLink('/explore/programs/01HF6FGJ7EJZ5QRPTKSKXDWY0C'),
        },
        {
          name: '🎂 Birthday’s & Anniversaries',
          description: 'Never Miss A Team Milestone',
          href: makeMarketLink('/explore/programs/01HEW38JNAVS7WZFPYT1JK1V66'),
        },
      ],
    },
  ],
};

// const RESOURCES: PrimaryNavItem = {
//   name: 'Resources',
//   children: [
//     {
//       name: 'Company Culture',
//       title: 'Company Culture & Celebrations',
//       children: [
//         {
//           name: '🎉 Company Celebrations',
//           description: 'Elevate Team Celebrations Instantly',
//         },
//         {
//           name: '🍂 Seasonal Occasions',
//           description: 'Celebrate Throughout the Year',
//         },
//         {
//           name: '👏 Company Camaraderie',
//           description: 'Establish Teamwide Connection through Play',
//         },
//         {
//           name: '🌎 Intentional Icebreakers',
//           description: 'Make Connection Personal, Engaging & Interactive',
//         },
//       ],
//     },
//     {
//       name: 'Employee Engagement',
//       children: [
//         {
//           name: '🌎 Intern Engagement',
//           description: 'Learn How The Best Are Doing It',
//         },
//         {
//           name: '👋 Team Intros',
//           description: "Overcome Donut's Shortcomings w/ Luna Park",
//         },
//         {
//           name: '🙌 Teamwide Engagement',
//           description: 'Explore Social Connection Touchpoints',
//         },
//         {
//           name: '💬 ERG Connection',
//           description: 'Stimulate Intentional Connections Orgwide',
//         },
//         {
//           name: '🍦 Gamifying Recognition',
//           description: 'Logic behind Ice Cream Shoutouts',
//         },
//       ],
//     },
//     {
//       name: 'Leadership & Management',
//       description: 'Fun & Thoughtful Employee Recogntion',
//       children: [
//         {
//           name: '💪 Empowering Managers',
//           description: 'Drive Engagement Bottom Up',
//         },
//         {
//           name: '💬 Stress Free 1:1s',
//           description: 'Efficient 1:1 Engagement Solutions',
//         },
//         {
//           name: '💼 C Level Connection',
//           description: 'Increase C Level Visibility & Connection',
//         },
//       ],
//     },
//     {
//       name: 'Onboarding, Development & Analytics',
//       children: [
//         {
//           name: '👋 Employee Onboarding',
//           description: 'Streamline Onboarding Success',
//         },
//         {
//           name: '📊 Transparent Analytics',
//           description: 'Explore Engagement Data',
//         },
//         {
//           name: '🔍 Making The Case',
//           description: 'How to Position Luna Park',
//         },
//       ],
//     },
//     {
//       name: 'Hear from Our Customers',
//       description: 'Fun & Thoughtful Employee Recogntion',
//       children: [
//         {
//           name: '📝 Superside Case Study',
//           description: 'See How A Global Org Achieves 63% eNPS',
//         },
//         {
//           name: '💗 Wall of Love',
//           description: 'Feedback from Luna Park Customers',
//         },
//       ],
//     },
//   ],
// };

function TertiaryNavButton(props: { item: TertiaryNavItem }) {
  const { item } = props;

  if (!item.href) return null;
  return (
    <Link
      to={item.href}
      className={`btn cursor-pointer w-[270px] h-fit-content px-3 pt-2 pb-2.5 flex flex-col gap-2 rounded-lg ${
        item.highlight ? 'bg-[#00072D]' : ''
      }  hover:bg-lp-gray-002`}
    >
      <div className='text-base font-bold'>{item.name}</div>
      <div className='text-sms text-icon-gray'>{item.description}</div>
    </Link>
  );
}

function SecondaryNavButton(props: {
  item: SecondaryNavItem;
  active: boolean;
  onHover?: () => void;
}) {
  const { item, active, onHover } = props;

  return (
    <Link
      to={$path('/explore')}
      onMouseEnter={onHover}
      className={`
          btn w-full p-4 rounded-lg 
          group
          flex justify-between items-center 
          ${
            active ? 'bg-lp-gray-009 font-bold' : ''
          } hover:bg-lp-gray-002 hover:text-red-006 text-left`}
    >
      <p>{item.name}</p>

      <ArrowRightIcon
        className={`w-4.5 h-4.5 fill-current ${
          active ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
        }`}
      />
    </Link>
  );
}

function SecondaryNavDetail(props: {
  item: SecondaryNavItem;
  active: boolean;
}) {
  const { item, active } = props;

  return (
    <div className={`flex-1 pt-1 ${active ? 'block' : 'hidden'}`}>
      <div>
        <div className='text-xl font-bold'>{item.title ?? item.name}</div>
        <div className='text-sms'>{item.description}</div>
      </div>
      <hr className='my-2.5 border-secondary' />
      <div className='flex flex-wrap gap-2.5'>
        {item.children?.map((child) => (
          <TertiaryNavButton key={child.name} item={child} />
        ))}
      </div>
    </div>
  );
}

function PrimaryNavButton(props: {
  icon?: (props: { className: string }) => React.ReactNode;
  text: string;
  withArrow?: boolean;
  active?: boolean;
  onClick?: () => void;
}) {
  const { text, icon, withArrow, active, onClick } = props;

  return (
    <button
      type='button'
      onClick={onClick}
      className={`
        relative h-full
        border-t-2 border-t-transparent
        border-b-2 ${active ? 'border-b-white' : 'border-b-transparent'}
        flex items-center justify-center
        text-sms font-Montserrat
        transition-all duration-300 transform-gpu
      `}
    >
      <div className='opacity-0 flex justify-center items-center gap-1'>
        {icon && <div className='w-3 h-3'></div>}
        <div className='font-bold'>{text}</div>
        {withArrow && <div className='w-3.5 h-3.5'></div>}
      </div>

      <div className='absolute flex justify-center items-center gap-1'>
        {icon &&
          icon({
            className: active
              ? `w-3 h-3 fill-current`
              : ` w-2.5 h-2.5 fill-current`,
          })}
        <div className={active ? 'font-bold' : ''}>{text}</div>
        {withArrow && (
          <div
            className={`transform transform-gpu ${
              active ? '-rotate-180' : ''
            } transition-transform`}
          >
            <ArrowDownIcon className='w-3.5 h-3.5 fill-current' />
          </div>
        )}
      </div>
    </button>
  );
}

function PrimaryNav(props: { item: PrimaryNavItem }) {
  const { item } = props;

  const [active, setActive] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const activeChild = item.children?.[activeIndex];

  return (
    <div
      className='relative h-full'
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <PrimaryNavButton text={item.name} withArrow active={active} />

      <div
        className={`
          ${active ? 'flex gap-5' : 'hidden'}
          fixed z-50 top-14 left-0 right-0 min-h-75 
          py-5 pl-40 pr-40
        `}
        style={{
          background: 'linear-gradient(180deg, #000 0%, #232323 100%)',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        {active && activeChild?.characterSrc && (
          <div className='absolute bottom-0 left-0'>
            <img
              src={activeChild.characterSrc}
              className='w-50 h-[156px] object-contain'
              alt=''
            />
          </div>
        )}

        <div className='w-70 flex flex-col gap-2.5'>
          {item.children?.map((child, index) => (
            <SecondaryNavButton
              key={child.name}
              item={child}
              active={activeIndex === index}
              onHover={() => setActiveIndex(index)}
            />
          ))}
        </div>

        {item.children?.map((child, index) => (
          <SecondaryNavDetail
            key={child.name}
            item={child}
            active={activeIndex === index}
          />
        ))}
      </div>
    </div>
  );
}

export function AnonNav() {
  const [openHelpWidget, setOpenHelpWidget] = useState(false);

  const [pricingActive, setPricingActive] = useState(false);
  const [liveChatActive, setLiveChatActive] = useState(false);

  return (
    <div className='h-full flex items-center gap-7.5'>
      <PrimaryNav item={PRODUCT} />
      <PrimaryNav item={USE_CASE} />
      {/* <PrimaryNav item={RESOURCES} /> */}

      <a
        className='h-full'
        href={makeMarketLink('/pricing/')}
        onMouseEnter={() => setPricingActive(true)}
        onMouseLeave={() => setPricingActive(false)}
      >
        <PrimaryNavButton text='Pricing' active={pricingActive} />
      </a>

      <div
        className='h-full'
        onMouseEnter={() => setLiveChatActive(true)}
        onMouseLeave={() => setLiveChatActive(false)}
      >
        <PrimaryNavButton
          text='Live Chat'
          icon={ChatSingleBubbleIcon}
          onClick={() => setOpenHelpWidget(true)}
          active={liveChatActive}
        />
      </div>

      <HelpWidget open={openHelpWidget} setOpen={setOpenHelpWidget} />
    </div>
  );
}
