import { useRef, useState } from 'react';

import { EnumsSlideLayout } from '@lp-lib/api-service-client/public';
import { BlockType, type BlockTypeToFields } from '@lp-lib/game';

import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { getStaticAssetPath } from '../../../utils/assets';
import { SparkBlockIcon } from '../../icons/Block';
import { ToolsIcon } from '../../icons/ToolsIcon';

function SlideChoice(props: {
  icon: React.ReactNode;
  title: string;
  description: string;
  previewImageUrl: string;
  onClick: () => void;
  onPreview: (preview: SlideTypePreview | null) => void;
  disabled?: boolean;
}) {
  const {
    icon,
    title,
    description,
    previewImageUrl,
    onClick,
    onPreview,
    disabled,
  } = props;

  return (
    <button
      type='button'
      onClick={onClick}
      onMouseEnter={() => onPreview({ title, description, previewImageUrl })}
      onMouseLeave={() => onPreview(null)}
      className='w-full flex items-center gap-2.5 disabled:opacity-60 p-0.5 hover:bg-lp-black-003 rounded-lg'
      disabled={disabled}
    >
      {icon}
      <div className='flex flex-col gap-0.5'>
        <div className='text-sm font-bold'>{title}</div>
      </div>
    </button>
  );
}

function SlideSection(props: {
  title: React.ReactNode;
  children: React.ReactNode;
}) {
  const { title, children } = props;

  return (
    <section className='space-y-2.5'>
      <div className='font-medium text-2xs text-icon-gray'>{title}</div>
      {children}
    </section>
  );
}

type SlideTypePreview = {
  title: string;
  description: string;
  previewImageUrl: string;
};

function SlideTypeChooser(props: {
  onAddSlideGroup: () => void;
  onAddBlock: <T extends BlockType>(
    blockType: T,
    fields?: Partial<BlockTypeToFields<T>>
  ) => void;
  onCancel: () => void;
}) {
  const { onAddSlideGroup, onAddBlock, onCancel } = props;
  const ref = useRef<null | HTMLDivElement>(null);
  const [preview, setPreview] = useState<SlideTypePreview | null>(null);

  useOutsideClick(ref, () => onCancel());

  return (
    <div
      ref={ref}
      className={`
        w-174 max-h-[75vh]
        bg-main-layer border border-secondary rounded-xl 
        text-white
        flex
      `}
    >
      <div className='flex-none w-1/2 min-h-0 py-1 pr-2 border-r border-secondary'>
        <div className='w-full h-full pl-5 py-4 pr-2 overflow-y-auto space-y-5 scrollbar'>
          <SlideSection title='Structure'>
            <SlideChoice
              icon={
                <div className='w-6 h-6 flex items-center justify-center rounded-md bg-[#FBB707] text-white'>
                  <ToolsIcon className='w-2/3 h-2/3 fill-current block-v2-icon' />
                </div>
              }
              title='Section'
              description='Organize your course by grouping slides into clear sections.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-section-preview.png'
              )}
              onClick={onAddSlideGroup}
              onPreview={setPreview}
            />
          </SlideSection>

          <SlideSection title='Slides'>
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.SLIDE} />}
              title='Info Slide'
              description='Display key information using a variety of styles and layouts.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-info-preview.png'
              )}
              onClick={() => onAddBlock(BlockType.SLIDE)}
              onPreview={setPreview}
            />
            <SlideChoice
              icon={
                <SparkBlockIcon
                  blockType={BlockType.SLIDE}
                  fields={{ layout: EnumsSlideLayout.SlideLayoutBigMedia }}
                />
              }
              title='Video Slide'
              description='Add engaging video content to enhance your course material.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-video-preview.png'
              )}
              onClick={() =>
                onAddBlock(BlockType.SLIDE, {
                  layout: EnumsSlideLayout.SlideLayoutBigMedia,
                })
              }
              onPreview={setPreview}
            />
            <SlideChoice
              icon={
                <SparkBlockIcon
                  blockType={BlockType.SLIDE}
                  fields={{ layout: EnumsSlideLayout.SlideLayoutFreeForm }}
                />
              }
              title='Content Slide'
              description='Present detailed content with flexible formatting options.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-content-preview.png'
              )}
              onClick={() =>
                onAddBlock(BlockType.SLIDE, {
                  layout: EnumsSlideLayout.SlideLayoutFreeForm,
                })
              }
              onPreview={setPreview}
            />
          </SlideSection>

          <SlideSection title='Interactive'>
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.QUESTION} />}
              title='Question'
              description='Challenge learners to type in answers and get graded for accuracy.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-question-preview.png'
              )}
              onClick={() => onAddBlock(BlockType.QUESTION)}
              onPreview={setPreview}
            />
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.MULTIPLE_CHOICE} />}
              title='Multiple Choice'
              description='Test knowledge with customizable multiple-choice options.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-mc-preview.png'
              )}
              onClick={() => onAddBlock(BlockType.MULTIPLE_CHOICE)}
              onPreview={setPreview}
            />
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.MEMORY_MATCH} />}
              title='Memory Match'
              description='Match words or phrases together to reinforce key concepts in a fun, interactive way.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-mm-preview.png'
              )}
              onClick={() => onAddBlock(BlockType.MEMORY_MATCH)}
              onPreview={setPreview}
            />
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.DRAW_TO_WIN} />}
              title='Draw to Win'
              description='Use your cursor to draw on the image and mark the correct spot to win.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-dtw-preview-v3.png'
              )}
              onClick={() => onAddBlock(BlockType.DRAW_TO_WIN)}
              onPreview={setPreview}
            />
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.HIDDEN_PICTURE} />}
              title='Hot Spot'
              description='Add clickable regions to images—perfect for identifying landmarks or selecting key components.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-hp-preview.png'
              )}
              onClick={() => onAddBlock(BlockType.HIDDEN_PICTURE)}
              onPreview={setPreview}
            />
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.FILL_IN_THE_BLANKS} />}
              title='Fill in the Blanks'
              description='Challenge learners to complete sentences with missing words.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-fitb-preview.png'
              )}
              onClick={() => onAddBlock(BlockType.FILL_IN_THE_BLANKS)}
              onPreview={setPreview}
            />
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.SWIPE_TO_WIN} />}
              title='Swipe to Win'
              description='Swipe through cards, matching each one to the correct image at the top.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-stw-preview.png'
              )}
              onClick={() => onAddBlock(BlockType.SWIPE_TO_WIN)}
              onPreview={setPreview}
            />
          </SlideSection>

          <SlideSection title='Advanced'>
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.JEOPARDY} />}
              title='Jeopardy'
              description='Gamify learning with a quiz-show format for friendly competition.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-jeopardy-preview.png'
              )}
              onClick={() => onAddBlock(BlockType.JEOPARDY)}
              onPreview={setPreview}
            />
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.ROLEPLAY} />}
              title='Role Play'
              description='Simulate scenarios to practice real-world conversations or skills.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-rp-preview.png'
              )}
              onClick={() => onAddBlock(BlockType.ROLEPLAY)}
              onPreview={setPreview}
            />
            <SlideChoice
              icon={<SparkBlockIcon blockType={BlockType.SPARKIFACT} />}
              title='Sparkifact'
              description='An experimental open ended coding block.'
              previewImageUrl={getStaticAssetPath(
                'images/spark-sparkifact-preview.png'
              )}
              onClick={() => onAddBlock(BlockType.SPARKIFACT)}
              onPreview={setPreview}
            />
          </SlideSection>
        </div>
      </div>
      <div className='flex-none w-1/2'>
        <div
          className={`p-6 text-white transition-opacity ${
            preview ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {preview && (
            <div className='space-y-2.5'>
              <div className='font-bold'>{preview?.title}</div>
              <div className='font-normal text-sms'>{preview?.description}</div>
              <div className='bg-secondary rounded-xl w-full overflow-hidden'>
                <img
                  src={preview?.previewImageUrl}
                  alt='Preview'
                  className='w-full h-full object-cover rounded-xl'
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function NewSlideButton(props: {
  onAddSlideGroup: () => void;
  onAddBlock: <T extends BlockType>(
    blockType: T,
    fields?: Partial<BlockTypeToFields<T>>
  ) => void;
}): JSX.Element {
  const { onAddSlideGroup, onAddBlock } = props;

  const [open, setOpen] = useState(false);

  return (
    <div className='relative w-full'>
      <button
        type='button'
        onClick={() => setOpen(!open)}
        className='w-full h-10 btn-secondary text-sms'
      >
        + New Slide
      </button>

      {open && (
        <div className={`z-10 absolute left-full ml-7 -top-2`}>
          <SlideTypeChooser
            onAddSlideGroup={() => {
              onAddSlideGroup();
              setOpen(false);
            }}
            onAddBlock={(blockType, fields) => {
              onAddBlock(blockType, fields);
              setOpen(false);
            }}
            onCancel={() => setOpen(false)}
          />
        </div>
      )}
    </div>
  );
}
