import { useNavigate } from '@remix-run/react';
import { useCallback } from 'react';
import { $path } from 'remix-routes';

import { getLogger } from '../../../logger/logger';
import { apiService } from '../../../services/api-service';
import type { EnrollmentTarget } from './Modals/CourseAssignmentModal';
import { useAssignmentResultsModal } from './useAssignmentResultsModal';
import { useTriggerEnrollmentModal } from './useTriggerCourseAssignmentModal';

const logger = getLogger().scoped('EnrollmentActions');

export function useEnrollmentActions() {
  const navigate = useNavigate();
  const triggerEnrollmentModal = useTriggerEnrollmentModal();
  const triggerAssignmentResultsModal = useAssignmentResultsModal();

  async function assignObject(
    target: EnrollmentTarget,
    mode: 'email' | 'group',
    items: string[],
    createGroup?: boolean,
    newGroupName?: string
  ) {
    if (target.type === 'course') {
      // course
      if (mode === 'email') {
        const resp = await apiService.learning.assignCourse({
          courseId: target.id,
          emails: mode === 'email' ? items : [],
          groupName: createGroup ? newGroupName : undefined,
        });
        return resp.data;
      } else {
        const resp = await apiService.learning.assignCourseToGroups({
          groupIds: items,
          objectId: target.id,
        });
        return resp.data;
      }
    } else {
      // stack
      if (mode === 'email') {
        const resp = await apiService.learning.assignStack({
          stackId: target.id,
          emails: mode === 'email' ? items : [],
          groupName: createGroup ? newGroupName : undefined,
        });
        return resp.data;
      } else {
        const resp = await apiService.learning.assignStackToGroups({
          groupIds: items,
          objectId: target.id,
        });
        return resp.data;
      }
    }
  }

  const handleAssign = useCallback(
    (target: EnrollmentTarget, onComplete?: () => void) => {
      triggerEnrollmentModal({
        enrollmentTarget: target,
        onAssigned: async ({ mode, items, createGroup, newGroupName }) => {
          try {
            const result = await assignObject(
              target,
              mode,
              items,
              createGroup,
              newGroupName
            );
            if ('assignedUsers' in result) {
              await triggerAssignmentResultsModal(result.assignedUsers);
            }

            if (onComplete) {
              onComplete();
            }
          } catch (error) {
            onComplete?.();
            logger.error('Failed to assign:', error);
          }
        },
      });
    },
    [triggerEnrollmentModal, triggerAssignmentResultsModal]
  );

  const handleEdit = useCallback(
    (id: string) => {
      navigate($path('/trainings/:id/edit', { id }));
    },
    [navigate]
  );

  return {
    handleAssign,
    handleEdit,
  };
}
