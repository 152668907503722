import { Link } from '@remix-run/react';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import pluralize from 'pluralize';
import { useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { $path } from 'remix-routes';

import {
  type DtoOrganizer,
  EnumsEventType,
  type EnumsPageName,
} from '@lp-lib/api-service-client/public';

import { PageDisplayName } from '../../../../app/components/DynamicPage/utils';
import { isGamePackLaunched } from '../../../../app/components/GamePack/utils';
import { useEventSchedulerAnalytics } from '../../../analytics/eventScheduler';
import { getFeatureQueryParamNumber } from '../../../hooks/useFeatureQueryParam';
import { apiService } from '../../../services/api-service';
import { type Organization, OrganizerUtils } from '../../../types';
import { fromDTOGamePack, fromDTOOrganizer } from '../../../utils/api-dto';
import { joinNames } from '../../../utils/string';
import { Tooltip } from '../../common/Tooltip';
import { useTriggerGamePackPicker } from '../../Game/GamePackPicker';
import { PlayerRangeUtils } from '../../Game/PlayerRangeUtils';
import { GamePackCover } from '../../Game/Utilities';
import { CalendarIcon } from '../../icons/CalendarIcon';
import { FilledCheckIcon } from '../../icons/CheckIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { EditIcon } from '../../icons/EditIcon';
import { GameIcon } from '../../icons/GameIcon';
import { InfoIcon } from '../../icons/InfoIcon';
import { TeamIcon } from '../../icons/TeamIcon';
import { TimerIcon } from '../../icons/TimerIcon';
import { OrgSubscriptionUpgradeIcon } from '../../Organization';
import {
  OrganizerMultiSelect,
  type OrganizerSelectOption,
} from '../../Organization/OrganizerSelect';
import { useNavigateToSubscriptionChange } from '../../Product/utils';
import { useUser } from '../../UserContext';
import { useTriggerEventTimePicker } from '../EventTimePicker';
import { type EventScheduleSharedFormData } from './types';

export function EventScheduleGamePackField(props: {
  eventType: EnumsEventType;
  pageName: EnumsPageName;
  hasPermission: boolean;
}) {
  const { eventType, pageName, hasPermission } = props;

  const analytics = useEventSchedulerAnalytics();
  const triggerGamePackPicker = useTriggerGamePackPicker();
  const { setValue, watch } = useFormContext<EventScheduleSharedFormData>();
  const eventTime = watch('eventTime');

  const {
    field: { value: pack, onChange },
  } = useController<EventScheduleSharedFormData, 'pack'>({
    name: 'pack',
    rules: { required: true },
  });

  const openPicker = () => {
    analytics.trackGamePackSelectorViewed({
      eventType,
      pageName: PageDisplayName(pageName),
    });

    triggerGamePackPicker({
      pageName,
      onSelect: (p) => {
        onChange(p);
        if (eventTime && !isGamePackLaunched(p, eventTime.startDate)) {
          setValue('eventTime', null);
        }
      },
      onCancel: () => {
        analytics.trackGamePackSelectorDismissed({
          eventType,
          pageName: PageDisplayName(pageName),
        });
      },
    });
  };

  return (
    <div className='w-80 flex-none bg-lp-gray-009 p-10'>
      {!pack ? (
        <button
          type='button'
          onClick={openPicker}
          className='btn-primary w-full h-20 text-base flex flex-col items-center justify-center gap-2'
        >
          <GameIcon />
          <p>Choose Experiences</p>
        </button>
      ) : (
        <div className='flex flex-col items-center gap-6'>
          <div className='w-full flex-none'>
            <GamePackCover pack={fromDTOGamePack(pack)} />
          </div>

          <div className='w-full flex flex-col text-white gap-2'>
            <div className='flex items-center gap-2'>
              <div className='font-bold truncate'>{pack.name}</div>
              {!hasPermission && (
                <div className='flex-none'>
                  <OrgSubscriptionUpgradeIcon />
                </div>
              )}
            </div>
            {!hasPermission && (
              <div className='text-sm text-tertiary'>
                Scheduling this game requires upgrading your account.
              </div>
            )}
            {!isGamePackLaunched(pack) && (
              <div className='text-sm text-tertiary'>
                Launching{' '}
                {format(
                  new Date(pack.detailSettings?.availability?.launchDate || ''),
                  'M/d/yy'
                )}
              </div>
            )}
            <div className='text-sm text-icon-gray'>{pack.description}</div>
            <div className='w-full mt-2 flex items-center gap-1 text-xs text-icon-gray'>
              <span>
                {pluralize(
                  'Minute',
                  Math.round(pack.approximateDurationSeconds / 60),
                  true
                )}
              </span>
              <span>·</span>
              <span>{PlayerRangeUtils.Format(pack.playerRange)}</span>
            </div>
            <span
              className='cursor-pointer text-xs text-primary'
              onClick={openPicker}
            >
              Switch Game
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

function PendingConfirmationBadge() {
  return (
    <div className='relative rounded-[100px] bg-tertiary group px-2.5 py-1 h-7 flex justify-center items-center gap-2 text-black'>
      <div className='text-sms'>Confirmation Needed</div>
      <div className='relative flex justify-center'>
        <InfoIcon className='w-3.5 h-3.5 fill-current' color='black' />
        <div className='absolute invisible group-hover:visible transition-visibility z-5 bottom-full mb-2'>
          <Tooltip
            position={'top'}
            backgroundColor={'#FBB707'}
            borderRadius={12}
            arrowWidth={12}
            borderColor={'rgba(255, 255, 255, 0.4)'}
            borderWidth={1}
          >
            <div className='w-100 px-4.5 py-3 text-center'>
              <div className='font-bold'>Availability Confirmation Needed</div>
              <div>
                This time slot is currently pending confirmation. You will be
                notified once it is confirmed.
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export function EventScheduleEventTimeField(props: {
  eventType: EnumsEventType;
}) {
  const { eventType } = props;

  const analytics = useEventSchedulerAnalytics();
  const triggerEventTimePicker = useTriggerEventTimePicker();

  const {
    field: { value: eventTime, onChange },
  } = useController<EventScheduleSharedFormData, 'eventTime'>({
    name: 'eventTime',
    rules: { required: true },
  });
  const { watch } = useFormContext<EventScheduleSharedFormData>();
  const pack = watch('pack');

  const openSelector = () => {
    analytics.trackDateTimeSelectorViewed({
      eventType,
    });

    triggerEventTimePicker({
      eventType,
      eventTime,
      pack,
      onSelect: (eventTime) => onChange(eventTime),
      onCancel: () => analytics.trackDateTimeSelectorDismissed({ eventType }),
    });
  };

  return (
    <div className='w-full flex gap-4'>
      <div className='mt-1'>
        <CalendarIcon className='fill-current w-6 h-6' />
      </div>
      {!eventTime ? (
        <button
          type='button'
          onClick={openSelector}
          className='btn-secondary w-78 h-12.5'
        >
          Select Date & Time
        </button>
      ) : (
        <div
          className='
            w-full bg-modal border border-secondary rounded-xl
            p-5 flex justify-between items-center
          '
        >
          <div className='flex gap-2'>
            <div>
              <div className='text-xl font-bold'>
                {formatInTimeZone(
                  eventTime.startDate,
                  eventTime.timezone,
                  'EE, MMM d'
                )}
              </div>
              <div className='text-sms text-icon-gray'>{`${formatInTimeZone(
                eventTime.startDate,
                eventTime.timezone,
                'h:mm aa (z)'
              )}`}</div>
            </div>

            {eventType === EnumsEventType.EventTypeLive && (
              <PendingConfirmationBadge />
            )}
          </div>
          <div className='flex items-center gap-2'>
            <button
              type='button'
              onClick={openSelector}
              className='w-6 h-6 btn text-icon-gray hover:text-white flex justify-center items-center'
            >
              <EditIcon className='fill-current w-4 h-4' />
            </button>
            <button
              type='button'
              onClick={() => onChange(null)}
              className='w-6 h-6 btn text-icon-gray hover:text-white flex justify-center items-center'
            >
              <DeleteIcon className='fill-current w-4 h-4' />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const ATTENDEE_HARD_LIMIT = getFeatureQueryParamNumber(
  'event-attendees-max-count'
);

const INVITE_LIMIT = ATTENDEE_HARD_LIMIT - 1;

function PlayersRecommendedLabel(props: { seatLimit?: number }) {
  const { watch } = useFormContext<EventScheduleSharedFormData>();
  const pack = watch('pack');
  const attendees = watch('attendees');

  if (!pack) return <div></div>;

  if (props.seatLimit !== undefined && attendees.length + 1 > props.seatLimit) {
    return (
      <div className='text-tertiary text-xs'>
        Your plan limits the number of players in your venue to{' '}
        {props.seatLimit}. Please visit{' '}
        <Link
          to={$path('/subscription/change')}
          className='underline'
          target='_blank'
        >
          our billing section
        </Link>{' '}
        to upgrade your plan
      </div>
    );
  }

  if (attendees.length + 1 >= ATTENDEE_HARD_LIMIT) {
    return (
      <div className='text-tertiary text-xs'>
        You’ve reached the maximum number of attendees that you can invite
        through this form.
      </div>
    );
  }

  const inRange = PlayerRangeUtils.InRange(
    pack.playerRange,
    attendees.length + 1
  );

  return (
    <div
      className={`${
        inRange === 0 ? 'text-secondary' : 'text-tertiary'
      } transition-colors text-xs flex items-center gap-1`}
    >
      <div>
        {PlayerRangeUtils.Format(pack.playerRange, 'participant', null)}{' '}
        recommended
      </div>
      {inRange === 0 ? (
        <FilledCheckIcon className='w-3 h-3 fill-current' />
      ) : (
        <div className='w-3 h-3'></div>
      )}
    </div>
  );
}

function RecommendedAttendeesPanel(props: {
  eventType: EnumsEventType;
  items: DtoOrganizer[][];
  onAdd: (organizers: DtoOrganizer[]) => void;
}) {
  const { eventType, items, onAdd } = props;

  const analytics = useEventSchedulerAnalytics();

  const handleAdd = (organizers: DtoOrganizer[], index: number) => {
    analytics.trackRecommendedAttendeesClicked({
      eventType,
      index,
      uids: organizers.map((o) => o.uid),
      total: organizers.length,
    });

    onAdd(organizers);
  };

  return (
    <div className='w-full flex flex-col gap-1'>
      {items.map((organizers, index) => (
        <div
          key={`recommended-attendees-${index}`}
          className='w-full bg-main-layer rounded p-2.5 flex items-center justify-between gap-2.5'
        >
          <div className='flex items-center gap-2.5'>
            <TimerIcon className='w-4 h-4 fill-current opacity-60' />

            <p className='text-3xs'>
              <span className='text-[#8C6FFF]'>{`[${organizers.length} people]`}</span>
              {` `}
              {organizers.length >= 10
                ? `${organizers
                    .slice(0, 8)
                    .map((i) => OrganizerUtils.GetFirstName(i))
                    .join(', ')} and ${pluralize(
                    'other',
                    organizers.length - 8,
                    true
                  )}`
                : joinNames(
                    organizers.map((i) => OrganizerUtils.GetFirstName(i))
                  )}
            </p>
          </div>
          <button
            type='button'
            className='btn text-primary text-3xs font-medium'
            onClick={() => handleAdd(organizers, index)}
          >
            Add
          </button>
        </div>
      ))}
    </div>
  );
}

function isNewUserCauseOverOrgCapacity(
  organization: Organization,
  attendees: OrganizerSelectOption[]
) {
  if (!organization.settings?.enforceSeatCap) return false;
  if (!organization.maxSize) return false;

  const newUsers = attendees.filter((a) => a.kind === undefined && a.__isNew__);
  if (newUsers.length === 0) return false;
  const organizersCount = organization.organizersCount + newUsers.length;
  return organizersCount > organization.maxSize;
}

export function EventScheduleAttendeesField(props: {
  eventType: EnumsEventType;
  organization: Organization;
  recommendedAttendees?: DtoOrganizer[][];
  seatLimit?: number;
}) {
  const { eventType, organization, recommendedAttendees, seatLimit } = props;

  const navigate = useNavigateToSubscriptionChange();

  const maxAttendees =
    seatLimit !== undefined
      ? Math.max(Math.min(seatLimit - 1, INVITE_LIMIT), 0)
      : INVITE_LIMIT;
  const user = useUser();

  const {
    field: { value: attendees, onChange },
  } = useController<EventScheduleSharedFormData, 'attendees'>({
    name: 'attendees',
    rules: {
      validate: (value) =>
        value.length <= maxAttendees &&
        !isNewUserCauseOverOrgCapacity(organization, value),
    },
  });

  const newUsers = attendees.filter((a) => a.kind === undefined && a.__isNew__);
  const isNewUserCauseOverCapacity = isNewUserCauseOverOrgCapacity(
    organization,
    attendees
  );
  const hasReportedError = useRef(false);
  useEffect(() => {
    if (!isNewUserCauseOverCapacity) return;
    if (hasReportedError.current) return;
    const errorEmail = newUsers.find((a) => a.kind === undefined)?.value;
    if (!errorEmail) return;
    hasReportedError.current = true;

    apiService.organization.onSeatCapErrorShown({
      orgId: organization.id,
      errorType: 'scheduler',
      errorEmail: errorEmail,
      userEmail: user.email || '',
    });
  }, [isNewUserCauseOverCapacity, newUsers, organization, user]);

  const handleAdd = (organizers: DtoOrganizer[]) => {
    const newAttendees = [...attendees];
    for (const organizer of organizers) {
      const exist = newAttendees.find(
        (a) => a.kind === 'organizer' && a.organizer.uid === organizer.uid
      );
      if (!exist) {
        newAttendees.push({
          kind: 'organizer',
          organizer: fromDTOOrganizer(organizer),
        });
      }
    }
    onChange(newAttendees);
  };

  return (
    <div className='w-full flex gap-4'>
      <div className='mt-1'>
        <TeamIcon className='fill-current w-6 h-6' />
      </div>
      <div className='w-full'>
        <div className='text-sms font-bold'>Email Addresses</div>
        <div className='text-sms text-icon-gray'>
          Who do you want to invite?{' '}
          {eventType === EnumsEventType.EventTypeOnd &&
            '(You can edit this after scheduling)'}
        </div>

        <div className='mt-5 w-full'>
          <div className='flex justify-between items-center mb-1'>
            <PlayersRecommendedLabel seatLimit={props.seatLimit} />
            <div></div>
          </div>

          <OrganizerMultiSelect
            orgId={organization.id}
            options={attendees}
            onChange={onChange}
            filterOrganizer={(organizer) => organizer.uid !== user.id}
            placeholder='Enter one or more email addresses (copy and paste comma separated emails)'
            className='w-full'
            creatable
            maxAttendees={maxAttendees}
            orgSeatCapped={isNewUserCauseOverCapacity}
          />

          {recommendedAttendees && recommendedAttendees.length > 0 && (
            <div className='max-h-40 bg-modal px-1.5 pt-3.5 pb-1 -mt-2.5 overflow-auto scrollbar'>
              <RecommendedAttendeesPanel
                eventType={eventType}
                items={recommendedAttendees}
                onAdd={handleAdd}
              />
            </div>
          )}

          {newUsers.length > 0 && (
            <div className='w-full gap-1 mt-1 text-xs'>
              ✉️ {pluralize('invitee', newUsers.length, true)}{' '}
              {newUsers.length === 1 ? 'is' : 'are'} not on Luna Park.
              {isNewUserCauseOverCapacity ? (
                <span>
                  They will not be able to join your event. To add more users to
                  your account{' '}
                  <button
                    type='button'
                    className='text-primary underline inline-block'
                    onClick={navigate}
                  >
                    visit our Upgrade page.
                  </button>
                </span>
              ) : (
                'We’ll automatically create accounts for them!'
              )}
            </div>
          )}

          {attendees.length > INVITE_LIMIT ? (
            <div className='text-xs'>
              You’ve reached the maximum number of attendees that you can invite
              through this form.
            </div>
          ) : seatLimit && attendees.length > seatLimit - 1 ? (
            <div className='text-xs'>
              Your plan limits the number of players in your venue to{' '}
              {seatLimit}.{' '}
              <button
                type='button'
                onClick={navigate}
                className='btn underline text-primary'
              >
                Upgrade
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
