import { useCallback } from 'react';

import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { AddCoursesToStackModal } from './Modals/AddCoursesToStackModal';

interface TriggerModalOptions {
  onConfirm: (courseIds: string[]) => void;
  existingCourseIds: string[];
}

export function useTriggerAddCoursesModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    ({ onConfirm, existingCourseIds }: TriggerModalOptions) => {
      return triggerModal({
        kind: 'custom',
        containerClassName: 'bg-lp-black-002',
        element: (modalProps) => (
          <AddCoursesToStackModal
            onClose={modalProps.internalOnCancel}
            onConfirm={(courseIds) => {
              onConfirm(courseIds);
              modalProps.internalOnConfirm();
            }}
            existingCourseIds={existingCourseIds}
          />
        ),
      });
    },
    [triggerModal]
  );
}
