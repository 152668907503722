export function FillInTheBlanksBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
    >
      <rect
        x='1.14307'
        y='3.42859'
        width='13.7143'
        height='3.42853'
        fill='white'
      />
      <path d='M1.14307 9.1427H5.71449V12.5712H1.14307V9.1427Z' fill='white' />
      <rect
        x='6.85693'
        y='11.4285'
        width='8'
        height='1.14285'
        rx='0.571423'
        fill='white'
      />
    </svg>
  );
}
