import Uppy, { type FailedUppyFile, type UploadResult } from '@uppy/core';
import { FileInput } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { type SparkifactBlock } from '@lp-lib/game';

import config from '../../../config';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useUppy } from '../../../hooks/useUppy';
import { apiService } from '../../../services/api-service';
import { RoleUtils } from '../../../types';
import { getStaticAssetPath } from '../../../utils/assets';
import { getToken } from '../../../utils/getToken';
import { uncheckedIndexAccess_UNSAFE } from '../../../utils/uncheckedIndexAccess_UNSAFE';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { Loading } from '../../Loading';
import { useUser } from '../../UserContext';
import { useTrainingSlideEditor } from './hooks';
import { type TrainingSlideEditorProps } from './types';

export function SparkifactBlockEditor(
  props: TrainingSlideEditorProps<SparkifactBlock>
) {
  return <Left {...props} />;
}

export function SparkifactBlockSidebarEditor(
  props: TrainingSlideEditorProps<SparkifactBlock>
) {
  return <Right {...props} />;
}

function Left(props: TrainingSlideEditorProps<SparkifactBlock>) {
  const entrypoint = props.block.fields.entrypoint.trim();
  if (!entrypoint) {
    return null;
  }

  const src = getStaticAssetPath(`spark/${entrypoint}`);
  return (
    <div className='relative w-full h-full min-h-0 flex flex-col'>
      <iframe title='sparkifact' className='w-full h-full' src={src} />
    </div>
  );
}

function Right(props: TrainingSlideEditorProps<SparkifactBlock>) {
  return (
    <div className='w-full h-full flex flex-col gap-5 text-white'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Entrypoint</p>
        <EntryPointInput {...props} />
        <p className='text-sms text-icon-gray mb-1'>
          The entry point must point to an HTML file in the{' '}
          <span className='font-mono px-1 py-0.5 bg-light-gray rounded'>
            spark
          </span>{' '}
          folder on S3. The file must be within a folder. Examples:
          <ul className='pt-2 space-y-2'>
            <li className='w-max font-mono px-1 py-0.5 bg-light-gray rounded'>
              enemy-crawl/dungeon.html
            </li>
            <li className='w-max font-mono px-1 py-0.5 bg-light-gray rounded'>
              hero-quest/index.html
            </li>
            <li className='w-max font-mono px-1 py-0.5 bg-light-gray rounded'>
              tetris/game/index.html
            </li>
          </ul>
        </p>
      </label>
      <ManageButton {...props} />
    </div>
  );
}

function EntryPointInput(props: TrainingSlideEditorProps<SparkifactBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const [entryPoint, setEntryPoint] = useState(block.fields.entrypoint);

  useEffect(() => {
    setEntryPoint(block.fields.entrypoint);
  }, [block.fields.entrypoint]);

  return (
    <input
      type='text'
      className='field invalid:field-error'
      value={entryPoint}
      onChange={(e) => {
        const value = e.target.value;
        setEntryPoint(value);
      }}
      onBlur={(e) => {
        const value = e.target.value;
        onChange('entrypoint', value);
        onBlur('entrypoint', value);
      }}
      pattern='^[^/]+/.+\.html$'
      placeholder='enemy-crawl/index.html'
    />
  );
}

function ManageButton(props: TrainingSlideEditorProps<SparkifactBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);
  const user = useUser();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const handleMakeEntryPoint = useLiveCallback((entrypoint: string) => {
    onChange('entrypoint', entrypoint);
    onBlur('entrypoint', entrypoint);
  });

  const isAdmin = RoleUtils.isAdmin(user);

  const handleManage = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <FileManager
          blockId={block.id}
          initialEntryPoint={block.fields.entrypoint}
          onClose={p.internalOnCancel}
          onMakeEntryPoint={handleMakeEntryPoint}
        />
      ),
    });
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <button
      type='button'
      className='btn btn-secondary w-full h-10'
      onClick={handleManage}
    >
      Manage
    </button>
  );
}

function FileManager(props: {
  blockId: string;
  initialEntryPoint: Nullable<string>;
  onClose: () => void;
  onMakeEntryPoint: (entrypoint: string) => void;
}) {
  const { data, isLoading, error, mutate } = useSWR(
    `/blocks/${props.blockId}/sparkifact`,
    async () => {
      const res = await apiService.block.getSparkifact(props.blockId);
      return res.data.files
        .filter((f) => f.size > 0)
        .map((f) => {
          const key = f.key.split(`${props.blockId}/`)[1] ?? f.key;
          return {
            ...f,
            key,
          };
        });
    }
  );

  const [entryPoint, setEntryPoint] = useState(props.initialEntryPoint);
  const handleSetEntryPoint = useLiveCallback((key: string) => {
    props.onMakeEntryPoint(key);
    setEntryPoint(key);
  });

  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadResult, setUploadResult] = useState<UploadResult | null>(null);

  const uppyId = `sparkifact-${props.blockId}`;
  const uppy = useUppy(uppyId, () => {
    return new Uppy({
      id: uppyId,
      autoProceed: true,
      allowMultipleUploadBatches: true,
      onBeforeUpload: () => {
        setProgress(0);
        setUploadResult(null);
        return true;
      },
    })
      .use(XHRUpload, {
        endpoint: `${config.api.baseUrl}/blocks/${props.blockId}/sparkifact/upload`,
        method: 'post',
        formData: false,
        fieldName: 'files',
        headers: (file) => {
          const extraHeaders = {
            authorization: `Bearer ${getToken()}`,
          };
          if (file.type) {
            uncheckedIndexAccess_UNSAFE(extraHeaders)['Content-Type'] =
              file.type;
          }
          if (file.name) {
            uncheckedIndexAccess_UNSAFE(extraHeaders)['x-lp-filename'] =
              file.name;
          }
          return extraHeaders;
        },
        timeout: 1800 * 1000, // 30 Mins
        limit: 0,
      })
      .on('upload', () => {
        setIsUploading(true);
      })
      .on('complete', async (result) => {
        try {
          if (result.failed.length > 0) {
            setUploadResult(result);
          } else {
            setUploadResult(null);
            await mutate();
          }
        } finally {
          setIsUploading(false);
          uppy.reset();
        }
      })
      .on('progress', (progress) => {
        setProgress(progress);
      });
  });

  return (
    <div className='relative border border-secondary bg-black rounded-xl px-5 py-3 w-200 h-[70%]'>
      {(isUploading || (uploadResult && uploadResult.failed.length > 0)) && (
        <div className='absolute inset-0 z-10 bg-lp-black-004 rounded-xl'>
          {isUploading ? (
            <div className='w-full h-full flex flex-col items-center justify-center text-center text-icon-gray'>
              <Loading text='' />
              <div className='mt-2'>
                <p className='text-white text-lg font-bold'>Uploading</p>
                <p className='mt-1'>
                  This may take a while depending on the number of files to
                  upload.
                </p>

                <div className='mt-1 w-full bg-gray-800 rounded-full h-2.5'>
                  <div
                    className='bg-primary h-2.5 rounded-full transition-size'
                    style={{
                      width: `${progress}%`,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className='w-full h-full flex flex-col items-center justify-center text-center text-icon-gray'>
              <div className='mt-2'>
                <p className='text-white text-lg font-bold'>Uploads Failed</p>
                <p className='mt-1'>
                  Failed to upload the following files. Please try again.
                </p>

                <div className='mt-1 w-full text-red-001'>
                  {uploadResult?.failed
                    .map(
                      (
                        file: FailedUppyFile<
                          Record<string, unknown>,
                          Record<string, unknown>
                        >
                      ) => file.name
                    )
                    .join(', ')}
                </div>

                <button
                  type='button'
                  className='btn-secondary mt-1 w-40 h-10'
                  onClick={() => {
                    setUploadResult(null);
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full py-2'>
          <div className='font-bold text-2xl'>Manage Sparkifact</div>
          <div className='pt-1 text-icon-gray text-sms'>
            This tool uses the sparkifact name{' '}
            <span className='w-max font-mono px-1 py-0.5 bg-light-gray rounded'>
              {props.blockId}
            </span>
            . Be sure your entrypoint uses a file within this folder.
          </div>
        </div>

        <div className='flex-1 w-full my-4 overflow-y-auto'>
          {isLoading ? (
            <div className='w-full h-full flex items-center justify-center'>
              <Loading text='' />
            </div>
          ) : error ? (
            <div className='w-full h-full flex items-center justify-center'>
              <div className='text-red-001'>Error loading files</div>
            </div>
          ) : !data || data.length === 0 ? (
            <div className='w-full h-full flex items-center justify-center'>
              <div className='font-bold'>No files found</div>
            </div>
          ) : (
            <table className='table-auto w-full text-sm'>
              <thead>
                <tr className='text-left'>
                  <th className='px-4 py-2'>Key</th>
                  <th className='px-4 py-2'>Size</th>
                  <th className='px-4 py-2'>Last Modified</th>
                  <th className='w-28'></th>
                </tr>
              </thead>
              <tbody>
                {data.map((file) => {
                  const scopedKey = `${props.blockId}/${file.key}`;
                  const isEntryPoint = entryPoint === scopedKey;

                  return (
                    <tr
                      key={file.key}
                      className='hover:bg-lp-gray-002 odd:bg-lp-gray-001 group'
                    >
                      <td className='px-4 py-2'>{file.key}</td>
                      <td className='px-4 py-2'>{file.size}</td>
                      <td className='px-4 py-2'>
                        {format(
                          new Date(file.lastModified),
                          'yyyy-MM-dd HH:mm'
                        )}
                      </td>
                      <td className='w-28 text-center'>
                        {isEntryPoint ? (
                          <span className='text-2xs uppercase text-tertiary font-bold'>
                            Entrypoint
                          </span>
                        ) : (
                          <button
                            type='button'
                            className='btn text-2xs uppercase font-bold text-primary opacity-0 group-hover:opacity-100 transition-opacity'
                            onClick={() => handleSetEntryPoint(scopedKey)}
                          >
                            Set Entrypoint
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        <div className='mt-auto w-full pb-2 flex items-center justify-between gap-4'>
          <label className='btn flex justify-center items-center text-primary gap-1 text-sm hover:bg-light-gray px-2 py-1 rounded transition-colors cursor-pointer'>
            Upload Files
            <div className='hidden'>
              <FileInput uppy={uppy} inputName='files' />
            </div>
          </label>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
