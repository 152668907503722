import { type AxiosInstance } from 'axios';

export abstract class BaseAPIService {
  constructor(protected client: AxiosInstance) {}

  getToken(): string | null {
    const auth = this.client.defaults.headers.common.Authorization;
    if (!auth) return null;
    return auth.split(' ')[1] ?? null;
  }
}
