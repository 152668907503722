import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import {
  type DtoSharedAsset,
  type EnumsSharedAssetPurpose,
} from '@lp-lib/api-service-client/public';
import {
  type Media,
  type MediaData,
  MediaTranscodeStatus,
  MediaType,
  VolumeLevelUtils,
} from '@lp-lib/game';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { bytesToSize, err2s } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { playWithCatch } from '../../utils/playWithCatch';
import { TimeUtils } from '../../utils/time';
import { DeleteIcon } from '../icons/DeleteIcon';
import { PlayFillIcon } from '../icons/PlayFillIcon';
import { TimerIcon } from '../icons/TimerIcon';
import { WarningIcon } from '../icons/WarningIcon';
import { Loading } from '../Loading';
import { useOpenShareAssetPickerModal } from '../SharedAsset';
import {
  getMaxFileSizeByMediaType,
  type MediaUploaderProps,
  useMediaUploader,
} from './useMediaUploader';

type ObjectFit = 'object-contain' | 'object-cover';
type ClipPath = 'game-cover-clip';

export type MediaUploaderOptions = {
  title?: React.ReactNode;
  mediaData?: MediaData | null;
  extraNotice?: ReactNode;
  objectFit?: ObjectFit;
  replace?: boolean;
  allowDelete?: boolean;
  allowInitialUpload?: boolean;
  clipPath?: ClipPath;
  duration?: true;
  width?: string;
  border?: string;
  onMediaDelete?: () => void;
  pickMedia?: (media: Media | null | undefined) => string | null;
  sharedAssetPurposes?: EnumsSharedAssetPurpose[];
  onSharedAssetSelected?: (item: DtoSharedAsset) => void;
  fixedAspectRatio?: boolean;
} & MediaUploaderProps;

const defaultUploadOptions: MediaUploaderOptions = {
  image: true,
  video: true,
  replace: true,
  allowDelete: true,
  allowInitialUpload: true,
  objectFit: 'object-cover',
  width: 'w-85',
  border: 'border border-dashed border-secondary',
  fixedAspectRatio: true,
};

/**
 * Reusable, styled component for media uploading. Useful for block editors. To
 * build your on file upload component, use the `useMediaUploader` hook and
 * style the component yourself.
 */
export const MediaUploader = (opts: MediaUploaderOptions): JSX.Element => {
  const props = Object.assign({}, defaultUploadOptions, opts);

  const openShareAssetPickerModal = useOpenShareAssetPickerModal();
  const [isMediaOnHover, setMediaOnHover] = useState(false);
  const [showOverlayPlayButton, setShowOverlayPlayButton] = useState(true);
  const [showReplace, setShowReplace] = useState<boolean>(
    props.replace ?? true
  );

  const noop = () => void 0;
  const noopBool = () => true;

  const {
    inputElement,
    isUploading,
    media,
    uploadError,
    deleteMedia,
    maxFileSize,
  } = useMediaUploader({
    ...props,
    onUploadStart: useLiveCallback(props.onUploadStart ?? noop),
    onUploadSuccess: useLiveCallback(props.onUploadSuccess ?? noop),
    onUploadFailed: useLiveCallback(props.onUploadFailed ?? noop),
    onComplete: useLiveCallback(props.onComplete ?? noop),
    onBeforeUpload: useLiveCallback(props.onBeforeUpload ?? noopBool),
    inputText:
      props.image && props.video
        ? 'Upload Media'
        : props.image
        ? 'Upload Image'
        : props.video
        ? 'Upload Video'
        : 'Upload',
  });

  const ref = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const hint = useMemo(() => {
    const maxImageSize = bytesToSize(
      getMaxFileSizeByMediaType(MediaType.Image, opts.scene, maxFileSize)
    );
    const maxVideoSize = bytesToSize(
      getMaxFileSizeByMediaType(MediaType.Video, opts.scene, maxFileSize)
    );

    if (props.image && props.video) {
      return `Upload JPEG, PNG, GIF (max ${maxImageSize}), or MP4, WEBM (max ${maxVideoSize})`;
    } else if (props.image) {
      return `Upload JPEG, PNG, GIF (max ${maxImageSize})`;
    } else if (props.video) {
      return `Upload MP4, WEBM (max ${maxVideoSize})`;
    }
  }, [opts.scene, maxFileSize, props.image, props.video]);

  const mediaUrl = props.pickMedia
    ? props.pickMedia(media)
    : MediaUtils.PickMediaUrl(media);
  const durationMs = MediaUtils.GetAVDurationMs(
    MediaUtils.PickMediaFormat(media)
  );

  useEffect(() => {
    if (videoRef.current && mediaUrl) {
      videoRef.current.src = mediaUrl;
      videoRef.current.onplay = () => {
        setShowOverlayPlayButton(false);
        setShowReplace(false);
      };
      videoRef.current.onpause = () => {
        if (videoRef.current?.readyState === 4) {
          setShowOverlayPlayButton(true);
          props.replace && setShowReplace(true);
        }
      };
      videoRef.current.onended = () => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      };
    }
  }, [mediaUrl, props.replace]);

  const onMediaDelete = () => {
    deleteMedia();
    if (props.onMediaDelete) {
      props.onMediaDelete();
    }
  };

  const playVideo = () => {
    if (
      videoRef.current &&
      videoRef.current.readyState >= 2 &&
      media &&
      media.type === MediaType.Video
    ) {
      playWithCatch(videoRef.current);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume =
        0.32 * VolumeLevelUtils.ConvertToScale(props.mediaData?.volumeLevel);
    }
  }, [props.mediaData?.volumeLevel, media]);

  let transcodeStatusLabel = <></>;
  switch (media?.transcodeStatus) {
    case MediaTranscodeStatus.Pending:
    case MediaTranscodeStatus.Processing:
      transcodeStatusLabel = (
        <div className='absolute top-0 right-0 w-24 h-6 py-1 bg-transparent flex flex-row justify-center items-center'>
          <TimerIcon className='fill-current w-3 h-3' />
          <p className='ml-1 font-normal text-shadow text-2xs text-white'>
            Optimizing
          </p>
        </div>
      );
      break;
    case MediaTranscodeStatus.Failed:
      transcodeStatusLabel = (
        <div className='absolute top-0 right-2 h-6 py-1 bg-transparent flex flex-row justify-center items-center text-red-002'>
          <WarningIcon className='fill-current w-3 h-3' />
          <p className='ml-1 font-normal text-shadow text-2xs'>Error</p>
        </div>
      );
      break;
    case undefined:
      break;
    default:
      break;
  }

  const videoDisplayer = (
    <div className='relative w-full h-full'>
      <video
        ref={videoRef}
        className={`relative w-full h-full rounded-xl ${props.objectFit}`}
        controls={!showOverlayPlayButton}
        preload='auto'
      />
      {showOverlayPlayButton && (
        <button
          type='button'
          onClick={playVideo}
          className='btn absolute top-0 w-full h-full opacity-80 hover:opacity-100 bg-transparent flex flex-row justify-center items-center'
        >
          <PlayFillIcon className='fill-current w-16 h-18' />
        </button>
      )}
      {transcodeStatusLabel}
    </div>
  );

  const imageDisplayer = mediaUrl && (
    <img
      className={`${
        props.objectFit === 'object-cover'
          ? `w-full h-full ${props.objectFit}`
          : `max-w-full max-h-full ${props.objectFit}`
      } rounded-xl`}
      src={mediaUrl}
      alt=''
    />
  );

  return (
    <div ref={ref} className={`${props.width}`}>
      {props.title && (
        <div className='text-white capitalize font-bold mb-1'>
          {props.title}
        </div>
      )}
      <div
        className={`${
          props.clipPath
            ? `${props.clipPath} bg-secondary p-px`
            : `${props.border} bg-black`
        } relative max-w-full max-h-full rounded-xl flex flex-col justify-center items-center`}
        style={{
          aspectRatio: props.fixedAspectRatio ? '16/9' : undefined,
        }}
      >
        <div
          className={`w-full h-full flex flex-col justify-center items-center ${
            props.clipPath ? `bg-black ${props.clipPath}` : ''
          }`}
        >
          {media && (
            <div
              onMouseEnter={() => setMediaOnHover(true)}
              onMouseLeave={() => setMediaOnHover(false)}
              className='relative w-full h-full flex flex-row justify-center items-center'
            >
              {media.type === MediaType.Video && videoDisplayer}
              {media.type === MediaType.Image && imageDisplayer}

              {isMediaOnHover && (
                <div className='absolute top-2 right-2 flex items-center gap-2'>
                  {props.sharedAssetPurposes && props.onSharedAssetSelected && (
                    <button
                      type='button'
                      className='btn w-10 h-10'
                      onClick={() => {
                        if (!props.onSharedAssetSelected) return;
                        openShareAssetPickerModal({
                          onSelected: props.onSharedAssetSelected,
                          purposes: props.sharedAssetPurposes,
                        });
                      }}
                    >
                      📂
                    </button>
                  )}
                  {props.allowDelete && (
                    <button
                      type='button'
                      onClick={onMediaDelete}
                      className='btn w-10 h-10 bg-black hover:bg-dark-gray border border-secondary rounded-xl flex flex-row justify-center items-center text-3xs text-red-005'
                    >
                      <DeleteIcon />
                    </button>
                  )}
                </div>
              )}
              {isMediaOnHover && showReplace && (
                <>
                  <label className='absolute bottom-2 outline-none focus:outline-none text-white text-sms filter drop-shadow-lp-upload-replace cursor-pointer'>
                    Replace
                    <div className='hidden w-0 h-0'>{inputElement}</div>
                  </label>
                </>
              )}
              {props.duration && (
                <div className='absolute bottom-2 right-2 font-normal text-shadow text-2xs text-white'>
                  {TimeUtils.DurationFormattedHHMMSS(durationMs)}
                </div>
              )}
            </div>
          )}
          {isUploading && (
            <div className='absolute w-full h-full flex flex-row justify-center items-center self-center z-5'>
              <div className='w-45 h-16 bg-lp-black-001 border-2 border-black-001 rounded-xl text-white text-base font-bold flex items-center justify-center'>
                <Loading text='Uploading' />
              </div>
            </div>
          )}
          {!media && !isUploading && props.allowInitialUpload && (
            <>
              <div className='absolute top-2 right-2'>
                {props.sharedAssetPurposes && props.onSharedAssetSelected && (
                  <button
                    type='button'
                    className='btn w-10 h-10'
                    onClick={() => {
                      if (!props.onSharedAssetSelected) return;
                      openShareAssetPickerModal({
                        onSelected: props.onSharedAssetSelected,
                        purposes: props.sharedAssetPurposes,
                      });
                    }}
                  >
                    📂
                  </button>
                )}
              </div>
              <p className='relative font-bold text-secondary text-sms text-center mb-2 w-[230px]'>
                {hint}
              </p>
              {inputElement}
            </>
          )}
        </div>
      </div>
      <div className='w-full h-4'>
        <p className='mt-1 text-3xs text-red-005'>{err2s(uploadError)}</p>
      </div>
      <span className='w-full text-icon-gray text-sms font-normal'>
        {props.extraNotice}
      </span>
    </div>
  );
};
