import {
  EnumsTrainingBlockType,
  type ModelsTrainingProfile,
} from '@lp-lib/api-service-client/public';

export function buildTemplateConfigGroups(profile: ModelsTrainingProfile) {
  const blocks = profile.blocks ? [...profile.blocks] : [];
  for (const type of Object.values(EnumsTrainingBlockType)) {
    if (!blocks.find((b) => b.type === type)) {
      blocks.push({ type, templateId: '' });
    }
  }
  return {
    blocks: blocks.sort((a, b) => a.type.localeCompare(b.type)),
    global: [
      {
        label: 'Module Outline',
        name: 'moduleOutline',
        config: profile.moduleOutline,
      },
      {
        label: 'Topic Outline',
        name: 'topicOutline',
        config: profile.topicOutline,
      },
      {
        label: 'Topic Block Overview',
        name: 'topicBlockOverview',
        config: profile.topicBlockOverview,
      },
      {
        label: 'Block Dialogue',
        name: 'blockDialogue',
        config: profile.blockDialogue,
      },
      {
        label: 'Block Bg Music',
        name: 'blockBgMusic',
        config: profile.blockBgMusic,
      },
      {
        label: 'Media Search Keywords',
        name: 'mediaSearchKeywords',
        config: profile.mediaSearchKeywords,
      },
      {
        label: 'Select Best Match Media',
        name: 'mediaSelection',
        config: profile.mediaSelection,
      },
    ],
  };
}
