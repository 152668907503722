import capitalize from 'lodash/capitalize';
import { useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import {
  EnumsRoleplayTurnEndMode,
  EnumsRoleplayVoice,
  type ModelsMediaAsset,
} from '@lp-lib/api-service-client/public';
import { MediaType, type RoleplayBlock } from '@lp-lib/game';

import { fromMediaDTO, toMediaDTO } from '../../../utils/api-dto';
import { MediaPickPriorityHD, MediaUtils } from '../../../utils/media';
import { RoleplayEvaluationEditor } from '../../GameV2/blocks/Roleplay/RoleplayEvaluationEditor';
import { RoleplayPromptEditor } from '../../GameV2/blocks/Roleplay/RoleplayPromptEditor';
import { RoleplayVoiceSelect } from '../../GameV2/blocks/Roleplay/RoleplayVoiceSelect';
import { CommonButton } from '../../GameV2/design/Button';
import { EditableText } from '../../GameV2/design/Editable';
import { ArrowDownIcon } from '../../icons/Arrows';
import { ArrowUpIcon } from '../../icons/Arrows';
import { CloseIcon } from '../../icons/CloseIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { ImageIcon } from '../../icons/ImageIcon';
import { PauseIcon } from '../../icons/PauseIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import { useTriggerMediaSearchModal } from '../../MediaSearch/useTriggerMediaSearchModal';
import { SwitcherControlled } from '../../Switcher';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { useTrainingSlideEditor } from './hooks';
import { BlockIntroSelect } from './Shared/BlockIntroSelect';
import { type TrainingSlideEditorProps } from './types';
const tabs = ['intro', 'roleplay', 'success', 'failure'] as const;

type Tab = (typeof tabs)[number];

type State = {
  activeTab: Tab;
};

const state = proxy<State>({
  activeTab: 'intro',
});

export function RoleplayBlockEditorTabs() {
  const { activeTab } = useSnapshot(state);

  useEffect(() => {
    return () => {
      state.activeTab = 'intro';
    };
  }, []);

  return (
    <div className='p-2 flex items-center gap-2.5 text-white'>
      {tabs.map((tab) => (
        <button
          type='button'
          key={tab}
          className={`
            w-25 h-8 bg-secondary rounded-lg
            border ${activeTab === tab ? 'border-white' : 'border-transparent'}
          `}
          onClick={() => {
            state.activeTab = tab;
          }}
        >
          {capitalize(tab)}
        </button>
      ))}
    </div>
  );
}

function MediaField(props: {
  asset: ModelsMediaAsset | null;
  description?: string;
  onChange: (asset: ModelsMediaAsset | null) => void;
}) {
  const media = fromMediaDTO(props.asset?.media);
  const url = MediaUtils.PickMediaUrl(media, {
    priority: MediaPickPriorityHD,
  });
  const ref = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const triggerMediaSearchModal = useTriggerMediaSearchModal();
  const openMediaSearchModal = () => {
    triggerMediaSearchModal({
      video: true,
      onUploadSuccess: (media) => {
        const asset: ModelsMediaAsset = {
          media: toMediaDTO(media),
          data: {
            id: media.id,
          },
        };
        props.onChange(asset);
      },
    });
  };

  if (media && url) {
    return (
      <div className='absolute inset-0 group flex justify-center items-center'>
        <div className='absolute inset-0'>
          {media.type === MediaType.Video && (
            <video
              src={url}
              className='w-full h-full object-cover'
              ref={ref}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
            />
          )}
          {media.type === MediaType.Image && (
            <img src={url} alt='' className='w-full h-full object-cover' />
          )}
        </div>

        <div className='absolute flex items-center gap-2.5 group-hover:opacity-100 opacity-0 transition-opacity'>
          {media.type === MediaType.Video && (
            <button
              type='button'
              className='btn-secondary w-10 h-10 flex items-center justify-center'
              onClick={() => {
                if (isPlaying) {
                  ref.current?.pause();
                } else {
                  ref.current?.play();
                }
              }}
            >
              {isPlaying ? (
                <PauseIcon className='w-4 h-4 fill-current' />
              ) : (
                <PlayIcon className='w-4 h-4 fill-current' />
              )}
            </button>
          )}
          <button
            type='button'
            className='btn-secondary w-33 h-10'
            onClick={openMediaSearchModal}
          >
            Replace
          </button>
          <button
            type='button'
            onClick={() => props.onChange(null)}
            className='btn-secondary flex w-10 h-10 items-center justify-center text-red-002'
          >
            <DeleteIcon className='w-4 h-4 fill-current' />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center justify-center gap-4'>
      <button
        type='button'
        className='
          h-40 bg-main-layer hover:bg-light-gray transition-colors rounded-xl
          border border-secondary
          flex flex-col items-center justify-center gap-4
        '
        style={{
          aspectRatio: '16/9',
        }}
        onClick={openMediaSearchModal}
      >
        <ImageIcon className='w-12 h-12 text-white' />
        <div className='text-center'>
          <div className='text-white'>Add Media</div>
        </div>
      </button>
      {props.description && (
        <p className='max-w-70 text-xs text-icon-gray'>{props.description}</p>
      )}
    </div>
  );
}

function Intro(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10 flex justify-center items-center'>
        <MediaField
          asset={props.block.fields.introMedia}
          description='Upload media to automatically play. Last frame of video will be used throughout.'
          onChange={(asset) => {
            onChange('introMedia', asset);
            onBlur('introMedia', asset);
          }}
        />
      </div>

      <footer
        className={`mt-auto w-full flex items-center justify-center gap-2 p-3 pb-5`}
      >
        <CommonButton
          variant='correct'
          className='flex-none'
          styles={{
            disabled: 'disabled:opacity-100 disabled:cursor-auto',
          }}
          disabled
        >
          <EditableText
            value={props.block.fields.introButtonText || 'Start Conversation'}
            onBlur={(value) => {
              onChange('introButtonText', value);
              onBlur('introButtonText', value);
            }}
          />
        </CommonButton>
      </footer>
    </div>
  );
}

function Roleplay(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10 flex justify-center items-center'>
        <MediaField
          asset={props.block.fields.playingMedia}
          onChange={(asset) => {
            onChange('playingMedia', asset);
            onBlur('playingMedia', asset);
          }}
          description='Upload media to automatically play. Last frame of video will be used throughout.'
        />
      </div>

      {props.block.fields.turnEndMode ===
      EnumsRoleplayTurnEndMode.RoleplayTurnEndModePushToTalk ? (
        <footer
          className={`w-full p-3 pb-5 flex items-center justify-center gap-2`}
        >
          <CommonButton
            variant='gray'
            className='flex-none'
            styles={{
              disabled: 'disabled:opacity-100 disabled:cursor-auto',
              size: 'h-full',
            }}
            style={{
              aspectRatio: '1/1',
            }}
            disabled
          >
            <CloseIcon className='w-4 h-4 fill-current' />
          </CommonButton>

          <CommonButton
            variant='correct'
            className='flex-none'
            styles={{
              disabled: 'disabled:opacity-100 disabled:cursor-auto',
            }}
            disabled
          >
            Talk
          </CommonButton>
        </footer>
      ) : (
        <footer
          className={`mt-auto w-full flex items-center justify-center gap-2 p-3 pb-5`}
        >
          <CommonButton
            variant='incorrect'
            className='flex-none'
            styles={{
              disabled: 'disabled:opacity-100 disabled:cursor-auto',
            }}
            disabled
          >
            <EditableText
              value={props.block.fields.playingButtonText || 'End Conversation'}
              onBlur={(value) => {
                onChange('playingButtonText', value);
                onBlur('playingButtonText', value);
              }}
            />
          </CommonButton>
        </footer>
      )}
    </div>
  );
}

function Success(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10 flex justify-center items-center'>
        <MediaField
          asset={props.block.fields.successMedia}
          onChange={(asset) => {
            onChange('successMedia', asset);
            onBlur('successMedia', asset);
          }}
          description='Upload media to automatically play when conversation is completed successfully'
        />
      </div>
    </div>
  );
}

function Failure(props: TrainingSlideEditorProps<RoleplayBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex-1 min-h-0 p-5 sm:p-8 lg:p-10 flex justify-center items-center'>
        <MediaField
          asset={props.block.fields.failureMedia}
          onChange={(asset) => {
            onChange('failureMedia', asset);
            onBlur('failureMedia', asset);
          }}
          description='Upload media to automatically play when conversation is completed unsuccessfully'
        />
      </div>
    </div>
  );
}

export function RoleplayBlockEditor(
  props: TrainingSlideEditorProps<RoleplayBlock>
) {
  const { activeTab } = useSnapshot(state);

  switch (activeTab) {
    case 'intro':
      return <Intro {...props} />;
    case 'roleplay':
      return <Roleplay {...props} />;
    case 'success':
      return <Success {...props} />;
    case 'failure':
      return <Failure {...props} />;
    default:
      return null;
  }
}

export function RoleplayBlockSidebarEditor(
  props: TrainingSlideEditorProps<RoleplayBlock>
) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const [opened, toggleOpened] = useToggle(false);

  return (
    <div className='w-full flex flex-col gap-5 text-white'>
      <RoleplayPromptEditor
        prompt={props.block.fields.systemPrompt}
        onChange={(value) => {
          onChange('systemPrompt', value);
          onBlur('systemPrompt', value);
        }}
      />

      <button
        type='button'
        className='btn flex items-center gap-2'
        onClick={() => toggleOpened()}
      >
        <p className='text-base font-bold'>Advanced Settings</p>
        {opened ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>

      {opened && (
        <div className='w-full flex flex-col gap-5'>
          <div className='flex flex-col gap-1'>
            <p className='text-base font-bold'>Roleplay Voice</p>
            <RoleplayVoiceSelect
              value={
                props.block.fields.voice ||
                EnumsRoleplayVoice.RoleplayVoiceAlloy
              }
              onChange={(value) => {
                onChange('voice', value);
                onBlur('voice', value);
              }}
            />
            <p className='text-3xs text-icon-gray'>
              Choose the voice the AI character will use during the roleplay.
            </p>
          </div>

          <div className='flex flex-col gap-1'>
            <p className='text-base font-bold'>Slide Intro</p>
            <BlockIntroSelect
              value={props.block.fields.intro}
              onChange={(value) => {
                onChange('intro', value);
                onBlur('intro', value);
              }}
            />
            <p className='text-3xs text-icon-gray'>
              Configure what is said or shown before this slide begins.
            </p>
          </div>

          <div className='flex flex-col gap-1'>
            <p className='text-base font-bold'>Intro Voice</p>
            <PersonalitySelect
              onChange={(value) => {
                onChange('personalityId', value?.id);
                onBlur('personalityId', value?.id);
              }}
              value={props.block.fields.personalityId}
              isClearable
            />
            <p className='text-3xs text-icon-gray'>
              Select the voice for the roleplay introduction.
            </p>
          </div>

          <div className='flex flex-col gap-1'>
            <p className='text-base font-bold'>Auto Turn Detection</p>
            <div className='flex items-center justify-between'>
              <p className='text-sms'>Auto Turn Detection</p>
              <SwitcherControlled
                name={`${props.block.id}-turn-detection`}
                checked={
                  !props.block.fields.turnEndMode ||
                  props.block.fields.turnEndMode ===
                    EnumsRoleplayTurnEndMode.RoleplayTurnEndModeServerVad
                }
                onChange={(checked: boolean): void => {
                  const mode = checked
                    ? EnumsRoleplayTurnEndMode.RoleplayTurnEndModeServerVad
                    : EnumsRoleplayTurnEndMode.RoleplayTurnEndModePushToTalk;
                  onChange('turnEndMode', mode);
                  onBlur('turnEndMode', mode);
                }}
              />
            </div>
            <p className='text-3xs text-icon-gray'>
              Enable this to let the AI detect when you're done speaking and
              respond automatically, or disable to use a button to proceed
              manually.
            </p>
          </div>

          <RoleplayEvaluationEditor
            block={props.block}
            onChange={(evaluation) => {
              onChange('evaluation', evaluation);
              onBlur('evaluation', evaluation);
            }}
          />
        </div>
      )}
    </div>
  );
}
