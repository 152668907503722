import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import {
  LearnersPage,
  type LearnersPageProps,
} from '../components/Training/Admin/Learner/LearnersPage';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export async function clientLoader(
  action: ClientLoaderFunctionArgs
): Promise<LearnersPageProps> {
  const url = new URL(action.request.url);
  const view = url.searchParams.get('tab') ?? 'members';

  if (view === 'groups') {
    const resp = await tokenWithRedirect(
      () => apiService.learning.getUserCreatedGroups(),
      action.request.url
    );

    return {
      groups: resp.data,
      view: 'groups',
    };
  } else {
    const resp = await tokenWithRedirect(
      () => apiService.learning.getLearners(),
      action.request.url
    );

    return {
      learners: resp.data,
      view: 'members',
    };
  }
}

export function Component() {
  const { learners, groups, view } = useLoaderData<typeof clientLoader>();
  return <LearnersPage learners={learners} groups={groups} view={view} />;
}
