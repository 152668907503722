import { useCallback } from 'react';

import { type DtoAssignedUser } from '@lp-lib/api-service-client/public';

import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { AssignmentResultsModal } from './Modals/AssignmentResultsModal';

export function useAssignmentResultsModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (assignedUsers: DtoAssignedUser[]) => {
      return triggerModal({
        kind: 'custom',
        containerClassName: 'bg-lp-black-002',
        element: (p) => (
          <AssignmentResultsModal
            assignedUsers={assignedUsers}
            onClose={p.internalOnConfirm}
          />
        ),
      });
    },
    [triggerModal]
  );
}
