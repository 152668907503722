import { type MediaProvider } from '../MediaProvider';
import { AzureProvider } from './AzureApiProvider';
import { GiphyProvider } from './GiphyProvider';
import { ImageGenProvider } from './ImageGenProvider';
import { UnsplashProvider } from './UnsplashProvider';

export const mediaProviders: MediaProvider[] = [
  GiphyProvider,
  UnsplashProvider,
  AzureProvider,
  ImageGenProvider,
  // SerpProvider,
];
