import { proxy, useSnapshot } from 'valtio';

import { Emitter, type EmitterOptions } from '../../../utils/emitter';
import { markSnapshottable } from '../../../utils/valtio';

export type TimerEvent = {
  timeout: () => void;
};

type TimerState = {
  totalMS: number;
  remainingMS: number;
  isRunning: boolean;
};

export class Timer {
  private _lastTick: number;
  private _rafId: number;
  private emitter: Emitter<TimerEvent>;
  private _state: TimerState;
  private _pausedTimeout: Nullable<NodeJS.Timeout>;

  constructor(totalMS: number) {
    this._state = markSnapshottable(
      proxy<TimerState>({
        totalMS,
        remainingMS: totalMS,
        isRunning: false,
      })
    );
    this._lastTick = 0;
    this._rafId = 0;
    this._pausedTimeout = null;
    this.emitter = new Emitter();
  }

  get state() {
    return this._state;
  }

  on<Name extends keyof TimerEvent>(
    name: Name,
    cb: TimerEvent[Name],
    opts?: EmitterOptions
  ) {
    this.emitter.on(name, cb, opts);
  }

  once<Name extends keyof TimerEvent>(
    name: Name,
    cb: TimerEvent[Name],
    opts?: EmitterOptions
  ) {
    this.emitter.once(name, cb, opts);
  }

  start() {
    this.stop();

    this._state.isRunning = true;
    this._lastTick = performance.now();
    this._tick();
  }

  pause(ms: number) {
    this.stop();
    this._pausedTimeout = setTimeout(() => {
      this.start();
    }, ms);
  }

  stop() {
    if (this._pausedTimeout) {
      clearTimeout(this._pausedTimeout);
      this._pausedTimeout = null;
    }
    if (this._rafId) {
      cancelAnimationFrame(this._rafId);
      this._rafId = 0;
    }
    this._state.isRunning = false;
  }

  private _tick = () => {
    const now = performance.now();
    const delta = now - this._lastTick;
    this._lastTick = now;

    this._state.remainingMS -= delta;
    if (this._state.remainingMS <= 0) {
      this.stop();
      this.emitter.emit('timeout');
      return;
    }

    this._rafId = requestAnimationFrame(this._tick);
  };
}

export function TimerBackground(props: { timer: Timer }) {
  const { timer } = props;
  const { totalMS, remainingMS } = useSnapshot(timer.state);

  const remainPercentage = totalMS > 0 ? (100 * remainingMS) / totalMS : 0;

  return (
    <div className='fixed inset-0 bg-black pt-12'>
      <div className='relative w-full h-full'>
        <div
          className='absolute inset-0 bg-[#001EB8]'
          style={{
            borderTopLeftRadius: '50% 4%',
            borderTopRightRadius: '50% 4%',
          }}
        />

        <div
          className='absolute inset-0'
          style={{
            borderTopLeftRadius: '50% 4%',
            borderTopRightRadius: '50% 4%',
            background: `linear-gradient(to bottom, #0029FF, #00A6FF)`,
            transform: `translateY(${100 - remainPercentage}%)`,
          }}
        />
      </div>
    </div>
  );
}
