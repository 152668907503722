import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { proxy } from 'valtio';

import type { SparkifactBlock } from '@lp-lib/game';

import { getStaticAssetPath } from '../../../../utils/assets';
import { markSnapshottable } from '../../../../utils/valtio';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from '../../types';

type State = {
  status: 'init' | 'play' | 'complete';
};

export class SparkifactBlockControlAPI implements IBlockCtrl {
  private _state = markSnapshottable(
    proxy<State>({
      status: 'init',
    })
  );
  private delegate: Nullable<PlaygroundPlaybackProtocol>;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(_block: SparkifactBlock, _deps: BlockDependencies) {
    // placeholder
  }

  get state() {
    return this._state;
  }

  async preload() {
    return void 0;
  }

  async initialize() {
    return void 0;
  }

  setDelegate(delegate: PlaygroundPlaybackProtocol) {
    this.delegate = delegate;
  }

  async present() {
    this._state.status = 'play';
  }

  async end() {
    await this.delegate?.blockDidEnd();
  }
}

export function SparkifactBlockPlayground(props: {
  block: SparkifactBlock;
  ctrl: SparkifactBlockControlAPI;
}) {
  useEffectOnce(() => {
    props.ctrl.present();
  });

  useEffect(() => {
    const messageHandler = async (event: MessageEvent) => {
      if (event.data?.type === 'BLOCK_DID_END') {
        await props.ctrl.end();
      }
    };

    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [props.ctrl]);

  const entrypoint = props.block.fields.entrypoint.trim();
  if (!entrypoint) {
    return null;
  }

  const src = getStaticAssetPath(`spark/${entrypoint}`);
  return (
    <div className='relative w-full h-full min-h-0 flex flex-col'>
      <iframe title='sparkifact' className='w-full h-full' src={src} />
    </div>
  );
}
