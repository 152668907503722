import cloneDeep from 'lodash/cloneDeep';

import { type QuestionBlock } from '@lp-lib/game';

import { PointsInput } from '../../Game/Blocks/Common/Editor/PointsUtilities';
import { CommonButton } from '../../GameV2/design/Button';
import { FFriendlyEditableText } from '../../GameV2/design/Editable';
import { CommonInput } from '../../GameV2/design/Input';
import { PersonalitySelect } from '../../VoiceOver/PersonalitySelect';
import { useTrainingSlideEditor } from './hooks';
import { BlockIntroSelect } from './Shared/BlockIntroSelect';
import { BlockMusicSelect } from './Shared/BlockMusicSelect';
import { type TrainingSlideEditorProps } from './types';

export function QuestionBlockEditor(
  props: TrainingSlideEditorProps<QuestionBlock>
) {
  return <Left {...props} />;
}

export function QuestionBlockSidebarEditor(
  props: TrainingSlideEditorProps<QuestionBlock>
) {
  return <Right {...props} />;
}

function Left(props: TrainingSlideEditorProps<QuestionBlock>) {
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <QuestionEditor
      question={props.block.fields.question || ''}
      onQuestionChange={(question) => {
        onChange('question', question);
        onBlur('question', question);
      }}
      answer={props.block.fields.answer || ''}
      onAnswerChange={(answer) => {
        onChange('answer', answer);
      }}
      onAnswerBlur={(answer) => {
        onBlur('answer', answer);
      }}
    />
  );
}

export function QuestionEditor(props: {
  question: string;
  onQuestionChange: (question: string) => void;
  answer: string;
  onAnswerChange: (answer: string) => void;
  onAnswerBlur: (answer: string) => void;
}) {
  return (
    <div className='relative w-full h-full min-h-0 flex flex-col'>
      <main className='w-full flex-1 min-h-0 px-10 flex flex-col justify-center gap-5 sm:gap-10'>
        <div className='text-white text-base text-center'>
          <FFriendlyEditableText
            value={props.question}
            onBlur={(val) => {
              props.onQuestionChange(val);
            }}
            className={`
          w-full outline-none cursor-text
          contenteditable-placeholder whitespace-pre-wrap break-words
          text-base sm:text-xl lg:text-2xl
        `}
            placeholder='Your Question Here'
          />
        </div>

        <div className='w-full'>
          <CommonInput
            type='text'
            placeholder='Type your answer here'
            value={props.answer}
            onChange={(e) => props.onAnswerChange(e.currentTarget.value)}
            onBlur={(e) => props.onAnswerBlur(e.currentTarget.value)}
            variant='brand'
          />
        </div>
      </main>

      <footer className='w-full flex flex-col items-center gap-2 px-3 pt-3 pb-5'>
        <CommonButton variant='brand' className='pointer-events-none'>
          Submit
        </CommonButton>
      </footer>
    </div>
  );
}

function Right(props: TrainingSlideEditorProps<QuestionBlock>) {
  const { block } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  return (
    <div className='w-full h-full flex flex-col gap-5 text-white'>
      <label>
        <p className='text-base text-white font-bold mb-1'>Voice Over</p>
        <PersonalitySelect
          onChange={(value) => {
            onChange('personalityId', value?.id);
            onBlur('personalityId', value?.id);
          }}
          value={block.fields.personalityId}
          isClearable
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>Block Intro</p>
        <BlockIntroSelect
          value={block.fields.intro}
          onChange={(value) => {
            onChange('intro', value);
            onBlur('intro', value);
          }}
        />
      </label>
      <label>
        <p className='text-base text-white font-bold mb-1'>
          Correct Answer Points
        </p>
        <PointsInput
          defaultValue={block.fields.points}
          max={1000}
          min={0}
          placeholder={'Max 1000 points'}
          onChange={(value) => {
            onChange('points', value);
            onBlur('points', value);
          }}
        />
        <p className='mt-1.5 text-2xs font-medium text-icon-gray'>
          Points earned if the answer is exactly correct
        </p>
      </label>

      <label>
        <p className='text-base text-white font-bold mb-1'>Background Music</p>
        <BlockMusicSelect
          value={block.fields.bgMusic}
          onChange={(value) => {
            onChange('bgMusic', value);
            // Do not persist the decorated media object.
            const out = cloneDeep(value);
            delete out?.asset.media;
            onBlur('bgMusic', out);
          }}
        />
      </label>
    </div>
  );
}
