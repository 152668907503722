import {
  type DtoStackWithLearnerCount,
  type ModelsStack,
} from '@lp-lib/api-service-client/public';

import { StackCard } from './StackCard';

interface StackCardListProps {
  stacks: DtoStackWithLearnerCount[];
  onAssign: (stack: ModelsStack) => void;
  onDelete: (id: string) => void;
  onDetails: (id: string) => void;
  learnersMap?: Record<string, number>;
}

export function StackCardList({
  stacks,
  onAssign,
  onDelete,
  onDetails,
  learnersMap = {},
}: StackCardListProps) {
  return (
    <div className='flex flex-col w-full'>
      {/* Sticky header row */}
      <div
        className='
        sticky top-0 z-10
        bg-modal
        px-4 py-2 pr-4
        text-gray-400 text-sm
        flex items-center
      '
      >
        <div className='flex-1'>Name</div>
        <div className='flex items-center gap-16 pr-10'>
          <div className='w-20 text-start'>Learners</div>
          <div className='w-20 text-start'>Progress</div>
          <div className='w-32 text-start'>Created On</div>
        </div>
        <div className='w-32' />
      </div>

      <div className='flex flex-col gap-6 pr-4'>
        {stacks.map(({ stack }) => {
          const learners = learnersMap[stack.id] ?? 0;
          return (
            <StackCard
              key={stack.id}
              stack={stack}
              learners={learners}
              onAssign={onAssign}
              onDelete={onDelete}
              onDetails={onDetails}
            />
          );
        })}
      </div>
    </div>
  );
}
