import { Link, type MetaFunction, useNavigate } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';

import { HelpWidget } from '../../../app/components/HelpWidget/HelpWidget';
import { useLearningAnalytics } from '../../analytics/learning';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { getStaticAssetPath } from '../../utils/assets';
import { makeMarketLink } from '../../utils/makeLinks';
import { type FAQ } from '../FAQ';
import { FAQItem } from '../FAQ/FAQList';
import { UGCFileManagerProvider } from '../Game/UGC';
import { useUGCFileManager } from '../Game/UGC/CustomGameFileManager';
import {
  type ActionProps,
  type CustomEditorPrimaryAction,
  CustomGamePackPromptEditor,
} from '../Game/UGC/CustomGamePackPromptEditor';
import { MultipleChoiceBlockIcon } from '../icons/Block';
import { InstructionBlockIcon } from '../icons/Block/InstructionBlockIcon';
import { JeopardyBlockIcon } from '../icons/Block/JeopardyBlockIcon';
import { DocumentIcon } from '../icons/DocumentIcon';
import { GameIcon } from '../icons/GameIcon';
import { ImageIcon } from '../icons/ImageIcon';
import { LockIcon } from '../icons/LockIcon';
import { LunaParkLogo } from '../icons/LogoIcon';
import { LPLogo } from '../icons/LPLogo';
import { ScoreboardIcon } from '../icons/ScoreboardIcon';
import { InstgramIcon, LinkedInIcon } from '../icons/SocialIcon';
import { StarIcon } from '../icons/StarIcon';
import { VolumeIcon } from '../icons/VolumeIcon';
import { CaseStudyHeader } from './TrainingStarterStylish';
import { openTrainingTypeform } from './TrainingTypeform';
import { useCreateEmptyGamePack, useCreateGuestUser } from './utils';

const EMPLOYEE_ONBOARDING_URL = getStaticAssetPath(
  'images/employee-onboarding-laptop-phone.png'
);
const MULTIPLE_CHOICE_GIF_URL = getStaticAssetPath(
  'images/training-gameified.gif'
);
const JEOPARKY_GIF_URL = getStaticAssetPath('images/jeoparky.gif');
const HOW_SCORM_WORKS_URL = getStaticAssetPath('images/how-scorm-works.png');
const READY_TO_DIVE_IN_VID_URL = getStaticAssetPath(
  'videos/ready-to-dive-in.mp4'
);

const BANNER_VIDEO_RESPONSIVE_STYLES = {
  mobile: {
    url: getStaticAssetPath('videos/training-banner-mobile-v3.mp4'),
    poster: getStaticAssetPath('images/training-banner-mobile-v3.png'),
    aspectRatio: '1/1',
    maxHeight: undefined,
    objectFit: 'contain' as const,
  },
  desktop: {
    url: getStaticAssetPath('videos/training-banner-desktop-v3.mp4'),
    poster: getStaticAssetPath('images/training-banner-desktop-v3.png'),
    aspectRatio: undefined,
    maxHeight: '500px',
    objectFit: 'cover' as const,
  },
};

export const meta: MetaFunction = () => {
  return [
    { title: 'Trainings | Luna Park' },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.desktop.poster,
      as: 'image',
    },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.mobile.poster,
      as: 'image',
    },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.desktop.url,
      as: 'fetch',
    },
    {
      tagName: 'link',
      rel: 'preload',
      href: BANNER_VIDEO_RESPONSIVE_STYLES.mobile.url,
      as: 'fetch',
    },
  ];
};

const faqItems: FAQ[] = [
  {
    id: '1',
    question: 'Is your content generator actually free?',
    answer:
      'Yes! You can generate courses, experience them, and share them with your colleagues on our platform—all without needing a credit card or paying any upfront fees.',
  },
  {
    id: '2',
    question: 'Can I download your content using SCORM?',
    answer:
      'Yes! While generating, experiencing, and sharing content on the Luna Park platform is free, you’ll need a paid plan to download files in SCORM format. This allows you to explore and test the platform before committing to a subscription.',
  },
  {
    id: '3',
    question: 'Is your LMS free to use?',
    answer:
      'Yes! Our LMS features—including learning journeys, onboarding stacks, and basic analytics—are available without requiring a credit card or any upfront payment.',
  },
  {
    id: '4',
    question: 'What sources do you use to generate courses?',
    answer: ` 
        We rely on a vetted pool of world-class, publicly available resources including, but not limited to:

        • Harvard Business Review
        • Learning Guild
        • MIT OpenCourseWare
        • OpenStax
        • McKinsey Academy
        • World Economic Forum
        • Brandon Hall Group
        • Gartner
        • TED Talks
        • Additional university sources, research articles and whitepapers from reputable industry experts\n
        These ensure that the content generated is accurate, relevant, and insightful`,
  },
  {
    id: '5',
    question: 'Am I able to edit the content that you generate?',
    answer:
      "Yes! Every element of our content—whether it's a course, quiz, or game—is fully customizable. You can edit text, images, and other components to personalize the content for your organization.",
  },
  {
    id: '6',
    question: 'What happens to the content I upload?',
    answer:
      'Your source materials are only used temporarily to generate personalized content. Once the content is created, we do not store your files on our backend. All uploaded files remain private to you and are not retained by us in any form.',
  },
];

function Header() {
  const analytics = useLearningAnalytics();
  const navigate = useNavigate();

  const handleBookDemo = (e: React.MouseEvent) => {
    e.preventDefault();
    analytics.trackLandingBookMeetingClicked({});
    window.location.href =
      'https://www.lunapark.com/lp/schedule-your-meeting-training-tool';
  };

  const handleTryFree = (e: React.MouseEvent) => {
    e.preventDefault();
    analytics.trackTryForFreeClicked({});
    navigate('/learning/assistant');
  };

  return (
    <header
      className={`
        w-full flex-none bg-black border-b border-secondary 
        flex items-center justify-center text-white px-4 lg:px-7.5 py-2
      `}
    >
      <div className='flex items-center justify-between w-full h-full'>
        <div className='h-full flex items-center gap-7.5 text-sms'>
          <a href={`${makeMarketLink('')}`}>
            <LunaParkLogo className='w-27 h-7.5 mr-4' />
          </a>
        </div>
        <div className='flex items-center gap-2'>
          <a
            href='https://www.lunapark.com/lp/schedule-your-meeting-training-tool'
            onClick={handleBookDemo}
            className='btn-secondary font-Montserrat border-0 rounded 
            w-25 sm:w-40 h-10 text-white text-sms font-bold flex items-center justify-center'
          >
            Book a Demo
          </a>
          <Link
            to='/learning/assistant'
            onClick={handleTryFree}
            className='btn-secondary bg-red-001 font-Montserrat border-0 rounded
            w-25 sm:w-40 h-10 text-sms font-bold flex items-center justify-center'
          >
            Try it free
          </Link>
        </div>
      </div>
    </header>
  );
}

export function Banner(props: {
  tagline: 'instantly-create' | 'engaging-games';
  dimWidth: number;
  videoCtrl: 'enabled' | 'disabled' | 'disabled-with-height';
  widthClasses?: string;
}) {
  const { dimWidth, videoCtrl } = props;
  const bannerVideoStyles =
    dimWidth <= 640
      ? BANNER_VIDEO_RESPONSIVE_STYLES.mobile
      : BANNER_VIDEO_RESPONSIVE_STYLES.desktop;

  const taglineUrl =
    props.tagline === 'instantly-create'
      ? 'images/training-tagline-2.png'
      : 'images/training-tagline.png';

  return (
    <div className='w-full relative'>
      {videoCtrl === 'enabled' ? (
        <div className='w-full relative pointer-events-none'>
          <video
            className='w-full inset-0'
            src={bannerVideoStyles.url}
            poster={bannerVideoStyles.poster}
            muted
            autoPlay
            loop
            style={{
              aspectRatio: bannerVideoStyles.aspectRatio,
              maxHeight: bannerVideoStyles.maxHeight,
              objectFit: bannerVideoStyles.objectFit,
            }}
            preload='auto'
          />
          <div className='bg-black bg-opacity-40 absolute inset-0' />
        </div>
      ) : videoCtrl === 'disabled-with-height' ? (
        <div className='w-full h-30' />
      ) : null}
      <img
        className={`md:max-w-3xl max-w-full absolute left-1/2 transform -translate-x-1/2 -translate-y-3/4 ${
          props.widthClasses ?? 'w-4/5 3xl:w-1/3'
        }`}
        src={getStaticAssetPath(taglineUrl)}
        alt='tagline'
      />
      <div className='w-full text-center text-base font-bold text-icon-gray mt-10 px-7.5'>
        3x better knowledge retention through personalized learning experiences
      </div>
    </div>
  );
}

function CustomActionButton(props: ActionProps) {
  const { disabled, disabledReason, submit, abort } = props;
  const submitting = disabledReason === 'submitting';

  return (
    <button
      type='button'
      className='py-1.5 px-4 flex items-center justify-center btn-delete font-normal text-sms sm:font-bold'
      disabled={disabledReason === 'emptyPrompt' ? false : disabled}
      title={
        disabledReason === 'filesNotReady' ? 'Wait for files to be ready' : ''
      }
      onClick={submitting ? abort : submit}
    >
      {submitting ? 'Stop' : 'Create ✨'}
    </button>
  );
}

type StarterOrigin = 'landing' | 'admin';

function Starter(props: {
  customAction?: CustomEditorPrimaryAction;
  alternateButton?: boolean;
  bottomLabel?: string;
  starterOrigin: StarterOrigin;
}) {
  const fileman = useUGCFileManager();
  const createGamePack = useCreateEmptyGamePack(null);
  const createUser = useCreateGuestUser();
  const navigate = useNavigate();

  const initFileman = useLiveCallback(async () => {
    if (fileman.inited) return;
    const user = await createUser();
    if (!user) return;
    const pack = await createGamePack();
    if (!pack) return;
    fileman.emitMessage('A new course created.');
    fileman.init(pack.id, []);
  });

  useEffect(() => {
    fileman.uppy.addPreProcessor(initFileman);
    return () => fileman.uppy.removePreProcessor(initFileman);
  }, [fileman.uppy, initFileman]);

  const {
    call: submit,
    state: { state, error },
  } = useLiveAsyncCall(async (prompt: string) => {
    if (prompt.trim().length === 0) {
      fileman.emitMessage('Please type in a topic.', 'error');
      return false;
    }

    const params = new URLSearchParams();

    if (props.starterOrigin !== 'landing') {
      const formResponse = await openTrainingTypeform();
      if (formResponse) {
        params.set('form-id', formResponse.formId);
        params.set('response-id', formResponse.responseId);
      }
    }

    const user = await createUser();
    if (!user) return false;
    const pack = await createGamePack();
    if (!pack) return false;
    params.set('prompt', prompt);
    params.set('id', pack.id);
    params.set('starter-origin', props.starterOrigin);
    navigate(`/learning/assistant?${params.toString()}`);
    return true;
  });

  return (
    <CustomGamePackPromptEditor
      enabled
      onSubmit={submit}
      onAbort={() => void 0}
      isSubmitting={state.isRunning}
      wrapperClassName='mt-auto py-4'
      active
      autoFocus
      error={error}
      placeholder='Type in a topic...'
      disableDeactivate
      width='w-full md:w-160'
      borderVariant={props.customAction ? 'gradient' : 'secondary'}
      bottomLabel={
        props.bottomLabel ?? 'Create for free. No credit card required'
      }
      alternateButton={props.alternateButton}
    >
      {props.customAction}
    </CustomGamePackPromptEditor>
  );
}

function LogoRow() {
  const logos = useInstance(() => [
    'Amazon',
    'Salesforce',
    'Spotify',
    'IBM',
    'Tiktok',
    'HubSpot',
    'Stripe',
    'Mastercard',
    'Warby',
    'DoorDash',
    'Lyra',
    'Confluent',
    'DuckDuckGo',
    'CodeCademy',
    'Snapdocs',
    'Instacart',
  ]);

  return (
    <section className='w-full bg-black py-8'>
      <div className='flex items-center justify-center gap-1 text-sms text-icon-gray mb-4'>
        <LockIcon className='w-3.5 h-3.5 fill-current' />
        <p>Loved by privacy-focused companies like</p>
      </div>

      <Marquee gradient={false} speed={50} className='h-9' autoFill>
        <div className='flex items-center gap-10 object-contain overflow-visible'>
          {logos.map((logo) => (
            <img
              key={logo}
              src={getStaticAssetPath(`images/training/logos/${logo}.png`)}
              alt={logo}
              className='max-w-20 max-h-9 logo-image'
            />
          ))}
        </div>
      </Marquee>
    </section>
  );
}
function UseCasesRow() {
  const dim = useWindowDimensions(20);
  const useCases = [
    { emoji: '📋', text: 'Employee Onboarding' },
    { emoji: '👆', text: 'Team Upskilling' },
    { emoji: '👆', text: 'Customer Service Training' },
    { emoji: '📈', text: 'Sales Training' },
    { emoji: '🎯', text: 'Leadership Development' },
    { emoji: '⚙️', text: 'Compliance Training' },
    { emoji: '🚀', text: 'Career Development' },
    { emoji: '💪', text: 'Product Training' },
  ];

  // Fade logic
  const fadeStyle =
    dim.width >= 640
      ? {
          WebkitMaskImage:
            'linear-gradient(to right, transparent, black 100px, black calc(100% - 100px), transparent)',
          maskImage:
            'linear-gradient(to right, transparent, black 100px, black calc(100% - 100px), transparent)',
        }
      : {
          WebkitMaskImage:
            'linear-gradient(to bottom, black 30%, black calc(100% - 50px), transparent 100%)',
          maskImage:
            'linear-gradient(to bottom, black 30%, black calc(100% - 50px), transparent 100%)',
        };

  return (
    <section className='w-full py-8'>
      <div className='max-w-5xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col gap-5 text-white items-center'>
        <h2 className='text-xl hidden sm:block'>Use cases</h2>
        <div className='relative w-full'>
          <div className='overflow-x-auto pb-4 sm:pb-0' style={fadeStyle}>
            <div className='flex flex-wrap gap-2.5 justify-center'>
              {useCases.map(({ emoji, text }, idx) => (
                <div
                  key={idx}
                  className={`
                    inline-flex items-center bg-dark-gray border border-[#868686]
                    rounded-full px-2 py-1 sm:px-5 sm:py-4 text-sms md:text-base
                    flex-[1_1_calc(50%-10px)]  
                    min-w-[100px]              
                    sm:min-w-[170px]              
                    max-w-[calc(100%-10px)]    
                    sm:max-w-[calc(50%-10px)]  
                    whitespace-nowrap          
                  `}
                >
                  <span className='mr-2'>{emoji}</span>
                  <span className='italic font-bold'>{text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function InstantlyCreate() {
  return (
    <section className='w-full py-12'>
      <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col items-center text-center gap-5'>
        <h2 className='text-2xl sm:text-4xl font-Montserrat font-bold text-white'>
          <span className='text-red-001'>Instantly</span> Create Interactive
          Learning Experiences.
        </h2>
        <p className='text-lg text-icon-gray font-bold'>
          With our Instant Creator, you can transform your ideas into engaging
          content tailored to your audience. Choose from three dynamic formats
          to bring your vision to life.
        </p>
      </div>
    </section>
  );
}

function EngagingCourses() {
  return (
    <section className='w-full py-12'>
      <div className='max-w-5xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row items-center gap-20'>
        <div className='flex-1'>
          <img
            src={EMPLOYEE_ONBOARDING_URL}
            alt='Employee Onboarding'
            className='w-full h-full object-cover rounded-lg'
          />
        </div>
        <div className='flex-1 flex flex-col space-y-4 text-icon-gray'>
          <h2 className='text-white text-2xl sm:text-3xl lg:text-4xl font-bold'>
            Engaging Courses: Your Content, Elevated
          </h2>
          <p>
            Deliver interactive training experiences designed to captivate and
            educate. Our course format is perfect for structured learning,
            offering:
          </p>

          <div className='flex flex-col gap-2 pl-1.5'>
            <div className='flex items-center gap-2.5'>
              <DocumentIcon className='flex-shrink-0 w-5 h-5 fill-current text-tertiary' />
              <span className='text-white font-bold'>
                Slides&nbsp;
                <span className='text-icon-gray font-normal'>
                  to present key ideas.
                </span>
              </span>
            </div>
            <div className='flex items-center gap-2.5'>
              <ImageIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#FF3975]' />
              <span className='text-white font-bold'>
                Images&nbsp;
                <span className='text-icon-gray font-normal'>
                  to make content visually engaging.
                </span>
              </span>
            </div>
            <div className='flex items-center gap-2.5'>
              <VolumeIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#3988FF]' />
              <span className='text-white font-bold'>
                Voiceovers&nbsp;
                <span className='text-icon-gray font-normal'>
                  for added clarity and emphasis.
                </span>
              </span>
            </div>
            <div className='flex items-center gap-2.5'>
              <MultipleChoiceBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#00D0C4]' />
              <span className='text-white font-bold'>
                Basic quizzes&nbsp;
                <span className='text-icon-gray font-normal'>
                  to reinforce and test knowledge.
                </span>
              </span>
            </div>
          </div>

          <p>
            Whether you're onboarding employees or upskilling your team, our
            Courses provide a familiar yet powerful way to learn.
          </p>
        </div>
      </div>
    </section>
  );
}

function Quizzes() {
  return (
    <section className='w-full py-12'>
      <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row items-center gap-20'>
        <div className='flex-1 flex flex-col space-y-4 text-icon-gray order-2 sm:order-1'>
          <h2 className='text-white text-2xl sm:text-3xl lg:text-4xl font-bold'>
            Quizzes: Test Knowledge, Spark Fun
          </h2>
          <p>
            Make learning competitive and fun with interactive quizzes inspired
            by tools like Kahoot. Features include:
          </p>

          <div className='flex flex-col gap-2 pl-1.5'>
            <div className='flex items-center'>
              <MultipleChoiceBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#FF3975]' />
              <span className='ml-2.5 text-white font-bold'>
                Customizable question formats.
              </span>
            </div>
            <div className='flex items-center'>
              <ScoreboardIcon className='flex-shrink-0 w-5 h-5 fill-current text-tertiary' />
              <span className='ml-2.5 text-white font-bold'>
                Real-time scoring to keep participants engaged.
              </span>
            </div>
            <div className='flex items-center'>
              <InstructionBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#7BC0FF]' />
              <span className='ml-2.5 text-white font-bold'>
                Perfect for knowledge checks, team-building activities
              </span>
            </div>
          </div>

          <p>Turn any topic into an exciting challenge!</p>
        </div>

        <div className='flex-1 order-1 sm:order-2  max-w-[220px]'>
          <img
            src={MULTIPLE_CHOICE_GIF_URL}
            alt='Quiz Example'
            className='w-full h-full object-cover rounded-lg'
          />
        </div>
      </div>
    </section>
  );
}

function GamesPlayLearnWin() {
  return (
    <section className='w-full py-12'>
      <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row items-center gap-20'>
        <div>
          <img
            src={JEOPARKY_GIF_URL}
            alt='Jeoparky Example'
            className='w-full h-full object-cover rounded-lg max-w-[220px]'
          />
        </div>
        <div className='flex-1 flex flex-col space-y-4 text-icon-gray'>
          <h2 className='text-white text-2xl sm:text-3xl lg:text-4xl font-bold'>
            Games: Play, Learn, Win
          </h2>
          <p>
            Bring the thrill of game shows to your training sessions! Our Game
            format offers experiences inspired by:
          </p>

          {/* Icons restored */}
          <div className='flex flex-col gap-2 pl-1.5'>
            <div className='flex items-center'>
              <JeopardyBlockIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#FF3975]' />
              <span className='ml-2.5 text-white font-bold'>
                Jeopardy:{' '}
                <span className='text-icon-gray font-normal'>
                  Test participants’ knowledge across categories.
                </span>
              </span>
            </div>
            <div className='flex items-center'>
              <StarIcon className='flex-shrink-0 w-5 h-5 fill-current text-tertiary' />
              <span className='ml-2.5 text-white font-bold'>
                Who Wants to Be a Millionaire:{' '}
                <span className='text-icon-gray font-normal'>
                  Add suspense with tiered rewards and lifelines.
                </span>
              </span>
            </div>
            <div className='flex items-center'>
              <GameIcon className='flex-shrink-0 w-5 h-5 fill-current text-[#FF3975]' />
              <span className='ml-2.5 text-white font-bold'>
                And More:{' '}
                <span className='text-icon-gray font-normal'>
                  Create your own unique games with custom questions and prizes.
                </span>
              </span>
            </div>
          </div>

          <p>Learning doesn’t have to be boring—make it unforgettable.</p>
        </div>
      </div>
    </section>
  );
}

function ExportScormFiles() {
  return (
    <section className='w-full py-12'>
      <div className='max-w-4xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col gap-10'>
        <div className='flex flex-col items-center text-center gap-5'>
          <h2 className='text-2xl sm:text-4xl font-Montserrat font-bold text-white'>
            Integrate with your LMS
          </h2>
          <p className='text-lg text-icon-gray font-bold'>
            Take your custom content to the next level with seamless SCORM file
            exports, making it easy to integrate into your existing Learning
            Management System (LMS). Designed with L&D content creators in mind,
            our SCORM export feature ensures your training materials are
            versatile, scalable, and ready to deliver results.
          </p>
        </div>

        <div className='flex flex-col sm:flex-row items-center gap-20'>
          <div className='flex-1'>
            <img
              src={HOW_SCORM_WORKS_URL}
              alt='How SCORM Works'
              className='w-full h-full object-cover rounded-lg'
            />
          </div>
          <div className='flex-1 flex flex-col gap-4 text-icon-gray'>
            <h3 className='text-2xl sm:text-3xl lg:text-4xl font-bold text-white'>
              How it Works
            </h3>
            <ol className='list-decimal pl-4 space-y-2'>
              <li>
                Create your experience using our{' '}
                <span className='font-bold text-white'>Instant Creator</span>.
              </li>
              <li>Personalize every element to suit your training goals.</li>
              <li>Export as a SCORM file in just a few clicks.</li>
              <li>
                Upload the SCORM file to your LMS and start delivering impactful
                training.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
}

type BringInspirationProps = {
  onGetStartedClick?: () => void;
};

function BringInspiration({ onGetStartedClick }: BringInspirationProps) {
  return (
    <section className='bg-[#F8F8F8] w-full pt-8'>
      <div className='max-w-5xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col lg:flex-row items-center gap-8'>
        <div className='flex flex-col gap-4 py-8 lg:py-16 flex-1'>
          <div className='max-w-xl flex flex-col gap-2.5 lg:gap-4'>
            <h2 className='font-Montserrat font-bold text-2xl lg:text-4xl text-black'>
              Bring Your Inspiration to Life
            </h2>
            <p className='text-icon-gray text-base font-bold lg:text-xl'>
              No matter your goal, our Instant Creator empowers you to design
              content that resonates. Simply share your content inspiration,
              choose your format, and watch your ideas come to life in seconds.
            </p>
            <button
              className='btn-delete w-40 h-10 flex items-center justify-center text-sms font-bold mt-4'
              onClick={onGetStartedClick}
              type='button'
            >
              Get Started
            </button>
          </div>
        </div>
        <video
          src={READY_TO_DIVE_IN_VID_URL}
          muted
          autoPlay
          loop
          style={{
            maxHeight: '460px',
            maxWidth: '100%',
            objectFit: 'cover',
          }}
          preload='auto'
        />
      </div>
    </section>
  );
}

function CaseStudy() {
  return (
    <section className='bg-black w-full relative'>
      <div className='absolute inset-0 bg-gradient-to-t from-[#0029FF] opacity-30 to-transparent pointer-events-none' />
      <div className='max-w-5xl sm:h-140 mx-auto px-4 sm:px-6 md:px-8 flex flex-col items-start lg:items-center gap-4 py-8 lg:pt-16 lg:pb-28 relative z-10'>
        <CaseStudyHeader />

        <div className='w-full sm:w-2/3 flex flex-col gap-2.5 lg:gap-4 self-start mt-8'>
          <div className='font-Montserrat font-bold text-2xl lg:text-4xl text-white'>
            KPMG uses gamified learning to increase fees by{' '}
            <span className='text-green-001'>25%</span>
          </div>

          <div className='text-icon-gray text-base lg:text-xl font-bold'>
            Discover how KPMG leveraged a learning game to bridge knowledge
            gaps... increased employee engagement... and fueled remarkable
            revenue growth.
          </div>

          <Link
            className='btn-delete w-40 h-10 flex items-center justify-center text-sms font-bold mt-4'
            to='https://lunapark.com/blog/kpmg-globerunner-a-case-study-in-gamified-learning/'
          >
            Learn More
          </Link>
        </div>

        <img
          className='w-72 absolute bottom-0 right-0 hidden lg:block'
          src={getStaticAssetPath('images/training-case-study-cover.png')}
          alt='case-study'
        />
      </div>
    </section>
  );
}

function FAQList() {
  return (
    <section className='w-full bg-black py-10 hidden sm:block'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
        <h2 className='text-4xl font-bold text-white text-center mb-12'>FAQ</h2>
        <div className='max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-4'>
          {faqItems.map((item) => (
            <FAQItem key={item.question} faq={item} />
          ))}
        </div>
      </div>
    </section>
  );
}

function Footer() {
  return (
    <footer className='w-full bg-black sm:bg-lp-gray-009 py-3 pb-4'>
      <div className='max-w-7xl mx-auto px-6'>
        <div className='flex flex-row items-start sm:items-center justify-between'>
          <div className='flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-16'>
            <LPLogo />
            <div className='flex flex-col sm:flex-row gap-4 sm:gap-8 text-sms'>
              <Link
                to={makeMarketLink('/terms-of-use/')}
                className='text-white hover:text-gray-300'
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms of Service
              </Link>
              <Link
                to={makeMarketLink('/privacy-policy/')}
                className='text-white hover:text-gray-300'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className='flex flex-row items-start space-x-4 mt-3.5 sm:mt-0'>
            <Link
              to='https://www.instagram.com/golunapark'
              className='text-white hover:text-gray-300'
              target='_blank'
              rel='noopener noreferrer'
            >
              <InstgramIcon />
            </Link>
            <Link
              to='https://www.linkedin.com/company/golunapark'
              className='text-white hover:text-gray-300'
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkedInIcon />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export function AnonTrainingGenAIStarter() {
  const ref = useRef<HTMLDivElement>(null);
  const dim = useWindowDimensions(20);

  const scrollableRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.background = `url('${getStaticAssetPath(
      'images/ugc/background-landing-page.png'
    )}')`;
    ref.current.style.backgroundRepeat = 'no-repeat';
    ref.current.style.backgroundSize = 'cover';
  }, []);

  const [openHelpWidget, setOpenHelpWidget] = useState(false);

  return (
    <div
      className='w-full h-full overflow-y-auto scrollbar'
      ref={scrollableRef}
    >
      <Header />
      <main className='flex flex-col items-center'>
        <Banner
          dimWidth={dim.width}
          tagline='instantly-create'
          videoCtrl='enabled'
        />
        <section className='w-full'>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-8'>
            <UGCFileManagerProvider>
              <Starter
                customAction={(props) => <CustomActionButton {...props} />}
                alternateButton
                starterOrigin='landing'
              />
            </UGCFileManagerProvider>
          </div>
        </section>
        <LogoRow />
        <UseCasesRow />
        <InstantlyCreate />
        <EngagingCourses />
        <div ref={ref} className='w-full'>
          <Quizzes />
          <GamesPlayLearnWin />
          <ExportScormFiles />
        </div>
        <BringInspiration onGetStartedClick={scrollToTop} />
        <CaseStudy />
        <FAQList />
      </main>
      <Footer />
      <HelpWidget open={openHelpWidget} setOpen={setOpenHelpWidget} />
    </div>
  );
}

export function TrainingGenAIStarter() {
  const dim = useWindowDimensions(20);
  return (
    <div className='w-full'>
      <main>
        <Banner
          dimWidth={dim.width}
          tagline='instantly-create'
          widthClasses='w-4/5 lg:w-2/5 md:w-3/5'
          videoCtrl='disabled-with-height'
        />
        <UGCFileManagerProvider>
          <div className='px-7.5 py-8'>
            <Starter
              alternateButton={false}
              bottomLabel='AI Editor can make mistakes. Please check your work.'
              starterOrigin='admin'
            />
          </div>
        </UGCFileManagerProvider>
      </main>
    </div>
  );
}
