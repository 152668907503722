import { useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { type Media, type MediaFormatVersion } from '@lp-lib/media';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { type GamePack } from '../../types/game';
import { assertExhaustive } from '../../utils/common';
import { MediaUtils } from '../../utils/media';

export type GamePackCoverSize = 'large' | 'medium' | 'small';

export function getGamePackCoverStyle(size: GamePackCoverSize): {
  outer: string;
  inner: string;
} {
  switch (size) {
    case 'large':
      return {
        outer: 'rounded-2.5xl p-1',
        inner: 'rounded-2.5xl',
      };
    case 'medium':
      return {
        outer: 'rounded-1.5lg p-0.5',
        inner: 'rounded-1.5lg',
      };
    case 'small':
      return {
        outer: 'rounded p-0.5',
        inner: 'rounded',
      };
    default:
      assertExhaustive(size);
      return {
        outer: '',
        inner: '',
      };
  }
}

export interface GamePackCoverPresProps {
  pack: GamePack | null;
  preferredFormatVersion?: MediaFormatVersion;
  outerClassName?: string;
  outerBg?: string;
  size?: GamePackCoverSize;
  hover?: React.ReactNode;
  myBadge?: React.ReactNode;
  featuredBadge?: React.ReactNode;
  children?: React.ReactNode;
  lazy?: boolean;
}

/**
 * Goal is to have zero dependencies on any context or state data.
 */

export function GamePackCoverPres(props: GamePackCoverPresProps) {
  const style = getGamePackCoverStyle(props.size || 'small');
  const [load, setLoad] = useState(!props.lazy);

  return (
    <div className='relative'>
      <div className='aspect-w-16 aspect-h-9 group'>
        <div
          className={`w-full h-full ${
            props.outerBg ?? 'bg-secondary'
          } game-cover-clip ${style.outer} ${
            props.hover ? 'group-hover:bg-primary' : ''
          } ${props.outerClassName ?? ''}`}
        >
          <div
            className={`w-full h-full bg-dark-gray game-cover-clip ${style.inner} z-1`}
          >
            {load ? (
              <GamePackCoverImage
                pack={props.pack}
                alt={`${props.pack?.name} cover`}
                preferredFormatVersion={props.preferredFormatVersion}
                className='absolute object-cover w-full h-full -z-1'
              />
            ) : (
              <Waypoint onEnter={() => setLoad(true)}></Waypoint>
            )}

            {props.pack && (
              <>
                {props.myBadge ?? null}
                {props.featuredBadge ?? null}
              </>
            )}

            {props.hover && (
              <div
                className={`w-full h-full p-1 hidden group-hover:flex items-center justify-center bg-lp-black-004 absolute left-0 top-0 z-10 text-white`}
              >
                {props.hover}
              </div>
            )}

            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export function GamePackCoverImage(props: {
  pack: { cover?: Media | null } | null | undefined;
  alt: string;
  preferredFormatVersion?: MediaFormatVersion;
  className?: string;
}) {
  const coverSrc =
    MediaUtils.PickMediaUrl(props.pack?.cover, {
      priority: props.preferredFormatVersion
        ? [props.preferredFormatVersion]
        : undefined,
    }) || placeholder;

  return (
    <img
      src={coverSrc}
      alt={props.alt}
      className={`${props.className ?? ''}`}
    />
  );
}

export const GameCoverClip = (props: { id: string }): JSX.Element => {
  return (
    <svg width='0' height='0' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id={props.id} clipPathUnits='objectBoundingBox'>
          <path
            d='M0,0.301 C0,0.284,0.004,0.268,0.012,0.257 L0.171,0.018 C0.179,0.007,0.19,0,0.201,0 H0.958 C0.981,0,1,0.028,1,0.063 V0.938 C1,0.972,0.981,1,0.958,1 H0.042 C0.019,1,0,0.972,0,0.938 V0.301'
            fill='black'
            fillOpacity='0.8'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
