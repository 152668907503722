import {
  type KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { CourseModalCard } from '../Components/CourseModalCard';
import {
  CourseMultiSelect,
  type CourseOption,
} from '../Components/CourseMultiSelect';

interface CreateStackModalProps {
  onClose: () => void;
  onConfirm: (name: string, selectedCourses: string[]) => Promise<void>;
}

export function CreateStackModal({
  onClose,
  onConfirm,
}: CreateStackModalProps) {
  const [name, setName] = useState('');
  const [selectedCourses, setSelectedCourses] = useState<CourseOption[]>([]);
  const courseSelectRef = useRef<HTMLDivElement>(null);
  const coursesListRef = useRef<HTMLDivElement>(null);

  const isValid = name.trim().length > 0 && selectedCourses.length > 0;

  // Auto-scroll to the latest course when added
  useEffect(() => {
    if (selectedCourses.length > 0) {
      const container = coursesListRef.current;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }
  }, [selectedCourses.length]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (isValid) {
        handleConfirm();
      } else {
        const input = courseSelectRef.current?.querySelector('input');
        if (input) {
          input.focus();
        }
      }
    }
  };

  const handleConfirm = () => {
    if (isValid) {
      onConfirm(
        name.trim(),
        selectedCourses.map((course) => course.value)
      );
    }
  };

  const handleDeleteCourse = (id: string) => {
    setSelectedCourses((prev) => prev.filter((c) => c.value !== id));
  };

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[800px] h-auto'
      innerClassName='bg-main-layer'
    >
      <div className='px-8 pt-8 pb-8 text-white flex flex-col gap-6'>
        <div className='text-xl font-bold text-center'>Create Course Stack</div>

        <div>
          <div className='text-lg font-medium text-white mb-2'>Stack name:</div>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder='Enter stack name'
            className='w-full px-4 py-3 bg-black border border-lp-gray-003 rounded-lg text-white placeholder:text-gray-500 focus:outline-none focus:border-lp-gray-002 text-sms'
            autoFocus
          />
        </div>
        <div className='border border-lp-gray-003 w-full my-2' />
        <div>
          <div className='text-lg font-medium text-white mb-2'>
            Add Courses to the Stack
          </div>

          <p className='text-sms text-icon-gray text-left mb-4'>
            You can then assign this stack just like you would a course.
          </p>

          {selectedCourses.length > 0 && (
            <div className='rounded-lg overflow-hidden mb-3'>
              <div
                ref={coursesListRef}
                className='max-h-64 overflow-y-auto flex flex-col gap-2.5'
              >
                {selectedCourses.map((course) => (
                  <CourseModalCard
                    key={course.value}
                    course={course}
                    onDelete={handleDeleteCourse}
                  />
                ))}
              </div>
            </div>
          )}

          <div ref={courseSelectRef}>
            <CourseMultiSelect
              onSelect={(course) => {
                setSelectedCourses((prev) => [...prev, course]);
              }}
              placeholder='Search and select courses'
              excludeCourseIds={selectedCourses.map((c) => c.value)}
            />
          </div>
        </div>

        <div className='flex justify-center gap-4'>
          <button
            type='button'
            onClick={onClose}
            className='px-6 py-2 rounded-lg transition-colors btn btn-secondary'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={handleConfirm}
            disabled={!isValid}
            className={`px-6 py-2 btn btn-primary ${
              isValid
                ? 'bg-cyan-500 text-white hover:bg-cyan-600'
                : 'bg-gray-600 text-gray-300 cursor-not-allowed'
            }`}
          >
            Create Stack
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

interface TriggerCreateStackModalProps {
  onConfirm: (name: string, courseIds: string[]) => Promise<void>;
}

export function useTriggerCreateStackModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: TriggerCreateStackModalProps) => {
      return triggerModal({
        kind: 'custom',
        containerClassName: 'bg-lp-black-002',
        element: (modalProps) => {
          function ModalContent() {
            return (
              <CreateStackModal
                onClose={modalProps.internalOnCancel}
                onConfirm={async (name, courseIds) => {
                  await props.onConfirm(name, courseIds);
                  modalProps.internalOnConfirm();
                }}
              />
            );
          }
          return <ModalContent />;
        },
      });
    },
    [triggerModal]
  );
}
