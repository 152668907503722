import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Select from 'react-select';
import useSWR from 'swr';

import {
  type DtoProduct,
  type DtoSimpleStripePrice,
  type EnumsProductBillingInterval,
  StripePriceRecurringInterval,
  StripePriceType,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { Loading } from '../Loading';
import { ProductUtils } from './utils';

const currency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

type FormData = {
  type: StripePriceType;
  recurring?: {
    interval: StripePriceRecurringInterval;
    interval_count: number;
  };
  description: string;
  amount: number;
};

function PriceTypeCard(props: {
  title: string;
  description: string;
  selected: boolean;
  onSelect: () => void;
  children?: React.ReactNode;
}) {
  return (
    <label className='w-full p-4 border border-secondary rounded-xl flex items-start gap-4 hover:cursor-pointer'>
      <input
        type={'radio'}
        checked={props.selected}
        className='field-radio w-4 h-4'
        onChange={(e) => {
          e.stopPropagation();
          props.onSelect();
        }}
      />

      <div className='flex-1'>
        <h3 className='text-sms font-bold'>{props.title}</h3>
        <p className='text-sms text-icon-gray'>{props.description}</p>
        {props.children}
      </div>
    </label>
  );
}

function PricePreviewModal(props: { data: FormData }) {
  const { data } = props;
  return (
    <div className='px-5 py-3 text-white text-center'>
      <p className='text-2xl font-medium'>Review the price</p>
      <table className='w-full mt-4 text-sms text-left border border-secondary'>
        <tbody>
          <tr className='odd:bg-lp-gray-001'>
            <td className='font-medium p-1'>Type</td>
            <td className='p-1'>
              {data.type === StripePriceType.PriceTypeRecurring
                ? 'Recurring'
                : 'One-time'}
            </td>
          </tr>
          <tr className='odd:bg-lp-gray-001'>
            <td className='font-medium p-1'>Amount</td>
            <td className='p-1'>{currency.format(data.amount)}</td>
          </tr>
          {data.type === StripePriceType.PriceTypeRecurring &&
            data.recurring && (
              <tr className='odd:bg-lp-gray-001'>
                <td className='font-medium p-1'>Billing Period</td>
                <td className='p-1'>
                  {`Every ${data.recurring.interval_count} ${data.recurring.interval}(s)`}
                </td>
              </tr>
            )}
          <tr className='odd:bg-lp-gray-001'>
            <td className='font-medium p-1'>Description</td>
            <td className='p-1'>{data.description || 'N/A'}</td>
          </tr>
        </tbody>
      </table>
      <p className='mt-4 text-sms'>
        We don't support editing prices after they are created. Please make sure
        the price is correct before saving.
      </p>
    </div>
  );
}

export function CustomPriceEditor(props: {
  product: DtoProduct;
  defaultAmount: number;
  onCancel: () => void;
  onSave: (price: DtoSimpleStripePrice) => Promise<void>;
}) {
  const { product } = props;
  const form = useForm<FormData>({
    defaultValues: {
      type: StripePriceType.PriceTypeRecurring,
      recurring: {
        interval: StripePriceRecurringInterval.PriceRecurringIntervalMonth,
        interval_count: 3,
      },
      amount: Number(props.defaultAmount),
      description: '',
    },
  });
  const styles = useMemo(
    () =>
      buildReactSelectStyles({
        override: {
          control: {
            height: '40px',
          },
        },
      }),
    []
  );
  const intervalOptions = useInstance(() => [
    {
      label: StripePriceRecurringInterval.PriceRecurringIntervalDay,
      value: StripePriceRecurringInterval.PriceRecurringIntervalDay,
    },
    {
      label: StripePriceRecurringInterval.PriceRecurringIntervalWeek,
      value: StripePriceRecurringInterval.PriceRecurringIntervalWeek,
    },
    {
      label: StripePriceRecurringInterval.PriceRecurringIntervalMonth,
      value: StripePriceRecurringInterval.PriceRecurringIntervalMonth,
    },
    {
      label: StripePriceRecurringInterval.PriceRecurringIntervalYear,
      value: StripePriceRecurringInterval.PriceRecurringIntervalYear,
    },
  ]);

  const type = form.watch('type');
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
  } = form;

  const onSave = handleSubmit(async (data) => {
    const resp = await triggerModal({
      kind: 'confirm-cancel',
      prompt: <PricePreviewModal data={data} />,
      confirmBtnLabel: 'Continue',
      cancelBtnLabel: 'Cancel',
    });
    if (resp.result === 'canceled') return;
    try {
      const resp = await apiService.product.addUnmangedStripePrice(product.id, {
        amount: data.amount,
        description: data.description,
        type: data.type,
        recurring: data.recurring,
      });
      await props.onSave(resp.data.price);
    } catch (error) {
      form.setError('root.serverError', {
        message: err2s(error) ?? 'Something went wrong, please try again.',
      });
      throw error;
    }
  });

  return (
    <FormProvider {...form}>
      <section className='p-7.5 w-full h-full relative'>
        <header className='text-2xl font-medium'>{`Add price to ${product.name}`}</header>
        <main className='mt-5 w-full flex flex-col gap-5'>
          <Controller
            name='type'
            control={form.control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <div className='flex items-center gap-2'>
                <PriceTypeCard
                  title='Recurring'
                  description='Charge an ongoing fee'
                  selected={value === StripePriceType.PriceTypeRecurring}
                  onSelect={() => onChange(StripePriceType.PriceTypeRecurring)}
                />
                <PriceTypeCard
                  title='One-off'
                  description='Charge a one-time fee'
                  selected={value === StripePriceType.PriceTypeOneTime}
                  onSelect={() => onChange(StripePriceType.PriceTypeOneTime)}
                />
              </div>
            )}
          />
          <Controller
            name='amount'
            control={form.control}
            rules={{ required: true, min: 1 }}
            render={({ field: { value, onChange }, fieldState }) => (
              <div>
                <label className='font-bold mb-2'>Price Amount</label>
                <div className='flex items-center relative'>
                  <div className='absolute left-2'>$</div>
                  <input
                    type='number'
                    className={`${
                      fieldState.error ? 'field-error' : 'field'
                    } mb-0 h-10 px-8`}
                    placeholder='0.00'
                    value={value}
                    min={1}
                    onChange={(e) => onChange(Number(e.target.value))}
                  />
                  <div className='absolute right-2'>USD</div>
                </div>
              </div>
            )}
          />
          {type === StripePriceType.PriceTypeRecurring && (
            <Controller
              name='recurring'
              control={form.control}
              render={({ field: { value, onChange }, fieldState }) => (
                <div>
                  <label className='font-bold mb-2'>Billing period</label>
                  <div className='flex items-center gap-1'>
                    <div className='text-sms text-secondary'>Every</div>
                    <input
                      type='number'
                      className={`${
                        fieldState.error ? 'field-error' : 'field'
                      } mb-0 h-10`}
                      placeholder='2'
                      value={value?.interval_count ?? 2}
                      min={1}
                      onChange={(e) =>
                        onChange({
                          ...value,
                          interval_count: Number(e.target.value),
                        })
                      }
                    />
                    <Select
                      options={intervalOptions}
                      onChange={(o) =>
                        onChange({
                          ...value,
                          interval:
                            o?.value ??
                            StripePriceRecurringInterval.PriceRecurringIntervalMonth,
                        })
                      }
                      value={intervalOptions.find(
                        (o) => o.value === value?.interval
                      )}
                      classNamePrefix='select-box-v2'
                      className='w-full h-full'
                      styles={styles}
                    />
                  </div>
                </div>
              )}
            />
          )}
          <Controller
            name='description'
            control={form.control}
            render={({ field: { value, onChange }, fieldState }) => (
              <div>
                <label className='font-bold mb-2'>Price description</label>
                <div className='flex items-center relative'>
                  <input
                    type='text'
                    className={`${
                      fieldState.error ? 'field-error' : 'field'
                    } mb-0 h-10`}
                    placeholder='Use to organize your prices. Not shown to customers.'
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                  />
                </div>
              </div>
            )}
          />
          {errors.root?.serverError && (
            <div className='text-sms text-red-002'>
              {errors.root.serverError.message}
            </div>
          )}
        </main>
        <footer className='w-full absolute bottom-2 right-4 flex justify-end items-center gap-10'>
          <button type='button' className='btn' onClick={props.onCancel}>
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary h-10 px-4 min-w-30 flex items-center justify-center gap-1'
            onClick={onSave}
            disabled={isSubmitting}
          >
            {isSubmitting && <Loading text='' />}
            <p>Save</p>
          </button>
        </footer>
      </section>
    </FormProvider>
  );
}

type PriceRecurring =
  | {
      custom: false;
      interval: EnumsProductBillingInterval;
    }
  | {
      custom: true;
      interval: StripePriceRecurringInterval;
      interval_count: number;
    };

type PriceOption = {
  id: string;
  amount: number;
  description: string;
  type: StripePriceType;
} & PriceRecurring;

export function usePriceOptions(product: DtoProduct | undefined) {
  const productId = product?.id;
  const {
    data: stripePrices,
    isLoading,
    mutate,
  } = useSWR(
    productId ? `/products/${productId}/stripe-prices` : null,
    async () => {
      if (!productId) return;
      const resp = await apiService.product.listStripePrices(productId);
      return resp.data.prices;
    }
  );

  const priceOptions = useMemo(() => {
    if (!product) return [];
    const managedPrices = (product.prices ?? []).sort(
      (a, b) => a.amount - b.amount
    );
    const managedPriceOptions: PriceOption[] = [];
    const set = new Set<string>();
    for (const p of managedPrices) {
      if (!p.stripePriceId || p.archived) continue;
      set.add(p.stripePriceId);
      managedPriceOptions.push({
        id: p.stripePriceId,
        amount: p.amount,
        description: `Max Seats: ${p.maxSeats}`,
        type: StripePriceType.PriceTypeRecurring,
        custom: false,
        interval: p.billingInterval,
      });
    }
    const customPrices = (stripePrices ?? [])
      .filter((p) => !set.has(p.id))
      .sort((a, b) => a.unit_amount - b.unit_amount);
    const customPriceOptions: PriceOption[] = [];
    for (const p of customPrices) {
      if (!p.active) continue;
      customPriceOptions.push({
        id: p.id,
        amount: p.unit_amount / 100,
        description: p.description,
        type: p.type,
        custom: true,
        interval: p.recurring?.interval,
        interval_count: p.recurring?.interval_count ?? 0,
      });
    }
    return [
      { label: 'Custom (Unmanaged)', options: customPriceOptions },
      { label: 'Managed', options: managedPriceOptions },
    ];
  }, [product, stripePrices]);

  return { priceOptions, isLoading, mutate };
}

function FormatPriceOptionLabel(props: { option: PriceOption }) {
  const { option } = props;
  if (!option.custom) {
    return (
      <div className='flex items-center justify-between'>
        <div>
          {currency.format(option.amount)} USD /
          {ProductUtils.FormatInterval(option.interval, true)}
        </div>
        <div>{option.description}</div>
      </div>
    );
  }

  if (option.type === StripePriceType.PriceTypeOneTime) {
    return (
      <div className='flex items-center justify-between'>
        <div>{currency.format(option.amount)} USD / One-time</div>
        <div>{option.description}</div>
      </div>
    );
  } else {
    const interval =
      option.interval_count > 1
        ? `Every ${option.interval_count} ${option.interval}s`
        : `Per ${option.interval}`;
    return (
      <div className='flex items-center justify-between'>
        <div>
          {currency.format(option.amount)} USD / {interval}
        </div>
        <div>{option.description}</div>
      </div>
    );
  }
}
export function AllPricePicker(props: {
  priceOptions: {
    label: string;
    options: PriceOption[];
  }[];
  disabled?: boolean;
  onChange: (option: PriceOption | null) => void;
  value: PriceOption | null;
}) {
  const { priceOptions, value } = props;
  const styles = useMemo(
    () =>
      buildReactSelectStyles({
        override: {
          control: {
            height: '40px',
          },
        },
      }),
    []
  );
  return (
    <Select<PriceOption>
      isDisabled={props.disabled}
      options={priceOptions}
      onChange={(o) => props.onChange(o)}
      value={
        priceOptions
          .flatMap((o) => o.options)
          .find((o) => o.id === value?.id) ?? null
      }
      classNamePrefix='select-box-v2'
      className='w-full h-full'
      styles={styles}
      getOptionValue={(option) => option.id}
      formatOptionLabel={(option) => <FormatPriceOptionLabel option={option} />}
    />
  );
}
