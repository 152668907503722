import { format } from 'date-fns';

import coverPlaceholder from '../../../../assets/img/placeholder/game-cover.png';
import { XIcon } from '../../../icons/XIcon';
import { type CourseOption } from './CourseMultiSelect';

type CourseModalCardProps = {
  course: CourseOption;
  onDelete: (id: string) => void;
};

export function CourseModalCard(props: CourseModalCardProps) {
  const { course, onDelete } = props;
  return (
    <div
      key={course.value}
      className='flex items-center justify-between p-1.5 bg-lp-gray-009 rounded-lg'
    >
      <div className='flex items-center gap-3'>
        <img
          src={course.course.cover?.url || coverPlaceholder}
          alt={course.course.name}
          className='w-19 h-10 rounded-lg object-cover'
        />
        <span className='text-white font-bold text-sms'>
          {course.course.name}
        </span>
      </div>
      <div className='flex items-center gap-4'>
        <span className='text-white text-left text-sms'>
          {format(new Date(course.course.createdAt), 'MM/dd/yyyy')}
        </span>
        <button
          type='button'
          onClick={() => onDelete(course.value)}
          className='flex items-center justify-center pr-3'
        >
          <XIcon className='w-4 h-4 fill-current' />
        </button>
      </div>
    </div>
  );
}
