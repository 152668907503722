import { EnumsExternalMediaProvider } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import { getStaticAssetPath } from '../../../utils/assets';
import { type MediaItem, type MediaProvider } from '../MediaProvider';

const UNSPLASH_LOGO = getStaticAssetPath('images/unsplash-logo.png');

export const UnsplashProvider: MediaProvider = {
  name: 'Unsplash',
  kind: EnumsExternalMediaProvider.ExternalMediaProviderUnsplash,
  proxy: false,
  filter: {
    aspectRatio: true,
  },
  icon: <img src={UNSPLASH_LOGO} alt='Unsplash logo' className='w-8 h-8' />,
  async searchMedia(params): Promise<MediaItem[]> {
    const response = await apiService.media.searchExternalMedia({
      ...params,
      provider: 'unsplash',
      size: 25,
    });

    return response.data.items;
  },
};
