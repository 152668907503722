import { useCallback } from 'react';

import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { CreateGroupModal } from './Modals/CreateGroupModal';

interface TriggerModalOptions {
  onConfirm: (params: { name: string; emails: string[] }) => void;
}

export function useTriggerCreateGroupModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    ({ onConfirm }: TriggerModalOptions) => {
      return triggerModal({
        kind: 'custom',
        containerClassName: 'bg-lp-black-002',
        element: (modalProps) => (
          <CreateGroupModal
            onClose={modalProps.internalOnCancel}
            onConfirm={(params) => {
              onConfirm(params);
              modalProps.internalOnConfirm();
            }}
          />
        ),
      });
    },
    [triggerModal]
  );
}
