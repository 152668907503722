import {
  Link,
  Navigate,
  Route,
  Routes,
  useSearchParams,
} from '@remix-run/react';
import { type ReactNode, useCallback, useMemo } from 'react';

import { AdminAIChat } from '../../components/AIChat';
import { DrawingList } from '../../components/Drawing';
import { IntakeSummary, LiveEventSettings } from '../../components/Event';
import { AdminEventList } from '../../components/Event/AdminEventList';
import { LiveEventCreate } from '../../components/Event/Create';
import { EventManage } from '../../components/Event/Manager';
import { TeamRelayAdminPanel } from '../../components/Game/Blocks/TeamRelay';
import { MessageCampaignList } from '../../components/MessageCampaign';
import { MessageCampaignEditor } from '../../components/MessageCampaign/Editor';
import { MessageCampaignMessageList } from '../../components/MessageCampaign/Message';
import { MessageTemplateList } from '../../components/MessageTemplate';
import { OrganizationSelect } from '../../components/Organization';
import {
  SESSION_MODE_OPTIONS,
  SessionModeFilter,
  SessionTrackHistory,
} from '../../components/Session';
import { AdminSharedAsset } from '../../components/SharedAsset';
import { AdminTagList } from '../../components/Tagging';
import config from '../../config';
import { useTitle } from '../../hooks/useTitle';
import { makeTitle } from '../../utils/common';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { AdminSharedScripts } from './AdminSharedScripts';
import { AdminView } from './AdminView';
import { CloudHostingList } from './CloudHosting';
import { DmgTemplates } from './DmgTemplates';
import { Header, type NavItem } from './Header';
import { LogoColorExtraction } from './LogoColorExtraction';
import { AdminMarkdownPreview } from './MarkdownPreview';
import { MediaUpload } from './MediaUpload';
import SessionInspector from './SessionInspector';
import { SlackSocketModeConnections } from './Slack';
import { VoiceOverLocales } from './VoiceOverLocales';

export function AdminToolkitNav(props: {
  slackAdminToolEnabled?: boolean;
}): JSX.Element {
  const { slackAdminToolEnabled = config.misc.slackAdminToolEnabled } = props;

  const links: Record<string, NavItem> = {
    Sessions: '/admin/toolkit/sessions',
    'Message Campaigns': {
      'Message Templates': '/admin/toolkit/message-templates',
      'Message Campaigns': '/admin/toolkit/message-campaigns',
    },
    'Drip Campaigns': '/admin/messages/campaigns',
    'Scheduled Games': '/admin/toolkit/events',
    AI: {
      'Prompt Templates': '/admin/prompt-templates',
      'Chat Coversations': '/admin/toolkit/ai-chat/threads',
      'Realtime Voice Chat': '/admin/voice-chat',
      'Training Prompt Profiles': '/admin/training/profiles',
      'Training Intelligence': '/admin/training/intelligence',
      'Training Media Tool': '/admin/training/media-tool',
    },
    'Shared Media': '/admin/toolkit/shared-media',
    Products: {
      Products: '/admin/products',
      'OTP Price Table': '/admin/purchases/pricing-table',
      'OTP Activities': '/admin/purchases/activities',
      'HubSpot Products': '/admin/hubspot/products',
    },
    Misc: {
      'Team Relay Settings': '/admin/toolkit/team-relay-settings',
      'Cloud Hosting': '/admin/toolkit/cloud-hosting',
      DMG: '/admin/toolkit/dmg',
      'Test Media Upload': '/admin/toolkit/media-upload',
      Drawings: '/admin/toolkit/drawings',
      'Live Event Settings': '/admin/toolkit/live-event-settings',
      Categories: '/admin/toolkit/tags',
      'Markdown Preview': '/admin/toolkit/markdown',
      'Shared Scripts': '/admin/toolkit/shared-scripts',
      'Voice Over Locales': '/admin/toolkit/voice-over-locales',
      'Logo Color Extraction': '/admin/toolkit/logo-color-extraction',
    },
  };
  if (slackAdminToolEnabled) {
    uncheckedIndexAccess_UNSAFE(links)['Misc']['Slack'] =
      '/admin/toolkit/slack';
  }
  return <Header links={links} />;
}

function Wrapper({ children }: { children?: ReactNode }): JSX.Element {
  return (
    <div className='w-full h-full'>
      <div className='py-10 px-10'>
        <AdminToolkitNav />
      </div>
      {children}
    </div>
  );
}

function TeamRelaySettings(): JSX.Element | null {
  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <TeamRelayAdminPanel />
    </div>
  );
}

type SessionFilters = {
  orgId?: string;
  mode?: string;
  invalid?: boolean;
};

function useSessionFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useMemo(() => {
    const filters: SessionFilters = {};
    if (searchParams.has('orgId')) {
      filters.orgId = searchParams.get('orgId') ?? undefined;
    }
    if (searchParams.has('mode')) {
      filters.mode = searchParams.get('mode') ?? undefined;
    }
    if (searchParams.has('invalid')) {
      filters.invalid = searchParams.get('invalid') === 'true';
    }
    return filters;
  }, [searchParams]);

  const setFilters = useCallback(
    (filters: SessionFilters) => {
      setSearchParams((prev) => {
        for (const [filterName, value] of Object.entries(filters)) {
          if (value === undefined) {
            prev.delete(filterName);
          } else {
            prev.set(filterName, String(value));
          }
        }
        return prev;
      });
    },
    [setSearchParams]
  );

  const clearFilters = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('orgId');
      prev.delete('mode');
      prev.delete('invalid');
      return prev;
    });
  }, [setSearchParams]);

  return {
    filters,
    setFilters,
    clearFilters,
  };
}

function SessionFilterControl(): JSX.Element {
  const { filters, setFilters } = useSessionFilters();
  const sessionMode = useMemo(() => {
    return (
      SESSION_MODE_OPTIONS.find((o) => o.key === filters.mode)?.value ?? {
        mode: null,
        onDemandControllerKind: null,
      }
    );
  }, [filters.mode]);

  return (
    <div>
      <div className='flex items-center gap-4'>
        <div className='w-50'>
          <OrganizationSelect
            className='w-full'
            orgId={filters.orgId ?? null}
            onChange={(org) =>
              setFilters({
                ...filters,
                orgId: org?.id,
              })
            }
            isClearable
          />
        </div>
        <SessionModeFilter
          value={sessionMode}
          onChange={(_, key) => {
            setFilters({
              ...filters,
              mode: key,
            });
          }}
        />
        <div className='flex flex-row items-center'>
          <input
            type='checkbox'
            className='checkbox-dark'
            checked={!(filters.invalid ?? false)}
            onChange={(e) =>
              setFilters({
                ...filters,
                invalid: !e.target.checked ? true : undefined,
              })
            }
          />
          <p className='ml-2 text-sms'>Valid Session Only</p>
        </div>
      </div>
    </div>
  );
}

function AllSessions(): JSX.Element | null {
  const { filters } = useSessionFilters();
  const searchParams = useMemo(() => {
    const sessionMode = SESSION_MODE_OPTIONS.find((o) => o.key === filters.mode)
      ?.value ?? {
      mode: null,
      onDemandControllerKind: null,
    };
    return {
      orgId: filters.orgId,
      ...sessionMode,
    };
  }, [filters.mode, filters.orgId]);
  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <div className='mb-8 flex items-center justify-between'>
        <SessionFilterControl />
        <Link to='/admin/toolkit/sessions/inspector'>
          Game Session Inspector
        </Link>
      </div>
      <SessionTrackHistory
        searchParams={searchParams}
        config={{
          organizer: true,
          mode: true,
          org: true,
          actionSheet: {
            showEdit: true,
            showCopyStreamId: true,
            showInspect: true,
          },
          controller: true,
          adminLinks: true,
          localTime: true,
        }}
        useDefaultFilters={!filters.invalid}
      />
    </div>
  );
}

function AllTemplates(): JSX.Element | null {
  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <MessageTemplateList />
    </div>
  );
}

function AllCloudHostings(): JSX.Element | null {
  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <CloudHostingList />
    </div>
  );
}

function AdminToolkit(): JSX.Element | null {
  useTitle(makeTitle('Toolkit'));
  return (
    <AdminView notificationCenter>
      <Wrapper>
        <Routes>
          <Route index element={null} />
          <Route path={`team-relay-settings`} element={<TeamRelaySettings />} />
          <Route path={`sessions/inspector`} element={<SessionInspector />} />
          <Route path={`sessions`} element={<AllSessions />} />
          <Route path={`message-templates`} element={<AllTemplates />} />
          <Route path={`message-campaigns`}>
            <Route index element={<Navigate to={'list'} replace />} />
            <Route path='list' element={<MessageCampaignList />} />
            <Route path=':id' element={<MessageCampaignEditor />} />
            <Route
              path=':id/messages'
              element={<MessageCampaignMessageList />}
            />
          </Route>
          <Route
            path='global-pairing-rounds'
            element={
              <Navigate to='/admin/programs/global-pairing-rounds' replace />
            }
          />
          <Route
            path='global-promotions'
            element={
              <Navigate to='/admin/programs/global-promotions' replace />
            }
          />
          <Route path={`cloud-hosting`} element={<AllCloudHostings />} />
          <Route path={`dmg`} element={<DmgTemplates />} />
          <Route path={`slack`} element={<SlackSocketModeConnections />} />
          <Route path='events'>
            <Route index element={<Navigate to={'list'} replace />} />
            <Route path='list' element={<AdminEventList />} />
            <Route path='create' element={<LiveEventCreate />} />
            <Route
              path=':eventId/edit'
              element={
                <EventManage
                  withAdminHeader={false}
                  canceledMessage=''
                  home='/admin/toolkit/events/list'
                  className=''
                />
              }
            />
            <Route path=':eventId/intake/:formId' element={<IntakeSummary />} />
          </Route>
          <Route path={`media-upload`} element={<MediaUpload />} />
          <Route path={`drawings`}>
            <Route index element={<DrawingList />} />
          </Route>
          <Route path={`ai-chat/*`} Component={() => <AdminAIChat />} />
          <Route
            path={`shared-media/*`}
            Component={() => <AdminSharedAsset />}
          />
          <Route path='live-event-settings' element={<LiveEventSettings />} />
          <Route path='tags' element={<AdminTagList />} />
          <Route path='markdown' element={<AdminMarkdownPreview />} />
          <Route path='shared-scripts' element={<AdminSharedScripts />} />
          <Route path='voice-over-locales' element={<VoiceOverLocales />} />
          <Route
            path='logo-color-extraction'
            element={<LogoColorExtraction />}
          />
        </Routes>
      </Wrapper>
    </AdminView>
  );
}

// eslint-disable-next-line import/no-default-export
export default AdminToolkit;
