import { Link } from '@remix-run/react';
import { type ConnectDragSource } from 'react-dnd';
import { Controller, useController, useFieldArray } from 'react-hook-form';
import useSWR from 'swr';

import {
  EnumsMediaScene,
  EnumsSharedAssetPurpose,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import { type GamePackShowcaseCard } from '../../../types/game';
import { fromMediaDataDTO, fromMediaDTO } from '../../../utils/api-dto';
import { uuidv4 } from '../../../utils/common';
import { DragDropList } from '../../common/DragDrop';
import { FAQGroupsEditor } from '../../FAQ/FAQGroupList';
import { ArrowRightIcon } from '../../icons/Arrows';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { MenuIcon } from '../../icons/MenuIcon';
import { NewWindowIcon } from '../../icons/NewWindowIcon';
import { MediaEditor } from '../../MediaUploader/MediaEditor';
import { MUSIC_PLAYER_DEFAULT_LIST_ID } from '../../MusicPlayer/types';
import {
  SharedAssetCover,
  useOpenShareAssetPickerModal,
} from '../../SharedAsset';
import { SwitcherControlled } from '../../Switcher';
import { LobbyBackgroundField } from './Shared';
import {
  type GamePackEditorControlledProps,
  type GamePackEditorFieldProps,
  type GamePackEditorFormData,
} from './types';
import { GamePackUtils } from './utils';

function FAQGroupsField(props: GamePackEditorFieldProps) {
  const { fields, move, append, remove } = useFieldArray<
    GamePackEditorFormData,
    'faqGroups',
    'key'
  >({
    control: props.control,
    name: 'faqGroups',
    keyName: 'key',
  });

  return (
    <FAQGroupsEditor
      groups={fields}
      onAdd={append}
      onMove={move}
      title={'FAQ Groups (Logged In)'}
      onDelete={remove}
    />
  );
}

function AnonymousFAQGroupsField(props: GamePackEditorFieldProps) {
  const { fields, move, append, remove } = useFieldArray<
    GamePackEditorFormData,
    'anonymousFAQGroups',
    'key'
  >({
    control: props.control,
    name: 'anonymousFAQGroups',
    keyName: 'key',
  });

  return (
    <FAQGroupsEditor
      groups={fields}
      onAdd={append}
      onMove={move}
      title={'FAQ Groups (Logged Out)'}
      onDelete={remove}
    />
  );
}

function JoyCaptureBackgroundField(props: GamePackEditorFieldProps) {
  return (
    <label htmlFor='marketingSettings.joyCaptureBackground' className='w-full'>
      <Controller<
        GamePackEditorFormData,
        'marketingSettings.joyCaptureBackground'
      >
        name='marketingSettings.joyCaptureBackground'
        control={props.control}
        render={({ field }) => (
          <MediaEditor
            video={false}
            scene={EnumsMediaScene.MediaSceneGamePackMarketingMaterials}
            title='Joy Capture Background'
            media={field.value?.media ? fromMediaDTO(field.value.media) : null}
            mediaData={
              field.value?.data ? fromMediaDataDTO(field.value.data) : null
            }
            onChange={(data, media) => {
              field.onChange(
                media === null
                  ? null
                  : {
                      data,
                      media,
                    }
              );
            }}
            sharedAssetPurposes={[
              EnumsSharedAssetPurpose.SharedAssetPurposeGeneral,
            ]}
            onSharedAssetSelected={(item) => {
              field.onChange({
                data: {
                  id: item.mediaId,
                },
                media: item.media,
              });
            }}
          />
        )}
      />
    </label>
  );
}

function SharableMarketingMaterialsField(props: GamePackEditorFieldProps) {
  return (
    <label
      htmlFor='marketingSettings.sharableMarketingMaterials'
      className='w-full'
    >
      <Controller<
        GamePackEditorFormData,
        'marketingSettings.sharableMarketingMaterials'
      >
        name='marketingSettings.sharableMarketingMaterials'
        control={props.control}
        render={({ field }) => (
          <MediaEditor
            video
            scene={EnumsMediaScene.MediaSceneGamePackMarketingMaterials}
            title='Sharable Marketing Materials'
            media={field.value?.media ? fromMediaDTO(field.value.media) : null}
            mediaData={
              field.value?.data ? fromMediaDataDTO(field.value.data) : null
            }
            onChange={(data, media) => {
              field.onChange(
                media === null
                  ? null
                  : {
                      data,
                      media,
                    }
              );
            }}
            sharedAssetPurposes={[
              EnumsSharedAssetPurpose.SharedAssetPurposeGeneral,
            ]}
            onSharedAssetSelected={(item) => {
              field.onChange({
                data: {
                  id: item.mediaId,
                },
                media: item.media,
              });
            }}
          />
        )}
      />
    </label>
  );
}

function GameTrailerField(props: GamePackEditorFieldProps) {
  return (
    <label htmlFor='marketingSettings.gameTrailer' className='w-full'>
      <Controller<GamePackEditorFormData, 'marketingSettings.gameTrailer'>
        name='marketingSettings.gameTrailer'
        control={props.control}
        render={({ field }) => (
          <MediaEditor
            video
            scene={EnumsMediaScene.MediaSceneGamePackMarketingMaterials}
            title='Game Trailer'
            media={field.value?.media ? fromMediaDTO(field.value.media) : null}
            mediaData={
              field.value?.data ? fromMediaDataDTO(field.value.data) : null
            }
            onChange={(data, media) => {
              field.onChange(
                media === null
                  ? null
                  : {
                      data,
                      media,
                    }
              );
            }}
            sharedAssetPurposes={[
              EnumsSharedAssetPurpose.SharedAssetPurposeGeneral,
            ]}
            onSharedAssetSelected={(item) => {
              field.onChange({
                data: {
                  id: item.mediaId,
                },
                media: item.media,
              });
            }}
          />
        )}
      />
    </label>
  );
}

function GameBackgroundField(props: GamePackEditorFieldProps) {
  return (
    <label htmlFor='marketingSettings.gameBackground' className='w-full'>
      <Controller<GamePackEditorFormData, 'marketingSettings.gameBackground'>
        name='marketingSettings.gameBackground'
        control={props.control}
        render={({ field: { value: asset, onChange } }) => (
          <MediaEditor
            video
            scene={EnumsMediaScene.MediaSceneGamePackMarketingMaterials}
            title='Stage Background'
            media={fromMediaDTO(asset?.media)}
            mediaData={fromMediaDataDTO(asset?.data)}
            volumeSelectable
            onChange={(data, media) => {
              onChange(
                media
                  ? {
                      media,
                      data,
                    }
                  : null
              );
            }}
            sharedAssetPurposes={[
              EnumsSharedAssetPurpose.SharedAssetPurposeGeneral,
            ]}
            onSharedAssetSelected={(item) => {
              onChange({
                data: {
                  id: item.mediaId,
                },
                media: item.media,
              });
            }}
          />
        )}
      />
    </label>
  );
}

function CalendarInviteMessageField(props: GamePackEditorFieldProps) {
  return (
    <label>
      <div className='mb-2 font-bold'>Calendar Invite</div>
      <Controller<
        GamePackEditorFormData,
        'marketingSettings.calendarInviteMessage'
      >
        name='marketingSettings.calendarInviteMessage'
        control={props.control}
        rules={{ maxLength: 500 }}
        render={({ field, fieldState }) => (
          <textarea
            value={field.value || ''}
            onChange={field.onChange}
            name={field.name}
            className={`${
              fieldState.error ? 'field-error mb-0' : 'field mb-0'
            } h-30 py-2 scrollbar`}
            maxLength={500}
            placeholder='Max 500 characters'
          />
        )}
      />
    </label>
  );
}

function PlaylistField(props: GamePackEditorFieldProps) {
  const openShareAssetPickerModal = useOpenShareAssetPickerModal();
  const {
    field: { value, onChange },
  } = useController<
    GamePackEditorFormData,
    'marketingSettings.musicPlaylistId'
  >({
    name: 'marketingSettings.musicPlaylistId',
    control: props.control,
  });
  const playlistId = value || MUSIC_PLAYER_DEFAULT_LIST_ID;

  const { data: asset } = useSWR(
    !playlistId ? null : ['/media/shared/', playlistId],
    async ([_, id]) => {
      const sharedAsset = await apiService.media.getSharedAsset(id);
      return sharedAsset.data.sharedAsset;
    }
  );

  const handleAdd = () => {
    openShareAssetPickerModal({
      purposes: [EnumsSharedAssetPurpose.SharedAssetPurposeMusicPlaylist],
      onSelected: (item) => {
        onChange(item.id);
      },
    });
  };

  return (
    <div>
      <div className='mb-2 flex justify-between items-center'>
        <p className='font-bold'>Playlist Id</p>
        {playlistId && (
          <button
            type='button'
            className='btn text-sms text-icon-gray hover:text-primary hover:underline transition-all'
            onClick={handleAdd}
          >
            Edit
          </button>
        )}
      </div>
      {asset ? (
        <div className='w-full flex items-center gap-2'>
          <div className='w-full bg-layer-002 rounded border border-secondary px-4 py-2 flex items-center justify-between'>
            <div className='text-white font-bold'>{asset.label}</div>
            <Link
              to={`/admin/toolkit/shared-media?id=${playlistId}`}
              target='_blank'
            >
              <div className='transform hover:scale-110 transition-transform'>
                <NewWindowIcon />
              </div>
            </Link>
          </div>
        </div>
      ) : (
        <button
          type='button'
          className='mt-2 btn text-sms text-secondary'
          onClick={handleAdd}
        >
          + Add Playlist
        </button>
      )}
    </div>
  );
}

function ShowcaseCardEditor(props: {
  card: GamePackShowcaseCard;
  drag: ConnectDragSource;
  onDelete: () => void;
}) {
  const { card, drag, onDelete } = props;

  return (
    <div className='w-full flex items-center gap-2 relative'>
      <div className='flex-1 bg-secondary rounded-xl p-2 flex items-center gap-3.5'>
        <button type='button' ref={drag} className='btn cursor-move'>
          <MenuIcon />
        </button>
        <SharedAssetCover
          media={fromMediaDTO(card.media)}
          className='w-22.5 h-12.5'
          cliclable
        />
        <div className='flex-1'>
          <div className='text-2xs font-bold'>{card.primaryText}</div>
          <div className={`mt-2 text-3xs font-normal text-icon-gray`}>
            {card.secondaryText}
          </div>
        </div>
        <div className='flex flex-col h-full justify-between'>
          <div className='w-6 h-6 flex items-center justify-center'>
            <a
              href={`/admin/toolkit/shared-media?id=${card.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <ArrowRightIcon />
            </a>
          </div>
          <button
            type='button'
            onClick={onDelete}
            className='w-6 h-6 rounded-lg border border-secondary btn flex justify-center items-center text-red-002 bg-black'
          >
            <DeleteIcon className='w-3 h-3 fill-current' />
          </button>
        </div>
      </div>
    </div>
  );
}

function ShowcaseCardsEditor(props: {
  cards: GamePackShowcaseCard[];
  onMove: (from: number, to: number) => void;
  onDelete: (index: number) => void;
  onAdd: (group: GamePackShowcaseCard) => void;
}) {
  const { cards, onMove } = props;

  const openShareAssetPickerModal = useOpenShareAssetPickerModal();

  const handleAdd = () => {
    openShareAssetPickerModal({
      purposes: [
        EnumsSharedAssetPurpose.SharedAssetPurposeGamePackShowcaseCard,
      ],
      onSelected: (item) => {
        props.onAdd(GamePackUtils.ToShowcaseCard(item));
      },
    });
  };

  return (
    <div className='w-full'>
      <div className='w-full flex flex-col gap-2'>
        <DragDropList
          type={`showcase-cards-${uuidv4()}`}
          items={cards}
          onMove={onMove}
          render={({ item, index, drag, ref, style }) => (
            <div className={`w-full`} ref={ref} style={style}>
              <ShowcaseCardEditor
                card={item}
                drag={drag}
                onDelete={() => props.onDelete(index)}
              />
            </div>
          )}
        />
      </div>
      <button
        type='button'
        className='mt-2 btn text-sms text-secondary'
        onClick={handleAdd}
      >
        + Add Showcase Card
      </button>
    </div>
  );
}

function ShowcaseCardsFields(props: GamePackEditorFieldProps) {
  const { fields, move, append, remove } = useFieldArray<
    GamePackEditorFormData,
    'showcaseCards',
    'key'
  >({
    control: props.control,
    name: 'showcaseCards',
    keyName: 'key',
  });

  return (
    <div>
      <div className='mb-2 flex justify-between items-center'>
        <p className='font-bold'>Showcase Cards</p>
        <Controller<
          GamePackEditorFormData,
          'marketingSettings.useShowcaseCards'
        >
          name='marketingSettings.useShowcaseCards'
          control={props.control}
          render={({ field }) => (
            <SwitcherControlled
              name={field.name}
              checked={field.value || false}
              onChange={field.onChange}
            />
          )}
        />
      </div>
      <ShowcaseCardsEditor
        cards={fields}
        onAdd={append}
        onMove={move}
        onDelete={remove}
      />
    </div>
  );
}

export function GamePackMarketingSettingsEditor(
  props: GamePackEditorControlledProps
) {
  return (
    <div className='flex flex-col gap-4'>
      <FAQGroupsField {...props} />
      <AnonymousFAQGroupsField {...props} />
      <JoyCaptureBackgroundField {...props} />
      <SharableMarketingMaterialsField {...props} />
      <GameTrailerField {...props} />
      <LobbyBackgroundField {...props} />
      <GameBackgroundField {...props} />
      <CalendarInviteMessageField {...props} />
      <PlaylistField {...props} />
      <ShowcaseCardsFields {...props} />
    </div>
  );
}
