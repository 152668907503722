export function VideoBlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
    >
      <path
        d='M2.52632 2.28577C1.12676 2.28577 0 3.37803 0 4.73475V11.2654C0 12.6221 1.12676 13.7143 2.52632 13.7143H13.4737C14.8732 13.7143 16 12.6221 16 11.2654V4.73475C16 3.37803 14.8732 2.28577 13.4737 2.28577H2.52632ZM6.10526 5.34699L11.1579 8.00005L6.10526 10.6531V5.34699Z'
        fill='white'
      />
    </svg>
  );
}
