import { Link, Navigate } from '@remix-run/react';
import { type ReactNode } from 'react';

import { type DtoGamePack } from '@lp-lib/api-service-client/public';

import {
  getFeatureQueryParam,
  useFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { BingInstall } from '../../tracking/bing';
import { fromDTOGamePack } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { GamePackUtils } from '../Game/GamePack/utils';
import { LunaParkLogo } from '../icons/LogoIcon';
import { useMyOrganizationFeatureChecker } from '../Organization';

function GamePackNotPurchasableNotice() {
  return (
    <div className='w-2/3 h-100 bg-modal rounded-2.5xl px-9 py-7.5 gap-5 flex flex-col items-center justify-center'>
      <div className='flex flex-col items-center'>
        <div className='text-white'>
          This Game Pack currently is not available for One Time Purchase.
        </div>
        <Link className='text-primary underline' to='/home'>
          Back to Home
        </Link>
      </div>
    </div>
  );
}

function ProgressBar(props: { percentage: number }) {
  return (
    <div className='w-full h-2 rounded-lg bg-lp-gray-003'>
      <div
        style={{
          width: `${props.percentage.toFixed(2)}%`,
        }}
        className='h-full rounded-lg bg-white'
      ></div>
    </div>
  );
}

const bg = getStaticAssetPath('images/gamepack-covers-bg.png');

export function OTPCheckoutLayout({
  pack,
  progress,
  checkAccessible = false,
  repeatPurchaseEnabled = getFeatureQueryParam('game-pack-repeat-purchase'),
  containerClassName,
  widthClassName,
  background = true,
  children,
}: {
  pack: DtoGamePack;
  progress: number;
  checkAccessible?: boolean;
  repeatPurchaseEnabled?: boolean;
  widthClassName?: string;
  containerClassName?: string;
  background?: boolean;
  children?: ReactNode;
}) {
  const otpCheckout = useFeatureQueryParam('otp-checkout');
  let purchasable = true;
  if (otpCheckout) {
    const priceTable = GamePackUtils.ActivePrices(pack);
    purchasable = priceTable.length > 0;
  }

  const gamePack = fromDTOGamePack(pack);
  const featureChecker = useMyOrganizationFeatureChecker();

  if (
    otpCheckout &&
    checkAccessible &&
    featureChecker.canAccessGamePack(gamePack) &&
    !repeatPurchaseEnabled
  ) {
    if (pack.isUGCTemplate) {
      return (
        <Navigate to={`/custom-games/create?template-id=${pack.id}`} replace />
      );
    }

    return (
      <Navigate
        to={`/events/create?game-pack-id=${
          pack.id
        }&back-to=${encodeURIComponent('/events')}`}
        replace
      />
    );
  }

  return (
    <div className='w-full h-full flex flex-col text-white overflow-auto scrollbar'>
      {background && (
        <div className='absolute inset-0 -z-1'>
          <img
            src={bg}
            alt=''
            className='w-full h-full object-cover opacity-20'
          />
        </div>
      )}

      <header className='relative flex-none w-full h-20 flex justify-center items-center px-60'>
        <div className='w-full max-w-140 2xl:max-w-160'>
          <ProgressBar percentage={progress} />
        </div>
        <div className='absolute left-5'>
          <LunaParkLogo className='w-45 h-12.5' />
        </div>
      </header>
      <main className='w-full flex-grow flex items-center justify-center'>
        <div
          className={`${
            widthClassName ?? 'w-245'
          } flex gap-10 items-center justify-center ${
            containerClassName ?? ''
          }`}
        >
          {purchasable ? children : <GamePackNotPurchasableNotice />}
        </div>
      </main>
      <BingInstall />
    </div>
  );
}
