import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useEffect, useState } from 'react';

import {
  type DtoLearnerProfileResponse,
  type DtoSingleGamePackResponse,
} from '@lp-lib/api-service-client/public';

import { useLearningAnalytics } from '../analytics/learning';
import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import { GlobalAudioDetectionProvider } from '../components/GameV2/apis/AudioDetection';
import { Overworld } from '../components/GameV2/Overworld';
import { getRandomAnimatedAvatar } from '../components/Participant/avatars';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { apiService } from '../services/api-service';
import { fromDTOGamePack, fromDTOGames } from '../utils/api-dto';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const [courses, profile] = await tokenWithRedirect(
    () =>
      Promise.all([
        apiService.learning.getUserCourses(),
        apiService.learning.getMyLearnerProfile({
          enrollmentsSummary: true,
          membershipsSummary: false,
        }),
      ]),
    action.request.url
  );

  return {
    courses: courses.data,
    profile: profile.data,
  };
}

function Loading() {
  return <div className='text-white'>Loading your courses...</div>;
}

export interface AssignedItem {
  id: string;
  name: string;
  progress: number; // percentage
  active: boolean;
  type: 'course' | 'stack';
}

interface AssignedItemsListProps {
  items: AssignedItem[];
  onItemClick?: (id: string) => void;
}

function AssignedItemsList({ items, onItemClick }: AssignedItemsListProps) {
  return (
    <div className='w-full'>
      <h2 className='text-lg font-bold mb-4'>My Courses</h2>
      {items.length > 0 ? (
        <ul className='flex flex-col gap-2'>
          {items.map((item) => (
            <li
              key={item.id}
              className='relative cursor-pointer hover:bg-lp-black-003 transition-colors p-2'
              onClick={() => onItemClick?.(item.id)}
            >
              {/* Active indicator line */}
              {item.active && (
                <div className='absolute left-0 top-0 h-full w-1 bg-red-500 rounded-r' />
              )}

              <div
                className={`pl-3 ${
                  item.active ? 'text-white font-semibold' : 'text-gray-300'
                }`}
              >
                <div className='flex flex-col items-start justify-between'>
                  <span className='truncate'>{item.name}</span>
                  <span className='text-xs text-gray-400'>
                    {item.progress}% complete
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className='text-gray-400 text-sm'>No courses available</p>
      )}
    </div>
  );
}

function EmptyState() {
  return (
    <div className='flex items-center justify-center h-full'>
      <p className='text-white text-xl'>You have no courses assigned</p>
    </div>
  );
}

function Internal(props: {
  courses: DtoSingleGamePackResponse[];
  profile: DtoLearnerProfileResponse;
}) {
  const { courses, profile } = props;

  const [assignedItems, setAssignedItems] = useState<AssignedItem[]>([]);
  const [activeCourseId, setActiveCourseId] = useState<string | null>(null);

  const navigate = useNavigate();
  const analytics = useLearningAnalytics();

  const { call: handlePlayGame } = useLiveAsyncCall(
    async (gamePackId: string) => {
      const packNeedsProgress = courses?.find(
        (c) => c.gamePack.id === gamePackId && !c.progression
      );

      if (packNeedsProgress) {
        await apiService.progression.createMyProgression(gamePackId);
      }

      navigate(`/game-packs/${gamePackId}/overworld`);
    }
  );

  useEffect(() => {
    // Transform courses into assigned items for the sidebar
    const items = courses.map((courseResp, idx) => {
      const { gamePack: gp, games: gs, progression: prog } = courseResp;
      let completedCount = 0;
      if (prog && prog.progress) {
        completedCount = Object.values(prog.progress).filter(
          (p) => p.completedAt
        ).length;
      }
      const totalGames = gs?.length || 0;
      const progress =
        totalGames > 0 ? Math.floor((completedCount / totalGames) * 100) : 0;

      return {
        id: gp.id,
        name: gp.name,
        progress: progress,
        active: idx === 0,
        type: 'course' as const,
      };
    });

    setAssignedItems(items);
    if (items.length > 0) {
      setActiveCourseId(items[0].id);
    }
  }, [courses]);

  if (!courses) {
    return <Loading />;
  }

  const activeCourse = courses.find((c) => c.gamePack.id === activeCourseId);
  let overworldContent = null;
  if (activeCourse) {
    const { gamePack: gp, games: gs, progression: prog } = activeCourse;
    const pack = fromDTOGamePack(gp);
    const gms = fromDTOGames(gs || []);
    overworldContent = (
      <Overworld
        pack={pack}
        games={gms}
        progression={prog}
        onClickGame={() => {
          analytics.trackCourseStarted({
            packId: pack.id,
            packName: pack.name,
            groupId: gp.id,
            groupName: gp.name,
          });
          handlePlayGame(gp.id);
        }}
        displayOptions={{ showLpLogo: false }}
      />
    );
  }

  const handleItemClick = (id: string) => {
    const selected = assignedItems.find((item) => item.id === id);
    if (!selected) return;
    setAssignedItems((prev) =>
      prev.map((item) => ({ ...item, active: item.id === id }))
    );
    setActiveCourseId(id);
    analytics.trackCourseSelected({
      packId: id,
      packName: selected.name,
    });
  };

  return (
    <div className='w-full h-full flex text-white'>
      <div className='w-75 bg-main-layer text-white flex flex-col'>
        <div className='p-2'>
          <LearnerProfileCard profile={profile} />
        </div>
        <div className='flex-1 min-h-0 p-4 pr-0'>
          <AssignedItemsList
            items={assignedItems}
            onItemClick={handleItemClick}
          />
        </div>
      </div>
      <div className='flex-1 p-8 overflow-auto'>
        {courses.length > 0 ? (
          overworldContent ? (
            overworldContent
          ) : (
            <div className='text-white'>Select a course from the sidebar</div>
          )
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
}

function LearnerProfileCard(props: { profile: DtoLearnerProfileResponse }) {
  return (
    <div className='w-full bg-black rounded-lg'>
      <div className='p-4 flex-1 flex items-center gap-2'>
        <div className='flex-none rounded-full w-12.5 h-12.5 bg-secondary overflow-hidden'>
          <video
            src={getRandomAnimatedAvatar(props.profile.uid)}
            className='w-full h-full object-cover'
            muted
            autoPlay
            loop
            controls={false}
          />
        </div>
        <div className='flex-1 min-w-0'>
          <div className='w-full font-bold text-white text-lg truncate'>
            {props.profile.name}
          </div>
          <div className='w-full text-icon-gray text-xs truncate'>
            {props.profile.email}
          </div>
        </div>
      </div>
      {props.profile.enrollmentsSummary && (
        <div className='w-full flex items-center justify-center gap-5 py-5'>
          <div className='w-16 flex flex-col items-center gap-1 text-white text-center'>
            <div className='font-bold text-2xl'>
              {props.profile.enrollmentsSummary.activeCount}
            </div>
            <div className='text-[11px]'>Active</div>
          </div>
          <div className='w-16 flex flex-col items-center gap-1 text-white text-center'>
            <div className='font-bold text-2xl'>
              {props.profile.enrollmentsSummary.completedCount}
            </div>
            <div className='text-[11px]'>Completed</div>
          </div>
          <div className='w-16 flex flex-col items-center gap-1 text-white text-center'>
            <div className='font-bold text-2xl'>
              {props.profile.enrollmentsSummary.totalCount}
            </div>
            <div className='text-[11px]'>All Courses</div>
          </div>
        </div>
      )}
    </div>
  );
}

export function Component() {
  const { courses, profile } = useLoaderData<typeof clientLoader>();
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess allowGuests />,
    <GlobalAudioDetectionProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Internal courses={courses} profile={profile} />
    </ProvidersList>
  );
}
