import { Link } from '@remix-run/react';

import { ArrowRightIcon } from '../../../icons/Arrows';

export function PageHeader(props: { title: string; path: string }) {
  return (
    <Link
      to={props.path}
      className='text-icon-gray flex items-center gap-1 group text-white/80 hover:text-white transition-colors'
    >
      <h1 className='text-sms font-bold tracking-wide'>{props.title}</h1>
      <ArrowRightIcon className='w-3 h-3 fill-current transition-transform transform group-hover:translate-x-1' />
    </Link>
  );
}
