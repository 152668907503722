import { Button, Container, Hr, Img, Text } from '@react-email/components';
import { EmailTemplateLayout } from './components/Layout';
import { btnPrimary } from './components/styles';
import { EmailTemplateBase } from './types';
import { LPLogo } from './components';

function Content(props: EmailTemplateEnrollmentProps): JSX.Element {
  return (
    <EmailTemplateLayout
      style={{
        maxWidth: '640px',
      }}
    >
      <Container>
        <LPLogo
          style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 4 }}
        />

        <Text>👋 Hi {props.firstName},</Text>

        <Text>
          You have been enrolled in {props.courseName} by{' '}
          {props.assignerFirstName} {props.assignerLastName} from{' '}
          {props.orgName}. Click the button below to get started.
        </Text>

        <Img
          src={
            props.gamePackCoverUrl ??
            'https://assets.golunapark.com/static/images/email/program-enrolled.png'
          }
          alt='Program Enrolled'
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '12px',
            margin: '24px 0',
          }}
        />

        <Container style={{ textAlign: 'center' }}>
          <Button
            href={props.startLink}
            style={{
              ...btnPrimary,
              backgroundColor: '#ff0033',
              color: '#fff',
              padding: '12px 20px',
              borderRadius: '8px',
              textAlign: 'center' as const,
              width: '70%',
              display: 'inline-block',
            }}
          >
            Start
          </Button>
        </Container>

        <Hr style={{ margin: '40px 0' }} />

        <Text
          style={{
            textAlign: 'center',
            color: '#666',
            fontSize: '14px',
          }}
        >
          Luna Park helps boost team culture with Events, Icebreakers, DEI
          Celebrations and more
        </Text>

        <Text
          style={{
            textAlign: 'center',
            color: '#999',
            fontSize: '12px',
          }}
        >
          All rights reserved.
        </Text>

        <Text
          style={{
            textAlign: 'center',
            fontSize: '14px',
          }}
        >
          <a
            href='https://lunapark.com'
            style={{ color: '#000', textDecoration: 'none' }}
          >
            LunaPark.com
          </a>
        </Text>
      </Container>
    </EmailTemplateLayout>
  );
}

export interface EmailTemplateEnrollmentProps {
  firstName: string;
  assignerFirstName: string;
  assignerLastName: string;
  orgName: string;
  startLink: string;
  courseName: string;
  gamePackCoverUrl?: string;
}

export const EmailTemplateEnrollment: EmailTemplateBase<EmailTemplateEnrollmentProps> =
  {
    Key: 'enrollment',
    Name: 'Course Enrollment',
    Description: 'Notification for course enrollment',
    Subject: (props) =>
      `You were enrolled in a learning course by ${props.assignerFirstName} from ${props.orgName}`,
    Content,
    PreviewProps: {
      firstName: 'there',
      orgName: 'Acme Corp',
      startLink: 'https://lunapark.com/start',
      assignerFirstName: 'John',
      assignerLastName: 'Doe',
      courseName: 'My Course',
      gamePackCoverUrl:
        'https://assets.golunapark.com/static/images/email/program-enrolled.png',
    },
  };
