/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum ClientAspectRatio {
  ASPECTRATIO_WIDE = 'Wide',
  ASPECTRATIO_TALL = 'Tall',
  ASPECTRATIO_SQUARE = 'Square',
}

export interface ClientButton {
  additionalProperties?: Record<string, any>;
  href: string;
  id: string;
  style: ClientEmailButtonStyle;
  text: string;
}

export interface ClientChannelDto {
  additionalProperties?: Record<string, any>;
  id: string;
  name: string;
  type: ClientChannelType;
}

export enum ClientChannelQueryType {
  CHANNELQUERYTYPE_BY_IDS = 'byIds',
  CHANNELQUERYTYPE_ALL = 'all',
  CHANNELQUERYTYPE_BY_USER_ID = 'byUserId',
}

export enum ClientChannelType {
  CHANNELTYPE_PUBLIC = 'public',
  CHANNELTYPE_PRIVATE = 'private',
  CHANNELTYPE_SINGLE = 'single',
  CHANNELTYPE_GROUP = 'group',
}

export enum ClientChatTemplateName {
  CHATTEMPLATENAME_GENERAL = 'general',
  CHATTEMPLATENAME_MARKDOWN = 'markdown',
  CHATTEMPLATENAME_PROMOTION_GAME_RECAP = 'promotion-game-recap',
  CHATTEMPLATENAME_GAME_PACK_PROMOTION = 'game-pack-promotion',
  CHATTEMPLATENAME_PROMOTION_ANALYTICS_DIGEST = 'promotion-analytics-digest',
  CHATTEMPLATENAME_CELEBRATION_BIRTHDAY = 'celebration-birthday',
  CHATTEMPLATENAME_CELEBRATION_ANNIVERSARY = 'celebration-anniversary',
  CHATTEMPLATENAME_ONBOARDING_QUICK_GUIDE = 'onboarding-quick-guide',
  CHATTEMPLATENAME_LP_ENGAGED = 'lp-engaged',
}

export enum ClientChunkingMethod {
  CHUNKINGMETHOD_TEXT_CHUNKER = 'TextChunker',
  CHUNKINGMETHOD_SEMANTIC_CHUNKER = 'SemanticChunker',
  CHUNKINGMETHOD_LLM_CHUNKER = 'LLMChunker',
}

export interface ClientDocLoader {
  additionalProperties?: Record<string, any>;
  name: ClientDocLoaderName;
  promptable: boolean;
}

export interface ClientDocLoaderConfigItem {
  additionalProperties?: Record<string, any>;
  loaders: ClientDocLoader[];
  mimeTypeOrExt: string;
}

export enum ClientDocLoaderName {
  DOCLOADERNAME_PY_PDF_LOADER = 'PyPDFLoader',
  DOCLOADERNAME_TEXT_LOADER = 'TextLoader',
  DOCLOADERNAME_UNSTRUCTURED_LOADER = 'UnstructuredLoader',
  DOCLOADERNAME_CSV_LOADER = 'CSVLoader',
  DOCLOADERNAME_BSHTML_LOADER = 'BSHTMLLoader',
  DOCLOADERNAME_JSON_LOADER = 'JSONLoader',
  DOCLOADERNAME_AZURE_AI_DOCUMENT_INTELLIGENCE_LOADER = 'AzureAIDocumentIntelligenceLoader',
  DOCLOADERNAME_GEMINI_IMAGE_AI_LOADER = 'GeminiImageAILoader',
  DOCLOADERNAME_GEMINI_AUDIO_AI_LOADER = 'GeminiAudioAILoader',
  DOCLOADERNAME_GEMINI_VIDEO_I_LOADER = 'GeminiVideoILoader',
}

export interface ClientDocument {
  additionalProperties?: Record<string, any>;
  id: GithubComNarviiLunaparkApiServicePkgLlmClientNullableString;
  metadata: Record<string, any>;
  page_content: string;
  type: string;
}

export enum ClientEmailButtonStyle {
  EMAILBUTTONSTYLE_PRIMARY = 'primary',
  EMAILBUTTONSTYLE_DANGER = 'danger',
  EMAILBUTTONSTYLE_DEFAULT = 'default',
}

export interface ClientGeneratedImage {
  additionalProperties?: Record<string, any>;
  b64: string;
}

export interface ClientHitDocument {
  additionalProperties?: Record<string, any>;
  id: GithubComNarviiLunaparkApiServicePkgLlmClientNullableString;
  metadata: Record<string, any>;
  page_content: string;
  score: number;
  type: string;
}

export enum ClientImageGenProvider {
  IMAGEGENPROVIDER_OPEN_AI = 'OpenAI',
  IMAGEGENPROVIDER_GOOGLE = 'Google',
}

export interface ClientImageGenProviderItem {
  additionalProperties?: Record<string, any>;
  models: string[];
  name: ClientImageGenProvider;
}

export interface ClientInstallationDto {
  additionalProperties?: Record<string, any>;
  createdAt: string;
  id: string;
  scopes: string[];
  teamId: string;
}

export interface ClientIntakeFormSummaryItem {
  additionalProperties?: Record<string, any>;
  label: string;
  value: string;
}

export enum ClientLLMProvider {
  LLMPROVIDER_OPEN_AI = 'OpenAI',
  LLMPROVIDER_GOOGLE = 'Google',
  LLMPROVIDER_ANTHROPIC = 'Anthropic',
}

export interface ClientLLMProviderItem {
  additionalProperties?: Record<string, any>;
  models: string[];
  name: ClientLLMProvider;
}

export interface ClientMessageButton {
  action: ClientMessageButtonAction;
  actionId: string;
  additionalProperties?: Record<string, any>;
  id: string;
  style: ClientMessageButtonStyle;
  text: string;
  url: string;
  value: string;
}

export enum ClientMessageButtonAction {
  MESSAGEBUTTONACTION_OPEN_URL = 'open_url',
  MESSAGEBUTTONACTION_GAME_PACK_PROMO_BUTTON_SCHEDULE_GAME = 'game_pack_promo_button_schedule_game',
  MESSAGEBUTTONACTION_PROGRAM_GROUP_GAME_COMPLETE = 'program_group_game_complete',
  MESSAGEBUTTONACTION_ADD_DATES = 'add_dates',
  MESSAGEBUTTONACTION_PROMOTION_PROGRAM_NOT_INTERESTED = 'promotion_program_not_interested',
}

export enum ClientMessageButtonStyle {
  MESSAGEBUTTONSTYLE_PRIMARY = 'primary',
  MESSAGEBUTTONSTYLE_DANGER = 'danger',
  MESSAGEBUTTONSTYLE_DEFAULT = 'default',
}

export interface ClientMessageMedia {
  additionalProperties?: Record<string, any>;
  mediaUrl: string;
  thumbnailUrl: string;
  title: string;
  type: ClientMessageMediaType;
}

export enum ClientMessageMediaType {
  MESSAGEMEDIATYPE_IMAGE = 'image',
  MESSAGEMEDIATYPE_VIDEO = 'video',
}

export interface ClientMessageMetadata {
  EventPayload: Record<string, any>;
  EventType: string;
  additionalProperties?: Record<string, any>;
}

export enum ClientMessageType {
  MESSAGETYPE_SYSTEM = 'system',
  MESSAGETYPE_HUMAN = 'human',
  MESSAGETYPE_AI = 'ai',
  MESSAGETYPE_TOOL = 'tool',
}

export interface ClientMessageUrlButton {
  actionId: GithubComNarviiLunaparkApiServicePkgSlackClientNullableString;
  additionalProperties?: Record<string, any>;
  text: string;
  url: string;
}

export interface ClientSlackGenerateInstallUrlResponse {
  additionalProperties?: Record<string, any>;
  url: string;
}

export interface ClientTemplateResponseDto {
  additionalProperties?: Record<string, any>;
  contentType: string;
  displayName: string;
  exampleImageUrl: string;
  id: string;
}

export interface ClientTemplatesResponseDto {
  additionalProperties?: Record<string, any>;
  templates: ClientTemplateResponseDto[];
}

export interface ClientUserDto {
  additionalProperties?: Record<string, any>;
  displayName: string;
  email: string;
  fullName: string;
  icon: GithubComNarviiLunaparkApiServicePkgSlackClientNullableString;
  id: string;
  teamId: string;
  tz: GithubComNarviiLunaparkApiServicePkgSlackClientNullableString;
}

export enum ClientUserQueryType {
  USERQUERYTYPE_BY_IDS = 'byIds',
  USERQUERYTYPE_BY_KEYWORDS = 'byKeywords',
  USERQUERYTYPE_ALL = 'all',
  USERQUERYTYPE_BY_CHANNEL_ID = 'byChannelId',
  USERQUERYTYPE_BY_EMAILS = 'byEmails',
}

export interface CommonBusinessError {
  code: number;
  data: CommonRecord;
  key: string;
  msg: string;
}

export interface CommonMedia {
  createdAt: string;
  firstThumbnailUrl?: string;
  formats: CommonMediaFormat[];
  hash: string;
  id: string;
  lastThumbnailUrl?: string;
  scene?: EnumsMediaScene;
  transcodeStatus: EnumsMediaTranscodeStatus;
  type: EnumsMediaType;
  uid: string;
  updatedAt: string;
  url: string;
}

export interface CommonMediaFormat {
  height: number;
  length: number;
  size: number;
  url: string;
  version: EnumsMediaFormatVersion;
  width: number;
}

export interface CommonPaging {
  next?: any;
}

export type CommonRecord = Record<string, any>;

export interface CommonStandardUTMParams {
  campaign: string;
  medium: string;
  source: string;
}

export enum CommonUserRole {
  UserRoleUser = 0,
  UserRoleAdmin = 10,
}

export interface DtoAIChatMessage {
  body: Record<string, any>;
  createdAt: string;
  displayName: string;
  id: string;
  uid: string;
}

export interface DtoAIChatMessageListResponse {
  chatMessages: DtoAIChatMessage[];
}

export interface DtoAIChatThread {
  bizId: string;
  bizLabel: string;
  createdAt: string;
  id: string;
  messagesCount: number;
  promptTemplate: DtoPromptTemplate;
  updatedAt: string;
  vendor: string;
}

export interface DtoAIChatThreadListResponse {
  chatThreads: DtoAIChatThread[];
  paging: CommonPaging;
}

export interface DtoAIChatThreadResponse {
  chatThread: DtoAIChatThread;
}

export interface DtoAcceptOrgSubscriptionCancelOfferRequest {
  offer: EnumsOrgSubscriptionCancelOffer;
  timezone?: string | null;
}

export interface DtoActivateOTPUpsellRequest {
  priceId: string;
  productId: string;
  promoCode: EnumsOneTimePurchaseUpsellPromoCode;
  trialPeriodDays: number;
}

export interface DtoAddBlockRecordingReq {
  actions: ModelsBlockAction[];
  durationMs: number;
  mediaId: string;
  version: number;
}

export interface DtoAddCoursesToStackRequest {
  /** @minItems 1 */
  courseIds: string[];
}

export interface DtoAddEnrollmentsToGroupRequest {
  /** @minItems 1 */
  objectIds: string[];
  type: 'course' | 'stack';
}

export interface DtoAddMembersRequest {
  members: DtoNewMember[];
  removeUnmatched?: boolean;
  skipJoinExGroup?: boolean;
}

export interface DtoAddMembersToLearningGroupRequest {
  /** @minItems 1 */
  emails: string[];
}

export interface DtoAddPairingPlayerRequest {
  uid: string;
}

export interface DtoAddProgramLinkRequest {
  cadenceSettings?: ModelsProgramCadenceSettings | null;
  extensions?: object | null;
  groupSettings?: ModelsProgramGroupSettings | null;
  programId: string;
  programTargetId: string;
  programType: EnumsProgramType;
  tagSettings?: ModelsProgramTagSettings | null;
}

export interface DtoAddUnmanagedStripePriceRequest {
  amount: number;
  description: string;
  recurring?: {
    interval: StripePriceRecurringInterval;
    interval_count: number;
  };
  type: StripePriceType;
}

export interface DtoAddUnmanagedStripePriceResponse {
  price: DtoSimpleStripePrice;
}

export interface DtoApplyCloudHostingResponse {
  result: string;
}

export interface DtoAssignCourseRequest {
  courseId: string;
  emails: string[];
  groupName?: string;
}

export interface DtoAssignCourseResponse {
  assignedUsers: DtoAssignedUser[];
}

export interface DtoAssignStackRequest {
  emails: string[];
  groupName?: string;
  stackId: string;
}

export interface DtoAssignStackResponse {
  assignedUsers: DtoAssignedUser[];
}

export interface DtoAssignedGroupMember {
  addedBy: string;
  assignedOn: string;
  email: string;
  name: string;
  userId: string;
}

export interface DtoAssignedStackMember {
  addedBy: string;
  assignedOn: string;
  completed: boolean;
  email: string;
  /** "N/A", or comma-delimited if multiple */
  group: string;
  name: string;
  userId: string;
}

export interface DtoAssignedUser {
  /** true if successfully assigned */
  assigned: boolean;
  email: string;
  userId: string;
}

export interface DtoAttachGameBlockReq {
  blockId: string;
  gameId: string;
  targetPosition: number;
}

export interface DtoBasicProgram {
  active: boolean;
  basicSettings?: ModelsProgramBasicSettings | null;
  createdAt: string;
  /** For backward compatibility */
  hint?: string | null;
  id: string;
  name: string;
  preparing: boolean;
  promotionalAssets?: ModelsProgramPromotionalAssets | null;
  type: EnumsProgramType;
  updatedAt: string;
}

export interface DtoBlock {
  approximateDurationSeconds: number;
  brandId?: string;
  createdAt: string;
  fields: any;
  gameId: string;
  id: string;
  outdatedRecording: boolean;
  position: number;
  recording?: ModelsBlockRecording;
  type: EnumsBlockType;
  updatedAt: string;
  videoEffectsSettings?: ModelsVideoEffectsSettings;
}

export interface DtoBlockCountResponse {
  count: number;
}

export interface DtoBlockData {
  gamePacksCount: number;
  parentTitle: string;
}

export interface DtoBlockDataEntry {
  createdAt: string;
  data: ModelsBlockData;
  id: string;
  updatedAt: string;
}

export interface DtoBlockDoc {
  block: DtoBlock;
  brand?: DtoBrand;
  data?: DtoBlockData;
}

export interface DtoBlockListResp {
  blocks: DtoBlock[];
}

export interface DtoBlockPlayedSnapshot {
  blockId: string;
  playedAt: string;
}

export interface DtoBlockResp {
  block: DtoBlock;
}

export interface DtoBlockSearchResponse {
  blockHitsCount: number;
  blockSearchResults: DtoBlockDoc[];
  paging: CommonPaging;
}

export interface DtoBlockVote {
  createdAt: string;
  id: string;
  reason: EnumsBlockDownVoteReason;
  updatedAt: string;
  value: EnumsBlockVoteValue;
}

export interface DtoBrand {
  allBlocks?: DtoBlock[];
  blockIds?: string[];
  blockLengthSec: number;
  id: string;
  name: string;
  predefinedBlockData?: ModelsPredefinedBlockItem[];
  showcaseMedia?: CommonMedia;
  showcaseMediaData?: ModelsMediaData;
  showcaseText: string;
  tags?: DtoTag[];
}

export interface DtoBrandDescription {
  description?: string;
  id: string;
  name: string;
}

export interface DtoBrandsCountResponse {
  count: number;
}

export interface DtoBundledCelebration {
  channel: DtoChannel;
  channelId: string;
  createdAt: string;
  id: string;
  messageSettings: ModelsCelebrationMessageSettings;
  organization: DtoOrganization;
  owner: DtoOrganizer;
  programLink: DtoChannelProgramLink;
  stats: ModelsCelebrationStats;
  updatedAt: string;
}

export interface DtoCalendarRoundExtensions {
  gamePack?: DtoGamePack | null;
}

export interface DtoCelebration {
  channelId: string;
  createdAt: string;
  id: string;
  messageSettings: ModelsCelebrationMessageSettings;
  stats: ModelsCelebrationStats;
  updatedAt: string;
}

export interface DtoCelebrationParticipant {
  birthday: ModelsBirthday;
  celebrateBirthday: boolean;
  celebrationId: string;
  channelMember: DtoChannelMember;
  uid: string;
  workStartDate: ModelsDate;
}

export interface DtoChangeHubSpotProductRequest {
  description: string;
  internalProductId: string;
  name: string;
  stripePriceId: string;
}

export interface DtoChannel {
  createdAt: string;
  creator: DtoChannelCreator;
  exConnectType?: EnumsExConnectType | null;
  exGroupId?: string | null;
  id: string;
  membersCount: number;
  name: string;
  orgId: string;
  programLinks: DtoChannelProgramLink[];
  status: EnumsChannelStatus;
  teamId?: string | null;
  uid: string;
  updatedAt: string;
}

export interface DtoChannelByTypeOrgSummary {
  channelsCount: number;
  membersCount: number;
  orgId: string;
}

export interface DtoChannelCreator {
  fullName: string;
  icon: string;
}

export interface DtoChannelListResponse {
  channels: DtoChannel[];
}

export interface DtoChannelMember {
  channelId: string;
  createdAt: string;
  displayName: string;
  email: string;
  exUserId?: string | null;
  fullName: string;
  icon?: string | null;
  id: string;
  orgId?: string | null;
  status: EnumsMemberStatus;
  uid?: string | null;
  updatedAt: string;
}

export interface DtoChannelOrgSummary {
  default: DtoChannelByTypeOrgSummary;
  gameDrops: DtoChannelByTypeOrgSummary;
  organizersCount: number;
}

export interface DtoChannelOrgSummaryResponse {
  summaries: Record<string, DtoChannelOrgSummary>;
}

export interface DtoChannelProgramLink {
  basicProgram?: DtoBasicProgram | null;
  cadenceSettings?: ModelsProgramCadenceSettings | null;
  channel?: DtoChannel | null;
  channelId: string;
  createdAt: string;
  extensions?: any;
  groupSettings?: ModelsProgramGroupSettings | null;
  id: string;
  organization?: DtoOrganization | null;
  owner?: DtoOrganizer | null;
  programId?: string | null;
  programTargetId: string;
  programType: EnumsProgramType;
  status: EnumsProgramLinkStatus;
  tagSettings?: ModelsProgramTagSettings | null;
  uid: string;
  updatedAt: string;
}

export interface DtoChannelProgramLinkResponse {
  programLink: DtoChannelProgramLink;
}

export interface DtoChannelProgramLinksResponse {
  programLinks: DtoChannelProgramLink[];
}

export interface DtoChannelResponse {
  channel: DtoChannel;
}

export interface DtoCheckEmailAvailabilityRequest {
  email: string;
}

export interface DtoCheckInvitationResponse {
  failedResults: DtoCheckInvitationResult[];
}

export interface DtoCheckInvitationResult {
  email: string;
  failedReason: string;
  failureDetails?: DtoInvitationFailureDetails | null;
}

export interface DtoCheckOrganizersInviteRequest {
  emails: string[];
}

export interface DtoCleanupVenueRequest {
  force: boolean;
}

export interface DtoComputeEngagementScoreParams {
  gameDurationMultiplier: {
    everySecs: number;
    value: number;
  };
  gameOrganizedPoints: number;
  gamePlayedPoints: number;
  gameTypeMultipliers: {
    type: string;
    value: number;
  }[];
  liveGameMultiplier: number;
  recognitionReceivedByMessagePoints: number;
  recognitionReceivedByReactionPoints: number;
  recognitionSentByMessagePoints: number;
  recognitionSentByReactionPoints: number;
  replyToCelebrationPoints: number;
  replyToWaterCoolerPoints: number;
  ugcGameMultiplier: number;
}

export interface DtoController {
  /** Milliseconds */
  acquiredAt: number;
  /** Milliseconds */
  disconnectedAt: number;
  env: string;
  id: string;
  kind: string;
  /** Milliseconds */
  pingedAt: number;
  /** don't use enum for backward compatibility */
  status: string;
}

export interface DtoCountLiveEventsResponse {
  count: number;
}

export interface DtoCoupon {
  amountOff: number;
  /** One of `forever`, `once`, and `repeating`. Describes how long a customer who applies this coupon will get the discount. */
  duration: StripeCouponDuration;
  /** If `duration` is `repeating`, the number of months the coupon applies. Null if coupon `duration` is `forever` or `once`. */
  durationInMonths: number;
  id: string;
  percentOff: number;
}

export interface DtoCourseSearchResult {
  cover: DtoCover;
  createdAt: string;
  id: string;
  name: string;
}

export interface DtoCourseStackInfo {
  id: string;
  learnerCount: number;
  name: string;
}

export interface DtoCourseWithLearnerCount {
  gamePack: DtoGamePack;
  learnerCount: number;
}

export interface DtoCover {
  url: string;
}

export interface DtoCreateBlockVoteRequest {
  customReason?: string;
  reason?: EnumsBlockDownVoteReason;
  sessionId: string;
  value: EnumsBlockVoteValue;
}

export interface DtoCreateBrandRequest {
  blockIds: string[];
  blockLengthSec: number;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  predefinedBlockData: ModelsPredefinedBlockItem[];
  showcaseMediaData?: ModelsMediaData;
  showcaseText: string;
  tags: string[];
}

export interface DtoCreateCelebrationRequest {
  channelId: string;
  timezone: string;
}

export interface DtoCreateChannelRequest {
  addMembersFromOrgMembers?: boolean;
  exConnectType?: EnumsExConnectType | null;
  exGroupId?: string | null;
  name: string;
  orgId: string;
  teamId?: string | null;
}

export interface DtoCreateCheckoutSessionRequest {
  cancelUrl?: string | null;
  priceId: string;
  productId: string;
  returnUrl?: string | null;
  successUrl?: string | null;
  trial?: boolean | null;
  trialDays?: number | null;
  uiMode: string;
}

export interface DtoCreateCheckoutSessionResponse {
  clientSecret: string;
  url: string;
}

export interface DtoCreateCloudPlayerRequest {
  channelName: string;
  existingPlayerId: string;
  name: string;
  sessionUid: string;
  streamUrl: string;
}

export interface DtoCreateCloudPlayerResponse {
  id: string;
}

export interface DtoCreateCustomizeGamePackRequest {
  name?: string;
}

export type DtoCreateDownloadUGCFileTokenRequest = object;

export interface DtoCreateDownloadUGCFileTokenResponse {
  token: string;
}

export interface DtoCreateDrawingRequest {
  artworkMediaData: ModelsMediaData;
  data: ModelsDrawingData;
  orgId: string;
  sessionId: string;
}

export interface DtoCreateEventIntakeFormRequest {
  formId: string;
}

export interface DtoCreateEventRequest {
  attendeeEmails: string;
  attendees: string[];
  endAt: string;
  eventFirstParagraph: string;
  eventTitle: string;
  gamePackId: string;
  hostShoutOut: string;
  hostUid: string;
  message: string;
  orgId: string;
  orgName: string;
  organizerEmail: string;
  organizerName: string;
  organizerUid: string;
  prepMin: number;
  producerUid: string;
  startAt: string;
  studioId: string;
  timezone: string;
  type: EnumsEventType;
  venueId: string;
  vipOnStage: boolean;
}

export interface DtoCreateGameBlockRequest {
  position: number;
  type: EnumsBlockType;
}

export interface DtoCreateGamePackCheckoutSessionRequest {
  cancelUrl?: string | null;
  priceId: string;
  returnUrl?: string | null;
  successUrl?: string | null;
  uiMode: string;
}

export interface DtoCreateGamePackCheckoutSessionResponse {
  clientSecret: string;
  id: string;
  url: string;
}

export interface DtoCreateGamePackInvoiceRequest {
  email: string;
  priceId: string;
  promotionCode?: string | null;
}

export interface DtoCreateGamePackInvoiceResponse {
  invoiceId: string;
}

export interface DtoCreateGamePackOTPIntentRequest {
  introCallScheduled?: boolean;
  path?: string;
  priceId: string;
  queries?: string;
  timezone?: string;
}

export interface DtoCreateGamePackPaymentIntentRequest {
  applyDiscount?: boolean;
  priceId: string;
}

export interface DtoCreateGamePackPaymentIntentResponse {
  clientSecret: string;
}

export interface DtoCreateGamePackRequest {
  aiHostSettings?: ModelsAIHostSettings;
  approximateDurationSeconds?: number;
  childrenIds?: string[];
  cohostSettings?: ModelsCohostSettings;
  coverMediaId?: string;
  description?: string;
  detailSettings?: ModelsDetailSettings;
  extraSettings?: ModelsExtraSettings;
  featured?: boolean;
  free?: boolean;
  id?: string;
  logicSettings?: ModelsLogicSettings;
  marketingSettings?: ModelsMarketingSettings;
  name?: string;
  playbackSettings?: ModelsPlaybackSettings;
  playerRange?: ModelsPlayerRange;
  promotionalAssets?: DtoUpdateGamePackPromotionalAssets;
  replayable?: boolean;
  tags?: string[];
  teamRandomizationSettings?: ModelsTeamRandomizationSettings;
  ugcSettings?: ModelsUgcSettings;
  uid?: string;
  venueBackgroundMediaData?: ModelsMediaData;
  version: EnumsGamePackVersion;
}

export interface DtoCreateGlobalPromotionRequest {
  endedAt: string;
  messageCampaignId: string;
  orgIds: string[];
  startedAt: string;
  title: string;
}

export interface DtoCreateGroupRequest {
  emails: string[];
  name: string;
}

export interface DtoCreateGroupResponse {
  assignedMembers: DtoAssignedUser[];
  group: ModelsLearningGroup;
}

export interface DtoCreateGuestRequest {
  /**
   * @minLength 3
   * @maxLength 25
   */
  username: string;
}

export interface DtoCreateInquiryRequest {
  /**
   * @minLength 1
   * @maxLength 1000
   */
  query: string;
  /**
   * @maxItems 20
   * @minItems 1
   */
  questions: DtoCreateQuestionRequest[];
}

export interface DtoCreateInterviewRequest {
  inquiryId: string;
  status?: EnumsInterviewStatus;
}

export interface DtoCreateJeopardySessionRequest {
  data: ModelsJeopardySessionData;
}

export interface DtoCreateMessageLogicV2Request {
  campaignId: string;
  campaignType: EnumsMessageCampaignType;
  condition: ModelsMessageCondition;
  name: string;
  tags: string[];
  target: EnumsMessageTarget;
  template: ModelsMessageTemplateV2;
  trigger: ModelsMessageTrigger;
}

export interface DtoCreateMessageTemplateRequest {
  body: string;
  mediaId: string;
  mediaTemplateId: string;
  title: string;
  uid?: string;
}

export interface DtoCreateMessageTemplateSettingsRequest {
  description: string;
  key: string;
  name: string;
  template: ModelsMessageTemplateV2;
  vars: string;
}

export interface DtoCreateOrganizationRequest {
  /** @maxLength 50 */
  name: string;
  queries: Record<string, string>;
}

export type DtoCreatePairingRequest = object;

export interface DtoCreatePairingRoundRequest {
  channelId: string;
  globalRoundId: string;
  orgId: string;
}

export type DtoCreatePortalSessionRequest = object;

export interface DtoCreateProductFeatureRequest {
  description?: string;
  featureSettings: ModelsFeatureSettings;
  name: string;
  type: EnumsProductFeatureType;
}

export interface DtoCreateProductRequest {
  description?: string;
  name: string;
}

export interface DtoCreateProgramRequest {
  basicSettings?: ModelsProgramBasicSettings;
  marketingMetadata?: ModelsProgramMarketingMetadata;
  name: string;
  promotionalAssets?: ModelsProgramPromotionalAssets;
  type: EnumsProgramType;
}

export interface DtoCreateProgramRoundRequest {
  extensions?: object;
  label?: string;
  startedAt?: string;
  tags?: string[];
}

export interface DtoCreatePromptTemplateRequest {
  functions: ModelsFunctionDefinition[];
  messages: ModelsPromptMessage[];
  model: string;
  name: string;
  provider: string;
  settings: ModelsPromptTemplateSettings;
  systemPrompt: string;
  type: EnumsPromptTemplateType;
  variables: ModelsVariableDefinition[];
}

export interface DtoCreateQuestionReplyRequest {
  interviewId: string;
  questionId: string;
  replyMediaId: string;
}

export interface DtoCreateQuestionRequest {
  text: string;
}

export interface DtoCreateRoleplayHistoryRequest {
  blockId: string;
  endedAt: string;
  feedback?: ModelsRoleplayHistoryFeedback | null;
  instructions: string;
  isReplay: boolean;
  startedAt: string;
  transcript?: ModelsRoleplayHistoryTranscript[] | null;
}

export interface DtoCreateSharedAssetRequest {
  data?: ModelsJSONObject | null;
  label: string;
  mediaId?: string | null;
  purpose: EnumsSharedAssetPurpose;
}

export interface DtoCreateStackRequest {
  /** @minItems 1 */
  courseIds: string[];
  name: string;
}

export interface DtoCreateStandaloneBlockRequest {
  scenario?: EnumsBrandPredefinedBlockScenario;
  type: EnumsBlockType;
}

export interface DtoCreateStripeSubscriptionRequest {
  priceId: string;
  productId: string;
  trial?: boolean | null;
}

export interface DtoCreateSubscriptionResponse {
  clientSecret: string;
  subscriptionId: string;
}

export interface DtoDeleteOrganizersRequest {
  uids: string[];
}

export interface DtoDeriveVenueSettingsRequest {
  gamePackId: string;
}

export interface DtoDerivedVenueSettings {
  preferSinglePlayerTeams: boolean;
  seatCap?: number | null;
  tryItNowEnabled: boolean;
}

export interface DtoDetailDrawing {
  artworkMedia: CommonMedia;
  artworkMediaData: ModelsMediaData;
  createdAt: string;
  data: ModelsDrawingData;
  id: string;
  orgId: string;
  orgName: string;
  sessionId: string;
  sessionStartedAt: string;
  uid: string;
  updatedAt: string;
  username: string;
}

export interface DtoDetailDrawingListResponse {
  drawings: DtoDetailDrawing[];
  paging: CommonPaging;
}

export interface DtoDiscount {
  coupon?: DtoCoupon | null;
  endAt?: string | null;
}

export interface DtoDmgRenderRequest {
  pairingId: string;
  roundId: string;
  templateId: string;
}

export interface DtoDmgRenderResponse {
  url: string;
}

export type DtoDownloadUGCFileRequest = object;

export interface DtoDrawing {
  artworkMedia: CommonMedia;
  artworkMediaData: ModelsMediaData;
  createdAt: string;
  data: ModelsDrawingData;
  id: string;
  orgId: string;
  sessionId: string;
  uid: string;
  updatedAt: string;
}

export interface DtoDuplicateGameResponse {
  blocks: DtoBlock[];
  game: DtoGame;
}

export interface DtoEndSessionRequest {
  endedAt: number;
  isGameCompleted: boolean;
  silentRefresh: boolean;
}

export interface DtoEnrollGroupsRequest {
  groupIds: string[];
  objectId: string;
}

export interface DtoEnrollGroupsResponse {
  groupIds: string[];
  objectId: string;
}

export interface DtoEvent {
  attendeeUids: string[];
  attendees?: DtoOrganizer[] | null;
  createdAt: string;
  data: ModelsEventData;
  endAt: string;
  gamePack: DtoGamePack;
  host: ModelsLiveEventHost;
  hostUid: string;
  id: string;
  intakeForm: DtoEventIntakeForm;
  message: string;
  organizer: DtoOrganizer;
  organizerUid: string;
  producer: ModelsLiveEventProducer;
  producerUid: string;
  sequence: number;
  startAt: string;
  status: EnumsEventStatus;
  studio: ModelsLiveEventStudio;
  studioId: string;
  timezone: string;
  type: EnumsEventType;
  updatedAt: string;
  venue: DtoVenue;
  venueId: string;
}

export interface DtoEventAttendee {
  email?: string | null;
  icon?: string | null;
  id: string;
  name?: string | null;
}

export interface DtoEventIntakeForm {
  createdAt: string;
  data?: ModelsEventIntakeFormData | null;
  eventId: string;
  id: string;
  lastSentAt?: string | null;
  status: EnumsIntakeFormStatus;
  updatedAt: string;
}

export interface DtoEventIntakeFormResponse {
  form: DtoEventIntakeForm;
}

export interface DtoEventIntakeSummaryResponse {
  form: DtoEventIntakeForm;
  items: ClientIntakeFormSummaryItem[];
  subject: string;
}

export interface DtoEventResponse {
  event: DtoEvent;
}

export interface DtoEventsResponse {
  events: DtoEvent[];
  paging: CommonPaging;
}

export interface DtoExecuteTokenExchangeRequest {
  code: string;
  platform: EnumsTokenExchangePlatform;
}

export interface DtoExecuteTokenExchangeResponse {
  deeplink: string;
}

export interface DtoExperience {
  event?: DtoEvent | null;
  id: string;
  session?: DtoSession | null;
  type: DtoExperienceType;
}

export interface DtoExperienceListResponse {
  experiences: DtoExperience[];
  paging: CommonPaging;
}

export enum DtoExperienceType {
  ExperienceTypeSession = 'session',
  ExperienceTypeEvent = 'event',
}

export interface DtoExportScormPackageRequest {
  orgId?: string;
  url: string;
  version: ScormVersion;
}

export interface DtoExportSessionsRequest {
  orgId: string;
  timeRange: ModelsReportTimeRange;
}

export interface DtoExportSessionsResponse {
  status: string;
}

export interface DtoExternalMediaItem {
  id: string;
  provider: string;
  thumbnailUrl: string;
  title: string;
  url: string;
}

export interface DtoExternalMediaSearchResponse {
  items: DtoExternalMediaItem[];
  total: number;
}

export interface DtoFeaturedItem {
  gamePack?: DtoGamePack | null;
  id: string;
  product?: DtoProduct | null;
  tag?: DtoTag | null;
  type: EnumsFeaturedItemType;
}

export interface DtoFetchDocumentConfigResponse {
  additionalProperties?: Record<string, any>;
  chunkingMethods: ClientChunkingMethod[];
  loaderConfigItems: ClientDocLoaderConfigItem[];
}

export interface DtoFormResponse {
  formId: string;
  responseId: string;
}

export interface DtoGame {
  approximateDurationSeconds: number;
  blocksCount: number;
  cover: CommonMedia;
  createdAt: string;
  creator: DtoPublicUser;
  description: string;
  id: string;
  isPrime: boolean;
  name: string;
  playbackVersion: number;
  recordingSeconds: number;
  recordingVersionMismatch: boolean;
  recordingsCount: number;
  tags: DtoTag[];
  type: string;
  uid: string;
  updatedAt: string;
}

export interface DtoGameDescription {
  description?: string;
  id: string;
  name: string;
}

export interface DtoGameListResponse {
  games: DtoGame[];
  gamesCount: number;
  paging: CommonPaging;
}

export interface DtoGamePack {
  aiHostSettings?: ModelsAIHostSettings | null;
  approximateDurationSeconds: number;
  blockVoteStats?: ModelsBlockVoteStats | null;
  childrenIds?: string[] | null;
  cohostSettings?: ModelsCohostSettings | null;
  cover: CommonMedia;
  createdAt: string;
  creator: DtoPublicUser;
  description: string;
  detailSettings: ModelsDetailSettings;
  extraSettings?: ModelsExtraSettings | null;
  gamesCount: number;
  id: string;
  isFeatured: boolean;
  isFree: boolean;
  isPrime: boolean;
  isUGCTemplate?: boolean | null;
  logicSettings?: ModelsLogicSettings | null;
  marketingSettings?: ModelsMarketingSettings | null;
  name: string;
  playStyle: EnumsGamePackPlayStyle;
  playbackSettings?: ModelsPlaybackSettings | null;
  played?: boolean | null;
  playerRange: ModelsPlayerRange;
  promotionalAssets?: DtoGamePackPromotionalAssets | null;
  promotionalGameDetails?: string[] | null;
  recordingSeconds: number;
  replayable: boolean;
  slug: string;
  tags: DtoTag[];
  teamRandomizationSettings: ModelsTeamRandomizationSettings;
  type: string;
  ugcSettings?: ModelsUgcSettings | null;
  uid: string;
  updatedAt: string;
  venueBackground: CommonMedia;
  venueBackgroundMediaData: ModelsMediaData;
  version: EnumsGamePackVersion;
}

export interface DtoGamePackListResponse {
  gamePacks: DtoGamePack[];
  gamePacksCount: number;
  paging: CommonPaging;
}

export interface DtoGamePackPromotionalAssets {
  cover?: {
    media?: CommonMedia;
    mediaData?: ModelsMediaData;
  };
  description: string;
  pushable: boolean;
  title: string;
}

export interface DtoGamePackUGCFile {
  contentType?: string | null;
  createdAt: string;
  downloadable: boolean;
  extFileId?: string | null;
  id: string;
  media?: ModelsMediaAsset | null;
  name: string;
  orgId: string;
  packId: string;
  status: EnumsGamePackUGCFileStatus;
  uid: string;
  updatedAt: string;
}

export interface DtoGameScore {
  createdAt: string;
  fullNames: string;
  gamePackId: string;
  gamePackName: string;
  id: string;
  joyCaptures: DtoJoyCapture[];
  mode: ModelsSessionMode;
  organizationId: string;
  organizationLogo: CommonMedia;
  organizationName: string;
  pairingId: string;
  score: number;
  sessionId: string;
  shortNames: string;
  teamId: string;
  teamName: string;
  updatedAt: string;
  userIds: string[];
  venueId: string;
}

export interface DtoGameScoreListResponse {
  gameScores: DtoGameScore[];
}

export interface DtoGameStateForVoiceOver {
  blockId: string;
  blockScenario?: EnumsBrandPredefinedBlockScenario;
  brand?: DtoBrandDescription;
  gamePack?: DtoGameDescription;
  miniGame?: DtoGameDescription;
  nextMiniGame?: DtoGameDescription;
  numUnitsInSession: number;
  scoreboard: DtoScoreboardEntry[];
  sessionId: string;
  sessionUnitIndex?: number;
  teams: Record<string, DtoTeam>;
  unitLabel: string;
  vips?: DtoVIPDescription[];
}

export interface DtoGenAIGlobalSettingsResponse {
  additionalProperties?: Record<string, any>;
  imageGenProviders: ClientImageGenProviderItem[];
  llmProviders: ClientLLMProviderItem[];
}

export interface DtoGenerateEngagementScoreReportRequest {
  breakdown?: boolean;
  debug?: boolean;
  endedAt?: string;
  filterZero?: boolean;
  orgId?: string;
  paid?: boolean;
  params?: DtoComputeEngagementScoreParams;
  standard?: boolean;
  startedAt?: string;
  summary?: boolean;
}

export interface DtoGenerateLineOfInquiryRequest {
  query: string;
}

export interface DtoGenerateMediaSearchKeywordRequest {
  context: string;
  limit: number;
  promptTemplateId: string;
  sessionId: string;
}

export interface DtoGenerateMediaSearchKeywordResponse {
  keywords: string[];
}

export interface DtoGenerateRoleplayPromptRequest {
  formId: string;
  promptTemplateId?: string | null;
  responseId: string;
}

export interface DtoGenerateRoleplayPromptResponse {
  prompt: string;
}

export interface DtoGenerateTopicBlocksRequest {
  llmSettings?: DtoLLMSettings;
  outline: SchemaTrainingTopicOutline;
  profileId: string;
  searchSettings: DtoLLMSearchSettings;
  sessionId: string;
  topicIndex: number;
  topicName: string;
}

export interface DtoGenerateTopicBlocksResponse {
  blocks: DtoGeneratedBlock[];
}

export interface DtoGenerateTopicOutlineRequest {
  llmSettings?: DtoLLMSettings;
  profileId: string;
  searchSettings: DtoLLMSearchSettings;
  sessionId: string;
  topicName: string;
}

export interface DtoGenerateTopicOutlineResponse {
  outline: SchemaTrainingTopicOutline;
}

export interface DtoGenerateTrainingBlockRequest {
  blockType: EnumsTrainingBlockType;
  llmSettings?: DtoLLMSettings;
  profileId: string;
  reason: string;
  searchSettings: DtoLLMSearchSettings;
  sessionId: string;
  slideLayout: string;
  topicName: string;
}

export interface DtoGenerateTrainingBlockResponse {
  blocks: DtoGeneratedBlock[];
}

export interface DtoGenerateTrainingCourseRequest {
  coverMediaId?: string;
  description: string;
  formResponse?: DtoFormResponse | null;
  name: string;
  prompt: string;
  /**
   * PackId       string        `json:"-"`
   * Uid          string        `json:"-"`
   */
  templateId: string;
}

export interface DtoGenerateTrainingCourseResponse {
  packId: string;
}

export interface DtoGenerateTrainingOutlineRequest {
  context: string;
  llmSettings?: DtoLLMSettings;
  profileId: string;
  sessionId: string;
}

export interface DtoGenerateTrainingOutlineResponse {
  outline: SchemaTrainingOutline;
}

export interface DtoGeneratedBlock {
  data: Record<string, any>;
  dialogue?: SchemaTrainingDialogue | null;
  intro?: EnumsBlockIntro | null;
  mediaReply?: ModelsBlockMediaSearchAIReply | null;
  personalityId?: string[] | null;
  song?: ModelsSharedAssetSong | null;
  type: EnumsTrainingBlockType;
}

export interface DtoGetAgoraRTCTokenRequest {
  channelName: string;
  sessionUid: string;
}

export interface DtoGetAgoraRTCTokenResponse {
  token: string;
}

export interface DtoGetAutoScalingStatusResponse {
  enabled: boolean;
}

export interface DtoGetBlockDataEntryResponse {
  entry: DtoBlockDataEntry;
}

export interface DtoGetBlockVoteResponse {
  vote?: DtoBlockVote | null;
}

export interface DtoGetChatTokenResponse {
  token: string;
}

export interface DtoGetCloudInstanceBindingResponse {
  binding: DtoPublicCloudInstanceBinding;
}

export interface DtoGetCourseAssignedGroupsResponse {
  groups: DtoGroupEnrollmentDetail[];
}

export interface DtoGetCourseAssignedMembersResponse {
  members: ModelsAssignedMemberRow[];
}

export interface DtoGetCourseStacksResponse {
  stacks: DtoCourseStackInfo[];
}

export interface DtoGetCreatedCoursesResponse {
  courses: DtoCourseWithLearnerCount[];
  coursesCount: number;
  pagination: DtoPagination;
}

export interface DtoGetCreatedStacksResponse {
  pagination: DtoPagination;
  stacks: DtoStackWithLearnerCount[];
  stacksCount: number;
}

export interface DtoGetEventAttendeesResponse {
  attendees: DtoEventAttendee[];
}

export interface DtoGetFeaturedResponse {
  items: DtoFeaturedItem[];
}

export interface DtoGetFirebaseTokenResponse {
  token: string;
}

export interface DtoGetHubSpotDealResponse {
  association: DtoHubSpotDealAssociation;
  deal: DtoHubSpotDeal;
}

export interface DtoGetHubSpotProductResponse {
  product: HubspotProduct;
}

export interface DtoGetLearnersResponse {
  learnersDetails: ModelsLearnerAssignmentDetail[];
}

export interface DtoGetNotificationSettingsResponse {
  features: ModelsNotificationFeatures;
  settings: ModelsNotificationSettings;
  user: DtoUser;
}

export interface DtoGetOrgPresentationSummaryResponse {
  celebrations: DtoProgramSummaryCelebrations;
  endAt: string;
  engagementPercentile?: number | null;
  engagementScore: number;
  engagementScoreBefore: number;
  globalPairing: DtoProgramSummaryGlobalPairing;
  intros: DtoProgramSummaryIntros;
  introsPercentile?: number | null;
  newFeaturedGamePacks: DtoGamePack[];
  recognition: DtoProgramSummaryRecognition;
  sessionPlayers: number;
  sessionSeconds: number;
  socialTimePercentile?: number | null;
  startAt?: string | null;
  topContributors: DtoTopContributor[];
  waterCooler: DtoProgramSummaryWaterCooler;
}

export interface DtoGetOthersPlayingResponse {
  gameScores: DtoGameScore[];
  type: EnumsGameOthersPlayingType;
}

export interface DtoGetPageResponse {
  page: DtoPage;
}

export interface DtoGetPairingGameResponse {
  pairingGame: DtoPairingGame;
}

export interface DtoGetPreUnsubscribeSummaryResponse {
  conversationsCount: number;
  groupPhotos: DtoGroupPhoto[];
  introsCount: number;
  programLinks: DtoChannelProgramLink[];
  savingsAmount: number;
  scheduledEvents: DtoEvent[];
  sessionsCount: number;
  sessionsSeconds: number;
}

export interface DtoGetProgramsResponse {
  activePrograms: DtoProgram[];
  inactivePrograms: DtoProgram[];
}

export interface DtoGetSessionParticipantsResponse {
  participants: DtoSessionParticipant[];
}

export interface DtoGetSessionSentryStatsResponse {
  controllerErrorsHandledCount: number;
  controllerErrorsUnhandledCounts: number;
  totalErrorsHandledCount: number;
  totalErrorsUnhandledCounts: number;
}

export interface DtoGetSessionSnapshotResponse {
  data: Record<string, any>;
}

export interface DtoGetTempTokenRequest {
  reCaptchaResponse: string;
}

export interface DtoGetTempTokenResponse {
  token: string;
}

export interface DtoGetTestResultResponse {
  createdAt?: string | null;
  succeeded?: boolean | null;
}

export interface DtoGetTrainingMusicLibraryResponse {
  playlist: ModelsSharedAssetSong[];
}

export interface DtoGetTrainingPersonalitiesResponse {
  personalities: DtoPersonality[];
}

export type DtoGetUGCFilesRequest = object;

export interface DtoGetUGCFilesResponse {
  files: DtoGamePackUGCFile[];
}

export interface DtoGetUserResponse {
  user: DtoUser;
}

export interface DtoGlobalPairingRound {
  completedPairingsCount: number;
  completedPlayersCount: number;
  config: ModelsGlobalRoundConfig;
  createdAt: string;
  endedAt: string;
  id: string;
  mainGamePack: DtoGamePack;
  mainGamePackId: string;
  messageCampaignId: string;
  orgsCount: number;
  pairingsCount: number;
  playedOrgsCount: number;
  playersCount: number;
  shadowGamePackIds: string[];
  shadowGamePacks: DtoGamePack[];
  startedAt: string;
  status: ModelsGlobalPairingRoundStatus;
  updatedAt: string;
}

export interface DtoGlobalPromotion {
  createdAt: string;
  endedAt: string;
  gamePack: DtoGamePack;
  gamePackId: string;
  id: string;
  managerChannelCount: number;
  managerCount: number;
  messageCampaignId: string;
  orgsCount: number;
  startedAt: string;
  status: EnumsGlobalPromotionStatus;
  title: string;
  totalOrgsCount: number;
  updatedAt: string;
}

export interface DtoGlobalPromotionListResponse {
  globalPromotions: DtoGlobalPromotion[];
}

export interface DtoGradeSubmissionRequest {
  correctAnswers: string[];
  model: string;
  temperature?: number;
  userSubmission: string;
}

export interface DtoGradeSubmissionResponse {
  evaluation: boolean;
}

export interface DtoGroupAssignedCourse {
  assignedBy: string;
  assignedOn: string;
  courseId: string;
  courseName: string;
  cover: DtoCover;
}

export interface DtoGroupAssignedStack {
  assignedBy: string;
  assignedOn: string;
  courseCount: number;
  stackId: string;
  stackName: string;
}

export interface DtoGroupDetailResponse {
  assignedCourses: DtoGroupAssignedCourse[];
  assignedMembers: DtoAssignedGroupMember[];
  assignedStacks: DtoGroupAssignedStack[];
  createdAt: string;
  creatorUid: string;
  id: string;
  name: string;
  orgId: string;
}

export interface DtoGroupEnrollmentDetail {
  assignedBy: string;
  assignedOn: string;
  groupId: string;
  groupName: string;
  membersCount: number;
  /** placeholder = 0 */
  progressPct: number;
}

export interface DtoGroupPhoto {
  createdAt: string;
  id: string;
  orgId: string;
  sessionId: string;
  status: EnumsGroupPhotoStatus;
  updatedAt: string;
  url: string;
}

export interface DtoGroupSettings {
  createdAt: string;
  updatedAt: string;
  value: object;
}

export interface DtoGuestSignupRequest {
  /**
   * @minLength 1
   * @maxLength 60
   */
  email: string;
  /**
   * @minLength 1
   * @maxLength 25
   */
  firstName: string;
  orgId: string;
  timezone?: string | null;
}

export interface DtoHeyGenTemplateVariablesResponse {
  variables: Record<string, ModelsHeyGenTemplateVariable>;
}

export interface DtoHostsResponse {
  hosts: ModelsLiveEventHost[];
}

export interface DtoHubSpotContact {
  email: string;
  firstname: string;
  id: string;
  lastname: string;
}

export interface DtoHubSpotDeal {
  amount: string;
  closeDate?: string | null;
  createDate?: string | null;
  id: string;
  link: string;
  name: string;
  owner?: HubspotOwner | null;
  ownerId?: string | null;
  pipeline?: HubspotPipeline | null;
  pipelineId?: string | null;
  pipelineStageId?: string | null;
}

export interface DtoHubSpotDealAssociation {
  contacts: DtoHubSpotContact[];
  lineItems: HubspotLineItem[];
}

export interface DtoImageGenRequest {
  aspectRatio?: ClientAspectRatio;
  model?: string;
  num?: number;
  prompt: string;
  provider?: string;
}

export interface DtoImageGenResponse {
  additionalProperties?: Record<string, any>;
  images: ClientGeneratedImage[];
}

export interface DtoInAnotherOrgFailureDetails {
  orgId: string;
  orgName: string;
}

export interface DtoInquiry {
  createdAt: string;
  generatedSummary?: string;
  id: string;
  inquirerName: string;
  query: string;
  questionIds: string[];
  questions?: DtoQuestion[];
  summaryGeneratedAt?: string;
  uid: string;
  updatedAt: string;
}

export interface DtoIntakeEvent {
  endAt: string;
  id: string;
  intakeForm: DtoEventIntakeForm;
  orgLogo: CommonMedia;
  orgName: string;
  startAt: string;
  timezone: string;
}

export interface DtoIntakeEventResponse {
  event: DtoIntakeEvent;
}

export interface DtoInterview {
  createdAt: string;
  editedSummary?: string;
  generatedSummary?: string;
  id: string;
  inquiry: DtoInquiry;
  questionReplies?: DtoQuestionReply[];
  questions: DtoQuestion[];
  status: EnumsInterviewStatus;
  updatedAt: string;
  userName: string;
}

export interface DtoInvitationFailureDetails {
  inAnotherOrg?: DtoInAnotherOrgFailureDetails | null;
}

export interface DtoInviteOrganizerRequest {
  invitedUsers: DtoInvitedUser[];
  sendEmail: boolean;
}

export interface DtoInvitedUser {
  activate: boolean;
  email: string;
  exUserId: string;
  fullName: string;
}

export interface DtoInvoice {
  amountDue: number;
  createdAt: string;
  description: string;
  downloadLink: string;
  downloadLinkType: EnumsInvoiceDownloadLinkType;
  /** only for subscription invoices */
  hostedInvoiceUrl?: string | null;
  id: string;
  receiptUrl?: string | null;
  type: EnumsInvoiceType;
}

export interface DtoInvoiceListResponse {
  invoices: DtoInvoice[];
}

export interface DtoJeopardySession {
  blockId: string;
  createdAt: string;
  data: ModelsJeopardySessionData;
  id: string;
  orgId?: string | null;
  uid: string;
  username?: string | null;
}

export interface DtoJeopardySessionsResponse {
  sessions: DtoJeopardySession[];
}

export interface DtoJoinGroupsRequest {
  groupIds: string[];
  uids: string[];
}

export interface DtoJoyCapture {
  /** full name, when available. note that joy captures are authorized before returning. */
  fullName: string;
  /** a fallback in case there is no media, or the user has opted out. */
  initials: string;
  /** a media object for the preprocessed joy capture. this is a gif, if it exists. */
  media: CommonMedia;
  /**
   * a fallback in case the media object is not present for this joy capture. points to a raw, unprocessed image
   * on s3. the client can render these to appear like gifs.
   */
  preSignedS3Url: string;
  uid: string;
}

export interface DtoLLMSearchSettings {
  limit?: number;
  namespace: string;
  scoreThreshold?: number;
}

export interface DtoLLMSettings {
  model: string;
  provider: string;
}

export interface DtoLeaderboardSummaryResponse {
  globalCompanyRank: number;
  round: DtoPairingRound;
}

export interface DtoLeaderboardTeam {
  orgId: string;
  orgName: string;
  pairingId: string;
  players: DtoLeaderboardTeamPlayer[];
  rank: number;
  roundId: string;
  score: number;
  sessionId: string;
  teamName: string;
}

export interface DtoLeaderboardTeamPlayer {
  displayName: string;
  uid: string;
}

export interface DtoLeaderboardTeamsResponse {
  paging: CommonPaging;
  teams: DtoLeaderboardTeam[];
}

export interface DtoLearnerEnrollmentsSummary {
  activeCount: number;
  completedCount: number;
  enrollments?: DtoLearningEnrollment[] | null;
  paging: CommonPaging;
  totalCount: number;
}

export interface DtoLearnerMembershipsSummary {
  memberships?: DtoLearningGroupMembership[] | null;
  paging: CommonPaging;
  totalCount: number;
}

export interface DtoLearnerProfileResponse {
  email: string;
  enrollmentsSummary?: DtoLearnerEnrollmentsSummary | null;
  membershipsSummary?: DtoLearnerMembershipsSummary | null;
  name: string;
  uid: string;
}

export interface DtoLearningEnrollment {
  assignedBy?: DtoOrganizer | null;
  assignedByUid: string;
  cover?: CommonMedia | null;
  createdAt: string;
  displayName: string;
  finished: boolean;
  id: string;
  objectId: string;
  type: ModelsAssignmentType;
  uid: string;
  updateAt: string;
}

export interface DtoLearningEnrollmentsResponse {
  enrollments?: DtoLearningEnrollment[] | null;
  paging: CommonPaging;
}

export interface DtoLearningGroup {
  createdAt: string;
  creator?: DtoOrganizer | null;
  creatorUid: string;
  id: string;
  name: string;
  orgId: string;
  updatedAt: string;
}

export interface DtoLearningGroupMembership {
  addedBy?: DtoOrganizer | null;
  addedByUid: string;
  createdAt: string;
  group?: DtoLearningGroup | null;
  groupId: string;
  id: string;
  uid: string;
  updateAt: string;
}

export interface DtoLearningGroupMembershipsResponse {
  memberships?: DtoLearningGroupMembership[] | null;
  paging: CommonPaging;
}

export interface DtoLearningGroupSearchResponse {
  groups: ModelsLearningGroup[];
}

export interface DtoLearningGroupWithCounts {
  coursesCount: number;
  createdAt: string;
  creatorUid: string;
  id: string;
  membersCount: number;
  name: string;
  orgId: string;
  updatedAt: string;
}

export interface DtoLineOfInquiryResponse {
  questions: string[];
}

export interface DtoListHeyGenAvatarsResponse {
  avatars: ModelsHeyGenAvatar[];
}

export interface DtoListHeyGenTemplatesResponse {
  templates: ModelsHeyGenTemplate[];
}

export interface DtoListOnboardingTasksResponse {
  tasks: DtoOnboardingTask[];
}

export interface DtoListStripePricesResponse {
  prices: DtoSimpleStripePrice[];
}

export interface DtoListTrainingProfileResponse {
  profiles: ModelsTrainingProfile[];
}

export interface DtoLiveEventRequest {
  attendeeUids: string[];
  attendees?: DtoOrganizer[] | null;
  createdAt: string;
  gamePack?: DtoGamePack | null;
  gamePackId: string;
  hostShoutOut: string;
  id: string;
  orgId: string;
  requester?: DtoOrganizer | null;
  requesterUid: string;
  startAt: string;
  timezone: string;
}

export interface DtoLiveEventRequestResponse {
  request: DtoLiveEventRequest;
}

export interface DtoLoadDocumentParams {
  chunkingMethod?: string;
  loaderName?: string;
  namespace?: string;
  prompt?: string;
}

export interface DtoLoadDocumentResponse {
  additionalProperties?: Record<string, any>;
  docs: ClientDocument[];
}

export interface DtoLoginRequest {
  email: string;
  password: string;
}

export interface DtoLookupNewUsersRequest {
  uids: string[];
}

export interface DtoLookupNewUsersResponse {
  uids: string[];
}

export interface DtoMakeTrainingCourseRequest {
  coverMediaId?: string;
  description?: string;
  groups: DtoTrainingCourseGroup[];
  name: string;
}

export interface DtoMakeTrainingCourseResponse {
  packId: string;
}

export interface DtoMemberListResponse {
  members: DtoChannelMember[];
}

export interface DtoMessage {
  content: ModelsMessageContent;
  createdAt: string;
  exGroupId: string;
  exMessageId: string;
  globalRoundId: string;
  id: string;
  messageTemplate: DtoMessageTemplate;
  orgId: string;
  organization: DtoOrganization;
  recipientData: ModelsRecipientData;
  status: ModelsMessageStatus;
  targetType: string;
  templateId: string;
  updatedAt: string;
}

export interface DtoMessageCampaign {
  createdAt: string;
  globalPairingRounds: DtoGlobalPairingRound[];
  id: string;
  integrationCount: number;
  messageCampaignType: ModelsMessageCampaignType;
  name: string;
  triggerDescription: string;
  updatedAt: string;
}

export interface DtoMessageCampaignListResponse {
  campaigns: DtoMessageCampaign[];
}

export interface DtoMessageCampaignV2ListResponse {
  campaigns: ModelsMessageCampaignV2[];
}

export interface DtoMessageLogic {
  campaignId: string;
  condition: string;
  createdAt: string;
  description: string;
  id: string;
  messageCampaign: DtoMessageCampaign;
  messageTemplate: DtoMessageTemplate;
  name: string;
  targetType: string;
  templateId: string;
  title: string;
  triggerDetails: ModelsTriggerDetails;
  triggerType: string;
  updatedAt: string;
}

export interface DtoMessageLogicV2 {
  campaignId: string;
  campaignType: EnumsMessageCampaignType;
  condition: ModelsMessageCondition;
  createdAt: string;
  id: string;
  name: string;
  status: EnumsMessageLogicStatus;
  tags: string[];
  target: EnumsMessageTarget;
  template: ModelsMessageTemplateV2;
  trigger: ModelsMessageTrigger;
  updatedAt: string;
}

export interface DtoMessageLogicV2Response {
  logic: DtoMessageLogicV2;
}

export interface DtoMessageLogicsV2Response {
  logics: DtoMessageLogicV2[];
}

export interface DtoMessageTemplate {
  body: string;
  createdAt: string;
  id: string;
  media: CommonMedia;
  mediaTemplateId: string;
  messageLogics: DtoMessageLogic[];
  messagesSentCount: number;
  reactionsCount: number;
  recipientsCount: number;
  title: string;
  uid: string;
  updatedAt: string;
}

export interface DtoMessageTemplateSettings {
  description: string;
  id: string;
  key: string;
  name: string;
  template: ModelsMessageTemplateV2;
  vars?: string | null;
}

export interface DtoMessageTemplateSettingsListResponse {
  settingsList: DtoMessageTemplateSettings[];
}

export interface DtoMessageTemplateSettingsResponse {
  settings: DtoMessageTemplateSettings;
}

export interface DtoMoveBlocksReq {
  dragPosition: number;
  gameId: string;
  operation: string;
  targetPosition: number;
}

export interface DtoMultiBrandResponse {
  brands: DtoBrand[];
  brandsCount: number;
  paging: CommonPaging;
}

export interface DtoMultiCelebrationParticipantsResponse {
  participants: DtoCelebrationParticipant[];
}

export interface DtoMultiCelebrationsResponse {
  celebrations: DtoBundledCelebration[];
}

export interface DtoMultiGlobalPairingRoundResponse {
  globalRounds: DtoGlobalPairingRound[];
}

export interface DtoMultiJoyCaptureResponse {
  joyCaptures: DtoJoyCapture[];
  paging: CommonPaging;
}

export interface DtoMultiMessagesResp {
  messages: DtoMessage[];
}

export interface DtoMultiOrganizationsResponse {
  organizations: DtoOrganization[];
}

export interface DtoMultiPairingRoundsResponse {
  rounds: DtoPairingRound[];
}

export interface DtoMultiPairingsResponse {
  paging: CommonPaging;
  pairings: DtoPairing[];
}

export interface DtoMultiSummarizedInterviewResponse {
  interviews?: DtoSummarizedInterview[];
}

export interface DtoMyStatsSummaryResponse {
  pairsRoundsPlayed: number;
}

export interface DtoNarrowedRunStep {
  id: string;
  status: OpenaiRunStepStatus;
  stepDetails: OpenaiStepDetails;
  type: OpenaiRunStepType;
}

export interface DtoNewMember {
  exUserId?: string | null;
  isNewInvited: boolean;
  uid?: string | null;
}

export interface DtoNullableEventResponse {
  event?: DtoEvent | null;
}

export interface DtoNullableProductResponse {
  product?: DtoProduct | null;
}

export interface DtoOAuthLoginRequest {
  code: string;
  loginFrom: string;
  provider: EnumsOAuthProvider;
  redirectURI: string;
}

export interface DtoOAuthRegisterRequest {
  code: string;
  organizationName?: string;
  provider: EnumsOAuthProvider;
  queries?: Record<string, string>;
  redirectURI: string;
  timezone?: string | null;
  useEmailAsOrgName?: boolean;
}

export interface DtoOTPUpsellResponse {
  price: ModelsPrice;
  product: DtoProduct;
  trialPeriodDays: number;
}

export interface DtoOnSeatCapErrorShownRequest {
  errorEmail: string;
  errorType: string;
  orgId: string;
  userEmail: string;
}

export interface DtoOnboardTask {
  body: ModelsOnboardTaskBody;
  createdAt: string;
  iconUrl: string;
  id: string;
  media: CommonMedia;
  primaryAction: ModelsOnboardTaskAction;
  secondaryAction: ModelsOnboardTaskAction;
  slug: string;
  subtitle: string;
  title: string;
  updatedAt: string;
}

export interface DtoOnboardTaskResponse {
  onboardTask: DtoOnboardTask;
}

export interface DtoOnboardUserRequest {
  /**
   * @minLength 1
   * @maxLength 60
   */
  email: string;
  exUserId: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  lastName: string;
  orgId: string;
  timezone?: string | null;
}

export interface DtoOnboardingTask {
  completedAt: string;
  data?: DtoOnboardingTaskData | null;
  displayName: string;
  hasPermission: boolean;
  isCompleted: boolean;
  isLocked: boolean;
  isRelevant: boolean;
  isSkipped: boolean;
  lockedReason: string;
  name: EnumsOnboardingTaskName;
  skippedAt: string;
}

export interface DtoOnboardingTaskData {
  destination?: string | null;
  gamePack?: DtoGamePack | null;
}

export interface DtoOnboardingTaskLog {
  createdAt: string;
  id: string;
  operation: EnumsOnboardingTaskOperation;
  operatorUid: string;
  orgId: string;
  taskName: EnumsOnboardingTaskName;
}

export interface DtoOnboardingTaskLogsResponse {
  logs: DtoOnboardingTaskLog[];
}

export interface DtoOneTimePurchaseActivity {
  channel: string;
  eventId?: string | null;
  gamePack: DtoGamePack;
  lastAttemptedAt: string;
  organizer: DtoOrganizer;
  priceId: string;
  purchasedAt?: string | null;
  scheduledAt?: string | null;
  status: EnumsOneTimePurchaseIntentStatus;
  stripeTxnType?: EnumsOneTimePurchaseStripeTxnType | null;
  utm?: CommonStandardUTMParams | null;
}

export interface DtoOneTimePurchaseActivityListResponse {
  activities: DtoOneTimePurchaseActivity[];
}

export interface DtoOneTimePurchaseLog {
  createdAt: string;
  id: string;
  objectId: string;
  orgId: string;
  priceId: string;
  stripeTxnId: string;
  uid: string;
  updatedAt: string;
}

export interface DtoOneTimePurchaseLogListResponse {
  logs: DtoOneTimePurchaseLog[];
}

export interface DtoOneTimePurchaseReceiptResponse {
  receiptUrl: string;
}

export interface DtoOpenProgramGroupGameResponse {
  redirectTo: string;
}

export interface DtoOperateTaskRequest {
  operation: EnumsOnboardingTaskOperation;
  orgId: string;
}

export interface DtoOptimizeUGCPromptRequest {
  prompt: string;
}

export interface DtoOptimizeUGCPromptResponse {
  prompt: string;
}

export interface DtoOrgConnection {
  asSourceOfTruth: boolean;
  exConnId: string;
  icon: string;
  id: string;
  name: string;
  orgId: string;
  status: EnumsExConnectStatus;
  type: EnumsExConnectType;
}

export interface DtoOrgConnectionBase {
  asSourceOfTruth: boolean;
  exConnId: string;
  id: string;
  orgId: string;
  status: EnumsExConnectStatus;
  type: EnumsExConnectType;
}

export interface DtoOrgConnectionResponse {
  connection: DtoOrgConnection;
}

export interface DtoOrgDailySummary {
  connectionCount: number;
  date: string;
  id: string;
  orgId: string;
  previousSessionCount: number;
  sessionCount: number;
  sessionSeconds: number;
  slackEngagement: number;
}

export interface DtoOrgDailySummaryListResponse {
  summaries: DtoOrgDailySummary[];
  upcomingEventsCount: number;
}

export interface DtoOrgSessionSecondsResponse {
  seconds: number;
}

export interface DtoOrgSubscription {
  amount?: number | null;
  billingInterval?: EnumsProductBillingInterval | null;
  cancelAt?: string | null;
  currentPeriodEnd?: string | null;
  currentPeriodStart?: string | null;
  /** stripe ids. these were existing, so to avoid a migration, the names are kept the same. */
  customerId?: string | null;
  discount?: DtoDiscount | null;
  features: ModelsProductFeature[];
  paymentMethod?: DtoPaymentMethod | null;
  /** avoid using. we'll keep this for backward compatibility, but eventually remove some enum variants. */
  plan: EnumsOrgSubscriptionPlan;
  priceId?: string | null;
  /** these are LP ids. */
  productId?: string | null;
  productName?: string | null;
  status?: EnumsOrgSubscriptionStatus | null;
  subscriptionId?: string | null;
  trialEnd?: string | null;
  trialPeriodDays?: number | null;
  /** the last user who operated the subscription. */
  uid?: string | null;
  upgradedAt?: string | null;
}

export interface DtoOrganization {
  canTrial: boolean;
  connection: DtoOrgConnectionBase;
  createdAt: string;
  gameSessionsCount: number;
  id: string;
  logo?: CommonMedia | null;
  maxSize: number;
  name: string;
  organizersCount: number;
  pairingDisabledParticipantsCount: number;
  purchase?: ModelsOrgPurchase | null;
  settings?: ModelsOrgSettings | null;
  slug: string;
  subscription: DtoOrgSubscription;
  totalPlayerSessionsCount: number;
  updatedAt: string;
  usesSlack: boolean;
}

export interface DtoOrganizer {
  activated: boolean;
  activatedAt: string;
  createdAt: string;
  email: string;
  exConnectType: EnumsExConnectType;
  exUserId: string;
  firstName: string;
  icon: string;
  invitedAt: string;
  lastName: string;
  orgId: string;
  organization: DtoOrganization;
  pairingDisabled: boolean;
  role: ModelsOrganizerRole;
  uid: string;
  updatedAt: string;
  venueId: string;
  venueName: string;
}

export interface DtoOrganizerListResponse {
  organizers: DtoOrganizer[];
  organizersCount: number;
  paging: CommonPaging;
}

export interface DtoOverrideCoordinatorPolicyRequest {
  coordinatorPolicyOverride?: EnumsCoordinatorPolicy | null;
}

export interface DtoPage {
  background?: ModelsMediaAsset | null;
  rows?: DtoPageRow[] | null;
  sections?: DtoPageSection[] | null;
}

export interface DtoPageRow {
  anchor: string;
  id: string;
  layout?: EnumsPageRowLayout | null;
  tag?: DtoTag | null;
  tagCollection?: DtoTagCollection | null;
  type: EnumsPageRowType;
}

export interface DtoPageSection {
  anchor: string;
  hideFromNav: boolean;
  icon?: ModelsMediaAsset | null;
  id: string;
  layoutStyle: EnumsPageSectionLayoutStyle;
  rows?: DtoPageRow[] | null;
  showDemoButton: boolean;
  showSocialProof: boolean;
  subtitle: string;
  title: string;
  type: EnumsPageSectionType;
  url?: ModelsPageSectionUrl | null;
  video?: ModelsMediaAsset | null;
  videoText: string;
}

export interface DtoPagination {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export interface DtoPairing {
  additionalSessions: DtoSession[];
  createdAt: string;
  globalRoundId: string;
  id: string;
  isGameCompleted: boolean;
  lastPlayedAt: string;
  mainSession: DtoSession;
  organizerUid: string;
  players: DtoPairingPlayer[];
  rank: number;
  round: DtoPairingRound;
  roundId: string;
  score: number;
  teamId: string;
  teamName: string;
  updatedAt: string;
}

export interface DtoPairingGame {
  asLevels: boolean;
  completedGamePackIds: string[];
  /** main game pack + shadow/level game packs */
  gamePacks: DtoGamePack[];
  isGameCompleted: boolean;
  mainGamePack: DtoGamePack;
  nextGamePack: DtoGamePack;
  pairingId: string;
  roundId: string;
  score: number;
  teamId: string;
  teamName: string;
  venueId: string;
  venueName: string;
}

export interface DtoPairingParticipationDetailResponse {
  items: DtoPublicPairingParticipation[];
}

export interface DtoPairingParticipationStats {
  optedIn: number;
  optedOut: number;
}

export interface DtoPairingParticipationStatsResponse {
  stats: DtoPairingParticipationStats;
}

export interface DtoPairingPlayer {
  createdAt: string;
  globalRoundId: string;
  isGameCompleted: boolean;
  organizer: DtoOrganizer;
  pairingId: string;
  roundId: string;
  sessionId: string;
  uid: string;
  updatedAt: string;
}

export interface DtoPairingRepairLog {
  createdAt: string;
  data: ModelsPairingRepairData;
  globalRoundId: string;
  id: string;
  roundId: string;
}

export interface DtoPairingRepairLogsResponse {
  logs: DtoPairingRepairLog[];
}

export interface DtoPairingRound {
  channel: DtoChannel;
  channelId: string;
  completedPairingsCount: number;
  completedPlayersCount: number;
  createdAt: string;
  endedAt: string;
  globalRound: DtoGlobalPairingRound;
  globalRoundId: string;
  id: string;
  lastSessionPlayedAt: string;
  mainGamePack: DtoGamePack;
  orgId: string;
  orgScore: number;
  organization: DtoOrganization;
  pairingsCount: number;
  playersCount: number;
  startedAt: string;
  status: ModelsPairingRoundStatus;
  updatedAt: string;
}

export interface DtoPatchNotificationSettingsRequest {
  analyticsDigestEmail?: boolean;
  analyticsDigestSlack?: boolean;
  disabled?: boolean;
  emailFrequency?: EnumsNotificationFrequencyEmail;
  gameRecapsSlack?: boolean;
  notInterestedProgramIds?: string[];
  onboardingGuides?: boolean;
  pendingPurchaseReminder?: boolean;
  recognitionGiven?: boolean;
  recognitionReceived?: boolean;
  recognitionWeeklyReport?: boolean;
  slackFrequency?: EnumsNotificationFrequencySlack;
}

export interface DtoPatchSettingsRequest {
  groupKey?: EnumsUserSettingsGroupKey;
  uid?: string;
  value?: any;
}

export interface DtoPaymentMethod {
  card?: StripePaymentMethodCard | null;
  email: string;
  id: string;
  type: StripePaymentMethodType;
}

export interface DtoPersonality {
  avatarId?: string | null;
  /** a description of the personality. */
  description: string;
  /** a display label like "John (Voice Only)" */
  displayLabel: string;
  id: string;
  /** the name of this personality "Sally", "Linus", "Chuck", etc. */
  name: string;
  profileImage?: ModelsMediaAsset | null;
  /** used for spark personality select in the editor. */
  publiclyListed: boolean;
  voiceId: string;
}

export interface DtoPingResponse {
  pong: boolean;
  private: boolean;
  timestampMs: number;
}

export interface DtoPlaybackConfig {
  shouldInjectMarketingBlocks: boolean;
}

export interface DtoPlayedBlockEntry {
  blockId: string;
  blockType: EnumsBlockType;
  brandId?: string | null;
  brandName?: string | null;
  playbackSummary?: DtoPlayedBlockEntryPlaybackSummary | null;
  uid: string;
}

export interface DtoPlayedBlockEntryPlaybackSummary {
  playbackItemDurationSec: number;
  playbackItemInjected: boolean;
  playbackItemScenario?: string | null;
  playbackItemsIndex: number;
  playbackItemsLength: number;
  sessionProgressSec: number;
}

export interface DtoPlayer {
  name: string;
  uid: string;
}

export interface DtoPreSignedUrlRequest {
  key: string;
}

export interface DtoPrepareTokenExchangeRequest {
  platform: EnumsTokenExchangePlatform;
  platformUid: string;
}

export interface DtoPrepareTokenExchangeResponse {
  code: string;
}

export interface DtoProducersResponse {
  producers: ModelsLiveEventProducer[];
}

export interface DtoProduct {
  createdAt: string;
  description?: string | null;
  features: DtoProductFeature[];
  id: string;
  name: string;
  prices?: ModelsPrice[] | null;
  status: EnumsProductStatus;
  stripeProductId?: string | null;
  stripeProductUrl?: string | null;
  trialPeriodDays?: number | null;
  updatedAt: string;
}

export interface DtoProductDefaultsResponse {
  defaultProductId?: string | null;
  demoProductId?: string | null;
  liveProductId?: string | null;
  mostPopularProductId?: string | null;
  smallHeadCountPaidProductId?: string | null;
}

export interface DtoProductFeature {
  createdAt: string;
  description?: string | null;
  featureSettings: ModelsFeatureSettings;
  id: string;
  name: string;
  productId: string;
  type: EnumsProductFeatureType;
  updatedAt: string;
}

export interface DtoProductFeatureResponse {
  productFeature: DtoProductFeature;
}

export interface DtoProductFeaturesResponse {
  productFeatures: DtoProductFeature[];
}

export interface DtoProductResponse {
  product: DtoProduct;
}

export interface DtoProductsResponse {
  archived: DtoProduct[];
  defaultProductId?: string | null;
  drafts: DtoProduct[];
  marketed: DtoProduct[];
  published: DtoProduct[];
}

export interface DtoProgram {
  active: boolean;
  basicSettings?: ModelsProgramBasicSettings | null;
  cadenceSettings?: ModelsProgramCadenceSettings | null;
  createdAt: string;
  extensions?: any;
  groupSettings?: ModelsProgramGroupSettings | null;
  /** For backward compatibility */
  hint?: string | null;
  id: string;
  marketingMetadata?: ModelsProgramMarketingMetadata | null;
  name: string;
  preparing: boolean;
  promotionalAssets?: ModelsProgramPromotionalAssets | null;
  tagSettings?: DtoProgramTagSettings | null;
  type: EnumsProgramType;
  updatedAt: string;
}

export interface DtoProgramResponse {
  program: DtoProgram;
}

export interface DtoProgramRound {
  createdAt: string;
  endedAt?: string | null;
  extensions?: any;
  id: string;
  label?: string | null;
  parentId: string;
  parentType: EnumsProgramRoundParentType;
  startedAt?: string | null;
  status: EnumsProgramRoundStatus;
  tags?: DtoTag[] | null;
  updatedAt: string;
}

export interface DtoProgramRoundGroup {
  createdAt: string;
  data: DtoProgramRoundGroupData;
  engaged: boolean;
  id: string;
  linkId: string;
  orgId: string;
  roundId: string;
  sessionId?: string | null;
  updatedAt: string;
}

export interface DtoProgramRoundGroupData {
  engageConfirmed: boolean;
  players: DtoProgramRoundGroupPlayer[];
  sessionIds: string[];
  sessions?: DtoSession[] | null;
  talked: boolean;
}

export interface DtoProgramRoundGroupPlayer {
  organizer: DtoOrganizer;
  uid: string;
  venueOpenedAt: string;
}

export interface DtoProgramRoundGroupsResponse {
  groups: DtoProgramRoundGroup[];
}

export interface DtoProgramRoundResponse {
  programRound: DtoProgramRound;
}

export interface DtoProgramRoundsResponse {
  programRounds: DtoProgramRound[];
}

export interface DtoProgramSummaryCelebrations {
  birthdayCelebratedCount: number;
  datesCelebratedCount: number;
  responsesCount: number;
  workAnniversaryCelebratedCount: number;
}

export interface DtoProgramSummaryGlobalPairing {
  pairingCount: number;
  roundsCount: number;
  sessionsCount: number;
}

export interface DtoProgramSummaryIntros {
  completedIntrosCount: number;
  introsCount: number;
  roundsCount: number;
}

export interface DtoProgramSummaryRecognition {
  givenCount: number;
  giversCount: number;
  receiversCount: number;
}

export interface DtoProgramSummaryWaterCooler {
  pastRoundsCount: number;
  responsesCount: number;
}

export interface DtoProgramTagSettings {
  selectedTagIds?: number[] | null;
  selectedTags?: DtoTag[] | null;
}

export interface DtoProgression {
  blockOutputs?: ModelsBlockOutputMap | null;
  completedAt?: string | null;
  createdAt: string;
  gamePackId: string;
  id: string;
  progress?: ModelsMinigameProgressionMap | null;
  uid: string;
  updatedAt: string;
}

export interface DtoPromotion {
  channel: DtoChannel;
  channelId: string;
  channelOrgSummary: DtoChannelOrgSummary;
  createdAt: string;
  globalPromotion: DtoGlobalPromotion;
  globalPromotionId: string;
  id: string;
  orgId: string;
  organization: DtoOrganization;
  status: EnumsPromotionStatus;
  updatedAt: string;
}

export interface DtoPromotionCode {
  code: string;
  coupon: DtoCoupon;
  id: string;
}

export interface DtoPromotionCodeListResponse {
  promotionCodes: DtoPromotionCode[];
}

export interface DtoPromotionHistory {
  campaign: EnumsPromotionCampaign;
  createdAt: string;
  id: string;
  medium: EnumsPromotionMedium;
  organization?: DtoOrganization;
  recipient?: DtoOrganizer;
}

export interface DtoPromotionHistoryListResponse {
  histories: DtoPromotionHistory[];
}

export interface DtoPromotionListResponse {
  promotions: DtoPromotion[];
}

export interface DtoPromptTemplate {
  createdAt: string;
  functions?: ModelsFunctionDefinition[] | null;
  id: string;
  messages?: ModelsPromptMessage[] | null;
  model: string;
  name: string;
  owner?: DtoPublicUser | null;
  ownerType: EnumsPromptTemplateOwnerType;
  provider: string;
  settings?: ModelsPromptTemplateSettings | null;
  systemPrompt: string;
  type: EnumsPromptTemplateType;
  uid: string;
  updatedAt: string;
  variables?: ModelsVariableDefinition[] | null;
  vendorObjectId?: string | null;
}

export interface DtoPromptTemplateListResponse {
  promptTemplates: DtoPromptTemplate[];
}

export interface DtoPromptTemplateResponse {
  promptTemplate: DtoPromptTemplate;
}

export type DtoPropagateUGCSettingsRequest = object;

export interface DtoPropagateUGCSettingsResponse {
  numOfAffectedPacks: number;
}

export interface DtoPublicCloudInstanceBinding {
  controller: DtoController;
  createdAt: string;
  id: string;
  state: string;
  updatedAt: string;
}

export interface DtoPublicEvent {
  endAt: string;
  hostName?: string | null;
  hostUid?: string | null;
  id: string;
  orgId?: string | null;
  orgName?: string | null;
  packId: string;
  startAt: string;
  timezone: string;
  type: EnumsEventType;
  venueId: string;
  venueName?: string | null;
  vipOnStage: boolean;
}

export interface DtoPublicEventResponse {
  event: DtoPublicEvent;
}

export interface DtoPublicOrganization {
  id: string;
  logo?: CommonMedia | null;
  name: string;
  seatCapped: boolean;
  slug: string;
}

export interface DtoPublicPairingParticipation {
  createdAt: string;
  exUserId: string;
  globalRoundId: string;
  id: string;
  organizer?: DtoOrganizer;
  roundId: string;
  status: EnumsPairingParticipationStatus;
  suggestedUsers?: ClientUserDto[];
  suggestions?: ModelsPairingPartnerSuggestions;
  updatedAt: string;
}

export interface DtoPublicUser {
  id: string;
  username: string;
}

export interface DtoPublishGamePackRequest {
  aiHostSettings?: ModelsAIHostSettings;
  approximateDurationSeconds?: number;
  childrenIds?: string[];
  cohostSettings?: ModelsCohostSettings;
  coverMediaId?: string;
  description?: string;
  detailSettings?: ModelsDetailSettings;
  extraSettings?: ModelsExtraSettings;
  featured?: boolean;
  free?: boolean;
  id?: string;
  logicSettings?: ModelsLogicSettings;
  marketingSettings?: ModelsMarketingSettings;
  name?: string;
  playbackSettings?: ModelsPlaybackSettings;
  playerRange?: ModelsPlayerRange;
  promotionalAssets?: DtoUpdateGamePackPromotionalAssets;
  replayable?: boolean;
  tags?: string[];
  teamRandomizationSettings?: ModelsTeamRandomizationSettings;
  ugcSettings?: ModelsUgcSettings;
  uid?: string;
  venueBackgroundMediaData?: ModelsMediaData;
}

export interface DtoQueryGroupedGamePacksByTagsResponse {
  groupedGamePacks: Record<string, DtoGamePack[]>;
}

export interface DtoQueryMessageLogicsRequest {
  campaignId: string;
}

export interface DtoQueryProgramsSummaryResponse {
  celebrations: DtoProgramSummaryCelebrations;
  globalPairing: DtoProgramSummaryGlobalPairing;
  intros: DtoProgramSummaryIntros;
  recognition: DtoProgramSummaryRecognition;
  waterCooler: DtoProgramSummaryWaterCooler;
}

export interface DtoQueryRecognitionExGroupsResponse {
  groups: DtoRecognitionExGroup[];
}

export interface DtoQueryRecognitionSummaryResponse {
  items: DtoRecognitionSummaryItem[];
}

export interface DtoQuestion {
  createdAt: string;
  id: string;
  text: string;
  thoughtStarters?: DtoThoughtStarter[];
  ttsMedia?: CommonMedia;
  updatedAt: string;
}

export interface DtoQuestionReply {
  createdAt: string;
  id: string;
  interviewId: string;
  questionId: string;
  replyMedia: CommonMedia;
  status: EnumsQuestionReplyStatus;
  summary?: string;
  transcript?: string;
  updatedAt: string;
}

export interface DtoRecognitionExGroup {
  id: string;
  name: string;
}

export interface DtoRecognitionProgramLinkExtensions {
  adminDailyGivingAmount: number;
  emoji?: ModelsRecognitionEmoji | null;
  initExGroupId: string;
  membersDailyGivingAmount: number;
}

export interface DtoRecognitionSummaryItem {
  amount: number;
  member?: DtoChannelMember | null;
  uid: string;
}

export interface DtoRecommendCourseCandidate {
  active: boolean;
  cover: CommonMedia;
  description: string;
  name: string;
  templateId: string;
}

export interface DtoRecommendEventAttendeesRequest {
  uid: string;
}

export interface DtoRecommendEventAttendeesResponse {
  results: DtoOrganizer[][];
}

export interface DtoRecommendGamePacksRequest {
  currentPlayedGamePackId?: string;
  scenario: EnumsGamePackRecommendationScenario;
}

export interface DtoRecommendGamePacksResponse {
  gamePacks: DtoGamePack[];
}

export interface DtoRecommendTrainingCourseCoverImagesRequest {
  description: string;
  instruction?: string;
  name: string;
}

export interface DtoRecommendTrainingCourseCoverImagesResponse {
  images: ClientGeneratedImage[];
}

export interface DtoRecommendTrainingCoursesRequest {
  formResponse?: DtoFormResponse | null;
  prompt: string;
  templateId?: string;
}

export interface DtoRecommendTrainingCoursesResponse {
  candidates: DtoRecommendCourseCandidate[];
}

export interface DtoRegisterRequest {
  activate?: boolean;
  /**
   * @minLength 1
   * @maxLength 60
   */
  email: string;
  firstName?: string | null;
  fromGuest?: boolean;
  lastName?: string | null;
  organizationName?: string;
  queries?: Record<string, string>;
  timezone?: string | null;
  useEmailAsOrgName?: boolean;
}

export interface DtoRenderBlocksRequest {
  blockIds: string[];
}

export interface DtoRenderMemoriesSouvenirRequest {
  sessionId: string;
  teamId: string;
  templateId: string;
  useParticipantTeamId?: boolean | null;
}

export interface DtoRenderSlackBlocksResponse {
  blocks: string;
}

export interface DtoReorderStackCourseItem {
  courseId: string;
  sequenceOrder: number;
}

export interface DtoReorderStackCoursesRequest {
  courses: DtoReorderStackCourseItem[];
}

export interface DtoReportTestResultRequest {
  email: string;
  from: string;
  id: string;
  isGameCoordinator?: boolean | null;
  orgId?: string | null;
  results: DtoTestResult[];
  source: EnumsWebRTCTestSource;
}

export interface DtoRequestLiveEventRequest {
  attendees: string[];
  gamePackId: string;
  hostShoutOut: string;
  startAt: string;
  timezone: string;
}

export type DtoResendInviteEmail = object;

export interface DtoRoleplayHistory {
  analysis: ModelsRoleplayHistoryAnalysis;
  blockId: string;
  blockName: string;
  endedAt: string;
  evaluation: ModelsRoleplayHistoryEvaluation;
  feedback: ModelsRoleplayHistoryFeedback;
  id: string;
  instructions: string;
  isReplay: boolean;
  rating: ModelsRoleplayRating;
  startedAt: string;
  transcript: ModelsRoleplayHistoryTranscript[];
  userId: string;
  userName: string;
}

export interface DtoRoleplayHistoryListResponse {
  histories: DtoRoleplayHistory[];
}

export interface DtoRoleplayHistoryResponse {
  history: DtoRoleplayHistory;
}

export interface DtoScoreboardEntry {
  prevScore: number;
  score: number;
  teamId: string;
}

export interface DtoSearchDealRequest {
  limit: number;
  query: string;
}

export interface DtoSearchDealResponse {
  deals: DtoHubSpotDeal[];
  paging: HubspotPaging;
}

export interface DtoSearchDocumentResponse {
  additionalProperties?: Record<string, any>;
  docs: ClientHitDocument[];
}

export interface DtoSearchHubSpotProductRequest {
  limit: number;
  query: string;
}

export interface DtoSearchHubSpotProductResponse {
  paging: HubspotPaging;
  products: HubspotProduct[];
}

export interface DtoSearchUserCreatedCoursesResponse {
  courses: DtoCourseSearchResult[];
}

export interface DtoSearchUserCreatedStacksResponse {
  stacks: DtoStackSearchResult[];
}

export interface DtoSelectBestMatchMediaRequest {
  keyword: string;
  limit: number;
  promptTemplateId: string;
  provider: EnumsExternalMediaProvider;
  sessionId: string;
}

export interface DtoSelectBestMatchMediaResponse {
  results: DtoSelectBestMatchMediaResult[];
}

export interface DtoSelectBestMatchMediaResult {
  mediaItem: DtoExternalMediaItem;
  rating: SchemaSelectBestMatchMediaRating;
}

export interface DtoSendAnalyticsDigestRequest {
  endTime: string;
  isDebug: boolean;
  limit: number;
  orgId: string;
  recipientId: string;
  startTime: string;
}

export interface DtoSendGameUpdatesRequest {
  gamePackIds: string;
  recipientId: string;
}

export interface DtoSendMarkdownEmailRequest {
  notificationSettingsLink?: string | null;
  recipientId: string;
  template: ModelsMessageTemplateEmail;
}

export interface DtoSendMarkdownSlackMessageRequest {
  recipientId: string;
  template: ModelsMessageTemplateSlack;
}

export interface DtoSendMessageTemplateRequest {
  mediaVars: Record<string, CommonMedia>;
  metadata?: ClientMessageMetadata | null;
  recipientId: string;
  template: ModelsMessageTemplateV2;
  vars: Record<string, any>;
}

export interface DtoSendProgramPromotionRequest {
  programId: string;
  recipientId: string;
}

export interface DtoSendTrainingEditorMessageRequest {
  fileIds?: string[] | null;
  packId: string;
  text: string;
}

export interface DtoSendTrainingEditorMessageResponse {
  block: DtoBlock;
  messages: DtoTrainingEditorMessage[];
}

export interface DtoSentimentMetadata {
  contentType?: string;
  expression: string;
  frameRate: number;
  height: number;
  maxStrips: number;
  score: number;
  width: number;
}

export interface DtoSession {
  cohostUid?: string | null;
  durationSeconds: number;
  endedAt: string;
  gameId: string;
  gameName: string;
  gamePack?: DtoGamePack | null;
  gamePackId: string;
  gamePackName: string;
  groupPhoto: DtoGroupPhoto;
  hostName: string;
  id: string;
  isGameCompleted: boolean;
  isInternalGame: boolean;
  isRepaired: boolean;
  maxPlayers: number;
  maxScore: number;
  mode: ModelsSessionMode;
  onDemandControllerKind: string;
  orgId: string;
  organizationName: string;
  organizer?: DtoOrganizer | null;
  originalOrgId: string;
  platform?: EnumsPlatform | null;
  startedAt: string;
  status: ModelsSessionStatus;
  timeZone: string;
  uid: string;
  uids: string[];
  venueId: string;
}

export interface DtoSessionListResponse {
  paging: CommonPaging;
  sessions: DtoSession[];
}

export interface DtoSessionMemories {
  endedAt: string;
  eventId: string;
  gamePack: DtoGamePack;
  id: string;
  mode: ModelsSessionMode;
  organization: DtoOrganization;
  pairingId: string;
  pairingRoundId: string;
  scoreboard: DtoSessionScoreboard;
  startedAt: string;
  status: ModelsSessionStatus;
  uid: string;
  venueId: string;
}

export interface DtoSessionMemoriesResponse {
  session: DtoSessionMemories;
}

export interface DtoSessionParticipant {
  id: string;
  joyCapture?: DtoJoyCapture | null;
  name: string;
  uid: string;
}

export interface DtoSessionResponse {
  session: DtoSession;
}

export interface DtoSessionScoreboard {
  participants: Record<string, DtoTeamMember>;
  scores: DtoSessionScoreboardEntry[];
}

export interface DtoSessionScoreboardEntry {
  rank?: number;
  score?: number;
  teamId: string;
  teamMembers: string[];
  teamName: string;
}

export interface DtoSessionSnapshotItem {
  data: any;
  key: FirebaseDataItemKey;
  remote: boolean;
}

export interface DtoSessionVoiceOverAudit {
  blockId: string;
  createdAt: string;
  gameState: string;
  id: string;
  initiatedAt: string;
  media?: CommonMedia;
  mediaId: string;
  renderDescription: string;
  script: string;
  sequence: number;
  sessionId: string;
  uid: string;
  updatedAt: string;
}

export interface DtoSessionVoiceOverAuditsResponse {
  voiceOverAudits?: DtoSessionVoiceOverAudit[];
}

export interface DtoSettings {
  createdAt: string;
  data: string;
  description: string;
  name: EnumsSettingsName;
  updatedAt: string;
}

export interface DtoSettingsResponse {
  settings: DtoSettings;
}

export interface DtoShareMessageRequest {
  exGroupId: string;
  mediaList: ClientMessageMedia[];
  text: string;
}

export interface DtoSharedAsset {
  createdAt: string;
  data?: ModelsJSONObject | null;
  id: string;
  jobStatus: EnumsSharedAssetFanOutJobStatus;
  label: string;
  linksCount: number;
  media: CommonMedia;
  mediaId: string;
  prevMediaId: string;
  purpose: EnumsSharedAssetPurpose;
  updatedAt: string;
}

export interface DtoSharedAssetListResponse {
  paging: CommonPaging;
  sharedAssets: DtoSharedAsset[];
}

export interface DtoSharedAssetObjectLink {
  objectId: string;
  objectType: EnumsRefObjectType;
  refId: string;
  updatedAt: string;
}

export interface DtoSharedAssetObjectLinkListResponse {
  blocks: DtoBlock[];
  gamePacks: DtoGamePack[];
  links: DtoSharedAssetObjectLink[];
}

export interface DtoSharedAssetResponse {
  sharedAsset: DtoSharedAsset;
}

export interface DtoSignupRequest {
  email: string;
  /**
   * @minLength 8
   * @maxLength 20
   */
  password: string;
  /**
   * @minLength 3
   * @maxLength 25
   */
  username: string;
}

export interface DtoSimpleStripePrice {
  active: boolean;
  created: number;
  currency: string;
  description: string;
  id: string;
  recurring: StripePriceRecurring;
  type: StripePriceType;
  unit_amount: number;
}

export interface DtoSingleBrandResponse {
  brand: DtoBrand;
}

export interface DtoSingleCelebrationResponse {
  celebration: DtoCelebration;
}

export interface DtoSingleDrawingResponse {
  drawing: DtoDrawing;
}

export interface DtoSingleGamePackResponse {
  blockPlayedHistory?: Record<string, DtoBlockPlayedSnapshot[]>;
  blocks?: DtoBlock[];
  brands?: DtoBrand[];
  gamePack: DtoGamePack;
  games?: DtoGame[];
  linkedSharedAssets?: DtoSharedAsset[];
  personalities?: Record<string, DtoPersonality>;
  playbackConfig?: DtoPlaybackConfig;
  progression?: DtoProgression;
}

export interface DtoSingleGameResponse {
  game: DtoGame;
}

export interface DtoSingleGlobalPairingRoundResponse {
  globalRound: DtoGlobalPairingRound;
}

export interface DtoSingleGlobalPromotionResponse {
  globalPromotion: DtoGlobalPromotion;
}

export interface DtoSingleInquiryResponse {
  inquiry: DtoInquiry;
}

export interface DtoSingleInterviewResponse {
  interview: DtoInterview;
}

export interface DtoSingleMediaResponse {
  media: CommonMedia;
}

export interface DtoSingleMessageCampaignResponse {
  campaign: DtoMessageCampaign;
}

export interface DtoSingleMessageLogicResponse {
  logic: DtoMessageLogic;
}

export interface DtoSingleMessageTemplateResponse {
  messageTemplate: DtoMessageTemplate;
}

export interface DtoSingleOrganizationResponse {
  organization: DtoOrganization;
}

export interface DtoSingleOrganizerResponse {
  organizer: DtoOrganizer;
}

export interface DtoSinglePairingResponse {
  pairing: DtoPairing;
}

export interface DtoSinglePairingRoundResponse {
  round: DtoPairingRound;
}

export interface DtoSingleProgressionResponse {
  progression: DtoProgression;
}

export interface DtoSinglePublicOrganizationResponse {
  organization: DtoPublicOrganization;
}

export interface DtoSingleTagResponse {
  tag: DtoTag;
}

export interface DtoSingleTrainingProfileResponse {
  profile: ModelsTrainingProfile;
}

export interface DtoSingleVenueResponse {
  venue: DtoVenue;
}

export interface DtoSlackAddUsersToChannelRequest {
  channelId: string;
  orgId: string;
  userIds: string[];
}

export interface DtoSlackChannelListResponse {
  channels: ClientChannelDto[];
  newUsersByChannelId: Record<string, DtoSlackUser[]>;
}

export interface DtoSlackCreateChannelRequest {
  inviteLunaPark?: boolean | null;
  inviteOperator?: boolean | null;
  name: string;
  uid?: string;
}

export interface DtoSlackCreateChannelResponse {
  channel: ClientChannelDto;
  isNewCreated: boolean;
}

export interface DtoSlackInstallCallbackResponse {
  connection: DtoOrgConnection;
}

export interface DtoSlackInstallationResponse {
  installation: ClientInstallationDto;
}

export interface DtoSlackRegisterRequest {
  code: string;
  queries?: Record<string, string>;
  state: string;
  timezone?: string | null;
}

export interface DtoSlackSocketModeConnection {
  connected: boolean;
  env: string;
}

export interface DtoSlackSocketModeConnectionsResponse {
  connections: DtoSlackSocketModeConnection[];
}

export interface DtoSlackUser {
  displayName: string;
  email: string;
  fullName: string;
  icon?: string | null;
  id: string;
  organizer?: DtoOrganizer;
  teamId: string;
  user?: DtoUser;
}

export interface DtoSlackUserListResponse {
  users: DtoSlackUser[];
}

export interface DtoSparkifactFile {
  key: string;
  lastModified: string;
  size: number;
}

export interface DtoSparkifactFilesResponse {
  files: DtoSparkifactFile[];
}

export interface DtoStackCourseItem {
  courseId: string;
  courseName: string;
  cover: DtoCover;
  sequenceOrder: number;
}

export interface DtoStackDetailResponse {
  assignedGroups: DtoGroupEnrollmentDetail[];
  assignedMembers: DtoAssignedStackMember[];
  courses: DtoStackCourseItem[];
  id: string;
  name: string;
}

export interface DtoStackSearchResult {
  createdAt: string;
  id: string;
  name: string;
}

export interface DtoStackWithLearnerCount {
  learnerCount: number;
  stack: ModelsStack;
}

export interface DtoStartProgramGroupGameRequest {
  sessionId: string;
}

export interface DtoStartSessionRequest {
  cohostUid: string;
  eventId: string;
  gamePackId: string;
  mode: ModelsSessionMode;
  orgId: string;
  pairingId: string;
  platform: EnumsPlatform;
  sessionId?: string;
  startedAt: number;
  timeZone: string;
  uid?: string;
  venueId?: string;
}

export interface DtoStudiosResponse {
  studios: ModelsLiveEventStudio[];
}

export interface DtoSubmitOrgSubscriptionCancelReasonRequest {
  reason: string;
}

export interface DtoSubmitRoleplayRatingRequest {
  rating: ModelsRoleplayRating;
}

export interface DtoSummarizedInterview {
  createdAt: string;
  id: string;
  summary: string;
  uid: string;
  updatedAt: string;
  userName: string;
}

export interface DtoTTSAuditMetadata {
  blockId?: string | null;
  sequence?: number | null;
  sessionId?: string | null;
}

export interface DtoTTSGenerator {
  displayName: string;
  id: EnumsTTSGeneratorId;
}

export interface DtoTTSGeneratorList {
  generators: DtoTTSGenerator[];
}

export interface DtoTTSGeneratorModelSettings {
  displayName: string;
  id: string;
}

export interface DtoTTSGeneratorSettings {
  displayName: string;
  id: EnumsTTSGeneratorId;
  models: DtoTTSGeneratorModelSettings[];
  voices: DtoTTSGeneratorVoiceSettings[];
}

export interface DtoTTSGeneratorVoiceSettings {
  displayName: string;
  id: string;
}

export interface DtoTTSRenderRequest {
  audit?: boolean | null;
  auditMetadata?: DtoTTSAuditMetadata | null;
  cacheControl?: EnumsTTSCacheControl;
  /** if present, the voice settings attached to this locale will be used instead of the voiceId or settings. */
  locale?: string | null;
  /** the shared media id for this LP personality. note that using a personality id will ignore the voiceId and locale. */
  personalityId?: string | null;
  policy?: EnumsTTSRenderPolicy;
  script: string;
  /** the settings to use for rendering the script. these may be omitted when VoiceId is provided. */
  ttsRenderSettings?: ModelsTTSRenderSettings | null;
  /** the shared media id for this LP voice. this can be used instead of explicitly passing ttsRenderSettings. */
  voiceId?: string | null;
}

export interface DtoTTSRenderResponse {
  media: CommonMedia;
}

export interface DtoTTSRenderRuntimeSampleRequest {
  delayStartMs?: number | null;
  gameState?: DtoGameStateForVoiceOver | null;
  generatorId: EnumsTTSGeneratorId;
  noVariables?: boolean | null;
  script: string;
  settings?: ModelsVoiceOverRenderSettings | null;
  voiceId: string;
  /** relevant for runtime generated media. */
  volumeLevel?: EnumsVolumeLevel | null;
}

export interface DtoTTSResolveCodeFencesRequest {
  script: string;
}

export interface DtoTTSResolveCodeFencesResponse {
  script: string;
}

export interface DtoTag {
  background?: ModelsMediaAsset | null;
  description?: string | null;
  extensions: DtoTagExtensions[];
  icon?: ModelsMediaAsset | null;
  id: number;
  name: string;
  shortAlias?: string | null;
  slug: string;
  styles?: ModelsTagStyles | null;
  supportingMedia?: ModelsMediaAsset | null;
}

export interface DtoTagCollection {
  ids?: string[] | null;
  name: string;
  tags?: DtoTag[] | null;
}

export interface DtoTagExtensions {
  featuredObjectIds: string[];
  objectType: number;
  objectsCount: number;
}

export interface DtoTagListResponse {
  paging: CommonPaging;
  tags: DtoTag[];
}

export interface DtoTeam {
  id: string;
  name: string;
  players: DtoPlayer[];
}

export interface DtoTeamMember {
  id: string;
  joyCapture: DtoJoyCapture;
  name: string;
  uid: string;
}

export interface DtoTestResult {
  channelName: string;
  data: any[];
  endedAt: string;
  id: string;
  logs: any[];
  startedAt: string;
  succeeded: boolean;
}

export interface DtoThoughtStarter {
  createdAt: string;
  id: string;
  text: string;
  updatedAt: string;
}

export interface DtoToggleOrgGameUpdatesRequest {
  orgId: string;
  pauseDEI?: boolean | null;
  pauseGameUpdatesEmail?: boolean | null;
  pauseGameUpdatesSlack?: boolean | null;
}

export interface DtoToggleOrganizersPairingStatusRequest {
  disabledUids: string[];
  enabledUids: string[];
}

export interface DtoTopContributor {
  firstName: string;
  joyCapture?: DtoJoyCapture | null;
  uid: string;
}

export interface DtoTrackMessageRequest {
  bizId: string;
  bizLabel: string;
  messages: DtoTrackableMessage[];
  promptTemplateId: string;
}

export interface DtoTrackPlayedBlockRequest {
  entries: DtoPlayedBlockEntry[];
}

export interface DtoTrackProgressionBlockPlayedRequest {
  blockId: string;
  blockType: EnumsBlockType;
  minigameId: string;
  outputs?: Record<string, ModelsBlockOutput>;
}

export interface DtoTrackableMessage {
  body: Record<string, any>;
  createdAt: number;
  messageId: string;
  senderDisplayName: string;
  senderUid: string;
}

export interface DtoTrainingCourseGroup {
  blocks: DtoGeneratedBlock[];
  name: string;
}

export interface DtoTrainingEditorMessage {
  blockFields?: any;
  fileIds?: string[] | null;
  id: string;
  role: string;
  text: string;
}

export interface DtoTrainingEditorMessagesResponse {
  messages: DtoTrainingEditorMessage[];
}

export interface DtoTranslationTextRequest {
  sourceLanguageCode: string;
  targetLanguageCode: string;
  text: string;
}

export interface DtoUGCGenerateBlockRequest {
  blockId: string;
  userPrompt: string;
  variables?: Record<string, string>;
}

export interface DtoUGCGenerateBlockResponse {
  runSteps: DtoNarrowedRunStep[];
}

export interface DtoUGCGenerateNameRequest {
  userPrompt: string;
}

export interface DtoUGCGenerateNameResponse {
  name: string;
}

export interface DtoUpdateActiveProgramsRequest {
  programIds: string[];
}

export interface DtoUpdateBlockFieldReq {
  field: string;
  value: any;
}

export interface DtoUpdateBlockRecordingMediaIdReq {
  mediaId: string;
}

export interface DtoUpdateBrandRequest {
  blockIds?: string[];
  blockLengthSec?: number;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name?: string;
  predefinedBlockData?: ModelsPredefinedBlockItem[];
  showcaseMediaData?: ModelsMediaData;
  showcaseText?: string;
  tags?: string[];
}

export interface DtoUpdateEventIntakeFormRequest {
  data: ModelsEventIntakeFormData;
}

export interface DtoUpdateEventIntakeFormStatusRequest {
  status: EnumsIntakeFormStatus;
}

export interface DtoUpdateEventRequest {
  attendeeEmails: string;
  attendees: string[];
  endAt: string;
  eventFirstParagraph: string;
  eventTitle: string;
  gamePackId: string;
  hostShoutOut: string;
  hostUid: string;
  id?: string;
  message: string;
  organizerName: string;
  prepMin: number;
  producerUid: string;
  sendLiveNotify: boolean;
  startAt: string;
  studioId: string;
  timezone: string;
  uid?: string;
  venueId: string;
  vipOnStage: boolean;
}

export interface DtoUpdateFeaturedTagsRequest {
  name: EnumsPageName;
  tagIds: number[];
}

export interface DtoUpdateGameLikeRequest {
  coverMediaId?: string;
  description?: string;
  id?: string;
  name?: string;
  tags?: string[];
  uid?: string;
}

export interface DtoUpdateGamePackCollectionRequest {
  gamePackIds: string[];
}

export interface DtoUpdateGamePackPromotionalAssets {
  cover?: {
    mediaData?: ModelsMediaData;
  };
  description: string;
  pushable: boolean;
  title: string;
}

export interface DtoUpdateGamePackRequest {
  aiHostSettings?: ModelsAIHostSettings;
  approximateDurationSeconds?: number;
  changeLevel: EnumsGamePackChangeLevel;
  changeNote?: string;
  childrenIds?: string[];
  cohostSettings?: ModelsCohostSettings;
  coverMediaId?: string;
  description?: string;
  detailSettings?: ModelsDetailSettings;
  extraSettings?: ModelsExtraSettings;
  featured?: boolean;
  free?: boolean;
  id?: string;
  logicSettings?: ModelsLogicSettings;
  marketingSettings?: ModelsMarketingSettings;
  name?: string;
  playbackSettings?: ModelsPlaybackSettings;
  playerRange?: ModelsPlayerRange;
  promotionalAssets?: DtoUpdateGamePackPromotionalAssets;
  replayable?: boolean;
  tags?: string[];
  teamRandomizationSettings?: ModelsTeamRandomizationSettings;
  ugcSettings?: ModelsUgcSettings;
  uid?: string;
  venueBackgroundMediaData?: ModelsMediaData;
}

export interface DtoUpdateGamePackTagsRequest {
  tagIds: number[];
}

export interface DtoUpdateGlobalPairingRoundRequest {
  asLevels: boolean;
  endedAt: string;
  mainGamePackId: string;
  messageCampaignId: string;
  shadowGamePackIds: string[];
  startedAt: string;
}

export interface DtoUpdateGroupNameRequest {
  name: string;
}

export interface DtoUpdateHubSpotDealWithCustomPriceRequest {
  lineItemId: string;
  productId: string;
  stripePriceId: string;
}

export interface DtoUpdateHubSpotDealWithCustomPriceResponse {
  paymentLinkUrl: string;
}

export interface DtoUpdateInterviewRequest {
  editedSummary: string;
  interviewId: string;
}

export interface DtoUpdateMarketedProductsRequest {
  productIds: string[];
}

export interface DtoUpdateMessageLogicRequest {
  templateId: string;
}

export interface DtoUpdateMessageLogicV2Request {
  condition?: ModelsMessageCondition | null;
  name?: string | null;
  tags?: string[] | null;
  target?: EnumsMessageTarget | null;
  template?: ModelsMessageTemplateV2 | null;
  trigger?: ModelsMessageTrigger | null;
}

export interface DtoUpdateMessageTemplateRequest {
  body: string;
  id?: string;
  mediaId: string;
  mediaTemplateId: string;
  title: string;
  uid?: string;
}

export interface DtoUpdateMessageTemplateSettingsRequest {
  description?: string | null;
  name?: string | null;
  template?: ModelsMessageTemplateV2;
  vars?: string | null;
}

export interface DtoUpdateNotificationSettingsRequest {
  payload?: string;
  signature?: string;
  value: DtoPatchNotificationSettingsRequest;
}

export interface DtoUpdateOrgConnectionAsSourceOfTruthRequest {
  asSourceOfTruth: boolean;
  sendInviteEmails: boolean;
}

export interface DtoUpdateOrgSettingsRequest {
  allowLiveEventsDuringTrial?: boolean;
  brandColor?: string | null;
  cancelOfferDisabled?: boolean | null;
  enforceSeatCap?: boolean | null;
  nonAdminScheduleLiveEventsDisabled?: boolean;
  onboardingCallScheduled?: boolean | null;
  venueConnectivityTestDisabled?: boolean | null;
}

export interface DtoUpdateOrgSubscriptionPlanRequest {
  priceId?: string | null;
  productId: string;
}

export interface DtoUpdateOrgSubscriptionStatusRequest {
  status: EnumsOrgSubscriptionStatus;
}

export interface DtoUpdateOrganizationCanTrialRequest {
  canTrial: boolean;
}

export interface DtoUpdateOrganizationLogoRequest {
  logoId: string;
}

export interface DtoUpdateOrganizationRequest {
  /** use -1 to unset */
  maxSize: number;
  /** @maxLength 100 */
  name: string;
  /** @maxLength 100 */
  slug: string;
}

export interface DtoUpdateOrganizationUsesSlackRequest {
  usesSlack: boolean;
}

export interface DtoUpdateOrganizerRequest {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  timezone?: string | null;
}

export interface DtoUpdateOutdatedRecordingReq {
  outdatedRecording: boolean;
}

export interface DtoUpdatePairingRequest {
  organizerUid: string;
}

export interface DtoUpdatePairingRoundRequest {
  status: ModelsPairingRoundStatus;
}

export interface DtoUpdatePasswordRequest {
  /**
   * @minLength 8
   * @maxLength 20
   */
  password: string;
}

export interface DtoUpdateProductDefaultsRequest {
  defaultProductId?: string | null;
  demoProductId?: string | null;
  liveProductId?: string | null;
  mostPopularProductId?: string | null;
  smallHeadCountPaidProductId?: string | null;
}

export interface DtoUpdateProductFeatureRequest {
  description?: string;
  featureSettings?: ModelsFeatureSettings;
  name?: string;
  type?: EnumsProductFeatureType;
}

export interface DtoUpdateProductRequest {
  description?: string;
  name?: string;
  prices?: ModelsPrice[];
  trialPeriodDays?: number;
}

export interface DtoUpdateProgramLinkRequest {
  cadenceSettings?: ModelsProgramCadenceSettings | null;
  extensions?: object | null;
  groupSettings?: ModelsProgramGroupSettings | null;
  tagSettings?: ModelsProgramTagSettings | null;
}

export interface DtoUpdateProgramRequest {
  basicSettings?: ModelsProgramBasicSettings;
  extensions?: object;
  marketingMetadata?: ModelsProgramMarketingMetadata;
  name?: string;
  promotionalAssets?: ModelsProgramPromotionalAssets;
  tagSettings?: ModelsProgramTagSettings;
}

export interface DtoUpdateProgramRoundRequest {
  extensions?: object;
  label?: string;
  startedAt?: string;
  status?: EnumsProgramRoundStatus;
  tags?: string[];
}

export interface DtoUpdatePromptTemplateRequest {
  functions: ModelsFunctionDefinition[];
  messages: ModelsPromptMessage[];
  model: string;
  name: string;
  provider: string;
  settings: ModelsPromptTemplateSettings;
  systemPrompt: string;
  type: EnumsPromptTemplateType;
  variables: ModelsVariableDefinition[];
}

export interface DtoUpdatePuzzleSliceJobRequest {
  gridSize: ModelsGridSize;
  mediaData: ModelsMediaData;
}

export interface DtoUpdateRoleRequest {
  role: CommonUserRole;
}

export interface DtoUpdateSessionRequest {
  orgId: string;
  sessionId?: string;
  venueId?: string;
}

export interface DtoUpdateSessionSnapshotRequest {
  fetchOnly?: boolean;
  isGameCompleted: boolean;
  items: DtoSessionSnapshotItem[];
  sessionId?: string;
  silentRefresh: boolean;
  venueId?: string;
}

export interface DtoUpdateSharedAssetRequest {
  data?: ModelsJSONObject | null;
  label: string;
  mediaId?: string | null;
  purpose: EnumsSharedAssetPurpose;
}

export interface DtoUpdateStackNameRequest {
  name: string;
}

export interface DtoUpdateStripeSubscriptionRequest {
  subscriptionId: string;
}

export interface DtoUpdateTagFeaturedGamePacksRequest {
  gamePackIds: string[];
  tagId: number;
}

export interface DtoUpdateTagRequest {
  background?: ModelsMediaAsset | null;
  description?: string | null;
  icon?: ModelsMediaAsset | null;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name?: string | null;
  styles?: ModelsTagStyles | null;
  supportingMedia?: ModelsMediaAsset | null;
}

export interface DtoUpdateUsernameRequest {
  /**
   * @minLength 3
   * @maxLength 25
   */
  username: string;
}

export interface DtoUpdateVenueRequest {
  backgroundMediaId: string;
  /**
   * @minLength 4
   * @maxLength 25
   */
  displayName: string;
  guestEnabled: boolean;
  /**
   * @minLength 4
   * @maxLength 30
   */
  name: string;
  shareControl: boolean;
}

export interface DtoUpgradeDowngradeOrgSubscriptionPlanRequest {
  priceId: string;
  productId: string;
}

export interface DtoUploadSessionSentimentRequest {
  clientId: string;
  data: string;
  metadata: DtoSentimentMetadata;
  sessionId?: string;
  userId: string;
  venueId?: string;
}

export type DtoUploadUGCFileRequest = object;

export interface DtoUploadUGCFileResponse {
  file: DtoGamePackUGCFile;
}

export interface DtoUpsertSubscriptionIntentRequest {
  path: string;
  priceId: string;
  productId: string;
  queries: string;
  timezone?: string | null;
  trial: boolean;
}

export interface DtoUpsertTrainingProfileRequest {
  blockBgMusic?: ModelsPromptConfig | null;
  blockDialogue?: ModelsPromptConfig | null;
  blocks?: ModelsBlockPromptConfig[] | null;
  mediaSearchKeywords?: ModelsPromptConfig | null;
  mediaSelection?: ModelsPromptConfig | null;
  moduleOutline?: ModelsPromptConfig | null;
  name: string;
  topicBlockOverview?: ModelsPromptConfig | null;
  topicOutline?: ModelsPromptConfig | null;
}

export interface DtoUser {
  connectionTestStatus?: boolean | null;
  connectionTestUpdatedAt?: string | null;
  createdAt: string;
  email: string;
  id: string;
  organizer?: DtoOrganizer | null;
  role: number;
  username: string;
  venueActivated: boolean;
}

export interface DtoUserAnalyticsReport {
  coworkersCount: number;
  firstActiveDate: string;
  fullName: string;
  id: string;
  isGuest: boolean;
  joyCapture: DtoJoyCapture;
  lastActiveDate: string;
  orgId: string;
  playedCount: number;
  playedSeconds: number;
  slackEngagement: number;
  timeRange: ModelsReportTimeRange;
  uid: string;
}

export interface DtoUserAnalyticsReportListResponse {
  paging: CommonPaging;
  reports: DtoUserAnalyticsReport[];
}

export interface DtoUserAuthenticatedResponse {
  token: string;
  user: DtoUser;
}

export type DtoUserSettingsResponse = Record<string, DtoGroupSettings>;

export interface DtoUsersResponse {
  paging: CommonPaging;
  users: DtoUser[];
}

export interface DtoVIPDescription {
  id: string;
  name: string;
}

export interface DtoValidateMessageConditionRequest {
  condition: ModelsMessageCondition;
  vars: any;
}

export interface DtoValidateMessageConditionResponse {
  valid: boolean;
}

export interface DtoVenue {
  background: CommonMedia;
  backgroundUrl: string;
  code: string;
  coordinatorPolicyOverride: EnumsCoordinatorPolicy;
  createdAt: string;
  displayName: string;
  guestEnabled: boolean;
  id: string;
  name: string;
  nameModifiedAt: string;
  shareControl: boolean;
  uid: string;
  updatedAt: string;
}

export interface DtoVenuesResponse {
  venues: DtoVenue[];
}

export interface DtoVerifyCallback {
  code: string;
}

export interface DtoVerifyCheckoutSessionResponse {
  completed: boolean;
  customerName?: string | null;
}

export interface DtoVerifyLoginRequest {
  identify: string;
  queries?: Record<string, string>;
  retry: boolean;
  webDomain: string;
}

export interface DtoVideoEffectsSettings {
  boundingBox: ModelsBoundingBoxSettings;
  greenScreen: ModelsGreenScreenSettingsV0;
  podium: ModelsPodiumSettings;
  stage: ModelsStageSettings;
}

export interface DtoZoomCheckQuickLoginRequest {
  zoomUid: string;
}

export interface DtoZoomCheckQuickLoginResponse {
  available: boolean;
}

export interface DtoZoomDecryptAppContextRequest {
  context: string;
}

export interface DtoZoomDecryptAppContextResponse {
  context: ZoomDecryptedAppContext;
}

export interface DtoZoomGetAccessTokenRequest {
  code: string;
  redirectURL: string;
  state?: string;
}

export interface DtoZoomGetAuthorizeURLRequest {
  redirectURL: string;
  state?: string;
}

export interface DtoZoomGetAuthorizeURLResponse {
  url: string;
}

export interface DtoZoomGetDeeplinkRequest {
  code: string;
  redirectURL: string;
  state?: string;
}

export interface DtoZoomGetDeeplinkResponse {
  deeplink: string;
}

export interface DtoZoomGetUserResponse {
  user: ZoomUser;
}

export interface DtoZoomLoginRequest {
  auth?: DtoZoomGetAccessTokenRequest;
  mode: EnumsZoomLoginMode;
  zoomUid?: string;
}

export interface DtoZoomLoginResponse {
  token: string;
  user: DtoUser;
  zoomUser: ZoomUser;
}

export interface DtoZoomLogoutRequest {
  zoomUid: string;
}

export interface DtoZoomLogoutResponse {
  token: string;
  user: DtoUser;
}

export interface DtoZoomSetupExperienceResponse {
  venueId: string;
}

export enum EnumsAvatarGeneratorId {
  AvatarGeneratorIdSyncLabs = 'sync-labs',
  AvatarGeneratorIdHeyGen = 'hey-gen',
}

export enum EnumsBlockDownVoteReason {
  BlockDownVoteReasonNone = 0,
  BlockDownVoteReasonTechIssues = 1,
  BlockDownVoteReasonTooHard = 2,
  BlockDownVoteReasonConfusingRules = 3,
  BlockDownVoteReasonSomethingElse = 100,
  BlockDownVoteReasonCustom = 101,
}

export enum EnumsBlockIntro {
  BlockIntroNone = 'none',
  BlockIntroStinger = 'stinger',
  BlockIntroVoice = 'voice',
  BlockIntroVoiceWithStinger = 'voice-with-stinger',
}

export enum EnumsBlockSearchSortBy {
  BlockSearchSortByBestMatch = 'best-match',
  BlockSearchSortByRecentUpdated = 'recent-updated',
}

export enum EnumsBlockType {
  BlockTypeQuestion = 'question',
  BlockTypeCreativePrompt = 'creativePrompt',
  BlockTypeRapid = 'rapid',
  BlockTypeScoreboard = 'scoreboard',
  BlockTypeSpotlight = 'spotlight',
  BlockTypeSpotlightV2 = 'spotlightV2',
  BlockTypeTeamRelay = 'teamRelay',
  BlockTypeRandomizer = 'randomizer',
  BlockTypeMultipleChoice = 'multipleChoice',
  BlockTypeMemoryMatch = 'memoryMatch',
  BlockTypePuzzle = 'puzzle',
  BlockTypeRoundRobinQuestion = 'roundRobinQuestion',
  BlockTypeTitleV2 = 'titleV2',
  BlockTypeInstruction = 'instruction',
  BlockTypeOverRoasted = 'overRoasted',
  BlockTypeDrawingPrompt = 'drawingPrompt',
  BlockTypeHiddenPicture = 'hiddenPicture',
  BlockTypeAiChat = 'aiChat',
  BlockTypeIcebreaker = 'icebreaker',
  BlockTypeGuessWho = 'guessWho',
  BlockTypeMarketing = 'marketing',
  BlockTypeJeopardy = 'jeopardy',
  BlockTypeHeadToHead = 'headToHead',
  BlockTypeSlide = 'slide',
  BlockTypeRoleplay = 'roleplay',
  BlockTypeDrawToWin = 'drawToWin',
  BlockTypeSparkifact = 'sparkifact',
  BlockTypeSwipeToWin = 'swipeToWin',
  BlockTypeFillInTheBlanks = 'fillInTheBlanks',
}

export enum EnumsBlockVoteValue {
  BlockVoteValueDOWN = -1,
  BlockVoteValueUP = 1,
}

export enum EnumsBrandPredefinedBlockScenario {
  BrandPredefinedBlockScenarioInstructions = 'instructions',
  BrandPredefinedBlockScenarioHostedInstructions = 'hosted-instructions',
  BrandPredefinedBlockScenarioOpeningTitle = 'opening-title',
  BrandPredefinedBlockScenarioDemo = 'demo',
  BrandPredefinedBlockScenarioScoreboard = 'scoreboard',
}

export enum EnumsBrandQueryType {
  BrandQueryTypeTag = 'tag',
  BrandQueryTypeUntagged = 'untagged',
}

export enum EnumsCelebrationMessageFrequency {
  CelebrationMessageFrequencyDaily = 'daily',
  CelebrationMessageFrequencyWeekly = 'weekly',
  CelebrationMessageFrequencyMonthly = 'monthly',
}

export enum EnumsChannelStatus {
  ChannelStatusActive = 'active',
  ChannelStatusInactive = 'inactive',
}

export enum EnumsCoordinatorPolicy {
  CoordinatorPolicyEveryone = 'everyone',
  CoordinatorPolicyVenueOwner = 'venue-owner',
  CoordinatorPolicyCohost = 'cohost',
}

export enum EnumsDialogueGenerationType {
  DialogueGenerationTypeOffline = 'offline',
  DialogueGenerationTypeClient = 'client',
}

export enum EnumsDialogueOfflineRenderStatus {
  DialogueOfflineRenderStatusNotStarted = 'not-started',
  DialogueOfflineRenderStatusProcessing = 'processing',
  DialogueOfflineRenderStatusCompleted = 'completed',
  DialogueOfflineRenderStatusFailed = 'failed',
}

export enum EnumsEventStatus {
  EventStatusActive = 'active',
  EventStatusCanceled = 'canceled',
}

export enum EnumsEventType {
  EventTypeOnd = 'ond',
  EventTypeLive = 'live',
}

export enum EnumsExConnectStatus {
  ExConnectStatusActive = 'active',
  ExConnectStatusInactive = 'inactive',
}

export enum EnumsExConnectType {
  ExConnectTypeSlack = 'slack',
  ExConnectTypeTeams = 'teams',
  ExConnectTypeLpOrgAdmin = 'lp_org_admin',
  ExConnectTypeLpOrgAllMembers = 'lp_org_all_members',
}

export enum EnumsExternalMediaAspectRatio {
  ExternalMediaAspectRatioWide = 'wide',
  ExternalMediaAspectRatioTall = 'tall',
  ExternalMediaAspectRatioSquare = 'square',
  ExternalMediaAspectRatioAll = 'all',
}

export enum EnumsExternalMediaProvider {
  ExternalMediaProviderUnsplash = 'unsplash',
  ExternalMediaProviderSerp = 'serp',
  ExternalMediaProviderGiphy = 'giphy',
  ExternalMediaProviderAzureBing = 'azure-bing',
  ExternalMediaProviderImageGen = 'image-gen',
}

export enum EnumsFeaturedItemType {
  FeaturedItemTypeGamePack = 'game-pack',
  FeaturedItemTypeTag = 'tag',
  FeaturedItemTypePlanAd = 'plan-ad',
}

export enum EnumsFillInTheBlanksAnswerStyle {
  FillInTheBlanksAnswerStyleTyping = 'typing',
  FillInTheBlanksAnswerStyleChoice = 'choice',
}

export enum EnumsFillInTheBlanksSegmentType {
  FillInTheBlanksSegmentTypeText = 'text',
  FillInTheBlanksSegmentTypeBlank = 'blank',
}

export enum EnumsGameOthersPlayingType {
  GameOthersPlayingTypeSameGamePack = 'same-game-pack',
  GameOthersPlayingTypeRecent = 'recent',
}

export enum EnumsGamePackAudience {
  GamePackAudienceUsCentric = 'us_centric',
  GamePackAudienceGlobal = 'global',
}

export enum EnumsGamePackChangeLevel {
  GamePackChangeLevelNegligible = 'negligible',
  GamePackChangeLevelMinor = 'minor',
  GamePackChangeLevelMajor = 'major',
}

export enum EnumsGamePackCompetitionLevel {
  GamePackCompetitionLevelCasual = 'casual',
  GamePackCompetitionLevelNeutral = 'neutral',
  GamePackCompetitionLevelCompetitive = 'competitive',
}

export enum EnumsGamePackDifficulty {
  GamePackDifficultyEasy = 'easy',
  GamePackDifficultyMedium = 'medium',
  GamePackDifficultyHard = 'hard',
}

export enum EnumsGamePackInstructionRule {
  GamePackInstructionRuleStartOfSession = 'start_of_session',
  GamePackInstructionRuleStartOfEachNewBrand = 'start_of_each_new_brand',
  GamePackInstructionRuleNeverShow = 'never_show',
}

export enum EnumsGamePackLeaderboardRule {
  GamePackLeaderboardRuleAfterEveryBlock = 'after_every_block',
  GamePackLeaderboardRuleAfterEveryOtherBlock = 'after_every_other_block',
  GamePackLeaderboardRuleEveryGameBrandChange = 'every_game_brand_change',
  GamePackLeaderboardRuleEndOfSession = 'end_of_session',
  GamePackLeaderboardRuleNeverShow = 'never_show',
}

export enum EnumsGamePackMakeUnitsFrom {
  GamePackMakeUnitsFromIndividualBlocks = 'individual_blocks',
  GamePackMakeUnitsFromConsecutiveBrandBlocks = 'consecutive_brand_blocks',
  GamePackMakeUnitsFromWholeGamePack = 'whole_game_pack',
}

export enum EnumsGamePackMakeup {
  GamePackMakeupMultipleLevels = 'multiple_levels',
  GamePackMakeupMultipleRounds = 'multiple_rounds',
  GamePackMakeupOneBigGame = 'one_big_game',
}

export enum EnumsGamePackPlayStyle {
  GamePackPlayStylePlayOnATeam = 'play_on_a_team',
  GamePackPlayStylePlayAllTogether = 'play_all_together',
}

export enum EnumsGamePackRecommendationScenario {
  GamePackRecommendationScenarioGeneral = 'general',
  GamePackRecommendationScenarioZoom = 'zoom',
}

export enum EnumsGamePackUGCCreationStatus {
  GamePackUGCCreationStatusQueued = 'queued',
  GamePackUGCCreationStatusInProgress = 'in_progress',
  GamePackUGCCreationStatusCompleted = 'completed',
  GamePackUGCCreationStatusFailed = 'failed',
}

export enum EnumsGamePackUGCFileStatus {
  GamePackUGCFileStatusPreProcessing = 'pre_processing',
  GamePackUGCFileStatusInProgress = 'in_progress',
  GamePackUGCFileStatusCompleted = 'completed',
  GamePackUGCFileStatusFailed = 'failed',
}

export enum EnumsGamePackVersion {
  GamePackVersionV1 = 1,
  GamePackVersionV2 = 2,
}

export enum EnumsGlobalPromotionStatus {
  GlobalPromotionStatusConfiguring = 'Configuring',
  GlobalPromotionStatusScheduled = 'Scheduled',
  GlobalPromotionStatusCompleted = 'Completed',
}

export enum EnumsGroupPhotoStatus {
  GroupPhotoStatusGenerating = 'generating',
  GroupPhotoStatusCompleted = 'completed',
  GroupPhotoStatusFailed = 'failed',
}

export enum EnumsH2HCardSource {
  H2HCardSourceNoCard = 0,
  H2HCardSourceDefaultCard = 1,
  H2HCardSourceMyCard = 2,
}

export enum EnumsH2HGameStarter {
  H2HGameStarterBoth = 0,
  H2HGameStarterGameCoordinator = 1,
  H2HGameStarterGroupATeamCaptain = 2,
}

export enum EnumsH2HJudgingMode {
  H2HJudgingModeDisabled = 0,
  H2HJudgingModeDuringGamePlay = 1,
  H2HJudgingModeEndOfGamePlay = 2,
}

export enum EnumsH2HJudgingSentiment {
  H2HJudgingSentimentPositive = 1,
  H2HJudgingSentimentNegative = 2,
}

export enum EnumsH2HJudgingUserGroup {
  H2HJudgingUserGroupAudience = 1,
  H2HJudgingUserGroupGameCoordinator = 2,
}

export enum EnumsH2HMode {
  H2HModeTurns = 0,
  H2HModeDebate = 1,
}

export enum EnumsH2HSelectPlayerStrategy {
  H2HSelectPlayerStrategyRandomPlayer = 0,
  H2HSelectPlayerStrategyRandomTeam = 1,
}

export enum EnumsH2HSubTimerType {
  H2HSubTimerTypePerTurn = 0,
  H2HSubTimerTypePerRound = 1,
}

export enum EnumsHiddenPictureAsymmetricPinDropAudibility {
  HiddenPictureAsymmetricPinDropAudibilityMuted = 'muted',
  HiddenPictureAsymmetricPinDropAudibilityAudible = 'audible',
}

export enum EnumsHiddenPictureAsymmetricPinDropVisibility {
  HiddenPictureAsymmetricPinDropVisibilityHidden = 'hidden',
  HiddenPictureAsymmetricPinDropVisibilityVisible = 'visible',
}

export enum EnumsHiddenPictureHotSpotShape {
  HiddenPictureHotSpotShapeCircle = 'circle',
  HiddenPictureHotSpotShapeRectangle = 'rectangle',
}

export enum EnumsHiddenPictureMode {
  HiddenPictureModeAll = 'all',
  HiddenPictureModeOne = 'one',
}

export enum EnumsHiddenPicturePenaltyResetStrategy {
  HiddenPicturePenaltyResetStrategyNever = 'never',
  HiddenPicturePenaltyResetStrategyPerPicture = 'perPicture',
}

export enum EnumsIcebreakerGameStyle {
  IcebreakerGameStyleDefault = 'default',
  IcebreakerGameStyleTaboo = 'taboo',
}

export enum EnumsIcebreakerMode {
  IcebreakerModeDefault = 'default',
  IcebreakerModeHeadsUp = 'headsUp',
  IcebreakerModeChatPack = 'chatPack',
}

export enum EnumsIcebreakerOnStageSelection {
  IcebreakerOnStageSelectionNone = 0,
  IcebreakerOnStageSelectionRandom = 1,
  IcebreakerOnStageSelectionVip = 2,
}

export enum EnumsIcebreakerOnStageTimerMode {
  IcebreakerOnStageTimerModePlayerReset = 0,
  IcebreakerOnStageTimerModeCardReset = 1,
}

export enum EnumsIcebreakerSelectFirstStrategy {
  IcebreakerSelectFirstStrategyDefault = 0,
  IcebreakerSelectFirstStrategyVip = 1,
}

export enum EnumsIcebreakerSelectNextStrategy {
  IcebreakerSelectNextStrategyDefault = 0,
  IcebreakerSelectNextStrategyOnStageChoose = 1,
  IcebreakerSelectNextStrategyKeepCurrent = 2,
}

export enum EnumsIntakeFormStatus {
  IntakeFormStatusUnfilled = 'unfilled',
  IntakeFormStatusFilled = 'filled',
}

export enum EnumsInterviewStatus {
  InterviewStatusNotStarted = 'not_started',
  InterviewStatusInProgress = 'in_progress',
  InterviewStatusSummarizing = 'summarizing',
  InterviewStatusInReview = 'in_review',
  InterviewStatusSubmitted = 'submitted',
}

export enum EnumsInvoiceDownloadLinkType {
  InvoiceDownloadLinkTypePdf = 'pdf',
  InvoiceDownloadLinkTypeWebsite = 'website',
}

export enum EnumsInvoiceType {
  InvoiceTypeSubscription = 'subscription',
  InvoiceTypeOneTimePurchase = 'oneTimePurchase',
}

export enum EnumsJeopardyClueType {
  JeopardyClueTypeQuestion = 'question',
  JeopardyClueTypeMultipleChoice = 'multiple-choice',
}

export enum EnumsJeopardyHostScriptKey {
  JeopardyHostScriptKeyCategoriesIntro = 'categoriesIntro',
  JeopardyHostScriptKeySelectClue = 'selectClue',
  JeopardyHostScriptKeyCorrectResponse = 'correctResponse',
  JeopardyHostScriptKeyIncorrectNextBuzzer = 'incorrectNextBuzzer',
  JeopardyHostScriptKeyIncorrectNoBuzzQueue = 'incorrectNoBuzzQueue',
  JeopardyHostScriptKeyFirstTime = 'firstTime',
  JeopardyHostScriptKeyGameOver = 'gameOver',
}

export enum EnumsJeopardyTurnResult {
  JeopardyTurnResultCorrect = 'correct',
  JeopardyTurnResultIncorrect = 'incorrect',
  JeopardyTurnResultSkipped = 'skipped',
}

export enum EnumsLiveBookingAccess {
  LiveBookingAccessUnlimited = 'unlimited',
  LiveBookingAccessDiscount = 'discount',
}

export enum EnumsMediaFormatVersion {
  MediaFormatVersionRaw = 'raw',
  MediaFormatVersionHd = 'hd',
  MediaFormatVersionFhd = 'fhd',
  MediaFormatVersionMd = 'md',
  MediaFormatVersionSm = 'sm',
  MediaFormatVersionHq = 'hq',
  MediaFormatVersionSq = 'sq',
}

export enum EnumsMediaScene {
  MediaSceneVenueBackground = 'venue-background',
  MediaSceneBlockBackground = 'block-background',
  MediaSceneBlockMedia = 'block-media',
  MediaSceneGameCover = 'game-cover',
  MediaSceneGamePackCover = 'game-pack-cover',
  MediaSceneGamePackVenueBackground = 'game-pack-venue-background',
  MediaSceneGamePackMarketingMaterials = 'game-pack-marketing-materials',
  MediaSceneGamePackUgcFile = 'game-pack-ugc-file',
  MediaSceneBlockHostRecordingV2 = 'block-host-recording-v2',
  MediaSceneProgramCelebrationsMessage = 'program-celebrations-message',
  MediaSceneDrawingPrompt = 'drawing-prompt',
  MediaSceneBrandShowcase = 'brand-showcase',
  MediaSceneProgram = 'program',
  MediaSceneTag = 'tag',
  MediaSceneAnalyticsUserJoyCapture = 'analytics-user-joy-capture',
  MediaSceneWaterCoolerTopics = 'water-cooler-topics',
  MediaSceneEngagedVideos = 'engaged-videos',
  MediaSceneBlockPuzzleSource = 'block-puzzle-source',
  MediaScenePageSection = 'page-section',
  MediaSceneMusicPlayer = 'music-player',
  MediaSceneCollective = 'collective',
  MediaSceneProfileImage = 'profile-image',
}

export enum EnumsMediaTranscodeStatus {
  MediaTranscodeStatusPending = 0,
  MediaTranscodeStatusProcessing = 1,
  MediaTranscodeStatusFailed = 2,
  MediaTranscodeStatusReady = 3,
}

export enum EnumsMediaType {
  MediaTypeImage = 100,
  MediaTypeVideo = 101,
  MediaTypeAudio = 102,
}

export enum EnumsMemberStatus {
  MemberStatusActive = 'active',
  MemberStatusSnoozed = 'snoozed',
  MemberStatusDeactivated = 'deactivated',
}

export enum EnumsMessageCampaignType {
  MessageCampaignTypePlayground = 'playground',
  MessageCampaignTypeProgramRound = 'program-round',
  MessageCampaignTypeProgramLinkActivated = 'program-link-activated',
  MessageCampaignTypeOtpStarted = 'otp-started',
  MessageCampaignTypeOtpPurchased = 'otp-purchased',
  MessageCampaignTypeOtpEventEnded = 'otp-event-ended',
  MessageCampaignTypeOnboardingDrip = 'onboarding-drip',
  MessageCampaignTypeMemberInvited = 'member-invited',
  MessageCampaignTypeProgramInstalled = 'program-installed',
  MessageCampaignTypeSessionEnded = 'session-ended',
  MessageCampaignTypeCancelOfferAccepted = 'cancel-offer-accepted',
  MessageCampaignTypeSubscriptionStarted = 'subscription-started',
  MessageCampaignTypeOrgSeatCapErrShown = 'org-seat-cap-err-shown',
}

export enum EnumsMessageDistributionChannel {
  MessageDistributionChannelEmail = 'email',
  MessageDistributionChannelSlack = 'slack',
}

export enum EnumsMessageLogicStatus {
  MessageLogicStatusActive = 'active',
  MessageLogicStatusInactive = 'inactive',
}

export enum EnumsMessageTarget {
  MessageTargetOrgAdmins = 'org-admins',
  MessageTargetOrgOwners = 'org-owners',
  MessageTargetOrgMembers = 'org-members',
  MessageTargetChannel = 'channel',
  MessageTargetChannelMembers = 'channel-members',
  MessageTargetChannelCreator = 'channel-creator',
  MessageTargetGroups = 'groups',
  MessageTargetGroup = 'group',
  MessageTargetMember = 'member',
}

export enum EnumsMessageTemplateFormat {
  MessageTemplateFormatMarkdown = 'markdown',
  MessageTemplateFormatHardcoded = 'hardcoded',
}

export enum EnumsMessageTemplateMediaSource {
  MessageTemplateMediaSourceUpload = 'upload',
  MessageTemplateMediaSourcePredefined = 'predefined',
}

export enum EnumsMessageTriggerEventName {
  MessageTriggerEventNameProgramActivated = 'program-activated',
  MessageTriggerEventNameGroupsAssigned = 'groups-assigned',
  MessageTriggerEventNameGroupMembersDownToOne = 'group-members-down-to-one',
  MessageTriggerEventNameGroupRepaired = 'group-repaired',
  MessageTriggerEventNameRecognitionGiven = 'recognition-given',
  MessageTriggerEventNameRecognitionReceived = 'recognition-received',
}

export enum EnumsMessageTriggerTimeOffsetDirection {
  MessageTriggerTimeOffsetDirectionAfter = 'after',
  MessageTriggerTimeOffsetDirectionBefore = 'before',
}

export enum EnumsMessageTriggerType {
  MessageTriggerTypeTimeOffset = 'time-offset',
  MessageTriggerTypeWeekdaysOffset = 'weekdays-offset',
  MessageTriggerTypeEventDriven = 'event-driven',
}

export enum EnumsNotificationFrequencyEmail {
  NotificationFrequencyEmailWeekly = 'weekly',
  NotificationFrequencyEmailMonthly = 'monthly',
  NotificationFrequencyEmailBiMonthly = 'bi-monthly',
  NotificationFrequencyEmailQuarterly = 'quarterly',
  NotificationFrequencyEmailNever = 'never',
}

export enum EnumsNotificationFrequencySlack {
  NotificationFrequencySlackWeekly = 'weekly',
  NotificationFrequencySlackBiWeekly = 'bi-weekly',
  NotificationFrequencySlackMonthly = 'monthly',
  NotificationFrequencySlackNever = 'never',
}

export enum EnumsOAuthProvider {
  OAuthProviderGoogle = 'google',
  OAuthProviderSlack = 'slack',
}

export enum EnumsOnboardingTaskName {
  OnboardingTaskNameEnterTaskPage = 'enter-task-page',
  OnboardingTaskNameScheduleCall = 'schedule-call',
  OnboardingTaskNameConnectToSlack = 'connect-to-slack',
  OnboardingTaskNameAddTeam = 'add-team',
  OnboardingTaskNameNotifyTeam = 'notify-team',
  OnboardingTaskNameSetUpPrograms = 'set-up-programs',
  OnboardingTaskNameSubmitDates = 'submit-dates',
  OnboardingTaskNameBookLiveShow = 'book-live-show',
  OnboardingTaskNameScheduleOndGame = 'schedule-ond-game',
  OnboardingTaskNameSchedulePurchasedOndGame = 'schedule-purchased-ond-game',
  OnboardingTaskNamePendingPurchaseOndGame = 'pending-purchase-ond-game',
  OnboardingTaskNameAddTeamMembersWithSlack = 'add-team-members-with-slack',
  OnboardingTaskNameAddTeamMembersWithoutSlack = 'add-team-members-without-slack',
  OnboardingTaskNameAnnounceToTeamWithSlack = 'announce-to-team-with-slack',
  OnboardingTaskNameAnnounceToTeamWithoutSlack = 'announce-to-team-without-slack',
  OnboardingTaskNameEnableGlobalPairingTournaments = 'enable-global-pairing-tournaments',
  OnboardingTaskNameEnableGameDrops = 'enable-game-drops',
  OnboardingTaskNameEnableBirthdaysAndCelebrations = 'enable-birthdays-and-celebrations',
}

export enum EnumsOnboardingTaskOperation {
  OnboardingTaskOperationMarkDone = 'mark-done',
  OnboardingTaskOperationMarkClosed = 'mark-closed',
  OnboardingTaskOperationDisable = 'disable',
  OnboardingTaskOperationEnable = 'enable',
}

export enum EnumsOndGamePlayAccess {
  OndGamePlayAccessUnlimited = 'unlimited',
  OndGamePlayAccessGamePackIds = 'game_pack_ids',
  OndGamePlayAccessGamePackTagIds = 'game_pack_tag_ids',
}

export enum EnumsOndGameUGCAccess {
  OndGameUGCAccessUnlimited = 'unlimited',
}

export enum EnumsOneTimePurchaseIntentStatus {
  OneTimePurchaseIntentStatusPending = 'pending',
  OneTimePurchaseIntentStatusSucceeded = 'succeeded',
  OneTimePurchaseIntentStatusAborted = 'aborted',
}

export enum EnumsOneTimePurchaseStripeTxnType {
  OneTimePurchaseStripeTxnTypeCheckoutSession = 'checkoutSession',
  OneTimePurchaseStripeTxnTypePaymentIntent = 'paymentIntent',
  OneTimePurchaseStripeTxnTypeInvoice = 'invoice',
  OneTimePurchaseStripeTxnTypeUnknown = 'unknown',
}

export enum EnumsOneTimePurchaseUpsellPromoCode {
  OneTimePurchaseUpsellPromoCodePostCheckout = 'post-checkout',
  OneTimePurchaseUpsellPromoCodePostGame = 'post-game',
}

export enum EnumsOrgSubscriptionCancelOffer {
  OrgSubscriptionCancelOfferFirst = 'first',
  OrgSubscriptionCancelOfferFinal = 'final',
}

export enum EnumsOrgSubscriptionPlan {
  OrgSubscriptionPlanFree = 'free',
  OrgSubscriptionPlanPaid = 'paid',
  OrgSubscriptionPlanCompanyPlus = 'company_plus',
  OrgSubscriptionPlanCompany = 'company',
}

export enum EnumsOrgSubscriptionStatus {
  OrgSubscriptionStatusActive = 'active',
  OrgSubscriptionStatusTrialing = 'trialing',
  OrgSubscriptionStatusCancelled = 'cancelled',
}

export enum EnumsPageName {
  PageNameDiscoverGames = 'discover-games',
  PageNameDiscoverGamePacks = 'discover-game-packs',
  PageNamePublicGamePacks = 'public-game-packs',
  PageNameFeaturedGamePacks = 'featured-game-packs',
  PageNameBrands = 'brands',
  PageNamePublicGamePacksNg = 'public-game-packs-ng',
  PageNameExplore = 'explore',
  PageNamePublicHome = 'public-home',
  PageNameLiveOtp = 'live-otp',
  PageNameLiveLoggedIn = 'live-logged-in',
  PageNameZoom = 'zoom',
  PageNameStaging = 'staging',
  PageNameTrial = 'trial',
  PageNameGeneralLearning = 'general-learning',
  PageNameGeneralLearningStaging = 'general-learning-staging',
}

export enum EnumsPageRowLayout {
  PageRowLayoutExpanded = 'expanded',
  PageRowLayoutCarousel = 'carousel',
}

export enum EnumsPageRowType {
  PageRowTypeTag = 'tag',
  PageRowTypeTagCollections = 'tag-collections',
  PageRowTypeFeaturedCarousel = 'featured-carousel',
  PageRowTypeSocialProof = 'social-proof',
}

export enum EnumsPageSectionLayoutStyle {
  PageSectionLayoutStyleMediaCollapse = 'media-collapse',
  PageSectionLayoutStyleMediaExpandedWithButton = 'media-expanded-with-button',
}

export enum EnumsPageSectionType {
  PageSectionTypeGeneral = 'general',
  PageSectionTypeProgram = 'program',
}

export enum EnumsPairingParticipationMode {
  PairingParticipationModeNone = 'none',
  PairingParticipationModeOptInOnly = 'opt_in_only',
  PairingParticipationModeOptInAndOut = 'opt_in_and_out',
}

export enum EnumsPairingParticipationStatus {
  PairingParticipationStatusOptedOut = 'opted_out',
  PairingParticipationStatusOptedIn = 'opted_in',
  PairingParticipationStatusUnknown = 'unknown',
}

export enum EnumsPlatform {
  PlatformZoom = 'zoom',
}

export enum EnumsProductBillingInterval {
  ProductBillingIntervalMonthly = 'monthly',
  ProductBillingIntervalQuarterly = 'quarterly',
  ProductBillingIntervalYearly = 'yearly',
}

export enum EnumsProductFeatureType {
  ProductFeatureTypeProgramAccess = 'program_access',
  ProductFeatureTypeOndGamePlay = 'ond_game_play',
  ProductFeatureTypeLiveBooking = 'live_booking',
  ProductFeatureTypeVenueSeatCap = 'venue_seat_cap',
  ProductFeatureTypeOndGameUgc = 'ond_game_ugc',
  ProductFeatureTypeTraining = 'training',
}

export enum EnumsProductStatus {
  ProductStatusDraft = 'draft',
  ProductStatusPublished = 'published',
  ProductStatusArchived = 'archived',
}

export enum EnumsProgramAccess {
  ProgramAccessUnlimited = 'unlimited',
  ProgramAccessProgramIds = 'program_ids',
}

export enum EnumsProgramCadenceFrequency {
  ProgramCadenceFrequencyMultipleTimesPerWeek = 0,
  ProgramCadenceFrequencyEveryWeek = 1,
  ProgramCadenceFrequencyEveryTwoWeeks = 2,
  ProgramCadenceFrequencyEveryThreeWeeks = 3,
  ProgramCadenceFrequencyEveryFourWeeks = 4,
  ProgramCadenceFrequencyEverySixWeeks = 6,
  ProgramCadenceFrequencyEveryEightWeeks = 8,
  ProgramCadenceFrequencyEveryTenWeeks = 10,
  ProgramCadenceFrequencyEveryTwelveWeeks = 12,
}

export enum EnumsProgramLinkStatus {
  ProgramLinkStatusActive = 'active',
  ProgramLinkStatusInactive = 'inactive',
}

export enum EnumsProgramRoundParentType {
  ProgramRoundParentTypeProgram = 'program',
  ProgramRoundParentTypeProgramLink = 'programLink',
}

export enum EnumsProgramRoundStatus {
  ProgramRoundStatusConfiguring = 'configuring',
  ProgramRoundStatusScheduled = 'scheduled',
  ProgramRoundStatusInProgress = 'inProgress',
  ProgramRoundStatusCompleted = 'completed',
}

export enum EnumsProgramType {
  ProgramTypeGlobalPairing = 'global_pairing',
  ProgramTypeGameDrops = 'game_drops',
  ProgramTypeBirthdayAndCelebrations = 'birthday_and_celebrations',
  ProgramTypeDummy = 'dummy',
  ProgramTypeCalendar = 'calendar',
  ProgramTypeIntros = 'intros',
  ProgramTypeWaterCooler = 'water_cooler',
  ProgramTypeRecognition = 'recognition',
  ProgramTypeAiSurvey = 'ai_survey',
  ProgramTypeCustomTournament = 'custom_tournament',
}

export enum EnumsPromotionCampaign {
  PromotionCampaignAutoGamePacks = 'auto_game_packs',
  PromotionCampaignAutoPostGame = 'auto_post_game',
  PromotionCampaignAutoPrograms = 'auto_programs',
}

export enum EnumsPromotionMedium {
  PromotionMediumEmail = 'email',
  PromotionMediumSlack = 'slack',
}

export enum EnumsPromotionStatus {
  PromotionStatusActive = 'Active',
  PromotionStatusInactive = 'Inactive',
}

export enum EnumsPromptTemplateOwnerType {
  PromptTemplateOwnerTypeSystem = 'System',
  PromptTemplateOwnerTypeUser = 'User',
}

export enum EnumsPromptTemplateType {
  PromptTemplateTypeSimple = 'Simple',
  PromptTemplateTypeAssistant = 'Assistant',
  PromptTemplateTypeImageGen = 'ImageGen',
}

export enum EnumsPuzzleMode {
  PuzzleModePuzzle = 'puzzle',
  PuzzleModeDragAndDrop = 'drag_and_drop',
}

export enum EnumsQuestionReplyStatus {
  QuestionReplyStatusPending = 'pending',
  QuestionReplyStatusTranscribing = 'transcribing',
  QuestionReplyStatusSummarizing = 'summarizing',
  QuestionReplyStatusDone = 'done',
}

export enum EnumsRecognitionQueryTimeRange {
  RecognitionQueryTimeRangeAllTime = 'all_time',
  RecognitionQueryTimeRangeLastWeek = 'last_week',
  RecognitionQueryTimeRangeLastTwoWeeks = 'last_two_weeks',
  RecognitionQueryTimeRangeLastMonth = 'last_month',
  RecognitionQueryTimeRangeLastThreeMonths = 'last_three_months',
  RecognitionQueryTimeRangeLastSixMonths = 'last_six_months',
  RecognitionQueryTimeRangeLastYear = 'last_year',
}

export enum EnumsRecognitionQueryType {
  RecognitionQueryTypeGiven = 'given',
  RecognitionQueryTypeReceived = 'received',
  RecognitionQueryTypeCombined = 'combined',
}

export enum EnumsRefObjectType {
  RefObjectTypeNone = 0,
  RefObjectTypeBlock = 100,
  RefObjectTypeGame = 200,
  RefObjectTypePrimeGame = 201,
  RefObjectTypeGamePack = 210,
  RefObjectTypePrimeGamePack = 211,
  RefObjectTypeBrand = 220,
  RefObjectTypeProgramRound = 221,
}

export enum EnumsRoleplayCriterionWeight {
  RoleplayCriterionWeightLight = 'light',
  RoleplayCriterionWeightModerate = 'moderate',
  RoleplayCriterionWeightHeavy = 'heavy',
  RoleplayCriterionWeightImmediate = 'immediate',
}

export enum EnumsRoleplayFailureCriterionResult {
  RoleplayFailureCriterionResultTriggered = 'triggered',
  RoleplayFailureCriterionResultAvoided = 'avoided',
  RoleplayFailureCriterionResultUndetermined = 'undetermined',
}

export enum EnumsRoleplaySuccessCriterionResult {
  RoleplaySuccessCriterionResultMet = 'met',
  RoleplaySuccessCriterionResultFailed = 'failed',
  RoleplaySuccessCriterionResultUndetermined = 'undetermined',
}

export enum EnumsRoleplayTurnEndMode {
  RoleplayTurnEndModePushToTalk = 'push_to_talk',
  RoleplayTurnEndModeServerVad = 'server_vad',
}

export enum EnumsRoleplayVoice {
  RoleplayVoiceAlloy = 'alloy',
  RoleplayVoiceAsh = 'ash',
  RoleplayVoiceBallad = 'ballad',
  RoleplayVoiceCoral = 'coral',
  RoleplayVoiceEcho = 'echo',
  RoleplayVoiceSage = 'sage',
  RoleplayVoiceShimmer = 'shimmer',
  RoleplayVoiceVerse = 'verse',
}

export enum EnumsRoundRobinMode {
  RoundRobinModeDefault = 'default',
  RoundRobinModeRace = 'race',
}

export enum EnumsSettingsName {
  SettingsNameProgramCelebrations = 'program-celebrations',
  SettingsNameOrgSubscription = 'org-subscription',
  SettingsNameGamePacks = 'game-packs',
  SettingsNamePrograms = 'programs',
  SettingsNamePublishedProducts = 'published-products',
  SettingsNameLiveEvent = 'live-event',
  SettingsNameOneTimePurchase = 'one-time-purchase',
  SettingsNamePages = 'pages',
  SettingsNameSharedTtsScripts = 'shared-tts-scripts',
  SettingsNameWordle = 'wordle',
  SettingsNameVoiceOverLocales = 'voice-over-locales',
  SettingsNameGamePackUgc = 'game-pack-ugc',
  SettingsNameTraining = 'training',
}

export enum EnumsSharedAssetFanOutJobStatus {
  SharedAssetFanOutJobStatusPending = 'pending',
  SharedAssetFanOutJobStatusProcessing = 'processing',
  SharedAssetFanOutJobStatusCompleted = 'completed',
  SharedAssetFanOutJobStatusFailed = 'failed',
}

export enum EnumsSharedAssetPurpose {
  SharedAssetPurposeGeneral = 'general',
  SharedAssetPurposeTitleInstructionCard = 'title-instruction-card',
  SharedAssetPurposeFaqGroup = 'faq-group',
  SharedAssetPurposeGamePackShowcaseCard = 'game-pack-showcase-card',
  SharedAssetPurposeVoice = 'voice',
  SharedAssetPurposeAvatar = 'avatar',
  SharedAssetPurposeMusicPlaylist = 'music-playlist',
  SharedAssetPurposePersonality = 'personality',
}

export enum EnumsSharedAssetQueryType {
  SharedAssetQueryTypeByIds = 'by-ids',
}

export enum EnumsSlideBackgroundOption {
  SlideBackgroundOptionNone = 'none',
  SlideBackgroundOptionBrand = 'brand',
  SlideBackgroundOptionHost = 'host',
  SlideBackgroundOptionMedia = 'media',
}

export enum EnumsSlideLayout {
  SlideLayoutBigTitle = 'big-title',
  SlideLayoutTitleAndText = 'title-and-text',
  SlideLayoutBulletPoints = 'bullet-points',
  SlideLayoutQuote = 'quote',
  SlideLayoutClassic = 'classic',
  SlideLayoutQuoteMedia = 'quote-media',
  SlideLayoutBigMedia = 'big-media',
  SlideLayoutBigTitleMedia = 'big-title-media',
  SlideLayoutBulletPointsMedia = 'bullet-points-media',
  SlideLayoutFullHost = 'full-host',
  SlideLayoutFreeForm = 'free-form',
}

export enum EnumsSpotlightBlockStageLayout {
  SpotlightBlockStageLayoutCenter = 'center',
  SpotlightBlockStageLayoutInterview = 'interview',
}

export enum EnumsTTSCacheControl {
  TTSCacheControlShortLive = 'short-live',
  TTSCacheControlLongLive = 'long-live',
}

export enum EnumsTTSGeneratorId {
  TTSGeneratorIdElevenLabs = 'elevenLabs',
  TTSGeneratorIdOpenAI = 'openAI',
}

export enum EnumsTTSRenderPolicy {
  TTSRenderPolicyReadSourceOnly = 'read-source-only',
  TTSRenderPolicyReadCacheOnly = 'read-cache-only',
  TTSRenderPolicyReadThrough = 'read-through',
}

export enum EnumsTokenExchangePlatform {
  TokenExchangePlatformZoom = 'zoom',
}

export enum EnumsTrainingAccess {
  TrainingAccessUnlimited = 'unlimited',
}

export enum EnumsTrainingBlockType {
  TrainingBlockTypeQuestion = 'question',
  TrainingBlockTypeMultipleChoice = 'multipleChoice',
  TrainingBlockTypeClickToMatch = 'clickToMatch',
  TrainingBlockTypeSlide = 'slide',
  TrainingBlockTypeJeopardy = 'jeopardy',
  TrainingBlockTypeSwipeToWin = 'swipeToWin',
  TrainingBlockTypeMarkdown = 'markdown',
}

export enum EnumsUserSettingsGroupKey {
  UserSettingsGroupKeyPrivacy = 'privacy',
  UserSettingsGroupKeyNotification = 'notification',
  UserSettingsGroupKeyNps = 'nps',
  UserSettingsGroupKeyVbg = 'vbg',
  UserSettingsGroupKeyI18N = 'i18n',
  UserSettingsGroupKeyVes = 'ves',
}

export enum EnumsUserVesProfileKey {
  UserVesProfileKeyCohostSolo = 'cohostSolo',
  UserVesProfileKeyCohostInterview = 'cohostInterview',
}

export enum EnumsVolumeLevel {
  VolumeLevelFull = 'full',
  VolumeLevelMidHigh = 'mid-high',
  VolumeLevelMidLow = 'mid-low',
  VolumeLevelBackground = 'background',
}

export enum EnumsWebRTCTestSource {
  WebRTCTestSourceVenue = 'venue',
  WebRTCTestSourceEvent = 'event',
  WebRTCTestSourceStandalone = 'standalone',
}

export enum EnumsZoomLoginMode {
  ZoomLoginModeAuto = 'auto',
  ZoomLoginModeManual = 'manual',
}

export enum FirebaseDataItemKey {
  DataItemKeyParticipant = 'participants',
  DataItemKeySession = 'session',
  DataItemKeyTeam = 'teams',
  DataItemKeyScoreboard = 'scoreboard',
  DataItemKeyGameSessionCore = 'game-session-core',
  DataItemKeyGameSession = 'game-session',
  DataItemKeyOnDGame = 'ond-game',
  DataItemKeyPlaybackInfo = 'playback-info',
  DataItemKeyGameLog = 'game-log',
  DataItemKeyANUTracking = 'anu-tracking',
}

export type GithubComNarviiLunaparkApiServicePkgLlmClientNullableString = object;

export type GithubComNarviiLunaparkApiServicePkgSlackClientNullableString = object;

export interface HubspotLineItem {
  amount: string;
  currency: string;
  description: string;
  hs_object_id: string;
  hs_product_id: string;
  hs_recurring_billing_end_date: string;
  hs_recurring_billing_start_date: string;
  hs_sku: string;
  hs_url: string;
  name: string;
  price: string;
  quantity: string;
  recurringbillingfrequency: string;
}

export interface HubspotOwner {
  archived: boolean;
  archivedAt: string;
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  teams: {
    id: string;
    name: string;
    primary: boolean;
  }[];
  type: string;
  updatedAt: string;
  userId: number;
  userIdIncludingInactive: number;
}

export interface HubspotPaging {
  next: {
    after: string;
    link: string;
  };
}

export interface HubspotPipeline {
  archived: boolean;
  archivedAt: string;
  createdAt: string;
  displayOrder: number;
  id: string;
  label: string;
  stages: HubspotPipelineStage[];
  updatedAt: string;
}

export interface HubspotPipelineStage {
  archived: boolean;
  archivedAt: string;
  createdAt: string;
  displayOrder: number;
  id: string;
  label: string;
  updatedAt: string;
}

export interface HubspotProduct {
  createdate: string;
  description: string;
  hs_object_id: string;
  hs_sku: string;
  hs_url: string;
  name: string;
  price: string;
  recurringbillingfrequency: string;
}

export interface ModelsAIChatBot {
  avatarMedia: CommonMedia;
  avatarMediaData: ModelsMediaData;
  enabled: boolean;
  name: string;
}

export interface ModelsAIChatFields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  bot: ModelsAIChatBot;
  decreasingPointsTimer: boolean;
  gameTimeSec: number;
  internalLabel: string;
  intro: EnumsBlockIntro;
  introMedia: CommonMedia;
  introMediaData: ModelsMediaData;
  lifecycleRules: ModelsBlockLifecycleRule[];
  loseMedia: CommonMedia;
  loseMediaData: ModelsMediaData;
  model: string;
  notes: string;
  outroMedia: CommonMedia;
  outroMediaData: ModelsMediaData;
  promptTemplateId: string;
  referenceId: string;
  roundRobinMode: boolean;
  showResults: boolean;
  startDescendingImmediately: boolean;
  systemPrompt: string;
  systemPromptTemperature: number;
  temperature: number;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
  winMedia: CommonMedia;
  winMediaData: ModelsMediaData;
  winningPoints: number;
}

export interface ModelsAIHostSettings {
  voiceId?: string | null;
}

export interface ModelsAnimation {
  durationMs: number;
  id: string;
  name: string;
  timingFunction: string;
  triggerId: string;
}

export interface ModelsAssignedCourse {
  course_id: string;
  course_name: string;
}

export interface ModelsAssignedMemberRow {
  addedBy: string;
  assignedOn: string;
  completed: boolean;
  email: string;
  group: string;
  groupId: string;
  id: string;
  name: string;
}

export interface ModelsAssignedStack {
  courses: ModelsAssignedCourse[];
  stack_id: string;
  stack_name: string;
}

export enum ModelsAssignmentType {
  AssignmentTypeCourse = 'course',
  AssignmentTypeStack = 'stack',
}

export interface ModelsAvatarLabeledRenderSettings {
  generatorId: EnumsAvatarGeneratorId;
  heyGen?: ModelsAvatarRenderHeyGenSettings | null;
  id: string;
  label: string;
  syncLabs?: ModelsAvatarRenderSyncLabsSettings | null;
}

export interface ModelsAvatarRenderHeyGenSettings {
  avatarId?: string | null;
  templateId?: string | null;
}

export interface ModelsAvatarRenderSyncLabsSettings {
  videoUrl: string;
}

export interface ModelsBgMusic {
  asset: ModelsMediaAsset;
  songId: string;
  songTitle: string;
}

export interface ModelsBirthday {
  day: number;
  month: number;
}

export interface ModelsBlockAction {
  action: string;
  gameSessionStatus: number;
  timestamp: number;
}

export type ModelsBlockAnimations = Record<string, ModelsEnterExitAnimation>;

export interface ModelsBlockData {
  downvoteCount: number;
  downvoteReasonStats: Record<string, number>;
  totalPlayedCount: number;
  upvoteCount: number;
}

export interface ModelsBlockLifecycleCondition {
  props?: object | null;
  type: string;
}

export interface ModelsBlockLifecycleOperation {
  props?: object | null;
  type: string;
}

export interface ModelsBlockLifecycleRule {
  condition: ModelsBlockLifecycleCondition;
  id: string;
  operation: ModelsBlockLifecycleOperation;
}

export interface ModelsBlockMediaSearchAIReply {
  items: ModelsBlockMediaSearchAIReplyItem[];
}

export interface ModelsBlockMediaSearchAIReplyItem {
  mediaInfoList: ModelsBlockMediaSearchAIReplyMediaInfo[];
  tag: string;
}

export interface ModelsBlockMediaSearchAIReplyMediaInfo {
  keyword: string;
  mediaId: string;
  mediaUrl: string;
}

export interface ModelsBlockOutput {
  type: string;
  value: any;
}

export type ModelsBlockOutputMap = Record<string, ModelsBlockOutput>;

export interface ModelsBlockPromptConfig {
  templateId: string;
  templateName?: string | null;
  type: EnumsTrainingBlockType;
}

export interface ModelsBlockRecording {
  actions: ModelsBlockAction[];
  durationMs: number;
  /** view only */
  media: CommonMedia;
  mediaId: string;
  updatedAt: string;
  updatedByUid: string;
  version: number;
}

export interface ModelsBlockUnionFields {
  aiChat: ModelsAIChatFields;
  creativePrompt: ModelsCreativePromptFields;
  drawToWin: ModelsDrawToWinFields;
  drawingPrompt: ModelsDrawingPromptFields;
  fillInTheBlanks: ModelsFillInTheBlanksFields;
  guessWho: ModelsGuessWhoFields;
  headToHead: ModelsHeadToHeadFields;
  hiddenPicture: ModelsHiddenPictureFields;
  icebreaker: ModelsIcebreakerFields;
  instruction: ModelsInstructionFields;
  jeopardy: ModelsJeopardyFields;
  marketing: ModelsMarketingFields;
  memoryMatch: ModelsMemoryMatchFields;
  multipleChoice: ModelsMultipleChoiceFields;
  overRoasted: ModelsOverRoastedFields;
  puzzle: ModelsPuzzleFields;
  question: ModelsQuestionFields;
  randomizer: ModelsRandomizerFields;
  rapid: ModelsRapidFields;
  roleplay: ModelsRoleplayFields;
  roundRobinQuestion: ModelsRoundRobinQuestionFields;
  scoreboard: ModelsScoreboardFields;
  slide: ModelsSlideFields;
  spotlight: ModelsSpotlightFields;
  spotlightV2Fields: ModelsSpotlightV2Fields;
  swipeToWin: ModelsSwipeToWinFields;
  teamRelay: ModelsTeamRelayFields;
  titleV2: ModelsTitleV2Fields;
}

export interface ModelsBlockVoteStats {
  downVoteCount: number;
  rating: number;
  upVoteCount: number;
}

export interface ModelsBoundingBox {
  height: number;
  width: number;
  x: number;
  y: number;
}

export interface ModelsBoundingBoxSettings {
  box: ModelsBoundingBox;
  fit: string;
}

export interface ModelsCalendarProgramLinkExtensions {
  engagedRoundIds?: string[] | null;
}

export interface ModelsCalendarRoundExtensions {
  packId?: string | null;
}

export interface ModelsCelebrationEventMessageSettings {
  customizedMessage: string;
  enabled: boolean;
  includeMedia: boolean;
}

export interface ModelsCelebrationMessageSettings {
  birthday: ModelsCelebrationEventMessageSettings;
  frequency: EnumsCelebrationMessageFrequency;
  groupByCelebrationType: boolean;
  sendSeconds: number;
  timezone: string;
  workAnniversary: ModelsCelebrationEventMessageSettings;
}

export interface ModelsCelebrationStats {
  anniversariesCount: number;
  birthdaysCount: number;
  celebrateBirthdaysCount: number;
  responsesCount: number;
}

export interface ModelsCircleHotSpotData {
  left: number;
  radius: number;
  top: number;
}

export interface ModelsCohostSettings {
  enabled?: boolean | null;
}

export interface ModelsCreativePromptFields {
  bgMusic: ModelsBgMusic;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  points: number;
  pointsMultiplier: number;
  prompt: string;
  referenceId: string;
  scoreboard: boolean;
  startVideoWithTimer: boolean;
  submissionMedia: CommonMedia;
  submissionMediaData: ModelsMediaData;
  submissionTime: number;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
  votingTime: number;
}

export interface ModelsDate {
  day: number;
  month: number;
  year: number;
}

export interface ModelsDetailSettings {
  audience?: EnumsGamePackAudience | null;
  availability?: ModelsGameAvailability | null;
  competitionLevel?: EnumsGamePackCompetitionLevel | null;
  gameDifficulty?: EnumsGamePackDifficulty | null;
  gameInspiredBy: string;
  gameType: string;
  markAsNew?: ModelsMarkAsNew | null;
  richDescription?: string | null;
}

export interface ModelsDialogue {
  entries: ModelsDialogueEntry[];
}

export interface ModelsDialogueEntry {
  generationType: EnumsDialogueGenerationType;
  id: string;
  offlineRender?: ModelsDialogueEntryOfflineRender | null;
  personalityId: string;
  script: string;
}

export interface ModelsDialogueEntryOfflineRender {
  avatar?: ModelsMediaAsset | null;
  error?: string | null;
  jobId?: string | null;
  status: EnumsDialogueOfflineRenderStatus;
  voiceOver?: ModelsMediaAsset | null;
}

export interface ModelsDrawToWinFields {
  bgMusic: ModelsBgMusic;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  matchTool: ModelsDrawToWinMatchTool;
  media: ModelsMediaAsset;
  notes: string;
  personalityId: string;
  question: string;
  referenceId: string;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsDrawToWinMatchTool {
  correctThreshold: number;
  drawing: {
    height: number;
    nodes: string[];
    width: number;
  };
  icon?: ModelsMediaAsset | null;
  missedThreshold: number;
  name: string;
  wrongThreshold: number;
}

export interface ModelsDrawingData {
  backgroundColor: string;
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  height: number;
  paths: ModelsPath[];
  prompt: string;
  version: number;
  width: number;
}

export interface ModelsDrawingPrompt {
  correct: string;
  id: string;
}

export interface ModelsDrawingPromptFields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  canvasMedia: CommonMedia;
  canvasMediaData: ModelsMediaData;
  correctPromptPoints: number;
  drawingTimeSec: number;
  incorrectPromptPoints: number;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  prompts: ModelsDrawingPrompt[];
  referenceId: string;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
  votingTimeSec: number;
}

export interface ModelsDropSpot {
  acceptedValue: string;
  media: CommonMedia;
  mediaId: string;
}

export interface ModelsElevenLabsSettings {
  modelId?: string | null;
  similarityBoost?: number | null;
  stability?: number | null;
}

export interface ModelsEmailAddress {
  address: string;
  name: string;
}

export interface ModelsEnterExitAnimation {
  enter?: ModelsAnimation | null;
  exit?: ModelsAnimation | null;
}

export interface ModelsEventData {
  attendeeEmails: string;
  eventFirstParagraph: string;
  eventTitle: string;
  externalEventId: string;
  hostShoutOut: string;
  orgId: string;
  orgName: string;
  organizerEmail: string;
  /** TODO: GDPR, clean if current user become an organizer */
  organizerName: string;
  prepMin: number;
  source: string;
  studioEventId: string;
  vipOnStage: boolean;
}

export interface ModelsEventIntakeFormData {
  additionalNotes?: string | null;
  attendedBefore?: string | null;
  canDisableFirewall?: string | null;
  celebration?: string | null;
  department?: string | null;
  halloweenEvent?: string | null;
  hasFirewall?: string | null;
  highlight?: string | null;
  holidayEvent?: string | null;
  languagePreference?: string | null;
  participantsCount?: string | null;
  prize?: string | null;
  reason?: string | null;
  tel?: string | null;
  website?: string | null;
}

export interface ModelsExtraSettings {
  oneTimePurchasePricingTable?: ModelsGamePackPrice[] | null;
  /** DTO only */
  syntheticOTPPricingTable?: ModelsGamePackPrice[] | null;
  templateId?: string | null;
  usingDefaultPricingTable?: boolean | null;
}

export interface ModelsFeatureSettings {
  liveBooking?: ModelsLiveBookingSettings | null;
  ondGamePlay?: ModelsOndGamePlaySettings | null;
  ondGameUGC?: ModelsOndGameUGCSettings | null;
  programAccess?: ModelsProgramAccessSettings | null;
  training?: ModelsTrainingSettings | null;
  venueSeatCap?: ModelsVenueSeatCapSettings | null;
}

export interface ModelsFillInTheBlanksFields {
  answerStyle: EnumsFillInTheBlanksAnswerStyle;
  bgMusic: ModelsBgMusic;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  personalityId: string;
  referenceId: string;
  segments: ModelsFillInTheBlanksSegment[];
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
  wrongAnswers: string[];
}

export interface ModelsFillInTheBlanksSegment {
  id: string;
  text: string;
  type: EnumsFillInTheBlanksSegmentType;
}

export interface ModelsFreeFormBody {
  json: ModelsJSONObject;
  jsonUpdatedAt: number;
  /**
   * In the generative AI, we can not directly generate the JSON structure of BlockNote easily. Instead, we use AI to
   * generate the Markdown content. But there is no cheap way to transform the Markdown into the JSON in the backend
   * code.
   * Here is a protocol, if the _freeFormMarkdown_ is available, the frontend (both editor and playback) should use
   * the Markdown over the JSON field. When the editor is opened, the frontend code should update JSON field from the
   * Markdown and set the Markdown to nullish.
   */
  markdown: string;
  markdownUpdatedAt: number;
}

export interface ModelsFunctionDefinition {
  description: string;
  id: string;
  name: string;
  parameters: string;
  required: boolean;
  strict: boolean;
}

export interface ModelsGameAvailability {
  launchDate: string;
  timezone: string;
}

export interface ModelsGamePackPrice {
  amount: number;
  archived: boolean;
  id: string;
  maxPlayers: number;
}

export enum ModelsGlobalPairingRoundStatus {
  GlobalPairingRoundStatusNew = 'New',
  GlobalPairingRoundStatusConfiguring = 'Configuring',
  GlobalPairingRoundStatusScheduled = 'Scheduled',
  GlobalPairingRoundStatusInProgress = 'InProgress',
  GlobalPairingRoundStatusCompleted = 'Completed',
}

export interface ModelsGlobalRoundConfig {
  /** deprecated */
  asLevels: boolean;
  participationMode: EnumsPairingParticipationMode;
}

export interface ModelsGreenScreenSettingsV0 {
  enabled: boolean;
  maskPct: ModelsRelativeRect;
  sharpPrefixedKeyColor: string;
  similarity: number;
  smoothness: number;
  spill: number;
}

export interface ModelsGridSize {
  numCols: number;
  numRows: number;
}

export interface ModelsGuessWhoFields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  internalLabel: string;
  intro: EnumsBlockIntro;
  introMedia: CommonMedia;
  introMediaData: ModelsMediaData;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  pointsPerCorrect: number;
  promptTimeSec: number;
  prompts: ModelsGuessWhoPrompt[];
  referenceId: string;
  showGuessers: boolean;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsGuessWhoPrompt {
  id: string;
  text: string;
}

export interface ModelsHeadToHeadFields {
  background: ModelsMediaAsset;
  bgMusic: ModelsBgMusic;
  cardSkippable: boolean;
  cards: ModelsHeadToHeadCard[];
  gameMode: EnumsH2HMode;
  gameName: string;
  gamePrompt: string;
  gameStarter: EnumsH2HGameStarter;
  gameTimeSec: number;
  internalLabel: string;
  intro: EnumsBlockIntro;
  introMedia: ModelsMediaAsset;
  introductoryVoiceOver: ModelsVoiceOver;
  judgingAfterXTurns: number;
  judgingHeadline: string;
  judgingInGameAfterXTurns: number;
  judgingInGameButtonText: string;
  judgingInGameSendingEmoji: string;
  judgingInGameSentiment: EnumsH2HJudgingSentiment;
  judgingMode: EnumsH2HJudgingMode;
  judgingPoints: number;
  judgingPrompt: string;
  judgingUserGroup: EnumsH2HJudgingUserGroup;
  judgingVoiceOver: ModelsVoiceOver;
  judgingWhenTimesUp: boolean;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  referenceId: string;
  replayable: boolean;
  selectPlayerStrategy: EnumsH2HSelectPlayerStrategy;
  showResults: boolean;
  subTimeSec: number;
  subTimerType: EnumsH2HSubTimerType;
  title: string;
  ttsOptions: ModelsTTSLabeledRenderSettings[];
  ttsScripts: ModelsTTSScript[];
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsHeyGenAvatar {
  avatar_id: string;
  avatar_name: string;
  preview_image_url: string;
  preview_video_url: string;
}

export interface ModelsHeyGenTemplate {
  name: string;
  template_id: string;
  thumbnail_image_url: string;
}

export interface ModelsHeyGenTemplateVariable {
  name: string;
  properties: ModelsHeyGenTemplateVariableVoice;
  /** audio */
  type: string;
}

export interface ModelsHeyGenTemplateVariableVoice {
  url: string;
}

export interface ModelsHiddenPicture {
  asymmetricGamePlay: boolean;
  asymmetricMedia: CommonMedia;
  asymmetricMediaData: ModelsMediaData;
  asymmetricPinDropAudibility: EnumsHiddenPictureAsymmetricPinDropAudibility;
  asymmetricPinDropVisibility: EnumsHiddenPictureAsymmetricPinDropVisibility;
  everyoneClicks: boolean;
  hidePointsAnimation: boolean;
  /** deprecated */
  hotSpots: ModelsHotSpot[];
  hotSpotsV2: ModelsHotSpotV2[];
  id: string;
  incorrectAnswerPenalty: number;
  mainMedia: CommonMedia;
  mainMediaData: ModelsMediaData;
  mode: EnumsHiddenPictureMode;
  name: string;
  pinDropHidden: boolean;
  pinDropMuted: boolean;
  question: string;
  sequenced: boolean;
  tool: string;
}

export interface ModelsHiddenPictureFields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  bonusPointsPerRemainingSec: number;
  failAudioMedia: CommonMedia;
  failAudioMediaData: ModelsMediaData;
  failMedia: CommonMedia;
  failMediaData: ModelsMediaData;
  gameCompletionMedia: CommonMedia;
  gameCompletionMediaData: ModelsMediaData;
  gameTimeSec: number;
  instructions: string;
  internalLabel: string;
  intro: EnumsBlockIntro;
  introMedia: CommonMedia;
  introMediaData: ModelsMediaData;
  lifecycleRules: ModelsBlockLifecycleRule[];
  maxPenaltyLimit: number;
  maxPenaltyLimitLabel: string;
  notes: string;
  outroMedia: CommonMedia;
  outroMediaData: ModelsMediaData;
  penaltiesFailureMedia: CommonMedia;
  penaltiesFailureMediaData: ModelsMediaData;
  penaltyResetStrategy: EnumsHiddenPicturePenaltyResetStrategy;
  personalityId: string;
  pictures: ModelsHiddenPicture[];
  randomizePictures: boolean;
  referenceId: string;
  showItemList: boolean;
  successAudioMedia: CommonMedia;
  successAudioMediaData: ModelsMediaData;
  successMedia: CommonMedia;
  successMediaData: ModelsMediaData;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsHotSpot {
  id: string;
  left: number;
  name: string;
  points: number;
  radius: number;
  top: number;
}

export interface ModelsHotSpotShapeData {
  circle: ModelsCircleHotSpotData;
  rectangle: ModelsRectangleHotSpotData;
}

export interface ModelsHotSpotV2 {
  id: string;
  name: string;
  points: number;
  shape: EnumsHiddenPictureHotSpotShape;
  shapeData: ModelsHotSpotShapeData;
}

export interface ModelsIcebreakerCard {
  hiddenToAudience: boolean;
  id: string;
  options: ModelsIcebreakerOption[];
  /** deprecated, use option.text instead */
  texts: string[];
}

export interface ModelsIcebreakerFields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  cardClickProgressesGame: boolean;
  cards: ModelsIcebreakerCard[];
  gameName: string;
  gameTimeSec: number;
  instructionsNotOnStage: string;
  instructionsOnStage: string;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  majorityVoteProgressesGame: boolean;
  mode: EnumsIcebreakerMode;
  muteBackgroundMusic: boolean;
  notes: string;
  offStageCardVoting: boolean;
  onStageCardSelection: boolean;
  /** deprecated, use SelectNextStrategy instead */
  onStageChooseNext: boolean;
  onStageSelection: EnumsIcebreakerOnStageSelection;
  onStageTimeSec: number;
  onStageTimerAutoStart: boolean;
  onStageTimerMode: EnumsIcebreakerOnStageTimerMode;
  playCardsInOrder: boolean;
  points: number;
  referenceId: string;
  /** deprecated, use OnStageSelection instead */
  selectFirstStrategy: EnumsIcebreakerSelectFirstStrategy;
  selectNextStrategy: EnumsIcebreakerSelectNextStrategy;
  /** deprecated, use OnStageSelection instead */
  selectPlayer: boolean;
  skipGameRecap: boolean;
  style: EnumsIcebreakerGameStyle;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsIcebreakerOption {
  audienceBackground: ModelsMediaAsset;
  hiddenToAudienceText: string;
  onStageBackground: ModelsMediaAsset;
  text: string;
}

export interface ModelsInstructionCard {
  animatedTransition: boolean;
  breakIntoTeams: boolean;
  fullscreen: boolean;
  id: string;
  media: CommonMedia;
  mediaData: ModelsMediaData;
  playBackgroundMusicWithMedia: boolean;
  teamIntroEnabled: boolean;
  text: string;
  voiceOver: ModelsVoiceOver;
}

export interface ModelsInstructionFields {
  bgMusic: ModelsBgMusic;
  cards: ModelsInstructionCard[];
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  referenceId: string;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsIntrosProgramLinkExtensions {
  introMessageTemplate?: ModelsMessageTemplateV2 | null;
}

export interface ModelsIntrosRoundExtensions {
  completedGroupsCount: number;
  completedPlayersCount: number;
  frequency: EnumsProgramCadenceFrequency;
  groupsCount: number;
  playersCount: number;
}

export type ModelsJSONObject = Record<string, any>;

export interface ModelsJeopardyBoard {
  categories: ModelsJeopardyCategory[];
  /** Training only */
  name: string;
}

export interface ModelsJeopardyCategory {
  clues: ModelsJeopardyClue[];
  id: string;
  name: string;
  nameScript: string;
}

export interface ModelsJeopardyClue {
  answer: string;
  answerChoices: ModelsMultipleChoiceOption[];
  id: string;
  question: string;
  type: EnumsJeopardyClueType;
  value: number;
}

export interface ModelsJeopardyFields {
  answerPrepareTimeSec: number;
  answerTimeSec: number;
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  board: ModelsJeopardyBoard;
  boardSize: ModelsGridSize;
  buzzerTimeSec: number;
  clueSelectionTimeSec: number;
  internalLabel: string;
  intro: EnumsBlockIntro;
  judgingTimeSec: number;
  lifecycleRules: ModelsBlockLifecycleRule[];
  /** deprecated, all media is dynamic now */
  mediaLookup: Record<string, ModelsMediaContainer>;
  notes: string;
  outroMedia: CommonMedia;
  outroMediaData: ModelsMediaData;
  personalityId: string;
  referenceId: string;
  title: string;
  ttsOptions: ModelsTTSLabeledRenderSettings[];
  ttsScripts: ModelsTTSScript[];
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsJeopardySessionData {
  history: ModelsJeopardyTurnHistory[];
  score: number;
}

export interface ModelsJeopardyTurnHistory {
  clueId: string;
  points: number;
  result: EnumsJeopardyTurnResult;
  total: number;
}

export interface ModelsLearnerAssignmentDetail {
  courses?: ModelsAssignedCourse[] | null;
  firstName: string;
  groupNames?: string[] | null;
  lastName: string;
  stacks?: ModelsAssignedStack[] | null;
  uniqueCourses?: ModelsAssignedCourse[] | null;
  userEmail: string;
  userId: string;
}

export interface ModelsLearningGroup {
  createdAt: string;
  creatorUid: string;
  id: string;
  name: string;
  orgId: string;
  updatedAt: string;
}

export interface ModelsLiveBookingSettings {
  access: EnumsLiveBookingAccess;
  discount?: number | null;
}

export interface ModelsLiveEventHost {
  active: boolean;
  email: string;
  name: string;
  uid: string;
}

export interface ModelsLiveEventProducer {
  active: boolean;
  email: string;
  name: string;
  uid: string;
}

export interface ModelsLiveEventStudio {
  calendarId: string;
  id: string;
  name: string;
}

export interface ModelsLogicSettings {
  blockLogic: Record<string, any>;
}

export interface ModelsMarkAsNew {
  expiredAt: number;
}

export interface ModelsMarketingFields {
  bgMusic: ModelsBgMusic;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  referenceId: string;
  skippableAfterSec: number;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsMarketingSettings {
  anonymousFAQGroupIds?: string[] | null;
  calendarInviteMessage?: string | null;
  faqGroupIds?: string[] | null;
  gameBackground?: ModelsMediaAsset | null;
  gameTrailer?: ModelsMediaAsset | null;
  joyCaptureBackground?: ModelsMediaAsset | null;
  lobbyBackground?: ModelsMediaAsset | null;
  musicPlaylistId?: string | null;
  sharableMarketingMaterials?: ModelsMediaAsset | null;
  showcaseCardIds?: string[] | null;
  useShowcaseCards?: boolean | null;
}

export interface ModelsMediaAsset {
  data?: ModelsMediaData | null;
  media?: CommonMedia | null;
}

export interface ModelsMediaContainer {
  media: CommonMedia;
  mediaData: ModelsMediaData;
}

export interface ModelsMediaData {
  id: string;
  loop?: boolean;
  volumeLevel?: EnumsVolumeLevel;
}

export interface ModelsMemoryMatchCardPair {
  firstMedia: CommonMedia;
  firstMediaId: string;
  firstText: string;
  secondMedia: CommonMedia;
  secondMediaId: string;
  secondText: string;
}

export interface ModelsMemoryMatchFields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  cardPairs: ModelsMemoryMatchCardPair[];
  decreasingPointsTimer: boolean;
  gameTimeSec: number;
  goalAnimationMedia: CommonMedia;
  goalAnimationMediaData: ModelsMediaData;
  hiddenGameplay: boolean;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  numberOfCardPairs: number;
  personalityId: string;
  pointsPerMatch: number;
  realtimeLeaderboard: boolean;
  referenceId: string;
  startDescendingImmediately: boolean;
  text: string;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export enum ModelsMessageCampaignType {
  MessageCampaignTypeTournament = 'Tournament',
  MessageCampaignTypeGlobalRound = 'GlobalRound',
  MessageCampaignTypeGlobalPromotion = 'GlobalPromotion',
}

export interface ModelsMessageCampaignV2 {
  description: string;
  id: string;
  name: string;
  type: EnumsMessageCampaignType;
}

export interface ModelsMessageCondition {
  expression: string;
}

export interface ModelsMessageContent {
  blocks: string;
  buttonList: ClientMessageButton[];
  channelId: string;
  iconUrl: string;
  mediaList: ClientMessageMedia[];
  text: string;
  urlButtonList: ClientMessageUrlButton[];
  username: string;
}

export enum ModelsMessageStatus {
  MessageStatusPending = 'Pending',
  MessageStatusSuccess = 'Success',
  MessageStatusError = 'Error',
  MessageStatusDeleted = 'Deleted',
}

export interface ModelsMessageTemplateEmail {
  bcc?: string[] | null;
  body: string;
  buttons: ClientButton[];
  cc?: string[] | null;
  from: ModelsEmailAddress;
  replyTo?: string[] | null;
  subject: string;
}

export interface ModelsMessageTemplateMedia {
  media?: CommonMedia | null;
  mediaData?: ModelsMediaData | null;
  predefined?: string | null;
  source?: EnumsMessageTemplateMediaSource | null;
}

export interface ModelsMessageTemplateSlack {
  body: string;
  buttons?: ClientMessageButton[] | null;
}

export interface ModelsMessageTemplateV2 {
  buttons?: ClientMessageButton[] | null;
  email?: ModelsMessageTemplateEmail | null;
  format?: EnumsMessageTemplateFormat | null;
  media?: ModelsMessageTemplateMedia | null;
  slack?: ModelsMessageTemplateSlack | null;
  /** DistributionChannel = nil */
  text?: string | null;
  type?: EnumsMessageDistributionChannel | null;
}

export interface ModelsMessageTrigger {
  eventName?: EnumsMessageTriggerEventName | null;
  timeOffset?: ModelsMessageTriggerTimeOffset | null;
  type?: EnumsMessageTriggerType | null;
  weekdaysOffset?: ModelsMessageTriggerWeekdaysOffset | null;
}

export interface ModelsMessageTriggerTimeOffset {
  days: number;
  direction: EnumsMessageTriggerTimeOffsetDirection;
  hours: number;
  minutes: number;
  triggerTime?: string | null;
}

export interface ModelsMessageTriggerWeekdaysOffset {
  days: number;
  direction: EnumsMessageTriggerTimeOffsetDirection;
  hours: number;
  minutes: number;
  triggerTime?: string | null;
}

export interface ModelsMinigameProgression {
  completedAt?: string | null;
  lastCompletedBlockId?: string | null;
  lastStartedAt?: string | null;
  minigameId: string;
  unlockedAt: string;
}

export type ModelsMinigameProgressionMap = Record<string, ModelsMinigameProgression>;

export interface ModelsMultipleChoiceFields {
  answerChoices: ModelsMultipleChoiceOption[];
  answerMedia: CommonMedia;
  answerMediaData: ModelsMediaData;
  backgroundMedia: CommonMedia;
  backgroundMediaId: string;
  bgMusic: ModelsBgMusic;
  decreasingPointsTimer: boolean;
  displayPointsMultiplier: boolean;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  personalityId: string;
  points: number;
  question: string;
  questionMedia: CommonMedia;
  questionMediaData: ModelsMediaData;
  questionTimeSec: number;
  referenceId: string;
  startDescendingImmediately: boolean;
  startVideoWithTimer: boolean;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsMultipleChoiceOption {
  correct: boolean;
  media: CommonMedia;
  mediaId: string;
  text: string;
}

export interface ModelsNotificationFeatures {
  analyticsDigest: boolean;
  gameRecaps: boolean;
  gameUpdates: boolean;
  onboardingGuide: boolean;
}

export interface ModelsNotificationSettings {
  analyticsDigestEmail: boolean;
  analyticsDigestSlack: boolean;
  disabled: boolean;
  emailFrequency: EnumsNotificationFrequencyEmail;
  gameRecapsSlack: boolean;
  notInterestedProgramIds: string[];
  onboardingGuides: boolean;
  pendingPurchaseReminder: boolean;
  recognitionGiven: boolean;
  recognitionReceived: boolean;
  recognitionWeeklyReport: boolean;
  slackFrequency: EnumsNotificationFrequencySlack;
}

export interface ModelsOnboardTaskAction {
  text: string;
  url: string;
}

export interface ModelsOnboardTaskBody {
  sections: ModelsOnboardTaskBodySection[];
  showNextRound: boolean;
  timeToCompleteInSecs: number;
}

export interface ModelsOnboardTaskBodySection {
  header: string;
  items: string[];
}

export interface ModelsOndGamePlaySettings {
  access: EnumsOndGamePlayAccess;
  gamePackIds?: string[] | null;
  gamePackTagIds?: number[] | null;
}

export interface ModelsOndGameUGCSettings {
  access: EnumsOndGameUGCAccess;
}

export interface ModelsOpenAISettings {
  modelId?: string | null;
  speed?: number | null;
}

export interface ModelsOrgPromotionSettings {
  pauseDEI: boolean;
  pauseGameUpdateSlack: boolean;
  pauseGameUpdatesEmail: boolean;
}

export interface ModelsOrgPurchase {
  gamePackIds: string[];
}

export interface ModelsOrgSettings {
  allowLiveEventsDuringTrial: boolean;
  brandColor: string;
  cancelOfferDisabled: boolean;
  enforceSeatCap: boolean;
  nonAdminScheduleLiveEventsDisabled: boolean;
  onboardingCallScheduled: boolean;
  promotion: ModelsOrgPromotionSettings;
  venueConnectivityTestDisabled: boolean;
}

export enum ModelsOrganizerRole {
  OrganizerRoleDefault = 0,
  OrganizerRoleOwner = 1,
  OrganizerRoleAdmin = 2,
}

export interface ModelsOverRoastedFields {
  bgMusic: ModelsBgMusic;
  dispensersCountPerTruck: number;
  gameTimeSec: number;
  internalLabel: string;
  intro: EnumsBlockIntro;
  introMedia: CommonMedia;
  introMediaData: ModelsMediaData;
  lifecycleRules: ModelsBlockLifecycleRule[];
  maxIngredientsPerOrder: number;
  maxIngredientsPerPlayer: number;
  notes: string;
  outroMedia: CommonMedia;
  outroMediaData: ModelsMediaData;
  pointsPerOrder: number;
  referenceId: string;
  title: string;
  trucksCount: number;
  tutorialMode: boolean;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsPageRow {
  anchor: string;
  id: string;
  layout?: EnumsPageRowLayout | null;
  tag?: ModelsTag | null;
  tagCollection?: ModelsTagCollection | null;
  type: EnumsPageRowType;
}

export interface ModelsPageSection {
  anchor: string;
  hideFromNav: boolean;
  icon?: ModelsMediaAsset | null;
  id: string;
  layoutStyle: EnumsPageSectionLayoutStyle;
  rows?: ModelsPageRow[] | null;
  showDemoButton: boolean;
  showSocialProof: boolean;
  subtitle: string;
  title: string;
  type: EnumsPageSectionType;
  url?: ModelsPageSectionUrl | null;
  video?: ModelsMediaAsset | null;
  videoText: string;
}

export interface ModelsPageSectionUrl {
  text: string;
  url: string;
}

export interface ModelsPairingPartnerSuggestions {
  exUserIds: string[];
}

export interface ModelsPairingRepairData {
  pairings: ModelsRepairedPairing[];
}

export enum ModelsPairingRoundStatus {
  PairingRoundStatusActive = 'Active',
  PairingRoundStatusPaused = 'Paused',
}

export interface ModelsPath {
  brushColor: string;
  brushSize: number;
  points: ModelsPoint[];
}

export interface ModelsPersonality {
  avatarId?: string | null;
  /** a description of the personality. */
  description: string;
  /** a display label like "John (Voice Only)" */
  displayLabel: string;
  /** the name of this personality "Sally", "Linus", "Chuck", etc. */
  name: string;
  profileImage?: ModelsMediaAsset | null;
  /** used for spark personality select in the editor. */
  publiclyListed: boolean;
  voiceId: string;
}

export interface ModelsPlaybackSettings {
  defaultUnitsPerSession: number;
  gameMakeup?: EnumsGamePackMakeup | null;
  instructionRules?: EnumsGamePackInstructionRule | null;
  leaderboardRules?: EnumsGamePackLeaderboardRule | null;
  lockUnitsPerSession: boolean;
  makeUnitsFrom?: EnumsGamePackMakeUnitsFrom | null;
  newUserTutorialBlockId?: string | null;
  randomizeUnitOrder: boolean;
  resumeFromLastUnitPlayed: boolean;
}

export interface ModelsPlayerRange {
  max?: number;
  /** @min 0 */
  min: number;
}

export interface ModelsPodiumSettings {
  enabled: boolean;
  id: string;
}

export interface ModelsPoint {
  ts: number;
  x: number;
  y: number;
}

export interface ModelsPredefinedBlockItem {
  id: string;
  scenario: EnumsBrandPredefinedBlockScenario;
}

export interface ModelsPrice {
  amount: number;
  archived: boolean;
  billingInterval: EnumsProductBillingInterval;
  id: string;
  maxSeats: number;
  stripePriceId?: string | null;
}

export interface ModelsProductFeature {
  createdAt: string;
  description?: string | null;
  featureSettings: ModelsFeatureSettings;
  id: string;
  name: string;
  productId: string;
  type: EnumsProductFeatureType;
  updatedAt: string;
}

export interface ModelsProgramAccessSettings {
  access: EnumsProgramAccess;
  programIds?: string[] | null;
  /** this is assembled and not persisted */
  programTypes?: EnumsProgramType[] | null;
}

export interface ModelsProgramBasicSettings {
  background?: {
    media?: CommonMedia | null;
    mediaData?: ModelsMediaData | null;
  };
  banner?: {
    media?: CommonMedia | null;
    mediaData?: ModelsMediaData | null;
  };
  cover?: {
    media?: CommonMedia | null;
    mediaData?: ModelsMediaData | null;
  };
  headline?: string | null;
  headlineColor?: string | null;
  /**
   * the fields below are used in the existing hard-coded programs, they are not in the new spec, we keep them
   * for backward compatibility.
   */
  icon?: {
    media?: CommonMedia | null;
    mediaData?: ModelsMediaData | null;
  };
  longDescription: string;
  richDescription?: string | null;
  shortDescription: string;
  themeColor?: string | null;
  trailer?: {
    media?: CommonMedia | null;
    mediaData?: ModelsMediaData | null;
  };
}

export interface ModelsProgramCadenceSettings {
  frequency: EnumsProgramCadenceFrequency;
  nextTriggerTime: string;
  timezone: string;
  weekdays: number[];
}

export interface ModelsProgramGroupSettings {
  participationMode: EnumsPairingParticipationMode;
  size: number;
}

export interface ModelsProgramMarketingMetadata {
  anonymousFAQGroupIds?: string[] | null;
  faqGroupIds?: string[] | null;
  stats: ModelsProgramMarketingStat[];
  tutorials: ModelsProgramMarketingTutorial[];
}

export interface ModelsProgramMarketingStat {
  description: string;
  id: string;
  value: string;
}

export interface ModelsProgramMarketingTutorial {
  description: string;
  id: string;
  media?: ModelsMediaAsset | null;
}

export interface ModelsProgramPromotionalAssets {
  description: string;
}

export interface ModelsProgramTagSettings {
  selectedTagIds: number[];
}

export interface ModelsPromptConfig {
  templateId: string;
  templateName?: string | null;
}

export interface ModelsPromptMessage {
  text: string;
  type: ClientMessageType;
}

export interface ModelsPromptTemplateSettings {
  fileSearchEnhancement: boolean;
}

export interface ModelsPuzzleFields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  completionBonusPoints: number;
  decreasingPointsTimer: boolean;
  dropSpots: ModelsDropSpot[];
  gameTimeSec: number;
  goalAnimationMedia: CommonMedia;
  goalAnimationMediaData: ModelsMediaData;
  gradeOnPlacement: boolean;
  gridSize: ModelsGridSize;
  internalLabel: string;
  intro: EnumsBlockIntro;
  introMedia: CommonMedia;
  introMediaData: ModelsMediaData;
  lifecycleRules: ModelsBlockLifecycleRule[];
  mode: EnumsPuzzleMode;
  notes: string;
  outroMedia: CommonMedia;
  outroMediaData: ModelsMediaData;
  pieces: ModelsPuzzlePiece[];
  pointsPerCorrectPiece: number;
  referenceId: string;
  showCompletionProgress: boolean;
  showPreview: boolean;
  sliceJob: ModelsPuzzleSliceJob;
  startDescendingImmediately: boolean;
  text: string;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsPuzzlePiece {
  id: string;
  media: CommonMedia;
  mediaId: string;
  value: string;
}

export interface ModelsPuzzleSliceJob {
  gridSize: ModelsGridSize;
  id: string;
  sourceMedia: CommonMedia;
  sourceMediaData: ModelsMediaData;
  status: EnumsMediaTranscodeStatus;
}

export interface ModelsQuestionFields {
  additionalAnswers: string;
  answer: string;
  /** view only */
  answerMedia: CommonMedia;
  answerMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  decreasingPointsTimer: boolean;
  displayPointsMultiplier: boolean;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  mediaUrl: string;
  notes: string;
  personalityId: string;
  points: number;
  question: string;
  /** view only */
  questionMedia: CommonMedia;
  questionMediaData: ModelsMediaData;
  referenceId: string;
  scoreboard: boolean;
  startDescendingImmediately: boolean;
  startVideoWithTimer: boolean;
  time: number;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsRandomizerFields {
  bgMusic: ModelsBgMusic;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  referenceId: string;
  t1TeamSize: number;
  t1TeamSizeMax: number;
  t2TeamSize: number;
  t2TeamSizeMax: number;
  t3TeamSize: number;
  t3TeamSizeMax: number;
  t4TeamSize: number;
  t4TeamSizeMax: number;
  t5TeamSize: number;
  t5TeamSizeMax: number;
  timer: number;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsRapidFields {
  answerMedia: CommonMedia;
  answerMediaData: ModelsMediaData;
  answers: string;
  bgMusic: ModelsBgMusic;
  everyoneSubmits: boolean;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  question: string;
  questionMedia: CommonMedia;
  questionMediaData: ModelsMediaData;
  questionTime: number;
  referenceId: string;
  scoreboard: boolean;
  showMissedAnswers: boolean;
  startVideoWithTimer: boolean;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsRecipient {
  email: string;
  exUserId: string;
  name: string;
  uid: string;
}

export interface ModelsRecipientData {
  exGroupId: string;
  mode: ModelsRecipientMode;
  recipients: ModelsRecipient[];
}

export enum ModelsRecipientMode {
  RecipientModeUsers = 'Users',
  RecipientModeAll = 'All',
}

export interface ModelsRecognitionEmoji {
  default: boolean;
  /** Taco */
  name: string;
  /** 🌮 */
  token: string;
  /** :taco: */
  value: string;
}

export interface ModelsRectangleHotSpotData {
  height: number;
  left: number;
  top: number;
  width: number;
}

export interface ModelsRelativeRect {
  bottom: number;
  left: number;
  right: number;
  top: number;
}

export interface ModelsRepairedPairing {
  pairingId: string;
  playerUids: string[];
}

export enum ModelsReportTimeRange {
  ReportTimeRangeThreeMonths = '3months',
  ReportTimeRangeSixMonths = '6months',
  ReportTimeRangeTwelveMonths = '12months',
  ReportTimeRangeAllTime = 'all time',
}

export interface ModelsRoleplayCriterion {
  description?: string;
  weight?: EnumsRoleplayCriterionWeight;
}

export interface ModelsRoleplayEvaluation {
  criteria: ModelsRoleplayEvaluationCriterion[];
}

export interface ModelsRoleplayEvaluationCriterion {
  id: string;
  name: string;
  prompt: string;
}

export interface ModelsRoleplayEvaluationResult {
  id: string;
  name: string;
  prompt: string;
  reason: string;
  result: string;
}

export interface ModelsRoleplayFailureCriterion {
  description: string;
  result: EnumsRoleplayFailureCriterionResult;
}

export interface ModelsRoleplayFields {
  bgMusic: ModelsBgMusic;
  evaluation: ModelsRoleplayEvaluation;
  failureCriteria: ModelsRoleplayCriterion[];
  failureMedia: ModelsMediaAsset;
  internalLabel: string;
  intro: EnumsBlockIntro;
  introButtonText: string;
  introMedia: ModelsMediaAsset;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  personalityId: string;
  playingButtonText: string;
  playingMedia: ModelsMediaAsset;
  referenceId: string;
  successCriteria: ModelsRoleplayCriterion[];
  successMedia: ModelsMediaAsset;
  systemPrompt: string;
  title: string;
  turnEndMode: EnumsRoleplayTurnEndMode;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
  voice: EnumsRoleplayVoice;
}

export interface ModelsRoleplayHistoryAnalysis {
  summary: string;
}

export interface ModelsRoleplayHistoryEvaluation {
  results: ModelsRoleplayEvaluationResult[];
}

export interface ModelsRoleplayHistoryFeedback {
  failureCriteria: ModelsRoleplayFailureCriterion[];
  result: string;
  successCriteria: ModelsRoleplaySuccessCriterion[];
}

export interface ModelsRoleplayHistoryTranscript {
  speaker: string;
  text: string;
}

export interface ModelsRoleplayRating {
  comment?: string | null;
  score: number;
}

export interface ModelsRoleplaySuccessCriterion {
  description: string;
  result: EnumsRoleplaySuccessCriterionResult;
}

export interface ModelsRoundRobinAnswerOrder {
  orderedAnswerIds: string[];
  randomize: boolean;
}

export interface ModelsRoundRobinClue {
  clueId: string;
  points: number;
  text: string;
  title: string;
}

export interface ModelsRoundRobinIncorrectAnswer {
  id: string;
  text: string;
}

export interface ModelsRoundRobinQuestion {
  /** view only */
  answerMedia: CommonMedia;
  answerMediaId: string;
  answerOrder: ModelsRoundRobinAnswerOrder;
  answers: string;
  backgroundMedia: CommonMedia;
  backgroundMediaId: string;
  clues: ModelsRoundRobinClue[];
  createdAt: number;
  incorrectAnswers: ModelsRoundRobinIncorrectAnswer[];
  points: number;
  question: string;
  /** view only */
  questionMedia: CommonMedia;
  questionMediaId: string;
  questionMediaLoop: boolean;
  timeSec: number;
}

export interface ModelsRoundRobinQuestionFields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  clueTimeSec: number;
  decreasingPointsTimer: boolean;
  gameTimeSec: number;
  goalAnimationMedia: CommonMedia;
  goalAnimationMediaData: ModelsMediaData;
  hideAnswerField: boolean;
  hotSeatUI: boolean;
  incorrectAnswerPenalty: boolean;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  mode: EnumsRoundRobinMode;
  muteBackgroundMusic: boolean;
  notes: string;
  questions: ModelsRoundRobinQuestion[];
  questionsSkippable: boolean;
  raceUnitMedia: CommonMedia;
  raceUnitMediaData: ModelsMediaData;
  raceWinPercentage: number;
  randomizeQuestions: boolean;
  rapidSubmissions: boolean;
  referenceId: string;
  replayIncorrectQuestions: boolean;
  startDescendingImmediately: boolean;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsScoreboardFields {
  bgMusic: ModelsBgMusic;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  mode: number;
  notes: string;
  referenceId: string;
  title: string;
  ttsOptions: ModelsTTSLabeledRenderSettings[];
  ttsScripts: ModelsTTSScript[];
  ugcAssetId: string;
  ugcPromptTemplateId: string;
  voiceOver: ModelsVoiceOver;
}

export enum ModelsSessionMode {
  SessionModeLive = 'Live',
  SessionModeOnDemand = 'OnDemand',
}

export enum ModelsSessionStatus {
  SessionStatusLive = 'Live',
  SessionStatusEnded = 'Ended',
  SessionStatusAborted = 'Aborted',
}

export interface ModelsSettingsDataGamePacks {
  featured: string[];
  free: string[];
  newUserTutorialBlockId: string;
  /** Deprecated, the templates are managed by UGCTemplateTagId */
  templates: string[];
  zoomFree: string[];
}

export interface ModelsSettingsLiveEvent {
  hosts: ModelsLiveEventHost[];
  producers: ModelsLiveEventProducer[];
  studios: ModelsLiveEventStudio[];
  venueIds: string[];
}

export interface ModelsSettingsOneTimePurchase {
  pricingTable: ModelsGamePackPrice[];
}

export interface ModelsSettingsPage {
  background?: ModelsMediaAsset | null;
  rows?: ModelsPageRow[] | null;
  sections?: ModelsPageSection[] | null;
}

export type ModelsSettingsPages = Record<string, ModelsSettingsPage>;

export interface ModelsSettingsSharedTTSScripts {
  ttsScripts: ModelsTTSScript[];
}

export interface ModelsSettingsVoiceOverLocales {
  voiceOverLocales: Record<string, ModelsVoiceOverLocale>;
}

export interface ModelsSharedAssetSong {
  id: string;
  media?: CommonMedia | null;
  mediaId: string;
  /** used for spark personality select in the editor. */
  publiclyListed: boolean;
  title: string;
}

export interface ModelsSlideFields {
  animations: ModelsBlockAnimations;
  backgroundMedia: ModelsMediaAsset;
  backgroundOption: EnumsSlideBackgroundOption;
  bgMusic: ModelsBgMusic;
  bulletPoints: string[];
  buttonText: string;
  creditName: string;
  dialogue: ModelsDialogue;
  freeFormBody: ModelsFreeFormBody;
  heading: string;
  hideButton: boolean;
  internalLabel: string;
  intro: EnumsBlockIntro;
  layout: EnumsSlideLayout;
  lifecycleRules: ModelsBlockLifecycleRule[];
  media: ModelsMediaAsset;
  notes: string;
  quote: string;
  referenceId: string;
  showLogo: boolean;
  subtitle: string;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsSpotlightFields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  message: string;
  notes: string;
  overlayMedia: CommonMedia;
  overlayMediaId: string;
  playConfetti: boolean;
  preselectedTeamOrder: number;
  referenceId: string;
  stageLayout: EnumsSpotlightBlockStageLayout;
  title: string;
  ttsOptions: ModelsTTSLabeledRenderSettings[];
  ttsScripts: ModelsTTSScript[];
  ugcAssetId: string;
  ugcPromptTemplateId: string;
  voiceOver: ModelsVoiceOver;
}

export interface ModelsSpotlightV2Fields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  instantWinnerPoints: number;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  message: string;
  notes: string;
  overlayMedia: CommonMedia;
  overlayMediaId: string;
  preselectedMaxParticipants: number;
  preselectedTeamOrder: number;
  referenceId: string;
  title: string;
  ttsOptions: ModelsTTSLabeledRenderSettings[];
  ttsScripts: ModelsTTSScript[];
  ugcAssetId: string;
  ugcPromptTemplateId: string;
  voiceOver: ModelsVoiceOver;
  votingMode: boolean;
  votingPoints: number;
}

export interface ModelsStack {
  createdAt: string;
  creatorUid: string;
  id: string;
  name: string;
  orgId: string;
  updatedAt: string;
}

export interface ModelsStageSettings {
  enabled: boolean;
  id: string;
}

export interface ModelsSwipeToWinCardPair {
  firstMedia?: ModelsMediaAsset | null;
  firstText: string;
  secondMedia?: ModelsMediaAsset | null;
  secondText: string;
}

export interface ModelsSwipeToWinFields {
  bgMusic: ModelsBgMusic;
  cardPairs: ModelsSwipeToWinCardPair[];
  instruction: string;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  personalityId: string;
  pointsPerMatch: number;
  referenceId: string;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsTTSElevenLabsSettings {
  modelId?: string | null;
  similarityBoost?: number | null;
  stability?: number | null;
  voiceId: string;
}

export interface ModelsTTSGeneratorSettings {
  elevenLabs?: ModelsTTSElevenLabsSettings | null;
  openAI?: ModelsTTSOpenAISettings | null;
}

export interface ModelsTTSLabeledRenderSettings {
  generatorId: EnumsTTSGeneratorId;
  generatorSettings: ModelsTTSGeneratorSettings;
  id: string;
  label: string;
}

export interface ModelsTTSOpenAISettings {
  modelId?: string | null;
  speed?: number | null;
  voiceId: string;
}

export interface ModelsTTSPlaybackOptions {
  delayMs: number;
  id: string;
  tags: string[];
}

export interface ModelsTTSRenderSettings {
  generatorId: EnumsTTSGeneratorId;
  generatorSettings: ModelsTTSGeneratorSettings;
}

export interface ModelsTTSScript {
  id: string;
  script: string;
  tags: string[];
  voiceId?: string | null;
}

export interface ModelsTag {
  background: ModelsMediaAsset;
  createdAt?: string;
  description: string;
  extensions: ModelsTaggedObjectExtension[];
  icon: ModelsMediaAsset;
  id: number;
  name: string;
  rawSlug: string;
  shortAlias: string;
  styles: ModelsTagStyles;
  supportingMedia: ModelsMediaAsset;
  updatedAt?: string;
}

export interface ModelsTagCollection {
  ids: string[];
  name: string;
  tags?: ModelsTag[] | null;
}

export interface ModelsTagStyles {
  backgroundColor1: string;
  backgroundColor2: string;
  titleTextColor: string;
}

export interface ModelsTaggedObjectExtension {
  createdAt?: string;
  featuredObjectIds: string[];
  objectType: EnumsRefObjectType;
  objectsCount: number;
  tagId: number;
  updatedAt?: string;
}

export interface ModelsTeamRandomizationSettings {
  oneTeam: boolean;
  t1TeamSize: number;
  t1TeamSizeMax: number;
  t2TeamSize: number;
  t2TeamSizeMax: number;
  t3TeamSize: number;
  t3TeamSizeMax: number;
  t4TeamSize: number;
  t4TeamSizeMax: number;
  t5TeamSize: number;
  t5TeamSizeMax: number;
  timer: number;
}

export interface ModelsTeamRelayFields {
  backgroundMedia: CommonMedia;
  backgroundMediaData: ModelsMediaData;
  bgMusic: ModelsBgMusic;
  decreasingPointsTimer: boolean;
  difficultyLevel: number;
  goalAnimationMedia: CommonMedia;
  goalAnimationMediaData: ModelsMediaData;
  internalLabel: string;
  intro: EnumsBlockIntro;
  introMedia: CommonMedia;
  introMediaData: ModelsMediaData;
  lifecycleRules: ModelsBlockLifecycleRule[];
  mode: number;
  movingObjectMedia: CommonMedia;
  /** Mode specific fields */
  movingObjectMediaId: string;
  notes: string;
  outroMedia: CommonMedia;
  outroMediaData: ModelsMediaData;
  points: number;
  referenceId: string;
  sequenceTime: number;
  startDescendingImmediately: boolean;
  stationaryGoalMedia: CommonMedia;
  stationaryGoalMediaId: string;
  text: string;
  title: string;
  ugcAssetId: string;
  ugcPromptTemplateId: string;
}

export interface ModelsTitleCard {
  animatedTransition: boolean;
  breakIntoTeams: boolean;
  fullscreen: boolean;
  id: string;
  media: CommonMedia;
  mediaData: ModelsMediaData;
  playBackgroundMusicWithMedia: boolean;
  teamIntroEnabled: boolean;
  text: string;
  voiceOver: ModelsVoiceOver;
}

export interface ModelsTitleV2Fields {
  bgMusic: ModelsBgMusic;
  cards: ModelsTitleCard[];
  /** deprecated, use lifecycle rules instead */
  hideCohost: boolean;
  internalLabel: string;
  intro: EnumsBlockIntro;
  lifecycleRules: ModelsBlockLifecycleRule[];
  notes: string;
  referenceId: string;
  title: string;
  ttsOptions: ModelsTTSLabeledRenderSettings[];
  ttsPlaybackOptions: ModelsTTSPlaybackOptions[];
  ttsScripts: ModelsTTSScript[];
  ugcAssetId: string;
  ugcPromptTemplateId: string;
  waitAfterEachCardSec: number;
}

export interface ModelsTrainingProfile {
  blockBgMusic?: ModelsPromptConfig | null;
  blockDialogue?: ModelsPromptConfig | null;
  blocks?: ModelsBlockPromptConfig[] | null;
  createdAt: string;
  id: string;
  locked: boolean;
  mediaSearchKeywords?: ModelsPromptConfig | null;
  mediaSelection?: ModelsPromptConfig | null;
  moduleOutline?: ModelsPromptConfig | null;
  name: string;
  topicBlockOverview?: ModelsPromptConfig | null;
  topicOutline?: ModelsPromptConfig | null;
  updatedAt: string;
}

export interface ModelsTrainingSettings {
  access: EnumsTrainingAccess;
}

export interface ModelsTriggerDetails {
  delayedSeconds: number;
  hours: number[];
  minutes: number[];
  weekdays: number[];
}

export interface ModelsUgcSettings {
  creationStatus?: EnumsGamePackUGCCreationStatus | null;
  promptOptimizerTemplateId?: string | null;
  promptTemplateId?: string | null;
  trainingEditorChatProfileId?: string | null;
  trainingPromptProfileId?: string | null;
  userDirection?: string | null;
}

export interface ModelsVariableDefinition {
  description: string;
  id: string;
  name: string;
  required: boolean;
}

export interface ModelsVenueSeatCapSettings {
  seatCap: number;
}

export interface ModelsVideoEffectsSettings {
  boundingBox: ModelsBoundingBoxSettings;
  greenScreen: ModelsGreenScreenSettingsV0;
  podium: ModelsPodiumSettings;
  stage: ModelsStageSettings;
}

export interface ModelsVoiceOver {
  fallback?: ModelsVoiceOverMedia | null;
  /** these values are never persisted and only set after the block has been prepared for runtime playback. */
  playbackMedia?: CommonMedia | null;
  playbackMediaData?: ModelsMediaData | null;
  runtime?: ModelsVoiceOverRenderDescription | null;
}

export interface ModelsVoiceOverLocale {
  locale: string;
  previewScript: string;
  voiceId: string;
}

export interface ModelsVoiceOverMedia {
  media?: CommonMedia;
  mediaData?: ModelsMediaData;
  renderDescription?: ModelsVoiceOverRenderDescription;
}

export interface ModelsVoiceOverRenderDescription {
  delayStartMs?: number | null;
  generatorId: EnumsTTSGeneratorId;
  script: string;
  settings?: ModelsVoiceOverRenderSettings | null;
  voiceId: string;
  /** relevant for runtime generated media. */
  volumeLevel?: EnumsVolumeLevel | null;
}

export interface ModelsVoiceOverRenderSettings {
  elevenLabs?: ModelsElevenLabsSettings | null;
  noInjectedScripts?: boolean | null;
  openAI?: ModelsOpenAISettings | null;
  /** used for llm code-fences */
  systemPromptId?: string | null;
}

export interface ModelsWaterCoolerMedia {
  media?: CommonMedia | null;
  mediaData?: ModelsMediaData | null;
}

export interface ModelsWaterCoolerProgramExtensions {
  roundIds: string[];
}

export interface ModelsWaterCoolerProgramLinkExtensions {
  links: ModelsWaterCoolerTopicLink[];
}

export interface ModelsWaterCoolerRoundExtensions {
  media?: ModelsWaterCoolerMedia | null;
  snapshot?: ModelsWaterCoolerSnapshot | null;
  stats?: ModelsWaterCoolerRoundStats | null;
  text: string;
}

export interface ModelsWaterCoolerRoundStats {
  responsesCount: number;
}

export interface ModelsWaterCoolerSnapshot {
  media?: ModelsWaterCoolerMedia | null;
  tags: string[];
  text: string;
  topicId: string;
}

export interface ModelsWaterCoolerTopicLink {
  isActive: boolean;
  roundId: string;
}

export interface ModelsHeadToHeadCard {
  audience: ModelsHeadToHeadCardPrompt;
  default: ModelsHeadToHeadCardPrompt;
  groupA: ModelsHeadToHeadCardPrompt;
  groupB: ModelsHeadToHeadCardPrompt;
  id: string;
}

export interface ModelsHeadToHeadCardPrompt {
  cover: ModelsMediaAsset;
  source: EnumsH2HCardSource;
  text: string;
  voiceOver: ModelsVoiceOver;
}

export interface OpenaiChatCompletionChoice {
  /**
   * FinishReason
   * stop: API returned complete message,
   * or a message terminated by one of the stop sequences provided via the stop parameter
   * length: Incomplete model output due to max_tokens parameter or token limit
   * function_call: The model decided to call a function
   * content_filter: Omitted content due to a flag from our content filters
   * null: API response still in progress or incomplete
   */
  finish_reason: OpenaiFinishReason;
  index: number;
  logprobs: OpenaiLogProbs;
  message: OpenaiChatCompletionMessage;
}

export interface OpenaiChatCompletionMessage {
  content: string;
  function_call: OpenaiFunctionCall;
  multiContent?: OpenaiChatMessagePart[];
  /**
   * This property isn't in the official documentation, but it's in
   * the documentation for the official library for python:
   * - https://github.com/openai/openai-python/blob/main/chatml.md
   * - https://github.com/openai/openai-cookbook/blob/main/examples/How_to_count_tokens_with_tiktoken.ipynb
   */
  name: string;
  refusal: string;
  role: string;
  /** For Role=tool prompts this should be set to the ID given in the assistant's prior request to call a tool. */
  tool_call_id: string;
  /** For Role=assistant prompts this may be set to the tool calls generated by the model, such as function calls. */
  tool_calls: OpenaiToolCall[];
}

export interface OpenaiChatCompletionRequest {
  frequency_penalty: number;
  /** Deprecated: use ToolChoice instead. */
  function_call: any;
  /** Deprecated: use Tools instead. */
  functions: OpenaiFunctionDefinition[];
  /**
   * LogitBias is must be a token id string (specified by their token ID in the tokenizer), not a word string.
   * incorrect: `"logit_bias":{"You": 6}`, correct: `"logit_bias":{"1639": 6}`
   * refs: https://platform.openai.com/docs/api-reference/chat/create#chat/create-logit_bias
   */
  logit_bias: Record<string, number>;
  /**
   * LogProbs indicates whether to return log probabilities of the output tokens or not.
   * If true, returns the log probabilities of each output token returned in the content of message.
   * This option is currently not available on the gpt-4-vision-preview model.
   */
  logprobs: boolean;
  /**
   * MaxCompletionsTokens An upper bound for the number of tokens that can be generated for a completion,
   * including visible output tokens and reasoning tokens https://platform.openai.com/docs/guides/reasoning
   */
  max_completions_tokens: number;
  /**
   * MaxTokens The maximum number of tokens that can be generated in the chat completion.
   * This value can be used to control costs for text generated via API.
   * This value is now deprecated in favor of max_completion_tokens, and is not compatible with o1 series models.
   * refs: https://platform.openai.com/docs/api-reference/chat/create#chat-create-max_tokens
   */
  max_tokens: number;
  messages: OpenaiChatCompletionMessage[];
  model: string;
  n: number;
  /** Disable the default behavior of parallel tool calls by setting it: false. */
  parallel_tool_calls: any;
  presence_penalty: number;
  response_format: OpenaiChatCompletionResponseFormat;
  seed: number;
  stop: string[];
  stream: boolean;
  /** Options for streaming response. Only set this when you set stream: true. */
  stream_options: OpenaiStreamOptions;
  temperature: number;
  /** This can be either a string or an ToolChoice object. */
  tool_choice: any;
  tools: OpenaiTool[];
  /**
   * TopLogProbs is an integer between 0 and 5 specifying the number of most likely tokens to return at each
   * token position, each with an associated log probability.
   * logprobs must be set to true if this parameter is used.
   */
  top_logprobs: number;
  top_p: number;
  user: string;
}

export interface OpenaiChatCompletionResponse {
  choices: OpenaiChatCompletionChoice[];
  created: number;
  id: string;
  model: string;
  object: string;
  system_fingerprint: string;
  usage: OpenaiUsage;
}

export interface OpenaiChatCompletionResponseFormat {
  json_schema: OpenaiChatCompletionResponseFormatJSONSchema;
  type: OpenaiChatCompletionResponseFormatType;
}

export interface OpenaiChatCompletionResponseFormatJSONSchema {
  description: string;
  name: string;
  schema: any;
  strict: boolean;
}

export enum OpenaiChatCompletionResponseFormatType {
  ChatCompletionResponseFormatTypeJSONObject = 'json_object',
  ChatCompletionResponseFormatTypeJSONSchema = 'json_schema',
  ChatCompletionResponseFormatTypeText = 'text',
}

export interface OpenaiChatMessageImageURL {
  detail: OpenaiImageURLDetail;
  url: string;
}

export interface OpenaiChatMessagePart {
  image_url: OpenaiChatMessageImageURL;
  text: string;
  type: OpenaiChatMessagePartType;
}

export enum OpenaiChatMessagePartType {
  ChatMessagePartTypeText = 'text',
  ChatMessagePartTypeImageURL = 'image_url',
}

export enum OpenaiFinishReason {
  FinishReasonStop = 'stop',
  FinishReasonLength = 'length',
  FinishReasonFunctionCall = 'function_call',
  FinishReasonToolCalls = 'tool_calls',
  FinishReasonContentFilter = 'content_filter',
  FinishReasonNull = 'null',
}

export interface OpenaiFunctionCall {
  /** call function with arguments in JSON format */
  arguments: string;
  name: string;
}

export interface OpenaiFunctionDefinition {
  description: string;
  name: string;
  /**
   * Parameters is an object describing the function.
   * You can pass json.RawMessage to describe the schema,
   * or you can pass in a struct which serializes to the proper JSON schema.
   * The jsonschema package is provided for convenience, but you should
   * consider another specialized library if you require more complex schemas.
   */
  parameters: any;
  strict: boolean;
}

export enum OpenaiImageURLDetail {
  ImageURLDetailHigh = 'high',
  ImageURLDetailLow = 'low',
  ImageURLDetailAuto = 'auto',
}

export interface OpenaiLogProb {
  /** Omitting the field if it is null */
  bytes: number[];
  logprob: number;
  token: string;
  /**
   * TopLogProbs is a list of the most likely tokens and their log probability, at this token position.
   * In rare cases, there may be fewer than the number of requested top_logprobs returned.
   */
  top_logprobs: OpenaiTopLogProbs[];
}

export interface OpenaiLogProbs {
  /** Content is a list of message content tokens with log probability information. */
  content: OpenaiLogProb[];
}

export enum OpenaiRunStepStatus {
  RunStepStatusInProgress = 'in_progress',
  RunStepStatusCancelling = 'cancelled',
  RunStepStatusFailed = 'failed',
  RunStepStatusCompleted = 'completed',
  RunStepStatusExpired = 'expired',
}

export enum OpenaiRunStepType {
  RunStepTypeMessageCreation = 'message_creation',
  RunStepTypeToolCalls = 'tool_calls',
}

export interface OpenaiStepDetails {
  message_creation: OpenaiStepDetailsMessageCreation;
  tool_calls: OpenaiToolCall[];
  type: OpenaiRunStepType;
}

export interface OpenaiStepDetailsMessageCreation {
  message_id: string;
}

export interface OpenaiStreamOptions {
  /**
   * If set, an additional chunk will be streamed before the data: [DONE] message.
   * The usage field on this chunk shows the token usage statistics for the entire request,
   * and the choices field will always be an empty array.
   * All other chunks will also include a usage field, but with a null value.
   */
  include_usage: boolean;
}

export interface OpenaiTool {
  function: OpenaiFunctionDefinition;
  type: OpenaiToolType;
}

export interface OpenaiToolCall {
  function: OpenaiFunctionCall;
  id: string;
  /** Index is not nil only in chat completion chunk object */
  index: number;
  type: OpenaiToolType;
}

export enum OpenaiToolType {
  ToolTypeFunction = 'function',
}

export interface OpenaiTopLogProbs {
  bytes: number[];
  logprob: number;
  token: string;
}

export interface OpenaiUsage {
  completion_tokens: number;
  prompt_tokens: number;
  total_tokens: number;
}

export interface SchemaSelectBestMatchMediaRating {
  /** A simple description of why the score is rated */
  description: string;
  /** The index of the given media, start from zero */
  index: number;
  /** The rating score that considering the relevance, quality, the value should between 0-100 (worst match - best match) */
  score: number;
}

export interface SchemaTrainingDialogue {
  /** The conversation entries of the dialogue */
  entries: SchemaTrainingDialogueEntry[];
}

export interface SchemaTrainingDialogueEntry {
  /** The voice-over script of the dialogue entry */
  script: string;
  /** The speaker ID of the dialogue entry */
  speakerId: string;
}

export interface SchemaTrainingOutline {
  /** The top-level heads of the outline */
  topics: string[];
}

export interface SchemaTrainingTopicBlockCandidate {
  /** Which type of block this page should be used */
  blockType: EnumsTrainingBlockType;
  /** If the block type is slide, which layout should be used. Otherwise, this field should be empty. */
  layout: string;
  /** The reason of why to choose this block type (and layout) */
  reason: string;
}

export interface SchemaTrainingTopicOutline {
  /** the block candidates of the topic */
  candidates: SchemaTrainingTopicBlockCandidate[];
}

export enum ScormVersion {
  V12 = '1.2',
  V20042ndEdition = '2004 2nd Edition',
  V20043rdEdition = '2004 3rd Edition',
  V20044thEdition = '2004 4th Edition',
}

export interface StripeAddress {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postal_code: string;
  state: string;
}

export enum StripeCardFunding {
  CardFundingCredit = 'credit',
  CardFundingDebit = 'debit',
  CardFundingPrepaid = 'prepaid',
  CardFundingUnknown = 'unknown',
}

export enum StripeCouponDuration {
  CouponDurationForever = 'forever',
  CouponDurationOnce = 'once',
  CouponDurationRepeating = 'repeating',
  CouponDurationVariable = 'variable',
}

export interface StripePaymentMethodCard {
  /** Card brand. Can be `amex`, `diners`, `discover`, `eftpos_au`, `jcb`, `mastercard`, `unionpay`, `visa`, or `unknown`. */
  brand: StripePaymentMethodCardBrand;
  /** Checks on Card address and CVC if provided. */
  checks: StripePaymentMethodCardChecks;
  /** Two-letter ISO code representing the country of the card. You could use this attribute to get a sense of the international breakdown of cards you've collected. */
  country: string;
  /**
   * Please note that the fields below are for internal use only and are not returned
   * as part of standard API requests.
   * A high-level description of the type of cards issued in this range. (For internal use only and not typically available in standard API requests.)
   */
  description: string;
  /** The brand to use when displaying the card, this accounts for customer's brand choice on dual-branded cards. Can be `american_express`, `cartes_bancaires`, `diners_club`, `discover`, `eftpos_australia`, `interac`, `jcb`, `mastercard`, `union_pay`, `visa`, or `other` and may contain more values in the future. */
  display_brand: string;
  /** Two-digit number representing the card's expiration month. */
  exp_month: number;
  /** Four-digit number representing the card's expiration year. */
  exp_year: number;
  /**
   * Uniquely identifies this particular card number. You can use this attribute to check whether two customers who've signed up with you are using the same card number, for example. For payment methods that tokenize card information (Apple Pay, Google Pay), the tokenized number might be provided instead of the underlying card number.
   *
   * *As of May 1, 2021, card fingerprint in India for Connect changed to allow two fingerprints for the same card---one for India and one for the rest of the world.*
   */
  fingerprint: string;
  /** Card funding type. Can be `credit`, `debit`, `prepaid`, or `unknown`. */
  funding: StripeCardFunding;
  /** Issuer identification number of the card. (For internal use only and not typically available in standard API requests.) */
  iin: string;
  /** The name of the card's issuing bank. (For internal use only and not typically available in standard API requests.) */
  issuer: string;
  /** The last four digits of the card. */
  last4: string;
  /** Contains information about card networks that can be used to process the payment. */
  networks: StripePaymentMethodCardNetworks;
  /** Contains details on how this Card may be used for 3D Secure authentication. */
  three_d_secure_usage: StripePaymentMethodCardThreeDSecureUsage;
  /** If this Card is part of a card wallet, this contains the details of the card wallet. */
  wallet: StripePaymentMethodCardWallet;
}

export enum StripePaymentMethodCardBrand {
  PaymentMethodCardBrandAmex = 'amex',
  PaymentMethodCardBrandDiners = 'diners',
  PaymentMethodCardBrandDiscover = 'discover',
  PaymentMethodCardBrandJCB = 'jcb',
  PaymentMethodCardBrandMastercard = 'mastercard',
  PaymentMethodCardBrandUnionpay = 'unionpay',
  PaymentMethodCardBrandUnknown = 'unknown',
  PaymentMethodCardBrandVisa = 'visa',
}

export interface StripePaymentMethodCardChecks {
  /** If a address line1 was provided, results of the check, one of `pass`, `fail`, `unavailable`, or `unchecked`. */
  address_line1_check: StripePaymentMethodCardChecksAddressLine1Check;
  /** If a address postal code was provided, results of the check, one of `pass`, `fail`, `unavailable`, or `unchecked`. */
  address_postal_code_check: StripePaymentMethodCardChecksAddressPostalCodeCheck;
  /** If a CVC was provided, results of the check, one of `pass`, `fail`, `unavailable`, or `unchecked`. */
  cvc_check: StripePaymentMethodCardChecksCVCCheck;
}

export enum StripePaymentMethodCardChecksAddressLine1Check {
  PaymentMethodCardChecksAddressLine1CheckFail = 'fail',
  PaymentMethodCardChecksAddressLine1CheckPass = 'pass',
  PaymentMethodCardChecksAddressLine1CheckUnavailable = 'unavailable',
  PaymentMethodCardChecksAddressLine1CheckUnchecked = 'unchecked',
}

export enum StripePaymentMethodCardChecksAddressPostalCodeCheck {
  PaymentMethodCardChecksAddressPostalCodeCheckFail = 'fail',
  PaymentMethodCardChecksAddressPostalCodeCheckPass = 'pass',
  PaymentMethodCardChecksAddressPostalCodeCheckUnavailable = 'unavailable',
  PaymentMethodCardChecksAddressPostalCodeCheckUnchecked = 'unchecked',
}

export enum StripePaymentMethodCardChecksCVCCheck {
  PaymentMethodCardChecksCVCCheckFail = 'fail',
  PaymentMethodCardChecksCVCCheckPass = 'pass',
  PaymentMethodCardChecksCVCCheckUnavailable = 'unavailable',
  PaymentMethodCardChecksCVCCheckUnchecked = 'unchecked',
}

export interface StripePaymentMethodCardNetworks {
  /** All available networks for the card. */
  available: StripePaymentMethodCardNetworksAvailable[];
  /** The preferred network for co-branded cards. Can be `cartes_bancaires`, `mastercard`, `visa` or `invalid_preference` if requested network is not valid for the card. */
  preferred: StripePaymentMethodCardNetworksPreferred;
}

export enum StripePaymentMethodCardNetworksAvailable {
  PaymentMethodCardNetworksAvailableAmex = 'amex',
  PaymentMethodCardNetworksAvailableCartesBancaires = 'cartes_bancaires',
  PaymentMethodCardNetworksAvailableDiners = 'diners',
  PaymentMethodCardNetworksAvailableDiscover = 'discover',
  PaymentMethodCardNetworksAvailableInterac = 'interac',
  PaymentMethodCardNetworksAvailableJCB = 'jcb',
  PaymentMethodCardNetworksAvailableMastercard = 'mastercard',
  PaymentMethodCardNetworksAvailableUnionpay = 'unionpay',
  PaymentMethodCardNetworksAvailableVisa = 'visa',
  PaymentMethodCardNetworksAvailableUnknown = 'unknown',
}

export enum StripePaymentMethodCardNetworksPreferred {
  PaymentMethodCardNetworksPreferredAmex = 'amex',
  PaymentMethodCardNetworksPreferredCartesBancaires = 'cartes_bancaires',
  PaymentMethodCardNetworksPreferredDiners = 'diners',
  PaymentMethodCardNetworksPreferredDiscover = 'discover',
  PaymentMethodCardNetworksPreferredInterac = 'interac',
  PaymentMethodCardNetworksPreferredJCB = 'jcb',
  PaymentMethodCardNetworksPreferredMastercard = 'mastercard',
  PaymentMethodCardNetworksPreferredUnionpay = 'unionpay',
  PaymentMethodCardNetworksPreferredVisa = 'visa',
  PaymentMethodCardNetworksPreferredUnknown = 'unknown',
}

export interface StripePaymentMethodCardThreeDSecureUsage {
  /** Whether 3D Secure is supported on this card. */
  supported: boolean;
}

export interface StripePaymentMethodCardWallet {
  amex_express_checkout: StripePaymentMethodCardWalletAmexExpressCheckout;
  apple_pay: StripePaymentMethodCardWalletApplePay;
  /** (For tokenized numbers only.) The last four digits of the device account number. */
  dynamic_last4: string;
  google_pay: StripePaymentMethodCardWalletGooglePay;
  link: StripePaymentMethodCardWalletLink;
  masterpass: StripePaymentMethodCardWalletMasterpass;
  samsung_pay: StripePaymentMethodCardWalletSamsungPay;
  /** The type of the card wallet, one of `amex_express_checkout`, `apple_pay`, `google_pay`, `masterpass`, `samsung_pay`, `visa_checkout`, or `link`. An additional hash is included on the Wallet subhash with a name matching this value. It contains additional information specific to the card wallet type. */
  type: StripePaymentMethodCardWalletType;
  visa_checkout: StripePaymentMethodCardWalletVisaCheckout;
}

export type StripePaymentMethodCardWalletAmexExpressCheckout = object;

export type StripePaymentMethodCardWalletApplePay = object;

export type StripePaymentMethodCardWalletGooglePay = object;

export type StripePaymentMethodCardWalletLink = object;

export interface StripePaymentMethodCardWalletMasterpass {
  /** Owner's verified billing address. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  billing_address: StripeAddress;
  /** Owner's verified email. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  email: string;
  /** Owner's verified full name. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  name: string;
  /** Owner's verified shipping address. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  shipping_address: StripeAddress;
}

export type StripePaymentMethodCardWalletSamsungPay = object;

export enum StripePaymentMethodCardWalletType {
  PaymentMethodCardWalletTypeAmexExpressCheckout = 'amex_express_checkout',
  PaymentMethodCardWalletTypeApplePay = 'apple_pay',
  PaymentMethodCardWalletTypeGooglePay = 'google_pay',
  PaymentMethodCardWalletTypeLink = 'link',
  PaymentMethodCardWalletTypeMasterpass = 'masterpass',
  PaymentMethodCardWalletTypeSamsungPay = 'samsung_pay',
  PaymentMethodCardWalletTypeVisaCheckout = 'visa_checkout',
}

export interface StripePaymentMethodCardWalletVisaCheckout {
  /** Owner's verified billing address. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  billing_address: StripeAddress;
  /** Owner's verified email. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  email: string;
  /** Owner's verified full name. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  name: string;
  /** Owner's verified shipping address. Values are verified or provided by the wallet directly (if supported) at the time of authorization or settlement. They cannot be set or mutated. */
  shipping_address: StripeAddress;
}

export enum StripePaymentMethodType {
  PaymentMethodTypeACSSDebit = 'acss_debit',
  PaymentMethodTypeAffirm = 'affirm',
  PaymentMethodTypeAfterpayClearpay = 'afterpay_clearpay',
  PaymentMethodTypeAlipay = 'alipay',
  PaymentMethodTypeAmazonPay = 'amazon_pay',
  PaymentMethodTypeAUBECSDebit = 'au_becs_debit',
  PaymentMethodTypeBACSDebit = 'bacs_debit',
  PaymentMethodTypeBancontact = 'bancontact',
  PaymentMethodTypeBLIK = 'blik',
  PaymentMethodTypeBoleto = 'boleto',
  PaymentMethodTypeCard = 'card',
  PaymentMethodTypeCardPresent = 'card_present',
  PaymentMethodTypeCashApp = 'cashapp',
  PaymentMethodTypeCustomerBalance = 'customer_balance',
  PaymentMethodTypeEPS = 'eps',
  PaymentMethodTypeFPX = 'fpx',
  PaymentMethodTypeGiropay = 'giropay',
  PaymentMethodTypeGrabpay = 'grabpay',
  PaymentMethodTypeIDEAL = 'ideal',
  PaymentMethodTypeInteracPresent = 'interac_present',
  PaymentMethodTypeKlarna = 'klarna',
  PaymentMethodTypeKonbini = 'konbini',
  PaymentMethodTypeLink = 'link',
  PaymentMethodTypeMobilepay = 'mobilepay',
  PaymentMethodTypeMultibanco = 'multibanco',
  PaymentMethodTypeOXXO = 'oxxo',
  PaymentMethodTypeP24 = 'p24',
  PaymentMethodTypePayNow = 'paynow',
  PaymentMethodTypePaypal = 'paypal',
  PaymentMethodTypePayto = 'payto',
  PaymentMethodTypePix = 'pix',
  PaymentMethodTypePromptPay = 'promptpay',
  PaymentMethodTypeRechnung = 'rechnung',
  PaymentMethodTypeRevolutPay = 'revolut_pay',
  PaymentMethodTypeSEPADebit = 'sepa_debit',
  PaymentMethodTypeSofort = 'sofort',
  PaymentMethodTypeSwish = 'swish',
  PaymentMethodTypeTWINT = 'twint',
  PaymentMethodTypeUSBankAccount = 'us_bank_account',
  PaymentMethodTypeWeChatPay = 'wechat_pay',
  PaymentMethodTypeZip = 'zip',
}

export interface StripePriceRecurring {
  /** Specifies a usage aggregation strategy for prices of `usage_type=metered`. Defaults to `sum`. */
  aggregate_usage: StripePriceRecurringAggregateUsage;
  /** The frequency at which a subscription is billed. One of `day`, `week`, `month` or `year`. */
  interval: StripePriceRecurringInterval;
  /** The number of intervals (specified in the `interval` attribute) between subscription billings. For example, `interval=month` and `interval_count=3` bills every 3 months. */
  interval_count: number;
  /** The meter tracking the usage of a metered price */
  meter: string;
  /** Default number of trial days when subscribing a customer to this price using [`trial_from_plan=true`](https://stripe.com/docs/api#create_subscription-trial_from_plan). */
  trial_period_days: number;
  /** Configures how the quantity per period should be determined. Can be either `metered` or `licensed`. `licensed` automatically bills the `quantity` set when adding it to a subscription. `metered` aggregates the total usage based on usage records. Defaults to `licensed`. */
  usage_type: StripePriceRecurringUsageType;
}

export enum StripePriceRecurringAggregateUsage {
  PriceRecurringAggregateUsageLastDuringPeriod = 'last_during_period',
  PriceRecurringAggregateUsageLastEver = 'last_ever',
  PriceRecurringAggregateUsageMax = 'max',
  PriceRecurringAggregateUsageSum = 'sum',
}

export enum StripePriceRecurringInterval {
  PriceRecurringIntervalDay = 'day',
  PriceRecurringIntervalMonth = 'month',
  PriceRecurringIntervalWeek = 'week',
  PriceRecurringIntervalYear = 'year',
}

export enum StripePriceRecurringUsageType {
  PriceRecurringUsageTypeLicensed = 'licensed',
  PriceRecurringUsageTypeMetered = 'metered',
}

export enum StripePriceType {
  PriceTypeOneTime = 'one_time',
  PriceTypeRecurring = 'recurring',
}

export interface ZoomDecryptedAppContext {
  /** same as action in GetDeeplink */
  act: string;
  aud: string;
  entitlements: any[];
  exp: number;
  iss: string;
  /** meeting id */
  mid: string;
  ts: number;
  typ: string;
  /** user id */
  uid: string;
}

export interface ZoomUser {
  account_id: string;
  account_number: number;
  display_name: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  pic_url: string;
  status: string;
}

export interface SearchBlocksParams {
  all: boolean;
  brandIds: string;
  gameId: string;
  includeBrands: boolean;
  pageToken?: string;
  q: string;
  size?: number;
  sortBy: 'best-match' | 'recent-updated';
  type:
    | 'question'
    | 'creativePrompt'
    | 'rapid'
    | 'scoreboard'
    | 'spotlight'
    | 'spotlightV2'
    | 'teamRelay'
    | 'randomizer'
    | 'multipleChoice'
    | 'memoryMatch'
    | 'puzzle'
    | 'roundRobinQuestion'
    | 'titleV2'
    | 'instruction'
    | 'overRoasted'
    | 'drawingPrompt'
    | 'hiddenPicture'
    | 'aiChat'
    | 'icebreaker'
    | 'guessWho'
    | 'marketing'
    | 'jeopardy'
    | 'headToHead'
    | 'slide'
    | 'roleplay'
    | 'drawToWin'
    | 'sparkifact'
    | 'swipeToWin'
    | 'fillInTheBlanks';
}

export interface QueryBrandsParams {
  pageToken?: string;
  size?: number;
  tagId?: number;
  type: 'tag' | 'untagged';
}

export interface SearchBrandsParams {
  pageToken?: string;
  q: string;
  size?: number;
}

export interface QueryChannelsParams {
  orgId: string;
  teamId?: string | null;
  uid?: string;
}

export interface AllProgramLinksParams {
  orgId: string | null;
  status: 'active' | 'inactive';
  type:
    | 'global_pairing'
    | 'game_drops'
    | 'birthday_and_celebrations'
    | 'dummy'
    | 'calendar'
    | 'intros'
    | 'water_cooler'
    | 'recognition'
    | 'ai_survey'
    | 'custom_tournament';
}

export interface QueryEventsParams {
  orgId: string;
  orgRef: string;
  pageToken?: string;
  size?: number;
  type: 'ond' | 'live';
}

export interface GetGamePackParams {
  blocks?: boolean;
  brands?: boolean;
  games?: boolean;
  guest?: boolean;
  linkedSharedAssets?: boolean;
  personalities?: boolean;
  playHistory?: string;
  progression?: string;
  /**
   * the uid of the "subscriber". this will be used to determine if the game pack needs additional playback
   * configuration. in practice, this value is the same as PlayHistory, but we keep it separate for clarity.
   */
  subscriberId?: string;
  /** id */
  id: string;
}

export interface QueryGroupedGamePacksByTagsParams {
  personalized: boolean;
  size?: number;
  tagIds: string;
}

export interface GetOthersPlayingParams {
  gamePackId: string;
}

export interface UnassignMemberFromCourseParams {
  userId: string;
  /** Course ID */
  id: string;
}

export interface SearchUserCreatedCoursesParams {
  /** Search query */
  q?: string;
}

export interface RemoveEnrollmentFromGroupParams {
  /** Enrollment Type (e.g., course, stack) */
  assignmentType: string;
  /** Object ID */
  objectId: string;
  /** Group ID */
  id: string;
}

export interface SearchGroupsByNameParams {
  /** Partial name to match */
  q?: string;
}

export interface GetLearnerEnrollmentsParams {
  '-': string;
  pageToken?: string;
  size?: number;
  uid: string;
}

export interface GetLearnerMembershipsParams {
  '-': string;
  pageToken?: string;
  size?: number;
  uid: string;
}

export interface GetLearnerProfileParams {
  '-': string;
  enrollmentsSummary: boolean;
  membershipsSummary: boolean;
  uid: string;
}

export interface SearchUserCreatedStacksParams {
  /** Search query */
  q?: string;
}

export interface SearchDocsParams {
  limit: number;
  namespace: string;
  query: string;
  scoreThreshold: number;
}

export interface SearchExternalMediaParams {
  /** Provider name (giphy, unsplash, serp) */
  provider: 'unsplash' | 'serp' | 'giphy' | 'azure-bing' | 'image-gen';
  /** Search query */
  q: string;
  /**
   * Pagination offset
   * @default 0
   */
  offset?: number;
  /**
   * Page size
   * @default 25
   */
  size?: number;
  /**
   * Aspect Ratio
   * @default "all"
   */
  aspectRatio?: 'wide' | 'tall' | 'square' | 'all';
}

export interface QuerySharedAssetParams {
  ids: string | null;
  type: 'by-ids';
}

export interface SearchSharedAssetParams {
  pageToken?: string;
  purposes: string;
  q: string;
  size?: number;
}

export interface QueryLogicsParams {
  campaignId: string;
  campaignType:
    | 'playground'
    | 'program-round'
    | 'program-link-activated'
    | 'otp-started'
    | 'otp-purchased'
    | 'otp-event-ended'
    | 'onboarding-drip'
    | 'member-invited'
    | 'program-installed'
    | 'session-ended'
    | 'cancel-offer-accepted'
    | 'subscription-started'
    | 'org-seat-cap-err-shown';
}

export interface RenderSlackBlocksParams {
  templateName:
    | 'general'
    | 'markdown'
    | 'promotion-game-recap'
    | 'game-pack-promotion'
    | 'promotion-analytics-digest'
    | 'celebration-birthday'
    | 'celebration-anniversary'
    | 'onboarding-quick-guide'
    | 'lp-engaged';
}

export interface GetMyExperiencesParams {
  pageToken?: string;
  size?: number;
  timeRange: '3months' | '6months' | '12months' | 'all time';
}

export interface GetMyLearnerProfileParams {
  '-': string;
  enrollmentsSummary: boolean;
  membershipsSummary: boolean;
}

export interface GetNotificationSettingsParams {
  payload?: string;
  signature?: string;
}

export interface QueryTaskLogsParams {
  name:
    | 'enter-task-page'
    | 'schedule-call'
    | 'connect-to-slack'
    | 'add-team'
    | 'notify-team'
    | 'set-up-programs'
    | 'submit-dates'
    | 'book-live-show'
    | 'schedule-ond-game'
    | 'schedule-purchased-ond-game'
    | 'pending-purchase-ond-game'
    | 'add-team-members-with-slack'
    | 'add-team-members-without-slack'
    | 'announce-to-team-with-slack'
    | 'announce-to-team-without-slack'
    | 'enable-global-pairing-tournaments'
    | 'enable-game-drops'
    | 'enable-birthdays-and-celebrations';
  orgId: string;
}

export interface ListOnboardingTasksParams {
  orgId: string;
}

export interface GetPresentationSummaryParams {
  endAt?: string | null;
  startAt?: string | null;
  timeRange?: '3months' | '6months' | '12months' | 'all time';
}

export interface QueryOrganizationExperiencesParams {
  pageToken?: string;
  size?: number;
  timeRange: '3months' | '6months' | '12months' | 'all time';
  /** orgId or slug */
  id: string;
}

export interface QueryOrganizersByEmailsParams {
  /** emails */
  emails: string;
  /** orgId */
  id: string;
}

export interface QueryProgramsSummaryParams {
  endAt: string | null;
  orgId?: string;
  startAt: string | null;
  timeRange: '3months' | '6months' | '12months' | 'all time';
  /** orgId */
  id: string;
}

export interface QueryRecognitionSummaryParams {
  exGroupId: string;
  orgId?: string;
  timeRange:
    | 'all_time'
    | 'last_week'
    | 'last_two_weeks'
    | 'last_month'
    | 'last_three_months'
    | 'last_six_months'
    | 'last_year';
  type: 'given' | 'received' | 'combined';
  /** orgId */
  id: string;
}

export interface FeaturedListParams {
  pageName?:
    | 'discover-games'
    | 'discover-game-packs'
    | 'public-game-packs'
    | 'featured-game-packs'
    | 'brands'
    | 'public-game-packs-ng'
    | 'explore'
    | 'public-home'
    | 'live-otp'
    | 'live-logged-in'
    | 'zoom'
    | 'staging'
    | 'trial'
    | 'general-learning'
    | 'general-learning-staging';
  size?: number | null;
}

export interface QueryPairingRoundsParams {
  channelId?: string;
  globalRoundId?: string;
  orgId?: string;
}

export interface QueryProgramRoundsParams {
  parentId: string;
}

export interface QueryProgramGroupsParams {
  roundId: string;
}

export interface QueryRoleplayHistoriesParams {
  blockId: string | null;
  uid: string | null;
}

export interface GetSessionMemoriesParams {
  /** useParticipantTeamId */
  useParticipantTeamId?: boolean;
  /** sessionId */
  sid: string;
}

export interface QuerySlackChannelsParams {
  channelIds: string;
  orgId: string;
  size: number;
  type: 'byIds' | 'all' | 'byUserId';
}

export interface SearchSlackChannelsParams {
  keyword: string;
  orgId: string;
  size: number;
  types: string;
  uid?: string;
  withNewUsers?: boolean;
}

export interface QuerySlackUsersParams {
  channelId?: string;
  emails?: string;
  keyword?: string;
  orgId: string;
  size?: number;
  type: 'byIds' | 'byKeywords' | 'all' | 'byChannelId' | 'byEmails';
  uid?: string;
  userIds?: string;
  withOrganizer?: boolean;
}

export interface QueryPromotionCodeParams {
  active: boolean | null;
  code: string | null;
}

export interface SearchTagsParams {
  /** keyword */
  q: string;
}

export interface QueryUsersParams {
  email?: string;
  orgId?: string;
  pageToken?: string;
  role?: 0 | 10;
  searchGuestUsers?: boolean;
  size?: number;
  uid?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '/api/v1';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Luna Park API
 * @version 1.0
 * @baseUrl /api/v1
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  aiChat = {
    /**
     * No description
     *
     * @tags AIChat
     * @name GetChatThread
     * @request GET:/ai/chat/threads/{id}
     * @secure
     * @response `200` `DtoAIChatThreadResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getChatThread: (id: string, params: RequestParams = {}) =>
      this.request<DtoAIChatThreadResponse, CommonBusinessError>({
        path: `/ai/chat/threads/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AIChat
     * @name GetChatMessages
     * @request GET:/ai/chat/threads/{id}/messages
     * @secure
     * @response `200` `DtoAIChatMessageListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getChatMessages: (id: string, params: RequestParams = {}) =>
      this.request<DtoAIChatMessageListResponse, CommonBusinessError>({
        path: `/ai/chat/threads/${id}/messages`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AIChat
     * @name SearchChatThreads
     * @request GET:/ai/chat/threads/search
     * @secure
     * @response `200` `DtoAIChatThreadListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchChatThreads: (params: RequestParams = {}) =>
      this.request<DtoAIChatThreadListResponse, CommonBusinessError>({
        path: `/ai/chat/threads/search`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AIChat
     * @name TrackMessages
     * @request POST:/ai/chat/track-messages
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    trackMessages: (payload: DtoTrackMessageRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/ai/chat/track-messages`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AIChat
     * @name GradeSubmission
     * @request POST:/ai/grade
     * @response `200` `DtoGradeSubmissionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    gradeSubmission: (payload: DtoGradeSubmissionRequest, params: RequestParams = {}) =>
      this.request<DtoGradeSubmissionResponse, CommonBusinessError>({
        path: `/ai/grade`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  aiGeneral = {
    /**
     * No description
     *
     * @tags AIGeneral
     * @name AiDummy
     * @request GET:/ai/dummy
     * @secure
     * @response `200` `DtoLoadDocumentParams` OK
     * @response `default` `CommonBusinessError`
     */
    aiDummy: (params: RequestParams = {}) =>
      this.request<DtoLoadDocumentParams, CommonBusinessError>({
        path: `/ai/dummy`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AIGeneral
     * @name GlobalSettings
     * @request GET:/ai/global-settings
     * @response `200` `DtoGenAIGlobalSettingsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    globalSettings: (params: RequestParams = {}) =>
      this.request<DtoGenAIGlobalSettingsResponse, CommonBusinessError>({
        path: `/ai/global-settings`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AIGeneral
     * @name GenerateImages
     * @request POST:/llm/image/generate
     * @response `200` `DtoImageGenResponse` OK
     * @response `default` `CommonBusinessError`
     */
    generateImages: (payload: DtoImageGenRequest, params: RequestParams = {}) =>
      this.request<DtoImageGenResponse, CommonBusinessError>({
        path: `/llm/image/generate`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  analytics = {
    /**
     * No description
     *
     * @tags Analytics
     * @name CountLiveEvents
     * @request GET:/analytics/live-events-count
     * @secure
     * @response `200` `DtoCountLiveEventsResponse` OK
     */
    countLiveEvents: (params: RequestParams = {}) =>
      this.request<DtoCountLiveEventsResponse, any>({
        path: `/analytics/live-events-count`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetOrgSessionSeconds
     * @request GET:/analytics/org-session-seconds
     * @secure
     * @response `200` `DtoOrgSessionSecondsResponse` OK
     */
    getOrgSessionSeconds: (params: RequestParams = {}) =>
      this.request<DtoOrgSessionSecondsResponse, any>({
        path: `/analytics/org-session-seconds`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name QueryOrgSummaries
     * @request GET:/analytics/org-summaries
     * @secure
     * @response `200` `DtoOrgDailySummaryListResponse` OK
     */
    queryOrgSummaries: (params: RequestParams = {}) =>
      this.request<DtoOrgDailySummaryListResponse, any>({
        path: `/analytics/org-summaries`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetPreUnsubscribeSummary
     * @request GET:/analytics/pre-unsubscribe
     * @secure
     * @response `200` `DtoGetPreUnsubscribeSummaryResponse` OK
     */
    getPreUnsubscribeSummary: (params: RequestParams = {}) =>
      this.request<DtoGetPreUnsubscribeSummaryResponse, any>({
        path: `/analytics/pre-unsubscribe`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name QueryUserReports
     * @request GET:/analytics/user-reports
     * @secure
     * @response `200` `DtoUserAnalyticsReportListResponse` OK
     */
    queryUserReports: (params: RequestParams = {}) =>
      this.request<DtoUserAnalyticsReportListResponse, any>({
        path: `/analytics/user-reports`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analytics
     * @name GetMyExperiences
     * @request GET:/my/experiences
     * @secure
     * @response `200` `DtoExperienceListResponse` OK
     */
    getMyExperiences: (query: GetMyExperiencesParams, params: RequestParams = {}) =>
      this.request<DtoExperienceListResponse, any>({
        path: `/my/experiences`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name ExecuteExchangeToken
     * @request POST:/auth/execute-token-exchange
     * @response `200` `DtoExecuteTokenExchangeResponse` OK
     * @response `default` `CommonBusinessError`
     */
    executeExchangeToken: (payload: DtoExecuteTokenExchangeRequest, params: RequestParams = {}) =>
      this.request<DtoExecuteTokenExchangeResponse, CommonBusinessError>({
        path: `/auth/execute-token-exchange`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GetAgoraRtcToken
     * @request POST:/auth/get-agora-rtc-token
     * @secure
     * @response `200` `DtoGetAgoraRTCTokenResponse` OK
     */
    getAgoraRtcToken: (payload: DtoGetAgoraRTCTokenRequest, params: RequestParams = {}) =>
      this.request<DtoGetAgoraRTCTokenResponse, any>({
        path: `/auth/get-agora-rtc-token`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GetChatToken
     * @request POST:/auth/get-chat-token
     * @secure
     * @response `200` `DtoGetChatTokenResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getChatToken: (params: RequestParams = {}) =>
      this.request<DtoGetChatTokenResponse, CommonBusinessError>({
        path: `/auth/get-chat-token`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GetFirebaseToken
     * @request POST:/auth/get-firebase-token
     * @secure
     * @response `200` `DtoGetFirebaseTokenResponse` OK
     */
    getFirebaseToken: (params: RequestParams = {}) =>
      this.request<DtoGetFirebaseTokenResponse, any>({
        path: `/auth/get-firebase-token`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GetS3PreSignedUrl
     * @request POST:/auth/get-presigned-url
     * @secure
     * @response `200` `void` OK
     */
    getS3PreSignedUrl: (payload: DtoPreSignedUrlRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/get-presigned-url`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name PrepareTokenExchange
     * @request POST:/auth/prepare-token-exchange
     * @response `200` `DtoPrepareTokenExchangeResponse` OK
     * @response `default` `CommonBusinessError`
     */
    prepareTokenExchange: (params: RequestParams = {}) =>
      this.request<DtoPrepareTokenExchangeResponse, CommonBusinessError>({
        path: `/auth/prepare-token-exchange`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name VerifyJwtToken
     * @request POST:/auth/verify
     * @secure
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    verifyJwtToken: (params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/auth/verify`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name CheckEmailAvailability
     * @request POST:/check-email-availability
     * @response `200` `void` OK
     */
    checkEmailAvailability: (payload: DtoCheckEmailAvailabilityRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/check-email-availability`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name DeleteCode
     * @request DELETE:/code/:code
     * @response `200` `void` OK
     */
    deleteCode: (code: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/code/${code}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GetTempToken
     * @request POST:/get-temp-token
     * @response `200` `DtoGetTempTokenResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getTempToken: (payload: DtoGetTempTokenRequest, params: RequestParams = {}) =>
      this.request<DtoGetTempTokenResponse, CommonBusinessError>({
        path: `/get-temp-token`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GuestLogin
     * @request POST:/guest-login
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    guestLogin: (payload: DtoCreateGuestRequest, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/guest-login`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GuestSignup
     * @request POST:/guest-signup
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    guestSignup: (payload: DtoGuestSignupRequest, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/guest-signup`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Login
     * @request POST:/login
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    login: (payload: DtoLoginRequest, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/login`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name OAuthLogin
     * @request POST:/oauth-login
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    oAuthLogin: (payload: DtoOAuthLoginRequest, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/oauth-login`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name OAuthRegister
     * @request POST:/oauth-register
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    oAuthRegister: (payload: DtoOAuthRegisterRequest, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/oauth-register`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name OnboardUser
     * @request POST:/onboard
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    onboardUser: (payload: DtoOnboardUserRequest, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/onboard`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Register
     * @request POST:/register
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    register: (payload: DtoRegisterRequest, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/register`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Signup
     * @request POST:/signup
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    signup: (payload: DtoSignupRequest, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/signup`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name SlackRegister
     * @request POST:/slack-register
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    slackRegister: (payload: DtoSlackRegisterRequest, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/slack-register`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name VerifyCallback
     * @request POST:/verify-callback
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    verifyCallback: (payload: DtoVerifyCallback, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/verify-callback`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name VerifyCode
     * @request POST:/verify-code
     * @response `200` `void` OK
     */
    verifyCode: (payload: DtoVerifyCallback, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/verify-code`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name VerifyLogin
     * @request POST:/verify-login
     * @response `200` `DtoUserAuthenticatedResponse` OK
     */
    verifyLogin: (payload: DtoVerifyLoginRequest, params: RequestParams = {}) =>
      this.request<DtoUserAuthenticatedResponse, any>({
        path: `/verify-login`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  tts = {
    /**
     * No description
     *
     * @tags tts
     * @name ListHeyGenAvatars
     * @request GET:/avatars/heygen
     * @secure
     * @response `200` `DtoListHeyGenAvatarsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    listHeyGenAvatars: (params: RequestParams = {}) =>
      this.request<DtoListHeyGenAvatarsResponse, CommonBusinessError>({
        path: `/avatars/heygen`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tts
     * @name ListHeyGenTemplates
     * @request GET:/avatars/heygen/templates
     * @secure
     * @response `200` `DtoListHeyGenTemplatesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    listHeyGenTemplates: (params: RequestParams = {}) =>
      this.request<DtoListHeyGenTemplatesResponse, CommonBusinessError>({
        path: `/avatars/heygen/templates`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tts
     * @name GetHeyGenTemplateVariables
     * @request GET:/avatars/heygen/templates/{templateId}
     * @secure
     * @response `200` `DtoHeyGenTemplateVariablesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getHeyGenTemplateVariables: (templateId: string, params: RequestParams = {}) =>
      this.request<DtoHeyGenTemplateVariablesResponse, CommonBusinessError>({
        path: `/avatars/heygen/templates/${templateId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tts
     * @name Dummy
     * @request GET:/tts/dummy
     * @secure
     * @response `200` `ModelsAvatarLabeledRenderSettings` OK
     * @response `default` `CommonBusinessError`
     */
    dummy: (params: RequestParams = {}) =>
      this.request<ModelsAvatarLabeledRenderSettings, CommonBusinessError>({
        path: `/tts/dummy`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tts
     * @name GetGenerators
     * @request GET:/tts/generators
     * @secure
     * @response `200` `DtoTTSGeneratorList` OK
     */
    getGenerators: (params: RequestParams = {}) =>
      this.request<DtoTTSGeneratorList, any>({
        path: `/tts/generators`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tts
     * @name GetGenerator
     * @request GET:/tts/generators/{generatorId}
     * @secure
     * @response `200` `DtoTTSGeneratorSettings` OK
     */
    getGenerator: (generatorId: string, params: RequestParams = {}) =>
      this.request<DtoTTSGeneratorSettings, any>({
        path: `/tts/generators/${generatorId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tts
     * @name Render
     * @request POST:/tts/render
     * @secure
     * @response `default` `CommonBusinessError`
     */
    render: (payload: DtoTTSRenderRequest, params: RequestParams = {}) =>
      this.request<any, CommonBusinessError>({
        path: `/tts/render`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tts
     * @name RenderRuntimeSample
     * @request POST:/tts/render-runtime-sample
     * @secure
     * @response `default` `CommonBusinessError`
     */
    renderRuntimeSample: (payload: DtoTTSRenderRuntimeSampleRequest, params: RequestParams = {}) =>
      this.request<any, CommonBusinessError>({
        path: `/tts/render-runtime-sample`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tts
     * @name RenderUpload
     * @request POST:/tts/render/upload
     * @secure
     * @response `200` `DtoTTSRenderResponse` OK
     * @response `default` `CommonBusinessError`
     */
    renderUpload: (payload: DtoTTSRenderRequest, params: RequestParams = {}) =>
      this.request<DtoTTSRenderResponse, CommonBusinessError>({
        path: `/tts/render/upload`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tts
     * @name ResolveCodeFences
     * @request POST:/tts/resolve-code-fences
     * @secure
     * @response `200` `DtoTTSResolveCodeFencesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    resolveCodeFences: (payload: DtoTTSResolveCodeFencesRequest, params: RequestParams = {}) =>
      this.request<DtoTTSResolveCodeFencesResponse, CommonBusinessError>({
        path: `/tts/resolve-code-fences`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  block = {
    /**
     * No description
     *
     * @tags Block
     * @name GetBlockSettings
     * @request GET:/block-settings/{type}
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    getBlockSettings: (type: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/block-settings/${type}`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name UpdateBlockSettings
     * @request POST:/block-settings/{type}
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateBlockSettings: (type: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/block-settings/${type}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Create block
     *
     * @tags Block
     * @name CreateBlock
     * @request POST:/blocks
     * @secure
     * @response `200` `DtoBlockListResp` OK
     * @response `default` `CommonBusinessError`
     */
    createBlock: (payload: DtoCreateStandaloneBlockRequest, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/blocks`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name DeleteBlock
     * @request DELETE:/blocks/{id}
     * @secure
     * @response `200` `DtoBlockListResp` OK
     * @response `default` `CommonBusinessError`
     */
    deleteBlock: (id: string, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/blocks/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name GetBlock
     * @request GET:/blocks/{id}
     * @secure
     * @response `200` `DtoBlockResp` OK
     * @response `default` `CommonBusinessError`
     */
    getBlock: (id: string, params: RequestParams = {}) =>
      this.request<DtoBlockResp, CommonBusinessError>({
        path: `/blocks/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name UpdateBlockField
     * @request PUT:/blocks/{id}/field
     * @secure
     * @response `200` `CommonRecord` OK
     * @response `default` `CommonBusinessError`
     */
    updateBlockField: (id: string, payload: DtoUpdateBlockFieldReq, params: RequestParams = {}) =>
      this.request<CommonRecord, CommonBusinessError>({
        path: `/blocks/${id}/field`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name UpdateBlockFields
     * @request PUT:/blocks/{id}/fields
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateBlockFields: (id: string, payload: CommonRecord, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/blocks/${id}/fields`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name UpdateBlockVideoEffectsSettings
     * @request PUT:/blocks/{id}/green-screen-settings
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateBlockVideoEffectsSettings: (id: string, payload: DtoVideoEffectsSettings, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/blocks/${id}/green-screen-settings`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name QueryJeopardyCompetitions
     * @request GET:/blocks/{id}/jeopardy-competitions
     * @secure
     * @response `200` `DtoJeopardySessionsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryJeopardyCompetitions: (id: string, params: RequestParams = {}) =>
      this.request<DtoJeopardySessionsResponse, CommonBusinessError>({
        path: `/blocks/${id}/jeopardy-competitions`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name CreateJeopardySession
     * @request POST:/blocks/{id}/jeopardy-session
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    createJeopardySession: (id: string, payload: DtoCreateJeopardySessionRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/blocks/${id}/jeopardy-session`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name UpdateBlockOutdatedRecording
     * @request PUT:/blocks/{id}/outdated-recording
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateBlockOutdatedRecording: (id: string, payload: DtoUpdateOutdatedRecordingReq, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/blocks/${id}/outdated-recording`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name DeletePuzzleSliceJob
     * @request DELETE:/blocks/{id}/puzzle-slice-job
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deletePuzzleSliceJob: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/blocks/${id}/puzzle-slice-job`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name UpdatePuzzleSliceJob
     * @request PUT:/blocks/{id}/puzzle-slice-job
     * @secure
     * @response `200` `DtoBlockResp` OK
     * @response `default` `CommonBusinessError`
     */
    updatePuzzleSliceJob: (id: string, payload: DtoUpdatePuzzleSliceJobRequest, params: RequestParams = {}) =>
      this.request<DtoBlockResp, CommonBusinessError>({
        path: `/blocks/${id}/puzzle-slice-job`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name DeleteBlockRecording
     * @request DELETE:/blocks/{id}/recording
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteBlockRecording: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/blocks/${id}/recording`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name AddBlockRecording
     * @request POST:/blocks/{id}/recording
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    addBlockRecording: (id: string, payload: DtoAddBlockRecordingReq, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/blocks/${id}/recording`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name UpdateBlockRecordingMediaId
     * @request PUT:/blocks/{id}/recording-mediaId
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateBlockRecordingMediaId: (id: string, payload: DtoUpdateBlockRecordingMediaIdReq, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/blocks/${id}/recording-mediaId`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name GetBlockData
     * @request GET:/blocks/{id}/refresh-data
     * @secure
     * @response `200` `DtoGetBlockDataEntryResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getBlockData: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetBlockDataEntryResponse, CommonBusinessError>({
        path: `/blocks/${id}/refresh-data`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name RefreshBlockData
     * @request PUT:/blocks/{id}/refresh-data
     * @secure
     * @response `200` `DtoGetBlockDataEntryResponse` OK
     * @response `default` `CommonBusinessError`
     */
    refreshBlockData: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetBlockDataEntryResponse, CommonBusinessError>({
        path: `/blocks/${id}/refresh-data`,
        method: 'PUT',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name GetSparkifact
     * @request GET:/blocks/{id}/sparkifact
     * @secure
     * @response `200` `DtoSparkifactFilesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getSparkifact: (id: string, params: RequestParams = {}) =>
      this.request<DtoSparkifactFilesResponse, CommonBusinessError>({
        path: `/blocks/${id}/sparkifact`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name UploadSparkifactFile
     * @request POST:/blocks/{id}/sparkifact/upload
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    uploadSparkifactFile: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/blocks/${id}/sparkifact/upload`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name GetBlockVote
     * @request GET:/blocks/{id}/vote
     * @secure
     * @response `200` `DtoGetBlockVoteResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getBlockVote: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetBlockVoteResponse, CommonBusinessError>({
        path: `/blocks/${id}/vote`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name CreateBlockVote
     * @request POST:/blocks/{id}/vote
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    createBlockVote: (id: string, payload: DtoCreateBlockVoteRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/blocks/${id}/vote`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name CountBlocks
     * @request GET:/blocks/count
     * @secure
     * @response `200` `DtoBlockCountResponse` OK
     * @response `default` `CommonBusinessError`
     */
    countBlocks: (params: RequestParams = {}) =>
      this.request<DtoBlockCountResponse, CommonBusinessError>({
        path: `/blocks/count`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name BlockDummy
     * @request GET:/blocks/dummy
     * @secure
     * @response `200` `EnumsJeopardyHostScriptKey` OK
     * @response `default` `CommonBusinessError`
     */
    blockDummy: (params: RequestParams = {}) =>
      this.request<EnumsJeopardyHostScriptKey, CommonBusinessError>({
        path: `/blocks/dummy`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name RenderBlocks
     * @request POST:/blocks/render
     * @secure
     * @response `200` `DtoBlockListResp` OK
     * @response `default` `CommonBusinessError`
     */
    renderBlocks: (payload: DtoRenderBlocksRequest, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/blocks/render`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Block
     * @name SearchBlocks
     * @request GET:/blocks/search
     * @secure
     * @response `200` `DtoBlockSearchResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchBlocks: (query: SearchBlocksParams, params: RequestParams = {}) =>
      this.request<DtoBlockSearchResponse, CommonBusinessError>({
        path: `/blocks/search`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  game = {
    /**
     * No description
     *
     * @tags Game
     * @name MoveBlock
     * @request PUT:/blocks
     * @secure
     * @response `200` `DtoBlockListResp` OK
     * @response `default` `CommonBusinessError`
     */
    moveBlock: (payload: DtoMoveBlocksReq, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/blocks`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name DuplicateBlock
     * @request POST:/blocks/{id}
     * @secure
     * @response `201` `DtoBlockListResp` Created
     * @response `default` `CommonBusinessError`
     */
    duplicateBlock: (id: string, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/blocks/${id}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name QueryBrands
     * @request GET:/brands
     * @secure
     * @response `200` `DtoMultiBrandResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryBrands: (query: QueryBrandsParams, params: RequestParams = {}) =>
      this.request<DtoMultiBrandResponse, CommonBusinessError>({
        path: `/brands`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name CreateBrand
     * @request POST:/brands
     * @secure
     * @response `200` `DtoSingleBrandResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createBrand: (payload: DtoCreateBrandRequest, params: RequestParams = {}) =>
      this.request<DtoSingleBrandResponse, CommonBusinessError>({
        path: `/brands`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name DeleteBrand
     * @request DELETE:/brands/{id}
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteBrand: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/brands/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name GetBrand
     * @request GET:/brands/{id}
     * @secure
     * @response `200` `DtoSingleBrandResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getBrand: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleBrandResponse, CommonBusinessError>({
        path: `/brands/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name UpdateBrand
     * @request PUT:/brands/{id}
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateBrand: (id: string, payload: DtoUpdateBrandRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/brands/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name DuplicateBrand
     * @request POST:/brands/{id}/duplicate
     * @secure
     * @response `200` `DtoSingleBrandResponse` OK
     * @response `default` `CommonBusinessError`
     */
    duplicateBrand: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleBrandResponse, CommonBusinessError>({
        path: `/brands/${id}/duplicate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name GetPublicBrand
     * @request GET:/brands/{id}/public
     * @secure
     * @response `200` `DtoSingleBrandResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getPublicBrand: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleBrandResponse, CommonBusinessError>({
        path: `/brands/${id}/public`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name CountBrands
     * @request GET:/brands/count
     * @secure
     * @response `200` `DtoBrandsCountResponse` OK
     * @response `default` `CommonBusinessError`
     */
    countBrands: (params: RequestParams = {}) =>
      this.request<DtoBrandsCountResponse, CommonBusinessError>({
        path: `/brands/count`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name SearchBrands
     * @request GET:/brands/search
     * @secure
     * @response `200` `DtoMultiBrandResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchBrands: (query: SearchBrandsParams, params: RequestParams = {}) =>
      this.request<DtoMultiBrandResponse, CommonBusinessError>({
        path: `/brands/search`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name CreateGamePack
     * @request POST:/game-packs
     * @secure
     * @response `201` `DtoSingleGamePackResponse` Created
     * @response `default` `CommonBusinessError`
     */
    createGamePack: (payload: DtoCreateGamePackRequest, params: RequestParams = {}) =>
      this.request<DtoSingleGamePackResponse, CommonBusinessError>({
        path: `/game-packs`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name QueryGames
     * @request GET:/games
     * @secure
     * @response `200` `DtoGameListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryGames: (params: RequestParams = {}) =>
      this.request<DtoGameListResponse, CommonBusinessError>({
        path: `/games`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name CreateGame
     * @request POST:/games
     * @secure
     * @response `201` `DtoSingleGameResponse` Created
     */
    createGame: (payload: DtoUpdateGameLikeRequest, params: RequestParams = {}) =>
      this.request<DtoSingleGameResponse, any>({
        path: `/games`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name DeleteGame
     * @request DELETE:/games/{id}
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteGame: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/games/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name GetGame
     * @request GET:/games/{id}
     * @secure
     * @response `200` `DtoSingleGameResponse` OK
     */
    getGame: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleGameResponse, any>({
        path: `/games/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name UpdateGame
     * @request PUT:/games/{id}
     * @secure
     * @response `200` `DtoSingleGameResponse` OK
     */
    updateGame: (id: string, payload: DtoUpdateGameLikeRequest, params: RequestParams = {}) =>
      this.request<DtoSingleGameResponse, any>({
        path: `/games/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name GetGameBlocks
     * @request GET:/games/{id}/blocks
     * @secure
     * @response `200` `DtoBlockListResp` OK
     * @response `default` `CommonBusinessError`
     */
    getGameBlocks: (id: string, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/games/${id}/blocks`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a game block
     *
     * @tags Game
     * @name CreateGameBlock
     * @request POST:/games/{id}/blocks
     * @secure
     * @response `200` `DtoBlockListResp` OK
     * @response `default` `CommonBusinessError`
     */
    createGameBlock: (id: string, payload: DtoCreateGameBlockRequest, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/games/${id}/blocks`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name DetachGameBlock
     * @request DELETE:/games/{id}/blocks/{blockId}
     * @secure
     * @response `200` `DtoBlockListResp` OK
     * @response `default` `CommonBusinessError`
     */
    detachGameBlock: (id: string, blockId: string, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/games/${id}/blocks/${blockId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name DuplicateGameBlock
     * @request POST:/games/{id}/blocks/{blockId}
     * @secure
     * @response `201` `DtoBlockListResp` Created
     * @response `default` `CommonBusinessError`
     */
    duplicateGameBlock: (id: string, blockId: string, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/games/${id}/blocks/${blockId}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name AttachGameBlock
     * @request POST:/games/{id}/blocks/attach
     * @secure
     * @response `200` `DtoBlockListResp` OK
     * @response `default` `CommonBusinessError`
     */
    attachGameBlock: (id: string, payload: DtoAttachGameBlockReq, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/games/${id}/blocks/attach`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name DuplicateGameBlocks
     * @request POST:/games/{id}/blocks/duplicate
     * @secure
     * @response `201` `DtoBlockListResp` Created
     * @response `default` `CommonBusinessError`
     */
    duplicateGameBlocks: (id: string, params: RequestParams = {}) =>
      this.request<DtoBlockListResp, CommonBusinessError>({
        path: `/games/${id}/blocks/duplicate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name DuplicateGame
     * @request POST:/games/{id}/duplicate
     * @secure
     * @response `201` `DtoDuplicateGameResponse` Created
     */
    duplicateGame: (id: string, params: RequestParams = {}) =>
      this.request<DtoDuplicateGameResponse, any>({
        path: `/games/${id}/duplicate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name GetLinkedGamePacks
     * @request GET:/games/{id}/game-packs
     * @secure
     * @response `200` `DtoSingleGameResponse` OK
     */
    getLinkedGamePacks: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleGameResponse, any>({
        path: `/games/${id}/game-packs`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name PublishGame
     * @request POST:/games/{id}/publish
     * @secure
     * @response `201` `DtoDuplicateGameResponse` Created
     * @response `default` `CommonBusinessError`
     */
    publishGame: (id: string, payload: DtoUpdateGameLikeRequest, params: RequestParams = {}) =>
      this.request<DtoDuplicateGameResponse, CommonBusinessError>({
        path: `/games/${id}/publish`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name SearchGames
     * @request GET:/games/search
     * @secure
     * @response `200` `DtoGameListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchGames: (params: RequestParams = {}) =>
      this.request<DtoGameListResponse, CommonBusinessError>({
        path: `/games/search`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  celebration = {
    /**
     * No description
     *
     * @tags Celebration
     * @name ListCelebration
     * @request GET:/celebrations
     * @secure
     * @response `200` `DtoMultiCelebrationsResponse` OK
     */
    listCelebration: (params: RequestParams = {}) =>
      this.request<DtoMultiCelebrationsResponse, any>({
        path: `/celebrations`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Celebration
     * @name CreateCelebration
     * @request POST:/celebrations
     * @secure
     * @response `200` `DtoSingleCelebrationResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createCelebration: (payload: DtoCreateCelebrationRequest, params: RequestParams = {}) =>
      this.request<DtoSingleCelebrationResponse, CommonBusinessError>({
        path: `/celebrations`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Celebration
     * @name GetCelebration
     * @request GET:/celebrations/{id}
     * @secure
     * @response `200` `DtoSingleCelebrationResponse` OK
     */
    getCelebration: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleCelebrationResponse, any>({
        path: `/celebrations/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Celebration
     * @name GetUpcomingEvents
     * @request GET:/celebrations/{id}/events/upcoming
     * @secure
     * @response `200` `DtoMultiCelebrationParticipantsResponse` OK
     */
    getUpcomingEvents: (id: string, params: RequestParams = {}) =>
      this.request<DtoMultiCelebrationParticipantsResponse, any>({
        path: `/celebrations/${id}/events/upcoming`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Celebration
     * @name UpdateMessageSettings
     * @request POST:/celebrations/{id}/message-settings
     * @secure
     * @response `200` `void` OK
     */
    updateMessageSettings: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/celebrations/${id}/message-settings`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Celebration
     * @name GetCelebrationParticipants
     * @request GET:/celebrations/{id}/participants
     * @secure
     * @response `200` `DtoMultiCelebrationParticipantsResponse` OK
     */
    getCelebrationParticipants: (id: string, params: RequestParams = {}) =>
      this.request<DtoMultiCelebrationParticipantsResponse, any>({
        path: `/celebrations/${id}/participants`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Celebration
     * @name UpdateCelebrationParticipantBirthday
     * @request PUT:/celebrations/{id}/participants/{uid}/birthday
     * @secure
     * @response `200` `void` OK
     */
    updateCelebrationParticipantBirthday: (id: string, uid: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/celebrations/${id}/participants/${uid}/birthday`,
        method: 'PUT',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Celebration
     * @name UpdateCelebrationParticipant
     * @request PUT:/celebrations/{id}/participants/{uid}/settings
     * @secure
     * @response `200` `void` OK
     */
    updateCelebrationParticipant: (id: string, uid: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/celebrations/${id}/participants/${uid}/settings`,
        method: 'PUT',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Celebration
     * @name UpdateCelebrationParticipantWorkStartDate
     * @request PUT:/celebrations/{id}/participants/{uid}/work-start-date
     * @secure
     * @response `200` `void` OK
     */
    updateCelebrationParticipantWorkStartDate: (id: string, uid: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/celebrations/${id}/participants/${uid}/work-start-date`,
        method: 'PUT',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Celebration
     * @name ImportCelebrationDates
     * @request POST:/celebrations/{id}/participants/import-dates
     * @secure
     * @response `200` `DtoMultiCelebrationParticipantsResponse` OK
     */
    importCelebrationDates: (id: string, params: RequestParams = {}) =>
      this.request<DtoMultiCelebrationParticipantsResponse, any>({
        path: `/celebrations/${id}/participants/import-dates`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  channel = {
    /**
     * No description
     *
     * @tags Channel
     * @name QueryChannels
     * @request GET:/channels
     * @secure
     * @response `200` `DtoChannelListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryChannels: (query: QueryChannelsParams, params: RequestParams = {}) =>
      this.request<DtoChannelListResponse, CommonBusinessError>({
        path: `/channels`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name CreateChannel
     * @request POST:/channels
     * @secure
     * @response `200` `DtoChannelResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createChannel: (payload: DtoCreateChannelRequest, params: RequestParams = {}) =>
      this.request<DtoChannelResponse, CommonBusinessError>({
        path: `/channels`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name DeleteChannel
     * @request DELETE:/channels/:id
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteChannel: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/channels/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name GetChannel
     * @request GET:/channels/:id
     * @secure
     * @response `200` `DtoChannelResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getChannel: (id: string, params: RequestParams = {}) =>
      this.request<DtoChannelResponse, CommonBusinessError>({
        path: `/channels/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name GetChannelMembers
     * @request GET:/channels/:id/members
     * @secure
     * @response `200` `DtoMemberListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getChannelMembers: (id: string, params: RequestParams = {}) =>
      this.request<DtoMemberListResponse, CommonBusinessError>({
        path: `/channels/${id}/members`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name AddChannelMembers
     * @request POST:/channels/:id/members
     * @secure
     * @response `200` `DtoMemberListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    addChannelMembers: (id: string, payload: DtoAddMembersRequest, params: RequestParams = {}) =>
      this.request<DtoMemberListResponse, CommonBusinessError>({
        path: `/channels/${id}/members`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name RemoveChannelMember
     * @request DELETE:/channels/:id/members/:memberId
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    removeChannelMember: (id: string, memberId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/channels/${id}/members/${memberId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name SnoozeChannelMember
     * @request POST:/channels/:id/members/:memberId/snooze
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    snoozeChannelMember: (id: string, memberId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/channels/${id}/members/${memberId}/snooze`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name UnSnoozeChannelMember
     * @request POST:/channels/:id/members/:memberId/unsnooze
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    unSnoozeChannelMember: (id: string, memberId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/channels/${id}/members/${memberId}/unsnooze`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name AddProgramLink
     * @request POST:/channels/:id/program-links
     * @secure
     * @response `200` `DtoChannelProgramLinkResponse` OK
     * @response `default` `CommonBusinessError`
     */
    addProgramLink: (id: string, payload: DtoAddProgramLinkRequest, params: RequestParams = {}) =>
      this.request<DtoChannelProgramLinkResponse, CommonBusinessError>({
        path: `/channels/${id}/program-links`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name UpdateProgramLink
     * @request PUT:/channels/:id/program-links/:linkId
     * @secure
     * @response `200` `DtoChannelProgramLinkResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateProgramLink: (id: string, linkId: string, payload: DtoUpdateProgramLinkRequest, params: RequestParams = {}) =>
      this.request<DtoChannelProgramLinkResponse, CommonBusinessError>({
        path: `/channels/${id}/program-links/${linkId}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name ActivateProgramLink
     * @request POST:/channels/:id/program-links/:linkId/activate
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    activateProgramLink: (id: string, linkId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/channels/${id}/program-links/${linkId}/activate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name DeactivateProgramLink
     * @request POST:/channels/:id/program-links/:linkId/deactivate
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deactivateProgramLink: (id: string, linkId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/channels/${id}/program-links/${linkId}/deactivate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name CreateProgramLinkRound
     * @request POST:/channels/:id/program-links/:linkId/rounds
     * @secure
     * @response `200` `DtoProgramRoundResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createProgramLinkRound: (
      id: string,
      linkId: string,
      payload: DtoCreateProgramRoundRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoProgramRoundResponse, CommonBusinessError>({
        path: `/channels/${id}/program-links/${linkId}/rounds`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name DeleteProgramLinkRound
     * @request DELETE:/channels/:id/program-links/:linkId/rounds/:roundId
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteProgramLinkRound: (id: string, linkId: string, roundId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/channels/${id}/program-links/${linkId}/rounds/${roundId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name OrgSummary
     * @request GET:/channels/org-summaries
     * @secure
     * @response `200` `DtoChannelOrgSummaryResponse` OK
     * @response `default` `CommonBusinessError`
     */
    orgSummary: (params: RequestParams = {}) =>
      this.request<DtoChannelOrgSummaryResponse, CommonBusinessError>({
        path: `/channels/org-summaries`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name AllProgramLinks
     * @request GET:/channels/program-links
     * @secure
     * @response `200` `DtoChannelProgramLinksResponse` OK
     * @response `default` `CommonBusinessError`
     */
    allProgramLinks: (query: AllProgramLinksParams, params: RequestParams = {}) =>
      this.request<DtoChannelProgramLinksResponse, CommonBusinessError>({
        path: `/channels/program-links`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Channel
     * @name GetChannelByProgramLinkId
     * @request GET:/channels/program-links/:id
     * @secure
     * @response `200` `DtoChannelResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getChannelByProgramLinkId: (id: string, params: RequestParams = {}) =>
      this.request<DtoChannelResponse, CommonBusinessError>({
        path: `/channels/program-links/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  program = {
    /**
     * No description
     *
     * @tags Program
     * @name UpdateProgramLinkRound
     * @request PUT:/channels/:id/program-links/:linkId/rounds/:roundId
     * @secure
     * @response `200` `DtoProgramRoundResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateProgramLinkRound: (
      id: string,
      linkId: string,
      roundId: string,
      payload: DtoUpdateProgramRoundRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoProgramRoundResponse, CommonBusinessError>({
        path: `/channels/${id}/program-links/${linkId}/rounds/${roundId}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name QueryProgramsSummary
     * @request GET:/organizations/{id}/programs-summary
     * @secure
     * @response `200` `DtoQueryProgramsSummaryResponse` OK
     */
    queryProgramsSummary: ({ id, ...query }: QueryProgramsSummaryParams, params: RequestParams = {}) =>
      this.request<DtoQueryProgramsSummaryResponse, any>({
        path: `/organizations/${id}/programs-summary`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name QueryRecognitionExGroups
     * @request GET:/organizations/{id}/recognition-groups
     * @secure
     * @response `200` `DtoQueryRecognitionExGroupsResponse` OK
     */
    queryRecognitionExGroups: (id: string, params: RequestParams = {}) =>
      this.request<DtoQueryRecognitionExGroupsResponse, any>({
        path: `/organizations/${id}/recognition-groups`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name QueryRecognitionSummary
     * @request GET:/organizations/{id}/recognition-summary
     * @secure
     * @response `200` `DtoQueryRecognitionSummaryResponse` OK
     */
    queryRecognitionSummary: ({ id, ...query }: QueryRecognitionSummaryParams, params: RequestParams = {}) =>
      this.request<DtoQueryRecognitionSummaryResponse, any>({
        path: `/organizations/${id}/recognition-summary`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name QueryProgramRounds
     * @request GET:/program-rounds
     * @secure
     * @response `200` `DtoProgramRoundsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryProgramRounds: (query: QueryProgramRoundsParams, params: RequestParams = {}) =>
      this.request<DtoProgramRoundsResponse, CommonBusinessError>({
        path: `/program-rounds`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name GetPrograms
     * @request GET:/programs
     * @secure
     * @response `200` `DtoGetProgramsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getPrograms: (params: RequestParams = {}) =>
      this.request<DtoGetProgramsResponse, CommonBusinessError>({
        path: `/programs`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name CreateProgram
     * @request POST:/programs
     * @secure
     * @response `200` `DtoProgramResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createProgram: (payload: DtoCreateProgramRequest, params: RequestParams = {}) =>
      this.request<DtoProgramResponse, CommonBusinessError>({
        path: `/programs`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name GetProgram
     * @request GET:/programs/:id
     * @secure
     * @response `200` `DtoGetProgramsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getProgram: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetProgramsResponse, CommonBusinessError>({
        path: `/programs/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name UpdateProgram
     * @request PUT:/programs/:id
     * @secure
     * @response `200` `DtoGetProgramsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateProgram: (id: string, payload: DtoUpdateProgramRequest, params: RequestParams = {}) =>
      this.request<DtoGetProgramsResponse, CommonBusinessError>({
        path: `/programs/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name CreateProgramRound
     * @request POST:/programs/:id/rounds
     * @secure
     * @response `200` `DtoProgramRoundResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createProgramRound: (id: string, payload: DtoCreateProgramRoundRequest, params: RequestParams = {}) =>
      this.request<DtoProgramRoundResponse, CommonBusinessError>({
        path: `/programs/${id}/rounds`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name DeleteProgramRound
     * @request DELETE:/programs/:id/rounds/:roundId
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteProgramRound: (id: string, roundId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/programs/${id}/rounds/${roundId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name DuplicateProgramRound
     * @request POST:/programs/:id/rounds/:roundId
     * @secure
     * @response `200` `DtoProgramRoundResponse` OK
     * @response `default` `CommonBusinessError`
     */
    duplicateProgramRound: (id: string, roundId: string, params: RequestParams = {}) =>
      this.request<DtoProgramRoundResponse, CommonBusinessError>({
        path: `/programs/${id}/rounds/${roundId}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name UpdateProgramRound
     * @request PUT:/programs/:id/rounds/:roundId
     * @secure
     * @response `200` `DtoProgramRoundResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateProgramRound: (
      id: string,
      roundId: string,
      payload: DtoUpdateProgramRoundRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoProgramRoundResponse, CommonBusinessError>({
        path: `/programs/${id}/rounds/${roundId}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name RepairProgramRound
     * @request POST:/programs/:id/rounds/:roundId/repair
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    repairProgramRound: (id: string, roundId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/programs/${id}/rounds/${roundId}/repair`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name GetActivePrograms
     * @request GET:/programs/active
     * @secure
     * @response `200` `DtoGetProgramsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getActivePrograms: (params: RequestParams = {}) =>
      this.request<DtoGetProgramsResponse, CommonBusinessError>({
        path: `/programs/active`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name UpdateActivePrograms
     * @request PUT:/programs/active
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateActivePrograms: (payload: DtoUpdateActiveProgramsRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/programs/active`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name QueryProgramGroups
     * @request GET:/programs/groups
     * @secure
     * @response `200` `DtoProgramRoundGroupsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryProgramGroups: (query: QueryProgramGroupsParams, params: RequestParams = {}) =>
      this.request<DtoProgramRoundGroupsResponse, CommonBusinessError>({
        path: `/programs/groups`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name PlayGroupGame
     * @request POST:/programs/groups/:id/open-game
     * @secure
     * @response `200` `DtoOpenProgramGroupGameResponse` OK
     * @response `default` `CommonBusinessError`
     */
    playGroupGame: (id: string, params: RequestParams = {}) =>
      this.request<DtoOpenProgramGroupGameResponse, CommonBusinessError>({
        path: `/programs/groups/${id}/open-game`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Program
     * @name StartGroupGame
     * @request POST:/programs/groups/:id/start-game
     * @secure
     * @response `200` `DtoStartProgramGroupGameRequest` OK
     * @response `default` `CommonBusinessError`
     */
    startGroupGame: (id: string, params: RequestParams = {}) =>
      this.request<DtoStartProgramGroupGameRequest, CommonBusinessError>({
        path: `/programs/groups/${id}/start-game`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  misc = {
    /**
     * No description
     *
     * @tags Misc
     * @name DisableAutoScaling
     * @request GET:/cloud-hosting/auto-scaling/disable
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    disableAutoScaling: (params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/cloud-hosting/auto-scaling/disable`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Misc
     * @name EnableAutoScaling
     * @request GET:/cloud-hosting/auto-scaling/enable
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    enableAutoScaling: (params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/cloud-hosting/auto-scaling/enable`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Misc
     * @name GetAutoScalingStatus
     * @request GET:/cloud-hosting/auto-scaling/status
     * @response `200` `DtoGetAutoScalingStatusResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getAutoScalingStatus: (params: RequestParams = {}) =>
      this.request<DtoGetAutoScalingStatusResponse, CommonBusinessError>({
        path: `/cloud-hosting/auto-scaling/status`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Misc
     * @name GetTestResult
     * @request GET:/network-test/last-result
     * @response `200` `DtoGetTestResultResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getTestResult: (params: RequestParams = {}) =>
      this.request<DtoGetTestResultResponse, CommonBusinessError>({
        path: `/network-test/last-result`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Misc
     * @name ReportTestResult
     * @request POST:/network-test/report
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    reportTestResult: (payload: DtoReportTestResultRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/network-test/report`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Misc
     * @name Ping
     * @request GET:/ping
     * @response `200` `DtoPingResponse` OK
     * @response `default` `CommonBusinessError`
     */
    ping: (params: RequestParams = {}) =>
      this.request<DtoPingResponse, CommonBusinessError>({
        path: `/ping`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  cloudHosting = {
    /**
     * No description
     *
     * @tags CloudHosting
     * @name GetCloudInstanceDetails
     * @request GET:/cloud-hosting/cloud-instance-details
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    getCloudInstanceDetails: (params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/cloud-hosting/cloud-instance-details`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CloudHosting
     * @name DisableInstance
     * @request POST:/cloud-hosting/instances/:id/disable
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    disableInstance: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/cloud-hosting/instances/${id}/disable`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CloudHosting
     * @name EnabledInstance
     * @request POST:/cloud-hosting/instances/:id/enable
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    enabledInstance: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/cloud-hosting/instances/${id}/enable`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CloudHosting
     * @name StartInstance
     * @request POST:/cloud-hosting/instances/:id/start
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    startInstance: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/cloud-hosting/instances/${id}/start`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  dmg = {
    /**
     * @description Build all the templates from the DMG Service
     *
     * @tags Dmg
     * @name GetDmgTemplates
     * @request GET:/dmg/templates
     * @secure
     * @response `200` `ClientTemplatesResponseDto` OK
     * @response `default` `CommonBusinessError`
     */
    getDmgTemplates: (params: RequestParams = {}) =>
      this.request<ClientTemplatesResponseDto, CommonBusinessError>({
        path: `/dmg/templates`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Renders the given template
     *
     * @tags Dmg
     * @name RenderDmgTemplate
     * @request POST:/dmg/templates/{templateId}/generate
     * @secure
     * @response `200` `DtoDmgRenderResponse` OK
     * @response `default` `CommonBusinessError`
     */
    renderDmgTemplate: (templateId: string, payload: DtoDmgRenderRequest, params: RequestParams = {}) =>
      this.request<DtoDmgRenderResponse, CommonBusinessError>({
        path: `/dmg/templates/${templateId}/generate`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  drawing = {
    /**
     * No description
     *
     * @tags Drawing
     * @name CreateDrawing
     * @request POST:/drawings
     * @secure
     * @response `201` `DtoSingleDrawingResponse` Created
     */
    createDrawing: (payload: DtoCreateDrawingRequest, params: RequestParams = {}) =>
      this.request<DtoSingleDrawingResponse, any>({
        path: `/drawings`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Drawing
     * @name SearchDrawings
     * @request GET:/drawings/search
     * @secure
     * @response `200` `DtoDetailDrawingListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchDrawings: (params: RequestParams = {}) =>
      this.request<DtoDetailDrawingListResponse, CommonBusinessError>({
        path: `/drawings/search`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  event = {
    /**
     * No description
     *
     * @tags Event
     * @name QueryEvents
     * @request GET:/events
     * @secure
     * @response `200` `DtoEventsResponse` OK
     */
    queryEvents: (query: QueryEventsParams, params: RequestParams = {}) =>
      this.request<DtoEventsResponse, any>({
        path: `/events`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name CreateEvent
     * @request POST:/events
     * @secure
     * @response `200` `DtoEventResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createEvent: (payload: DtoCreateEventRequest, params: RequestParams = {}) =>
      this.request<DtoEventResponse, CommonBusinessError>({
        path: `/events`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name DeleteEvent
     * @request DELETE:/events/:id
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteEvent: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/events/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetEvent
     * @request GET:/events/:id
     * @secure
     * @response `200` `DtoEventResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getEvent: (id: string, params: RequestParams = {}) =>
      this.request<DtoEventResponse, CommonBusinessError>({
        path: `/events/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name UpdateEvent
     * @request PUT:/events/:id
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateEvent: (id: string, payload: DtoUpdateEventRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/events/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetEventForIntake
     * @request GET:/events/:id/intake
     * @secure
     * @response `200` `DtoIntakeEventResponse` OK
     */
    getEventForIntake: (id: string, params: RequestParams = {}) =>
      this.request<DtoIntakeEventResponse, any>({
        path: `/events/${id}/intake`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name CreateIntakeForm
     * @request POST:/events/:id/intake
     * @secure
     * @response `200` `DtoEventIntakeFormResponse` OK
     */
    createIntakeForm: (id: string, payload: DtoCreateEventIntakeFormRequest, params: RequestParams = {}) =>
      this.request<DtoEventIntakeFormResponse, any>({
        path: `/events/${id}/intake`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name DeleteIntakeForm
     * @request DELETE:/events/:id/intake/:formId
     * @secure
     * @response `200` `void` OK
     */
    deleteIntakeForm: (id: string, formId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events/${id}/intake/${formId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name UpdateIntakeForm
     * @request PUT:/events/:id/intake/:formId
     * @secure
     * @response `200` `DtoEventIntakeFormResponse` OK
     */
    updateIntakeForm: (
      id: string,
      formId: string,
      payload: DtoUpdateEventIntakeFormRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoEventIntakeFormResponse, any>({
        path: `/events/${id}/intake/${formId}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetPrevEvent
     * @request GET:/events/:id/intake/:formId/prev-event
     * @secure
     * @response `200` `DtoNullableEventResponse` OK
     */
    getPrevEvent: (id: string, formId: string, params: RequestParams = {}) =>
      this.request<DtoNullableEventResponse, any>({
        path: `/events/${id}/intake/${formId}/prev-event`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name SendIntakeForm
     * @request POST:/events/:id/intake/:formId/send
     * @secure
     * @response `200` `DtoEventIntakeFormResponse` OK
     */
    sendIntakeForm: (id: string, formId: string, params: RequestParams = {}) =>
      this.request<DtoEventIntakeFormResponse, any>({
        path: `/events/${id}/intake/${formId}/send`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name SendIntakeSummary
     * @request POST:/events/:id/intake/:formId/send-summary
     * @secure
     * @response `200` `void` OK
     */
    sendIntakeSummary: (id: string, formId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events/${id}/intake/${formId}/send-summary`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name UpdateIntakeFormStatus
     * @request PUT:/events/:id/intake/:formId/status
     * @secure
     * @response `200` `DtoEventIntakeFormResponse` OK
     */
    updateIntakeFormStatus: (
      id: string,
      formId: string,
      payload: DtoUpdateEventIntakeFormStatusRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoEventIntakeFormResponse, any>({
        path: `/events/${id}/intake/${formId}/status`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetIntakeSummary
     * @request GET:/events/:id/intake/:formId/summary
     * @secure
     * @response `200` `DtoEventIntakeSummaryResponse` OK
     */
    getIntakeSummary: (id: string, formId: string, params: RequestParams = {}) =>
      this.request<DtoEventIntakeSummaryResponse, any>({
        path: `/events/${id}/intake/${formId}/summary`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetPublicEvent
     * @request GET:/events/:id/public
     * @secure
     * @response `200` `DtoPublicEventResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getPublicEvent: (id: string, params: RequestParams = {}) =>
      this.request<DtoPublicEventResponse, CommonBusinessError>({
        path: `/events/${id}/public`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name SendEventSummary
     * @request POST:/events/:id/send-summary
     * @secure
     * @response `200` `void` OK
     */
    sendEventSummary: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events/${id}/send-summary`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name FindLiveEventRequest
     * @request GET:/events/live-event-requests/:id
     * @secure
     * @response `200` `DtoLiveEventRequestResponse` OK
     * @response `default` `CommonBusinessError`
     */
    findLiveEventRequest: (id: string, params: RequestParams = {}) =>
      this.request<DtoLiveEventRequestResponse, CommonBusinessError>({
        path: `/events/live-event-requests/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name LiveHosts
     * @request GET:/events/live-hosts
     * @secure
     * @response `200` `DtoHostsResponse` OK
     */
    liveHosts: (params: RequestParams = {}) =>
      this.request<DtoHostsResponse, any>({
        path: `/events/live-hosts`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name LiveProducers
     * @request GET:/events/live-producers
     * @secure
     * @response `200` `DtoProducersResponse` OK
     */
    liveProducers: (params: RequestParams = {}) =>
      this.request<DtoProducersResponse, any>({
        path: `/events/live-producers`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name LiveStudios
     * @request GET:/events/live-studios
     * @secure
     * @response `200` `DtoStudiosResponse` OK
     */
    liveStudios: (params: RequestParams = {}) =>
      this.request<DtoStudiosResponse, any>({
        path: `/events/live-studios`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name LiveVenues
     * @request GET:/events/live-venues
     * @secure
     * @response `200` `DtoVenuesResponse` OK
     */
    liveVenues: (params: RequestParams = {}) =>
      this.request<DtoVenuesResponse, any>({
        path: `/events/live-venues`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name RecommendAttendees
     * @request GET:/events/recommend-attendees
     * @secure
     * @response `200` `DtoRecommendEventAttendeesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    recommendAttendees: (payload: DtoRecommendEventAttendeesRequest, params: RequestParams = {}) =>
      this.request<DtoRecommendEventAttendeesResponse, CommonBusinessError>({
        path: `/events/recommend-attendees`,
        method: 'GET',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name RequestLiveEvent
     * @request POST:/events/request-live
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    requestLiveEvent: (payload: DtoRequestLiveEventRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/events/request-live`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetMyUpcomingEvents
     * @request GET:/my/events/upcoming
     * @secure
     * @response `200` `DtoEventsResponse` OK
     */
    getMyUpcomingEvents: (params: RequestParams = {}) =>
      this.request<DtoEventsResponse, any>({
        path: `/my/events/upcoming`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  session = {
    /**
     * No description
     *
     * @tags Session
     * @name GetEventAttendees
     * @request GET:/events/:id/attendees
     * @secure
     * @response `200` `DtoGetEventAttendeesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getEventAttendees: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetEventAttendeesResponse, CommonBusinessError>({
        path: `/events/${id}/attendees`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name GetSession
     * @request GET:/sessions/{sid}
     * @secure
     * @response `200` `DtoSessionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getSession: (sid: string, params: RequestParams = {}) =>
      this.request<DtoSessionResponse, CommonBusinessError>({
        path: `/sessions/${sid}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name GetSessionGroupPhoto
     * @request GET:/sessions/{sid}/group-photo
     * @secure
     * @response `200` `DtoGroupPhoto` OK
     * @response `default` `CommonBusinessError`
     */
    getSessionGroupPhoto: (sid: string, params: RequestParams = {}) =>
      this.request<DtoGroupPhoto, CommonBusinessError>({
        path: `/sessions/${sid}/group-photo`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name GenerateSessionGroupPhoto
     * @request POST:/sessions/{sid}/group-photo/generate
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    generateSessionGroupPhoto: (sid: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/sessions/${sid}/group-photo/generate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name GetSessionMemories
     * @request GET:/sessions/{sid}/memories
     * @secure
     * @response `200` `DtoSessionMemoriesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getSessionMemories: ({ sid, ...query }: GetSessionMemoriesParams, params: RequestParams = {}) =>
      this.request<DtoSessionMemoriesResponse, CommonBusinessError>({
        path: `/sessions/${sid}/memories`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Renders the given memories template
     *
     * @tags Session
     * @name RenderMemoriesTemplate
     * @request POST:/sessions/{sid}/memories/templates/render
     * @secure
     * @response `200` `DtoDmgRenderResponse` OK
     * @response `default` `CommonBusinessError`
     */
    renderMemoriesTemplate: (sid: string, payload: DtoRenderMemoriesSouvenirRequest, params: RequestParams = {}) =>
      this.request<DtoDmgRenderResponse, CommonBusinessError>({
        path: `/sessions/${sid}/memories/templates/render`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name GetSessionParticipants
     * @request GET:/sessions/{sid}/participants
     * @secure
     * @response `200` `DtoGetSessionParticipantsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getSessionParticipants: (sid: string, params: RequestParams = {}) =>
      this.request<DtoGetSessionParticipantsResponse, CommonBusinessError>({
        path: `/sessions/${sid}/participants`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name GetSessionSentryStats
     * @request GET:/sessions/{sid}/sentry-stats
     * @secure
     * @response `200` `DtoGetSessionSentryStatsResponse` OK
     */
    getSessionSentryStats: (sid: string, params: RequestParams = {}) =>
      this.request<DtoGetSessionSentryStatsResponse, any>({
        path: `/sessions/${sid}/sentry-stats`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name TrackPlayedBlocks
     * @request POST:/sessions/{sid}/track-played-blocks
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    trackPlayedBlocks: (sid: string, payload: DtoTrackPlayedBlockRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/sessions/${sid}/track-played-blocks`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name GetSessionVoiceOverAudits
     * @request GET:/sessions/{sid}/voice-over-audits
     * @secure
     * @response `200` `DtoSessionVoiceOverAuditsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getSessionVoiceOverAudits: (sid: string, params: RequestParams = {}) =>
      this.request<DtoSessionVoiceOverAuditsResponse, CommonBusinessError>({
        path: `/sessions/${sid}/voice-over-audits`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name ExportSessions
     * @request POST:/sessions/export
     * @secure
     * @response `200` `DtoExportSessionsResponse` OK
     */
    exportSessions: (payload: DtoExportSessionsRequest, params: RequestParams = {}) =>
      this.request<DtoExportSessionsResponse, any>({
        path: `/sessions/export`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name SearchSessions
     * @request GET:/sessions/search
     * @secure
     * @response `200` `DtoSessionListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchSessions: (params: RequestParams = {}) =>
      this.request<DtoSessionListResponse, CommonBusinessError>({
        path: `/sessions/search`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name UpdateSession
     * @request PUT:/venues/{id}/sessions/{sid}
     * @secure
     * @response `200` `DtoSessionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateSession: (id: string, sid: string, payload: DtoUpdateSessionRequest, params: RequestParams = {}) =>
      this.request<DtoSessionResponse, CommonBusinessError>({
        path: `/venues/${id}/sessions/${sid}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name EndSession
     * @request POST:/venues/{id}/sessions/{sid}/end
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    endSession: (id: string, sid: string, payload: DtoEndSessionRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/venues/${id}/sessions/${sid}/end`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name UploadSessionSentiment
     * @request POST:/venues/{id}/sessions/{sid}/sentiments
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    uploadSessionSentiment: (
      id: string,
      sid: string,
      payload: DtoUploadSessionSentimentRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, CommonBusinessError>({
        path: `/venues/${id}/sessions/${sid}/sentiments`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name UpdateSessionSnapshot
     * @request POST:/venues/{id}/sessions/{sid}/snapshot
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateSessionSnapshot: (
      id: string,
      sid: string,
      payload: DtoUpdateSessionSnapshotRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, CommonBusinessError>({
        path: `/venues/${id}/sessions/${sid}/snapshot`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name StartSession
     * @request POST:/venues/{id}/sessions/{sid}/start
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    startSession: (id: string, sid: string, payload: DtoStartSessionRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/venues/${id}/sessions/${sid}/start`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Session
     * @name ReportSessionFailure
     * @request POST:/venues/{id}/sessions/report-failure
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    reportSessionFailure: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/venues/${id}/sessions/report-failure`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  gamePack = {
    /**
     * No description
     *
     * @tags GamePack
     * @name QueryGamePacks
     * @request GET:/game-packs
     * @secure
     * @response `200` `DtoGamePackListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryGamePacks: (params: RequestParams = {}) =>
      this.request<DtoGamePackListResponse, CommonBusinessError>({
        path: `/game-packs`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name DeleteGamePack
     * @request DELETE:/game-packs/{id}
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteGamePack: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/game-packs/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name GetGamePack
     * @request GET:/game-packs/{id}
     * @secure
     * @response `200` `DtoSingleGamePackResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getGamePack: ({ id, ...query }: GetGamePackParams, params: RequestParams = {}) =>
      this.request<DtoSingleGamePackResponse, CommonBusinessError>({
        path: `/game-packs/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name UpdateGamePack
     * @request PUT:/game-packs/{id}
     * @secure
     * @response `200` `DtoSingleGamePackResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateGamePack: (id: string, payload: DtoUpdateGamePackRequest, params: RequestParams = {}) =>
      this.request<DtoSingleGamePackResponse, CommonBusinessError>({
        path: `/game-packs/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name GetLinkedBrands
     * @request GET:/game-packs/{id}/brands
     * @secure
     * @response `200` `DtoMultiBrandResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getLinkedBrands: (id: string, params: RequestParams = {}) =>
      this.request<DtoMultiBrandResponse, CommonBusinessError>({
        path: `/game-packs/${id}/brands`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name CreateGamePackCheckoutSession
     * @request POST:/game-packs/{id}/create-checkout-session
     * @response `200` `DtoCreateGamePackCheckoutSessionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createGamePackCheckoutSession: (
      id: string,
      payload: DtoCreateGamePackCheckoutSessionRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoCreateGamePackCheckoutSessionResponse, CommonBusinessError>({
        path: `/game-packs/${id}/create-checkout-session`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name CreateGamePackInvoice
     * @request POST:/game-packs/{id}/create-invoice
     * @response `200` `DtoCreateGamePackInvoiceResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createGamePackInvoice: (id: string, payload: DtoCreateGamePackInvoiceRequest, params: RequestParams = {}) =>
      this.request<DtoCreateGamePackInvoiceResponse, CommonBusinessError>({
        path: `/game-packs/${id}/create-invoice`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name CreateGamePackOtpIntent
     * @request POST:/game-packs/{id}/create-otp-intent
     * @response `default` `CommonBusinessError`
     */
    createGamePackOtpIntent: (id: string, payload: DtoCreateGamePackOTPIntentRequest, params: RequestParams = {}) =>
      this.request<any, CommonBusinessError>({
        path: `/game-packs/${id}/create-otp-intent`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name CreateGamePackPaymentIntent
     * @request POST:/game-packs/{id}/create-payment-intent
     * @response `200` `DtoCreateGamePackPaymentIntentResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createGamePackPaymentIntent: (
      id: string,
      payload: DtoCreateGamePackPaymentIntentRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoCreateGamePackPaymentIntentResponse, CommonBusinessError>({
        path: `/game-packs/${id}/create-payment-intent`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name CustomizeGamePack
     * @request POST:/game-packs/{id}/customize
     * @secure
     * @response `201` `DtoSingleGamePackResponse` Created
     * @response `default` `CommonBusinessError`
     */
    customizeGamePack: (id: string, payload: DtoCreateCustomizeGamePackRequest, params: RequestParams = {}) =>
      this.request<DtoSingleGamePackResponse, CommonBusinessError>({
        path: `/game-packs/${id}/customize`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name DuplicateGamePack
     * @request POST:/game-packs/{id}/duplicate
     * @secure
     * @response `201` `DtoSingleGamePackResponse` Created
     * @response `default` `CommonBusinessError`
     */
    duplicateGamePack: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleGamePackResponse, CommonBusinessError>({
        path: `/game-packs/${id}/duplicate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name ExportScormPackage
     * @request POST:/game-packs/{id}/export-scorm-package
     * @secure
     * @response `default` `CommonBusinessError`
     */
    exportScormPackage: (id: string, payload: DtoExportScormPackageRequest, params: RequestParams = {}) =>
      this.request<any, CommonBusinessError>({
        path: `/game-packs/${id}/export-scorm-package`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name GetLinkedGames
     * @request GET:/game-packs/{id}/games
     * @secure
     * @response `200` `DtoGameListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getLinkedGames: (id: string, params: RequestParams = {}) =>
      this.request<DtoGameListResponse, CommonBusinessError>({
        path: `/game-packs/${id}/games`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name GenerateBlock
     * @request POST:/game-packs/{id}/generate-block
     * @secure
     * @response `201` `DtoUGCGenerateBlockResponse` Created
     * @response `default` `CommonBusinessError`
     */
    generateBlock: (id: string, payload: DtoUGCGenerateBlockRequest, params: RequestParams = {}) =>
      this.request<DtoUGCGenerateBlockResponse, CommonBusinessError>({
        path: `/game-packs/${id}/generate-block`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name GenerateName
     * @request POST:/game-packs/{id}/generate-name
     * @secure
     * @response `201` `DtoUGCGenerateNameResponse` Created
     * @response `default` `CommonBusinessError`
     */
    generateName: (id: string, payload: DtoUGCGenerateNameRequest, params: RequestParams = {}) =>
      this.request<DtoUGCGenerateNameResponse, CommonBusinessError>({
        path: `/game-packs/${id}/generate-name`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name GenerateTrainingCourse
     * @request POST:/game-packs/{id}/generate-training-course
     * @secure
     * @response `200` `DtoGenerateTrainingCourseResponse` OK
     * @response `default` `CommonBusinessError`
     */
    generateTrainingCourse: (id: string, payload: DtoGenerateTrainingCourseRequest, params: RequestParams = {}) =>
      this.request<DtoGenerateTrainingCourseResponse, CommonBusinessError>({
        path: `/game-packs/${id}/generate-training-course`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name OptimizeUgcPrompt
     * @request POST:/game-packs/{id}/optimize-ugc-prompt
     * @secure
     * @response `200` `DtoOptimizeUGCPromptResponse` OK
     * @response `default` `CommonBusinessError`
     */
    optimizeUgcPrompt: (id: string, payload: DtoOptimizeUGCPromptRequest, params: RequestParams = {}) =>
      this.request<DtoOptimizeUGCPromptResponse, CommonBusinessError>({
        path: `/game-packs/${id}/optimize-ugc-prompt`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name PropagateUgcSettings
     * @request POST:/game-packs/{id}/propagate-ugc-settings
     * @secure
     * @response `201` `DtoPropagateUGCSettingsResponse` Created
     * @response `default` `CommonBusinessError`
     */
    propagateUgcSettings: (id: string, payload: DtoPropagateUGCSettingsRequest, params: RequestParams = {}) =>
      this.request<DtoPropagateUGCSettingsResponse, CommonBusinessError>({
        path: `/game-packs/${id}/propagate-ugc-settings`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name PublishGamePack
     * @request POST:/game-packs/{id}/publish
     * @secure
     * @response `201` `DtoSingleGamePackResponse` Created
     * @response `default` `CommonBusinessError`
     */
    publishGamePack: (id: string, payload: DtoPublishGamePackRequest, params: RequestParams = {}) =>
      this.request<DtoSingleGamePackResponse, CommonBusinessError>({
        path: `/game-packs/${id}/publish`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name RecommendTrainingCourses
     * @request POST:/game-packs/{id}/recommend-training-courses
     * @secure
     * @response `200` `DtoRecommendTrainingCoursesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    recommendTrainingCourses: (id: string, payload: DtoRecommendTrainingCoursesRequest, params: RequestParams = {}) =>
      this.request<DtoRecommendTrainingCoursesResponse, CommonBusinessError>({
        path: `/game-packs/${id}/recommend-training-courses`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name UpdateGamePackTags
     * @request PUT:/game-packs/{id}/tags
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateGamePackTags: (id: string, payload: DtoUpdateGamePackTagsRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/game-packs/${id}/tags`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name GetUgcFiles
     * @request GET:/game-packs/{id}/ugc-files
     * @secure
     * @response `201` `DtoGetUGCFilesResponse` Created
     * @response `default` `CommonBusinessError`
     */
    getUgcFiles: (id: string, payload: DtoGetUGCFilesRequest, params: RequestParams = {}) =>
      this.request<DtoGetUGCFilesResponse, CommonBusinessError>({
        path: `/game-packs/${id}/ugc-files`,
        method: 'GET',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name DeleteUgcFile
     * @request DELETE:/game-packs/{id}/ugc-files/{fileId}
     * @secure
     * @response `201` `DtoGetUGCFilesResponse` Created
     * @response `default` `CommonBusinessError`
     */
    deleteUgcFile: (id: string, fileId: string, payload: DtoGetUGCFilesRequest, params: RequestParams = {}) =>
      this.request<DtoGetUGCFilesResponse, CommonBusinessError>({
        path: `/game-packs/${id}/ugc-files/${fileId}`,
        method: 'DELETE',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name DownloadUgcFile
     * @request GET:/game-packs/{id}/ugc-files/{fileId}/download
     * @secure
     * @response `default` `CommonBusinessError`
     */
    downloadUgcFile: (id: string, fileId: string, payload: DtoDownloadUGCFileRequest, params: RequestParams = {}) =>
      this.request<any, CommonBusinessError>({
        path: `/game-packs/${id}/ugc-files/${fileId}/download`,
        method: 'GET',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name CreateDownloadUgcFileToken
     * @request POST:/game-packs/{id}/ugc-files/{fileId}/download-token
     * @secure
     * @response `200` `DtoCreateDownloadUGCFileTokenResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createDownloadUgcFileToken: (
      id: string,
      fileId: string,
      payload: DtoCreateDownloadUGCFileTokenRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoCreateDownloadUGCFileTokenResponse, CommonBusinessError>({
        path: `/game-packs/${id}/ugc-files/${fileId}/download-token`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name UploadUgcFile
     * @request POST:/game-packs/{id}/ugc-files/upload
     * @secure
     * @response `201` `DtoUploadUGCFileResponse` Created
     * @response `default` `CommonBusinessError`
     */
    uploadUgcFile: (id: string, payload: DtoUploadUGCFileRequest, params: RequestParams = {}) =>
      this.request<DtoUploadUGCFileResponse, CommonBusinessError>({
        path: `/game-packs/${id}/ugc-files/upload`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name UpdateCollection
     * @request PUT:/game-packs/collections/{type}
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateCollection: (type: string, payload: DtoUpdateGamePackCollectionRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/game-packs/collections/${type}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name ImportGamePack
     * @request POST:/game-packs/import
     * @response `201` `DtoSingleGamePackResponse` Created
     * @response `default` `CommonBusinessError`
     */
    importGamePack: (params: RequestParams = {}) =>
      this.request<DtoSingleGamePackResponse, CommonBusinessError>({
        path: `/game-packs/import`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name RecommendGamePacks
     * @request POST:/game-packs/recommend
     * @response `200` `DtoRecommendGamePacksResponse` OK
     * @response `default` `CommonBusinessError`
     */
    recommendGamePacks: (payload: DtoRecommendGamePacksRequest, params: RequestParams = {}) =>
      this.request<DtoRecommendGamePacksResponse, CommonBusinessError>({
        path: `/game-packs/recommend`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name RecommendCoverImages
     * @request POST:/game-packs/recommend-cover-images
     * @secure
     * @response `200` `DtoRecommendTrainingCourseCoverImagesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    recommendCoverImages: (payload: DtoRecommendTrainingCourseCoverImagesRequest, params: RequestParams = {}) =>
      this.request<DtoRecommendTrainingCourseCoverImagesResponse, CommonBusinessError>({
        path: `/game-packs/recommend-cover-images`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name SearchGamePacks
     * @request GET:/game-packs/search
     * @secure
     * @response `200` `DtoGamePackListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchGamePacks: (params: RequestParams = {}) =>
      this.request<DtoGamePackListResponse, CommonBusinessError>({
        path: `/game-packs/search`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name QueryGroupedGamePacksByTags
     * @request GET:/game-packs/tag-grouped
     * @secure
     * @response `200` `DtoQueryGroupedGamePacksByTagsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryGroupedGamePacksByTags: (query: QueryGroupedGamePacksByTagsParams, params: RequestParams = {}) =>
      this.request<DtoQueryGroupedGamePacksByTagsResponse, CommonBusinessError>({
        path: `/game-packs/tag-grouped`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GamePack
     * @name UpdateFeaturedGamePacksByTag
     * @request PUT:/game-packs/tags/{id}/featured
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateFeaturedGamePacksByTag: (
      id: string,
      payload: DtoUpdateTagFeaturedGamePacksRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, CommonBusinessError>({
        path: `/game-packs/tags/${id}/featured`,
        method: 'PUT',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),
  };
  tag = {
    /**
     * No description
     *
     * @tags Tag
     * @name ActivateOtpUpsell
     * @request POST:/game-packs/{id}/activate-upsell
     * @response `default` `CommonBusinessError`
     */
    activateOtpUpsell: (id: string, payload: DtoActivateOTPUpsellRequest, params: RequestParams = {}) =>
      this.request<any, CommonBusinessError>({
        path: `/game-packs/${id}/activate-upsell`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name GetOtpUpsell
     * @request POST:/game-packs/{id}/upsell
     * @response `200` `DtoOTPUpsellResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getOtpUpsell: (id: string, params: RequestParams = {}) =>
      this.request<DtoOTPUpsellResponse, CommonBusinessError>({
        path: `/game-packs/${id}/upsell`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name GetFeaturedGamePacksByTag
     * @request GET:/game-packs/tags/{id}/featured
     * @response `200` `DtoGamePackListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getFeaturedGamePacksByTag: (id: string, params: RequestParams = {}) =>
      this.request<DtoGamePackListResponse, CommonBusinessError>({
        path: `/game-packs/tags/${id}/featured`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name QueryTags
     * @request GET:/tags
     * @response `200` `DtoTagListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryTags: (params: RequestParams = {}) =>
      this.request<DtoTagListResponse, CommonBusinessError>({
        path: `/tags`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Build tag by id or slug
     *
     * @tags Tag
     * @name GetTag
     * @request GET:/tags/{id}
     * @response `200` `DtoSingleTagResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getTag: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleTagResponse, CommonBusinessError>({
        path: `/tags/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update tag
     *
     * @tags Tag
     * @name UpdateTag
     * @request PUT:/tags/{id}
     * @response `200` `DtoSingleTagResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateTag: (id: string, payload: DtoUpdateTagRequest, params: RequestParams = {}) =>
      this.request<DtoSingleTagResponse, CommonBusinessError>({
        path: `/tags/${id}`,
        method: 'PUT',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name UpdateFeaturedTags
     * @request PUT:/tags/featured
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateFeaturedTags: (payload: DtoUpdateFeaturedTagsRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/tags/featured`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name SearchTags
     * @request GET:/tags/search
     * @response `200` `DtoTagListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchTags: (query: SearchTagsParams, params: RequestParams = {}) =>
      this.request<DtoTagListResponse, CommonBusinessError>({
        path: `/tags/search`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  gameScore = {
    /**
     * No description
     *
     * @tags GameScore
     * @name GetOthersPlaying
     * @request GET:/game-scores/others-playing
     * @secure
     * @response `200` `DtoGetOthersPlayingResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getOthersPlaying: (query: GetOthersPlayingParams, params: RequestParams = {}) =>
      this.request<DtoGetOthersPlayingResponse, CommonBusinessError>({
        path: `/game-scores/others-playing`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GameScore
     * @name SearchGameScores
     * @request GET:/game-scores/search
     * @secure
     * @response `200` `DtoGameScoreListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchGameScores: (params: RequestParams = {}) =>
      this.request<DtoGameScoreListResponse, CommonBusinessError>({
        path: `/game-scores/search`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  pairing = {
    /**
     * No description
     *
     * @tags Pairing
     * @name CreateGlobalRound
     * @request POST:/global-pairing-rounds
     * @secure
     * @response `200` `DtoSingleGlobalPairingRoundResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createGlobalRound: (payload: DtoCreatePairingRoundRequest, params: RequestParams = {}) =>
      this.request<DtoSingleGlobalPairingRoundResponse, CommonBusinessError>({
        path: `/global-pairing-rounds`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name DeleteGlobalRound
     * @request DELETE:/global-pairing-rounds/{globalRoundId}
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteGlobalRound: (globalRoundId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/global-pairing-rounds/${globalRoundId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetGlobalRound
     * @request GET:/global-pairing-rounds/{globalRoundId}
     * @secure
     * @response `200` `DtoSingleGlobalPairingRoundResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getGlobalRound: (globalRoundId: string, params: RequestParams = {}) =>
      this.request<DtoSingleGlobalPairingRoundResponse, CommonBusinessError>({
        path: `/global-pairing-rounds/${globalRoundId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name UpdateGlobalRound
     * @request PUT:/global-pairing-rounds/{globalRoundId}
     * @secure
     * @response `200` `DtoSingleGlobalPairingRoundResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateGlobalRound: (
      globalRoundId: string,
      payload: DtoUpdateGlobalPairingRoundRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoSingleGlobalPairingRoundResponse, CommonBusinessError>({
        path: `/global-pairing-rounds/${globalRoundId}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetGlobalCompanyLeaderboardTeams
     * @request GET:/global-pairing-rounds/{globalRoundId}/company-leaderboard/teams
     * @secure
     * @response `200` `DtoLeaderboardTeamsResponse` OK
     */
    getGlobalCompanyLeaderboardTeams: (globalRoundId: string, params: RequestParams = {}) =>
      this.request<DtoLeaderboardTeamsResponse, any>({
        path: `/global-pairing-rounds/${globalRoundId}/company-leaderboard/teams`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetGlobalLeaderboardTeams
     * @request GET:/global-pairing-rounds/{globalRoundId}/leaderboard/teams
     * @secure
     * @response `200` `DtoLeaderboardTeamsResponse` OK
     */
    getGlobalLeaderboardTeams: (globalRoundId: string, params: RequestParams = {}) =>
      this.request<DtoLeaderboardTeamsResponse, any>({
        path: `/global-pairing-rounds/${globalRoundId}/leaderboard/teams`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetGlobalRoundParticipationStats
     * @request GET:/global-pairing-rounds/{globalRoundId}/participation-stats
     * @secure
     * @response `200` `DtoPairingParticipationStatsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getGlobalRoundParticipationStats: (globalRoundId: string, params: RequestParams = {}) =>
      this.request<DtoPairingParticipationStatsResponse, CommonBusinessError>({
        path: `/global-pairing-rounds/${globalRoundId}/participation-stats`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name RepairGlobalRound
     * @request POST:/global-pairing-rounds/{globalRoundId}/repair
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    repairGlobalRound: (globalRoundId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/global-pairing-rounds/${globalRoundId}/repair`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetGlobalRoundRepairLogs
     * @request GET:/global-pairing-rounds/{globalRoundId}/repair-logs
     * @secure
     * @response `200` `DtoPairingRepairLogsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getGlobalRoundRepairLogs: (globalRoundId: string, params: RequestParams = {}) =>
      this.request<DtoPairingRepairLogsResponse, CommonBusinessError>({
        path: `/global-pairing-rounds/${globalRoundId}/repair-logs`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name QueryGlobalRounds
     * @request GET:/global-pairings-rounds
     * @secure
     * @response `200` `DtoMultiGlobalPairingRoundResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryGlobalRounds: (params: RequestParams = {}) =>
      this.request<DtoMultiGlobalPairingRoundResponse, CommonBusinessError>({
        path: `/global-pairings-rounds`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name UpcomingGlobalRounds
     * @request GET:/global-pairings-rounds/upcoming
     * @secure
     * @response `200` `DtoMultiGlobalPairingRoundResponse` OK
     */
    upcomingGlobalRounds: (params: RequestParams = {}) =>
      this.request<DtoMultiGlobalPairingRoundResponse, any>({
        path: `/global-pairings-rounds/upcoming`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetMyPairings
     * @request GET:/my/pairings
     * @secure
     * @response `200` `DtoMultiPairingsResponse` OK
     */
    getMyPairings: (params: RequestParams = {}) =>
      this.request<DtoMultiPairingsResponse, any>({
        path: `/my/pairings`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetMyStatsSummary
     * @request GET:/my/stats/summary
     * @secure
     * @response `200` `DtoMyStatsSummaryResponse` OK
     */
    getMyStatsSummary: (params: RequestParams = {}) =>
      this.request<DtoMyStatsSummaryResponse, any>({
        path: `/my/stats/summary`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name CreatePairingRound
     * @request POST:/pairing-rounds
     * @secure
     * @response `200` `DtoSinglePairingRoundResponse` OK
     */
    createPairingRound: (payload: DtoCreatePairingRoundRequest, params: RequestParams = {}) =>
      this.request<DtoSinglePairingRoundResponse, any>({
        path: `/pairing-rounds`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name DeletePairingRound
     * @request DELETE:/pairing-rounds/{roundId}
     * @secure
     * @response `200` `void` OK
     */
    deletePairingRound: (roundId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/pairing-rounds/${roundId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetPairingRound
     * @request GET:/pairing-rounds/{roundId}
     * @secure
     * @response `200` `DtoSinglePairingRoundResponse` OK
     */
    getPairingRound: (roundId: string, params: RequestParams = {}) =>
      this.request<DtoSinglePairingRoundResponse, any>({
        path: `/pairing-rounds/${roundId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name UpdatePairingRound
     * @request PUT:/pairing-rounds/{roundId}
     * @secure
     * @response `200` `DtoSinglePairingRoundResponse` OK
     */
    updatePairingRound: (roundId: string, payload: DtoUpdatePairingRoundRequest, params: RequestParams = {}) =>
      this.request<DtoSinglePairingRoundResponse, any>({
        path: `/pairing-rounds/${roundId}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetLeaderboard
     * @request GET:/pairing-rounds/{roundId}/leaderboard
     * @secure
     * @response `200` `DtoLeaderboardSummaryResponse` OK
     */
    getLeaderboard: (roundId: string, params: RequestParams = {}) =>
      this.request<DtoLeaderboardSummaryResponse, any>({
        path: `/pairing-rounds/${roundId}/leaderboard`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetLeaderboardTeams
     * @request GET:/pairing-rounds/{roundId}/leaderboard/teams
     * @secure
     * @response `200` `DtoLeaderboardTeamsResponse` OK
     */
    getLeaderboardTeams: (roundId: string, params: RequestParams = {}) =>
      this.request<DtoLeaderboardTeamsResponse, any>({
        path: `/pairing-rounds/${roundId}/leaderboard/teams`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name QueryPairings
     * @request GET:/pairing-rounds/{roundId}/pairings
     * @secure
     * @response `200` `DtoMultiPairingsResponse` OK
     */
    queryPairings: (roundId: string, params: RequestParams = {}) =>
      this.request<DtoMultiPairingsResponse, any>({
        path: `/pairing-rounds/${roundId}/pairings`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name CreatePairing
     * @request POST:/pairing-rounds/{roundId}/pairings
     * @secure
     * @response `200` `DtoSinglePairingResponse` OK
     */
    createPairing: (roundId: string, payload: DtoCreatePairingRequest, params: RequestParams = {}) =>
      this.request<DtoSinglePairingResponse, any>({
        path: `/pairing-rounds/${roundId}/pairings`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name DeletePairing
     * @request DELETE:/pairing-rounds/{roundId}/pairings/{pairingId}
     * @secure
     * @response `200` `void` OK
     */
    deletePairing: (roundId: string, pairingId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/pairing-rounds/${roundId}/pairings/${pairingId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name AddPairingPlayer
     * @request POST:/pairing-rounds/{roundId}/pairings/{pairingId}
     * @secure
     * @response `200` `DtoSinglePairingResponse` OK
     */
    addPairingPlayer: (
      roundId: string,
      pairingId: string,
      payload: DtoAddPairingPlayerRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoSinglePairingResponse, any>({
        path: `/pairing-rounds/${roundId}/pairings/${pairingId}`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name UpdatePairing
     * @request PUT:/pairing-rounds/{roundId}/pairings/{pairingId}
     * @secure
     * @response `200` `DtoSinglePairingResponse` OK
     */
    updatePairing: (roundId: string, pairingId: string, payload: DtoUpdatePairingRequest, params: RequestParams = {}) =>
      this.request<DtoSinglePairingResponse, any>({
        path: `/pairing-rounds/${roundId}/pairings/${pairingId}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name RemovePairingPlayer
     * @request DELETE:/pairing-rounds/{roundId}/pairings/{pairingId}/players/{uid}
     * @secure
     * @response `200` `void` OK
     */
    removePairingPlayer: (roundId: string, pairingId: string, uid: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/pairing-rounds/${roundId}/pairings/${pairingId}/players/${uid}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetPairingRoundParticipationDetail
     * @request GET:/pairing-rounds/{roundId}/participation-stats
     * @secure
     * @response `200` `DtoPairingParticipationDetailResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getPairingRoundParticipationDetail: (roundId: string, params: RequestParams = {}) =>
      this.request<DtoPairingParticipationDetailResponse, CommonBusinessError>({
        path: `/pairing-rounds/${roundId}/participation-stats`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name QueryPairingRounds
     * @request GET:/pairings-rounds
     * @secure
     * @response `200` `DtoMultiPairingRoundsResponse` OK
     */
    queryPairingRounds: (query: QueryPairingRoundsParams, params: RequestParams = {}) =>
      this.request<DtoMultiPairingRoundsResponse, any>({
        path: `/pairings-rounds`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pairing
     * @name GetPairingGame
     * @request GET:/pairings/{pairingId}/game
     * @secure
     * @response `200` `DtoGetPairingGameResponse` OK
     */
    getPairingGame: (pairingId: string, params: RequestParams = {}) =>
      this.request<DtoGetPairingGameResponse, any>({
        path: `/pairings/${pairingId}/game`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  promotion = {
    /**
     * No description
     *
     * @tags Promotion
     * @name QueryGlobalPromotions
     * @request GET:/global-promotions
     * @secure
     * @response `200` `DtoGlobalPromotionListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryGlobalPromotions: (params: RequestParams = {}) =>
      this.request<DtoGlobalPromotionListResponse, CommonBusinessError>({
        path: `/global-promotions`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name CreateGlobalPromotion
     * @request POST:/global-promotions
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    createGlobalPromotion: (payload: DtoCreateGlobalPromotionRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/global-promotions`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name DeleteGlobalPromotion
     * @request DELETE:/global-promotions/{globalPromotionId}
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteGlobalPromotion: (globalPromotionId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/global-promotions/${globalPromotionId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name FindGlobalPromotionById
     * @request GET:/global-promotions/{globalPromotionId}
     * @secure
     * @response `200` `DtoSingleGlobalPromotionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    findGlobalPromotionById: (globalPromotionId: string, params: RequestParams = {}) =>
      this.request<DtoSingleGlobalPromotionResponse, CommonBusinessError>({
        path: `/global-promotions/${globalPromotionId}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name UpdateGlobalPromotion
     * @request PUT:/global-promotions/{globalPromotionId}
     * @secure
     * @response `200` `DtoSingleGlobalPromotionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateGlobalPromotion: (
      globalPromotionId: string,
      payload: DtoCreateGlobalPromotionRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoSingleGlobalPromotionResponse, CommonBusinessError>({
        path: `/global-promotions/${globalPromotionId}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name QueryPromotions
     * @request GET:/global-promotions/{globalPromotionId}/promotions
     * @secure
     * @response `200` `DtoPromotionListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryPromotions: (globalPromotionId: string, params: RequestParams = {}) =>
      this.request<DtoPromotionListResponse, CommonBusinessError>({
        path: `/global-promotions/${globalPromotionId}/promotions`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name PausePromotion
     * @request POST:/promotions/{promotionId}/pause
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    pausePromotion: (promotionId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/promotions/${promotionId}/pause`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name ResumePromotion
     * @request POST:/promotions/{promotionId}/resume
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    resumePromotion: (promotionId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/promotions/${promotionId}/resume`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name SendAnalyticsDigest
     * @request POST:/promotions/analytics-digest
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    sendAnalyticsDigest: (payload: DtoSendAnalyticsDigestRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/promotions/analytics-digest`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name SendGameUpdates
     * @request POST:/promotions/game-updates
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    sendGameUpdates: (payload: DtoSendGameUpdatesRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/promotions/game-updates`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name QueryPromotionHistories
     * @request GET:/promotions/histories
     * @secure
     * @response `200` `DtoPromotionHistoryListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryPromotionHistories: (params: RequestParams = {}) =>
      this.request<DtoPromotionHistoryListResponse, CommonBusinessError>({
        path: `/promotions/histories`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name DeletePromotionHistory
     * @request DELETE:/promotions/histories/:id
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deletePromotionHistory: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/promotions/histories/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Promotion
     * @name SendProgram
     * @request POST:/promotions/program
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    sendProgram: (payload: DtoSendProgramPromotionRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/promotions/program`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  hubSpot = {
    /**
     * No description
     *
     * @tags HubSpot
     * @name GetDeal
     * @request GET:/hubspot/deals/{id}
     * @secure
     * @response `200` `DtoGetHubSpotDealResponse` OK
     */
    getDeal: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetHubSpotDealResponse, any>({
        path: `/hubspot/deals/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HubSpot
     * @name UpdateDealCustomPrice
     * @request PUT:/hubspot/deals/{id}/custom-price
     * @secure
     * @response `200` `DtoUpdateHubSpotDealWithCustomPriceResponse` OK
     */
    updateDealCustomPrice: (
      id: string,
      payload: DtoUpdateHubSpotDealWithCustomPriceRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoUpdateHubSpotDealWithCustomPriceResponse, any>({
        path: `/hubspot/deals/${id}/custom-price`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HubSpot
     * @name SearchDeals
     * @request POST:/hubspot/deals/search
     * @secure
     * @response `200` `DtoSearchDealResponse` OK
     */
    searchDeals: (payload: DtoSearchDealRequest, params: RequestParams = {}) =>
      this.request<DtoSearchDealResponse, any>({
        path: `/hubspot/deals/search`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HubSpot
     * @name UpdateHubSpotProduct
     * @request GET:/hubspot/products/{id}
     * @secure
     * @response `200` `DtoGetHubSpotProductResponse` OK
     */
    updateHubSpotProduct: (id: string, payload: DtoChangeHubSpotProductRequest, params: RequestParams = {}) =>
      this.request<DtoGetHubSpotProductResponse, any>({
        path: `/hubspot/products/${id}`,
        method: 'GET',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HubSpot
     * @name SearchHubSpotProducts
     * @request POST:/hubspot/products/search
     * @secure
     * @response `200` `DtoSearchHubSpotProductResponse` OK
     */
    searchHubSpotProducts: (payload: DtoSearchHubSpotProductRequest, params: RequestParams = {}) =>
      this.request<DtoSearchHubSpotProductResponse, any>({
        path: `/hubspot/products/search`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  deal = {
    /**
     * No description
     *
     * @tags Deal
     * @name CreateHubSpotProduct
     * @request POST:/hubspot/products
     * @secure
     * @response `200` `DtoGetHubSpotProductResponse` OK
     */
    createHubSpotProduct: (payload: DtoChangeHubSpotProductRequest, params: RequestParams = {}) =>
      this.request<DtoGetHubSpotProductResponse, any>({
        path: `/hubspot/products`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  inquiry = {
    /**
     * No description
     *
     * @tags Inquiry
     * @name CreateInquiry
     * @request POST:/inquiries
     * @secure
     * @response `201` `DtoSingleInquiryResponse` Created
     */
    createInquiry: (payload: DtoCreateInquiryRequest, params: RequestParams = {}) =>
      this.request<DtoSingleInquiryResponse, any>({
        path: `/inquiries`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inquiry
     * @name GetInquiry
     * @request GET:/inquiries/:id
     * @secure
     * @response `200` `DtoSingleInquiryResponse` OK
     */
    getInquiry: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleInquiryResponse, any>({
        path: `/inquiries/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inquiry
     * @name GetInquiryResponses
     * @request GET:/inquiries/:id/responses
     * @secure
     * @response `200` `DtoMultiSummarizedInterviewResponse` OK
     */
    getInquiryResponses: (id: string, params: RequestParams = {}) =>
      this.request<DtoMultiSummarizedInterviewResponse, any>({
        path: `/inquiries/${id}/responses`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inquiry
     * @name GenerateLineOfInquiry
     * @request POST:/inquiries/generate
     * @secure
     * @response `201` `DtoLineOfInquiryResponse` Created
     */
    generateLineOfInquiry: (payload: DtoGenerateLineOfInquiryRequest, params: RequestParams = {}) =>
      this.request<DtoLineOfInquiryResponse, any>({
        path: `/inquiries/generate`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inquiry
     * @name SummarizeInquiry
     * @request POST:/inquiry/:id/summarize
     * @secure
     * @response `200` `DtoSingleInquiryResponse` OK
     */
    summarizeInquiry: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleInquiryResponse, any>({
        path: `/inquiry/${id}/summarize`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  interview = {
    /**
     * No description
     *
     * @tags Interview
     * @name CreateInterview
     * @request POST:/interviews
     * @secure
     * @response `201` `DtoSingleInterviewResponse` Created
     */
    createInterview: (payload: DtoCreateInterviewRequest, params: RequestParams = {}) =>
      this.request<DtoSingleInterviewResponse, any>({
        path: `/interviews`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interview
     * @name GetInterview
     * @request GET:/interviews/:id
     * @secure
     * @response `200` `DtoSingleInterviewResponse` OK
     */
    getInterview: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleInterviewResponse, any>({
        path: `/interviews/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interview
     * @name UpdateInterview
     * @request PUT:/interviews/:id
     * @secure
     * @response `200` `DtoSingleInterviewResponse` OK
     */
    updateInterview: (id: string, payload: DtoUpdateInterviewRequest, params: RequestParams = {}) =>
      this.request<DtoSingleInterviewResponse, any>({
        path: `/interviews/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interview
     * @name RenderWelcome
     * @request POST:/interviews/:id/render-welcome
     * @secure
     * @response `200` `void` OK
     */
    renderWelcome: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/interviews/${id}/render-welcome`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interview
     * @name CreateQuestionReply
     * @request POST:/interviews/:id/reply
     * @secure
     * @response `200` `DtoSingleInterviewResponse` OK
     */
    createQuestionReply: (id: string, payload: DtoCreateQuestionReplyRequest, params: RequestParams = {}) =>
      this.request<DtoSingleInterviewResponse, any>({
        path: `/interviews/${id}/reply`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interview
     * @name ResummarizeInterview
     * @request POST:/interviews/:id/resummarize
     * @secure
     * @response `200` `DtoSingleInterviewResponse` OK
     */
    resummarizeInterview: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleInterviewResponse, any>({
        path: `/interviews/${id}/resummarize`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interview
     * @name StartInterview
     * @request POST:/interviews/:id/start
     * @secure
     * @response `200` `DtoSingleInterviewResponse` OK
     */
    startInterview: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleInterviewResponse, any>({
        path: `/interviews/${id}/start`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interview
     * @name SubmitInterview
     * @request POST:/interviews/:id/submit
     * @secure
     * @response `200` `DtoSingleInterviewResponse` OK
     */
    submitInterview: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleInterviewResponse, any>({
        path: `/interviews/${id}/submit`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interview
     * @name SummarizeInterview
     * @request POST:/interviews/:id/summarize
     * @secure
     * @response `200` `DtoSingleInterviewResponse` OK
     */
    summarizeInterview: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleInterviewResponse, any>({
        path: `/interviews/${id}/summarize`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  joyCapture = {
    /**
     * No description
     *
     * @tags JoyCapture
     * @name QueryJoyCaptures
     * @request GET:/joy-captures
     * @response `200` `DtoMultiJoyCaptureResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryJoyCaptures: (params: RequestParams = {}) =>
      this.request<DtoMultiJoyCaptureResponse, CommonBusinessError>({
        path: `/joy-captures`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  learning = {
    /**
     * @description Assign a course to a list of users identified by their emails.
     *
     * @tags Learning
     * @name AssignCourse
     * @request POST:/learning/assignments
     * @response `200` `DtoAssignCourseResponse` OK
     * @response `400` `CommonBusinessError` Bad Request
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    assignCourse: (payload: DtoAssignCourseRequest, params: RequestParams = {}) =>
      this.request<DtoAssignCourseResponse, CommonBusinessError>({
        path: `/learning/assignments`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Build all courses (game packs) assigned to the authenticated user. This includes courses assigned directly and those assigned via stacks.
     *
     * @tags Learning
     * @name GetUserAssignedCourses
     * @request GET:/learning/courses
     * @response `200` `(DtoSingleGamePackResponse)[]` An array of bundled game pack responses, similar to GetGamePackById but for multiple courses.
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getUserAssignedCourses: (params: RequestParams = {}) =>
      this.request<DtoSingleGamePackResponse[], CommonBusinessError>({
        path: `/learning/courses`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a course
     *
     * @tags Learning
     * @name DeleteCourse
     * @request DELETE:/learning/courses/{id}
     * @response `200` `void` OK
     * @response `400` `CommonBusinessError` Invalid course ID
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Course not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    deleteCourse: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/courses/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Build all learning groups that have been assigned a specific course.
     *
     * @tags Learning
     * @name GetCourseAssignedGroups
     * @request GET:/learning/courses/{id}/groups
     * @response `200` `DtoGetCourseAssignedGroupsResponse` OK
     * @response `400` `CommonBusinessError` Invalid Course ID
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Course not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getCourseAssignedGroups: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetCourseAssignedGroupsResponse, CommonBusinessError>({
        path: `/learning/courses/${id}/groups`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Unassigns the specified group from the given course. The requesting user must be in the same org and must be the course creator or admin/owner.
     *
     * @tags Learning
     * @name UnassignGroupFromCourse
     * @summary Unassign a group from a stack
     * @request DELETE:/learning/courses/{id}/groups/{groupId}
     * @response `200` `void` Successfully unassigned the group from the stack
     * @response `400` `CommonBusinessError` Invalid course or group ID
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Group enrollment not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    unassignGroupFromCourse: (id: string, groupId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/courses/${id}/groups/${groupId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Build all members assigned to a specific course, including their assignment details and completion status
     *
     * @tags Learning
     * @name GetCourseAssignedMembers
     * @request GET:/learning/courses/{id}/members
     * @response `200` `DtoGetCourseAssignedMembersResponse` OK
     * @response `400` `CommonBusinessError` Invalid course ID
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Course not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getCourseAssignedMembers: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetCourseAssignedMembersResponse, CommonBusinessError>({
        path: `/learning/courses/${id}/members`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Unassign a member from a course
     *
     * @tags Learning
     * @name UnassignMemberFromCourse
     * @request DELETE:/learning/courses/{id}/members/{userId}
     * @response `200` `void` OK
     * @response `400` `CommonBusinessError` Invalid input
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Course or assignment not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    unassignMemberFromCourse: ({ id, userId, ...query }: UnassignMemberFromCourseParams, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/courses/${id}/members/${userId}`,
        method: 'DELETE',
        query: query,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Build all stacks that include a specific course
     *
     * @tags Learning
     * @name GetCourseStacks
     * @request GET:/learning/courses/{id}/stacks
     * @response `200` `DtoGetCourseStacksResponse` OK
     */
    getCourseStacks: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetCourseStacksResponse, any>({
        path: `/learning/courses/${id}/stacks`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all created courses (gamepacks version=1) for the requesting user, with learner counts
     *
     * @tags Learning
     * @name GetCreatedCourses
     * @request GET:/learning/courses/created
     * @secure
     * @response `200` `DtoGetCreatedCoursesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getCreatedCourses: (params: RequestParams = {}) =>
      this.request<DtoGetCreatedCoursesResponse, CommonBusinessError>({
        path: `/learning/courses/created`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Assign a course to multiple learning groups at once
     *
     * @tags Learning
     * @name AssignCourseToGroups
     * @request POST:/learning/courses/groups
     * @response `200` `DtoEnrollGroupsResponse` OK
     */
    assignCourseToGroups: (payload: DtoEnrollGroupsRequest, params: RequestParams = {}) =>
      this.request<DtoEnrollGroupsResponse, any>({
        path: `/learning/courses/groups`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Search courses created by the requesting user, limited to 5 most recent if no query
     *
     * @tags Learning
     * @name SearchUserCreatedCourses
     * @request GET:/learning/courses/search
     * @response `200` `DtoSearchUserCreatedCoursesResponse` OK
     * @response `400` `CommonBusinessError` Bad Request
     * @response `401` `CommonBusinessError` Unauthorized
     */
    searchUserCreatedCourses: (query: SearchUserCreatedCoursesParams, params: RequestParams = {}) =>
      this.request<DtoSearchUserCreatedCoursesResponse, CommonBusinessError>({
        path: `/learning/courses/search`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Build all groups created by the authenticated user
     *
     * @tags Learning
     * @name GetUserCreatedGroups
     * @request GET:/learning/groups
     * @response `200` `(DtoLearningGroupWithCounts)[]` OK
     * @response `401` `CommonBusinessError` Unauthorized or user not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getUserCreatedGroups: (params: RequestParams = {}) =>
      this.request<DtoLearningGroupWithCounts[], CommonBusinessError>({
        path: `/learning/groups`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new group
     *
     * @tags Learning
     * @name CreateGroup
     * @request POST:/learning/groups
     * @response `200` `DtoCreateGroupResponse` OK
     * @response `400` `CommonBusinessError` Invalid request or group not found
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Group not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    createGroup: (payload: DtoCreateGroupRequest, params: RequestParams = {}) =>
      this.request<DtoCreateGroupResponse, CommonBusinessError>({
        path: `/learning/groups`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a group if the requesting user is the creator or has admin/owner privileges
     *
     * @tags Learning
     * @name DeleteGroup
     * @request DELETE:/learning/groups/{id}
     * @response `200` `void` OK
     * @response `400` `CommonBusinessError` group id is required
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Group not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    deleteGroup: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/groups/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Returns the group's details including members and assigned courses/stacks
     *
     * @tags Learning
     * @name GroupsDetail
     * @summary Build detailed information about a learning group
     * @request GET:/learning/groups/{id}
     * @response `200` `DtoGroupDetailResponse` OK
     */
    groupsDetail: (id: string, params: RequestParams = {}) =>
      this.request<DtoGroupDetailResponse, any>({
        path: `/learning/groups/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Remove an assignment from a group
     *
     * @tags Learning
     * @name RemoveEnrollmentFromGroup
     * @request DELETE:/learning/groups/{id}/enrollments
     * @response `200` `void` OK
     * @response `400` `CommonBusinessError` Invalid input
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Group or assignment not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    removeEnrollmentFromGroup: ({ id, ...query }: RemoveEnrollmentFromGroupParams, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/groups/${id}/enrollments`,
        method: 'DELETE',
        query: query,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add multiple enrollments to a group
     *
     * @tags Learning
     * @name AddEnrollmentsToGroup
     * @request POST:/learning/groups/{id}/enrollments
     * @response `200` `void` OK
     * @response `400` `CommonBusinessError` Invalid request or group not found
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Group not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    addEnrollmentsToGroup: (id: string, payload: DtoAddEnrollmentsToGroupRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/groups/${id}/enrollments`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add multiple members to a learning group
     *
     * @tags Learning
     * @name AddMembersToLearningGroup
     * @request POST:/learning/groups/{id}/members
     * @response `200` `void` OK
     * @response `400` `CommonBusinessError` Invalid request or group not found
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Group not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    addMembersToLearningGroup: (id: string, payload: DtoAddMembersToLearningGroupRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/groups/${id}/members`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Learning
     * @name GroupsMembersDelete
     * @summary Remove a member from a group
     * @request DELETE:/learning/groups/{id}/members/{userId}
     * @response `200` `void` OK
     */
    groupsMembersDelete: (id: string, userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/learning/groups/${id}/members/${userId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update group name
     *
     * @tags Learning
     * @name UpdateGroupName
     * @request PUT:/learning/groups/{id}/name
     * @response `200` `void` OK
     * @response `400` `CommonBusinessError` Invalid request or group not found
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Group not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    updateGroupName: (id: string, payload: DtoUpdateGroupNameRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/groups/${id}/name`,
        method: 'PUT',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Assigns multiple users to a list of learning groups
     *
     * @tags Learning
     * @name JoinGroups
     * @request POST:/learning/groups/join
     * @response `200` `void` OK
     */
    joinGroups: (payload: DtoJoinGroupsRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/learning/groups/join`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Search learning groups by partial name
     *
     * @tags Learning
     * @name SearchGroupsByName
     * @request GET:/learning/groups/search
     * @response `200` `DtoLearningGroupSearchResponse` OK
     * @response `400` `CommonBusinessError` Bad Request
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    searchGroupsByName: (query: SearchGroupsByNameParams, params: RequestParams = {}) =>
      this.request<DtoLearningGroupSearchResponse, CommonBusinessError>({
        path: `/learning/groups/search`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Build all learners in the organization associated with the logged-in user.
     *
     * @tags Learning
     * @name GetLearners
     * @request GET:/learning/learners
     * @response `200` `DtoGetLearnersResponse` OK
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getLearners: (params: RequestParams = {}) =>
      this.request<DtoGetLearnersResponse, CommonBusinessError>({
        path: `/learning/learners`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Build a user's enrollments
     *
     * @tags Learning
     * @name GetLearnerEnrollments
     * @request GET:/learning/learners/:uid/enrollments
     * @response `200` `DtoLearningEnrollmentsResponse` OK
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getLearnerEnrollments: ({ uid, ...query }: GetLearnerEnrollmentsParams, params: RequestParams = {}) =>
      this.request<DtoLearningEnrollmentsResponse, CommonBusinessError>({
        path: `/learning/learners/${uid}/enrollments`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Build a user's learning group memberships
     *
     * @tags Learning
     * @name GetLearnerMemberships
     * @request GET:/learning/learners/:uid/memberships
     * @response `200` `DtoLearningGroupMembershipsResponse` OK
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getLearnerMemberships: ({ uid, ...query }: GetLearnerMembershipsParams, params: RequestParams = {}) =>
      this.request<DtoLearningGroupMembershipsResponse, CommonBusinessError>({
        path: `/learning/learners/${uid}/memberships`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Build a user's learning profile.
     *
     * @tags Learning
     * @name GetLearnerProfile
     * @request GET:/learning/learners/:uid/profile
     * @response `200` `DtoLearnerProfileResponse` OK
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getLearnerProfile: ({ uid, ...query }: GetLearnerProfileParams, params: RequestParams = {}) =>
      this.request<DtoLearnerProfileResponse, CommonBusinessError>({
        path: `/learning/learners/${uid}/profile`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new course stack with the specified courses
     *
     * @tags Learning
     * @name CreateStack
     * @request POST:/learning/stacks
     * @response `200` `ModelsStack` OK
     * @response `400` `CommonBusinessError` Bad Request
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    createStack: (payload: DtoCreateStackRequest, params: RequestParams = {}) =>
      this.request<ModelsStack, CommonBusinessError>({
        path: `/learning/stacks`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a group if the requesting user is the creator or has admin/owner privileges
     *
     * @tags Learning
     * @name DeleteStack
     * @request DELETE:/learning/stacks/{id}
     * @response `200` `void` OK
     * @response `400` `CommonBusinessError` group id is required
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Stack not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    deleteStack: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/stacks/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Returns the stack's name, ordered courses, assigned groups (with enrollment info), and assigned members (whether individually or via group).
     *
     * @tags Learning
     * @name GetStackDetail
     * @summary Retrieve detailed information about a specific stack
     * @request GET:/learning/stacks/{id}
     * @response `200` `DtoStackDetailResponse` OK
     * @response `400` `CommonBusinessError` Invalid stack ID
     * @response `401` `CommonBusinessError` Unauthorized or not an organizer
     * @response `404` `CommonBusinessError` Stack not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getStackDetail: (id: string, params: RequestParams = {}) =>
      this.request<DtoStackDetailResponse, CommonBusinessError>({
        path: `/learning/stacks/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Add multiple courses to an existing stack. New courses will be added at the end of the sequence.
     *
     * @tags Learning
     * @name AddCoursesToStack
     * @request POST:/learning/stacks/{id}/courses
     * @response `200` `void` OK
     * @response `400` `CommonBusinessError` Invalid request or courses already in stack
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Stack not found
     */
    addCoursesToStack: (id: string, payload: DtoAddCoursesToStackRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/stacks/${id}/courses`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes a single course from the specified stack, if the requesting user is the stack creator or has admin/owner privileges. The remaining courses are re-sequenced automatically.
     *
     * @tags Learning
     * @name RemoveCourseFromStack
     * @summary Remove a specific course from a stack
     * @request DELETE:/learning/stacks/{id}/courses/{courseId}
     * @response `200` `void` Successfully removed the course from the stack
     * @response `400` `CommonBusinessError` Invalid stack ID or course ID
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Stack not found or course not found in stack
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    removeCourseFromStack: (id: string, courseId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/stacks/${id}/courses/${courseId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates the sequence order of each course in the specified stack based on the provided input array. Only stack creator or admin/owner can reorder.
     *
     * @tags Learning
     * @name ReorderStackCourses
     * @summary Reorder the courses in a stack
     * @request PUT:/learning/stacks/{id}/courses/order
     * @response `200` `void` Successfully reordered the courses
     * @response `400` `CommonBusinessError` Invalid stack ID or request body
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Stack not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    reorderStackCourses: (id: string, payload: DtoReorderStackCoursesRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/stacks/${id}/courses/order`,
        method: 'PUT',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Build all learning groups that have been assigned a specific stack.
     *
     * @tags Learning
     * @name GetStackAssignedGroups
     * @request GET:/learning/stacks/{id}/groups
     * @response `200` `(ModelsLearningGroup)[]` OK
     * @response `400` `CommonBusinessError` Invalid Stack ID
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Stack not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getStackAssignedGroups: (id: string, params: RequestParams = {}) =>
      this.request<ModelsLearningGroup[], CommonBusinessError>({
        path: `/learning/stacks/${id}/groups`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Unassigns the specified group from the given stack, removing the group enrollment row and decrementing each member's groupRefsCount. The requesting user must be in the same org and must be the stack creator or admin/owner.
     *
     * @tags Learning
     * @name UnassignGroupFromStack
     * @summary Unassign a group from a stack
     * @request DELETE:/learning/stacks/{id}/groups/{groupId}
     * @response `200` `void` Successfully unassigned the group from the stack
     * @response `400` `CommonBusinessError` Invalid stack or group ID
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Group enrollment not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    unassignGroupFromStack: (id: string, groupId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/stacks/${id}/groups/${groupId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes the user's enrollment record for the specified stack, if the requestor is the stack creator or has admin/owner privileges.
     *
     * @tags Learning
     * @name UnassignMemberFromStack
     * @summary Unassign a user from a stack
     * @request DELETE:/learning/stacks/{id}/members/{userId}
     * @response `200` `void` Successfully unassigned the member from the stack
     * @response `400` `CommonBusinessError` Invalid stack ID or user ID
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` User not assigned to stack or stack not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    unassignMemberFromStack: (id: string, userId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/stacks/${id}/members/${userId}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update the name of a stack
     *
     * @tags Learning
     * @name UpdateStackName
     * @request PUT:/learning/stacks/{id}/name
     * @response `200` `void` OK
     * @response `400` `CommonBusinessError` Invalid request or stack not found
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `404` `CommonBusinessError` Stack not found
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    updateStackName: (id: string, payload: DtoUpdateStackNameRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/learning/stacks/${id}/name`,
        method: 'PUT',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Build all stacks created by the authenticated user
     *
     * @tags Learning
     * @name GetUserCreatedStacks
     * @request GET:/learning/stacks/created
     * @response `200` `DtoGetCreatedStacksResponse` OK
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getUserCreatedStacks: (params: RequestParams = {}) =>
      this.request<DtoGetCreatedStacksResponse, CommonBusinessError>({
        path: `/learning/stacks/created`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Assign a stack to multiple learning groups at once
     *
     * @tags Learning
     * @name AssignStackToGroups
     * @request POST:/learning/stacks/groups
     * @response `200` `DtoEnrollGroupsResponse` OK
     */
    assignStackToGroups: (payload: DtoEnrollGroupsRequest, params: RequestParams = {}) =>
      this.request<DtoEnrollGroupsResponse, any>({
        path: `/learning/stacks/groups`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Search stacks created by the requesting user, limited to 5 most recent if no query
     *
     * @tags Learning
     * @name SearchUserCreatedStacks
     * @request GET:/learning/stacks/search
     * @response `200` `DtoSearchUserCreatedStacksResponse` OK
     * @response `400` `CommonBusinessError` Bad Request
     * @response `401` `CommonBusinessError` Unauthorized
     */
    searchUserCreatedStacks: (query: SearchUserCreatedStacksParams, params: RequestParams = {}) =>
      this.request<DtoSearchUserCreatedStacksResponse, CommonBusinessError>({
        path: `/learning/stacks/search`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Build a user's learning profile.
     *
     * @tags Learning
     * @name GetMyLearnerProfile
     * @request GET:/my/learner-profile
     * @response `200` `DtoLearnerProfileResponse` OK
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `500` `CommonBusinessError` Internal Server Error
     */
    getMyLearnerProfile: (query: GetMyLearnerProfileParams, params: RequestParams = {}) =>
      this.request<DtoLearnerProfileResponse, CommonBusinessError>({
        path: `/my/learner-profile`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  llmDocument = {
    /**
     * No description
     *
     * @tags LLMDocument
     * @name FetchConfig
     * @request GET:/llm/document/fetch-config
     * @response `200` `DtoFetchDocumentConfigResponse` OK
     * @response `default` `CommonBusinessError`
     */
    fetchConfig: (params: RequestParams = {}) =>
      this.request<DtoFetchDocumentConfigResponse, CommonBusinessError>({
        path: `/llm/document/fetch-config`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LLMDocument
     * @name LoadDoc
     * @request POST:/llm/document/load
     * @response `200` `DtoLoadDocumentResponse` OK
     * @response `default` `CommonBusinessError`
     */
    loadDoc: (params: RequestParams = {}) =>
      this.request<DtoLoadDocumentResponse, CommonBusinessError>({
        path: `/llm/document/load`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LLMDocument
     * @name SearchDocs
     * @request POST:/llm/document/search
     * @response `200` `DtoSearchDocumentResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchDocs: (query: SearchDocsParams, params: RequestParams = {}) =>
      this.request<DtoSearchDocumentResponse, CommonBusinessError>({
        path: `/llm/document/search`,
        method: 'POST',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  media = {
    /**
     * No description
     *
     * @tags Media
     * @name GetMediaById
     * @request GET:/media/{id}
     * @response `200` `DtoSingleMediaResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getMediaById: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleMediaResponse, CommonBusinessError>({
        path: `/media/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name MediaDummy
     * @request GET:/media/dummy
     * @secure
     * @response `200` `EnumsExternalMediaAspectRatio` OK
     * @response `default` `CommonBusinessError`
     */
    mediaDummy: (params: RequestParams = {}) =>
      this.request<EnumsExternalMediaAspectRatio, CommonBusinessError>({
        path: `/media/dummy`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Search for media across different providers (giphy, unsplash, serp)
     *
     * @tags Media
     * @name SearchExternalMedia
     * @request GET:/media/external/search
     * @response `200` `DtoExternalMediaSearchResponse` OK
     * @response `400` `CommonBusinessError` Invalid request parameters
     * @response `401` `CommonBusinessError` Unauthorized
     * @response `503` `CommonBusinessError` External provider error
     */
    searchExternalMedia: (query: SearchExternalMediaParams, params: RequestParams = {}) =>
      this.request<DtoExternalMediaSearchResponse, CommonBusinessError>({
        path: `/media/external/search`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name QuerySharedAsset
     * @request GET:/media/shared
     * @response `200` `DtoSharedAssetListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    querySharedAsset: (query: QuerySharedAssetParams, params: RequestParams = {}) =>
      this.request<DtoSharedAssetListResponse, CommonBusinessError>({
        path: `/media/shared`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name CreateSharedAsset
     * @request POST:/media/shared
     * @response `200` `DtoSharedAssetResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createSharedAsset: (payload: DtoCreateSharedAssetRequest, params: RequestParams = {}) =>
      this.request<DtoSharedAssetResponse, CommonBusinessError>({
        path: `/media/shared`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name DeleteSharedAsset
     * @request DELETE:/media/shared/:id
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteSharedAsset: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/media/shared/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name GetSharedAsset
     * @request GET:/media/shared/:id
     * @response `200` `DtoSharedAssetResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getSharedAsset: (id: string, params: RequestParams = {}) =>
      this.request<DtoSharedAssetResponse, CommonBusinessError>({
        path: `/media/shared/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name UpdateSharedAsset
     * @request PUT:/media/shared/:id
     * @response `200` `DtoSharedAssetResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateSharedAsset: (id: string, payload: DtoUpdateSharedAssetRequest, params: RequestParams = {}) =>
      this.request<DtoSharedAssetResponse, CommonBusinessError>({
        path: `/media/shared/${id}`,
        method: 'PUT',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name GetSharedAssetObjectLinks
     * @request GET:/media/shared/:id/links
     * @response `200` `DtoSharedAssetObjectLinkListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getSharedAssetObjectLinks: (id: string, params: RequestParams = {}) =>
      this.request<DtoSharedAssetObjectLinkListResponse, CommonBusinessError>({
        path: `/media/shared/${id}/links`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name RetrySharedAssetFanOutJob
     * @request POST:/media/shared/:id/retry-fan-out-job
     * @response `200` `DtoSharedAssetResponse` OK
     * @response `default` `CommonBusinessError`
     */
    retrySharedAssetFanOutJob: (id: string, params: RequestParams = {}) =>
      this.request<DtoSharedAssetResponse, CommonBusinessError>({
        path: `/media/shared/${id}/retry-fan-out-job`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name SearchSharedAsset
     * @request GET:/media/shared/search
     * @response `200` `DtoSharedAssetListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchSharedAsset: (query: SearchSharedAssetParams, params: RequestParams = {}) =>
      this.request<DtoSharedAssetListResponse, CommonBusinessError>({
        path: `/media/shared/search`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Media
     * @name UploadMedia
     * @request POST:/media/upload
     * @response `200` `DtoSingleMediaResponse` OK
     * @response `default` `CommonBusinessError`
     */
    uploadMedia: (params: RequestParams = {}) =>
      this.request<DtoSingleMediaResponse, CommonBusinessError>({
        path: `/media/upload`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  };
  messageCampaign = {
    /**
     * No description
     *
     * @tags MessageCampaign
     * @name QueryMessageCampaigns
     * @request GET:/message-campaigns
     * @secure
     * @response `200` `DtoMessageCampaignListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryMessageCampaigns: (params: RequestParams = {}) =>
      this.request<DtoMessageCampaignListResponse, CommonBusinessError>({
        path: `/message-campaigns`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageCampaign
     * @name UpdateMessageCampaign
     * @request PUT:/message-campaigns
     * @secure
     * @response `200` `DtoSingleMessageCampaignResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateMessageCampaign: (params: RequestParams = {}) =>
      this.request<DtoSingleMessageCampaignResponse, CommonBusinessError>({
        path: `/message-campaigns`,
        method: 'PUT',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageCampaign
     * @name DeleteMessageCampaign
     * @request DELETE:/message-campaigns/:id
     * @secure
     * @response `200` `DtoSingleMessageCampaignResponse` OK
     * @response `default` `CommonBusinessError`
     */
    deleteMessageCampaign: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleMessageCampaignResponse, CommonBusinessError>({
        path: `/message-campaigns/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageCampaign
     * @name GetMessageCampaign
     * @request GET:/message-campaigns/:id
     * @secure
     * @response `200` `DtoSingleMessageCampaignResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getMessageCampaign: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleMessageCampaignResponse, CommonBusinessError>({
        path: `/message-campaigns/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageCampaign
     * @name DuplicateMessageCampaign
     * @request POST:/message-campaigns/:id/duplicate
     * @secure
     * @response `200` `DtoSingleMessageCampaignResponse` OK
     * @response `default` `CommonBusinessError`
     */
    duplicateMessageCampaign: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleMessageCampaignResponse, CommonBusinessError>({
        path: `/message-campaigns/${id}/duplicate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  messageLogic = {
    /**
     * No description
     *
     * @tags MessageLogic
     * @name QueryMessageLogics
     * @request GET:/message-logics
     * @secure
     * @response `200` `DtoQueryMessageLogicsRequest` OK
     * @response `default` `CommonBusinessError`
     */
    queryMessageLogics: (params: RequestParams = {}) =>
      this.request<DtoQueryMessageLogicsRequest, CommonBusinessError>({
        path: `/message-logics`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageLogic
     * @name UpdateMessageLogic
     * @request PUT:/message-logics/{id}
     * @secure
     * @response `200` `DtoSingleMessageLogicResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateMessageLogic: (id: string, payload: DtoUpdateMessageLogicRequest, params: RequestParams = {}) =>
      this.request<DtoSingleMessageLogicResponse, CommonBusinessError>({
        path: `/message-logics/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  messageTemplate = {
    /**
     * No description
     *
     * @tags MessageTemplate
     * @name QueryMessageTemplates
     * @request GET:/message-templates
     * @secure
     * @response `200` `DtoSingleMessageTemplateResponse` OK
     */
    queryMessageTemplates: (params: RequestParams = {}) =>
      this.request<DtoSingleMessageTemplateResponse, any>({
        path: `/message-templates`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageTemplate
     * @name CreateMessageTemplate
     * @request POST:/message-templates
     * @secure
     * @response `200` `DtoSingleMessageTemplateResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createMessageTemplate: (payload: DtoCreateMessageTemplateRequest, params: RequestParams = {}) =>
      this.request<DtoSingleMessageTemplateResponse, CommonBusinessError>({
        path: `/message-templates`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageTemplate
     * @name DeleteMessageTemplate
     * @request DELETE:/message-templates/{id}
     * @secure
     * @response `200` `void` OK
     */
    deleteMessageTemplate: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/message-templates/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageTemplate
     * @name GetMessageTemplate
     * @request GET:/message-templates/{id}
     * @secure
     * @response `200` `DtoSingleMessageTemplateResponse` OK
     */
    getMessageTemplate: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleMessageTemplateResponse, any>({
        path: `/message-templates/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MessageTemplate
     * @name UpdateMessageTemplate
     * @request PUT:/message-templates/{id}
     * @secure
     * @response `200` `DtoSingleMessageTemplateResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateMessageTemplate: (id: string, payload: DtoUpdateMessageTemplateRequest, params: RequestParams = {}) =>
      this.request<DtoSingleMessageTemplateResponse, CommonBusinessError>({
        path: `/message-templates/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  message = {
    /**
     * No description
     *
     * @tags Message
     * @name QueryMessages
     * @request GET:/messages
     * @secure
     * @response `200` `DtoMultiMessagesResp` OK
     * @response `default` `CommonBusinessError`
     */
    queryMessages: (params: RequestParams = {}) =>
      this.request<DtoMultiMessagesResp, CommonBusinessError>({
        path: `/messages`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name ShareMessage
     * @request POST:/messages
     * @secure
     * @response `200` `DtoShareMessageRequest` OK
     * @response `default` `CommonBusinessError`
     */
    shareMessage: (params: RequestParams = {}) =>
      this.request<DtoShareMessageRequest, CommonBusinessError>({
        path: `/messages`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name RevokeMessage
     * @request POST:/messages/{id}/revoke
     * @secure
     * @response `200` `DtoMessage` OK
     * @response `default` `CommonBusinessError`
     */
    revokeMessage: (id: string, params: RequestParams = {}) =>
      this.request<DtoMessage, CommonBusinessError>({
        path: `/messages/${id}/revoke`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name SendMessage
     * @request POST:/messages/{id}/send
     * @secure
     * @response `200` `DtoMessage` OK
     * @response `default` `CommonBusinessError`
     */
    sendMessage: (id: string, params: RequestParams = {}) =>
      this.request<DtoMessage, CommonBusinessError>({
        path: `/messages/${id}/send`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name QueryMessageCampaignsV2
     * @request GET:/messages/campaigns
     * @secure
     * @response `200` `DtoMessageCampaignV2ListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryMessageCampaignsV2: (params: RequestParams = {}) =>
      this.request<DtoMessageCampaignV2ListResponse, CommonBusinessError>({
        path: `/messages/campaigns`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name ValidateCondition
     * @request GET:/messages/conditions/validate
     * @secure
     * @response `200` `DtoValidateMessageConditionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    validateCondition: (payload: DtoValidateMessageConditionRequest, params: RequestParams = {}) =>
      this.request<DtoValidateMessageConditionResponse, CommonBusinessError>({
        path: `/messages/conditions/validate`,
        method: 'GET',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name QueryLogics
     * @request GET:/messages/logics
     * @secure
     * @response `200` `DtoMessageLogicsV2Response` OK
     * @response `default` `CommonBusinessError`
     */
    queryLogics: (query: QueryLogicsParams, params: RequestParams = {}) =>
      this.request<DtoMessageLogicsV2Response, CommonBusinessError>({
        path: `/messages/logics`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name CreateLogic
     * @request POST:/messages/logics
     * @secure
     * @response `200` `DtoMessageLogicV2Response` OK
     * @response `default` `CommonBusinessError`
     */
    createLogic: (payload: DtoCreateMessageLogicV2Request, params: RequestParams = {}) =>
      this.request<DtoMessageLogicV2Response, CommonBusinessError>({
        path: `/messages/logics`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name DeleteLogic
     * @request DELETE:/messages/logics/:id
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteLogic: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/messages/logics/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name UpdateLogic
     * @request PUT:/messages/logics/:id
     * @secure
     * @response `200` `DtoMessageLogicV2Response` OK
     * @response `default` `CommonBusinessError`
     */
    updateLogic: (id: string, payload: DtoUpdateMessageLogicV2Request, params: RequestParams = {}) =>
      this.request<DtoMessageLogicV2Response, CommonBusinessError>({
        path: `/messages/logics/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name ActivateLogic
     * @request POST:/messages/logics/:id/activate
     * @secure
     * @response `200` `DtoMessageLogicV2Response` OK
     * @response `default` `CommonBusinessError`
     */
    activateLogic: (id: string, params: RequestParams = {}) =>
      this.request<DtoMessageLogicV2Response, CommonBusinessError>({
        path: `/messages/logics/${id}/activate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name DeactivateLogic
     * @request POST:/messages/logics/:id/deactivate
     * @secure
     * @response `200` `DtoMessageLogicV2Response` OK
     * @response `default` `CommonBusinessError`
     */
    deactivateLogic: (id: string, params: RequestParams = {}) =>
      this.request<DtoMessageLogicV2Response, CommonBusinessError>({
        path: `/messages/logics/${id}/deactivate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name DuplicateLogic
     * @request POST:/messages/logics/:id/duplicate
     * @secure
     * @response `200` `DtoMessageLogicV2Response` OK
     * @response `default` `CommonBusinessError`
     */
    duplicateLogic: (id: string, params: RequestParams = {}) =>
      this.request<DtoMessageLogicV2Response, CommonBusinessError>({
        path: `/messages/logics/${id}/duplicate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name QueryTemplateSettings
     * @request GET:/messages/template-settings
     * @secure
     * @response `200` `DtoMessageTemplateSettingsListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryTemplateSettings: (params: RequestParams = {}) =>
      this.request<DtoMessageTemplateSettingsListResponse, CommonBusinessError>({
        path: `/messages/template-settings`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name CreateTemplateSettings
     * @request POST:/messages/template-settings
     * @secure
     * @response `200` `DtoMessageTemplateSettingsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createTemplateSettings: (payload: DtoCreateMessageTemplateSettingsRequest, params: RequestParams = {}) =>
      this.request<DtoMessageTemplateSettingsResponse, CommonBusinessError>({
        path: `/messages/template-settings`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name DeleteTemplateSettings
     * @request DELETE:/messages/template-settings/{id}
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteTemplateSettings: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/messages/template-settings/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetTemplateSettings
     * @request GET:/messages/template-settings/{id}
     * @secure
     * @response `200` `DtoMessageTemplateSettingsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getTemplateSettings: (id: string, params: RequestParams = {}) =>
      this.request<DtoMessageTemplateSettingsResponse, CommonBusinessError>({
        path: `/messages/template-settings/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name UpdateTemplateSettings
     * @request PUT:/messages/template-settings/{id}
     * @secure
     * @response `200` `DtoMessageTemplateSettingsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateTemplateSettings: (
      id: string,
      payload: DtoUpdateMessageTemplateSettingsRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoMessageTemplateSettingsResponse, CommonBusinessError>({
        path: `/messages/template-settings/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name SendTemplate
     * @request POST:/messages/template/send
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    sendTemplate: (payload: DtoSendMessageTemplateRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/messages/template/send`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name RenderSlackBlocks
     * @request GET:/messages/templates/render-slack-blocks
     * @secure
     * @response `200` `DtoRenderSlackBlocksResponse` OK
     * @response `default` `CommonBusinessError`
     */
    renderSlackBlocks: (query: RenderSlackBlocksParams, params: RequestParams = {}) =>
      this.request<DtoRenderSlackBlocksResponse, CommonBusinessError>({
        path: `/messages/templates/render-slack-blocks`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name SendMarkdownEmail
     * @request POST:/messages/templates/send-markdown-email
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    sendMarkdownEmail: (payload: DtoSendMarkdownEmailRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/messages/templates/send-markdown-email`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name SendMarkdownSlackMessage
     * @request POST:/messages/templates/send-markdown-slack-message
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    sendMarkdownSlackMessage: (payload: DtoSendMarkdownSlackMessageRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/messages/templates/send-markdown-slack-message`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  progression = {
    /**
     * No description
     *
     * @tags Progression
     * @name CreateMyProgression
     * @request POST:/my/progressions/:packId
     * @secure
     * @response `201` `DtoSingleProgressionResponse` Created
     * @response `default` `CommonBusinessError`
     */
    createMyProgression: (packId: string, params: RequestParams = {}) =>
      this.request<DtoSingleProgressionResponse, CommonBusinessError>({
        path: `/my/progressions/${packId}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Progression
     * @name ResetMyProgression
     * @request POST:/my/progressions/:packId/reset
     * @secure
     * @response `201` `DtoSingleProgressionResponse` Created
     * @response `default` `CommonBusinessError`
     */
    resetMyProgression: (packId: string, params: RequestParams = {}) =>
      this.request<DtoSingleProgressionResponse, CommonBusinessError>({
        path: `/my/progressions/${packId}/reset`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Progression
     * @name ResetMyMinigameProgression
     * @request POST:/my/progressions/:packId/reset/:minigameId
     * @secure
     * @response `201` `DtoSingleProgressionResponse` Created
     * @response `default` `CommonBusinessError`
     */
    resetMyMinigameProgression: (packId: string, minigameId: string, params: RequestParams = {}) =>
      this.request<DtoSingleProgressionResponse, CommonBusinessError>({
        path: `/my/progressions/${packId}/reset/${minigameId}`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Progression
     * @name TrackProgressionBlockPlayed
     * @request POST:/my/progressions/:packId/track-block-played
     * @secure
     * @response `201` `DtoSingleProgressionResponse` Created
     * @response `default` `CommonBusinessError`
     */
    trackProgressionBlockPlayed: (
      packId: string,
      payload: DtoTrackProgressionBlockPlayedRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoSingleProgressionResponse, CommonBusinessError>({
        path: `/my/progressions/${packId}/track-block-played`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  userSettings = {
    /**
     * No description
     *
     * @tags UserSettings
     * @name GetAllMySettings
     * @request GET:/my/settings
     * @response `200` `DtoUserSettingsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getAllMySettings: (params: RequestParams = {}) =>
      this.request<DtoUserSettingsResponse, CommonBusinessError>({
        path: `/my/settings`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserSettings
     * @name GetMySettings
     * @request GET:/my/settings/:groupKey
     * @response `200` `DtoUserSettingsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getMySettings: (groupKey: string, params: RequestParams = {}) =>
      this.request<DtoUserSettingsResponse, CommonBusinessError>({
        path: `/my/settings/${groupKey}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update user settings for a given settings group.
     *
     * @tags UserSettings
     * @name PatchMySettings
     * @request POST:/my/settings/:groupKey
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    patchMySettings: (groupKey: string, payload: DtoPatchSettingsRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/my/settings/${groupKey}`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserSettings
     * @name UserSettingsDummy
     * @request GET:/my/settings/dummy
     * @secure
     * @response `200` `EnumsUserVesProfileKey` OK
     * @response `default` `CommonBusinessError`
     */
    userSettingsDummy: (params: RequestParams = {}) =>
      this.request<EnumsUserVesProfileKey, CommonBusinessError>({
        path: `/my/settings/dummy`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  venue = {
    /**
     * No description
     *
     * @tags Venue
     * @name GetMyVenue
     * @request GET:/my/venue
     * @secure
     * @response `200` `DtoSingleVenueResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getMyVenue: (params: RequestParams = {}) =>
      this.request<DtoSingleVenueResponse, CommonBusinessError>({
        path: `/my/venue`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name UpdateMyVenue
     * @request PUT:/my/venue
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateMyVenue: (payload: DtoUpdateVenueRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/my/venue`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name CreateCloudPlayer
     * @request POST:/my/venue/cloud-players
     * @secure
     * @response `200` `DtoCreateCloudPlayerResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createCloudPlayer: (payload: DtoCreateCloudPlayerRequest, params: RequestParams = {}) =>
      this.request<DtoCreateCloudPlayerResponse, CommonBusinessError>({
        path: `/my/venue/cloud-players`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name DeleteCloudPlayer
     * @request DELETE:/my/venue/cloud-players/{id}
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteCloudPlayer: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/my/venue/cloud-players/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name CreateVenue
     * @request POST:/venues
     * @secure
     * @response `200` `DtoSingleVenueResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createVenue: (params: RequestParams = {}) =>
      this.request<DtoSingleVenueResponse, CommonBusinessError>({
        path: `/venues`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name OverrideCoordinatorPolicy
     * @request POST:/venues/:id/override-coordinator-policy
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    overrideCoordinatorPolicy: (id: string, payload: DtoOverrideCoordinatorPolicyRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/venues/${id}/override-coordinator-policy`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name GetVenue
     * @request GET:/venues/{id}
     * @secure
     * @response `200` `DtoSingleVenueResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getVenue: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleVenueResponse, CommonBusinessError>({
        path: `/venues/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name ApplyCloudHosting
     * @request POST:/venues/{id}/apply-cloud-hosting
     * @secure
     * @response `200` `DtoApplyCloudHostingResponse` OK
     * @response `default` `CommonBusinessError`
     */
    applyCloudHosting: (id: string, params: RequestParams = {}) =>
      this.request<DtoApplyCloudHostingResponse, CommonBusinessError>({
        path: `/venues/${id}/apply-cloud-hosting`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name CleanupVenue
     * @request POST:/venues/{id}/cleanup
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    cleanupVenue: (id: string, payload: DtoCleanupVenueRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/venues/${id}/cleanup`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name GetCloudInstanceBinding
     * @request GET:/venues/{id}/cloud-instance-binding
     * @secure
     * @response `200` `DtoGetCloudInstanceBindingResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getCloudInstanceBinding: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetCloudInstanceBindingResponse, CommonBusinessError>({
        path: `/venues/${id}/cloud-instance-binding`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name DeriveSettings
     * @request POST:/venues/{id}/derive-settings
     * @secure
     * @response `200` `DtoDerivedVenueSettings` OK
     * @response `default` `CommonBusinessError`
     */
    deriveSettings: (id: string, payload: DtoDeriveVenueSettingsRequest, params: RequestParams = {}) =>
      this.request<DtoDerivedVenueSettings, CommonBusinessError>({
        path: `/venues/${id}/derive-settings`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name GetVenueOwner
     * @request GET:/venues/{id}/owner
     * @secure
     * @response `200` `DtoSingleVenueResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getVenueOwner: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleVenueResponse, CommonBusinessError>({
        path: `/venues/${id}/owner`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name ReleaseCloudHosting
     * @request POST:/venues/{id}/release-cloud-hosting
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    releaseCloudHosting: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/venues/${id}/release-cloud-hosting`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name GetSessionSnapshot
     * @request GET:/venues/{id}/sessions/{sid}/snapshot
     * @secure
     * @response `200` `DtoGetSessionSnapshotResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getSessionSnapshot: (id: string, sid: string, params: RequestParams = {}) =>
      this.request<DtoGetSessionSnapshotResponse, CommonBusinessError>({
        path: `/venues/${id}/sessions/${sid}/snapshot`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Venue
     * @name SyncCloudInstanceBinding
     * @request POST:/venues/{id}/sync-cloud-hosting
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    syncCloudInstanceBinding: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/venues/${id}/sync-cloud-hosting`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  notification = {
    /**
     * No description
     *
     * @tags Notification
     * @name GetNotificationSettings
     * @request GET:/notifications/settings
     * @secure
     * @response `200` `DtoGetNotificationSettingsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getNotificationSettings: (query: GetNotificationSettingsParams, params: RequestParams = {}) =>
      this.request<DtoGetNotificationSettingsResponse, CommonBusinessError>({
        path: `/notifications/settings`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name UpdateNotificationSettings
     * @request POST:/notifications/settings
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateNotificationSettings: (payload: DtoUpdateNotificationSettingsRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/notifications/settings`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  onboard = {
    /**
     * No description
     *
     * @tags Onboard
     * @name GetTask
     * @request GET:/onboard/task/{id}
     * @secure
     * @response `200` `DtoOnboardTaskResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getTask: (id: string, params: RequestParams = {}) =>
      this.request<DtoOnboardTaskResponse, CommonBusinessError>({
        path: `/onboard/task/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  onboarding = {
    /**
     * No description
     *
     * @tags Onboarding
     * @name QueryTaskLogs
     * @request GET:/onboarding/task-logs
     * @secure
     * @response `200` `DtoOnboardingTaskLogsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryTaskLogs: (query: QueryTaskLogsParams, params: RequestParams = {}) =>
      this.request<DtoOnboardingTaskLogsResponse, CommonBusinessError>({
        path: `/onboarding/task-logs`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Onboarding
     * @name ListOnboardingTasks
     * @request GET:/onboarding/tasks
     * @secure
     * @response `200` `DtoListOnboardingTasksResponse` OK
     * @response `default` `CommonBusinessError`
     */
    listOnboardingTasks: (query: ListOnboardingTasksParams, params: RequestParams = {}) =>
      this.request<DtoListOnboardingTasksResponse, CommonBusinessError>({
        path: `/onboarding/tasks`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Onboarding
     * @name OperateOnboardingTask
     * @request POST:/onboarding/tasks/:name/operate
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    operateOnboardingTask: (name: string, payload: DtoOperateTaskRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/onboarding/tasks/${name}/operate`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  purchase = {
    /**
     * No description
     *
     * @tags Purchase
     * @name GetReceipt
     * @request GET:/one-time-purchase/:id/receipt
     * @response `200` `DtoOneTimePurchaseReceiptResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getReceipt: (id: string, params: RequestParams = {}) =>
      this.request<DtoOneTimePurchaseReceiptResponse, CommonBusinessError>({
        path: `/one-time-purchase/${id}/receipt`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Purchase
     * @name ListPurchaseActivities
     * @request GET:/one-time-purchase/activities
     * @response `200` `DtoOneTimePurchaseActivityListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    listPurchaseActivities: (params: RequestParams = {}) =>
      this.request<DtoOneTimePurchaseActivityListResponse, CommonBusinessError>({
        path: `/one-time-purchase/activities`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Purchase
     * @name QueryPurchaseLogs
     * @request GET:/one-time-purchase/logs
     * @response `200` `DtoOneTimePurchaseLogListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryPurchaseLogs: (params: RequestParams = {}) =>
      this.request<DtoOneTimePurchaseLogListResponse, CommonBusinessError>({
        path: `/one-time-purchase/logs`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Purchase
     * @name VerifyCheckoutSession
     * @request GET:/stripe/checkout-sessions/:id/verify
     * @secure
     * @response `200` `DtoVerifyCheckoutSessionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    verifyCheckoutSession: (id: string, params: RequestParams = {}) =>
      this.request<DtoVerifyCheckoutSessionResponse, CommonBusinessError>({
        path: `/stripe/checkout-sessions/${id}/verify`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Purchase
     * @name QueryInvoice
     * @request GET:/stripe/invoices
     * @secure
     * @response `200` `DtoInvoiceListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryInvoice: (params: RequestParams = {}) =>
      this.request<DtoInvoiceListResponse, CommonBusinessError>({
        path: `/stripe/invoices`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Purchase
     * @name QueryPromotionCode
     * @request GET:/stripe/promotion-codes
     * @secure
     * @response `200` `DtoPromotionCodeListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryPromotionCode: (query: QueryPromotionCodeParams, params: RequestParams = {}) =>
      this.request<DtoPromotionCodeListResponse, CommonBusinessError>({
        path: `/stripe/promotion-codes`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  openAi = {
    /**
     * @description CreateChatCompletion
     *
     * @tags OpenAI
     * @name CreateChatCompletion
     * @request POST:/openai/chat/completions
     * @secure
     * @response `200` `OpenaiChatCompletionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createChatCompletion: (payload: OpenaiChatCompletionRequest, params: RequestParams = {}) =>
      this.request<OpenaiChatCompletionResponse, CommonBusinessError>({
        path: `/openai/chat/completions`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  organization = {
    /**
     * No description
     *
     * @tags Organization
     * @name GetOrgExConnection
     * @request GET:/organization/{id}/connection
     * @secure
     * @response `200` `DtoOrgConnectionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getOrgExConnection: (id: string, params: RequestParams = {}) =>
      this.request<DtoOrgConnectionResponse, CommonBusinessError>({
        path: `/organization/${id}/connection`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateExConnectionAsSourceOfTruth
     * @request PUT:/organization/{id}/connection/as-source-of-truth
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateExConnectionAsSourceOfTruth: (
      id: string,
      payload: DtoUpdateOrgConnectionAsSourceOfTruthRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/connection/as-source-of-truth`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name CheckExConnectionAsSourceOfTruth
     * @request GET:/organization/{id}/connection/as-source-of-truth/check
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    checkExConnectionAsSourceOfTruth: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/connection/as-source-of-truth/check`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name CreateStripeSubscription
     * @request POST:/organization/{id}/create-stripe-subscription
     * @secure
     * @response `200` `DtoCreateSubscriptionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createStripeSubscription: (id: string, payload: DtoCreateStripeSubscriptionRequest, params: RequestParams = {}) =>
      this.request<DtoCreateSubscriptionResponse, CommonBusinessError>({
        path: `/organization/${id}/create-stripe-subscription`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationDisconnect
     * @request POST:/organization/{id}/disconnect
     * @secure
     * @response `200` `DtoOrgConnectionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    organizationDisconnect: (id: string, params: RequestParams = {}) =>
      this.request<DtoOrgConnectionResponse, CommonBusinessError>({
        path: `/organization/${id}/disconnect`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name QueryJoyfulMoments
     * @request GET:/organization/{id}/joyful-moments
     * @secure
     * @response `200` `DtoMultiJoyCaptureResponse` OK
     */
    queryJoyfulMoments: (id: string, params: RequestParams = {}) =>
      this.request<DtoMultiJoyCaptureResponse, any>({
        path: `/organization/${id}/joyful-moments`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name ReinviteOrganizer
     * @request POST:/organization/{id}/organizers/{uid}/reinvite
     * @secure
     * @response `200` `DtoSingleOrganizerResponse` OK
     */
    reinviteOrganizer: (id: string, uid: string, payload: DtoResendInviteEmail, params: RequestParams = {}) =>
      this.request<DtoSingleOrganizerResponse, any>({
        path: `/organization/${id}/organizers/${uid}/reinvite`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name InviteOrganizers
     * @request POST:/organization/{id}/organizers/invite
     * @secure
     * @response `200` `DtoOrganizerListResponse` OK
     */
    inviteOrganizers: (id: string, payload: DtoInviteOrganizerRequest, params: RequestParams = {}) =>
      this.request<DtoOrganizerListResponse, any>({
        path: `/organization/${id}/organizers/invite`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name CheckOrganizersInvite
     * @request POST:/organization/{id}/organizers/invite/check
     * @secure
     * @response `200` `DtoCheckInvitationResponse` OK
     */
    checkOrganizersInvite: (id: string, payload: DtoCheckOrganizersInviteRequest, params: RequestParams = {}) =>
      this.request<DtoCheckInvitationResponse, any>({
        path: `/organization/${id}/organizers/invite/check`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name GetSubscriptionDetails
     * @request GET:/organization/{id}/subscription
     * @secure
     * @response `200` `DtoOrgSubscription` OK
     * @response `default` `CommonBusinessError`
     */
    getSubscriptionDetails: (id: string, params: RequestParams = {}) =>
      this.request<DtoOrgSubscription, CommonBusinessError>({
        path: `/organization/${id}/subscription`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name AcceptSubscriptionCancelOffer
     * @request POST:/organization/{id}/subscription/accept-cancel-offer
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    acceptSubscriptionCancelOffer: (
      id: string,
      payload: DtoAcceptOrgSubscriptionCancelOfferRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/subscription/accept-cancel-offer`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name CancelSubscriptionPlan
     * @request PUT:/organization/{id}/subscription/cancel
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    cancelSubscriptionPlan: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/subscription/cancel`,
        method: 'PUT',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name CreateCheckoutSession
     * @request POST:/organization/{id}/subscription/create-checkout-session
     * @secure
     * @response `200` `DtoCreateCheckoutSessionResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createCheckoutSession: (id: string, payload: DtoCreateCheckoutSessionRequest, params: RequestParams = {}) =>
      this.request<DtoCreateCheckoutSessionResponse, CommonBusinessError>({
        path: `/organization/${id}/subscription/create-checkout-session`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name CreatePortalSession
     * @request POST:/organization/{id}/subscription/create-portal-session
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    createPortalSession: (id: string, payload: DtoCreatePortalSessionRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/subscription/create-portal-session`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateSubscriptionPlan
     * @request PUT:/organization/{id}/subscription/plan
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateSubscriptionPlan: (id: string, payload: DtoUpdateOrgSubscriptionPlanRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/subscription/plan`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateSubscriptionStatus
     * @request PUT:/organization/{id}/subscription/status
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateSubscriptionStatus: (
      id: string,
      payload: DtoUpdateOrgSubscriptionStatusRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/subscription/status`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateStripeSubscription
     * @request PUT:/organization/{id}/subscription/stripe-subscription
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateStripeSubscription: (id: string, payload: DtoUpdateStripeSubscriptionRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/subscription/stripe-subscription`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name SubmitSubscriptionCancelReason
     * @request POST:/organization/{id}/subscription/submit-cancel-reason
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    submitSubscriptionCancelReason: (
      id: string,
      payload: DtoSubmitOrgSubscriptionCancelReasonRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/subscription/submit-cancel-reason`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpgradeDowngradeSubscriptionPlan
     * @request PUT:/organization/{id}/subscription/upgrade-downgrade
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    upgradeDowngradeSubscriptionPlan: (
      id: string,
      payload: DtoUpgradeDowngradeOrgSubscriptionPlanRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/subscription/upgrade-downgrade`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpsertSubscriptionIntent
     * @request POST:/organization/{id}/subscription/upsert-intent
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    upsertSubscriptionIntent: (id: string, payload: DtoUpsertSubscriptionIntentRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/organization/${id}/subscription/upsert-intent`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name GenerateEngagementScoreReport
     * @request GET:/organization/generate-engagement-score-report
     * @secure
     * @response `200` `void` OK
     */
    generateEngagementScoreReport: (payload: DtoGenerateEngagementScoreReportRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organization/generate-engagement-score-report`,
        method: 'GET',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name GetPresentationSummary
     * @request GET:/organization/presentation-summary
     * @secure
     * @response `200` `DtoGetOrgPresentationSummaryResponse` OK
     */
    getPresentationSummary: (query: GetPresentationSummaryParams, params: RequestParams = {}) =>
      this.request<DtoGetOrgPresentationSummaryResponse, any>({
        path: `/organization/presentation-summary`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OnSeatCapErrorShown
     * @request POST:/organization/seat-cap-error-shown
     * @secure
     * @response `200` `void` OK
     */
    onSeatCapErrorShown: (payload: DtoOnSeatCapErrorShownRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organization/seat-cap-error-shown`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name QueryOrganizations
     * @request GET:/organizations
     * @secure
     * @response `200` `DtoMultiOrganizationsResponse` OK
     */
    queryOrganizations: (params: RequestParams = {}) =>
      this.request<DtoMultiOrganizationsResponse, any>({
        path: `/organizations`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name CreateOrganization
     * @request POST:/organizations
     * @secure
     * @response `200` `DtoSingleOrganizationResponse` OK
     */
    createOrganization: (payload: DtoCreateOrganizationRequest, params: RequestParams = {}) =>
      this.request<DtoSingleOrganizationResponse, any>({
        path: `/organizations`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name DeleteOrganization
     * @request DELETE:/organizations/{id}
     * @secure
     * @response `200` `void` OK
     */
    deleteOrganization: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organizations/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name GetOrganization
     * @request GET:/organizations/{id}
     * @secure
     * @response `200` `DtoSingleOrganizationResponse` OK
     */
    getOrganization: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleOrganizationResponse, any>({
        path: `/organizations/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateOrganization
     * @request PUT:/organizations/{id}
     * @secure
     * @response `200` `DtoSingleOrganizationResponse` OK
     */
    updateOrganization: (id: string, payload: DtoUpdateOrganizationRequest, params: RequestParams = {}) =>
      this.request<DtoSingleOrganizationResponse, any>({
        path: `/organizations/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateOrganizationCanTrial
     * @request PUT:/organizations/{id}/can-trial
     * @secure
     * @response `200` `void` OK
     */
    updateOrganizationCanTrial: (
      id: string,
      payload: DtoUpdateOrganizationCanTrialRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organizations/${id}/can-trial`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name QueryOrganizationExperiences
     * @request GET:/organizations/{id}/experiences
     * @secure
     * @response `200` `DtoExperienceListResponse` OK
     */
    queryOrganizationExperiences: ({ id, ...query }: QueryOrganizationExperiencesParams, params: RequestParams = {}) =>
      this.request<DtoExperienceListResponse, any>({
        path: `/organizations/${id}/experiences`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateOrganizationLogo
     * @request PUT:/organizations/{id}/logo
     * @secure
     * @response `200` `DtoSingleOrganizationResponse` OK
     */
    updateOrganizationLogo: (id: string, payload: DtoUpdateOrganizationLogoRequest, params: RequestParams = {}) =>
      this.request<DtoSingleOrganizationResponse, any>({
        path: `/organizations/${id}/logo`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name ImportOrganizationLogo
     * @request POST:/organizations/{id}/logo/import
     * @secure
     * @response `200` `DtoSingleOrganizationResponse` OK
     */
    importOrganizationLogo: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleOrganizationResponse, any>({
        path: `/organizations/${id}/logo/import`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name QueryOrganizers
     * @request GET:/organizations/{id}/organizers
     * @secure
     * @response `200` `DtoOrganizerListResponse` OK
     */
    queryOrganizers: (id: string, params: RequestParams = {}) =>
      this.request<DtoOrganizerListResponse, any>({
        path: `/organizations/${id}/organizers`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name DeleteOrganizer
     * @request DELETE:/organizations/{id}/organizers/{uid}
     * @secure
     * @response `200` `void` OK
     */
    deleteOrganizer: (id: string, uid: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organizations/${id}/organizers/${uid}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name GetOrganizer
     * @request GET:/organizations/{id}/organizers/{uid}
     * @secure
     * @response `200` `DtoSingleOrganizerResponse` OK
     */
    getOrganizer: (id: string, uid: string, params: RequestParams = {}) =>
      this.request<DtoSingleOrganizerResponse, any>({
        path: `/organizations/${id}/organizers/${uid}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateOrganizer
     * @request PUT:/organizations/{id}/organizers/{uid}
     * @secure
     * @response `200` `DtoSingleOrganizerResponse` OK
     */
    updateOrganizer: (id: string, uid: string, payload: DtoUpdateOrganizerRequest, params: RequestParams = {}) =>
      this.request<DtoSingleOrganizerResponse, any>({
        path: `/organizations/${id}/organizers/${uid}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateOrganizerRole
     * @request PUT:/organizations/{id}/organizers/{uid}/role
     * @secure
     * @response `200` `void` OK
     */
    updateOrganizerRole: (id: string, uid: string, payload: DtoUpdateOrganizerRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organizations/${id}/organizers/${uid}/role`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name DeleteOrganizers
     * @request POST:/organizations/{id}/organizers/batch-delete
     * @secure
     * @response `200` `void` OK
     */
    deleteOrganizers: (id: string, payload: DtoDeleteOrganizersRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/organizations/${id}/organizers/batch-delete`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name QueryOrganizersByEmails
     * @request GET:/organizations/{id}/organizers/by-emails
     * @secure
     * @response `200` `DtoOrganizerListResponse` OK
     */
    queryOrganizersByEmails: ({ id, ...query }: QueryOrganizersByEmailsParams, params: RequestParams = {}) =>
      this.request<DtoOrganizerListResponse, any>({
        path: `/organizations/${id}/organizers/by-emails`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name ToggleOrganizersPairingStatus
     * @request PUT:/organizations/{id}/organizers/pairing-status
     * @secure
     * @response `200` `void` OK
     */
    toggleOrganizersPairingStatus: (
      id: string,
      payload: DtoToggleOrganizersPairingStatusRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organizations/${id}/organizers/pairing-status`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name GetPublicOrganizationById
     * @request GET:/organizations/{id}/public
     * @secure
     * @response `200` `DtoSinglePublicOrganizationResponse` OK
     */
    getPublicOrganizationById: (id: string, params: RequestParams = {}) =>
      this.request<DtoSinglePublicOrganizationResponse, any>({
        path: `/organizations/${id}/public`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateOrgSettings
     * @request PUT:/organizations/{id}/settings
     * @secure
     * @response `200` `DtoSingleOrganizationResponse` OK
     */
    updateOrgSettings: (id: string, payload: DtoUpdateOrgSettingsRequest, params: RequestParams = {}) =>
      this.request<DtoSingleOrganizationResponse, any>({
        path: `/organizations/${id}/settings`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name UpdateOrganizationUsesSlack
     * @request PUT:/organizations/{id}/uses-slack
     * @secure
     * @response `200` `void` OK
     */
    updateOrganizationUsesSlack: (
      id: string,
      payload: DtoUpdateOrganizationUsesSlackRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/organizations/${id}/uses-slack`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name GetOrganizerByUid
     * @request GET:/organizers/{uid}
     * @secure
     * @response `200` `DtoSingleOrganizerResponse` OK
     */
    getOrganizerByUid: (uid: string, params: RequestParams = {}) =>
      this.request<DtoSingleOrganizerResponse, any>({
        path: `/organizers/${uid}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name ToggleGameUpdates
     * @request POST:/promotions/game-updates/toggle-by-org
     * @secure
     * @response `200` `void` OK
     */
    toggleGameUpdates: (payload: DtoToggleOrgGameUpdatesRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/promotions/game-updates/toggle-by-org`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  pages = {
    /**
     * No description
     *
     * @tags Pages
     * @name GetPage
     * @request GET:/pages/:name
     * @response `200` `DtoGetPageResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getPage: (name: string, params: RequestParams = {}) =>
      this.request<DtoGetPageResponse, CommonBusinessError>({
        path: `/pages/${name}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  page = {
    /**
     * No description
     *
     * @tags Page
     * @name FeaturedList
     * @request GET:/pages/featured
     * @response `200` `DtoGetFeaturedResponse` OK
     * @response `default` `CommonBusinessError`
     */
    featuredList: (query: FeaturedListParams, params: RequestParams = {}) =>
      this.request<DtoGetFeaturedResponse, CommonBusinessError>({
        path: `/pages/featured`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  product = {
    /**
     * No description
     *
     * @tags Product
     * @name GetProductFeatures
     * @request GET:/product/:id/features
     * @secure
     * @response `200` `DtoProductFeaturesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getProductFeatures: (id: string, params: RequestParams = {}) =>
      this.request<DtoProductFeaturesResponse, CommonBusinessError>({
        path: `/product/${id}/features`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProducts
     * @request GET:/products
     * @secure
     * @response `200` `DtoProductsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getProducts: (params: RequestParams = {}) =>
      this.request<DtoProductsResponse, CommonBusinessError>({
        path: `/products`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name CreateProduct
     * @request POST:/products
     * @secure
     * @response `200` `DtoProductResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createProduct: (payload: DtoCreateProductRequest, params: RequestParams = {}) =>
      this.request<DtoProductResponse, CommonBusinessError>({
        path: `/products`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name DeleteProduct
     * @request DELETE:/products/:id
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteProduct: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/products/${id}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProduct
     * @request GET:/products/:id
     * @secure
     * @response `200` `DtoProductResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getProduct: (id: string, params: RequestParams = {}) =>
      this.request<DtoProductResponse, CommonBusinessError>({
        path: `/products/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name UpdateProduct
     * @request PUT:/products/:id
     * @secure
     * @response `200` `DtoProductResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateProduct: (id: string, payload: DtoUpdateProductRequest, params: RequestParams = {}) =>
      this.request<DtoProductResponse, CommonBusinessError>({
        path: `/products/${id}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name DuplicateProduct
     * @request POST:/products/:id/duplicate
     * @secure
     * @response `200` `DtoProductResponse` OK
     * @response `default` `CommonBusinessError`
     */
    duplicateProduct: (id: string, params: RequestParams = {}) =>
      this.request<DtoProductResponse, CommonBusinessError>({
        path: `/products/${id}/duplicate`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name CreateProductFeature
     * @request POST:/products/:id/features
     * @secure
     * @response `200` `DtoProductFeatureResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createProductFeature: (id: string, payload: DtoCreateProductFeatureRequest, params: RequestParams = {}) =>
      this.request<DtoProductFeatureResponse, CommonBusinessError>({
        path: `/products/${id}/features`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name DeleteProductFeature
     * @request DELETE:/products/:id/features/:featureId
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteProductFeature: (id: string, featureId: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/products/${id}/features/${featureId}`,
        method: 'DELETE',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name UpdateProductFeature
     * @request PUT:/products/:id/features/:featureId
     * @secure
     * @response `200` `DtoProductFeatureResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateProductFeature: (
      id: string,
      featureId: string,
      payload: DtoUpdateProductFeatureRequest,
      params: RequestParams = {},
    ) =>
      this.request<DtoProductFeatureResponse, CommonBusinessError>({
        path: `/products/${id}/features/${featureId}`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name PublishProduct
     * @request POST:/products/:id/publish
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    publishProduct: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/products/${id}/publish`,
        method: 'POST',
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ListStripePrices
     * @request GET:/products/:id/stripe-prices
     * @secure
     * @response `200` `DtoListStripePricesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    listStripePrices: (id: string, params: RequestParams = {}) =>
      this.request<DtoListStripePricesResponse, CommonBusinessError>({
        path: `/products/${id}/stripe-prices`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name AddUnmanagedStripePrice
     * @request POST:/products/:id/unmanaged-stripe-prices
     * @secure
     * @response `200` `DtoAddUnmanagedStripePriceResponse` OK
     * @response `default` `CommonBusinessError`
     */
    addUnmanagedStripePrice: (id: string, payload: DtoAddUnmanagedStripePriceRequest, params: RequestParams = {}) =>
      this.request<DtoAddUnmanagedStripePriceResponse, CommonBusinessError>({
        path: `/products/${id}/unmanaged-stripe-prices`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductDefaults
     * @request GET:/products/defaults
     * @secure
     * @response `200` `DtoProductDefaultsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getProductDefaults: (params: RequestParams = {}) =>
      this.request<DtoProductDefaultsResponse, CommonBusinessError>({
        path: `/products/defaults`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name UpdateProductDefaults
     * @request PUT:/products/defaults
     * @secure
     * @response `200` `DtoProductDefaultsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateProductDefaults: (payload: DtoUpdateProductDefaultsRequest, params: RequestParams = {}) =>
      this.request<DtoProductDefaultsResponse, CommonBusinessError>({
        path: `/products/defaults`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetLiveProduct
     * @request GET:/products/live
     * @secure
     * @response `200` `DtoNullableProductResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getLiveProduct: (params: RequestParams = {}) =>
      this.request<DtoNullableProductResponse, CommonBusinessError>({
        path: `/products/live`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name UpdateMarketedProducts
     * @request PUT:/products/marketed
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateMarketedProducts: (payload: DtoUpdateMarketedProductsRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/products/marketed`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetMostPopularProduct
     * @request GET:/products/most-popular
     * @secure
     * @response `200` `DtoNullableProductResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getMostPopularProduct: (params: RequestParams = {}) =>
      this.request<DtoNullableProductResponse, CommonBusinessError>({
        path: `/products/most-popular`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetPublicProducts
     * @request GET:/products/public
     * @secure
     * @response `200` `DtoProductsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getPublicProducts: (params: RequestParams = {}) =>
      this.request<DtoProductsResponse, CommonBusinessError>({
        path: `/products/public`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  settings = {
    /**
     * No description
     *
     * @tags Settings
     * @name SettingsDummy
     * @request GET:/programs/dummy
     * @secure
     * @response `200` `ModelsSettingsVoiceOverLocales` OK
     * @response `default` `CommonBusinessError`
     */
    settingsDummy: (params: RequestParams = {}) =>
      this.request<ModelsSettingsVoiceOverLocales, CommonBusinessError>({
        path: `/programs/dummy`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name GetSettings
     * @request GET:/settings/:name
     * @response `200` `DtoSettingsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getSettings: (name: string, params: RequestParams = {}) =>
      this.request<DtoSettingsResponse, CommonBusinessError>({
        path: `/settings/${name}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name GetSettingsData
     * @request GET:/settings/:name/data
     * @response `default` `CommonBusinessError`
     */
    getSettingsData: (name: string, params: RequestParams = {}) =>
      this.request<any, CommonBusinessError>({
        path: `/settings/${name}/data`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name UpdateSettingsData
     * @request POST:/settings/:name/data
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateSettingsData: (name: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/settings/${name}/data`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),
  };
  ai = {
    /**
     * No description
     *
     * @tags AI
     * @name GetPromptTemplates
     * @request GET:/prompt-templates
     * @response `200` `DtoPromptTemplateListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getPromptTemplates: (params: RequestParams = {}) =>
      this.request<DtoPromptTemplateListResponse, CommonBusinessError>({
        path: `/prompt-templates`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI
     * @name CreatePromptTemplate
     * @request POST:/prompt-templates
     * @response `200` `DtoPromptTemplateResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createPromptTemplate: (payload: DtoCreatePromptTemplateRequest, params: RequestParams = {}) =>
      this.request<DtoPromptTemplateResponse, CommonBusinessError>({
        path: `/prompt-templates`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI
     * @name DeletePromptTemplate
     * @request DELETE:/prompt-templates/:id
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deletePromptTemplate: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/prompt-templates/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI
     * @name GetPromptTemplateById
     * @request GET:/prompt-templates/:id
     * @response `200` `DtoPromptTemplateResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getPromptTemplateById: (id: string, params: RequestParams = {}) =>
      this.request<DtoPromptTemplateResponse, CommonBusinessError>({
        path: `/prompt-templates/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI
     * @name UpdatePromptTemplate
     * @request PUT:/prompt-templates/:id
     * @response `200` `DtoPromptTemplateResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updatePromptTemplate: (id: string, payload: DtoUpdatePromptTemplateRequest, params: RequestParams = {}) =>
      this.request<DtoPromptTemplateResponse, CommonBusinessError>({
        path: `/prompt-templates/${id}`,
        method: 'PUT',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI
     * @name ClonePromptTemplate
     * @request POST:/prompt-templates/:id/clone
     * @response `200` `DtoPromptTemplateResponse` OK
     * @response `default` `CommonBusinessError`
     */
    clonePromptTemplate: (id: string, params: RequestParams = {}) =>
      this.request<DtoPromptTemplateResponse, CommonBusinessError>({
        path: `/prompt-templates/${id}/clone`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI
     * @name LockPromptTemplate
     * @request POST:/prompt-templates/:id/lock
     * @response `200` `DtoPromptTemplateResponse` OK
     * @response `default` `CommonBusinessError`
     */
    lockPromptTemplate: (id: string, params: RequestParams = {}) =>
      this.request<DtoPromptTemplateResponse, CommonBusinessError>({
        path: `/prompt-templates/${id}/lock`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  roleplay = {
    /**
     * @description Query roleplay histories
     *
     * @tags roleplay
     * @name QueryRoleplayHistories
     * @request GET:/roleplay/histories
     * @response `200` `DtoRoleplayHistoryListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryRoleplayHistories: (query: QueryRoleplayHistoriesParams, params: RequestParams = {}) =>
      this.request<DtoRoleplayHistoryListResponse, CommonBusinessError>({
        path: `/roleplay/histories`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a roleplay history
     *
     * @tags roleplay
     * @name CreateRoleplayHistory
     * @request POST:/roleplay/histories
     * @response `200` `DtoRoleplayHistoryResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createRoleplayHistory: (payload: DtoCreateRoleplayHistoryRequest, params: RequestParams = {}) =>
      this.request<DtoRoleplayHistoryResponse, CommonBusinessError>({
        path: `/roleplay/histories`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Build a roleplay history
     *
     * @tags roleplay
     * @name GetRoleplayHistory
     * @request GET:/roleplay/histories/{id}
     * @response `200` `DtoRoleplayHistoryResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getRoleplayHistory: (id: string, params: RequestParams = {}) =>
      this.request<DtoRoleplayHistoryResponse, CommonBusinessError>({
        path: `/roleplay/histories/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Submit a roleplay rating
     *
     * @tags roleplay
     * @name SubmitRoleplayRating
     * @request POST:/roleplay/histories/{id}/rating
     * @response `200` `DtoRoleplayHistoryResponse` OK
     * @response `default` `CommonBusinessError`
     */
    submitRoleplayRating: (id: string, payload: DtoSubmitRoleplayRatingRequest, params: RequestParams = {}) =>
      this.request<DtoRoleplayHistoryResponse, CommonBusinessError>({
        path: `/roleplay/histories/${id}/rating`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Generate a roleplay prompt
     *
     * @tags roleplay
     * @name GenerateRoleplayPrompt
     * @request POST:/roleplay/prompt
     * @response `200` `DtoGenerateRoleplayPromptResponse` OK
     * @response `default` `CommonBusinessError`
     */
    generateRoleplayPrompt: (payload: DtoGenerateRoleplayPromptRequest, params: RequestParams = {}) =>
      this.request<DtoGenerateRoleplayPromptResponse, CommonBusinessError>({
        path: `/roleplay/prompt`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  slack = {
    /**
     * No description
     *
     * @tags Slack
     * @name AddUsersToChannel
     * @request POST:/slack/channel/add-users
     * @response `default` `CommonBusinessError`
     */
    addUsersToChannel: (payload: DtoSlackAddUsersToChannelRequest, params: RequestParams = {}) =>
      this.request<any, CommonBusinessError>({
        path: `/slack/channel/add-users`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slack
     * @name QuerySlackChannels
     * @request GET:/slack/channels
     * @response `200` `DtoSlackChannelListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    querySlackChannels: (query: QuerySlackChannelsParams, params: RequestParams = {}) =>
      this.request<DtoSlackChannelListResponse, CommonBusinessError>({
        path: `/slack/channels`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slack
     * @name CreateSlackChannel
     * @request POST:/slack/channels
     * @response `200` `DtoSlackCreateChannelResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createSlackChannel: (payload: DtoSlackCreateChannelRequest, params: RequestParams = {}) =>
      this.request<DtoSlackCreateChannelResponse, CommonBusinessError>({
        path: `/slack/channels`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slack
     * @name SearchSlackChannels
     * @request GET:/slack/channels/search
     * @response `200` `DtoSlackChannelListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    searchSlackChannels: (query: SearchSlackChannelsParams, params: RequestParams = {}) =>
      this.request<DtoSlackChannelListResponse, CommonBusinessError>({
        path: `/slack/channels/search`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slack
     * @name FindOrCreateChannel
     * @request POST:/slack/find-or-create-channel
     * @response `200` `DtoSlackCreateChannelResponse` OK
     * @response `default` `CommonBusinessError`
     */
    findOrCreateChannel: (payload: DtoSlackCreateChannelRequest, params: RequestParams = {}) =>
      this.request<DtoSlackCreateChannelResponse, CommonBusinessError>({
        path: `/slack/find-or-create-channel`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slack
     * @name GenerateSlackInstallUrl
     * @request POST:/slack/generate-install-url
     * @response `200` `ClientSlackGenerateInstallUrlResponse` OK
     * @response `default` `CommonBusinessError`
     */
    generateSlackInstallUrl: (params: RequestParams = {}) =>
      this.request<ClientSlackGenerateInstallUrlResponse, CommonBusinessError>({
        path: `/slack/generate-install-url`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slack
     * @name SlackInstallCallback
     * @request GET:/slack/install-callback
     * @response `200` `DtoSlackInstallCallbackResponse` OK
     * @response `default` `CommonBusinessError`
     */
    slackInstallCallback: (params: RequestParams = {}) =>
      this.request<DtoSlackInstallCallbackResponse, CommonBusinessError>({
        path: `/slack/install-callback`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slack
     * @name SlackGetInstallation
     * @request GET:/slack/installation
     * @response `200` `DtoSlackInstallationResponse` OK
     * @response `default` `CommonBusinessError`
     */
    slackGetInstallation: (params: RequestParams = {}) =>
      this.request<DtoSlackInstallationResponse, CommonBusinessError>({
        path: `/slack/installation`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slack
     * @name GetSocketModeConnections
     * @request GET:/slack/socket-mode-connections
     * @response `200` `DtoSlackSocketModeConnectionsResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getSocketModeConnections: (params: RequestParams = {}) =>
      this.request<DtoSlackSocketModeConnectionsResponse, CommonBusinessError>({
        path: `/slack/socket-mode-connections`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slack
     * @name UpdateSocketModeConnections
     * @request POST:/slack/socket-mode-connections
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateSocketModeConnections: (params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/slack/socket-mode-connections`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Slack
     * @name QuerySlackUsers
     * @request GET:/slack/users
     * @response `200` `DtoSlackUserListResponse` OK
     * @response `default` `CommonBusinessError`
     */
    querySlackUsers: (query: QuerySlackUsersParams, params: RequestParams = {}) =>
      this.request<DtoSlackUserListResponse, CommonBusinessError>({
        path: `/slack/users`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  training = {
    /**
     * No description
     *
     * @tags Training
     * @name SendEditorChatMessage
     * @request POST:/training/blocks/:id/editor/message
     * @response `200` `DtoSendTrainingEditorMessageResponse` OK
     * @response `default` `CommonBusinessError`
     */
    sendEditorChatMessage: (id: string, payload: DtoSendTrainingEditorMessageRequest, params: RequestParams = {}) =>
      this.request<DtoSendTrainingEditorMessageResponse, CommonBusinessError>({
        path: `/training/blocks/${id}/editor/message`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name GetTrainingEditorMessages
     * @request GET:/training/blocks/:id/editor/messages
     * @response `200` `DtoTrainingEditorMessagesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getTrainingEditorMessages: (id: string, params: RequestParams = {}) =>
      this.request<DtoTrainingEditorMessagesResponse, CommonBusinessError>({
        path: `/training/blocks/${id}/editor/messages`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name DeleteTrainingEditorThread
     * @request DELETE:/training/blocks/:id/editor/thread
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteTrainingEditorThread: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/training/blocks/${id}/editor/thread`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name GenerateMediaSearchKeywords
     * @request POST:/training/generate-media-search-keywords
     * @response `200` `DtoGenerateMediaSearchKeywordResponse` OK
     * @response `default` `CommonBusinessError`
     */
    generateMediaSearchKeywords: (payload: DtoGenerateMediaSearchKeywordRequest, params: RequestParams = {}) =>
      this.request<DtoGenerateMediaSearchKeywordResponse, CommonBusinessError>({
        path: `/training/generate-media-search-keywords`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name GenerateTopicBlocks
     * @request POST:/training/generate-topic-blocks
     * @response `200` `DtoGenerateTopicBlocksResponse` OK
     * @response `default` `CommonBusinessError`
     */
    generateTopicBlocks: (payload: DtoGenerateTopicBlocksRequest, params: RequestParams = {}) =>
      this.request<DtoGenerateTopicBlocksResponse, CommonBusinessError>({
        path: `/training/generate-topic-blocks`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name GenerateTopicOutline
     * @request POST:/training/generate-topic-outline
     * @response `200` `DtoGenerateTopicOutlineResponse` OK
     * @response `default` `CommonBusinessError`
     */
    generateTopicOutline: (payload: DtoGenerateTopicOutlineRequest, params: RequestParams = {}) =>
      this.request<DtoGenerateTopicOutlineResponse, CommonBusinessError>({
        path: `/training/generate-topic-outline`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name GenerateTrainingBlock
     * @request POST:/training/generate-training-block
     * @response `200` `DtoGenerateTrainingBlockResponse` OK
     * @response `default` `CommonBusinessError`
     */
    generateTrainingBlock: (payload: DtoGenerateTrainingBlockRequest, params: RequestParams = {}) =>
      this.request<DtoGenerateTrainingBlockResponse, CommonBusinessError>({
        path: `/training/generate-training-block`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name GenerateTrainingOutline
     * @request POST:/training/generate-training-outline
     * @response `200` `DtoGenerateTrainingOutlineResponse` OK
     * @response `default` `CommonBusinessError`
     */
    generateTrainingOutline: (payload: DtoGenerateTrainingOutlineRequest, params: RequestParams = {}) =>
      this.request<DtoGenerateTrainingOutlineResponse, CommonBusinessError>({
        path: `/training/generate-training-outline`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name MakeTrainingCourse
     * @request POST:/training/make-training-course
     * @response `200` `DtoMakeTrainingCourseResponse` OK
     * @response `default` `CommonBusinessError`
     */
    makeTrainingCourse: (payload: DtoMakeTrainingCourseRequest, params: RequestParams = {}) =>
      this.request<DtoMakeTrainingCourseResponse, CommonBusinessError>({
        path: `/training/make-training-course`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name GetMusicLibrary
     * @request GET:/training/music-library
     * @response `200` `DtoGetTrainingMusicLibraryResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getMusicLibrary: (params: RequestParams = {}) =>
      this.request<DtoGetTrainingMusicLibraryResponse, CommonBusinessError>({
        path: `/training/music-library`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name GetPersonalities
     * @request GET:/training/personalities
     * @response `200` `DtoGetTrainingPersonalitiesResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getPersonalities: (params: RequestParams = {}) =>
      this.request<DtoGetTrainingPersonalitiesResponse, CommonBusinessError>({
        path: `/training/personalities`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name ListTrainingProfiles
     * @request GET:/training/profiles
     * @response `200` `DtoListTrainingProfileResponse` OK
     * @response `default` `CommonBusinessError`
     */
    listTrainingProfiles: (params: RequestParams = {}) =>
      this.request<DtoListTrainingProfileResponse, CommonBusinessError>({
        path: `/training/profiles`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name CreateTrainingProfile
     * @request POST:/training/profiles
     * @response `200` `DtoSingleTrainingProfileResponse` OK
     * @response `default` `CommonBusinessError`
     */
    createTrainingProfile: (payload: DtoUpsertTrainingProfileRequest, params: RequestParams = {}) =>
      this.request<DtoSingleTrainingProfileResponse, CommonBusinessError>({
        path: `/training/profiles`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name DeleteTrainingProfile
     * @request DELETE:/training/profiles/:id
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    deleteTrainingProfile: (id: string, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/training/profiles/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name GetTrainingProfile
     * @request GET:/training/profiles/:id
     * @response `200` `DtoSingleTrainingProfileResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getTrainingProfile: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleTrainingProfileResponse, CommonBusinessError>({
        path: `/training/profiles/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name UpdateTrainingProfile
     * @request PUT:/training/profiles/:id
     * @response `200` `DtoSingleTrainingProfileResponse` OK
     * @response `default` `CommonBusinessError`
     */
    updateTrainingProfile: (id: string, payload: DtoUpsertTrainingProfileRequest, params: RequestParams = {}) =>
      this.request<DtoSingleTrainingProfileResponse, CommonBusinessError>({
        path: `/training/profiles/${id}`,
        method: 'PUT',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name DuplicateTrainingProfile
     * @request POST:/training/profiles/:id/duplicate
     * @response `200` `DtoSingleTrainingProfileResponse` OK
     * @response `default` `CommonBusinessError`
     */
    duplicateTrainingProfile: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleTrainingProfileResponse, CommonBusinessError>({
        path: `/training/profiles/${id}/duplicate`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name LockTrainingProfile
     * @request PUT:/training/profiles/:id/lock
     * @response `200` `DtoSingleTrainingProfileResponse` OK
     * @response `default` `CommonBusinessError`
     */
    lockTrainingProfile: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleTrainingProfileResponse, CommonBusinessError>({
        path: `/training/profiles/${id}/lock`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name UnlockTrainingProfile
     * @request PUT:/training/profiles/:id/unlock
     * @response `200` `DtoSingleTrainingProfileResponse` OK
     * @response `default` `CommonBusinessError`
     */
    unlockTrainingProfile: (id: string, params: RequestParams = {}) =>
      this.request<DtoSingleTrainingProfileResponse, CommonBusinessError>({
        path: `/training/profiles/${id}/unlock`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Training
     * @name SelectBestMatchMedia
     * @request POST:/training/select-best-match-media
     * @response `200` `DtoSelectBestMatchMediaResponse` OK
     * @response `default` `CommonBusinessError`
     */
    selectBestMatchMedia: (payload: DtoSelectBestMatchMediaRequest, params: RequestParams = {}) =>
      this.request<DtoSelectBestMatchMediaResponse, CommonBusinessError>({
        path: `/training/select-best-match-media`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  translation = {
    /**
     * No description
     *
     * @tags Translation
     * @name Translate
     * @request POST:/translation/translate
     * @response `200` `DtoTranslationTextRequest` OK
     * @response `default` `CommonBusinessError`
     */
    translate: (payload: DtoTranslationTextRequest, params: RequestParams = {}) =>
      this.request<DtoTranslationTextRequest, CommonBusinessError>({
        path: `/translation/translate`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  user = {
    /**
     * @description Query users
     *
     * @tags User
     * @name QueryUsers
     * @request GET:/users
     * @secure
     * @response `200` `DtoUsersResponse` OK
     * @response `default` `CommonBusinessError`
     */
    queryUsers: (query: QueryUsersParams, params: RequestParams = {}) =>
      this.request<DtoUsersResponse, CommonBusinessError>({
        path: `/users`,
        method: 'GET',
        query: query,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Build User Info
     *
     * @tags User
     * @name GetUser
     * @request GET:/users/:id
     * @secure
     * @response `200` `DtoGetUserResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getUser: (id: string, params: RequestParams = {}) =>
      this.request<DtoGetUserResponse, CommonBusinessError>({
        path: `/users/${id}`,
        method: 'GET',
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update users role. Admin only
     *
     * @tags User
     * @name UpdateRole
     * @request PUT:/users/:id/role
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateRole: (id: string, payload: DtoUpdateRoleRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/users/${id}/role`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name LookupNewUsers
     * @request POST:/users/new-users
     * @secure
     * @response `200` `DtoLookupNewUsersResponse` OK
     * @response `default` `CommonBusinessError`
     */
    lookupNewUsers: (payload: DtoLookupNewUsersRequest, params: RequestParams = {}) =>
      this.request<DtoLookupNewUsersResponse, CommonBusinessError>({
        path: `/users/new-users`,
        method: 'POST',
        body: payload,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update current password
     *
     * @tags User
     * @name UpdatePassword
     * @request PUT:/users/password
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updatePassword: (payload: DtoUpdatePasswordRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/users/password`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update current username
     *
     * @tags User
     * @name UpdateUsername
     * @request PUT:/users/username
     * @secure
     * @response `200` `void` OK
     * @response `default` `CommonBusinessError`
     */
    updateUsername: (payload: DtoUpdateUsernameRequest, params: RequestParams = {}) =>
      this.request<void, CommonBusinessError>({
        path: `/users/username`,
        method: 'PUT',
        body: payload,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  zoom = {
    /**
     * No description
     *
     * @tags Zoom
     * @name GetZoomAuthorizeUrl
     * @request POST:/zoom/authorize
     * @response `200` `DtoZoomGetAuthorizeURLResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getZoomAuthorizeUrl: (payload: DtoZoomGetAuthorizeURLRequest, params: RequestParams = {}) =>
      this.request<DtoZoomGetAuthorizeURLResponse, CommonBusinessError>({
        path: `/zoom/authorize`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zoom
     * @name ZoomCheckQuickLoginAvailable
     * @request POST:/zoom/check-quick-login-available
     * @response `200` `DtoZoomCheckQuickLoginResponse` OK
     * @response `default` `CommonBusinessError`
     */
    zoomCheckQuickLoginAvailable: (payload: DtoZoomCheckQuickLoginRequest, params: RequestParams = {}) =>
      this.request<DtoZoomCheckQuickLoginResponse, CommonBusinessError>({
        path: `/zoom/check-quick-login-available`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zoom
     * @name ZoomDecryptedAppContext
     * @request POST:/zoom/decrypt-app-context
     * @response `200` `DtoZoomDecryptAppContextResponse` OK
     * @response `default` `CommonBusinessError`
     */
    zoomDecryptedAppContext: (payload: DtoZoomDecryptAppContextRequest, params: RequestParams = {}) =>
      this.request<DtoZoomDecryptAppContextResponse, CommonBusinessError>({
        path: `/zoom/decrypt-app-context`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zoom
     * @name ZoomSetupExperience
     * @request POST:/zoom/experiences/:packId/setup
     * @response `200` `DtoZoomSetupExperienceResponse` OK
     * @response `default` `CommonBusinessError`
     */
    zoomSetupExperience: (packId: string, params: RequestParams = {}) =>
      this.request<DtoZoomSetupExperienceResponse, CommonBusinessError>({
        path: `/zoom/experiences/${packId}/setup`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zoom
     * @name GetZoomDeeplink
     * @request POST:/zoom/get-deeplink
     * @response `200` `DtoZoomGetDeeplinkResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getZoomDeeplink: (payload: DtoZoomGetDeeplinkRequest, params: RequestParams = {}) =>
      this.request<DtoZoomGetDeeplinkResponse, CommonBusinessError>({
        path: `/zoom/get-deeplink`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zoom
     * @name ZoomLogin
     * @request POST:/zoom/login
     * @response `200` `DtoZoomLoginResponse` OK
     * @response `default` `CommonBusinessError`
     */
    zoomLogin: (payload: DtoZoomLoginRequest, params: RequestParams = {}) =>
      this.request<DtoZoomLoginResponse, CommonBusinessError>({
        path: `/zoom/login`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zoom
     * @name ZoomLogout
     * @request POST:/zoom/logout
     * @response `200` `DtoZoomLogoutResponse` OK
     * @response `default` `CommonBusinessError`
     */
    zoomLogout: (payload: DtoZoomLogoutRequest, params: RequestParams = {}) =>
      this.request<DtoZoomLogoutResponse, CommonBusinessError>({
        path: `/zoom/logout`,
        method: 'POST',
        body: payload,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zoom
     * @name GetZoomUser
     * @request GET:/zoom/users/{uid}
     * @response `200` `DtoZoomGetUserResponse` OK
     * @response `default` `CommonBusinessError`
     */
    getZoomUser: (uid: string, params: RequestParams = {}) =>
      this.request<DtoZoomGetUserResponse, CommonBusinessError>({
        path: `/zoom/users/${uid}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
