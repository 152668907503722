import './design/styles.css';

import { useNavigate } from '@remix-run/react';
import { type ReactNode, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { proxy, ref } from 'valtio';

import {
  type DtoBlock,
  type DtoProgression,
  type ModelsBlockOutput,
} from '@lp-lib/api-service-client/public';
import {
  BlockTypeV2,
  blockTypeV2fromEnumsBlockType,
  type DrawToWinBlock,
  type FillInTheBlanksBlock,
  type HiddenPictureBlock,
  type JeopardyBlock,
  type MemoryMatchBlock,
  type MultipleChoiceBlock,
  type QuestionBlock,
  type RoleplayBlock,
  type SlideBlock,
  type SparkifactBlock,
  type SwipeToWinBlock,
} from '@lp-lib/game';
import {
  getBlockOutputAsString,
  getBlockOutputKey,
} from '@lp-lib/game/src/block-outputs';
import { type Logger } from '@lp-lib/logger-base';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { findLanguageOptionOrDefault } from '../../i18n/language-options';
import { getLogger } from '../../logger/logger';
import { useLoggerConsoleCtrl } from '../../logger/logger-ctrl';
import { apiService } from '../../services/api-service';
import { type User } from '../../types/user';
import {
  type AbortableRunner,
  YieldableAbortableRunner,
} from '../../utils/AbortSignalableRunner';
import { fromDTOBlock } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { assertExhaustive, sleep } from '../../utils/common';
import { StorageFactory } from '../../utils/storage';
import { markSnapshottable, useSnapshot } from '../../utils/valtio';
import { DefaultClock } from '../Clock';
import { Loading } from '../Loading';
import { SubtitlesAreaV2 } from '../MediaControls/SubtitlesAreaV2';
import { ProvidersList } from '../ProvidersList';
import { useMyI18nSettings } from '../Settings/useMyI18nSettings';
import { useUser } from '../UserContext';
import { InitGlobalLVOLocalCtrl } from '../VoiceOver/LocalLocalizedVoiceOvers';
import {
  type ISubtitlesManager,
  LocalSubtitlesManager,
} from '../VoiceOver/LocalSubtitlesManager';
import { VariableRegistry } from '../VoiceOver/VariableRegistry';
import {
  GlobalAudioDetectionProvider,
  useGlobalAudioDetectionAPI,
} from './apis/AudioDetection';
import { BlockLogicEvaluator } from './apis/BlockLogicEvaluator';
import { LMSNotifier } from './apis/LMSNotify';
import { MusicControl } from './apis/MusicControl';
import { PauseManagement } from './apis/PauseManagement';
import { SFXControl } from './apis/SFXControl';
import {
  BlockStinger,
  MinigameStinger,
  StingerControl,
  useStingerElements,
} from './apis/StingerControl';
import {
  DrawToWinBlockControlAPI,
  DrawToWinBlockPlayground,
} from './blocks/DrawToWin';
import {
  FIBPlayground,
  newFIBControlAPI,
} from './blocks/FillInTheBlanks/FIBPlayground';
import {
  HiddenPictureBlockControlAPI,
  HiddenPictureBlockPlayground,
} from './blocks/HiddenPicture/HiddenPictureBlock';
import {
  JeopardyBlockControlAPI,
  JeopardyBlockPlayground,
} from './blocks/Jeopardy/JeopardyBlockPlayground';
import {
  MatchBlockControlAPI,
  MatchBlockPlayground,
} from './blocks/Match/MatchBlock';
import {
  MultipleChoiceBlockControlAPI,
  MultipleChoiceBlockPlayground,
} from './blocks/MultipleChoice/MultipleChoiceBlock';
import {
  QuestionBlockControlAPI,
  QuestionBlockPlayground,
} from './blocks/Question/QuestionBlock';
import {
  RoleplayBlockControlAPI,
  RoleplayBlockPlayground,
} from './blocks/Roleplay/RoleplayBlock';
import {
  SlideBlockControlAPI,
  SlideBlockPlayground,
} from './blocks/SlideBlock';
import {
  SparkifactBlockControlAPI,
  SparkifactBlockPlayground,
} from './blocks/Sparkifact/SparkifactBlock';
import {
  SwipeToWinBlockControlAPI,
  SwipeToWinBlockPlayground,
} from './blocks/SwipeToWin/SwipeToWinBlock';
import { CommonButton } from './design/Button';
import { StatusBar } from './design/StatusBar';
import { type PlayCursor } from './PlayCursor';
import {
  type BlockDependencies,
  type IBlockCtrl,
  type PlaygroundPlaybackProtocol,
} from './types';

export type PlaygroundProps = {
  // the cursor that will be used to play the game.
  cursor: PlayCursor;
  // any block outputs that should be added to the initial variable registry.
  initialBlockOutputs?: Nullable<DtoProgression['blockOutputs']>;
  // called when the user clicks the X on the status bar.
  onClose: () => void;
  // called when the user clicks the continue button after finishing the game.
  onMinigameContinue: () => void;
  // when true, the playground will not track progression.
  preview?: boolean;
};

export function Playground(props: PlaygroundProps) {
  const stingerElements = useStingerElements();
  return (
    <ProviderInitialization>
      <AudioInitialization>
        <LoggerControl />
        <ControlInitialization {...props} stingerElements={stingerElements} />
      </AudioInitialization>
      <BlockStinger ref={stingerElements.blockStingerEl} />
      <MinigameStinger ref={stingerElements.minigameStingerEl} />
    </ProviderInitialization>
  );
}

const ROCKET_ANIMATION = getStaticAssetPath('videos/rocket-animation.mp4');
const FINISH_GAME_PACK_ANIMATION = getStaticAssetPath(
  'videos/training-finish-animation.mp4'
);
const FINISH_SLIDE_GROUP_ANIMATION = getStaticAssetPath(
  'videos/slide-group-finish-animation.mp4'
);

function ProviderInitialization(props: { children: ReactNode }) {
  //////////////
  // NOTE: please think twice about what you add here!! Try to keep this as
  // minimal as possible. Consider adding a block dependency instead.
  //////////////
  const providers = [];
  providers.push(<GlobalAudioDetectionProvider />);

  return <ProvidersList providers={providers}>{props.children}</ProvidersList>;
}

function LoggerControl() {
  useLoggerConsoleCtrl();
  return null;
}

function AudioInitialization(props: { children: ReactNode }) {
  const api = useGlobalAudioDetectionAPI();
  const audioReady = useSnapshot(api.state).audioReady;
  const [unlocking, setUnlocking] = useState(false);

  if (!audioReady) {
    return (
      <div className='h-full flex flex-col bg-black text-white items-center'>
        <div className='flex-1 flex flex-col justify-center items-center px-5'>
          <div className='flex flex-col items-center'>
            <h1 className='text-3xl lg:text-4xl font-bold mb-4'>
              Ready to go?
            </h1>

            <div className='w-80 h-80 mb-4 lg:w-120 lg:h-120'>
              <video
                src={ROCKET_ANIMATION}
                autoPlay
                loop
                muted
                playsInline
                className='w-full h-full object-contain'
              />
            </div>

            <div className='text-center lg:text-xl'>
              <p className='text-white'>Your experience has loaded.</p>
              <p className='text-white'>Click below to proceed</p>
            </div>
          </div>
        </div>

        <CommonButton
          type='button'
          variant='brand'
          onClickCapture={async () => {
            if (unlocking) return;
            setUnlocking(true);
            try {
              await api.tryUnlock();
            } finally {
              setUnlocking(false);
            }
          }}
          disabled={unlocking}
          className='mb-4 w-11/12 lg:w-1/5'
        >
          {unlocking ? 'Loading...' : 'Continue'}
        </CommonButton>
      </div>
    );
  }

  return <>{props.children}</>;
}

function ControlInitialization(
  props: PlaygroundProps & {
    stingerElements: ReturnType<typeof useStingerElements>;
  }
) {
  const user = useUser();
  const getUser = useLiveCallback(() => user);
  const navigate = useNavigate();
  const [control, setControl] = useState<PlaygroundControlAPI>(
    () =>
      new PlaygroundControlAPI(
        props.cursor,
        props.initialBlockOutputs,
        getUser,
        props.stingerElements,
        navigate,
        props.preview
      )
  );

  useEffect(() => {
    if (control.shouldRebuild(props.cursor)) {
      control.destroy();
      setControl(
        new PlaygroundControlAPI(
          props.cursor,
          props.initialBlockOutputs,
          getUser,
          props.stingerElements,
          navigate,
          props.preview
        )
      );
    }
  }, [
    control,
    getUser,
    props.cursor,
    props.preview,
    props.initialBlockOutputs,
    props.stingerElements,
    navigate,
  ]);

  useEffect(() => {
    return () => {
      control.destroy();
    };
  }, [control]);

  return (
    <>
      <InitGlobalLVOLocalCtrl subman={control.blockDeps.subman} />
      <PlaygroundInternal {...props} ctrl={control} />
    </>
  );
}

function SubtitlesAreaWithLocale(props: { subman: ISubtitlesManager }) {
  const { i18nSettings } = useMyI18nSettings();
  const currentSubtitlesLocale = findLanguageOptionOrDefault(
    i18nSettings?.value?.subtitlesLocale
  );
  return (
    <SubtitlesAreaV2
      subman={props.subman}
      className=''
      currentLanguage={currentSubtitlesLocale}
    />
  );
}

function PlaygroundInternal(
  props: PlaygroundProps & {
    ctrl: PlaygroundControlAPI;
  }
) {
  const snapshot = useSnapshot(props.ctrl.state);
  const prevBlock = usePrevious(snapshot.block);
  const prevBlockCtrl = usePrevious(snapshot.blockCtrl);

  let body = null;
  let showProgress = true;

  if (snapshot.status === 'preparing') {
    body = <Loading text='' />;
    showProgress = false;
  } else if (snapshot.status === 'minigame-intro') {
    body = null;
    showProgress = false;
  } else if (snapshot.status === 'finished') {
    body = <Finished {...props} />;
  } else if (snapshot.status === 'playing-block') {
    body = (
      <DisplayBlock
        block={snapshot.block as Nullable<DtoBlock>}
        blockCtrl={snapshot.blockCtrl}
        ctrl={props.ctrl}
      />
    );
  } else if (
    snapshot.status === 'initializing-block' &&
    prevBlock &&
    prevBlockCtrl
  ) {
    body = (
      <DisplayBlock
        block={prevBlock as Nullable<DtoBlock>}
        blockCtrl={prevBlockCtrl}
        ctrl={props.ctrl}
      />
    );
  }

  return (
    <div className='relative w-full max-w-240 h-full min-h-0 mx-auto flex flex-col'>
      <div
        className={`p-5 pb-0 ${
          showProgress ? 'opacity-100' : 'opacity-0'
        } transition-opacity z-5`}
      >
        <StatusBar progressPct={snapshot.progressPct} onClose={props.onClose} />
      </div>
      <div className='flex-1 min-h-0 overflow-hidden'>{body}</div>
    </div>
  );
}

function DisplayBlock(props: {
  ctrl: PlaygroundControlAPI;
  block: Nullable<DtoBlock>;
  blockCtrl: Nullable<IBlockCtrl>;
}) {
  const type = blockTypeV2fromEnumsBlockType(props.block?.type);
  switch (type) {
    case BlockTypeV2.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as MultipleChoiceBlock}
          ctrl={props.blockCtrl as MultipleChoiceBlockControlAPI}
        />
      );
    case BlockTypeV2.QUESTION:
      return (
        <QuestionBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as QuestionBlock}
          ctrl={props.blockCtrl as QuestionBlockControlAPI}
        />
      );
    case BlockTypeV2.MATCH:
      return (
        <MatchBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as MemoryMatchBlock}
          ctrl={props.blockCtrl as MatchBlockControlAPI}
        />
      );
    case BlockTypeV2.SLIDE:
      return (
        <SlideBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as SlideBlock}
          ctrl={props.blockCtrl as SlideBlockControlAPI}
          subtitlesArea={
            <SubtitlesAreaWithLocale subman={props.ctrl.blockDeps.subman} />
          }
        />
      );
    case BlockTypeV2.ROLEPLAY:
      return (
        <RoleplayBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as RoleplayBlock}
          ctrl={props.blockCtrl as RoleplayBlockControlAPI}
        />
      );
    case BlockTypeV2.DRAW_TO_WIN:
      return (
        <DrawToWinBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as DrawToWinBlock}
          ctrl={props.blockCtrl as DrawToWinBlockControlAPI}
        />
      );
    case BlockTypeV2.HIDDEN_PICTURE:
      return (
        <HiddenPictureBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as HiddenPictureBlock}
          ctrl={props.blockCtrl as HiddenPictureBlockControlAPI}
        />
      );
    case BlockTypeV2.JEOPARDY:
      return (
        <JeopardyBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as JeopardyBlock}
          ctrl={props.blockCtrl as JeopardyBlockControlAPI}
        />
      );
    case BlockTypeV2.SPARKIFACT:
      return (
        <SparkifactBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as SparkifactBlock}
          ctrl={props.blockCtrl as SparkifactBlockControlAPI}
        />
      );
    case BlockTypeV2.FILL_IN_THE_BLANKS:
      return (
        <FIBPlayground
          block={props.block as unknown as FillInTheBlanksBlock}
          ctrl={props.blockCtrl as IBlockCtrl}
        />
      );
    case BlockTypeV2.SWIPE_TO_WIN:
      return (
        <SwipeToWinBlockPlayground
          key={props.block?.id}
          block={props.block as unknown as SwipeToWinBlock}
          ctrl={props.blockCtrl as SwipeToWinBlockControlAPI}
        />
      );
    case null:
    case undefined:
      return null;
    default:
      assertExhaustive(type);
      return null;
  }
}

function Finished(props: PlaygroundProps) {
  const { onMinigameContinue: onContinue, cursor } = props;

  const currentSlideGroupName = cursor.currentMinigame()?.name;
  const gamePackName = cursor.gamePack.name;
  const isLastSlideGroup = !cursor.peekNextMinigame();

  const videoSrc = isLastSlideGroup
    ? FINISH_GAME_PACK_ANIMATION
    : FINISH_SLIDE_GROUP_ANIMATION;

  return (
    <div className='h-full flex flex-col bg-black text-white items-center'>
      <div className='flex-1 flex flex-col justify-center items-center px-5'>
        <h1 className='text-xl lg:text-2xl font-bold mb-4 text-center'>
          {isLastSlideGroup ? (
            <>
              Congratulations! You finished
              <br />
              {gamePackName}!
            </>
          ) : currentSlideGroupName ? (
            <>
              You finished
              <br />
              {currentSlideGroupName}!
            </>
          ) : (
            <>Great job! You finished!</>
          )}
        </h1>

        <div className='w-75 h-75 mb-4 lg:w-120 lg:h-120'>
          <video
            src={videoSrc}
            autoPlay
            loop
            muted
            playsInline
            className='w-full h-full object-contain'
          />
        </div>
      </div>

      <CommonButton
        variant='brand'
        onClick={onContinue}
        className='mb-5 w-11/12 lg:w-1/2'
      >
        Continue
      </CommonButton>
    </div>
  );
}

type PlaygroundControlState = {
  status:
    | 'preparing'
    | 'minigame-intro'
    | 'initializing-block'
    | 'playing-block'
    | 'finished';
  block: Nullable<DtoBlock>;
  blockCtrl: Nullable<IBlockCtrl>;
  progressPct: number;
  nextMinigameId: Nullable<string>;
};

class PlaygroundControlAPI {
  private _state = markSnapshottable(
    proxy<PlaygroundControlState>(this.initialState())
  );

  readonly blockDeps: BlockDependencies;
  private runner: Nullable<AbortableRunner<void, undefined>>;
  private musicControl;
  private logger: Logger;
  private pauseManagement: PauseManagement | null;
  private lmsNotifier = new LMSNotifier();

  constructor(
    private cursor: PlayCursor,
    initialBlockOutputs: Nullable<DtoProgression['blockOutputs']>,
    getUser: () => User,
    stingerElements: ReturnType<typeof useStingerElements>,
    navigate: ReturnType<typeof useNavigate>,
    private readonly preview = false
  ) {
    this.logger = this.initLogger(getUser);
    this.musicControl = new MusicControl();

    const sfxControl = new SFXControl();
    this.blockDeps = {
      sfxControl: sfxControl,
      subman: new LocalSubtitlesManager(),
      commonVariableRegistry: this.initVariableRegistry(
        getUser,
        initialBlockOutputs
      ),
      getLogger: (scope: string) => this.logger.scoped(scope),
      getUser,
      stingerControl: new StingerControl(
        stingerElements,
        sfxControl,
        this.logger.scoped('stinger')
      ),
      storage: StorageFactory('local'),
    };

    this.pauseManagement = preview
      ? null
      : new PauseManagement(this.logger.scoped('pause-mgmt'), cursor, navigate);

    this.run();
  }

  get state() {
    return this._state;
  }

  get gamePack() {
    return this.cursor.gamePack;
  }

  get minigame() {
    return this.cursor.currentMinigame();
  }

  shouldRebuild(cursor: PlayCursor) {
    return (
      this.cursor.gamePack.id !== cursor.gamePack.id ||
      this.cursor.currentMinigame()?.id !== cursor.currentMinigame()?.id
    );
  }

  destroy() {
    this.runner?.destroy();
    this.blockDeps.sfxControl.destroy();
    this.musicControl.destroy();
    this.pauseManagement?.destroy();
  }

  async run() {
    // already running;
    if (this.runner) throw new Error('failed to start: already running');

    this.runner = YieldableAbortableRunner<
      Nullable<{ didJump: boolean }>,
      void,
      Nullable<{ didJump: boolean }>
    >(
      async function* (
        this: PlaygroundControlAPI
      ): AsyncGenerator<
        Nullable<{ didJump: boolean }>,
        void,
        Nullable<{ didJump: boolean }>
      > {
        // start the music
        this.musicControl.play();

        // kick off preloading the first block...
        let block = this.cursor.currentBlock();
        let blockPreloadResult = this.preloadBlock(block);

        // before we enter the loop, let's run the minigame stinger.
        const currentMinigame = this.cursor.currentMinigame();
        if (currentMinigame) {
          // and before we run this, let's try to prep some music...
          // it's okay to do this early. when we call it again for the same block in the loop, the music will continue.
          this.switchMusicForBlock(block);

          this._state.status = 'minigame-intro';
          this.blockDeps.stingerControl.resetMinigameStingerEl();
          if (!this.blockDeps.stingerControl.isReady()) {
            // we should have the el refs, but if we're coming from the overworld,
            // they aren't ready yet.
            await sleep(5);
          }
          await this.blockDeps.stingerControl.playMinigameIntro(
            currentMinigame
          );
        }

        while (block) {
          if (!blockPreloadResult) {
            // this is some kind of block we don't support, just move along.
            this.logger.warn('unsupported block type, skipping', {
              blockId: block.id,
              blockType: block.type,
            });
            block = this.cursor.nextBlock();
            blockPreloadResult = this.preloadBlock(block);
            continue;
          }

          // start preloading the next block if it exists
          const nextBlockPreloadResult = this.preloadBlock(
            this.cursor.peekNextBlock()
          );

          this.switchMusicForBlock(block);

          // reset the stinger...
          this.blockDeps.stingerControl.resetBlockStingerEl();

          // TODO(falcon): errors...
          const result = yield this.playBlock(block, blockPreloadResult);
          if (result?.didJump) {
            // if we jumped, we don't want to preload the next block.
            block = this.cursor.currentBlock();
            blockPreloadResult = this.preloadBlock(block);
          } else {
            block = this.cursor.nextBlock();
            blockPreloadResult = nextBlockPreloadResult;
          }
        }

        this._state.status = 'finished';
        this._state.progressPct = 100;
        this._state.nextMinigameId = this.cursor.peekNextMinigame()?.id;
        if (!this._state.nextMinigameId) {
          this.lmsNotifier.post('setCourseCompleted');
        }
      }.bind(this)
    );
    this.runner.start();
  }

  private async switchMusicForBlock(block: Nullable<DtoBlock>) {
    const bgMusicMedia = fromDTOBlock(block)?.fields.bgMusic?.asset.media;
    await this.musicControl.switchTrack(bgMusicMedia);
  }

  private async playBlock(
    block: DtoBlock,
    blockPreloadResult: NonNullable<ReturnType<typeof this.preloadBlock>>
  ) {
    const promise = Promise.withResolvers<{ didJump: boolean }>();
    // these are what we'll persist to the backend, outputs with block ids.
    const outputs: Record<string, ModelsBlockOutput> = {};
    // these are additional outputs we'll put in the vr.
    const refOutputs: Record<string, ModelsBlockOutput> = {};
    const evaluator = new BlockLogicEvaluator(
      this.gamePack.logicSettings?.blockLogic?.[block.id],
      this.logger.scoped('block-logic-evaluator')
    );
    const protocol: PlaygroundPlaybackProtocol = {
      blockDidStart: async () => {
        this.logger.info('blockDidStart', {
          blockId: block.id,
          blockType: block.type,
        });
      },
      blockDidEnd: async () => {
        this.logger.info('blockDidEnd', {
          blockId: block.id,
          blockType: block.type,
        });
        if (this.preview) {
          // if we're in preview mode, we don't want to track progression.
          this.logger.info('preview mode, skipping progression tracking');
        } else {
          await apiService.progression.trackMyProgressionBlockPlayed(
            this.cursor.gamePack.id,
            {
              minigameId: block.gameId,
              blockId: block.id,
              blockType: block.type,
              outputs,
            }
          );
        }

        // set outputs on the vr.
        const entries = [
          ...Object.entries(outputs),
          ...Object.entries(refOutputs),
        ];
        for (const [key, value] of entries) {
          this.blockDeps.commonVariableRegistry.set(key, async () =>
            getBlockOutputAsString(value)
          );
        }

        let jumpToBlockId: string | undefined = undefined;
        evaluator.eval(outputs, {
          onJump: (toBlockId: string) => {
            jumpToBlockId = toBlockId;
          },
        });

        if (jumpToBlockId) {
          this.cursor = this.cursor.withJump(jumpToBlockId);
          promise.resolve({ didJump: true });
        } else {
          promise.resolve({ didJump: false });
        }
      },

      blockDidOutput: async (desc, value) => {
        this.logger.info('blockDidOutput', {
          blockId: block.id,
          blockType: block.type,
          desc,
          value,
        });
        const output: ModelsBlockOutput = {
          type: desc.schema.type,
          value,
        };
        outputs[getBlockOutputKey(block.id, desc.name)] = output;
        const refId = fromDTOBlock(block).fields.referenceId;
        if (refId) {
          refOutputs[getBlockOutputKey(refId, desc.name)] = output;
        }
      },
    };

    this._state.block = block;
    this._state.blockCtrl = ref(blockPreloadResult.blockCtrl);
    this._state.progressPct = this.cursor.progressPct();
    this._state.nextMinigameId = this.cursor.peekNextMinigame()?.id;

    this.logger.info('initializing block', {
      blockId: block.id,
      blockType: block.type,
    });

    this._state.status = 'initializing-block';
    await this._state.blockCtrl?.initialize(blockPreloadResult.preloaded);

    this.logger.info('playing block', {
      blockId: block.id,
      blockType: block.type,
    });
    this._state.blockCtrl?.setDelegate(protocol);
    this._state.status = 'playing-block';

    return promise.promise;
  }

  private preloadBlock(block: Nullable<DtoBlock>) {
    if (!block) return null;
    const blockCtrl = createBlockControl(block, this.blockDeps);
    if (!blockCtrl) return null;

    return {
      blockCtrl,
      preloaded: (async () => {
        try {
          await blockCtrl.preload();
        } catch (e) {
          this.logger.error('failed to preload block', e);
        }
      })(),
    };
  }

  private initialState(): PlaygroundControlState {
    return {
      status: 'preparing',
      block: null,
      blockCtrl: null,
      progressPct: 0,
      nextMinigameId: null,
    };
  }

  private initLogger(getUser: () => User): Logger {
    const logger = getLogger();
    logger.verbose(getFeatureQueryParam('verbose-local-logging'));
    logger.updateSharedMeta({
      uid: getUser().id,
    });
    const clock = new DefaultClock();
    logger.setTimeMsGetter(() => clock.now());
    (async () => {
      try {
        await clock.sync();
      } catch (e) {
        logger.error('failed to sync clock', e);
      }
    })();
    logger.scoped('device').info('DeviceInfo', {
      userAgent: navigator.userAgent,
      hardwareConcurrency: navigator.hardwareConcurrency,
      deviceMemory: navigator.deviceMemory,
    });
    return logger.scoped('playground');
  }

  private initVariableRegistry(
    getUser: () => User,
    initialBlockOutputs?: Nullable<DtoProgression['blockOutputs']>
  ): VariableRegistry {
    const vr = new VariableRegistry();
    vr.set('playerName', async () => {
      const user = getUser();
      return user.organizer?.firstName ?? user.username;
    })
      // TODO: this will be public facing since users will see the scripts.
      // Should it be called gamepack or something else, such as "course" or "lesson"?
      .set('gamePackName', async () => this.cursor.gamePack.name)
      .set('gamePackDescription', async () => this.cursor.gamePack.description)
      .set(
        'minigameName',
        async () => this.cursor.currentMinigame()?.name ?? ''
      )
      .set(
        'minigameDescription',
        async () => this.cursor.currentMinigame()?.description ?? ''
      );

    // add progression block outputs.
    for (const [key, value] of Object.entries(initialBlockOutputs ?? {})) {
      vr.set(key, async () => getBlockOutputAsString(value));
    }

    return vr;
  }
}

function createBlockControl(
  block: DtoBlock,
  blockDeps: BlockDependencies
): Nullable<IBlockCtrl> {
  const type = blockTypeV2fromEnumsBlockType(block.type);
  switch (type) {
    case BlockTypeV2.MULTIPLE_CHOICE: {
      return new MultipleChoiceBlockControlAPI(
        block as unknown as MultipleChoiceBlock,
        blockDeps
      );
    }
    case BlockTypeV2.QUESTION:
      return new QuestionBlockControlAPI(
        block as unknown as QuestionBlock,
        blockDeps
      );
    case BlockTypeV2.MATCH:
      return new MatchBlockControlAPI(
        block as unknown as MemoryMatchBlock,
        blockDeps
      );
    case BlockTypeV2.SLIDE:
      return new SlideBlockControlAPI(
        block as unknown as SlideBlock,
        blockDeps
      );
    case BlockTypeV2.ROLEPLAY:
      return new RoleplayBlockControlAPI(
        block as unknown as RoleplayBlock,
        blockDeps
      );
    case BlockTypeV2.DRAW_TO_WIN:
      return new DrawToWinBlockControlAPI(
        block as unknown as DrawToWinBlock,
        blockDeps
      );
    case BlockTypeV2.HIDDEN_PICTURE:
      return new HiddenPictureBlockControlAPI(
        block as unknown as HiddenPictureBlock,
        blockDeps
      );
    case BlockTypeV2.JEOPARDY:
      return new JeopardyBlockControlAPI(
        block as unknown as JeopardyBlock,
        blockDeps
      );
    case BlockTypeV2.SPARKIFACT:
      return new SparkifactBlockControlAPI(
        block as unknown as SparkifactBlock,
        blockDeps
      );
    case BlockTypeV2.FILL_IN_THE_BLANKS:
      return newFIBControlAPI(
        block as unknown as FillInTheBlanksBlock,
        blockDeps
      );
    case BlockTypeV2.SWIPE_TO_WIN:
      return new SwipeToWinBlockControlAPI(
        block as unknown as SwipeToWinBlock,
        blockDeps
      );
    case null:
    case undefined:
      return null;
    default:
      assertExhaustive(type);
      return null;
  }
}
